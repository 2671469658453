import { medialApi } from "Apis/AllApisMapping"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import React, { useContext, useRef, useState } from "react"
import { ReactContext } from "../MediaLibrary"
import GoogleDrivePicker from "Awesome-ui/FilePickers/GooglePicker/GooglePicker"
import DropBoxPicker from "Awesome-ui/FilePickers/DropBoxPicker/DropBoxPicker"
import PdfDropDown from "Components/AddDocument/PdfDropdown"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import Loader from "Awesome-ui/Loader/Loader"
import { useStyles } from "../MediaLibraryStyle"

function validateFile(file, validatingParameters) {
    if (!validatingParameters) return true
    return validatingParameters.includes(file.type)
}

function validateFileSize(file, validationSize) {
    if (!validationSize) return true
    if (file.size > validationSize) return false
    return true
}

const NOT_A_FILE = "NOT_A_FILE"

const UploadMedia = () => {
    const {
        singleFile,
        validationSize,
        validatingParameters,
        setHandlingFiles,
        GoogleDrive,
        DropBox,
        userToken,
        initFiles,
        styles,
    } = useContext(ReactContext)

    const [fileOver, setFileOver] = useState(false)
    const inputfile = useRef(null)
    const mediaController = new mediaHandler(medialApi)
    const [loading, setloading] = useState(false)
    const [isHover, setHover] = useState(false)
    const classes = useStyles(styles)

    function onFileEnter(event) {
        event.preventDefault()
    }
    function onFileDragOver(event) {
        event.preventDefault()
        if (!fileOver) setFileOver(true)
    }

    function onFileDrop(event) {
        event.preventDefault()
        handleFiles(event.dataTransfer.files)
        setFileOver(false)
    }

    function onFileLeave(event) {
        event.preventDefault()
        setFileOver(false)
    }

    async function handleFileDrive(file) {
        return await mediaController.uploadMediaOther(
            file.name,
            file.mimeType,
            file.id,
            "drive",
            userToken
        )
    }

    async function handleFiles(listFiles) {
        if (listFiles.length <= 0) return
        inputfile.current.files = null

        setloading(true)
        let filesToHandle = [...listFiles]
        if (singleFile) filesToHandle = [listFiles[0]]
        const validFiles = filesToHandle.filter((file) => {
            if (!validateFile(file, validatingParameters)) {
                console.log("files in not valid")
                return false
            }
            if (!validateFileSize(file, validationSize)) {
                console.log("file size is very big.")
                return false
            }
            return true
        })
        const currentFiles = await Promise.all(
            validFiles.map(async (file) => {
                try {
                    const mediaResp = await mediaController.uploadMedia(
                        file,
                        file.name,
                        userToken
                    )
                    return mediaResp
                } catch (err) {
                    console.log(err)
                    return NOT_A_FILE
                }
            })
        )
        if (singleFile)
            setHandlingFiles(() => ({
                ...initFiles,
                upload: [...currentFiles.filter((file) => file !== NOT_A_FILE)],
            }))
        else
            setHandlingFiles((prev) => ({
                ...prev,
                upload: [
                    ...prev.upload,
                    ...currentFiles.filter((file) => file !== NOT_A_FILE),
                ],
            }))
        setloading(false)
    }

    return (
        <>
            <div
                className={`${classes.uploadFilesWrapper} ${
                    isHover && classes.uploadFilesWrapperHover
                }`}
                onDragOver={(e) => onFileDragOver(e, fileOver, setFileOver)}
                onDragEnter={onFileEnter}
                onDrop={(e) => onFileDrop(e, setFileOver)}
                onDragLeave={(e) => onFileLeave(e, setFileOver)}
            >
                <div className={`${classes.fileUploaderRight}`}>
                    <div className={`${classes.fileUploaderTop}`}>
                        <div
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            onClick={() => inputfile.current.click()}
                        >
                            {loading ? (
                                <Loader style={{ height: 50, width: 50 }} />
                            ) : (
                                <PdfDropDown />
                            )}
                        </div>
                    </div>
                    <Text fontSize={14} align="center" marginTop="1rem">
                        Upload or drag and drop your files
                    </Text>
                </div>
                <input
                    type="file"
                    onChange={(e) => handleFiles(e.target.files)}
                    hidden
                    multiple={true}
                    ref={inputfile}
                    accept={validatingParameters.join(",")}
                />
            </div>
            <div className={`${classes.otherFilePickingMethods}`}>
                {GoogleDrive && (
                    <GoogleDrivePicker
                        setFile={async (files) => {
                            const otherMediaUploadedFiles = await Promise.all(
                                files.map(handleFileDrive)
                            )
                            handleFiles(otherMediaUploadedFiles)
                        }}
                        styleClass={`${classes.otherFilePickingMethod}`}
                    />
                )}
                {DropBox && (
                    <DropBoxPicker
                        setFile={handleFiles}
                        styleClass={`${classes.otherFilePickingMethod}`}
                    />
                )}
            </div>
        </>
    )
}

export default UploadMedia
