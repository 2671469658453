import React from "react";
import "./AppSwitcher.scss";

import SideDrawer from "Awesome-ui/SideDrawer"
import { ReactComponent as ApplicationsIcon } from "../../Assets/ProductIcon/apps.svg";
import { connect } from "react-redux";
import { productMap } from "../../data/products";
import { getLinkToService } from "../../data/ProductMapping";
import { Text } from "@awesomesuite-frontend/awesome-nebula";

const AppContent = ({ style, name, description, Icon, url, tag , token}) => {
  const onClickHandler =()=>{
    if(name!="Sign"){
      window.location.href=`${getLinkToService(name)}${tag == "new" ? "?p="+token: "" }`
    }
  }
  return (
    <div className="aui-app-card" onClick={onClickHandler}>
      <div className="app-card-icon">{Icon}</div>
      <div className="content">
        <div className="app-name">
          <div className="icon-status">
          <Text varient="h6" style ={{fontWeight:"400"}}>{name}</Text>
          {tag!= "new" && (
          <span className={`tags ${tag}`}>
            {tag}
          </span>
          )}
          </div>
          <Text varient="p" style={{ color: "#7C98B6", fontSize:"12px" }}>
            {description}
          </Text>
        </div>
        
      </div>
    </div>
  );
};

/**
 * main app switcher component
 * @param {*} param0
 * @returns
 */
const AppSwitcher = ({
  openAppSwitcher = true,
  setOpenSwitcher,
  token
}) => {
  if (!openAppSwitcher) return null;
  return (
    <SideDrawer
      width="370px"
      vis
      className="app-switcher"
      position="left"
      toggle={() => setOpenSwitcher(false)}
      toggleBtn={<div className="app-switcher-cross">+</div>}
    >
      <div className="switcher-top-bar">
        <span>
          <ApplicationsIcon />
        </span>
        <Text varient="h4">Applications</Text>
      </div>
      <div className="switcher-body">
        {productMap.map((item) => {
          return <AppContent {...item}  token={token} />;
        })}
      </div>
    </SideDrawer>
  );
};

const mapStateToProps = ({ userReducer }) => ({
  token:userReducer.token
});

export default connect(mapStateToProps)(AppSwitcher);
