import React from "react"
import { useStyles } from "./TrustCenterStyle"
import { Grid, Text } from "@awesomesuite-frontend/awesome-nebula"
import { ReactComponent as TrustCenterIcon } from "Assets/NavigationIcon/certificate-tab.svg"
import { ReactComponent as CSAIcon } from "Assets/TrustCenter/CSA.svg"
import { ReactComponent as ISOIcon } from "Assets/TrustCenter/ISO.svg"
import { ReactComponent as SOCIcon } from "Assets/TrustCenter/SOC.svg"
import { ReactComponent as AWSPoweredByIcon } from "Assets/TrustCenter/powered-by-aws.svg"
import { ReactComponent as EsignCertificateIcon } from "Assets/TrustCenter/EsignCertificate.svg"
import { ReactComponent as UETACertificateIcon } from "Assets/TrustCenter/UETACertificate.svg"
import eIDASIcon from "Assets/TrustCenter/eIDAS.png"

const trustCenterContent1 = [
    {
        title: "CSA",
        icon: <CSAIcon width={185} height={130} />,
        description: "Cloud Security Alliance Controls",
    },
    {
        title: "SOC 1",
        icon: <SOCIcon width={185} height={130} />,
        description: "Audit Controls Report",
    },
    {
        title: "SOC 2",
        icon: <SOCIcon width={185} height={130} />,
        description: "Security, Availability, & Confidentiality Report",
    },
    {
        title: "ISO 27001",
        icon: <ISOIcon width={185} height={130} />,
        description: "Security Management Controls",
    },
]

const trustCenterContent2 = [
    {
        title: "ESIGN Act",
        icon: <EsignCertificateIcon />,
        description: "Electronic Signatures in Global and National Commerce Act",
    },
    {
        title: "UETA Act",
        icon: <UETACertificateIcon />,
        description: "Uniform Electronic Transactions Act",
    },
    {
        title: "eIDAS",
        icon: <img src={eIDASIcon} height={84} />,
        description: "Electronic Identification and Trusted Services Regulation",
    },
]

const benefitialContent = [
    "✅ eSignatures that are 100% legally-binding",
    "✅ eSignatures with Awesome Sign hold weight in court.",
    "✅ We provide a digital trail (papertrail, but 100% online) of all the actions on a document.",
]

const TrustContentEach = ({ content, gap }) => {
    const classes = useStyles()
    return (
        <Grid item container xs={gap} className={classes.trustCenterContent}>
            {content.icon}
            <Text varient="h6" align="center" marginTop="1.5rem">
                {content.title}
            </Text>
            <Text color="light" varient="subText" align="center" padding="0.5rem">
                {content.description}
            </Text>
        </Grid>
    )
}

const TrustCenter = () => {
    const classes = useStyles()
    return (
        <Grid
            xl={8}
            lg={9}
            md={10}
            container
            className={classes.trustCenterWrapper}
            margin={"auto"}
        >
            <Grid container item xs={12} className={classes.trustCenterHeader}>
                <Grid item xs={1} className={classes.trustCenterHeaderIcon}>
                    <TrustCenterIcon height={50} width={41} />
                </Grid>
                <Grid item xs={10} className={classes.trustCenterHeaderContent}>
                    <Text varient={"h2"}>Awesome Sign Trust Center</Text>
                    <Text color="light" marginTop="0.25rem">
                        Our cloud platform service provider certificates
                    </Text>
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.trustCenterContents}>
                <Grid item xs={12}>
                    <Text varient={"h5"} marginLeft={`6.25%`} marginBottom="1rem">
                        Global Cloud Security
                    </Text>
                </Grid>
                {trustCenterContent1.map((content, index) => (
                    <TrustContentEach content={content} gap={2} key={index} />
                ))}
                <Grid
                    item
                    container
                    xs={12}
                    className={classes.trustCenterContent}
                    style={{ marginTop: "1.5rem" }}
                >
                    <AWSPoweredByIcon />
                </Grid>
            </Grid>
            <Grid container item xs={12} className={classes.trustCenterContents}>
                <Grid item xs={12}>
                    <Text varient={"h5"} marginLeft={`6.25%`} marginBottom="1rem">
                        eSignature Certification
                    </Text>
                </Grid>
                {trustCenterContent2.map((content, index) => (
                    <TrustContentEach content={content} gap={3} key={index} />
                ))}
            </Grid>
            <Grid container item xs={12} className={classes.trustCenterContents}>
                <Grid item xs={12} style={{ paddingLeft: "6.25%" }}>
                    <Text varient={"h5"} marginBottom="1rem">
                        With Awesome Sign you get:
                    </Text>
                    {benefitialContent.map((content, index) => (
                        <Text key={index} marginBottom="0.5rem">
                            {content}
                        </Text>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TrustCenter
