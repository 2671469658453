import React from "react"
import "./ColorPicker.scss"

const colorMapping = [
    { color: "#33475B", id: "dr-1" },
    { color: "#00AEEF", id: "bl-1" },
    { color: "#F22F46", id: "rd-1" },
]

const ColorPicker = ({ colorChangeHandler, selectedColor, style }) => {
    return (
        <div className="color-picker" style={{ ...style }}>
            {colorMapping.map((colorItem) => (
                <div
                    className={`color-picker-item ${
                        selectedColor.id === colorItem.id &&
                        "color-picker-item-selected"
                    }`}
                    onClick={() => colorChangeHandler(colorItem)}
                >
                    <div style={{ backgroundColor: colorItem.color }} />
                </div>
            ))}
        </div>
    )
}

export default ColorPicker
