import React, { forwardRef, useImperativeHandle } from "react"

import { getCanvasWithTextWidth } from "Library/CanvasLib/CanvasLib"
import ColorPicker from "../ColorPicker/ColorPicker"
import "./SignType.scss"

const fontFamilyList = {
    architectsDaughter: "'Architects Daughter', sans-serif",
    dancingScript: "'Dancing Script', sans-serif",
    pacifico: "'Pacifico', sans-serif",
    shadows: "'Shadows Into Light', sans-serif",
}

const TypeSignature = forwardRef((props, ref) => {
    const [signature, setSignature] = React.useState("")
    const [colorKeeper, setColorKeeper] = React.useState({
        color: "#33475B",
        id: "dr-1",
    })
    const [fontFamily, setFontFamily] = React.useState(
        fontFamilyList.architectsDaughter
    )

    function getCanvasForText() {
        if (!signature || !fontFamily) return

        return getCanvasWithTextWidth(signature, fontFamily, 2.3, colorKeeper.color)
    }

    useImperativeHandle(ref, () => ({
        getResult() {
            return getCanvasForText()
        },
    }))

    return (
        <div className="signType-wrapper">
            <div className="signType-content-wrapper">
                <div className="signType-title">Type</div>
                <div className="sign-Type-tools">
                    <ColorPicker
                        selectedColor={colorKeeper}
                        colorChangeHandler={setColorKeeper}
                    />
                </div>
            </div>
            <div className="main-component">
                <div className="type-comp-top">
                    <input
                        type="text"
                        className={`type-input-field`}
                        value={signature}
                        placeholder="Your Name"
                        onChange={(e) => setSignature(e.target.value)}
                    />
                </div>
                <div className="type-preview">
                    <div
                        className={`font-preview font-Architects-Daughter ${
                            fontFamily === fontFamilyList.architectsDaughter &&
                            "font-selected"
                        }`}
                        onClick={() =>
                            setFontFamily(fontFamilyList.architectsDaughter)
                        }
                        style={{ color: colorKeeper.color }}
                    >
                        {signature ? signature : "Your Name"}
                    </div>
                    <div
                        className={`font-preview font-Dancing-Script ${
                            fontFamily === fontFamilyList.dancingScript &&
                            "font-selected"
                        }`}
                        onClick={() => setFontFamily(fontFamilyList.dancingScript)}
                        style={{ color: colorKeeper.color }}
                    >
                        {signature ? signature : "Your Name"}
                    </div>
                    <div
                        className={`font-preview font-Pacifico ${
                            fontFamily === fontFamilyList.pacifico && "font-selected"
                        }`}
                        onClick={() => setFontFamily(fontFamilyList.pacifico)}
                        style={{ color: colorKeeper.color }}
                    >
                        {signature ? signature : "Your Name"}
                    </div>
                    <div
                        className={`font-preview font-Shadows-Into-Light ${
                            fontFamily === fontFamilyList.shadows && "font-selected"
                        }`}
                        onClick={() => setFontFamily(fontFamilyList.shadows)}
                        style={{ color: colorKeeper.color }}
                    >
                        {signature ? signature : "Your Name"}
                    </div>
                </div>
            </div>
        </div>
    )
})

export default TypeSignature
