import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
    getUserRole: {
        width: "8rem",
        height: "2.2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
    },
}))

const roleColors = {
    manager: {
        backgroundColor: "#b4ffdb",
        color: "#19db7e",
    },
    admin: {
        backgroundColor: "#FFF1EE",
        color: "#FF7A59",
    },
    "team member": {
        backgroundColor: "#EBF0F9",
        color: "#446FCB",
    },
    guest: {
        backgroundColor: "#a9fffa",
        color: "#00d4c8",
    },
    user: {
        backgroundColor: "#FFF4E5",
        color: "#FF970A",
    },
    "non member": {
        backgroundColor: "#eaf0f6",
        color: "black",
    },
    agent: {
        backgroundColor: "#ecd3ff",
        color: "#9f46e4",
    },
}

const GetUserRole = ({ user, roles, fontSize, height, width, style = {} }) => {
    if (!user.role)
        user.role = roles.find((role) => role.roleId === user.role_id)?.roleName
    if (user.role.includes("AwesomeEsign-"))
        user.role = user.role.split("AwesomeEsign-")[1]
    let currentRole = roleColors[user.role.toLowerCase()]
    if (!currentRole) currentRole = roleColors.user

    return (
        <div
            className={useStyles().getUserRole}
            style={{
                backgroundColor: currentRole.backgroundColor,
                fontSize,
                height,
                width,
                ...style,
            }}
        >
            <Text color={currentRole.color} varient="subText">
                {user.role[0]?.toUpperCase() + user.role?.slice(1)}
            </Text>
        </div>
    )
}

export default GetUserRole
