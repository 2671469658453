import { Grid } from '@awesomesuite-frontend/awesome-nebula'
import Input from 'Awesome-ui/Input/Input'
import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
    companyDetailsContainer:{
        paddingLeft:"10%"
    },
    inputClass: {
        "&::placeholder": {
            fontSize: "0.9rem"
        }
    }
}))

const CompanyDetails = () => {
    const classes = useStyles()
    return (
        <div className={classes.companyDetailsContainer}>
            <Grid container={true} xs={12} spacing={12}>
                <Grid item={true} lg={4} md={5} xs={8}>
                    <Input inputClassname={classes.inputClass} labelStyle={{ fontSize: "0.9rem" }} label="Company Name" placeHolder="Company Name" borderRadius="6px" />
                </Grid>
                <Grid item={true} lg={4} md={5} xs={8}>
                    <Input inputClassname={classes.inputClass} labelStyle={{ fontSize: "0.9rem" }} label="From Email Name" placeHolder="From Email Name" borderRadius="6px" />
                </Grid>
            </Grid>
            <Grid container={true} xs={12} spacing={12}>
                <Grid item={true} lg={4} md={5} xs={8}>
                    <Input inputClassname={classes.inputClass} labelStyle={{ fontSize: "0.9rem" }} label="Address" placeHolder="Address" borderRadius="6px" />
                </Grid>
                <Grid item={true} lg={4} md={5} xs={8}>
                    <Input inputClassname={classes.inputClass} labelStyle={{ fontSize: "0.9rem" }} label="Country" placeHolder="Country" borderRadius="6px" />
                </Grid>
            </Grid>
            <Grid container={true} xs={12} spacing={12}>
                <Grid item={true} lg={4} md={5} xs={8}>
                    <Input inputClassname={classes.inputClass} labelStyle={{ fontSize: "0.9rem" }} label="Email" placeHolder="Email" borderRadius="6px" />
                </Grid>
                <Grid item={true} lg={4} md={5} xs={8}>
                    <Input inputClassname={classes.inputClass} labelStyle={{ fontSize: "0.9rem" }} label="From Email Name" placeHolder="From Email Name" borderRadius="6px" />
                </Grid>
            </Grid>
        </div>
    )
}

export default CompanyDetails