import { log } from "@awesomesuite-frontend/awesome-nebula"
import { getCanvasSize } from "Library/canvasAdjuster"
import { FORM } from "Types"
import React, { useRef, useEffect } from "react"
import { connect } from "react-redux"

const { height: NORMAL_HEIGHT, width: NORMAL_WIDTH } = getCanvasSize()

function getRenderingSize(
    height,
    width,
    boundingHeight = NORMAL_HEIGHT,
    boundingWidth = NORMAL_WIDTH
) {
    let renderingHeight = height,
        renderingWidth = width
    const aspectRatio = height / width

    if (aspectRatio > 1) {
        //portrait
        if (renderingWidth > boundingWidth) {
            renderingWidth = boundingWidth
            renderingHeight = boundingWidth * aspectRatio
        }
        if (renderingHeight > boundingHeight) {
            renderingHeight = boundingHeight
            renderingWidth = boundingHeight / aspectRatio
        }
    } else {
        //landscape
        if (renderingHeight > boundingHeight) {
            renderingHeight = boundingHeight
            renderingWidth = boundingHeight / aspectRatio
        }
        if (renderingWidth > boundingWidth) {
            renderingWidth = boundingWidth
            renderingHeight = boundingWidth * aspectRatio
        }
    }

    return { width: renderingWidth, height: renderingHeight }
}

const CanvasLayer = ({
    page,
    setBonds,
    pageNumber,
    setPageSize,
    view,
    ...props
}) => {
    const ref = useRef(null)
    let renderTask = null

    function drawCanvas() {
        if (renderTask) {
            renderTask.cancle()
            log("page is already in process of rendering...")
        }

        var scale = view === FORM ? 2.5 : 1.7

        var viewPort = page.getViewport({ scale: scale })

        if (!viewPort) {
            log(pageNumber)
            return
        }

        const canvasDiv = ref.current
        if (!canvasDiv) return
        const ctx = canvasDiv.getContext("2d")

        // Support HiDPI-screens.
        var outputScale = window.devicePixelRatio || 1

        let maxWidthBound = NORMAL_WIDTH
        if (view === FORM) maxWidthBound = Math.min(0.6 * window.innerWidth, 980)

        const { width, height } = getRenderingSize(
            viewPort.height,
            viewPort.width,
            NORMAL_HEIGHT,
            maxWidthBound
        )
        setPageSize((prev) => ({ ...prev, height, width }))
        canvasDiv.width = Math.floor(viewPort.width * outputScale)
        canvasDiv.height = Math.floor(viewPort.height * outputScale)
        canvasDiv.style.width = Math.floor(width) + "px"
        canvasDiv.style.height = Math.floor(height) + "px"

        var transform =
            outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null

        setBonds(width, height)

        const renderContext = {
            canvasContext: ctx,
            viewport: viewPort,
            transform: transform,
        }

        renderTask = page.render(renderContext)
        renderTask.promise.then(() => {
            log("page rendered")
        })
    }

    useEffect(() => {
        drawCanvas()
    }, [])

    return <canvas ref={(el) => (ref.current = el)}></canvas>
}

const mapStateToProps = ({ signReducer }) => ({
    view: signReducer.documentDetails?.view,
})

export default connect(mapStateToProps)(CanvasLayer)
