import { Button, Grid, Text } from "@awesomesuite-frontend/awesome-nebula"
import { contracts, baseURLOfTemplates } from "data/contracts"
import React from "react"
import { createUseStyles } from "react-jss"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { ReactComponent as Download } from "Assets/PdfEditor/download.svg"
import usePdfHook from "hooks/PdfLib/PdfHook"
import { addDocument } from "Action/documentAction"
import { TEMPLATE } from "Types"

const useStyles = createUseStyles({
    staticTemplates: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "stretch",
        flexWrap: "wrap",
        margin: "1rem",
        // marginTop: "2rem",
    },
    staticTemplate: {
        cursor: "pointer",
        width: "20%",
        marginRight: "2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& img": {
            maxWidth: "100%",
            maxHeight: 450,
        },
        "&nth-child(4n)": {
            marginRight: 0,
        },
    },
    downloadLibraryTemplate: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "2rem",
        margin: "0.5rem 0",
    },
    downloadLibraryTemplateButton: {
        backgroundColor: "#00BDA5",
        color: "#fff",
        width: "80%",
        height: "100%",
        borderRadius: 17,
        "&:hover": {
            opacity: 0.8,
        },
        "& span": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& svg": {
                marginRight: "0.5rem",
            },
        },
    },
    staticTemplateImage: {
        position: "relative",
    },
    staticTemplateImageHover: {
        position: "absolute",
        backgroundColor: "#33475b",
        opacity: 0.87,
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    staticTemplateCopy: {
        position: "absolute",
        zIndex: 2,
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
})

const StaticTemplates = ({ searchQuery }) => {
    const classes = useStyles()
    const [currentContracts, setCurrentContracts] = React.useState(contracts)

    React.useEffect(() => {
        if (!searchQuery) return setCurrentContracts(contracts)
        const filteredContracts = contracts.filter((contract) =>
            contract.title.toLowerCase().includes(searchQuery.toLowerCase())
        )
        setCurrentContracts(filteredContracts)
    }, [searchQuery])

    return (
        <div className={classes.staticTemplates}>
            {currentContracts.map((contract, index) => (
                <EachStaticTemplate contract={contract} key={index} />
            ))}
        </div>
    )
}

const EachStaticTemplate = ({ contract }) => {
    const [hoveredContract, setHoveredContract] = React.useState(false)
    const classes = useStyles()
    const history = useHistory()
    const { loadedLib, loadPdfLib, loadPdf } = usePdfHook()
    async function getNumberOfPages(pdfFile) {
        if (!loadedLib) await loadPdfLib()
        const res = await loadPdf(pdfFile)
        return res.numberOfPages
    }
    const copyTemplateToMyLibrary = async () => {
        const templateURL = `${baseURLOfTemplates}${contract.fileNameInDb}`
        const pages = await getNumberOfPages(templateURL)
        try {
            const response = await addDocument(
                contract.fileNameInDb,
                "",
                templateURL,
                pages,
                -1,
                TEMPLATE
            )
            if (response) {
                history.push(`/pdf-editor/${response.model.fileId}`)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const downloadTemplate = async () => {
        fetch(`${baseURLOfTemplates}${contract.fileNameInDb}`, { method: "get" })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement("a")
                aElement.setAttribute("download", contract.fileNameInDb)
                const href = URL.createObjectURL(res)
                aElement.href = href
                aElement.setAttribute("target", "_blank")
                aElement.click()
                URL.revokeObjectURL(href)
            })
    }
    if (!contract.fileNameInDb) return null
    return (
        <div
            className={classes.staticTemplate}
            onMouseOver={() => setHoveredContract(true)}
            onMouseLeave={() => setHoveredContract(false)}
        >
            <div className={classes.staticTemplateMainContent}>
                <div
                    className={classes.staticTemplateImage}
                    onClick={copyTemplateToMyLibrary}
                >
                    {hoveredContract && (
                        <>
                            <div className={classes.staticTemplateImageHover}></div>
                            <Text
                                color="#fff"
                                varient="subText"
                                className={classes.staticTemplateCopy}
                                weight={600}
                            >
                                Use Template
                            </Text>
                        </>
                    )}
                    <LazyLoadImage src={contract.coverImage} alt={contract.title} />
                </div>
                <Text
                    varient="subText"
                    align="center"
                    weight={600}
                    marginTop="0.5rem"
                >
                    {contract.title}
                </Text>
            </div>
            <div className={classes.downloadLibraryTemplate}>
                {hoveredContract && (
                    <Button
                        className={classes.downloadLibraryTemplateButton}
                        onClick={downloadTemplate}
                    >
                        <Download height={13} width={13} fill="#fff" />
                        <Text color="#fff" varient="subText">
                            Download
                        </Text>
                    </Button>
                )}
            </div>
        </div>
    )
}

export default StaticTemplates
