import React from "react";
import PrioritySymbol from "../PrioritySymbol/PrioritySymbol";
import RoundedSymbol from "../RoundedSymbol/RoundedSymbol";
import Polygon from "./icons/Polygon.svg";
import "./DropDownSelectedItem.scss";

/**
 * For Create Dropdown Selected Box Use This DropDownSelectedItem Component
 *
 * This is Used in DropdownContainer Component
 *
 * @param {Object} selectedItem
 * @param {Boolean} showDropDown
 * @param {Function} setShowDropDown
 * @param {Boolean} showOnlyIconInSelect
 * @param {String} selectedItemColor
 *
 */

const DropDownSelectedItem = ({
  selectedItem,
  showDropDown,
  setShowDropDown,
  showOnlyIconInSelect,
  selectedItemColor,
}) => {
  const { name, priorityColor, roundedSymbol } = selectedItem;
  // Function for close dropdown when click outside
  const onClickOutsideListener = () => {
    document.removeEventListener("click", onClickOutsideListener);
    setShowDropDown(false);
  };

  return (
    <div
      className="dropdown-selected-box"
      onClick={() => setShowDropDown(!showDropDown)}
      onMouseLeave={() => {
        document.addEventListener("click", onClickOutsideListener);
      }}
    >
      {priorityColor && <PrioritySymbol color={priorityColor} />}
      {roundedSymbol && <RoundedSymbol {...roundedSymbol} />}
      <p style={{ color: selectedItemColor && selectedItemColor }}>
        {!showOnlyIconInSelect && name}
      </p>
      <img src={Polygon} alt="dorp" style={{transform: `rotate(${showDropDown ? "90deg" : "0deg"}`}} />
    </div>
  );
};

export default DropDownSelectedItem;
