import { SIDEBAR_ITEM, TEXT_BOX } from "Pages/PdfEditor/container/Constants"
import DragItem from "Pages/PdfEditor/container/DragItem"
import React, { useState } from "react"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { createUseStyles } from "react-jss"
import CustomDragLayer from "Pages/PdfEditor/container/CustomDragLayer"
import { setActiveTool } from "Action/signAction"
import { connect } from "react-redux"
import { DRAFT } from "Types"

const useStyles = createUseStyles(() => ({
    userSignImg: {
        flex: 1,
        width: "100%",
        height: "100%",
        // maxHeight: 75,
        border: "1px solid #7FD1DE",
        borderRadius: 6,
        objectFit: "cover",
    },
}))

function addField(sign) {
    return setActiveTool({
        top: 0,
        left: 0,
        mediaPath: sign.signUrl,
        ...sign,
    })
}

const DragUserSign = ({ sign, status, ...props }) => {
    const classes = useStyles()
    const [elementOffset, setElementOfset] = useState(null)
    const dragSignRef = React.useRef(null)

    if (status !== DRAFT)
        return (
            <div
                style={{
                    width: "100%",
                    marginRight: "1.5rem",
                    marginLeft: "'0.5rem",
                }}
            >
                <img className={classes.userSignImg} src={sign.signUrl} />
            </div>
        )

    return (
        <>
            <DragItem
                data={{
                    id: 1,
                    type: SIDEBAR_ITEM,
                    component: {
                        type: TEXT_BOX,
                        content: null,
                    },
                    elementOffset,
                    setToolSignerField: () => addField(sign),
                    setElementOfset,
                    height: dragSignRef.current?.getBoundingClientRect().height,
                    width: dragSignRef.current?.getBoundingClientRect().width,
                }}
                style={{
                    width: "100%",
                    marginRight: "1.5rem",
                    marginLeft: "'0.5rem",
                }}
            >
                <img
                    className={classes.userSignImg}
                    style={{ cursor: "all-scroll" }}
                    ref={dragSignRef}
                    onMouseDown={(e) => {
                        const rect = dragSignRef.current.getBoundingClientRect()
                        const x = e.clientX - rect.left
                        const y = e.clientY - rect.top
                        setElementOfset({ x, y })
                    }}
                    src={sign.signUrl}
                />
            </DragItem>
            <CustomDragLayer />
        </>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    status: signReducer.status,
})

export default connect(mapStateToProps)(DragUserSign)
