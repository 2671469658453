import React, { useEffect } from "react"
import Grid from "@awesomesuite-frontend/awesome-nebula/dist/Grid"
import Text from "@awesomesuite-frontend/awesome-nebula/dist/Text"
import { addDocument } from "Action/documentAction"
import usePdfHook from "hooks/PdfLib/PdfHook"
import { useHistory } from "react-router-dom"
import { userLoginWithAccountByPass } from "Action/userAction"

const Addons = (props) => {
    const { loadedLib, loadPdfLib, loadPdf } = usePdfHook()
    const history = useHistory()

    const initAddonToApp = async () => {
        const search = props.location.search
        let parsedUrl = new URLSearchParams(search)

        //In case user is logged out or don't have workspace and all
        const token = parsedUrl.get("token")
        if (token) await userLoginWithAccountByPass(token, true)

        if (!parsedUrl.get("url")) {
            history.push("/")
        }

        let fileDetails = {
            name: parsedUrl.get("name"),
            mediaId: parsedUrl.get("name"),
            mediaPath: parsedUrl.get("url"),
        }

        startDocumentSigning(fileDetails)
    }

    useEffect(() => {
        initAddonToApp()
    }, [])

    async function getNumberOfPages(pdfFile) {
        if (!loadedLib) {
            await loadPdfLib()
        }
        const res = await loadPdf(pdfFile)
        return res.numberOfPages
    }

    async function startDocumentSigning(fileDetails) {
        try {
            // setloading(true)
            const pages = await getNumberOfPages(fileDetails.mediaPath)
            const response = await addDocument(
                fileDetails.name,
                fileDetails.mediaId,
                fileDetails.mediaPath,
                pages
            )
            if (response) {
                history.push(`/pdf-editor/${response.model.fileId}`)
            }
        } catch (error) {
            console.log(error)
        }
        // setloading(false)
    }

    return (
        <Grid container height={"100vh"} align="center" justify="center">
            <Grid item xs={9} sm={3}>
                <Text varient="h6" align="center">
                    Preparing Your document...
                </Text>
            </Grid>
        </Grid>
    )
}

export default Addons
