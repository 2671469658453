import React from "react";
import "./SideBarNameIcon.scss";

import { NavLink } from "react-router-dom";

const SideBarNameIcon = ({ Pic, name, link, theme, action, isActive }) => {
    if (link !== null) {
        return <NavLink
            to={link}
            exact
            activeClassName={`type-name-${theme}-active`}
            className={`side-nav-type-name name-type-${theme}`}
        >
            <div className="name-type-icon">{Pic && <Pic />}</div>
            <div className="name-type-text">
                <span>{name}</span>
            </div>
        </NavLink>
    }

    return (
        <div
            onClick={action}
            activeClassName={`type-name-${theme}-active`}
            className={`side-nav-type-name name-type-${theme} ${
                isActive && `type-name-${theme}-active`
            }`}
        >
            <div className="name-type-icon">{Pic && <Pic />}</div>
            <div className="name-type-text">
                <span>{name}</span>
            </div>
        </div>
    );
};

export default SideBarNameIcon;