import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import React from "react"
import { Link } from "react-router-dom"
import BetaTag from "Awesome-ui/BetaTag/BetaTag"
import { ReactComponent as Settings } from "../../Assets/NavigationIcon/settings.svg"
import { ReactComponent as Help } from "../../Assets/NavigationIcon/help.svg"
import { ReactComponent as TrustCenter } from "Assets/NavigationIcon/certificate-tab.svg"
import { connect } from "react-redux"

const RightLinks = ({
    setOpenNotifications,
    setOpenHelp,
    daysLeft,
    showUpgrade,
}) => {
    return (
        <>
            {/* {daysLeft !== null && (
                <div className="days">
                    <div className="circle">
                        <div>
                            <Text varient="subText" color="#3896e3">
                                {daysLeft}
                            </Text>
                        </div>
                    </div>
                    <Text
                        color="#99acc2"
                        varient="subText"
                    >{` days left in demo`}</Text>
                </div>
            )} */}
            {/* {showUpgrade && (
                <Button
                    size={"small"}
                    fontWeight="600"
                    style={{ marginRight: "1rem", color: "#33475B" }}
                    round
                    color="#ebf1f8"
                    className="upgrade-btn"
                    onClick={showUpgradePlan}
                >
                    <div className="upgrade-button">
                        <Text fontSize={12}>Upgrade</Text>
                        <UpgradeIcon />
                    </div>
                </Button>
            )} */}
            {/* <div>
                <Guard scope="document_create">
                    <AddDocument />
                </Guard>
            </div> */}
            <div className="trust-center-nav-icon">
                <TooltipWrapper text={"Sign Trust Center"} direction={"bottom"}>
                    <Link to="/trust-center">
                        <TrustCenter width={"1.5rem"} />
                    </Link>
                </TooltipWrapper>
            </div>
            <div className="nav-icon">
                <TooltipWrapper text={"Settings"} direction={"bottom"}>
                    <Link to="/settings">
                        <Settings width={"1.5rem"} />
                    </Link>
                </TooltipWrapper>
            </div>
            <div className="nav-icon">
                <TooltipWrapper text={"Help"} direction={"bottom"}>
                    <Help
                        style={{ marginBottom: "-2px", cursor: "pointer" }}
                        onClick={() => setOpenHelp(true)}
                    />
                </TooltipWrapper>
            </div>
            {/* <div
                className="nav-icon"
                onClick={() => setOpenNotifications((prev) => !prev)}
            >
                <NotificationBell />
            </div> */}
        </>
    )
}

const mapStateToProps = ({ subscriptionReducer }) => ({
    daysLeft: subscriptionReducer.daysLeft,
})

export default connect(mapStateToProps)(RightLinks)
