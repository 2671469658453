import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles((theme) => ({
    trustCenterWrapper: {
        padding: "2rem 0",
    },
    trustCenterHeader: {
        alignItems: "stretch",
    },
    trustCenterHeaderIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        marginRight: "1rem"
    },
    trustCenterHeaderContent:{
        marginLeft: "1rem",
    },
    trustCenterContents: {
        marginTop: "2rem",
        borderRadius: 29,
        border: "1px solid #CBD6E2",
        backgroundColor: "#FFFFFF",
        justifyContent: "space-evenly",
        padding: "2rem 0",
    },
    trustCenterContent: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    }
}));