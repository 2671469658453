import {
    ADD_DOCUMENT,
    ADD_SIGNERS_TO_DOC,
    DELETE_DOCUMENT,
    GET_DOCUMENTS_RECIEVED_FOR_SIGN,
    GET_DOCUMENTS_WITH_STATUS,
    GET_DOCUMENTS_WITH_STATUS_FAILED,
    GET_DOCUMENT_BY_ID,
    GET_DOCUMENT_WITH_STATUS_LOADING,
    GET_LIST_OF_DOCUMENTS,
    GET_LIST_OF_DOCUMENTS_FAILED,
    GET_LIST_OF_DOCUMENT_LOADING,
    REJECT_DOCUMENT_SIGNING,
    START_DOCUMENT_SIGNING,
    UPDATE_DOCUMENT_DETAILS,
    UPDATE_DOCUMENT_DETAILS_FAILED,
    UPDATE_DOCUMENT_DETAILS_LOADING,
} from "ActionTypes/documentType"
import { ADD_DOCUMENT_TO_SPECIFIC_FOLDER } from "ActionTypes/foldersActionType"

import { API, eSignApi, mediaApi } from "Apis/AllApisMapping"
import { folderIcons } from "data/folderIcons"
import { Request } from "Library/RequestHandler/RequestHandler"
import {
    CANCELLED,
    DANGER,
    DOCUMENT,
    RECIEVED_FOR_SIGNATURE,
    SUCCESS,
    TEMPLATE,
} from "Types"
import { store } from "../store"
import { getHeaders } from "./ActionHelper"
import { addNewFolder } from "./foldersAction"
import { createMessage } from "./messageAndNotificationActions"
import { getSignerBackgroundDecorum } from "Library/randomBackgroundGenerator"
import { SignatureDragType } from "Components/PDFCommons/container/Types"
import { v4 as uuidv4 } from "uuid"
import axios from "axios"

const { dispatch, getState } = store

/**
 * get Documents details by id.
 * it return the details of the document nd join details of the signes attached to it.
 * @param {string} documentId
 */
export const getDocumentById = async (documentId) => {
    if (!documentId) return

    try {
        const headers = getHeaders()
        const documentResp = await eSignApi.get(
            `/document?documentId=${documentId}`,
            headers
        )

        if (documentResp.data.model) {
            dispatch({
                type: GET_DOCUMENT_BY_ID,
                payload: { document: documentResp.data.model },
            })
            return documentResp.data.model
        }
    } catch (error) {
        console.log("something went wrong in the get document request.")
        console.log(error)
    }
}

/**
 * get Documents details by id
 * NOTE: this is a public version of the document gives only documents details
 * @param {string} documentId
 */
export const getDocumentByIdPublic = async (documentId) => {
    if (!documentId) return

    try {
        const documentResp = await eSignApi.get(
            `/public/document?token=${documentId}`
        )
        const data = documentResp.data?.model
        if (data) {
            dispatch({
                type: GET_DOCUMENT_BY_ID,
                payload: { document: data.document, signer: data.signer },
            })
            return documentResp.data.model
        } else window.location = "/sign/404/document-not-found"
    } catch (error) {
        console.log("something went wrong in the get document request.")
        console.log(error)
    }
}

/**
 * get the list of active documents for authenticated user.
 * @example 
 * ```
 * [
    {
        "id": 2,
        "fileId": "2398ujdfdf-sd98ujiosdfmso-sddfu90sdfij",
        "fileName": "Roofe contract",
        "version": 1,
        "tag": "EMPLOY_CONTRACT",
        "isEnabled": true,
        "mediaId": "aspokfmeoijsidfjfsd",
        "mediaUrl": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1632156139esigntest2.pdf",
        "userId": 1,
        "createdAt": null,
        "updatedAt": null
    },
  ]
 * ```
 * @returns
 */
export const getAllDocuments = async () => {
    const { userReducer } = getState()
    const userDetails = userReducer.userDetails

    if (!userDetails) return null
    dispatch({ type: GET_LIST_OF_DOCUMENT_LOADING })

    try {
        const headers = getHeaders()
        const response = await eSignApi.get(
            `/allDocument?userId=${userDetails.id}`,
            headers
        )

        if (response.data.model)
            dispatch({
                type: GET_LIST_OF_DOCUMENTS,
                payload: { documents: response.data.model },
            })
    } catch (error) {
        dispatch({ type: GET_LIST_OF_DOCUMENTS_FAILED })
        console.log("something went wrong while making request")
        console.log(error)
    }
}

/**
 * get the list of active documents for authenticated user.
 * 
 * @param status status of the document [ OUT_FOR_SIGNATURE, FINISHED, DRAFT]
 * 
 * @example 
 * ```
 * [
    {
        "id": 2,
        "fileId": "2398ujdfdf-sd98ujiosdfmso-sddfu90sdfij",
        "fileName": "Roofe contract",
        "version": 1,
        "tag": "EMPLOY_CONTRACT",
        "isEnabled": true,
        "mediaId": "aspokfmeoijsidfjfsd",
        "mediaUrl": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1632156139esigntest2.pdf",
        "userId": 1,
        "createdAt": null,
        "updatedAt": null
    },
  ]
 * ```
 * @returns
 */
export const getAllDocumentsWithStatus = async (status) => {
    //TODO: Filter with user id
    // const { userReducer } = getState()
    // const userDetails = userReducer.userDetails

    const { currentWorkspace } = getState().workspaceReducer
    if (!currentWorkspace || !currentWorkspace.brands) return { content: null }

    dispatch({ type: GET_DOCUMENT_WITH_STATUS_LOADING })

    // if (!userDetails) return null

    try {
        const headers = getHeaders()
        headers.params = {
            // userId: userDetails.id,
            workspaceId: currentWorkspace.id,
            key: status,
        }
        let folderIds = ""
        if (currentWorkspace?.brands?.length > 0) {
            folderIds = currentWorkspace?.brands
                ?.map((brand) => brand.brandId)
                .join(",")
            headers.params.folderId = folderIds
        }
        const response = await eSignApi.get(`/allDocument`, headers)

        if (response.data.model) {
            dispatch({
                type: GET_DOCUMENTS_WITH_STATUS,
                payload: { documents: response.data.model, status },
            })
            return response.data.model
        }
    } catch (error) {
        dispatch({ type: GET_DOCUMENTS_WITH_STATUS_FAILED })
        console.log("something went wrong while making request")
        console.log(error)
    }
}

export const getAllDocumentsRecievedForSignature = async () => {
    const { userReducer, workspaceReducer } = getState()
    const userDetails = userReducer.userDetails
    const { currentWorkspace } = workspaceReducer
    dispatch({ type: GET_DOCUMENT_WITH_STATUS_LOADING })

    if (!userDetails || !currentWorkspace) return null

    try {
        const headers = getHeaders()
        const response = await eSignApi.get(
            `/document/receivedForSign?userId=${userDetails.id}`,
            headers
        )

        let docs = response.data.model

        docs.content = docs.content.map((doc) => ({ ...doc, isRecieved: true }))

        if (docs) {
            dispatch({
                type: GET_DOCUMENTS_RECIEVED_FOR_SIGN,
                payload: { documents: docs },
            })
            return docs
        }
    } catch (error) {
        dispatch({ type: GET_DOCUMENTS_WITH_STATUS_FAILED })
        console.log("something went wrong while making request")
        console.log(error)
    }
}

const findDefaultFolderId = async (folders) => {
    const defaultFolder = folders.find((folder) => folder.name === "My Documents")
    let defaultFolderId = 0

    if (!defaultFolder) {
        const res = await addNewFolder({
            folderName: "My Documents",
            icon: folderIcons[0].name,
        })
        defaultFolderId = res.id
    } else defaultFolderId = defaultFolder.brandId
    return defaultFolderId
}

export const finishDocumentInS3 = (mediaId) => {
    const headers = getHeaders()
    try {
        mediaApi.post(
            `/media/s3/finish?media_id=${mediaId}`,
            {},
            headers
        )
        return true
    } catch (err) {}
}

export const addDocumentToS3 = async (fileBlob, fileName, fileSize) => {
    const headers = getHeaders()
    const randomId = uuidv4()
    const { currentWorkspace } = getState().workspaceReducer
    if (!currentWorkspace)
        return createMessage(
            "Failed to upload document!",
            "User doesn't have a workspace.",
            DANGER
        )
    const workspaceId = currentWorkspace.id
    const data = {
        mediaName: `${randomId}_${fileName}`,
        mediaType: "pdf",
        relatedId: workspaceId,
        productId: 2,
        size: fileSize,
    }
    try {
        const presignedURLResponse = await mediaApi.post("/media/aws", data, headers)
        const presignedURLResponseData = presignedURLResponse.data.model
        await axios.put(presignedURLResponseData.url, fileBlob)
        finishDocumentInS3(presignedURLResponseData.mediaId)
        return presignedURLResponseData
    } catch (err) {
        createMessage("Failed to upload document!", null, DANGER)
        console.log(err)
    }
}

/**
 * add the document to the table for user
 */
export const addDocument = async (
    fileName,
    mediaId,
    mediaPath,
    pages = 0,
    folderId = -1,
    type = DOCUMENT
) => {
    const { userReducer, workspaceReducer } = getState()
    const userDetails = userReducer.userDetails
    const { currentWorkspace } = workspaceReducer

    if (!userDetails) return null;
    const brands = currentWorkspace?.brands;
    if (!currentWorkspace || !brands)
        return createMessage(
            "Failed to upload document!",
            "User doesn't have a workspace.",
            DANGER
        );

    let defaultFolderId = 0;

    if (folderId === -1) {
        defaultFolderId = await findDefaultFolderId(brands);
    }

    const data = {
        userId: userDetails.id,
        mediaId: mediaId,
        fileName: fileName,
        mediaUrl: mediaPath,
        tag: null,
        type: type,
        view: type === TEMPLATE ? DOCUMENT : null,
    }
    if (pages) data.pages = pages
    if (folderId > -1) data.folderId = folderId
    else data.folderId = defaultFolderId

    const headers = getHeaders()

    try {
        const response = await eSignApi.post("/document", data, headers)
        if (response.data.model) {
            dispatch({
                type: ADD_DOCUMENT,
                payload: { fileData: response.data.model, folderId: data.folderId },
            })
            if (folderId !== -1)
                dispatch({
                    type: ADD_DOCUMENT_TO_SPECIFIC_FOLDER,
                    payload: {
                        fileData: response.data.model,
                        folderId: data.folderId,
                    },
                })
            createMessage("Document uploaded Successfully!", null, SUCCESS)
            return response.data
        }
    } catch (error) {
        createMessage("Failed to upload document!", null, DANGER)
        console.log("got error while adding the document.")
        console.log(error)
    }

    return null
}

/**
 * delete the document form the store with the given document id
 *
 * @param {*} fileId - file id.
 */
export const deleteDocument = async (fileId, tag, folderId) => {
    if (!fileId) return
    const headers = getHeaders()

    try {
        const response = await eSignApi.delete(`/document?fileId=${fileId}`, headers)
        if (response.data) {
            dispatch({ type: DELETE_DOCUMENT, payload: { fileId, tag, folderId } })
            createMessage("Document deleted Successfully !", null, SUCCESS)
        }
    } catch (error) {
        createMessage("Failed to delete document !", null, DANGER)
        console.log("got error while adding the document.")
        console.log(error)
    }
}

/**
 * update document details like file name , tag ( folder)
 * @param fileId    file id
 * @param fileName  file name
 * @param tag       tag
 */
export const updateDocument = async ({
    fileId,
    fileName = null,
    tag = null,
    view,
}) => {
    if (!fileId) return

    dispatch({ type: UPDATE_DOCUMENT_DETAILS_LOADING })

    const data = {
        fileId,
        fileName: fileName,
        tag: tag,
        view,
    }

    const headers = getHeaders()

    try {
        const response = await eSignApi.put("/document", data, headers)

        if (response.data.model) {
            dispatch({ type: UPDATE_DOCUMENT_DETAILS, payload: { data } })
            createMessage("Document saved Successfully !", null, SUCCESS)
        }
    } catch (error) {
        console.log("got error while adding the document.")
        console.log(error)
        dispatch({ type: UPDATE_DOCUMENT_DETAILS_FAILED })
        createMessage(
            "Failed to update the document!",
            "Something went wrong. Could not save the changes.",
            DANGER
        )
    }
}

/**
 * start the document signature request from other signers.
 * @param {*} documentId
 */
export const startDocumentSinature = async (
    documentId,
    type,
    enableTemplateFlow,
    annotations,
) => {
    try {
        const headers = getHeaders()
        const data = getState().signReducer

        if (!enableTemplateFlow) {
            if (!data.signers || data.signers?.length < 1)
                return createMessage(
                    "No signer found.",
                    "Please add atleast one signer to send the document for signature.",
                    DANGER
                )
            data.signers.forEach((element) => {
                if (!element.fields || element.fields?.length < 1) {
                    throw new Error(
                        "Can not send for the signature signer don't have a field."
                    )
                }
                if (
                    !element.fields.find(
                        (field) => field.type === SignatureDragType.SIGNER_FIELD
                    )
                )
                    throw new Error(
                        "Can not send for the signature signer don't have a signature field."
                    )
            })
        } else {
            if (!data.signature || Object.keys(data.signature).length < 1)
                return createMessage(
                    "No signature found.",
                    "Please add atleast one signature for each roles associated with the signers",
                    DANGER
                )
            const roleIdsOfFields = new Set()
            Object.keys(data.signature).forEach((key) => {
                const elementsInPage = data.signature[key]
                if (!elementsInPage) return
                Object.values(elementsInPage).forEach((element) => {
                    roleIdsOfFields.add(element.data.roleId)
                })
            })
            const roleIdsOfFieldsArray = Array.from(roleIdsOfFields)
            data.signers.forEach((signer) => {
                if (!roleIdsOfFieldsArray.includes(signer.roleId)) {
                    throw new Error(
                        "Can not send for the signature signer don't have a field."
                    )
                }
            })
        }

        startDocumentLoading()
        headers.params = { id: documentId, type: type }
        const result = await eSignApi.post(
            "/sign/start", 
            { annotationElementList: annotations }, 
            headers
        )
        if (result.data.statusCode === 200) {
            dispatch({ type: START_DOCUMENT_SIGNING })
            createMessage(
                "Document Sent for signature !",
                "document is send to be signed by all the signers. you can come back later to check the signer status.",
                SUCCESS
            )
            return 1
        } else throw new Error("Sent For Signature api failed")
    } catch (error) {
        console.log(error)
        console.log("Could not start the document signature.")
        stopDocumentLoading()
        createMessage(
            "Sent for signature failed !",
            "Could not send document for signature. Please check the signer or fields",
            DANGER
        )
    }
}

export const startDocumentLoading = () => {
    dispatch({ type: UPDATE_DOCUMENT_DETAILS_LOADING })
}

export const stopDocumentLoading = () => {
    dispatch({ type: UPDATE_DOCUMENT_DETAILS_FAILED })
}

export const paginationRequest = async (
    userId,
    page = 1,
    key = "",
    sort = "",
    folderId = "",
    addIdemId = "",
    size = 10,
    fileName = "",
    type = DOCUMENT
) => {
    const headers = getHeaders()
    const { currentWorkspace } = getState().workspaceReducer
    if (!currentWorkspace || !currentWorkspace.brands) return { content: null }
    const params = { size, page: page - 1, workspaceId: currentWorkspace.id, type }
    if (sort) params.sort = sort
    if (fileName) params.fileName = fileName
    if (folderId !== "") params.folderId = folderId
    else if (key !== RECIEVED_FOR_SIGNATURE && type !== TEMPLATE) {
        let folderIds = ""
        if (currentWorkspace?.brands?.length > 0) {
            folderIds = currentWorkspace?.brands
                ?.map((brand) => brand.brandId)
                .join(",")
            params.folderId = folderIds
        }
    }

    let path = "allDocument"
    if (key) {
        if (key !== RECIEVED_FOR_SIGNATURE) {
            params.key = key
        } else {
            path = "document/receivedForSign"
            if (params.userId) params.userId = params.userId.toString()
            else params.userId = getState().userReducer.userDetails.id
            delete params["workspaceId"]
        }
    }

    const req = new Request(
        `${API.ESGIN_API}/${path}`,
        "get",
        headers.headers,
        `documents-${page}-${size}-${key}-${sort}-${folderId}-${addIdemId}-${fileName}`
    )
    req.withParams(params)
    try {
        let res = await req.makeRequest()

        if (!res) throw new Error("No response from server")

        if (key === RECIEVED_FOR_SIGNATURE) {
            res.model.content = res.model.content.map((item) => ({
                ...item,
                isRecieved: true,
            }))
        }
        return res.model
    } catch (err) {
        console.log(err)
        if (err !== "cancle the request")
            createMessage(
                "Failed to fetch documents !",
                "Something went wrong while fetching documents. Please try again later.",
                DANGER
            )
        return { content: [] }
    }
}

export async function getAllPreviousTags(limit = 5) {
    const { id } = getState().userReducer.userDetails
    if (!id) return

    try {
        const headers = getHeaders()
        const res = await eSignApi.get(`/document/tags?id=${id}&n=${limit}`, headers)
        if (res.data.statusCode === 200) return res.data.model.tags
        else throw new Error("Unable to Fetch tags")
    } catch (err) {
        createMessage(err.message, null, DANGER)
        return []
    }
}

export async function rejectDocument(documentId) {
    if (!documentId) return
    try {
        const headers = getHeaders()
        const res = await eSignApi.put(
            `/document/action?id=${documentId}&documentStatus=${CANCELLED}`,
            {},
            headers
        )
        if (res.data.statusCode === 200) {
            dispatch({ type: REJECT_DOCUMENT_SIGNING })
            createMessage("Document has been rejected successfully", null, SUCCESS)
            return res.data.model
        } else throw new Error("Unable to reject document")
    } catch (err) {
        createMessage(err.message, null, DANGER)
        return null
    }
}

export async function deleteMultipleDocuments(documentIds = []) {
    if (documentIds.length < 1) return
    const headers = getHeaders()

    let fileIds = ""
    documentIds.forEach((id, i) => {
        fileIds += id
        if (i !== documentIds.length - 1) fileIds += ","
    })

    try {
        const response = await eSignApi.delete(
            `/document?fileId=${fileIds}`,
            headers
        )
        if (response.data) {
            createMessage("Documents deleted Successfully !", null, SUCCESS)
            return response.data.statusCode
        } else throw new Error("Error while deleting")
    } catch (error) {
        createMessage("Failed to delete documents !", null, DANGER)
        console.log("got error while deleting the documents.")
        console.log(error)
    }
}

export async function getDocSigners(fileId) {
    const headers = getHeaders()

    try {
        const response = await eSignApi.get(`/document/${fileId}/signers`, headers)
        if (response.data?.statusCode !== 200) return null
        dispatch({
            type: ADD_SIGNERS_TO_DOC,
            payload: {
                signers: response.data.model.map((signer, id) => ({
                    ...signer,
                    backgroundDecorum: getSignerBackgroundDecorum(
                        (signer.firstName[0] + signer.lastName[0]).toLowerCase(),
                        id + 1
                    ),
                })),
            },
        })
        return response.data.model
    } catch (error) {
        console.log(error)
        createMessage("Could not get the signers in document!", null, DANGER)
    }
}

export async function convertTemplateToDocument(fileId, isPredifinedTemplate) {
    const headers = getHeaders()
    let defaultFolderId = null
    if (isPredifinedTemplate) {
        const { currentWorkspace } = getState().workspaceReducer
        defaultFolderId = await findDefaultFolderId(currentWorkspace.brands)
        console.log(defaultFolderId)
    }
    if (defaultFolderId) headers.params = { defaultFolderId }

    try {
        const response = await eSignApi.put(
            `/document/convert?fileId=${fileId}`,
            {},
            headers
        )
        if (response.data?.statusCode !== 200) return null
        return response.data.model
    } catch (error) {
        console.log(error)
    }
}
