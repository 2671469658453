import { createUseStyles } from "react-jss"

export const usePickerStyles = createUseStyles(() => ({
    mediaPicker: {
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        padding: "12px 0",
        backgroundColor: "#fff",
    },
    pageTitle: {
        fontSize: "1rem",
        fontWeight: 600,
        marginLeft: "14rem",
        marginBottom: "1rem",
    },

    imgCol: {
        width: "100%",
        padding: "0px 12px",
    },

    imageContent: {
        marginTop: "1rem",
        height: "100%",
        maxHeight: "100%",
        overflowY: "auto",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        scrollbarWidth: "thin",
        overflow: "hidden auto",
        paddingBottom: 50,

        "&::-webkit-scrollbar": {
            height: 8,
            width: 8,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
            borderRadius: 20,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#b6b6b6",
            borderRadius: 20,
        },
    },

    loaderRef: {
        width: "100%",
    },
    mediaSearch: {
        padding: "0 1rem",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        gap: "1rem",
    },
    mediaBtn: {
        cursor: "pointer",
        padding: "12px 16px",
        height: "100%",
        backgroundColor: "black",
        color: "#fff !important",
        lineHeight: "135%",
        borderRadius: "4px",
    },
    card: {
        width: "100%",
        position: "relative",
    },

    unsplasCreator: {
        padding: "12px 14px",
        position: "absolute",
        top: 0,
        right: 0,
        background:
            "linear-gradient(181deg, rgba(15, 15, 15, 084) 204%, rgba(255, 255, 255, 0) 952%)",
    },
    card_picture: {
        width: "100%",
        flex: "100%",
        backgroundColor: "coral",
        marginTop: "1rem",
    },

    imageSelectBtn: {
        position: "absolute",
        bottom: 10,
        right: 10,
        zIndex: 1,
    },

    finalCheckoutModel: {
        "& p": {
            margin: 0,
            fontWeight: 600,
        },
    },
    "& btn": {
        cursor: "pointer",
        width: 100,
        height: 40,
        backgroundColor: "black",
        color: "#fff !important",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& span": {
            color: "#fff !important",
        },
    },

    mediaContent: {
        height: "93%",
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
    },
    mediaContentWrapper: {
        position: "relative",
        height: "100%",
        maxHeight: "100%",
    },
}))
