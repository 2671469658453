import React from "react"
import { useStyles } from "./MediaLibraryStyle"
import MediaLibraryTopBar from "./MediaLibraryTopBar"
import { useState } from "react"
import MediaLibraryLeft from "./MediaLibraryLeft"
import MediaLibraryRight from "./MediaLibraryRight"

export const ReactContext = React.createContext({})
const staticValidatingParameters = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/x-icon",
    "image/gif",
    "image/svg",
    "image/svg+xml",
    "image/webp",
    "video/mp4",
    "video/ogg",
    "video/webm",
]

const initFiles = {
    upload: [],
    library: [],
    unsplash: [],
    tenor: [],
}

/**
 *
 * @param {*} userToken token of the user logged in, mandatory
 * @param {Function} setFiles   on change files handler to get back the response
 * @param {boolean} isShow show the component or not
 * @param {Function} setIsShow function to handle visibility of the component
 * @param {boolean} showTopBar  show the top bar or not
 * @returns {Array} array of files selected and continued with
 */

const MediaLibrary = ({
    userToken,
    setFiles = () => {},
    isShow = true,
    setIsShow = () => {},
    showTopBar = true,
    height,
    width,
    singleFile,
    validatingParameters = staticValidatingParameters,
    validationSize,
    GoogleDrive = true,
    DropBox = true,
    OneDrive,
    Box,
    onContinue,
    GIFPicker = true,
    UnsplashPicker = true,
    editImage,
    onCancel = () => {},
    ...props
}) => {
    const [selectedTab, setSelectedTab] = useState(1)
    const [handlingFiles, setHandlingFiles] = useState(initFiles)
    const classes = useStyles({ height, width })
    if (!isShow) return <></>
    return (
        <ReactContext.Provider
            value={{
                styles: { height, width },
                classes,
                selectedTab,
                setSelectedTab,
                singleFile,
                validatingParameters,
                validationSize,
                setFiles,
                handlingFiles,
                setHandlingFiles,
                userToken,
                GoogleDrive,
                DropBox,
                OneDrive,
                Box,
                initFiles,
                onContinue,
                GIFPicker,
                UnsplashPicker,
                editImage,
            }}
        >
            <div className={`${classes.container}`}>
                {showTopBar && <MediaLibraryTopBar onCancel={onCancel} />}
                <div className={`${classes.mainContainer}`} container>
                    <MediaLibraryLeft />
                    <MediaLibraryRight />
                </div>
            </div>
        </ReactContext.Provider>
    )
}

export default MediaLibrary
