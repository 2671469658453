import { store } from "../store"

import {
    ROLE_LOADING,
    ROLE_FAILED,
    ROLE_SUCCESS,
    ROLE_AUTH_FAILED,
} from "../ActionTypes/roleTypes"
import { createMessage } from "./messageAndNotificationActions"
import { getHeaders } from "./ActionHelper"
import { workspaceApi } from "Apis/AllApisMapping"
import { DANGER } from "Types"

const { dispatch } = store

export const getRoles = async () => {
    dispatch({ type: ROLE_LOADING })

    try {
        const headers = getHeaders()
        const res = await workspaceApi.get(`/role`, headers)
        if (res.data.model) {
            dispatch({ type: ROLE_SUCCESS, payload: { role: res.data.model } })
        } else {
            dispatch({ type: ROLE_AUTH_FAILED })
            createMessage(res.data.msg, null, DANGER)
        }
    } catch (err) {
        if (err.response) {
            dispatch({ type: ROLE_FAILED })
            createMessage(err.response.data.message, null, DANGER)
        }
    }
}

export const getRolesFromPermissions = async (role) => {
    dispatch({ type: ROLE_SUCCESS, payload: { role } })
    return role
}