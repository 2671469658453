import React from "react"
import Picker from "emoji-picker-react"

const EmojiPicker = React.memo(({ addEmoji, height }) => (
    <Picker
        onEmojiClick={addEmoji}
        disableAutoFocus={true}
        skinTonesDisabled
        lazyLoadEmojis
        previewConfig={{ showPreview: false }}
        height={height}
        width={"100%"}
    />
))

export default EmojiPicker
