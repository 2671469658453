import React, { useRef } from "react"
import { connect } from "react-redux"
import { medialApi } from "../../Apis/AllApisMapping"
import mediaHandler from "../../Library/mediaHandler/mediaHandler"
import "./SignComponent.scss"
import SignDrawer from "./components/Draw"
import TypeSignature from "./components/Type/TypeSignature"
import { ReactComponent as DrawSignatureIcon } from "Assets/ToolsIcons/signature.svg"
import { ReactComponent as TypeSignatureIcon } from "Assets/ToolsIcons/typing.svg"
import { createMessage } from "Action/messageAndNotificationActions"
import { DANGER, SIGNER } from "Types"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { addUserSign, setActiveTab } from "Action/signAction"
import { SignatureDragType } from "Components/PDFCommons/container/Types"

const DRAW = "Draw"
const TYPE = "Type"

const signComponentsType = [DRAW, TYPE]
const icons = {
    Draw: <DrawSignatureIcon height={25} width={25} fill="#7c98b6" />,
    Type: <TypeSignatureIcon height={25} width={25} />,
}

const initSign = {
    top: 0,
    left: 0,
}

const SignComponent = ({ editingMode, ...props }) => {
    const resultHandler = useRef()
    const typeResultHandler = useRef()
    const [type, setType] = React.useState(DRAW)

    async function uploadMedia(data) {
        if (!data) return

        const mediaService = new mediaHandler(medialApi)

        if (props.token) {
            return await mediaService.uploadMediaBlob(data, props.token, null)
        } else return await mediaService.uploadMediaBlobPublic(data, null)
    }

    async function getResultsFromTheComponent(e) {
        if (type === DRAW) {
            if (resultHandler.current.isCanvasBlank())
                return createMessage(
                    "Unable to submit sign",
                    "Please draw your sign first",
                    DANGER
                )
            const image = resultHandler.current.getResult()
            const response = await uploadMedia(image)

            if (editingMode === SIGNER) props.addSignatureHandler(response)
            else
                addUserSign({
                    mediaPath: response.mediaPath,
                    type: SignatureDragType.SIGN,
                })
        }
        if (type === TYPE) {
            const image = typeResultHandler.current.getResult()

            if (!image)
                return createMessage(
                    "Unable to submit sign",
                    "Please type your sign first",
                    DANGER
                )

            let response = await uploadMedia(image.data)

            if (!response) return

            if (editingMode === SIGNER)
                props.addTypeSignature({
                    ...response,
                    width: image.width,
                    height: image.height,
                })
            else
                addUserSign({
                    mediaPath: response.mediaPath,
                    type: SignatureDragType.TYPE,
                })
        }
        if (editingMode !== SIGNER) setActiveTab(4)
        props.close()
    }

    if (!props.open) return null

    return (
        <>
            <div className="create-sign-backdrop"></div>
            <div className="create-sign-wrapper">
                <div className="create-sign-top">
                    <div className="sideDrawer-close" onClick={props.close}>
                        +
                    </div>
                </div>
                <div className="create-sign-mid">
                    <div className="create-sign-tabs">
                        {signComponentsType.map((item, index) => (
                            <div
                                className={`create-sign-tab-item ${
                                    item === type && "tab-item-active"
                                }`}
                                key={index}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setType(item)
                                }}
                            >
                                <div className="menu-icon">{icons[item]}</div>
                                <Text>{item}</Text>
                            </div>
                        ))}
                    </div>
                    <div className="canvas-component-render">
                        {type === DRAW ? (
                            <SignDrawer ref={resultHandler} />
                        ) : (
                            <TypeSignature ref={typeResultHandler} />
                        )}
                    </div>
                </div>
                <div className="create-sign-bottom">
                    {/* <div
                        className="create-select-all-button">
                        <input type="checkbox" checked={isChecked} onClick={()=>setIsChecked(!isChecked)}/>
                        Fill All
                    </div> */}
                    <div
                        className="create-accept-button"
                        onClick={getResultsFromTheComponent}
                    >
                        <span>Accept and Sign</span>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ userReducer, signReducer }) => ({
    token: userReducer.token,
    editingMode: signReducer.editingMode,
})

export default connect(mapStateToProps)(SignComponent)
