import { Avatar, Text } from "@awesomesuite-frontend/awesome-nebula"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import useToggle from "hooks/ToggleHook"
import React, { useRef } from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as RemoveUser } from "Assets/settings/remove-user.svg"
import { ReactComponent as EditIcon } from "Assets/Storage/pencil-icon.svg"
import { connect } from "react-redux"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import { showWarningPopup } from "Action/messageAndNotificationActions"

const useStyles = createUseStyles(() => ({
    roleHandlerWrapper: {
        position: "relative",
    },
    roleHandlerOptionsWrapper: {
        position: "absolute",
        minWidth: 350,
        bottom: -9,
        right: 0,
        transform: "translate(25px, 100%)",
        background: "#fff",
        zIndex: 1000,
        border: "1px solid #CBD6E2",
        borderRadius: 9,
        boxShadow: "4px 4px 13px #CBD6E2",
        padding: "1.25rem 0",
    },
    roleHandlerArrow: {
        borderWidth: "0px 10px 10px 10px",
        borderStyle: "solid",
        borderColor: "transparent",
        borderBottomColor: "white",
        position: "absolute",
        bottom: -11,
        zIndex: 1001,
        right: "50%",
        transform: "translateX(50%)",
    },
    roleHandlerSubArrow: {
        borderWidth: "0px 11px 11px 11px",
        borderStyle: "solid",
        borderColor: "transparent",
        borderBottomColor: "#CBD6E2",
        position: "absolute",
        bottom: -10,
        right: "50%",
        transform: "translateX(50%)",
    },
    roleHandlerOption: {
        display: "flex",
        alignItems: "center",
        padding: "0.25rem 1rem",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#E5F5F8",
        },
    },
    roleHandlerOptionDetails: {
        padding: "0 0.75rem",
        flex: 1,
    },
    roleHandlerOptionEdit: {
        margin: "0 0.25rem",
        borderRadius: "50%",
        width: 30,
        aspectRatio: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "#CBD6E2",
        },
    },
    roleHandlerOptionEditDisabled: {
        opacity: 0.5,
        cursor: "not-allowed",
    },
    addMemberSwitch: {
        width: "100%",
        marginTop: "1rem",

        "& div": {
            textAlign: "left",
        },
    },
    addMemberSwitchContent: {
        display: "flex",
        textAlign: "center",
        transition: "all 0.3s ease-in-out",
        whiteSpace: "nowrap",
        color: "#7C98B6",
        fontSize: "0.9rem",
        alignItems: "center",
    },
    addMember: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "2rem",
        height: "2rem",
        borderRadius: "50%",
        border: "1px dashed #00bda5",
        color: "#00bda5",
        marginRight: "1rem",
        background: "white",
        transition: "all 0.3s ease",
        outline: "none",
        boxShadow: "none",
        marginLeft: "1rem",
        fontSize: 22,
        paddingTop: "2px",

        "&:hover": {
            color: "white",
            background: "#00bda5",
            borderColor: "#00bda5",
            borderStyle: "solid",
            outline: "none",
            boxShadow: "none",
        },
        "&:active": {
            outline: "none",
            boxShadow: "none",
        },
        "&:focus": {
            outline: "none",
            boxShadow: "none",
        },

        "@media (max-width: 992px)": {
            width: "2.25rem",
            height: "2.25rem",
        },
    },
    userInvitedStatus: {
        width: 10,
        height: 10,
        borderRadius: "50%",
        backgroundColor: "transparent",
        marginRight: "0.5rem",
    },
    userInvitedStatusPending: {
        backgroundColor: "#f5c26b",
    },
}))

const RoleHandler = ({
    users = [],
    children,
    addNewAvatar = () => {},
    deleteAvatar = () => {},
    setEditMemberModal = () => {},
    currentUserRoleId,
    currentUserEmailId,
    ...props
}) => {
    const classes = useStyles()
    const userListRef = useRef(null)
    const [visible, toggle] = useToggle(userListRef)
    const { splitText } = useBasicUtils()

    const removeUserHandler = (user) => {
        if (currentUserEmailId === user.email || currentUserRoleId !== 11) return
        showWarningPopup(
            "Delete this User?",
            "Are you sure you want to remove this user?",
            "Delete",
            () => deleteAvatar(user.id),
            "Cancel",
            () => {}
        )
    }

    return (
        <div className={classes.roleHandlerWrapper} onClick={() => toggle(!visible)}>
            {visible && (
                <div ref={userListRef}>
                    <div className={classes.roleHandlerArrow}></div>
                    <div className={classes.roleHandlerSubArrow}></div>
                    <div className={classes.roleHandlerOptionsWrapper}>
                        {users.map((user, key) => (
                            <div className={classes.roleHandlerOption} key={key}>
                                <TooltipWrapper
                                    text="User has not yet accepted the invitation"
                                    direction="left"
                                >
                                    <div
                                        className={`${classes.userInvitedStatus}
                                     ${
                                         user.isPending &&
                                         classes.userInvitedStatusPending
                                     }`}
                                    />
                                </TooltipWrapper>
                                <Avatar
                                    imageSource={user.imageSource}
                                    name={user.name}
                                    border="none"
                                    email={user.email}
                                    size={"xs"}
                                />
                                <div className={classes.roleHandlerOptionDetails}>
                                    <Text fontSize={15} weight={500}>
                                        {splitText(user.name, 23) || "No Username"}
                                    </Text>
                                    <Text
                                        varient={"subText"}
                                        fontSize={12}
                                        color={"light"}
                                    >
                                        {splitText(user.email, 23)}
                                    </Text>
                                </div>
                                <TooltipWrapper
                                    text={"Edit User"}
                                    wrapperClassName={`${
                                        classes.roleHandlerOptionEdit
                                    } ${
                                        (currentUserEmailId === user.email ||
                                            currentUserRoleId !== 11) &&
                                        classes.roleHandlerOptionEditDisabled
                                    }`}
                                    onClick={() => {
                                        if (
                                            currentUserEmailId === user.email ||
                                            currentUserRoleId !== 11
                                        )
                                            return
                                        setEditMemberModal(user)
                                    }}
                                >
                                    <EditIcon />
                                </TooltipWrapper>
                                <TooltipWrapper
                                    text={"Remove User"}
                                    wrapperClassName={`${
                                        classes.roleHandlerOptionEdit
                                    } ${
                                        (currentUserEmailId === user.email ||
                                            currentUserRoleId !== 11) &&
                                        classes.roleHandlerOptionEditDisabled
                                    }`}
                                >
                                    <RemoveUser
                                        onClick={() => removeUserHandler(user)}
                                    />
                                </TooltipWrapper>
                            </div>
                        ))}
                        <div className={classes.addMemberSwitch}>
                            <div>
                                <div className={classes.addMemberSwitchContent}>
                                    <button
                                        className={classes.addMember}
                                        onClick={addNewAvatar}
                                    >
                                        +
                                    </button>
                                    <Text fontSize={15} weight={500} color="light">
                                        Invite user to folder
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {children}
        </div>
    )
}

const mapStateToProps = ({ userReducer, workspaceReducer }) => ({
    currentUserEmailId: userReducer?.userDetails?.email_id,
    currentUserRoleId: workspaceReducer?.currentWorkspace?.roleId,
})

export default connect(mapStateToProps)(RoleHandler)
