import React from "react"
import { useDrag } from "react-dnd"

import "./dragItem.scss"

function onNamePritter(val) {
    let split = val.split("_")
    split = split.map((item) => {
        return item[0].toUpperCase() + item.substring(1).toLowerCase() + " "
    })
    return split
}

const DragItem = ({ data, style, children }) => {
    // data.component = children
    const [{ opacity }, drag] = useDrag({
        item: data,
        type: data.type,
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
    })

    if (data.disabled)
        return (
            <div style={{ opacity, ...style }} className="dragging-element">
                {children}
            </div>
        )

    return (
        <div ref={drag} style={{ opacity, ...style }} className="dragging-element">
            {/* <div className="item-icon"></div> */}
            {/* <div className="item-type">{onNamePritter(data?.component.type)}</div> */}
            {children}
        </div>
    )
}

export default DragItem
