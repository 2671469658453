import React from "react"
import TableRow from "Awesome-ui/TableRow/TableRow"
import TableCell from "Awesome-ui/TableCell/TableCell"
import { Avatar, CheckBox } from "@awesomesuite-frontend/awesome-nebula"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import { ReactComponent as FileIcon } from "Assets/Storage/filePdf.svg"
import { ReactComponent as More } from "Assets/Storage/more.svg"
import Guard from "Awesome-ui/Guard/Guard"
import useToggle from "hooks/ToggleHook"
import "Pages/Storage/Components/File/File.scss"
import { createUseStyles } from "react-jss"
import { initializeDocument } from "Action/signAction"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { deleteDocument } from "Action/documentAction"

const useStyles = createUseStyles({
    fileMenu: {
        position: "relative",
    },
    fileMenuOptions: {
        zIndex: 10,
        position: "absolute",
        top: 0,
        left: "-90px",
        maxWidth: "120px",
        borderRadius: "4px",
        width: "fit-content",
        backgroundColor: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    fileMenuIcon: {
        width: "30px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            borderRadius: "50%",
            backgroundColor: "aliceblue",
        },
    },
    fileMenuItem: {
        width: "100%",
        padding: "6px 1.5rem",
        textAlign: "center",
        cursor: "pointer",
        borderBottom: "1px solid rgb(226, 239, 250)",
        "&:hover": {
            backgroundColor: "rgb(226, 239, 250)",
        },
    },
    storeFolder: {
        display: "flex",
        alignItems: "center",
        "& svg": {
            marginRight: "1rem",
        },
    },
})

const DropDown = ({
    fileId,
    tag,
    openDocumentForView = () => {},
    folderId,
    onDeleteFile,
    status,
}) => {
    const dropdownRef = React.useRef(null)
    const [openDrop, setOpenDrop] = useToggle(dropdownRef)
    const classes = useStyles()

    async function handleDelete() {
        await deleteDocument(fileId, tag, folderId)
        await onDeleteFile()
        setOpenDrop(false)
    }


    return (
        <div className={classes.fileMenu}>
            <div
                className={classes.fileMenuIcon}
                onClick={() => setOpenDrop()}
                ref={dropdownRef}
            >
                <More />
            </div>
            {openDrop && (
                <div className={classes.fileMenuOptions}>
                    <Guard scope="document_delete" show>
                        <div className={classes.fileMenuItem} onClick={handleDelete}>
                            Delete
                        </div>
                    </Guard>
                    <Guard scope="document_update" show>
                        <div
                            className={classes.fileMenuItem}
                            onClick={openDocumentForView}
                        >
                            Edit
                        </div>
                    </Guard>
                </div>
            )}
        </div>
    )
}

const EachTemplate = ({
    template,
    selectFun,
    isChecked,
    userName,
    userEmail,
    userPicture = "",
    onDeleteFile = () => {},
}) => {
    const { namedDate, splitText, getDateAndTime } = useBasicUtils()
    const classes = useStyles()
    const history = useHistory()

    async function openDocumentForView() {
        initializeDocument(template)
        history.push(`/pdf-editor/${template.fileId}`)
    }
    return (
        <TableRow
            style={{
                border: isChecked ? "none" : "1px solid #d6dfe8",
                backgroundColor: `${isChecked ? "#E2F4FC" : "transparent"}`,
                outline: isChecked ? "1px solid #3DC1F2" : "none",
            }}
        >
            <TableCell align="left" width="60px">
                <CheckBox
                    active={isChecked}
                    clickFunction={selectFun}
                    color={"white"}
                    backgroundColor={"rgb(0, 189, 165)"}
                    variant={"curved"}
                    border={"lightblue"}
                />
            </TableCell>
            <TableCell>
                <TooltipWrapper
                    direction={"top"}
                    text={template.fileName}
                    style={{ wordBreak: "break-all" }}
                >
                    <div
                        className={classes.storeFolder}
                        style={{
                            cursor: "pointer",
                            position: "relative",
                            width: "100%",
                        }}
                        onClick={openDocumentForView}
                    >
                        <FileIcon width="24px" />
                        {splitText(template.fileName, 15)}
                        {template.pages && (
                            <div
                                style={{
                                    color: "#3FC2F2",
                                    marginLeft: "auto",
                                    marginRight: "1rem",
                                    fontWeight: "500",
                                }}
                            >
                                {template.pages} Pages
                            </div>
                        )}
                    </div>
                </TooltipWrapper>
            </TableCell>
            <TableCell align="center">
                <Avatar
                    name={userName}
                    email={splitText(userEmail, 20)}
                    isTooltip
                    imageSource={userPicture}
                    border="none"
                />
            </TableCell>
            <TableCell align="center" style={{ color: "#99ACC2" }}>
                <TooltipWrapper
                    text={`Created by ${userName} on ${getDateAndTime(
                        template.createdAt
                    )}`}
                >
                    {namedDate(template.createdAt)}
                </TooltipWrapper>
            </TableCell>
            <TableCell align="center" style={{ color: "#99ACC2" }}>
                <TooltipWrapper
                    text={`Last Updated by ${userName} on ${getDateAndTime(
                        template.updatedAt
                    )}`}
                >
                    {namedDate(template.updatedAt)}
                </TooltipWrapper>
            </TableCell>
            <TableCell width="5%">
                <Guard scope="document_settings_get">
                    <DropDown
                        openDocumentForView={openDocumentForView}
                        fileId={template.fileId}
                        tag={template.tag}
                        folderId={template.folderId}
                        onDeleteFile={onDeleteFile}
                        status={template.status}
                    />
                </Guard>
            </TableCell>
        </TableRow>
    )
}

export default EachTemplate
