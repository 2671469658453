import React, { useCallback } from "react"
import { useEffect } from "react"
import "./ProfileSettings.scss"
import Avatar from "../../../../Awesome-ui/Avatar/Avatar"
import InputBox from "../../../../Awesome-ui/InputBox/index"
import { connect } from "react-redux"
import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import { getLinkToService } from "data/ProductMapping"

const ProfileSettings = (props) => {
    const { user } = props
    const [isEditing, setisEditing] = React.useState(false)
    const [email, setEmail] = React.useState("")
    const [firstName, setfirstName] = React.useState("")
    const [picture, setPicture] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [isLoading, setisLoading] = React.useState(false)

    const reSet = useCallback(() => {
        if (user) {
            setEmail(user.email_id)
            setfirstName(user.first_name)
            setLastName(user.last_name)
            setPicture(user.picture)
        }
    }, [user])

    useEffect(() => {
        reSet()
    }, [user, reSet])

    async function submit() {
        setisLoading(true)

        setisLoading(false)
        setisEditing(false)
    }

    return (
        <div className="setting-profile">
            <div className="profile-left">
                <div className="profile-title">
                    <Text varient="h4" marginBottom={"0.5rem"}>Profile Photo</Text>
                    <Text varient="subText">Formats: jpg, png. Max size: 500kb</Text>
                </div>
                <Avatar
                    icon={picture}
                    user={user ? user.first_name : ""}
                    height={"100px"}
                    width={"100px"}
                    fontSize={"30px"}
                />
                <a 
                    href={getLinkToService("account")+"/profile"} 
                    style={{textDecoration:"none"}}
                >
                    <Text varient="subText" color="link" marginTop= "1rem">
                        Go To Main Profile Page
                    </Text>
                </a>
            </div>
            <div className="setting-right">
                <div className="setting-details">
                    <div className="profile-title-right">
                        <Text varient="h4">Personal info</Text>
                        <div className="buttons">
                {!isEditing ? (
                    <div
                        className="btn-base edit-btn"
                        onClick={() => setisEditing(true)}
                    >
                        Edit
                    </div>
                ) : (
                    <div className="all-buttons">
                        <div
                            className="btn-base edit-btn"
                            onClick={() => {
                                reSet()
                                setisEditing(false)
                            }}
                        >
                            Cancel
                        </div>
                        <div
                            className="btn-base save-btn"
                            onClick={() => {
                                submit()
                            }}
                            style={{
                                justifyContent: isLoading
                                    ? "space-between"
                                    : "center",
                            }}
                        >
                            <span>Save</span>
                            {isLoading && <span></span>}
                        </div>
                    </div>
                )}
            </div>
                    </div>
                    <div className="input-fields">
                        <div className="user-name">
                            <div className="profile-input">
                                {/* <div>First Name</div> */}
                                <InputBox
                                    placeHolder="First Name"
                                    name="search"
                                    type="text"
                                    value={firstName}
                                    ind={0}
                                    disable={!isEditing}
                                    focus={""}
                                    changeFunc={(val) => {
                                        setfirstName(val)
                                    }}
                                    // clickFun={filterGroups}
                                    complete="on"
                                    reverse
                                    req={false}
                                    style={{
                                        // height: "3rem",
                                        padding: "12px 16px",
                                        width: "90%",
                                    }}
                                />
                            </div>
                            <div className="profile-input">
                                <InputBox
                                    placeHolder="Last Name"
                                    name="search"
                                    type="text"
                                    value={lastName}
                                    ind={2}
                                    changeFunc={(val) => {
                                        setLastName(val)
                                    }}
                                    // clickFun={filterGroups}
                                    complete="on"
                                    disable={!isEditing}
                                    reverse
                                    req={false}
                                    style={{
                                        // height: "3rem",
                                        padding: "12px 16px",
                                        width: "90%",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="profile-input">
                            <InputBox
                                placeHolder="Email"
                                name="search"
                                type="text"
                                value={email}
                                ind={3}
                                changeFunc={(val) => {
                                    setEmail(val)
                                }}
                                // clickFun={filterGroups}
                                complete="on"
                                disable={!isEditing}
                                reverse
                                req={false}
                                style={{
                                    // height: "3rem",
                                    padding: "12px 16px",
                                    paddingRight: 0,
                                    width: "90%",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ userReducer }) => ({
    user: userReducer.userDetails,
    token: userReducer.token,
})

export default connect(mapStateToProps)(ProfileSettings)
