import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import React, { useContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { createApi } from "unsplash-js"
import ContentLibrary from "./Components/ContentLibrary"
import Gif from "./Components/GIFPicker/GifPicker"
import UnsplashPicker from "./Components/UnsplashPicker/UnsplashPicker"
import UploadMedia from "./Components/UploadMedia"
import { ReactContext } from "./MediaLibrary"
import { useStyles } from "./MediaLibraryStyle"

const unsplashApi = createApi({
    accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
})

const TabHandler = ({ selectedTab }) => {
    if (selectedTab === 1) return <UploadMedia />
    if (selectedTab === 2) return <ContentLibrary />
    if (selectedTab === 3) return <UnsplashPicker />
    if (selectedTab === 4) return <Gif />
    return <>{selectedTab}</>
}

const MediaLibraryRight = () => {
    const {
        selectedTab,
        handlingFiles,
        setHandlingFiles,
        setFiles,
        onContinue,
        initFiles,
        styles,
        singleFile,
    } = useContext(ReactContext)
    const classes = useStyles(styles)
    const [fileNo, setFileNo] = useState(0)

    const selectedFilesHandler = async (e) => {
        e.preventDefault()
        const unsplashFiles = handlingFiles.unsplash.map((newFile) => {
            unsplashApi.photos.trackDownload({
                downloadLocation: newFile.links.download_location,
            })
            return {
                name: newFile.name,
                preview: newFile.urls.regular,
                size: newFile.bytes,
                source: "Unsplash",
                otherDetails: newFile,
                mediaType: "IMAGE",
            }
        })
        const tenorFiles = handlingFiles.tenor.map((newFile) => {
            const media = newFile.media_formats
            return {
                name: newFile.content_description,
                preview: media?.gif.url,
                size: media?.gif.size,
                source: "Gif",
                otherDetails: newFile,
                mediaType: "IMAGE",
            }
        })
        let allFiles = [
            ...handlingFiles.library,
            ...handlingFiles.upload,
            ...unsplashFiles,
            ...tenorFiles,
        ]
        if(singleFile)  allFiles = allFiles[0]
        setFiles(allFiles)
        if (onContinue) await onContinue(allFiles)
        setHandlingFiles(initFiles)
    }

    useEffect(() => {
        let res = 0
        Object.keys(handlingFiles).map(
            (category) => (res += handlingFiles[category].length)
        )
        setFileNo(res)
    }, [handlingFiles])

    return (
        <div className={classes.mainContainerRight}>
            <TabHandler selectedTab={selectedTab} />
            {fileNo > 0 && (
                <div className={classes.mainContainerContinue}>
                    <Text>{fileNo} items selected</Text>
                    <Button onClick={selectedFilesHandler}>Continue</Button>
                </div>
            )}
        </div>
    )
}

export default MediaLibraryRight
