export const SIGN_NOW = "SIGN_NOW"
export const SEND_FOR_SIGNATURE = "SEND_FOR_SIGNATURE"

// DOCUMENT STATUS
export const OUT_FOR_SIGNATURE = "OUT_FOR_SIGNATURE"
export const FINISHED = "FINISHED"
export const DRAFT = "DRAFT"
export const RECIEVED_FOR_SIGNATURE = "RECIEVED_FOR_SIGNATURE"
export const CANCELLED = "CANCELLED"

// signing modes
export const USER = "USER"
export const SIGNER = "SIGNER"

export const SUCCESS = "success"
export const WARNING = "warning"
export const INFO = "info"
export const DANGER = "error"
export const PROGRESS = "progress"

//Signing Types for signers
export const CLICK_FOR_SIGN = "CLICK_SIGN"
export const DRAW_SIGNATURE = "SIGN"

//template views
export const DOCUMENT = "DOCUMENT"
export const FORM = "FORM"

//document types
export const TEMPLATE = "TEMPLATE"
export const PREDEFINED_TEMPLATE = "PREDEFINED_TEMPLATE"

export const OWNER = "owner"