import { DELETE_TEAM_MEMBER } from "ActionTypes/foldersActionType"
import {
    GET_WORKSPACE_ALL,
    GET_WORKSPACE_BY_ID,
    ADD_NEW_WORKSPACE,
    SET_WORKSPACE_LOADED,
    SET_WORKSPACE_LOADING,
    DELETE_WORKSPACE_BY_ID,
    SET_WORKSPACE_TO_NULL,
    EDIT_WORKSPACE_BY_ID,
    SET_WORKSPACE_USERS,
    GET_FOLDERS_BY_USER,
    GET_CURRENT_WORKSPACE,
    UPDATE_BRANDS_IN_CURRENT_WORKSPACE,
} from "ActionTypes/workspaceActionTypes"

const initialize = {
    workspace: [],
    currentWorkspace: null,
    users: null,
    workspaceLoading: true,
}

export const workspaceReducer = (state = initialize, action) => {
    const { payload, type } = action

    switch (type) {
        case GET_WORKSPACE_BY_ID:
            getWorkspaceById(state, payload)
        case GET_WORKSPACE_ALL:
            return { ...state, workspace: payload, workspaceLoading: false }
        case GET_CURRENT_WORKSPACE:
            return { ...state, currentWorkspace: payload.currentWorkspace }
        case ADD_NEW_WORKSPACE:
            return {
                ...state,
                workspace: [...state.workspace, payload],
                workspaceLoading: false,
            }
        case DELETE_WORKSPACE_BY_ID:
            return removeDeletedIdFromState(state, payload.id)
        case EDIT_WORKSPACE_BY_ID:
            return editDeletedIdFromState(state, payload)
        case SET_WORKSPACE_LOADED:
            return { ...state, workspaceLoading: false }
        case SET_WORKSPACE_LOADING:
            return { ...state, workspaceLoading: true }
        case SET_WORKSPACE_TO_NULL:
            return { ...initialize, workspaceLoading: false }
        case SET_WORKSPACE_USERS:
            return { ...state, users: payload.users }
        case GET_FOLDERS_BY_USER: {
            return getFoldersByUser(state, payload)
        }
        case DELETE_TEAM_MEMBER:
            return deleteTeamMemberFromFolder(state, payload)
        case UPDATE_BRANDS_IN_CURRENT_WORKSPACE:
            return {
                ...state,
                currentWorkspace: {
                    ...state.currentWorkspace,
                    brands: payload.updatedBrands,
                },
            }
        default:
            return state
    }
}

/**
 * Removes deleted workspace id in frontend
 */

const removeDeletedIdFromState = (state, id) => {
    let currentWorkspace = null
    const newWorkspace = state.workspace?.filter((ws) => ws?.id !== id)
    if (id === state.currentWorkspace.id) {
        if (newWorkspace?.length > 0) {
            currentWorkspace = newWorkspace[0]
        }
    }
    return {
        ...state,
        workspace: newWorkspace,
        workspaceLoading: false,
        currentWorkspace,
    }
}

/**
 * Edits a workspace data in frontend
 */

const editDeletedIdFromState = (state, data) => {
    const { id } = data
    const editedWorkspace = [...state.workspace]
    let editedData = editedWorkspace.find((ws) => ws.id === id)
    if (editedData) {
        editedData = { ...editedData, ...data }
        return { ...state, workspace: [...editedWorkspace], workspaceLoading: false }
    }
    return { ...state }
}

const getFoldersByUser = (state, payload) => {
    const { userId, folderList } = payload
    let userList = [...state.users]
    let userData = userList?.find((user) => user.id === userId)
    if (!userData) return state

    userData.folderList = [...folderList]
    return { ...state, users: userList }
}

const getWorkspaceById = (state, payload) => {
    const currentWorkspace = [...state.workspace]
    let editingWorskpace = currentWorkspace.find((ws) => ws.id === payload.id)
    if (editingWorskpace) {
        editingWorskpace = { ...editingWorskpace, ...payload }
    }
    return { ...state, workspaceLoading: false }
}

const deleteTeamMemberFromFolder = (state, payload) => {
    if (!state.users) return state
    let currentUsers = [...state.users]
    let editedUser = currentUsers.find((user) => user.id === payload.userId)
    if (!editedUser || !editedUser.folderList) return state
    editedUser.folderList = editedUser.folderList.filter(
        (folder) => folder.brandId.brandId !== payload.folderId
    )
    if (editedUser.folderList.length === 0) {
        currentUsers = currentUsers.filter((user) => user.id !== payload.userId)
    }
    return {
        ...state,
        users: currentUsers,
    }
}
