import React from "react"
import "./DrawerElementWrapper.scss"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

/**
 * use this to wrapp a component in sidedrawer and it provides title
 * and flex childern
 * @param {*} param0
 * @returns
 */
const DrawerElementWrapper = ({ title, children, style, compStyle }) => {
    return (
        <div style={compStyle}>
            <Text className="aui-side-bar-sub" margin="0 0 1rem 1rem" color="secondary">
                {title}
            </Text>
            <div className="aui-style-options" style={style}>
                {children}
            </div>
        </div>
    )
}

export default DrawerElementWrapper
