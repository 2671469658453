import React from "react"
import { useStyles } from "./TemplateFormStyle"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { setTemplateItemValue } from "Action/signAction"
import ToggleButton from "Awesome-ui/ToggleButton/ToggleButton"

const TemplateFormCheckBox = ({
    detailsOfPositions = [],
    value,
    count,
    scrollToPage = () => {},
}) => {
    const classes = useStyles()
    return (
        <div className={classes.templateFormInput}>
            <Text varient="h6">{"Checkbox " + count}</Text>
            <div className={classes.templateFormCheckboxToggle}>
                <ToggleButton
                    isChecked={!!value}
                    setIsChecked={(val) => {
                        setTemplateItemValue(val, detailsOfPositions)
                    }}
                    checkedText="Yes"
                    uncheckedText="No"
                />
            </div>
            <div className={classes.pageNumberList}>
                {detailsOfPositions.map(({ pageIndex }, index) => (
                    <div
                        key={index}
                        className={classes.eachPageNumber}
                        onClick={() => scrollToPage(parseInt(pageIndex))}
                    >
                        <Text fontSize={13}>Pg. {parseInt(pageIndex) + 1}</Text>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TemplateFormCheckBox
