import React from 'react';
import "./TimeLineDetails.scss";

/**
 * This is the component where the main card component will go
 * 
 * this is how it is suppose to use pdfActivity is an custom component. 
 * @example 
 * ```
 *  <TimeLineContainer>
        {timeline.map((item, index) => (
            <TimeLineComponent key={index}>
                <TimeLineConnector disableLine={index === 3} />
                <TimeLineDetails>
                    <PdfActivity {...item} />
                </TimeLineDetails>
            </TimeLineComponent>
        ))}
    </TimeLineContainer>
 * ``` 
 * 
 * @param {*} param0 
 * @returns 
 */
const TimeLineDetails = ({children}) => {
    return (
        <div className="aui-timeline-details">
            {children}
        </div>
    )
}

export default TimeLineDetails;
