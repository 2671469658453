import React from "react"
import { connect } from "react-redux"
import "./PublicTemplateEditor.scss"

import PdfTop from "../PdfEditor/PdfTop/PdfTop"
import PdfEditor from "../PdfEditor/PdfViewer/PdfEditor"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { CANCELLED } from "Types"
import { AcceptSign } from "public/PdfEditor/PublicESignature"
import { setDocumentAccept } from "Action/signAction"
import EditorLoader from "public/PdfEditor/EditorLoader"

/**
 * this is the pdf e-signature page with the pdf viewer and editing tools.
 * for public use.
 */
const ESignaturePage = ({
    signerId,
    document,
    isPdfPageView,
    documentUrl,
    currentSignerStatus,
    docStatus,
    view,
    activePage,
    setActivePage,
    isAccepted,
    parentRef,
    params,
    loading,
    setLoading,
    saveAllAnnotations = () => {},
    ...props
}) => {
    return (
        <div style={{ width: "100%", overflow: "hidden", minHeight: "100vh" }}>
            {loading && <EditorLoader />}
            <PdfTop
                securityHash={params?.documentId}
                setLoading={setLoading}
                saveAllAnnotations={saveAllAnnotations}
            />
            {!isAccepted &&
                ["VIEWED", "SENT"].indexOf(currentSignerStatus) !== -1 &&
                docStatus !== CANCELLED && (
                    <AcceptSign
                        onClick={() => setDocumentAccept(true)}
                        signerId={signerId}
                        documentId={document}
                        token={params?.documentId}
                    />
                )}
            <div
                className="public-template-editor-page"
                style={{ height: docStatus && "92.2vh" }}
            >
                <div className="public-ESignature-pdf-template">
                    <PdfEditor
                        parentRef={parentRef}
                        {...props}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        canvasStyle={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                        // isPageScrolling={isPageScrolling}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    docStatus: signReducer.status,
    currentSignerStatus: signReducer?.currentSignerStatus,
    documentUrl: signReducer.documentDetails?.documentUrl,
    document: signReducer.documentDetails?.documentId,
    signerId: signReducer.documentDetails?.currentSigner,
    isPdfPageView: signReducer.isPdfPageView,
    view: signReducer.documentDetails?.view,
    isAccepted: signReducer.isAccepted,
})

export default connect(mapStateToProps)(ESignaturePage)
