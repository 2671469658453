import React from "react"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { ReactComponent as HourGlass } from "./assets/hourglass.svg"
import { getLinkToService } from "data/ProductMapping"
import useStyles from "./style"

const TrialEndedPopup = ({
    active,
    isClosable = true,
    onClose = () => {},
    onLogOut = () => {},
}) => {
    const ACCOUNTS_LINK_API = getLinkToService("account")
    const classes = useStyles()
    if (!active) return <></>
    return (
        <div className={classes.auiTrialEnded}>
            <div className={classes.auiTrialEndedPopup}>
                <div
                    className={classes.auiTrialEndedImage}
                    style={{ backgroundColor: "#DCD8FF" }}
                >
                    <HourGlass />
                </div>
                <Text
                    className={classes.auiTrialEndedTitle}
                    align="center"
                    varient="h5"
                    marginBottom="1rem"
                    marginTop="1rem"
                    fontSize={26}
                >
                    Your Free Trial Has Ended
                </Text>
                <Text
                    className={classes.auiTrialEndedSubTitle}
                    align="center"
                    varient="subText"
                    margin="0.5rem 1rem"
                >
                    Don't go back to printing, copying, faxing and signing documents.
                    Upgrade to Awesome Sign Professional Plan designed to save you
                    time and money and keep your business moving forward.
                </Text>
                <div className={classes.auiTrialEndedButton}>
                    <a
                        className={classes.auiTrialEndedPaymentButton}
                        href={`${ACCOUNTS_LINK_API}/subscription/payment-methods`}
                    >
                        <Text varient="subText" color="#ffffff">
                            Add Payment Method
                        </Text>
                    </a>
                </div>
                <div className={classes.auiTrialEndedBottom}>
                    <a
                        className={classes.auiTrialEndedAllPlans}
                        href={`${ACCOUNTS_LINK_API}/subscription/plans`}
                    >
                        <Text varient="subText" color="#00a4bd">
                            View All Plans
                        </Text>
                    </a>
                    <span className={classes.auiTrialEndedLogout} onClick={onLogOut}>
                        Logout
                    </span>
                </div>
                {isClosable && (
                    <div className={classes.auiTrialEndedClose} onClick={onClose}>
                        +
                    </div>
                )}
            </div>
        </div>
    )
}

export default TrialEndedPopup
