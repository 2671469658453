import {ReactComponent as Agreement } from "Assets/settings/agreement.svg";
import {ReactComponent as CreditCard } from "Assets/settings/credit-card.svg";
import {ReactComponent as FolderIcon } from "Assets/settings/folder.svg";
import {ReactComponent as UserIcon } from "Assets/settings/users.svg";
import {ReactComponent as CustomizeIcon } from "Assets/settings/palet.svg";
import {ReactComponent as ProfileIcon } from "Assets/settings/profile.svg";
import { getLinkToService } from "data/ProductMapping";

export const data = [
    {
        name: "My Settings",
        subName: "Manage your personal settings",
        settings: [
            {
                select: false,
                type: "data",
                link: "/settings/profile",
                Icon: ProfileIcon,
                data: {
                    logo: "profile",
                    name: "Profile",
                },
            },
            // {
            //     select: false,
            //     type: "data",
            //     link: "/settings/notification",
            //     data: {
            //         logo: "notifications",
            //         name: "Notifications",
            //     },
            // },
        ],
    },
    {
        name: "Account Settings",
        subName: "Manage your accounts settings",
        settings: [
            // {
            //     select: false,
            //     type: "data",
            //     link: "/settings/customization",
            //     Icon: CustomizeIcon,
            //     data: {
            //         logo: "customization",
            //         name: "Customization",
            //     },
            // },
            {
                select: false,
                type: "data",
                externalLink: true,
                link: `${getLinkToService("account")}/subscription/plans`,
                Icon: CreditCard,
                data: {
                    logo: "Subscription",
                    name: "Subscription Plan",
                },
            },
            {
                select: false,
                type: "data",
                link: "/settings/users",
                Icon: UserIcon,
                data: {
                    logo: "Users",
                    name: "Users",
                },
            },
            // {
                // select: false,
                // type: "data",
                // link: "/settings/workspace",
                // Icon: FolderIcon,
                // data: {
                //     logo: "workspace",
                //     name: "Workspace",
                // },
            // },
            // {
            //     select: false,
            //     type: "data",
            //     link: "/settings/signRequests",
            //     Icon: Agreement,
            //     data: {
            //         logo: "Sign Requests",
            //         name: "Sign Requests",
            //     },
            // },
        ],
    },
]
