import React from "react"
import { NavLink } from "react-router-dom"
import AppsIcon from "../../Assets/Dashboard/Apps.svg"
import "./HorizontalBar.scss"

/**
 * Component for the horizontal nav bar will get consumed if sidebar is disabled and
 * customization is set to horizontal bar.
 *
 * @param {["dark", "light"]} theme - for managing the theme in the top nav bar.
 * @params {[object]}  navOptions - nav items or links to the horizontal nav menu
 * @returns returns the component with the top horizontal nav.
 */
const HorizontalBar = ({
    sideBarColor,
    navOptions,
    Logo,
    theme,
    appSwitch,
    userRole,
    restricted,
    showAppSwitcher,
    ...props
}) => {
    return (
        <div className={`top-sideBar top-sidebar-${theme}`}>
            {showAppSwitcher && (
                <div className="app-selector" onClick={appSwitch}>
                    <div className="app-icon">
                        <img src={AppsIcon} alt="apps" />
                    </div>
                </div>
            )}
            <div className="company-logo">
                <Logo />
            </div>
            {!restricted &&
                navOptions.map((item, index) => {
                    return (
                        <NavLink
                            key={index}
                            to={item.link}
                            exact
                            activeClassName={`top-nav-active top-nav-active-${theme}`}
                            className={`top-nav-icon top-nav-icon-${theme}`}
                        >
                            <div className="top-nav-text">{item.name}</div>
                        </NavLink>
                    )
                })}
        </div>
    )
}

export default HorizontalBar
