import React from "react"

import Loader from "../Loader/Loader"

const PageLoading = ({style={}}) => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "92vh",
                ...style
            }}
        >
            <Loader style={{ width: "34px", height: "34px" }} />
        </div>
    )
}

export default PageLoading
