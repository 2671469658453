import { SignatureDragType } from "Components/PDFCommons/container/Types"
import { getCanvasScale, getCanvasSize } from "Library/canvasAdjuster"
import { FORM, SIGNER } from "Types"
import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"

const { height: NORMAL_HEIGHT, width: NORMAL_WIDTH } = getCanvasSize()

function getRenderingSize(
    height,
    width,
    boundingHeight = NORMAL_HEIGHT,
    boundingWidth = NORMAL_WIDTH
) {
    let renderingHeight = height,
        renderingWidth = width
    const aspectRatio = height / width

    if (aspectRatio > 1) {
        //portrait
        if (renderingWidth > boundingWidth) {
            renderingWidth = boundingWidth
            renderingHeight = boundingWidth * aspectRatio
        }
        if (renderingHeight > boundingHeight) {
            renderingHeight = boundingHeight
            renderingWidth = boundingHeight / aspectRatio
        }
    } else {
        //landscape
        if (renderingHeight > boundingHeight) {
            renderingHeight = boundingHeight
            renderingWidth = boundingHeight / aspectRatio
        }
        if (renderingWidth > boundingWidth) {
            renderingWidth = boundingWidth
            renderingHeight = boundingWidth * aspectRatio
        }
    }

    return { width: renderingWidth, height: renderingHeight }
}

const TextLayer = ({ page, pageNumber, pdfjs, activeToolType, view, editingMode }) => {
    const ref = useRef([null])

    /**
     * render the Dom
     */
    const isSignerSigningInFormView = view === FORM && editingMode === SIGNER
    
    async function renderDom() {
        const textLayer = await page.getTextContent()

        var scale = isSignerSigningInFormView ? 2.5 : getCanvasScale()
        var viewPort = page.getViewport({ scale })
        if (!viewPort) return

        let maxWidthBound =
            isSignerSigningInFormView
                ? Math.min(0.6 * window.innerWidth, NORMAL_WIDTH)
                : NORMAL_WIDTH

        const { width } = getRenderingSize(
            viewPort.height,
            viewPort.width,
            NORMAL_HEIGHT,
            maxWidthBound
        )
        viewPort = page.getViewport({ scale: (width / viewPort.width) * scale })

        const annotateDiv = ref.current[0]

        if (textLayer) {
            pdfjs.renderTextLayer({
                textContent: textLayer,
                container: annotateDiv,
                viewport: viewPort,
                textDivs: [],
            })
        }
    }

    useEffect(() => {
        if (page) renderDom()
    }, [page])

    return (
        <div
            ref={(el) => (ref.current[0] = el)}
            name={`${pageNumber}`}
            style={{
                cursor: activeToolType === SignatureDragType.TEXT ? "text" : "auto",
            }}
            className="annotation-layer textLayer"
        ></div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    activeToolType: signReducer.activeTool?.type,
    view: signReducer.documentDetails?.view,
    editingMode: signReducer.editingMode,
})

export default connect(mapStateToProps)(TextLayer)
