import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as Beaker } from "Assets/ToolsIcons/conical-flask.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

const useStyles = createUseStyles({
    betaTagWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0.25rem",
        background:
            "linear-gradient(90deg, rgba(102,121,208,1) 0%, rgba(6,162,190,1) 100%)",
        borderRadius: "3rem",
        width: ({ expand }) => (expand ? "100%" : "6rem"),
    },
    betaTagContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        width: "5rem",
    },
    betaTagIcon: {
        margin: "0 0.2rem",
    },
    betaTagText: {
        margin: "0 0.2rem",
        color: "white",
        fontWeight: "500",
    },
})

function BetaTag({ expand }) {
    const classes = useStyles({ expand: expand })
    return (
        <div className={classes.betaTagWrapper}>
            <div className={classes.betaTagContent}>
                <div className={classes.betaTagIcon}>
                    <Beaker />
                </div>
                <Text fontSize={14} className={classes.betaTagText}>Beta</Text>
            </div>
            <div className={classes.betaTagDummyDiv}></div>
        </div>
    )
}

export default BetaTag
