import { Text } from "@awesomesuite-frontend/awesome-nebula"
import Guard from "Awesome-ui/Guard"
import React from "react"
import SettingCard from "./settingCard"

const Board = ({ name, subName, settings, isCreating }) => {
    if (name === "Account Settings")
        return (
            <Guard scope="account_settings_get">
                <div className="board">
                    <div className="title">
                        <Text varient="h5" margin="0.5rem 1.5rem">
                            {name}
                        </Text>
                        <Text varient="subText" margin="0 1.5rem">
                            {subName}
                        </Text>
                    </div>
                    <div className="cardDiv">
                        {settings.map((setting, i) => (
                            <SettingCard
                                type="data"
                                key={i}
                                {...setting}
                                isCreating={isCreating}
                            />
                        ))}
                    </div>
                </div>
            </Guard>
        )
    return (
        <div className="board">
            <div className="title">
                <Text varient="h5" margin="0.5rem 1.5rem">
                    {name}
                </Text>
                <Text varient="subText" margin="0 1.5rem">
                    {subName}
                </Text>
            </div>
            <div className="cardDiv">
                {settings.map((setting, i) => (
                    <SettingCard
                        type="data"
                        key={i}
                        {...setting}
                        isCreating={isCreating}
                    />
                ))}
            </div>
        </div>
    )
}

export default Board
