import React, { useEffect, useState } from "react"
import "./Dashboard.scss"
import FileUploader from "../../Awesome-ui/FileUploader/FileUploader"
import { Grid, Text } from "@awesomesuite-frontend/awesome-nebula"

import { connect } from "react-redux"
import { medialApi } from "../../Apis/AllApisMapping"
import { useHistory } from "react-router"
import RecentDocuments from "./Components/RecentDocuments/RecentDocuments"
import RecentActivity from "./Components/RecentActivities/RecentActivity"
import {
    addDocument,
    getAllDocumentsRecievedForSignature,
    getAllDocumentsWithStatus,
} from "Action/documentAction"
import { DRAFT, FINISHED, OUT_FOR_SIGNATURE, RECIEVED_FOR_SIGNATURE } from "Types"
import { createUseStyles } from "react-jss"
import { Helmet } from "react-helmet"
import usePdfHook from "hooks/PdfLib/PdfHook"
import Guard from "Awesome-ui/Guard"

const useStyle = createUseStyles((theme) => ({
    summeryItem: {
        width: "20%",
        height: 160,
        flexBasis: "auto",
        border: "1px solid transparent",
        borderRight: "1px solid #d6dfe8",
        cursor: "pointer",
        "&:hover": {
            background: "#eaf0f6",
        },
        "@media(max-width: 1600px)": {
            height: 140,
        },
        "@media(max-width: 600px)": {
            width: "100%",
            height: "auto",
            padding: "0 12px",
            borderRight: "1px solid transparent",
        },
    },
    summeryItemActive: {
        background: "#eaf0f6",
    },
    dot: {
        width: 10,
        height: 10,
        borderRadius: "50%",
        display: "inline-block",
        marginRight: "6px",
    },
    docHeader: {
        height: "40px",
        "@media(max-width: 900px)": {
            height: "50px",
        },
        "@media(max-width: 765px)": {
            height: "70px",
        },
        "@media(max-width: 600px)": {
            height: "auto",
        },
    },
    docCount: {
        "@media(max-width: 750px)": {
            fontSize: "30px",
        },
        "@media(max-width: 600px)": {
            fontSize: "22px",
            padding: "5px",
            paddingLeft: 0,
        },
    },
    docWrapper: {
        display: "flex",
        "@media(max-width: 600px)": {
            display: "block",
        },
    },
}))

const status = {
    TOTAL: "TOTAL",
    AWAITING: RECIEVED_FOR_SIGNATURE,
    PENDING: OUT_FOR_SIGNATURE,
    DONE: FINISHED,
    DRAFT: DRAFT,
}

const boards = [
    {
        name: "Total Document",
        status: status.TOTAL,
        color: "",
    },
    {
        name: "Draft",
        status: DRAFT,
        color: "#CBD6E2",
    },
    {
        name: "Documents to be signed",
        status: RECIEVED_FOR_SIGNATURE,
        color: "#25CAFF",
    },
    {
        name: "Awaiting other signature",
        status: OUT_FOR_SIGNATURE,
        color: "#F5C26B",
    },
    {
        name: "Documents Signed",
        status: FINISHED,
        color: "#00BDA5",
    },
]

const Dashboard = ({
    metric,
    draftDocuments,
    finishedDocments,
    sendForSignature,
    receivedForSign,
    sendFill,
    finishedFill,
    draftFill,
    receivedForSignFill,
    user,
    icon,
    ...props
}) => {
    const classes = useStyle()
    const [loading, setloading] = useState(false)
    const [activeFilter, setActiveFilter] = useState(null)
    const history = useHistory()
    const [uiDocuments, setuiDocuments] = useState([])
    const { loadedLib, loadPdfLib, loadPdf } = usePdfHook()
    const [initialDocLoading, setInitialDocLoading] = useState(true)

    async function getNumberOfPages(pdfFile) {
        if (!loadedLib) {
            await loadPdfLib()
        }
        const res = await loadPdf(pdfFile)
        return res.numberOfPages
    }

    function setRecentDocuments(res) {
        let files = []
        res.map((item) => {
            if (item && Array.isArray(item.content))
                files.push(...item?.content.slice(0, 4))
        })
        files = files.sort(function (a, b) {
            return new Date(b.updatedAt) - new Date(a.updatedAt)
        })
        setuiDocuments(files)
    }

    useEffect(() => {
        const initialDocsLoad = async () => {
            setInitialDocLoading(true)
            const res = await Promise.all([
                getAllDocumentsWithStatus(DRAFT),
                getAllDocumentsWithStatus(OUT_FOR_SIGNATURE),
                getAllDocumentsWithStatus(FINISHED),
                getAllDocumentsRecievedForSignature(),
            ])
            setRecentDocuments(res)
            setInitialDocLoading(false)
        }
        initialDocsLoad()
    }, [])

    async function startDocumentSigning(fileDetails) {
        try {
            setloading(true)
            const pages = await getNumberOfPages(fileDetails.mediaPath)
            const response = await addDocument(
                fileDetails.name,
                fileDetails.mediaId,
                fileDetails.mediaPath,
                pages
            )
            if (response) {
                history.push(`/pdf-editor/${response.model.fileId}`)
            }
        } catch (error) {
            console.log(error)
        }
        setloading(false)
    }

    function selectDocumentTypes(type) {
        if (type === activeFilter) {
            setActiveFilter(null)
            setRecentDocuments(
                [
                    draftDocuments,
                    sendForSignature,
                    receivedForSign,
                    finishedDocments,
                ].map((item) => ({ content: item }))
            )
        } else {
            switch (type) {
                case status.TOTAL:
                    setRecentDocuments(
                        [
                            draftDocuments,
                            sendForSignature,
                            receivedForSign,
                            finishedDocments,
                        ].map((item) => ({ content: item }))
                    )
                    break
                case status.AWAITING:
                    setuiDocuments(receivedForSign)
                    break
                case status.DONE:
                    setuiDocuments(finishedDocments)
                    break
                case status.DRAFT:
                    setuiDocuments(draftDocuments)
                    break
                case status.PENDING:
                    setuiDocuments(sendForSignature)
                    break
            }
            setActiveFilter(type)
        }
    }

    return (
        <div className="document-dashboard">
            <Helmet>
                <title>Dashboard | Awesome Sign</title>
            </Helmet>
            <Text
                fontSize={18}
                weight={600}
                color="default"
                style={{ color: "#33475B" }}
            >
                Hello {user} 👋
            </Text>
            <Text varient="body" color="default" style={{ color: "#33475B" }}>
                Welcome to Awesome Sign
            </Text>
            <Grid
                container
                radius="8px"
                background="#fff"
                marginTop="1rem"
                style={{ border: "1px solid #d6dfe8" }}
            >
                {boards.map((item, key) => (
                    <Grid
                        item
                        className={`${classes.summeryItem} 
                        ${
                            activeFilter === item.status
                                ? classes.summeryItemActive
                                : ""
                        }`}
                        padding="12px"
                        key={key}
                        onClick={() => selectDocumentTypes(item.status)}
                    >
                        {/* <Grid style={{ height: 40 }}>
                            {key === 0 && <Text varient="h3">Document</Text>}
                        </Grid> */}
                        <Text
                            varient="h6"
                            color="light"
                            className={classes.docHeader}
                        >
                            {key !== 0 && (
                                <span
                                    className={classes.dot}
                                    style={{ background: item.color }}
                                />
                            )}
                            {item.name}
                        </Text>
                        <Text
                            varient="h3"
                            padding="12px 0"
                            className={classes.docCount}
                        >
                            {metric[item.status]
                                ? metric[item.status]
                                : item.status === status.TOTAL
                                ? Object.values(metric).reduce((a, b) => a + b) || 0
                                : 0}
                        </Text>
                    </Grid>
                ))}
            </Grid>
            <div className="document-uploader">
                <FileUploader
                    userToken={props.token}
                    mediaApi={medialApi}
                    single
                    parentLoader={loading}
                    addFiles={startDocumentSigning}
                />
            </div>
            <div className={classes.docWrapper}>
                <Guard scope="document_get">
                    <RecentDocuments
                        filterDoc={uiDocuments}
                        name={user}
                        imageSource={icon}
                        loading={initialDocLoading}
                        filterDocStatus={activeFilter}
                    />
                </Guard>
                <RecentActivity />
            </div>
        </div>
    )
}

const mapStateToProps = ({ userReducer, documentReducer }) => ({
    user: userReducer.userDetails?.first_name,
    icon: userReducer.userDetails?.picture,
    token: userReducer.token,
    draftDocuments: documentReducer.draftDocuments?.content,
    sendForSignature: documentReducer.sendForSignature?.content,
    finishedDocments: documentReducer.finishedDocments?.content,
    receivedForSign: documentReducer.recievedForSign?.content,
    draftFill: documentReducer.draftFill,
    finishedFill: documentReducer.finishedFill,
    sendFill: documentReducer.sendFill,
    receivedForSignFill: documentReducer.recievedForSignFill,
    metric: {
        DRAFT: documentReducer.draftDocuments?.totalElements,
        OUT_FOR_SIGNATURE: documentReducer.sendForSignature?.totalElements,
        FINISHED: documentReducer.finishedDocments?.totalElements,
        RECIEVED_FOR_SIGNATURE: documentReducer.recievedForSign?.totalElements,
    },
})

export default connect(mapStateToProps)(Dashboard)
