import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React, { memo } from "react"
import { createUseStyles } from "react-jss"

/**
 * Custom input and text-field
 *
 *  Future update Validators will come later
 */

const useStyle = createUseStyles(() => ({
    auiInput: {
        maxWidth: "400px",
    },
    auiInputLabel: {
        color: "#33475b",
        paddingBottom: "8px",
        fontSize: "1.089rem",
        fontWeight: "500",
    },

    auiInputWrapper: {
        display: "flex",
        alignItems: "center",
        borderRadius: "19px",
        border: "1px solid #d6dfe8",
        position: "relative",

        "& textarea": {
            borderRadius: "inherit",
            width: "100%",
            height: "100%",
            padding: "10px 12px",
            fontSize: "1.1rem",
            outline: "none",
            color: "#33475b",
            border: "none",

            "&::placeholder": {
                fontSize: "0.9rem",
                fontWeight: "500",
                fontFamily: "Poppins, sans-serif",
            },
            "&:-moz-placeholder": {
                fontSize: "0.8rem",
                fontWeight: "500",
            },
        },

        "& input": {
            borderRadius: "inherit",
            width: "100%",
            height: "100%",
            padding: "10px 12px",
            fontSize: ({ fontSize }) => fontSize || "1rem",
            outline: "none",
            color: "#33475b",
            border: "none",
            fontFamily: "Poppins, sans-serif",
            "&::placeholder": {
                fontWeight: "500",
                fontFamily: "Poppins, sans-serif",
                fontSize: ({ placeHolderFontSize }) =>
                    placeHolderFontSize || "0.95rem",
            },
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            "-moz-appearance": "none",
            appearance: "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            "-moz-appearance": "none",
            appearance: "none",
            margin: 0,
        },
    },

    inputIcon: {
        position: "absolute",
        height: "100%",
        right: "10px",
        top: 0,
        display: "flex",
        alignItems: "center",
    },

    auiInputSelect: {
        outline: "none",
        border: "1px solid #7fd1de",
        boxShadow: "0px 0px 2px #7fd1dee8",
    },

    inputDisabled: {
        "& input": {
            backgroundcolor: "#eaf0f6",
        },
    },

    inputLabelSpacer: {
        marginTop: "0.5rem",
    },
    suggestionList: {
        position: "absolute",
        zIndex: 10,
        top: 43,
        left: 0,
        width: "100%",
        backgroundColor: "#fff",
        border: "1px solid #d6dfe8",
        boxShadow: "0px 0px 4px #a2c8f1",
        maxHeight: "120px",
        overflowY: "auto",
        borderRadius: "6px",
        display: "none",
    },
    suggestionListActive: {
        // maxHeight: "120px",
        display: "block",
    },
    suggestionListElement: {
        borderBottom: "1px solid #d6dfe8",
        padding: "0.25rem 0.5rem",
    },
}))

const Input = memo(
    ({
        id,
        rows,
        value = "",
        type,
        placeHolder,
        disabled,
        label,
        changeHandler,
        textField,
        style,
        inputStyle,
        labelStyle,
        icon,
        borderRadius,
        inputClassname,
        className,
        setSuggestionvalue,
        suggestionList,
        iconColor = "#cbd6e2",
        placeHolderFontSize,
        fontSize,
    }) => {
        const SearchIcon = (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill={iconColor}
            >
                <g id="search" transform="translate(0.072 0.072)">
                    <path
                        id="Path_92"
                        data-name="Path 92"
                        d="M14.507,11.809a8.285,8.285,0,0,1-2.7,2.7l3.862,3.862a1.908,1.908,0,0,0,2.7,0h0a1.908,1.908,0,0,0,0-2.7ZM7.494-.072a7.566,7.566,0,1,0,7.566,7.566A7.57,7.57,0,0,0,7.494-.072Zm0,2.6A4.964,4.964,0,1,1,2.53,7.494,4.966,4.966,0,0,1,7.494,2.53Z"
                        fill-rule="evenodd"
                    />
                </g>
            </svg>
        )

        const classes = useStyle({ placeHolderFontSize, fontSize })
        const [select, setSelect] = React.useState(false)
        const [inValue, setValue] = React.useState(value)
        const [currentSuggestionList, setCurrentSuggestionList] =
            React.useState(suggestionList)

        React.useEffect(() => {
            setValue(value)
        }, [value])

        React.useEffect(() => {
            if (suggestionList) {
                const val = suggestionList.filter((suggestion) =>
                    suggestion.includes(inValue)
                )
                setCurrentSuggestionList(val)
            }
        }, [suggestionList, inValue])

        return (
            <div className={`${classes.auiInput} ${className}`} style={{ ...style }}>
                {label && (
                    <label
                        htmlFor={id}
                        className={classes.auiInputLabel}
                        style={{ ...labelStyle }}
                    >
                        {label}
                    </label>
                )}
                <div
                    className={`${classes.auiInputWrapper} ${
                        disabled && classes.inputDisabled
                    } ${label && classes.inputLabelSpacer} ${
                        select ? classes.auiInputSelect : ""
                    }`}
                    style={{
                        borderRadius: borderRadius,
                        border: style?.border || "1px solid #d6dfe8",
                    }}
                >
                    {icon && (
                        <div className={classes.inputIcon}>
                            {icon === "search" && <div>{SearchIcon}</div>}
                        </div>
                    )}
                    {textField ? (
                        <textarea
                            onFocus={() => setSelect(true)}
                            onBlur={() => setSelect(false)}
                            id={id}
                            value={inValue}
                            rows={rows ? rows : 1}
                            placeholder={placeHolder && placeHolder}
                            disabled={disabled}
                            onChange={(e) => {
                                setValue(e.target.value)
                                changeHandler(e.target.value)
                            }}
                            style={{ ...inputStyle }}
                            spellCheck={true}
                            className={inputClassname}
                        />
                    ) : (
                        <input
                            onFocus={() => {
                                setSelect(true)
                            }}
                            onBlur={() => {
                                setTimeout(() => {
                                    setSelect(false)
                                }, 250)
                            }}
                            id={id}
                            value={inValue}
                            type={type}
                            placeholder={placeHolder && placeHolder}
                            disabled={disabled}
                            onChange={(e) => {
                                setValue(e.target.value)
                                changeHandler(e.target.value)
                            }}
                            style={{ ...inputStyle }}
                            className={inputClassname}
                            list={suggestionList && "suggestion-list"}
                        />
                    )}
                    {currentSuggestionList && currentSuggestionList?.length > 0 && (
                        <div
                            className={`${classes.suggestionList} ${
                                select && classes.suggestionListActive
                            }`}
                        >
                            {currentSuggestionList.map((suggestion) => (
                                <div
                                    className={classes.suggestionListElement}
                                    onClick={() => {
                                        setValue(suggestion)
                                        setSuggestionvalue(suggestion)
                                    }}
                                >
                                    <Text fontSize={12}>{suggestion}</Text>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )
    }
)

export default Input
