import React from "react"
import { useStyles } from "./TemplateFormStyle"
import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"

const AcceptAndSignTemplate = ({ isAccepted, onAccept }) => {
    const classes = useStyles()
    return (
        <div className={classes.templateFormInput}>
            <Text varient="h6">Signature of Authorised Person</Text>
            <Text color="light" varient="subText" margin="1rem 0">
                By Selecting 'Accept', I agree that this mark will be electronic
                representation of my signature or initials whenever I use it.
            </Text>
            <Button
                color="#6A78D1"
                radius={4}
                padding={"0.5rem 2rem"}
                disabled={!isAccepted}
                onClick={onAccept}
            >
                <Text varient="subText" color="#fff">
                    Accept and Sign
                </Text>
            </Button>
        </div>
    )
}

export default AcceptAndSignTemplate
