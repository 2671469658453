import React from "react"
import classNames from "classnames"
import { useDrop } from "react-dnd"
import { HEADER, TEXT_BOX, SIDEBAR_ITEM, IMAGE, TEMPLATE_ITEM } from "./Constants"
import "./dropZone.scss"

const ACCEPTS = [HEADER, TEXT_BOX, IMAGE, SIDEBAR_ITEM, TEMPLATE_ITEM]

const DropZone = ({
    data,
    onDrop,
    isLast,
    className,
    onClick = () => {},
    style = {},
    children,
}) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ACCEPTS,
        drop: (item, monitor) => {
            const element = monitor.getClientOffset()
            onDrop(
                element,
                item,
                {height : item.height, width : item.width}
            )
        },
        canDrop: (item, monitor) => true,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })

    const isActive = isOver && canDrop

    return (
        <div
            className={classNames(
                "dropZone",
                { activeDropMenu: isActive, isLast },
                className
            )}
            onClick={onClick}
            ref={drop}
            style={style}
        >
            {children && children}
        </div>
    )
}

export default DropZone
