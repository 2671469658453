import { InputUtility, Text } from "@awesomesuite-frontend/awesome-nebula"
import React, { useState, useEffect } from "react"
import { ReactComponent as CancelIcon } from "../../Assets/Storage/cancel-icon.svg"
import { connect } from "react-redux"
import { useStyles } from "./style"
import { rolesDescription } from "data/rolesDescription"
import { editTeamMember } from "Action/foldersAction"
import { getPermissions } from "Action/permissionAction"
import { folderIcons } from "data/folderIcons"

const EditTeamMember = ({
    setShowModal,
    roles,
    token,
    currentFolderId,
    editingMember,
    workspace,
    dropDownTwoContent,
    allFolders,
    ...props
}) => {
    function getIcon(item) {
        const currentFolderIconDetails = folderIcons.find(
            (icon) => icon.name === item?.icon
        )

        if (currentFolderIconDetails) return currentFolderIconDetails?.logo
        if(item.icon)   return item.icon
        return folderIcons[0].logo
    }

    const classes = useStyles()
    const [userEmails, setUserEmails] = useState([editingMember.email])
    const [dropDownOneContent, setDropdownOneContent] = useState([])
    const [dropDownOneValues, setDropdownOneValues] = useState([{}])
    const [dropDownTwoValue, setDropdownTwoValue] = useState({})
    const [loading, setLoading] = useState(false)

    async function addMemberHandler() {
        let validation = userEmails.find((ue) => ue === "") || !dropDownOneValues[0]
        if (validation || currentFolderId === -1) return
        Array(userEmails.length)
            .fill(0)
            .map(async (val, key) => {
                if (editingMember.role === dropDownOneValues[key]?.name) return
                const data = {
                    id: editingMember.id,
                    email_id: userEmails[key],
                    role_id: dropDownOneValues[key].id,
                    brand_id: currentFolderId,
                    folder_id: currentFolderId,
                    workspace_id: workspace[0].id,
                }
                await editTeamMember(data, false)
            })
        setShowModal(null)
    }

    const setNewRoles = () => {
        return new Promise((resolve, reject) => {
            let newRoles = roles?.map((role) => {
                if (!rolesDescription[role?.roleName])
                    return {
                        id: role.roleId,
                        name: role.roleName,
                    }

                return {
                    id: role.roleId,
                    ...rolesDescription[role?.roleName],
                }
            })
            resolve(newRoles)
        })
    }

    async function init() {
        if (!roles) {
            setLoading(true)
            await getPermissions()
            setLoading(false)
        } else {
            setLoading(true)
            await setNewRoles().then((val) => setDropdownOneContent(val))
            setLoading(false)
            setDropdownOneValues((prev) => {
                const userRole = roles?.find(
                    (role) => role.roleName === editingMember.role
                )
                if (userRole)
                    return [
                        {
                            id: userRole.roleId,
                            ...rolesDescription[userRole?.roleName],
                        },
                    ]
                return prev
            })
            if (dropDownTwoContent)
                setDropdownTwoValue((prev) => {
                    const currentFolder = allFolders.find(
                        (folder) => folder.id === currentFolderId
                    )
                    if (!currentFolder) return {}
                    return {
                        id: currentFolder.id,
                        name: currentFolder.name,
                        image: getIcon(currentFolder),
                    }
                })
        }
    }

    useEffect(() => {
        init()
    }, [roles, token])

    return (
        <div className={classes.backgroundCompound}>
            <div
                className={`${classes.createFolderModal} ${
                    dropDownTwoContent ? classes.createFolderModalLarge : ""
                }`}
            >
                <div className={classes.modalHeader}>
                    <Text marginLeft="1rem">
                        Edit User
                    </Text>
                    <div
                        className={classes.cancelLogo}
                        onClick={() => setShowModal(null)}
                    >
                        <CancelIcon />
                    </div>
                </div>
                <div className={classes.modalContent}>
                    {userEmails.map((val, key) => {
                        return (
                            <div className={classes.inputUtilityHolder}>
                                <InputUtility
                                    inputField={true}
                                    inputValue={userEmails[key]}
                                    setInputValue={(val) => {
                                        let currentValues = [...userEmails]
                                        currentValues[key] = val
                                        setUserEmails(currentValues)
                                    }}
                                    inputPlaceholder="Enter email address to invite"
                                    inputType="email"
                                    inputDisabled
                                    dropdownOne={true}
                                    dropDownOneContent={dropDownOneContent}
                                    dropDownOneValue={dropDownOneValues[key]}
                                    setDropdownOneValue={(val) => {
                                        let currentValues = [...dropDownOneValues]
                                        currentValues[key] = val
                                        setDropdownOneValues(currentValues)
                                    }}
                                    dropdownTwo={dropDownTwoContent ? true : false}
                                    dropDownTwoContent={dropDownTwoContent}
                                    dropDownTwoValue={dropDownTwoValue}
                                    setDropdownTwoValue={setDropdownTwoValue}
                                    dropdownTwoStyle={{
                                        opacity: 0.7,
                                    }}
                                    dropdownTwoDisabled
                                    button={userEmails.length === 1}
                                    buttonAction={addMemberHandler}
                                    buttonText="Edit"
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({
    workspaceReducer,
    roleReducer,
    userReducer,
    foldersReducer,
}) => {
    return {
        roles: roleReducer?.role,
        token: userReducer?.token,
        workspace: workspaceReducer.workspace,
        allFolders: foldersReducer.folderList,
    }
}

export default connect(mapStateToProps)(EditTeamMember)
