import React, { useEffect, useState } from "react"

import Table from "Awesome-ui/Table/Table"
import TableHeader from "Awesome-ui/TableHeader/TableHeader"
import TableRow from "Awesome-ui/TableRow/TableRow"
import FileRow from "./components/Files/FileRow"
import InBoxTop from "../InBoxTop/InBoxTop"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import { useHistory } from "react-router-dom"
import { ReactComponent as NoFileImage } from "../../../Assets/Inbox/sign-empty-inbox.svg"
import { ReactComponent as SortIcon } from "../../../Assets/Inbox/sortIcon.svg"
import { createUseStyles } from "react-jss"
import { Text, Button, CheckBox } from "@awesomesuite-frontend/awesome-nebula"
import { initializeDocument } from "Action/signAction"
import PaginationBar from "Awesome-ui/PaginationBar"
import {
    addDocument,
    addDocumentToS3,
    deleteMultipleDocuments,
    getAllDocumentsRecievedForSignature,
    getAllDocumentsWithStatus,
    paginationRequest,
} from "Action/documentAction"
import PageLoading from "Awesome-ui/PageLoading/PageLoading"
import Guard from "Awesome-ui/Guard"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import { medialApi } from "Apis/AllApisMapping"
import usePdfHook from "hooks/PdfLib/PdfHook"
import { createMessage } from "Action/messageAndNotificationActions"
import { DANGER } from "Types"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import useQuery from "hooks/UseQuery"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { statusLinks } from "../InBox"
import { DRAFT, FINISHED, OUT_FOR_SIGNATURE, RECIEVED_FOR_SIGNATURE } from "Types"

const useStyle = createUseStyles(() => ({
    noFile: {
        marginTop: "2rem",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    noFileContent: {
        margin: "1rem 0",
        textAlign: "center",
    },
    noFileBtn: {
        backgroundColor: "#00BDA5",
        fontWeight: "600",
        borderRadius: "3px",
    },
    inboxRight: {
        backgroundColor: "#F4F7F9",
        width: "85%",
        padding: "1rem 12px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            height: 8,
            width: 8,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
            borderRadius: 20,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#b6b6b6",
            borderRadius: 20,
        },
    },
    inboxRightExpand: {
        flex: 1,
    },
    tableRowViewSecond: {
        border: "none",
        backgroundColor: "white",
        boxShadow: "0px 3px 6px #B5C5D6",
        position: "relative",
        zIndex: "200",
    },
    sortIcon: {
        width: "fit-content",
        marginLeft: "0.5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    iconActive: {
        "& svg *": {
            fill: "#334760",
        },
    },
    paginationBarContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
}))

const InboxRight = ({ userDetails, userToken, isToggle }) => {
    const [files, setFiles] = useState(null)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [searchQuery, setSearchQuery] = useState("")
    const [selection, setselection] = useState({})
    const [sortType, setSortType] = useState(null)
    const inputfile = React.useRef(null)

    const { addQuery, getQuery, removeQuery } = useQuery()
    const { documentStatus } = useParams()
    const { namedDate, formatName } = useBasicUtils()
    const history = useHistory()
    const classes = useStyle()
    const { loadedLib, loadPdfLib, loadPdf } = usePdfHook()

    const mediaController = new mediaHandler(medialApi)

    const fetchAllDocuments = async () => {
        await Promise.all([
            getAllDocumentsWithStatus(DRAFT),
            getAllDocumentsWithStatus(OUT_FOR_SIGNATURE),
            getAllDocumentsWithStatus(FINISHED),
            getAllDocumentsRecievedForSignature(),
        ])
    }

    async function getNumberOfPages(pdfFile) {
        if (!loadedLib) {
            await loadPdfLib()
        }
        const res = await loadPdf(pdfFile)
        return res.numberOfPages
    }

    function validateFile(file) {
        if (file.type !== "application/pdf")
            return createMessage(
                "Document type not supported. Please upload a pdf document.",
                null,
                DANGER
            )
        if (file.size > 100 * 1024 * 1024)
            return createMessage(
                "Document size is too large.",
                "Please upload a document less than 100mb.",
                DANGER
            )
        return true
    }

    async function startDocumentSigning(fileDetails) {
        try {
            const response = await addDocument(
                fileDetails.name,
                fileDetails.mediaId,
                fileDetails.mediaPath,
                fileDetails.pages
            )
            if (response) {
                history.push(`/pdf-editor/${response.model.fileId}`)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function handleFiles(listFiles) {
        //Only single file upload supported for now
        const singleFile = listFiles[0]
        inputfile.current.value= null
        if (!validateFile(singleFile)) return

        const mediaResp = await addDocumentToS3(
            singleFile,
            singleFile.name,
            singleFile.size
        )
        const pages = await getNumberOfPages(mediaResp.mediaPath)
        startDocumentSigning({
            ...mediaResp,
            name: singleFile.name,
            file: singleFile,
            preView: mediaResp.mediaPath,
            size: singleFile.size,
            pages,
        })
    }

    function selectFile(val, id) {
        setselection((prev) => {
            const newData = { ...prev }
            newData[id] = val
            return newData
        })
    }

    const deleteMultipleFileHandler = async () => {
        const fileIds = []
        Object.keys(selection).map((fileId) => {
            if (selection[fileId] === true) fileIds.push(fileId)
        })
        if (fileIds.length < 1) return
        const res = await deleteMultipleDocuments(fileIds)
        if (res === 200) {
            await getInboxDocuments()
            await fetchAllDocuments()
        }
        setselection({})
    }

    async function openDocumentForView(file) {
        if (documentStatus === "recieved-for-signature") {
            const user = file.signer.find((s) => s.userId === userDetails.id)
            history.push(`/sign/${user.hash}`)
            return
        }
        history.push(`/pdf-editor/${file.fileId}`)
    }

    function allClick() {
        const isAllChecked = files.every((file) => selection[file.fileId] === true)
        let allCheck = {}
        if (!isAllChecked) files.forEach((file) => (allCheck[file.fileId] = true))
        else files.forEach((file) => (allCheck[file.fileId] = false))
        setselection(allCheck)
    }

    async function getInboxDocuments() {
        setLoading(true)
        const status = statusLinks.find((s) => s.link === documentStatus)?.status
        const userId = userDetails?.id
        const currentPage = Number(getQuery("page"))
        const sort = getQuery("sort")
        const size = Number(getQuery("size"))
        const searchQuery = getQuery("search")
        const res = await paginationRequest(
            userId,
            currentPage,
            status,
            sort,
            "",
            files?.length,
            size,
            searchQuery
        )
        if (res?.content) {
            setFiles(res.content)
            setTotalPages(res.totalPages)
        }
        setLoading(false)
    }

    useEffect(() => {
        getInboxDocuments()
    }, [history.location.search, documentStatus])

    useEffect(() => {
        async function init() {
            if (searchQuery) addQuery("search", searchQuery)
            else if (getQuery("search")) removeQuery("search")
        }
        const debounceTimeout = setTimeout(init, 800)

        return () => clearTimeout(debounceTimeout)
    }, [searchQuery])

    return (
        <div
            className={`${classes.inboxRight} ${
                isToggle && classes.inboxRightExpand
            }`}
        >
            <InBoxTop
                search={searchQuery}
                searchHandler={setSearchQuery}
                setLoading={setLoading}
                reloadFunc={getInboxDocuments}
                deleteMultipleFileHandler={deleteMultipleFileHandler}
                selectedFilesCount={
                    Object.values(selection).filter((e) => e === true).length
                }
            />
            {loading ? (
                <PageLoading style={{ minHeight: "calc(92vh - 63px - 2rem)" }} />
            ) : (
                <Guard scope="document_get">
                    <div style={{ position: "relative" }}>
                        <Table style={{ position: "relative" }}>
                            <TableRow
                                style={{
                                    border: "none",
                                    backgroundColor: "#f4f7f9",
                                }}
                            >
                                {documentStatus !== "recieved-for-signature" && (
                                    <TableHeader width="6%">
                                        <CheckBox
                                            active={
                                                files?.length > 0 &&
                                                files?.every(
                                                    (f) =>
                                                        selection[f.fileId] === true
                                                )
                                            }
                                            clickFunction={allClick}
                                            color={"white"}
                                            backgroundColor={"rgb(0, 189, 165)"}
                                            variant={"curved"}
                                            border={"lightblue"}
                                        />
                                    </TableHeader>
                                )}
                                <TableHeader
                                    align="left"
                                    fontWeight="600"
                                    width="28%"
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Text fontSize={14} weight={600}>
                                            Document
                                        </Text>
                                        <TooltipWrapper
                                            wrapperClassName={classes.sortIcon}
                                            text={"Sort By File Name"}
                                            direction={"top"}
                                        >
                                            <div
                                                className={
                                                    sortType === "fileName,asc" &&
                                                    classes.iconActive
                                                }
                                                style={{
                                                    height: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setSortType("fileName,asc")
                                                    addQuery("sort", "fileName,asc")
                                                }}
                                            >
                                                <SortIcon />
                                            </div>
                                            <div
                                                className={
                                                    sortType === "fileName,desc" &&
                                                    classes.iconActive
                                                }
                                                style={{
                                                    transform: "rotate(180deg)",
                                                    height: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setSortType("fileName,desc")
                                                    addQuery("sort", "fileName,desc")
                                                }}
                                            >
                                                <SortIcon />
                                            </div>
                                        </TooltipWrapper>
                                    </div>
                                </TableHeader>
                                <TableHeader fontWeight="600" width="10%">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text fontSize={14} weight={600}>
                                            From
                                        </Text>
                                        <div className={classes.sortIcon}>
                                            <div
                                                className={
                                                    sortType === "fromSortAsc" &&
                                                    classes.iconActive
                                                }
                                                style={{
                                                    height: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={
                                                    () => {}
                                                    // setSortType("fromSortAsc")
                                                }
                                            >
                                                {/* <SortIcon /> */}
                                            </div>
                                            <div
                                                className={
                                                    sortType === "fromSortDesc" &&
                                                    classes.iconActive
                                                }
                                                style={{
                                                    transform: "rotate(180deg)",
                                                    height: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={
                                                    () => {}
                                                    // setSortType("fromSortDesc")
                                                }
                                            >
                                                {/* <SortIcon /> */}
                                            </div>
                                        </div>
                                    </div>
                                </TableHeader>
                                <TableHeader fontWeight="600" width={"20%"}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text fontSize={14} weight={600}>
                                            Recieved
                                        </Text>
                                        <div className={classes.sortIcon}>
                                            <div
                                                className={
                                                    sortType === "recSortAsc" &&
                                                    classes.iconActive
                                                }
                                                style={{
                                                    height: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={
                                                    () => {}
                                                    // setSortType("recSortAsc")
                                                }
                                            >
                                                {/* <SortIcon /> */}
                                            </div>
                                            <div
                                                className={
                                                    sortType === "recSortDesc" &&
                                                    classes.iconActive
                                                }
                                                style={{
                                                    transform: "rotate(180deg)",
                                                    height: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={
                                                    () => {}
                                                    // setSortType("recSortDesc")
                                                }
                                            >
                                                {/* <SortIcon /> */}
                                            </div>
                                        </div>
                                    </div>
                                </TableHeader>
                                <TableHeader fontWeight="600" width="16%">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text fontSize={14} weight={600}>
                                            Label
                                        </Text>
                                        <TooltipWrapper
                                            wrapperClassName={classes.sortIcon}
                                            text={"Sort By Tags"}
                                            direction={"top"}
                                        >
                                            <div
                                                className={
                                                    sortType === "tag,asc" &&
                                                    classes.iconActive
                                                }
                                                style={{
                                                    height: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setSortType("tag,asc")
                                                    addQuery("sort", "tag,asc")
                                                }}
                                            >
                                                <SortIcon />
                                            </div>
                                            <div
                                                className={
                                                    sortType === "tag,desc" &&
                                                    classes.iconActive
                                                }
                                                style={{
                                                    transform: "rotate(180deg)",
                                                    height: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setSortType("tag,desc")
                                                    addQuery("sort", "tag,desc")
                                                }}
                                            >
                                                <SortIcon />
                                            </div>
                                        </TooltipWrapper>
                                    </div>
                                </TableHeader>
                                <TableHeader fontWeight="600">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text fontSize={14} weight={600}>
                                            Last Updated
                                        </Text>
                                        <TooltipWrapper
                                            wrapperClassName={classes.sortIcon}
                                            text={"Sort By \n Updated"}
                                            direction={"top"}
                                        >
                                            <div
                                                className={
                                                    sortType === "updatedAt,asc" &&
                                                    classes.iconActive
                                                }
                                                style={{
                                                    height: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setSortType("updatedAt,asc")
                                                    addQuery("sort", "updatedAt,asc")
                                                }}
                                            >
                                                <SortIcon />
                                            </div>
                                            <div
                                                className={
                                                    sortType === "updatedAt,desc" &&
                                                    classes.iconActive
                                                }
                                                style={{
                                                    transform: "rotate(180deg)",
                                                    height: "50%",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    setSortType("updatedAt,desc")
                                                    addQuery(
                                                        "sort",
                                                        "updatedAt,desc"
                                                    )
                                                }}
                                            >
                                                <SortIcon />
                                            </div>
                                        </TooltipWrapper>
                                    </div>
                                </TableHeader>
                            </TableRow>
                            {files &&
                                files.map((item, index) => (
                                    <FileRow
                                        key={index}
                                        {...item}
                                        getDate={namedDate}
                                        formatName={formatName}
                                        history={history}
                                        selectFun={(val) =>
                                            selectFile(val, item.fileId)
                                        }
                                        isChecked={selection[item.fileId]}
                                        onClick={() => openDocumentForView(item)}
                                        userDetails={userDetails}
                                        signers={item.signer}
                                        pages={item.pages}
                                        owner={item.owner}
                                        isRecieved={
                                            documentStatus ===
                                            "recieved-for-signature"
                                        }
                                    />
                                ))}
                        </Table>
                    </div>
                    {files && files?.length > 0 && (
                        <div style={{ marginTop: "1rem" }}>
                            <PaginationBar
                                currentPage={currentPage}
                                totalPages={totalPages}
                                size={pageSize}
                                setCurrentPage={(val) => {
                                    setCurrentPage(val)
                                    addQuery("page", val.toString())
                                }}
                                setSize={(val) => {
                                    setPageSize(val)
                                    addQuery("size", val.toString())
                                }}
                            />
                        </div>
                    )}
                </Guard>
            )}
            {(!files || files?.length < 1) && !loading && (
                <div className={classes.noFile}>
                    <NoFileImage />
                    <Text
                        varient="h4"
                        color="#506E91"
                        className={classes.noFileContent}
                    >
                        Get started signing!✨
                    </Text>
                    <Text
                        varient="body"
                        color="#506E91"
                        style={{ margin: "0.25rem 0 0.5rem 0" }}
                    >
                        You've no documents to sign.
                    </Text>
                    <input
                        type="file"
                        onChange={(e) => handleFiles(e.target.files)}
                        hidden
                        multiple={false}
                        ref={inputfile}
                        accept="application/pdf"
                    />
                    <Button
                        className={`${classes.noFileContent} ${classes.noFileBtn}`}
                        onClick={() => inputfile.current.click()}
                    >
                        Upload Document
                    </Button>
                </div>
            )}
        </div>
    )
}

export default InboxRight
