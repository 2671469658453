import React from "react";

import { ReactComponent as FileIcon } from "Assets/Storage/filePdf.svg";
import { createUseStyles } from "react-jss";
import { useBasicUtils } from "hooks/BasicUtility/basicUtils";

/**
 * file description in table.
 * includes name, owner details and pages
 */
const useStyle = createUseStyles(()=>({
    inboxFileWrapper:{
        display: "flex",
    },
    inboxFileIcon: {
        marginRight: "1rem",
    },
    fileName :{
        fontWeight: "600",
        marginBottom: "8px",
        color:"#334760"
    },
    highlightTextBlue:{
        color: "#00b5d1",
        marginRight: "1rem"
    },
    inboxFileMetaDetails:{
        display: "flex",
        alignItems: "center",
    }
}))

const FileDescription = ({ fileName, pages, owner }) => {
    const { splitText } = useBasicUtils()
    const classes = useStyle()
    return (
        <div className={`${classes.inboxFileWrapper}`}>
            <div className={`${classes.inboxFileIcon}`}>
                <FileIcon />
            </div>
            <div className={`${classes.inboxFileDetails}`}>
                <p className={`${classes.fileName}`}>{splitText(fileName,25)}</p>
                <div className={`${classes.inboxFileMetaDetails}`}>
                    {pages && <p className={`${classes.highlightTextBlue}`}>{pages} Pages</p>}
                    <p style={{color:"#7c98b6"}}>Owned by: {owner}</p>
                </div>
            </div>
        </div>
    )
}

export default FileDescription;