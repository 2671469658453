import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import { useState } from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as Arrow } from "../../../../Assets/settings/drop-arrow.svg"
import { ReactComponent as UpgradeIcon } from "../../../../Assets/settings/upgrade-icon.svg"

const useStyles = createUseStyles((theme) => ({
    activityItemContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        color: "#33475B",
        boxSizing: "border-box",
        margin: "10px 0",
    },
    mainContent: {
        display: "flex",
        justifyContent: "space-between",
        alignTtems: "center",
        padding: "15px 17px",
        boxShadow: "0px 3px 6px #DFE3EB",
        borderWidth: "1px 1px 0px 1px",
        borderStyle: "solid",
        borderColor: "#DFE3EB",
        borderRadius: "10px 10px 10px 10px",
        backgroundColor: "#ffffff",
    },
    open: {
        borderRadius: "10px 10px 0px 0px",
    },
    mainLeft: {
        display: "flex",
        alignItems: "center",
        maxWidth: "50%",
    },
    generalLogo: {
        marginRight: 10,
    },
    mainRight: {
        width: "36%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    arrowExpand: {
        cursor: "pointer",
        transform: "rotate(180deg)",
        transition: "all 0.15s",
        "& svg": {
            height: 15,
            width: 15,
        },
    },
    rightOpen: {
        transform: "rotate(0deg)",
    },
    mainTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    titleThin: {
        fontWeight: 400,
        color: "#3F5265",
    },
    mainSubtitle: {
        fontSize: 14,
        fontWeight: 500,
    },
    expandContent: {
        width: "100%",
        boxSizing: "border-box",
        padding: "20px 35px",
        backgroundColor: "white",
        border: "1px solid #DFE3EB",
        boxShadow: "0px 3px 6px #DFE3EB",
        borderRadius: "0px 0px 10px 10px",
        whiteSpace: "pre-wrap",
        color: "#3F5265",
        fontSize: 14,
    },
    upgradeButton: {
        marginRight: ".75rem",
    },
    upgradeButtonContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}))

const SettingActivityItem = ({ data }) => {
    const classes = useStyles()
    const [expand, setExpand] = useState(true)
    const { title, content, logo, titleinfo, isUpgrade } = data
    const handleArrowClick = () => {
        setExpand(!expand)
    }
    return (
        <div className={classes.activityItemContainer}>
            <div className={`${classes.mainContent} ${expand && classes.open}`}>
                <div className={classes.mainLeft}>
                    <div className={classes.generalLogo}>{logo}</div>
                    <div className={classes.mainTitle}>
                        <p className={classes.mainTitle}>{title}</p>
                        <p className={classes.mainSubtitle}>{titleinfo}</p>
                    </div>
                </div>

                <div className={classes.mainRight}>
                    {isUpgrade && (
                        <div className={classes.upgradeButton}>
                            <Button
                                size={"small"}
                                round
                                style={{
                                    backgroundColor: "#FEF8F0",
                                    border: "1px solid #FAE0B5",
                                }}
                            >
                                <div className={classes.upgradeButtonContent}>
                                    <UpgradeIcon style={{ marginRight: ".25rem" }} />
                                    <Text fontSize={12}>Upgrade</Text>
                                </div>
                            </Button>
                        </div>
                    )}
                    <div
                        onClick={() => handleArrowClick()}
                        className={`${classes.arrowExpand} ${
                            !expand ? classes.rightOpen : ""
                        }`}
                    >
                        <Arrow />
                    </div>
                </div>
            </div>
            {expand && <div className={classes.expandContent}>{content}</div>}
        </div>
    )
}
export default SettingActivityItem
