import React from "react"

import "./input.scss"

import { ReactComponent as Eye } from "Assets/LoginIcon/Eye.svg"
import { ReactComponent as EyeOff } from "Assets/LoginIcon/EyeOff.svg"

const InputBox = ({
    placeHolder,
    complete,
    type,
    name,
    value,
    changeFunc,
    req,
    brdr,
    src,
    focus,
    onFocus,
    reverse,
    clickFun,
    ind,
    disable,
    isPasswordShow=false,
    style = {},
}) => {
    const FocusRef = React.useRef([null])
    const [showPassword, setShowPassword] = React.useState(false)

    const handleFocus = () => {
        if(onFocus) onFocus()
        const next = FocusRef.current[0]
        if (next) {
            next.focus()
        }
    }

    const showPasswordHandler = (e) => {
        e.stopPropagation()
        setShowPassword(!showPassword)
    }

    React.useEffect(()=>{
        if(isPasswordShow){
            if(showPassword)
            FocusRef.current[0].type = "text"
            else FocusRef.current[0].type = "password"
        }
    },[showPassword])

    return (
        <div
            className={`inputBox ${focus === ind ? "actBox" : ""}`}
            style={{
                borderColor: brdr ? brdr : null,
                flexDirection: reverse ? "row-reverse" : "row",
                ...style,
            }}
            onFocus={handleFocus}
            onFocusCapture={handleFocus}
            onClick={handleFocus}
        >
            <div
                className="icon"
                onClick={(e) => (clickFun ? clickFun() : e.preventDefault())}
            >
                <img src={src} alt={src} width={"100%"} />
            </div>
            <div className={`inn`}>
                <div className="label">{placeHolder}</div>
                <input
                    ref={(el) => (FocusRef.current[0] = el)}
                    autoComplete={`${complete}`}
                    type={type}
                    name={name}
                    value={value}
                    onChange={(e) => changeFunc(e.target.value)}
                    required={req}
                    autoFocus={ind === focus ? true : false}
                    onFocus={(e) => e.currentTarget.select()}
                    disabled={disable}
                />
                {isPasswordShow && (
                    <div className="password-show" onClick={showPasswordHandler}>
                        {showPassword ? <Eye height={25} /> : <EyeOff height={25} />}
                    </div>
                )}
            </div>
        </div>
    )
}

export default InputBox
