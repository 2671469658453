import React from "react"
import { connect } from "react-redux"
import "./AppBar.scss"

/**
 * To be used as wrapper for the users
 *
 * @param {String} color
 * @param {String} justify Type [space-around, space-between, space-evenly]
 */
const AppBar = ({ color, justify, theme,sidebarType, ...props }) => {
    return (
        <div className={`AppBar AppBar-${(theme==="dark"&&sidebarType==="NO_SIDEBAR")?"dark":"light"}`}>
            <div className={`ToolBar ${justify}`}>{props.children}</div>
        </div>
    )
}

const mapStateToProps = ({settingsReducer}) => ({
    theme: settingsReducer.sideBarColor,
    sidebarType: settingsReducer.sideBarType
})

export default connect(mapStateToProps)(AppBar)
