import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import "./HelpSupportContainer.scss"

import { ReactComponent as BugICON } from "./images/bug.svg"
import { ReactComponent as IdeaIcon } from "./images/idea.svg"
import { ReactComponent as FAQIcon } from "./images/faq.svg"
import { ReactComponent as MessageIcon } from "./images/contact-support.svg"

const supports = [
    {
        name: "Contact Support",
        Icon: MessageIcon,
        directTo: "https://help.awesomesuite.com/portal/en/newticket",
    },
    {
        name: "FAQs",
        Icon: FAQIcon,
        directTo: "https://help.awesomesuite.com/portal/en/kb/awesome-sign",
    },
    // { name: "Submit a bug", Icon: BugICON },
]
/**
 * User Help Supporting Items Container Used In HelpSideNavbar Component
 *
 * Help Suppoert Container
 *
 */

const HelpSupportContainer = () => {
    return (
        <div className="help-support-container">
            <div>
                <Text varient="h5">Support</Text>
                <div className="supports">
                    {supports.map(({ name, Icon, directTo }, index) => (
                        <a
                            href={directTo}
                            key={index}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                        >
                            <div>
                                <div className="icon">
                                    <Icon />
                                </div>
                                <Text marginTop={12} fontSize={14}>
                                    {name}
                                </Text>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HelpSupportContainer
