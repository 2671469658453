import { PageLoader, Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
    editorLoader: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "#2d3e50b8",
        zIndex: 101,
        flexDirection: "column",
    },
}))

const EditorLoader = () => {
    const classes = useStyles()
    return (
        <div className={classes.editorLoader}>
            <PageLoader circleColor={"#ffffff"} interval={1000} />
            <Text varient="h5" color="#ffffff" align="center" marginTop="1.5rem">
                📝 Signing your document...
            </Text>
        </div>
    )
}

export default EditorLoader
