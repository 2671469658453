import React, { useEffect } from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import "./ESignaturePage.scss"

import PdfEditor from "Components/PDFCommons/PdfViewer/PdfEditor"
import SideBar from "Components/PDFCommons/Components/SideBar"
import PdfTop from "./PdfTop/PdfTop"
import PdfTools from "Components/PDFCommons/PdfTools/PdfTools"
import PdfLeftSideBar from "Components/PDFCommons/PdfLeftSideBar/PdfLeftSideBar"

import {
    getRolesByDocumentId,
    initializeDocument,
    setActiveTool,
    setESignFlow,
    togglePdfPagesPreview,
} from "Action/signAction"
import { connect } from "react-redux"
import { getDocSigners, getDocumentById } from "Action/documentAction"
import { useState } from "react"
import Guard from "Awesome-ui/Guard"
import { Helmet } from "react-helmet"
import { DOCUMENT, DRAFT, SEND_FOR_SIGNATURE } from "Types"
import PdfTopInactive from "./PdfTopInactive/PdfTopInactive"
import printJS from "print-js"
import SendForSignAnimation from "./SendForSignAnimation/SendForSignAnimation"
import { getFieldsbyRoleId } from "Action/signersActions"
import SignAnimation from "Components/SignAnimation/SignAnimation"

/**
 * this is the pdf e-signature page with the pdf viewer and editing tools.
 * @param {*} props
 * @returns
 */
const ESignaturePage = (props) => {
    const [activePage, setActivePage] = React.useState(0)
    const [isPageScrolling, setIsPageScrolling] = useState(true)
    const parentRef = React.useRef()
    const [isSetSigning, setIsSetSigning] = useState(() =>
        props.signFlowType ? false : true
    )
    const [isDocForwarding, setIsDocForwarding] = useState(false)
    const [isSentForSign, setIsSentForSign] = useState(false)
    const [initiallyLoading, setInitiallyLoading] = useState(true)

    function print() {
        printJS({
            type: "pdf",
            printable: props.docURL,
        })
    }

    /**
     * clear the current selected tool and bring back the pointer.
     * @param {*} e key press event
     */
    function handlePress(e) {
        if (e.code === "KeyA") return setActiveTool(null)
        let charCode = String.fromCharCode(e.which).toLowerCase()
        if ((e.ctrlKey || e.metaKey) && charCode === "p") {
            e.preventDefault()
            print()
        }
    }

    function saveAllAnnotations() {
        return parentRef.current.saveAllAnnotations()
    }

    async function getPdfDocument() {
        if (!props.match.params) return

        const { documentId } = props.match.params
        if (documentId) {
            const document = await getDocumentById(documentId)
            setIsSetSigning(document.signFlowType ? false : true)
            if (document) initializeDocument(document)
            await getDocSigners(documentId)
            if (document.enableTemplateFlow || document.type !== DOCUMENT) {
                await getRolesByDocumentId(documentId)
                if (document.type === DOCUMENT && document.enableTemplateFlow)
                    setESignFlow(SEND_FOR_SIGNATURE)
            }
        }
    }

    async function initialLoad() {
        if (props.globalLoading) return
        setInitiallyLoading(true)
        await getPdfDocument()
        setInitiallyLoading(false)
    }

    useEffect(() => {
        initialLoad()
        window.addEventListener("keydown", handlePress)

        return () => {
            window.removeEventListener("keydown", handlePress)
        }
    }, [props.match.params])

    function getPdfPages() {
        parentRef.current.getPdfPages()
    }

    if (initiallyLoading)
        return (
            <div className="loaderWrapper">
                <SignAnimation />
            </div>
        )

    return (
        <div style={{ width: "100%", overflow: "hidden", position: "relative" }}>
            <Helmet>
                <title>{props.fileName || "Document"} | Awesome Sign</title>
            </Helmet>
            <DndProvider backend={HTML5Backend}>
                {props.status === DRAFT ? (
                    <PdfTop
                        isSetSigning={isSetSigning}
                        setIsSentForSign={setIsSentForSign}
                        getPdfDocument={getPdfDocument}
                        setIsConvertingtemplateLoading={setInitiallyLoading}
                        saveAllAnnotations={saveAllAnnotations}
                    />
                ) : (
                    <PdfTopInactive getPdfDocument={getPdfDocument} />
                )}
                {props.eSignFlow && (
                    <Guard scope="document_update">
                        <PdfTools />
                    </Guard>
                )}
                <div
                    className="ESignature-page"
                    style={{ height: props.eSignFlow ? "87.6vh" : "92.1vh" }}
                >
                    {isSentForSign && (
                        <SendForSignAnimation setIsSentForSign={setIsSentForSign} />
                    )}
                    <PdfLeftSideBar
                        activePage={activePage}
                        getPdfPages={getPdfPages}
                        setActivePage={setActivePage}
                        pageScrollingHandler={setIsPageScrolling}
                    />
                    <div className="close-preview-pdf">
                        {props.isPdfPagePreview && (
                            <div
                                className="close-preview-pdf-cross"
                                onClick={togglePdfPagesPreview}
                            >
                                ✖
                            </div>
                        )}
                    </div>
                    <div className="ESignature-pdf">
                        <PdfEditor
                            parentRef={parentRef}
                            {...props}
                            activePage={activePage}
                            setActivePage={setActivePage}
                            isPageScrolling={isPageScrolling}
                        />
                    </div>
                    <SideBar
                        isSetSigning={isSetSigning}
                        isDocForwarding={isDocForwarding}
                        setIsSetSigning={setIsSetSigning}
                        setIsDocForwarding={setIsDocForwarding}
                        loading={initiallyLoading}
                    />
                </div>
            </DndProvider>
        </div>
    )
}

const mapStateToProps = ({ signReducer, userReducer }) => ({
    documentId: signReducer.documentDetails.documentId,
    fileName: signReducer.documentDetails.fileName,
    status: signReducer.documentDetails?.status,
    docURL: signReducer.documentDetails.documentUrl,
    isPdfPagePreview: signReducer.isPdfPageView,
    eSignFlow: signReducer.flow,
    globalLoading: userReducer.gloableLoading,
    type: signReducer.documentDetails.type,
    signFlowType: signReducer.documentDetails?.signFlowType,
})

export default connect(mapStateToProps)(ESignaturePage)
