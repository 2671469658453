import React from "react"
import { connect } from "react-redux"

import { ReactComponent as SignIcon } from "Assets/ToolsIcons/sign.svg"
import { ReactComponent as DateIcon } from "Assets/ToolsIcons/dateIcon.svg"
import { ReactComponent as CheckboxIcon } from "Assets/ToolsIcons/checkboxIcon.svg"
import { ReactComponent as ImageIcon } from "Assets/ToolsIcons/imageIcon.svg"
import { SignatureDragType } from "../container/Types"

import { SignerFiledUI } from "../container/DragElements/SignerField/SignerField"


const CursorComponent = ({ activeTool }) => {
    if (!activeTool) return null

    switch (activeTool.type) {
        case SignatureDragType.CHECKBOX:
            return <CheckboxIcon />
        case SignatureDragType.DATE:
            return <DateIcon />
        case SignatureDragType.SIGN:
            return <SignIcon />
        case SignatureDragType.IMAGE:
            return <ImageIcon />
        case SignatureDragType.TYPE:
            return <SignIcon />
        case SignatureDragType.TEXT:
            return null
        case SignatureDragType.SIGNER_FIELD:
            return <SignerFiledUI name={activeTool.data?.name} email={activeTool.data?.email} />
        default:
            return null
    }
}


const MouseComponent = ({ cursorElement, activeTool }) => {
    return (
        <div
            ref={(el) => (cursorElement.current = el)}
            className={`${
                activeTool?.type === SignatureDragType.SIGNER_FIELD
                    ? "signer-fields-stamper"
                    : "pdf-cursor-stamper"
            }`}
        >
            <CursorComponent activeTool={activeTool} />
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    activeTool: signReducer.activeTool,
})

export default connect(mapStateToProps)(MouseComponent)
