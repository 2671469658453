import React, { Fragment } from "react"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import TreeComponent from "Awesome-ui/TreeComponent/TreeComponent"
import { useHistory } from "react-router-dom/cjs/react-router-dom"

const activeHeaderStyle = {
    backgroundColor: "#eaf0f6",
    color: "#33475b",
}

const TemplatesLeft = () => {
    const history = useHistory()
    React.useEffect(() => {
        if (
            history.location.pathname === "/templates" ||
            history.location.pathname === "/templates/"
        )
            history.push("/templates/my-templates?page=1&size=10")
    }, [history.location.pathname])
    return (
        <Fragment>
            <TooltipWrapper
                text={"All templates owned by you"}
                direction={"right"}
                align="center"
            >
                <TreeComponent
                    title="My Templates"
                    headerStyle={
                        history.location.pathname?.includes("my-templates")
                            ? activeHeaderStyle
                            : {}
                    }
                    onClick={() => history.push("/templates/my-templates?page=1&size=10")}
                    style={{ margin: "2rem auto 0 auto", width: "85%" }}
                />
            </TooltipWrapper>
            <TooltipWrapper
                text={"Templates collection from Awesome Sign Library."}
                direction={"right"}
                align="center"
            >
                <TreeComponent
                    title="Templates Library"
                    headerStyle={
                        history.location.pathname?.includes("templates-library")
                            ? activeHeaderStyle
                            : {}
                    }
                    onClick={() => history.push("/templates/templates-library")}
                    style={{ margin: "1rem auto", width: "85%" }}
                />
            </TooltipWrapper>
        </Fragment>
    )
}

export default TemplatesLeft
