import {
    ADD_DATA_TO_ELEMENT,
    ADD_FIELD_TO_DOCUMENT,
    ADD_ROLE_TO_TEMPLATE,
    ADD_SIGNATURE_ELEMENT,
    ADD_USER_SIGN,
    CAPTURE_USER_ACTION,
    CAPTURE_USER_CLICK,
    CLEAR_E_SIGN_DATA,
    CLICK_TO_SIGN_ALL,
    CLICK_TO_SIGN_EACH,
    DELETE_USER_SIGN,
    DRAG_SIGNATURE_ELEMENT,
    FINISH_AND_SIGN,
    GET_ROLES_OF_TEMPLATE,
    GET_USER_SIGNS,
    INITIALIZE_DOCUMENT_SIGNATURE,
    REMOVE_ROLE_FROM_TEMPLATE,
    REMOVE_SIGNATURE_ELEMENT,
    RESIZE_SIGNATURE_ELEMENT,
    SET_ACTIVE_TAB,
    SET_ACTIVE_TOOL,
    SET_ACTIVITY_LOGS,
    SET_IS_DOCUMENT_ACCEPTED,
    SET_PDF_PAGES_PREVIEW,
    SET_SEND_FOR_SIGNATURE_TYPE,
    SET_SIGNATURE_FLOW,
    SET_TEMPLATE_VIEW,
    TOGGLE_PDF_PAGE_PREVIEW,
    UPDATE_ROLE_OF_TEMPLATE,
    UPDATE_SIGNATURE_ELEMENT,
    UPDATE_VALUE_OF_TEMPLATE_ITEM,
} from "../ActionTypes/signActionTypes"
import {
    UPDATE_DOCUMENT_DETAILS_FAILED,
    UPDATE_DOCUMENT_DETAILS_LOADING,
} from "ActionTypes/documentType"

import {
    SignatureDragType,
    TemplateElementsDragType,
} from "Components/PDFCommons/container/Types"
import { store } from "../store"
import { getHeaders } from "./ActionHelper"
import { eSignApi } from "Apis/AllApisMapping"
import { addFields } from "./signersActions"
import { createMessage } from "./messageAndNotificationActions"
import { log } from "@awesomesuite-frontend/awesome-nebula"
import { CLICK_FOR_SIGN, DANGER, SIGNER, SUCCESS } from "Types"
import { getCanvasSize } from "Library/canvasAdjuster"
import { getSignerBackgroundDecorum } from "Library/randomBackgroundGenerator"

// variables
const { dispatch, getState } = store
const CHECK_BOX_IMAGE = "https://d268w8hu7j31i6.cloudfront.net/check.png"

/**
 * clear the esign data so that new pdf can be mount and
 * edit without seeing the old data.
 */
export const clearESignData = () => {
    dispatch({ type: CLEAR_E_SIGN_DATA })
}

/**
 * initialize the document for the editing and signing
 * @param {*} documentUrl document url
 * @param {*} documentId document id
 */
export const initializeDocument = (document) => {
    clearESignData()
    dispatch({
        type: INITIALIZE_DOCUMENT_SIGNATURE,
        payload: { document },
    })
}

/**
 * add the small thumbnails for the preview.
 * @param {Array} pageArr  array of the document image and page number
 */
export const addPdfPagesPreview = (pageArr) => {
    dispatch({ type: SET_PDF_PAGES_PREVIEW, payload: { pageArr } })
}

/**
 * enable or disable the left side bar with the method call.
 */
export const togglePdfPagesPreview = () => {
    dispatch({ type: TOGGLE_PDF_PAGE_PREVIEW })
}

/**
 * set the flow of the signature SEND TO SIGNATURE OR SIGN NOW.
 * @param {*} flow eSign flow the.
 */
export const setESignFlow = (flow) => {
    dispatch({ type: SET_SIGNATURE_FLOW, payload: { flow } })
}

/**
 * add the signature element  (SIGN, IMAGE, TEXT)
 * @param {*} pageIndex
 * @param {*} details
 */
export const addSignatureElement = async (pageIndex, details, dimension) => {
    dispatch({
        type: ADD_SIGNATURE_ELEMENT,
        payload: { pageIndex, details, dimension },
    })
    if (
        details.type === SignatureDragType.SIGNER_FIELD ||
        (details.isTemplateItem &&
            Object.values(TemplateElementsDragType).includes(details.type)) ||
        details.data?.signerId
    ) {
        const res = await addFields(
            details.data.signerId,
            pageIndex,
            details.xAxis,
            details.yAxis - (details?.height || 72),
            details.top,
            details.left,
            "",
            details?.height || 72,
            details?.width || 274,
            dimension.height,
            dimension.width,
            details.data.roleId,
            details.type,
            details.data.subType
        )
        dispatch({
            type: UPDATE_SIGNATURE_ELEMENT,
            payload: {
                pageIndex,
                details: { ...details, ...dimension },
                currentId: res.id,
            },
        })
    }
}

/**
 * remove the signature element from the list.
 * @param {*} pageIndex
 * @param {*} id
 */
export const removeSignatureElement = (
    pageIndex,
    id,
    signerId,
    signId,
    type,
    isTemplateItem = false
) => {
    dispatch({
        type: REMOVE_SIGNATURE_ELEMENT,
        payload: { pageIndex, id, signerId, signId, type, isTemplateItem },
    })
}

/**
 * element resize handler in the
 * @param {*} pageIndex
 * @param {*} id
 * @param {*} width
 * @param {*} height
 */
export const resizeSignatureElement = (
    pageIndex,
    id,
    width,
    height,
    xAxis,
    yAxis,
    left,
    top
) => {
    dispatch({
        type: RESIZE_SIGNATURE_ELEMENT,
        payload: { id, width, height, pageIndex, xAxis, yAxis, left, top },
    })
}

export const dragSignatureElement = (pageIndex, id, xAxis, yAxis, top, left) => {
    dispatch({
        type: DRAG_SIGNATURE_ELEMENT,
        payload: { pageIndex, id, xAxis, yAxis, top, left },
    })
}

export const addDataToElement = (pageIndex, id, data) => {
    dispatch({ type: ADD_DATA_TO_ELEMENT, payload: { pageIndex, id, data } })
}

/**
 * this will handle currently active tool { sign, checkbox, text etc}
 * @param {*} toolType - tool type
 */
export const setActiveTool = (toolDetails) => {
    dispatch({ type: SET_ACTIVE_TOOL, payload: { toolDetails } })
    return toolDetails
}

export const setActivityLog = (logs) => {
    dispatch({ type: SET_ACTIVITY_LOGS, payload: { logs } })
}

export const captureUserClick = (data) => {
    dispatch({ type: CAPTURE_USER_CLICK, payload: { ...data } })
}

export const captureUserAction = (data) => {
    dispatch({ type: CAPTURE_USER_ACTION, payload: { ...data } })
}

export const clickToSignAll = () => {
    const { signature } = store.getState().signReducer
    Object.keys(signature).map((pageIndex) => {
        const details = signature[pageIndex]
        if (!details) return
        Object.keys(details).map((item) => {
            const signature = details[item]
            if (signature.type === SignatureDragType.SIGNER_FIELD)
                signature.isSigned = true
        })
    })
    dispatch({ type: CLICK_TO_SIGN_ALL, payload: { signature: { ...signature } } })
}

export const clickToSignEach = (pageIndex, id) => {
    const { signature } = store.getState().signReducer
    const details = signature[pageIndex]
    if (!details) return
    const signatureDetails = Object.values(details).find((item) => item.id === id)
    signatureDetails.isSigned = true
    dispatch({ type: CLICK_TO_SIGN_EACH, payload: { signature: { ...signature } } })
}

/**
 * handle the stamping of the document when user is signed in or when it is in public domain.
 * @param {*} createCanvasWithText - handler to create canvas for each text
 * @param {*} mediaHandler - media handler
 */
export const finishAndSign = async (
    createCanvasWithText,
    mediaHandler,
    securityHash,
    typeOfSignature,
    annotations
) => {
    const mediaApi = mediaHandler
    const headers = getHeaders()

    dispatch({ type: UPDATE_DOCUMENT_DETAILS_LOADING })

    const { userReducer, signReducer } = store.getState()
    const { documentDetails, signature: pages, dimension, editingMode } = signReducer
    const { token } = userReducer

    const documentId = documentDetails.documentId

    if (!documentId) return

    const signatureElement = {}

    if (!pages) return

    let isSignPresent = false

    await Promise.all(
        Object.keys(pages).map(async (pageIndex) => {
            const details = pages[pageIndex]

            if (!details) return

            await Promise.all(
                Object.keys(details).map(async (item) => {
                    const signature = details[item]
                    let configuration = null
                    const newPageIndex = parseInt(pageIndex) + 1

                    if (!signatureElement[newPageIndex])
                        signatureElement[newPageIndex] = []

                    if (
                        typeOfSignature === CLICK_FOR_SIGN &&
                        signature.type === SignatureDragType.SIGNER_FIELD
                    ) {
                        isSignPresent = true
                        configuration = getSignatureConfiguration(
                            signature,
                            null,
                            null,
                            securityHash
                        )
                        signatureElement[newPageIndex].push(configuration)
                        return
                    }

                    if (
                        signature.type === SignatureDragType.SIGN ||
                        signature.type === SignatureDragType.TYPE
                    ) {
                        isSignPresent = true
                        signature.type = SignatureDragType.SIGNER_FIELD
                    }

                    if (
                        signature.type === SignatureDragType.IMAGE ||
                        signature.type === SignatureDragType.SIGNER_FIELD
                    ) {
                        signature.type =
                            signature.type === SignatureDragType.SIGNER_FIELD
                                ? SignatureDragType.SIGNER_FIELD
                                : SignatureDragType.SIGN
                        configuration = getSignatureConfiguration(
                            signature,
                            signature.mediaPath,
                            signature.mediaId,
                            securityHash
                        )
                        signatureElement[newPageIndex].push(configuration)
                        return
                    }
                    if (signature.type === SignatureDragType.CHECKBOX) {
                        configuration = getSignatureConfiguration(
                            signature,
                            CHECK_BOX_IMAGE,
                            `checkBox ${item}`,
                            securityHash
                        )
                        if (!configuration.height || !configuration.width) {
                            configuration.height = 20
                            configuration.width = 20
                        }
                        signatureElement[newPageIndex].push(configuration)
                        return
                    }
                    if (signature.data) {
                        let image
                        if (signature.isTemplateItem || signature.data.signerId)
                            image = createCanvasWithText(
                                signature.data.value,
                                signature.width || 120,
                                signature.height || 38,
                                2.3,
                                16,
                                '"Poppins", sans-serif'
                            )
                        else
                            image = createCanvasWithText(
                                signature.data,
                                signature.width,
                                signature.height,
                                2.3,
                                16,
                                '"Poppins", sans-serif'
                            )
                        let uploadElement = null

                        if (token) {
                            uploadElement = await mediaApi.uploadMediaBlob(
                                image,
                                token,
                                "image/png",
                                `image ${item}.png`
                            )
                        } else {
                            uploadElement = await mediaApi.uploadMediaBlobPublic(
                                image,
                                `image ${pageIndex}_${item}.png`
                            )
                        }

                        configuration = getSignatureConfiguration(
                            signature,
                            uploadElement.mediaPath,
                            uploadElement.mediaId,
                            securityHash
                        )
                        signatureElement[newPageIndex].push(configuration)
                    }
                })
            )
        })
    )

    if (!isSignPresent && editingMode !== SIGNER) {
        dispatch({ type: UPDATE_DOCUMENT_DETAILS_FAILED })
        return createMessage(
            "No signature found",
            "Please add a signature to sign the document",
            DANGER
        )
    }

    const data = {
        documentId: documentId,
        token: securityHash,
        stamperElement: signatureElement,
        pageDimension: dimension,
    }

    data.annotationElements = annotations ? annotations : []

    let finalFile = null

    if (!securityHash) finalFile = await eSignApi.post("/sign", data, headers)
    else {
        finalFile = await eSignApi.post("/public/sign", data)
    }

    if (finalFile.data.model) {
        createMessage(
            "Document Updated Successfully!",
            "You have made some modifications in a document. you can download the it in pdf format.",
            SUCCESS
        )
        dispatch({
            type: FINISH_AND_SIGN,
            payload: { link: finalFile.data.model?.mediaPath },
        })
        if (securityHash)
            window.location = `/sign/signature/success?url=${finalFile.data.model?.mediaUrl}&name=${documentDetails?.fileName}`
        return 1
    } else {
        createMessage(
            "Failed to Updated Document !",
            "Something went wrong while saving the document",
            DANGER
        )
        dispatch({ type: UPDATE_DOCUMENT_DETAILS_FAILED })
    }
}

export const setSendForSignatureType = (type) => {
    dispatch({ type: SET_SEND_FOR_SIGNATURE_TYPE, payload: { type } })
}

export const addUserSign = async (sign) => {
    if (!sign) return
    const userId = getState().userReducer.userDetails.id
    const headers = getHeaders()
    const newUserSignature = {
        userId,
        signUrl: sign.mediaPath,
        type: sign.type,
    }
    try {
        const resp = await eSignApi.post(
            "/user-signature",
            newUserSignature,
            headers
        )
        if (resp.data.statusCode === 200) {
            const signResponse = resp.data.model
            dispatch({
                type: ADD_USER_SIGN,
                payload: { sign: signResponse },
            })
            return signResponse
        } else {
            dispatch({
                type: ADD_USER_SIGN,
                payload: { sign: newUserSignature },
            })
        }
    } catch (err) {
        dispatch({
            type: ADD_USER_SIGN,
            payload: { sign: newUserSignature },
        })
        console.log(err)
        // createMessage(
        //     "Failed to add signature !",
        //     "Something went wrong while adding the signature",
        //     DANGER
        // )
    }
}
export const getUserSigns = async () => {
    const userId = getState().userReducer.userDetails.id
    const headers = getHeaders()
    try {
        const resp = await eSignApi.get(`/user-signature?userId=${userId}`, headers)
        if (resp.data.statusCode === 200) {
            const userSigns = resp.data.model
            dispatch({ type: GET_USER_SIGNS, payload: { userSigns } })
            return userSigns
        } else throw new Error("Something went wrong while getting the signatures")
    } catch (err) {
        console.log(err)
        // createMessage(
        //     "Failed to get signature !",
        //     "Something went wrong while getting the signatures",
        //     DANGER
        // )
    }
}
export const deleteUserSign = async (signatureId) => {
    if (!signatureId) return
    dispatch({ type: DELETE_USER_SIGN, payload: { signatureId } })
    const headers = getHeaders()
    try {
        const resp = await eSignApi.delete(
            `/user-signature?signatureId=${signatureId}`,
            headers
        )
        if (resp.data.statusCode === 200) {
            dispatch({ type: DELETE_USER_SIGN, payload: { signatureId } })
            return true
        } else throw new Error("Something went wrong while deleting the signature")
    } catch (err) {
        console.log(err)
        // createMessage(
        //     "Failed to delete signature !",
        //     "Something went wrong while deleting the signature",
        //     DANGER
        // )
    }
}

export const setActiveTab = (tab) => {
    dispatch({ type: SET_ACTIVE_TAB, payload: { tab } })
}

export const setTemplateItemValue = (value, detailsOfPositions) => {
    dispatch({
        type: UPDATE_VALUE_OF_TEMPLATE_ITEM,
        payload: { value, detailsOfPositions },
    })
}

export const getRolesByDocumentId = async (fileId) => {
    const headers = getHeaders()
    try {
        const resp = await eSignApi.get(`/roles?fileId=${fileId}`, headers)
        if (resp.data.statusCode === 200) {
            const roles = resp.data.model
            roles.forEach(
                (role, id) =>
                    (role.backgroundDecorum = getSignerBackgroundDecorum(
                        role.roleName?.slice(2),
                        id + 1
                    ))
            )
            dispatch({ type: GET_ROLES_OF_TEMPLATE, payload: { roles } })
            return roles
        } else throw new Error("Something went wrong while getting the roles")
    } catch (err) {
        console.log(err)
        createMessage(
            "Failed to get roles !",
            "Something went wrong while getting the roles",
            DANGER
        )
    }
}

export const addRoleToTemplate = async (fileId, roleName) => {
    if (!roleName)
        return createMessage(
            "Role name is missing !",
            "Please provide the role name",
            DANGER
        )
    const data = { fileId, roleName }
    const { roles } = getState().signReducer
    let number = roles?.length || 0
    const headers = getHeaders()
    try {
        const resp = await eSignApi.post("/roles", data, headers)
        if (resp.data.statusCode === 200) {
            const role = resp.data.model
            role.backgroundDecorum = getSignerBackgroundDecorum(
                role.roleName?.slice(2),
                number + 1
            )
            dispatch({ type: ADD_ROLE_TO_TEMPLATE, payload: { role } })
            return role
        } else throw new Error("Something went wrong while adding the role")
    } catch (err) {
        console.log(err)
        createMessage(
            "Failed to add role !",
            "Something went wrong while adding the role",
            DANGER
        )
    }
}

export const removeRoleFromTemplate = async (roleId, roleName = "") => {
    if (!roleId) return
    const headers = getHeaders()
    let { roles } = getState().signReducer
    try {
        const resp = await eSignApi.delete(`/roles?roleId=${roleId}`, headers)
        if (resp.data.statusCode === 200) {
            dispatch({ type: REMOVE_ROLE_FROM_TEMPLATE, payload: { roleId } })
            createMessage(roleName + " Role deleted successfully !", SUCCESS)
            return true
        } else throw new Error("Something went wrong while deleting the role")
    } catch (err) {
        console.log(err)
        createMessage(
            "Failed to delete role !",
            "Something went wrong while deleting the role",
            DANGER
        )
    }
}

export const updateRoleOfTemplate = async (roleId, updatedData) => {
    const data = { ...updatedData }
    const headers = getHeaders()
    try {
        const resp = await eSignApi.put(`/roles?roleId=${roleId}`, data, headers)
        if (resp.data.statusCode === 200) {
            const role = resp.data.model
            dispatch({ type: UPDATE_ROLE_OF_TEMPLATE, payload: { role } })
            return role
        } else throw new Error("Something went wrong while updating the role")
    } catch (err) {
        console.log(err)
        createMessage(
            "Failed to update role !",
            "Something went wrong while updating the role",
            DANGER
        )
    }
}

export const setTemplateView = (view) => {
    dispatch({ type: SET_TEMPLATE_VIEW, payload: { view } })
}

export const setDocumentAccept = (isAccepted) => {
    dispatch({ type: SET_IS_DOCUMENT_ACCEPTED, payload: { isAccepted } })
}

function getOriginalDimensionOfElementPerPage(field) {
    const { height: currentHeightOfPage, width: currentWidthOfPage } = getCanvasSize(
        field.pageHeight / field.pageWidth
    )
    const { pageHeight, pageWidth, height, width, left, top, xAxis, yAxis } = field
    const heightRatio = pageHeight / currentHeightOfPage
    const widthRatio = pageWidth / currentWidthOfPage
    const newHeight = height * heightRatio
    const newWidth = width * widthRatio
    const newLeft = left * widthRatio
    const newTop = top * heightRatio
    const newXAxis = xAxis * widthRatio
    const newYAxis = yAxis * heightRatio
    return {
        height: newHeight,
        width: newWidth,
        left: newLeft,
        top: newTop,
        xAxis: newXAxis,
        yAxis: newYAxis,
    }
}

function getSignatureConfiguration(signature, mediaPath, mediaId, securityHash) {
    // if (securityHash)
    //     // in sign now flow, original dimension of element is not required
    //     signature = {
    //         ...signature,
    //         ...getOriginalDimensionOfElementPerPage(signature),
    //     }
    return {
        id: signature.id,
        width: signature.width,
        height: signature.height,
        top: signature.top,
        left: signature.left,
        xAxis: signature.xAxis,
        yAxis: signature.yAxis,
        mediaPath: mediaPath,
        mediaId: mediaId,
        type: signature.type,
        data: JSON.stringify(signature.data),
        reason: "signature",
        pageHeight: signature.pageHeight,
        pageWidth: signature.pageWidth,
    }
}
