import React from "react"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { RECIEVED_FOR_SIGNATURE } from "Types"
import "./RecentDocuments.scss"
import DocumentStatus from "./components/DocumentStatus"
import RecentDocumentsSkeleton from "./components/RecentDocumentsSkeleton"
import NoFiles from "./components/NoFiles"

const RecentDocumentsWrapper = ({ children }) => {
    return (
        <div className="aui-document-status">
            <Text className="document-status-title">Documents Status</Text>
            {children}
        </div>
    )
}

const RecentDocuments = ({ filterDoc, imageSource, loading, filterDocStatus }) => {
    if (loading)
        return (
            <RecentDocumentsWrapper>
                <RecentDocumentsSkeleton />
            </RecentDocumentsWrapper>
        )

    if (filterDoc.length === 0)
        return (
            <RecentDocumentsWrapper>
                <NoFiles status={filterDocStatus} />
            </RecentDocumentsWrapper>
        )

    return (
        <RecentDocumentsWrapper>
            {filterDoc.slice(0, 4).map((item, key) => (
                <DocumentStatus
                    key={key}
                    {...item}
                    name={`${item.owner?.firstName} ${item.owner?.lastName}`}
                    imageSource={imageSource}
                    status={item.isRecieved ? RECIEVED_FOR_SIGNATURE : item.status}
                />
            ))}
        </RecentDocumentsWrapper>
    )
}

export default RecentDocuments
