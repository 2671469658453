import React, { useEffect, useState } from "react"

import TableRow from "Awesome-ui/TableRow/TableRow"
import TableCell from "Awesome-ui/TableCell/TableCell"
import FileDescription from "../FileDescription/FileDescription"
import LabelChip from "Awesome-ui/LabelChip"
import {
    Avatar,
    CheckBox,
    Dropdown,
    Text,
} from "@awesomesuite-frontend/awesome-nebula"
import AvatarGroup from "Pages/Storage/Components/AvatarGroup"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import SignerStatusChip, {
    signerStatus,
} from "Awesome-ui/SignerStatusChip/SignerStatusChip"
import { connect } from "react-redux"
import { DRAFT } from "Types"

/**
 * Rows of the files with description
 */

const FileRow = ({
    fileName,
    pages,
    updatedAt,
    owner,
    getDate,
    formatName,
    history,
    fileId,
    selectFun,
    isChecked,
    onClick,
    userDetails,
    signer,
    tag,
    isRecieved,
    status,
    ...props
}) => {
    const { splitText } = useBasicUtils()

    const [signers, setSigners] = useState([])

    useEffect(() => {
        if (signer) {
            let newSigners = signer.map((eachSigner) => ({
                name: eachSigner.firstName + " " + eachSigner.lastName,
                email: splitText(eachSigner.emailId, 20),
                statusHighlightColor:
                    signerStatus[eachSigner.signerStatus]?.color || "#7C98B6",
                statusElement: <SignerStatusChip status={eachSigner.signerStatus} />,
            }))
            setSigners(newSigners)
        }
    }, [signer])
    return (
        <>
            <TableRow
                style={{
                    backgroundColor: isChecked ? "#E2F4FC" : "white",
                    border: `1px solid ${isChecked ? "#3DC1F2" : "#d6dfe8"}`,
                    cursor: "pointer",
                }}
            >
                {!isRecieved && (
                    <TableCell
                        style={{
                            backgroundColor: "#F4F7F9",
                            border: "1px solid #F4F7F9",
                            borderRightColor: isChecked ? "#3DC1F2" : "#d6dfe8",
                        }}
                    >
                        <CheckBox
                            active={isChecked}
                            clickFunction={() => selectFun(!isChecked)}
                            color={"white"}
                            backgroundColor={"rgb(0, 189, 165)"}
                            variant={"curved"}
                            border={"lightblue"}
                        />
                    </TableCell>
                )}
                <TableCell align="left" onClick={onClick}>
                    <TooltipWrapper
                        text={fileName}
                        direction={"top"}
                        textStyle={{ wordBreak: "break-all" }}
                    >
                        <div onClick={onClick}>
                            <FileDescription
                                fileName={fileName}
                                pages={pages}
                                owner={`${
                                    owner?.firstName
                                        ? owner?.firstName + " " + owner?.lastName
                                        : owner?.userName
                                }`}
                            />
                        </div>
                    </TooltipWrapper>
                </TableCell>
                <TableCell align="center" onClick={onClick}>
                    <Avatar
                        name={`${
                            owner?.firstName
                                ? owner?.firstName + " " + owner?.lastName
                                : owner?.userName
                        } `}
                        email={owner?.emailId}
                        imageSource={owner.picture}
                        isTooltip
                    />
                </TableCell>
                <TableCell
                    align="center"
                    onClick={onClick}
                >
                    {status !== DRAFT && (
                        <AvatarGroup data={signers} limit={5} noStatusCircleOffset />
                    )}
                </TableCell>
                <TableCell align="center">
                    <TooltipWrapper text={"Document tag"} direction={"top"}>
                        <LabelChip>{tag ? formatName(tag) : ""}</LabelChip>
                    </TooltipWrapper>
                </TableCell>
                <TableCell align="center">{getDate(updatedAt)}</TableCell>
            </TableRow>
            <div style={{ height: "1rem" }}></div>
        </>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    status: signReducer.status,
})

export default connect(mapStateToProps)(FileRow)
