import crown from "../Assets/Roles/crown.webp"
import megaphone from "../Assets/Roles/hand-hold-megaphone.webp"
import team from "../Assets/Roles/handshake.webp"
import guest from "../Assets/Roles/facebook-group.webp"
import user from "../Assets/Roles/offer.webp"
export const rolesDescription = {
    Admin: {
        name: "Admin",
        desc: "Leader of Team",
        // image: <img src={crown} />,
    },
    Manager: {
        name: "Manager",
        desc: "Manages the team",
        // image: <img src={megaphone} alt="" />,
    },
    "Team Member": {
        name: "Team member",
        desc: "Member in the Team",
        // image: <img src={team} alt="" />,
    },
    Guest: {
        name: "Guest",
        desc: "Guest Member of Team",
        // image: <img src={guest} alt="" />,
    },
    User: {
        name: "User",
        desc: "No Special Responsibilities",
        // image: <img src={user} alt="" />,
    },
    Agent: {
        name: "Agent",
        desc: "Negotiator",
    },
}
