import React, { memo, useEffect, useImperativeHandle, useRef } from "react"
import "./PdfEditor.scss"
import "../../../Components/PdfViewer/pdf.css"
import { connect } from "react-redux"
import DragItemCatcher from "../container/DragItemCatcher/DragItemCatcher"

import usePdfHook from "../../../hooks/PdfLib/PdfHook"
import {
    addPdfPagesPreview,
    addSignatureElement,
    setActiveTool,
} from "Action/signAction"

import { SignatureDragType, TemplateElementsDragType } from "../container/Types"
import MouseComponent from "./MouseComponent"
import PdfCanvas from "./PdfCanvas"
import DropZone from "Pages/PdfEditor/container/DropZone"
import { v4 as uuidv4 } from "uuid"
import TextLayer from "Components/PdfViewer/Layers/TextLayer"
import { SIDEBAR_ITEM, TEMPLATE_ITEM } from "Pages/PdfEditor/container/Constants"
import { getCanvasSize } from "Library/canvasAdjuster"
import AnnotationLayer from "Components/PdfViewer/Layers/AnnotationLayer"

//Debug
const allowedTemplateItems = Object.values(TemplateElementsDragType)

const eachAnnotationHandler = (annotationId, storage, eachPageAnnotations) => {
    const element = document.querySelector(`[data-annotation-id="${annotationId}"]`)
    let name = element?.childNodes[0]?.getAttribute("name")
    if (!name) name = element?.childNodes[0]?.getAttribute("data-name")
    return {
        value:
            element.childNodes[0].type === "checkbox"
                ? eachPageAnnotations[annotationId].value
                    ? "Yes"
                    : "Off"
                : eachPageAnnotations[annotationId].value,
        type: element.childNodes[0].type,
        name,
    }
}

const annotationsofEachStorageHandler = (storage) => {
    const eachPageAnnotations = storage.annotationStorage.getAll()
    if (!eachPageAnnotations) return []
    const res = []
    Object.keys(eachPageAnnotations).map((annotationId) => {
        const annotation = eachAnnotationHandler(
            annotationId,
            storage,
            eachPageAnnotations
        )
        if (annotation) res.push(annotation)
    })
    return res
}

/**
 * pdf viewer to show pdf and it contains the drag items
 *
 * @param {*} props params.pdfUrl is link to the pdf came for the editing.
 */
const PdfEditor = memo(
    ({
        activePage,
        activeTool,
        documentUrl,
        style,
        canvasStyle,
        disable,
        parentRef,
        isPageScrolling,
        setActivePage,
        status,
        signature,
        editingMode,
    }) => {
        var pdfWrapper = React.useRef()
        var pdfRefs = React.useRef([])
        const {
            pdfData,
            loadPdf,
            loadedPdf,
            loadPdfLib,
            loadedLib,
            numberOfPages,
            getAllPdfPagesForCurrentPdf,
        } = usePdfHook()
        const annotationStorages = React.useRef([])
        const cursorElement = React.useRef()
        const [isMouseOverElement, setIsMouseOverElement] = React.useState(false)
        const [allowSign, setAllowSign] = React.useState(true)

        useEffect(() => {
            async function getPdf() {
                if (loadedLib && documentUrl) {
                    await loadPdf(documentUrl)
                } else loadPdfLib()
            }

            getPdf()
        }, [loadedLib, documentUrl, status])

        /**
         * this will handle page scroll and page number of current page visible to the user
         * @param {*} pageNumber - page number of the the pdf
         */
        useEffect(() => {
            if (activePage > -1 && pdfRefs.current[activePage]) {
                const activePageRef = pdfRefs.current[activePage]
                const details = activePageRef.getBoundingClientRect()
                const pdfWrapperRef = pdfWrapper.current
                pdfWrapperRef.scrollTo({
                    top: pdfWrapperRef.scrollTop + details.y - 150,
                })
            }
        }, [isPageScrolling])

        useImperativeHandle(parentRef, () => ({
            async getPdfPages() {
                if (loadedLib && documentUrl) {
                    const result = await getAllPdfPagesForCurrentPdf(
                        null,
                        addPdfPagesPreview
                    )
                    addPdfPagesPreview(result)
                }
            },
            saveAllAnnotations() {
                let allAnnotations = []
                annotationStorages.current.map((annotationStorage) =>
                    allAnnotations.push(
                        ...annotationsofEachStorageHandler(annotationStorage)
                    )
                )
                return allAnnotations
            },
        }))

        /**
         * @param {*} e - mouse move event to get the client and screen offset where user
         *                want to sign his document. will require later for the
         * @param {*} indexOfCanvas - canvas or pdf page number where user have dragged or click
         */
        async function hadleMouseClick(e, indexOfCanvas, height, width) {
            if (!activeTool) return
            if (isMouseOverElement) return
            setAllowSign(false)
            const canvas = pdfRefs.current[indexOfCanvas].getBoundingClientRect()

            const pdfWrapperDom = pdfWrapper.current
            const cursorDom = cursorElement.current
            if (!cursorDom) return

            const top = e.clientY - canvas.top
            let left = e.clientX - canvas.left

            const randomId = uuidv4()

            let data = {
                data: activeTool?.data,
                id: activeTool.id,
                left: left,
                top: top,
                xAxis: left,
                yAxis: top,
                mediaPath: activeTool.mediaPath,
                type: activeTool.type,
                randomId,
            }
            let dimension = {
                height,
                width,
            }
            await addSignatureElement(indexOfCanvas, data, dimension)
            setAllowSign(true)
            setActiveTool(null)
        }

        /**
         * get the position or bonds of the pdf page.
         * */
        function getClientPos(id) {
            const canvas = pdfRefs.current[id]
            return canvas.getBoundingClientRect()
        }

        /**
         * handle currently active stamping element appearance on the cursor
         * @param {object} e mouse event
         */
        function onMouseMove(e) {
            const pdfWrapperDom = pdfWrapper.current
            const scrollWrapper = pdfWrapperDom.getBoundingClientRect()
            const cursorDom = cursorElement.current
            if (!cursorDom) return

            const top = e.pageY + pdfWrapperDom.scrollTop - scrollWrapper.top - 5
            const left = e.clientX - scrollWrapper.left + 6
            cursorDom.style.top = `${top}px`
            cursorDom.style.left = `${left}px`
        }

        const activePageScrollHandler = () => {
            let start = 0,
                end = numberOfPages - 1
            while (start <= end) {
                let mid = Math.floor((start + end) / 2)
                if (mid === numberOfPages - 1) {
                    setActivePage(numberOfPages - 1)
                    break
                }

                if (pdfRefs.current[mid]) {
                    const midRef1 = pdfRefs.current[mid]
                    const details1 = midRef1.getBoundingClientRect()
                    const midRef2 = pdfRefs.current[mid + 1]
                    const details2 = midRef2.getBoundingClientRect()

                    if (details1.y > 150) end = mid - 1
                    else if (details2.y <= 150) start = mid + 1
                    else {
                        setActivePage(mid)
                        break
                    }
                }
            }
        }

        React.useEffect(() => {
            if (pdfWrapper.current && loadedPdf)
                pdfWrapper.current.addEventListener(
                    "scroll",
                    activePageScrollHandler
                )

            return () =>
                pdfWrapper.current?.removeEventListener(
                    "scroll",
                    activePageScrollHandler
                )
        }, [pdfWrapper.current, loadedPdf])

        return (
            <div
                className="pdf-render-page"
                ref={(el) => (pdfWrapper.current = el)}
                onMouseMove={onMouseMove}
                onMouseEnter={onMouseMove}
                onMouseLeave={onMouseMove}
                style={{
                    cursor:
                        (!allowSign && "wait") ||
                        (activeTool &&
                            activeTool?.type === SignatureDragType.TEXT &&
                            "text"),
                    ...style,
                }}
            >
                {!loadedPdf && (
                    <div className="pdf-render-loader-wrapper">
                        <div className="pdf-render-loader"></div>
                    </div>
                )}
                <div
                    className="pdf-canvas-wrapper"
                    style={{
                        visibility: loadedPdf ? "visible" : "hidden",
                        ...canvasStyle,
                    }}
                >
                    {Array(numberOfPages)
                        .fill()
                        .map((item, index) => (
                            <EachPage
                                index={index}
                                allowSign={allowSign}
                                pdfRefs={pdfRefs}
                                pdfData={pdfData}
                                disable={disable}
                                getClientPos={getClientPos}
                                setIsMouseOverElement={setIsMouseOverElement}
                                isMouseOverElement={isMouseOverElement}
                                setAllowSign={setAllowSign}
                                cursorElement={cursorElement}
                                pdfWrapper={pdfWrapper}
                                activeTool={activeTool}
                                handleClick={hadleMouseClick}
                                editingMode={editingMode}
                                key={index}
                                annotationStorages={annotationStorages}
                                status={status}
                            />
                        ))}
                </div>
                {!isMouseOverElement && (
                    <MouseComponent cursorElement={cursorElement} />
                )}
            </div>
        )
    },
    areEqual
)

function areEqual(prevprops, nextProps) {
    if (
        prevprops.documentUrl !== nextProps.documentUrl ||
        prevprops.activeTool?.type !== nextProps.activeTool?.type ||
        prevprops.activePage !== nextProps.activePage ||
        prevprops.isPageScrolling !== nextProps.isPageScrolling ||
        prevprops.status !== nextProps.status ||
        prevprops.signature !== nextProps.signature
    )
        return false

    return true
}

const asyncProcessHelper = async (callback) => {
    return new Promise((resolve, reject) => {
        try {
            resolve(callback())
        } catch (err) {
            console.log(err)
        }
    })
}

const EachPage = ({
    index,
    allowSign,
    pdfRefs,
    pdfData,
    disable,
    getClientPos,
    setIsMouseOverElement,
    isMouseOverElement,
    setAllowSign,
    cursorElement,
    pdfWrapper,
    handleClick,
    activeTool,
    editingMode,
    annotationStorages,
    status,
    ...props
}) => {
    const [pageSize, setPageSize] = React.useState(getCanvasSize())
    const [page, setPage] = React.useState(null)
    const eachPageRef = useRef()

    async function dragSignerElement(
        indexOfCanvas,
        height,
        width,
        elementOrigin,
        setToolSignerField,
        isTemplateItem = false,
        elementSize,
        element
    ) {
        if (isMouseOverElement) return
        setAllowSign(false)
        let tool
        if (activeTool) tool = activeTool
        else tool = await asyncProcessHelper(setToolSignerField)

        if (tool.isTemplateItem && !allowedTemplateItems.includes(tool.type)) {
            setActiveTool(null)
            setAllowSign(true)
            return
        }

        const cursorDom = cursorElement.current
        if (!cursorDom) return

        const canvas = pdfRefs.current[indexOfCanvas]

        const top = elementOrigin.y + canvas.scrollTop
        const left = elementOrigin.x
        const randomId = uuidv4()

        const data = {
            data: tool.data,
            id: tool.id,
            left: left,
            top: top,
            xAxis: left,
            yAxis: top,
            mediaPath: tool.mediaPath,
            type: tool.type,
            randomId,
            isTemplateItem,
            height: elementSize?.height,
            width: elementSize?.width,
        }
        const dimension = {
            height,
            width,
        }
        setActiveTool(null)
        await addSignatureElement(indexOfCanvas, data, dimension)
        setAllowSign(true)
    }

    async function onDrop(elementPosition, element, elementSize) {
        const { elementOffset, setToolSignerField, setElementOfset, type } = element
        if (!allowSign) return
        const eachPageOffset = eachPageRef.current.getBoundingClientRect()
        let elementOrigin = {
            x: elementPosition.x - elementOffset.x - eachPageOffset.x,
            y: elementPosition.y - elementOffset.y - eachPageOffset.y,
        }

        if (elementOrigin.x < 0) elementOrigin.x = 0
        if (elementOrigin.y < 0) elementOrigin.y = 0
        await dragSignerElement(
            index,
            pageSize.height,
            pageSize.width,
            elementOrigin,
            setToolSignerField,
            type === TEMPLATE_ITEM,
            elementSize,
            element
        )
        setElementOfset(null)
    }

    async function loadPage() {
        let runningTask = await pdfData.getPage(index + 1)
        setPage(runningTask)
    }

    React.useEffect(() => {
        if (!pdfData) return
        loadPage()
    }, [pdfData])

    return (
        <div
            className="pdf-page-wrapper"
            style={{ height: pageSize.height, width: pageSize.width }}
            key={index}
            ref={eachPageRef}
            onMouseDown={(e) => {
                if (
                    activeTool?.type !== SignatureDragType.SIGNER_FIELD &&
                    !allowSign
                )
                    return
                handleClick(e, index, pageSize.height, pageSize.width)
            }}
        >
            <DropZone
                onDrop={onDrop}
                style={{ cursor: allowSign ? "default" : "wait" }}
            >
                <PdfCanvas
                    pdfRefs={pdfRefs}
                    pdf={pdfData}
                    index={index}
                    pageNumber={index + 1}
                    setPageSize={setPageSize}
                />
                <TextLayer pdfjs={window.pdfjs} page={page} pageNumber={index + 1} />
                {/* {status === DRAFT &&  */}
                <AnnotationLayer
                    pdfjs={window.pdfjs}
                    page={page}
                    pageNumber={index + 1}
                    pdfViewer={window.pdfViewer}
                    pdfHelpers={window.pdfHelpers}
                    annotationStorages={annotationStorages}
                />
                {/* } */}
                <DragItemCatcher
                    disable={disable}
                    pageIndex={index}
                    getClientPos={() => getClientPos(index)}
                    name={`pdf-page-render-${index}`}
                    setIsMouseOverElement={setIsMouseOverElement}
                    pageSize={pageSize}
                    editingMode={editingMode}
                />
            </DropZone>
        </div>
    )
}

const mapStateToProps = ({ userReducer, signReducer }) => ({
    token: userReducer.token,
    activeTool: signReducer.activeTool,
    isPdfPageView: signReducer.isPdfPageView,
    documentUrl: signReducer.documentDetails.documentUrl,
    pdfPages: signReducer.pdfPages,
    disable: signReducer.disable,
    status: signReducer.status,
    signature: signReducer.signature,
    editingMode: signReducer.editingMode,
})

export default connect(mapStateToProps)(PdfEditor)
