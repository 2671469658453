import { Button, Text, Grid } from '@awesomesuite-frontend/awesome-nebula'
import Input from 'Awesome-ui/Input/Input'
import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
    inputClass: {
        "&::placeholder": {
            fontSize: "0.9rem"
        }
    }
}))

const EmailSignature = () => {
    const classes = useStyles()
    return (
        <div style={{paddingLeft: "10%"}}>
            <Grid container={true} xs={12} spacing={12}>
                <Grid item={true} lg={4} md={5} xs={8}>
                    <Input labelStyle={{ fontSize: "0.9rem" }} label="Company Name" placeHolder="Company Name" borderRadius="6px" textField={true} inputStyle={{ minHeight: "10rem" }} inputClassname={classes.inputClass} />
                </Grid>
                <Grid item={true} lg={4} md={5} xs={8}>
                    <Input labelStyle={{ fontSize: "0.9rem" }} label="From Email Name" placeHolder="From Email Name" borderRadius="6px" inputClassname={classes.inputClass} />
                </Grid>
            </Grid>
            <Text varient='subText' style={{ color: "#3F5265", margin: "0.25rem 0" }}>Custom Domain</Text>
            <Button style={{ color: "#00BDA5", backgroundColor: "#fff", border: "1px solid #d6dfe8" }}>Setup custom Domain</Button>
        </div>
    )
}

export default EmailSignature
