import React, { useState, useRef, useEffect } from "react"
import { Rnd } from "react-rnd"
import { dragClasses, dragStyle, dragStyleTransparent } from "../../Common/Style"
import {
    addDataToElement,
    dragSignatureElement,
    removeSignatureElement,
    resizeSignatureElement,
    setTemplateItemValue,
} from "../../../../../../Action/signAction"
import Wrapper from "../../Common/Wrapper"
import DragPreviewer from "../../DragPreviewer"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { getBackgroundFromInitials } from "Library/randomBackgroundGenerator"
import { addFields, deleteFields } from "Action/signersActions"
import { TemplateElementsDragType } from "Components/PDFCommons/container/Types"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
    numberInput: {
        /* Chrome, Safari, Edge, Opera */
        "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        /* Firefox */
        "-moz-appearance": "textfield",
    },
}))

const TemplateText = ({
    pageIndex,
    getPagePosition,
    id,
    type,
    xDefault,
    yDefault,
    width,
    height,
    data,
    disable,
    roleName,
    textEditDisable,
    pageHeight,
    pageWidth,
    signatureRole,
    ...other
}) => {
    const [toolModel, setToolModel] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    // const [inputValue, setInputValue] = useState("")
    const [hover, setHover] = useState(false)
    const ref = useRef(null)

    function openModelHandler() {
        if (!disable) setToolModel(true)
    }

    function mouseLeaveHandler(e) {
        const comp = ref.current
        if (comp && comp.contains(e.target)) return
        else setToolModel(false)
    }

    async function onRemoveHandler(e) {
        e.stopPropagation()
        await deleteFields(data.id)
        removeSignatureElement(pageIndex, id, null, data.id, type, true)
        other.onMouseLeave()
    }

    async function onDragStop(e, d) {
        e?.stopPropagation()
        const element = ref.current.getBoundingClientRect()
        const canvas = getPagePosition()
        const pos = {
            x: Math.floor(element.left - canvas.left),
            y: Math.floor(canvas.bottom - element.top - element.height),
        }
        dragSignatureElement(
            pageIndex,
            id,
            pos.x,
            pos.y,
            element.top - canvas.top,
            element.left - canvas.left
        )
        setIsDragging(false)

        const response = await addFields(
            data?.signerId,
            pageIndex,
            pos.x,
            pos.y,
            d?.y,
            d?.x,
            data?.id,
            Math.floor(element.height),
            Math.floor(element.width),
            pageHeight,
            pageWidth,
            data?.roleId,
            TemplateElementsDragType.TEXT,
            data?.subType
        )
        addDataToElement(pageIndex, id, { ...data, ...response })
        setIsDragging(false)
    }

    let timer
    function debounce(func, timeout = 300) {
        return () => {
            clearTimeout(timer)
            timer = setTimeout(func, timeout)
        }
    }

    const onResize = (e) => {
        const init = debounce(() => onResizeHelper(e))
        init()
    }

    async function onResizeHelper(e) {
        e?.stopPropagation()
        const element = ref.current.getBoundingClientRect()
        const canvas = getPagePosition()
        const pos = {
            x: Math.floor(element.left - canvas.left),
            y: Math.floor(canvas.bottom - element.top - element.height),
        }
        resizeSignatureElement(
            pageIndex,
            id,
            Math.floor(element.width),
            Math.floor(element.height),
            pos.x,
            pos.y,
            element.left - canvas.left,
            element.top - canvas.top
        )
        const response = await addFields(
            data?.signerId,
            pageIndex,
            pos.x,
            pos.y,
            canvas.height - (pos.y + element.height),
            pos.x,
            data?.id,
            Math.floor(element.height),
            Math.floor(element.width),
            pageHeight,
            pageWidth,
            data?.roleId,
            TemplateElementsDragType.TEXT,
            data?.subType
        )
        addDataToElement(pageIndex, id, { ...data, ...response })
    }

    const customBackgroundColor =
        signatureRole?.backgroundDecorum ||
        getBackgroundFromInitials(data?.roleName?.slice(0, 2).toLowerCase())

    return (
        <Rnd
            style={{ zIndex: 12 }}
            bounds={"parent"}
            default={{
                x: xDefault,
                y: yDefault,
                width: width || "120px",
                height: height || "38px",
            }}
            minWidth={"18px"}
            minHeight={"18px"}
            resizeHandleStyles={toolModel ? dragStyle : dragStyleTransparent}
            resizeHandleClasses={toolModel ? dragClasses : {}}
            onDragStart={() => setIsDragging(true)}
            onDragStop={onDragStop}
            onResize={onResize}
            onMouseDown={openModelHandler}
            disableDragging={disable}
            enableResizing={!disable}
        >
            <div
                ref={(e) => (ref.current = e)}
                className=""
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
                {...other}
                onMouseLeave={() => {
                    document.addEventListener("click", mouseLeaveHandler)
                    other.onMouseLeave()
                }}
            >
                <DragPreviewer isDragging={isDragging} />
                {toolModel && <Wrapper onRemoveHandler={onRemoveHandler} />}
                <div
                    style={{
                        backgroundColor: customBackgroundColor.back,
                        border: `2px solid ${customBackgroundColor.border}`,
                        borderLeftWidth: 6,
                        borderRadius: 4,
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <TextArea
                        type={type}
                        value={data.value || ""}
                        onChange={(e) =>
                            setTemplateItemValue(e.target.value, [
                                { pageIndex, elementId: id },
                            ])
                        }
                        onMouseOver={() => setHover(true)}
                        onMouseDown={() => setHover(false)}
                        style={{
                            width: "100%",
                            height: "100%",
                            resize: "none",
                            background: "transparent",
                            fontSize: "1.1rem",
                            border: "none",
                            outline: "none",
                            overflow: "hidden",
                            cursor: hover && "move",
                            padding: "0.25rem 0.5rem",
                        }}
                        placeholder={data?.subType || "TextField"}
                        onBlur={() => {}}
                        readOnly={textEditDisable}
                    ></TextArea>
                </div>
            </div>
        </Rnd>
    )
}

const TextArea = ({ type, ...props }) => {
    const classes = useStyles()
    if (type === TemplateElementsDragType.TEXT) return <textarea {...props} />
    else return <input type="number" {...props} className={classes.numberInput} />
}

export default TemplateText
