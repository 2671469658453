import React, { useEffect, useState } from "react"
import { useStyles } from "./GetStartedStyle"
import { ReactComponent as RocketIcon } from "Assets/GetStarted/get-started.svg"
import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import { connect } from "react-redux"
import { getOrganization } from "Action/userAction"
import { subscribeFreePlan } from "Action/subscriptionActions"
import { useHistory } from "react-router-dom"
import Guard from "Awesome-ui/Guard"
import { OWNER } from "Types"
import PermissionDenied from "Awesome-ui/PermissionDenied"

const startFreeTrial = async (e, token, userId, loadingHandler, history) => {
    e.preventDefault()
    loadingHandler(true)
    const organisation = await getOrganization(token)
    const subscriptionSuccessful = await subscribeFreePlan(
        token,
        organisation.id,
        userId
    )
    loadingHandler(false)
    if (subscriptionSuccessful) history.push("/onboarding")
}

const GetStarted = ({ token, userId, subscription, userRole }) => {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (Array.isArray(subscription) && subscription.length > 0) history.push("/")
    }, [subscription])

    return (
        <Guard
            forceBlock={userRole !== OWNER ? true : false}
            FallbackUi={<PermissionDenied />}
        >
            <div className={classes.getStartedWrapper}>
                <div className={classes.getStartedBox}>
                    <RocketIcon />
                    <Text varient="h4" margin="1.5rem 0">
                        👋 Welcome to Awesome Sign!
                    </Text>
                    <Text varient="subText" color="light" align="center">
                        Let's start with a product tour and setup.
                    </Text>
                    <Text varient="subText" color="light" align="center">
                        We will have you up and running in no time!
                    </Text>
                    <Button
                        color="#33475B"
                        round
                        style={{ marginTop: "2rem" }}
                        onClick={(e) =>
                            startFreeTrial(e, token, userId, setLoading, history)
                        }
                        disabled={loading}
                    >
                        <Text color="white" fontSize={16}>
                            🚀 Get Started!
                        </Text>
                    </Button>
                </div>
            </div>
        </Guard>
    )
}

const mapStateToProps = ({ userReducer, subscriptionReducer }) => ({
    token: userReducer.token,
    userId: userReducer.userDetails?.id,
    subscription: subscriptionReducer.subscription,
    userRole: userReducer.userRole,
})

export default connect(mapStateToProps)(GetStarted)
