import React, { useEffect, useState } from "react"
import DocumentContext from "../DocumentContext"
import "../pdf.css"

let pdfjs = null
let pdfWorker = null
let pdfViewer = null
let pdfHelpers = null

/**
 * PDF JS Document
 * @param {*} param0
 */
const Document = ({ children, ...props }) => {
    const [pdf, setPdf] = useState(null)
    let taskWorker = null

    async function initFun() {
        if (
            window.pdfjs &&
            window.pdfWorker &&
            window.pdfViewer &&
            window.pdfHelpers
        ) {
            pdfjs = window.pdfjs
            pdfWorker = window.pdfWorker
            pdfViewer = window.pdfViewer
            pdfHelpers = window.pdfHelpers
        } else if (!pdfjs) {
            pdfjs = await import("pdfjs-dist/legacy/build/pdf")
            window.pdfjs = pdfjs
            pdfWorker = await import("pdfjs-dist/legacy/build/pdf.worker.entry")
            window.pdfWorker = pdfWorker
            pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker
            pdfViewer = await import("pdfjs-dist/legacy/web/pdf_viewer")
            window.pdfViewer = pdfViewer
            pdfHelpers = await import("pdfjs-dist/lib/display/annotation_storage")
            window.pdfHelpers = pdfHelpers
        }
    }

    useEffect(() => {
        initFun()
    }, [])

    useEffect(() => {
        loadDocument()
    }, [props.file])

    async function loadDocument() {
        const { file, onSuccessHandler } = props

        console.log("This is the file", file)

        if (!file) return
        if (taskWorker) taskWorker.destroy()

        console.log("This is the file 2", file)

        if (!pdfjs) await initFun()
        taskWorker = pdfjs.getDocument({
            url: file,
            standardFontDataUrl:
                "https://unpkg.com/pdfjs-dist@2.13.216/standard_fonts/",
            useWorkerFetch: true,
        })

        taskWorker.promise.then(
            (loadedPDF) => {
                setPdf(loadedPDF)
                onSuccessHandler({ numberOfPages: loadedPDF.numPages })
            },
            (error) => {
                console.log(error)
            }
        )
    }

    return (
        <DocumentContext.Provider
            value={{
                pdf: pdf,
                pdfjs: pdfjs,
                pdfViewer: pdfViewer,
                pdfHelpers: pdfHelpers,
            }}
        >
            <div className="pdf-document">{children}</div>
        </DocumentContext.Provider>
    )
}

export default Document
