export const GET_USER_DETAILS_LOADING = "GET_USER_DETAILS_LOADING"
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS"
export const GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAILED"
export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED"
export const USER_SIGN_OUT = "USER_SIGN_OUT"

export const GLOABLE_LOADING = "GLOABLE_LOADING"
export const STOP_GLOABLE_LOADING = "STOP_GLOABLE_LOADING"
