import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as NoFileIcon } from "Assets/Storage/noFile.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { DRAFT, FINISHED, OUT_FOR_SIGNATURE, RECIEVED_FOR_SIGNATURE } from "Types"

const useStyles = createUseStyles(() => ({
    noFileWrapper: {
        border: "1px solid #D6DFE8",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 350,
    },
    noFile: {
        width: "100%",
        marginTop: "3rem",
    },
    noFileIcon: {
        display: "flex",
        justifyContent: "center",
    },
}))

const STATUS_LIST = {
    TOTAL: "TOTAL",
    AWAITING: RECIEVED_FOR_SIGNATURE,
    PENDING: OUT_FOR_SIGNATURE,
    DONE: FINISHED,
    DRAFT: DRAFT,
}

const getDescText = (status) => {
    switch (status) {
        case STATUS_LIST.AWAITING:
            return "You have no documents to sign"
        case STATUS_LIST.PENDING:
            return "You have no documents sent for signature"
        case STATUS_LIST.DONE:
            return "You have no signed documents"
        default:
            return "Start uploading documents to sign"
    }
}

const NoFiles = ({ status, ...props }) => {
    const classes = useStyles()
    const descText = getDescText(status)
    return (
        <div className={classes.noFileWrapper}>
            <div className={classes.noFileIcon}>
                <NoFileIcon />
            </div>
            <div className={classes.noFile}>
                <Text color="light" align="center">
                    No documents
                </Text>
                <Text color="light" align="center">
                    {descText}
                </Text>
            </div>
        </div>
    )
}

export default NoFiles
