export const signerBackgrounds = [
    {
        border: "#00A4BD",
        back: "#C6ECF9",
    },
    {
        border: "#FFDCB4",
        back: "#FFF4E7",
    },
    {
        border: "#FFBBB8",
        back: "#FFE9E8",
    },
    {
        border: "#B4BBE8",
        back: "#DFE2F5",
    },
    {
        border: "#7FDED2",
        back: "#DFF6F3",
    },
    {
        border: "#FFBCAC",
        back: "#FFECE8",
    },
]

const colorByInitials = {
    aa: {
        border: "rgb(200,232,133)",
        back: "rgb(250,255,183)",
    },
    ab: {
        border: "rgb(128,157,192)",
        back: "rgb(178,207,242)",
    },
    ac: {
        border: "rgb(210,209,146)",
        back: "rgb(255,255,196)",
    },
    ad: {
        border: "rgb(225,154,147)",
        back: "rgb(255,204,197)",
    },
    ae: {
        border: "rgb(135,220,236)",
        back: "rgb(185,255,255)",
    },
    af: {
        border: "rgb(244,249,243)",
        back: "rgb(255,255,255)",
    },
    ag: {
        border: "rgb(166,207,141)",
        back: "rgb(216,255,191)",
    },
    ah: {
        border: "rgb(186,142,144)",
        back: "rgb(236,192,194)",
    },
    ai: {
        border: "rgb(173,133,235)",
        back: "rgb(223,183,255)",
    },
    aj: {
        border: "rgb(108,211,210)",
        back: "rgb(158,255,255)",
    },
    ak: {
        border: "rgb(130,128,119)",
        back: "rgb(180,178,169)",
    },
    al: {
        border: "rgb(179,148,113)",
        back: "rgb(229,198,163)",
    },
    am: {
        border: "rgb(104,129,104)",
        back: "rgb(154,179,154)",
    },
    an: {
        border: "rgb(199,251,111)",
        back: "rgb(249,255,161)",
    },
    ao: {
        border: "rgb(155,248,139)",
        back: "rgb(205,255,189)",
    },
    ap: {
        border: "rgb(222,160,185)",
        back: "rgb(255,210,235)",
    },
    aq: {
        border: "rgb(190,107,185)",
        back: "rgb(240,157,235)",
    },
    ar: {
        border: "rgb(205,209,216)",
        back: "rgb(255,255,255)",
    },
    as: {
        border: "rgb(162,198,126)",
        back: "rgb(212,248,176)",
    },
    at: {
        border: "rgb(195,240,210)",
        back: "rgb(245,255,255)",
    },
    au: {
        border: "rgb(255,116,146)",
        back: "rgb(255,166,196)",
    },
    av: {
        border: "rgb(249,167,232)",
        back: "rgb(255,217,255)",
    },
    aw: {
        border: "rgb(157,168,185)",
        back: "rgb(207,218,235)",
    },
    ax: {
        border: "rgb(107,174,227)",
        back: "rgb(157,224,255)",
    },
    ay: {
        border: "rgb(138,175,170)",
        back: "rgb(188,225,220)",
    },
    az: {
        border: "rgb(229,137,112)",
        back: "rgb(255,187,162)",
    },
    ba: {
        border: "rgb(214,215,163)",
        back: "rgb(255,255,213)",
    },
    bb: {
        border: "rgb(252,181,126)",
        back: "rgb(255,231,176)",
    },
    bc: {
        border: "rgb(234,233,114)",
        back: "rgb(255,255,164)",
    },
    bd: {
        border: "rgb(189,191,219)",
        back: "rgb(239,241,255)",
    },
    be: {
        border: "rgb(164,183,103)",
        back: "rgb(214,233,153)",
    },
    bf: {
        border: "rgb(183,177,231)",
        back: "rgb(233,227,255)",
    },
    bg: {
        border: "rgb(129,102,245)",
        back: "rgb(179,152,255)",
    },
    bh: {
        border: "rgb(192,189,215)",
        back: "rgb(242,239,255)",
    },
    bi: {
        border: "rgb(107,178,237)",
        back: "rgb(157,228,255)",
    },
    bj: {
        border: "rgb(208,185,144)",
        back: "rgb(255,235,194)",
    },
    bk: {
        border: "rgb(199,242,142)",
        back: "rgb(249,255,192)",
    },
    bl: {
        border: "rgb(107,166,203)",
        back: "rgb(157,216,253)",
    },
    bm: {
        border: "rgb(212,213,121)",
        back: "rgb(255,255,171)",
    },
    bn: {
        border: "rgb(105,211,182)",
        back: "rgb(155,255,232)",
    },
    bo: {
        border: "rgb(177,127,215)",
        back: "rgb(227,177,255)",
    },
    bp: {
        border: "rgb(246,181,126)",
        back: "rgb(255,231,176)",
    },
    bq: {
        border: "rgb(160,108,205)",
        back: "rgb(210,158,255)",
    },
    br: {
        border: "rgb(205,205,231)",
        back: "rgb(255,255,255)",
    },
    bs: {
        border: "rgb(112,115,129)",
        back: "rgb(162,165,179)",
    },
    bt: {
        border: "rgb(239,195,237)",
        back: "rgb(255,245,255)",
    },
    bu: {
        border: "rgb(211,215,131)",
        back: "rgb(255,255,181)",
    },
    bv: {
        border: "rgb(234,175,190)",
        back: "rgb(255,225,240)",
    },
    bw: {
        border: "rgb(203,228,190)",
        back: "rgb(253,255,240)",
    },
    bx: {
        border: "rgb(184,136,134)",
        back: "rgb(234,186,184)",
    },
    by: {
        border: "rgb(173,224,232)",
        back: "rgb(223,255,255)",
    },
    bz: {
        border: "rgb(145,100,138)",
        back: "rgb(195,150,188)",
    },
    ca: {
        border: "rgb(225,222,185)",
        back: "rgb(255,255,235)",
    },
    cb: {
        border: "rgb(173,116,230)",
        back: "rgb(223,166,255)",
    },
    cc: {
        border: "rgb(178,149,111)",
        back: "rgb(228,199,161)",
    },
    cd: {
        border: "rgb(244,111,220)",
        back: "rgb(255,161,255)",
    },
    ce: {
        border: "rgb(107,121,177)",
        back: "rgb(157,171,227)",
    },
    cf: {
        border: "rgb(250,227,164)",
        back: "rgb(255,255,214)",
    },
    cg: {
        border: "rgb(188,186,128)",
        back: "rgb(238,236,178)",
    },
    ch: {
        border: "rgb(145,164,178)",
        back: "rgb(195,214,228)",
    },
    ci: {
        border: "rgb(133,139,244)",
        back: "rgb(183,189,255)",
    },
    cj: {
        border: "rgb(165,152,175)",
        back: "rgb(215,202,225)",
    },
    ck: {
        border: "rgb(167,113,153)",
        back: "rgb(217,163,203)",
    },
    cl: {
        border: "rgb(102,178,151)",
        back: "rgb(152,228,201)",
    },
    cm: {
        border: "rgb(219,180,145)",
        back: "rgb(255,230,195)",
    },
    cn: {
        border: "rgb(204,165,210)",
        back: "rgb(254,215,255)",
    },
    co: {
        border: "rgb(198,210,153)",
        back: "rgb(248,255,203)",
    },
    cp: {
        border: "rgb(172,155,197)",
        back: "rgb(222,205,247)",
    },
    cq: {
        border: "rgb(248,212,197)",
        back: "rgb(255,255,247)",
    },
    cr: {
        border: "rgb(192,243,187)",
        back: "rgb(242,255,237)",
    },
    cs: {
        border: "rgb(174,172,244)",
        back: "rgb(224,222,255)",
    },
    ct: {
        border: "rgb(183,176,210)",
        back: "rgb(233,226,255)",
    },
    cu: {
        border: "rgb(127,227,192)",
        back: "rgb(177,255,242)",
    },
    cv: {
        border: "rgb(244,243,114)",
        back: "rgb(255,255,164)",
    },
    cw: {
        border: "rgb(141,180,221)",
        back: "rgb(191,230,255)",
    },
    cx: {
        border: "rgb(240,180,130)",
        back: "rgb(255,230,180)",
    },
    cy: {
        border: "rgb(111,164,135)",
        back: "rgb(161,214,185)",
    },
    cz: {
        border: "rgb(151,153,200)",
        back: "rgb(201,203,250)",
    },
    da: {
        border: "rgb(122,146,185)",
        back: "rgb(172,196,235)",
    },
    db: {
        border: "rgb(154,141,123)",
        back: "rgb(204,191,173)",
    },
    dc: {
        border: "rgb(131,107,227)",
        back: "rgb(181,157,255)",
    },
    dd: {
        border: "rgb(176,150,167)",
        back: "rgb(226,200,217)",
    },
    de: {
        border: "rgb(136,245,110)",
        back: "rgb(186,255,160)",
    },
    df: {
        border: "rgb(239,187,231)",
        back: "rgb(255,237,255)",
    },
    dg: {
        border: "rgb(136,208,178)",
        back: "rgb(186,255,228)",
    },
    dh: {
        border: "rgb(131,171,246)",
        back: "rgb(181,221,255)",
    },
    di: {
        border: "rgb(194,137,132)",
        back: "rgb(244,187,182)",
    },
    dj: {
        border: "rgb(171,141,106)",
        back: "rgb(221,191,156)",
    },
    dk: {
        border: "rgb(169,109,100)",
        back: "rgb(219,159,150)",
    },
    dl: {
        border: "rgb(101,244,205)",
        back: "rgb(151,255,255)",
    },
    dm: {
        border: "rgb(219,171,169)",
        back: "rgb(255,221,219)",
    },
    dn: {
        border: "rgb(254,239,135)",
        back: "rgb(255,255,185)",
    },
    do: {
        border: "rgb(208,141,158)",
        back: "rgb(255,191,208)",
    },
    dp: {
        border: "rgb(173,191,103)",
        back: "rgb(223,241,153)",
    },
    dq: {
        border: "rgb(198,212,150)",
        back: "rgb(248,255,200)",
    },
    dr: {
        border: "rgb(155,211,119)",
        back: "rgb(205,255,169)",
    },
    ds: {
        border: "rgb(124,138,242)",
        back: "rgb(174,188,255)",
    },
    dt: {
        border: "rgb(168,176,187)",
        back: "rgb(218,226,237)",
    },
    du: {
        border: "rgb(117,144,199)",
        back: "rgb(167,194,249)",
    },
    dv: {
        border: "rgb(153,190,210)",
        back: "rgb(203,240,255)",
    },
    dw: {
        border: "rgb(189,100,135)",
        back: "rgb(239,150,185)",
    },
    dx: {
        border: "rgb(190,157,228)",
        back: "rgb(240,207,255)",
    },
    dy: {
        border: "rgb(152,123,122)",
        back: "rgb(202,173,172)",
    },
    dz: {
        border: "rgb(254,143,185)",
        back: "rgb(255,193,235)",
    },
    ea: {
        border: "rgb(210,236,142)",
        back: "rgb(255,255,192)",
    },
    eb: {
        border: "rgb(250,119,253)",
        back: "rgb(255,169,255)",
    },
    ec: {
        border: "rgb(251,107,160)",
        back: "rgb(255,157,210)",
    },
    ed: {
        border: "rgb(107,111,252)",
        back: "rgb(157,161,255)",
    },
    ee: {
        border: "rgb(101,250,211)",
        back: "rgb(151,255,255)",
    },
    ef: {
        border: "rgb(230,100,150)",
        back: "rgb(255,150,200)",
    },
    eg: {
        border: "rgb(131,189,151)",
        back: "rgb(181,239,201)",
    },
    eh: {
        border: "rgb(245,151,165)",
        back: "rgb(255,201,215)",
    },
    ei: {
        border: "rgb(201,153,227)",
        back: "rgb(251,203,255)",
    },
    ej: {
        border: "rgb(164,181,178)",
        back: "rgb(214,231,228)",
    },
    ek: {
        border: "rgb(194,142,236)",
        back: "rgb(244,192,255)",
    },
    el: {
        border: "rgb(100,199,177)",
        back: "rgb(150,249,227)",
    },
    em: {
        border: "rgb(186,112,165)",
        back: "rgb(236,162,215)",
    },
    en: {
        border: "rgb(112,190,209)",
        back: "rgb(162,240,255)",
    },
    eo: {
        border: "rgb(140,169,208)",
        back: "rgb(190,219,255)",
    },
    ep: {
        border: "rgb(209,238,143)",
        back: "rgb(255,255,193)",
    },
    eq: {
        border: "rgb(101,125,239)",
        back: "rgb(151,175,255)",
    },
    er: {
        border: "rgb(138,139,158)",
        back: "rgb(188,189,208)",
    },
    es: {
        border: "rgb(190,199,192)",
        back: "rgb(240,249,242)",
    },
    et: {
        border: "rgb(233,128,177)",
        back: "rgb(255,178,227)",
    },
    eu: {
        border: "rgb(222,139,254)",
        back: "rgb(255,189,255)",
    },
    ev: {
        border: "rgb(153,100,114)",
        back: "rgb(203,150,164)",
    },
    ew: {
        border: "rgb(130,142,132)",
        back: "rgb(180,192,182)",
    },
    ex: {
        border: "rgb(147,186,188)",
        back: "rgb(197,236,238)",
    },
    ey: {
        border: "rgb(194,131,100)",
        back: "rgb(244,181,150)",
    },
    ez: {
        border: "rgb(137,161,140)",
        back: "rgb(187,211,190)",
    },
    fa: {
        border: "rgb(192,231,180)",
        back: "rgb(242,255,230)",
    },
    fb: {
        border: "rgb(111,234,185)",
        back: "rgb(161,255,235)",
    },
    fc: {
        border: "rgb(254,218,154)",
        back: "rgb(255,255,204)",
    },
    fd: {
        border: "rgb(236,119,161)",
        back: "rgb(255,169,211)",
    },
    fe: {
        border: "rgb(178,135,211)",
        back: "rgb(228,185,255)",
    },
    ff: {
        border: "rgb(252,217,204)",
        back: "rgb(255,255,254)",
    },
    fg: {
        border: "rgb(183,240,254)",
        back: "rgb(233,255,255)",
    },
    fh: {
        border: "rgb(104,250,179)",
        back: "rgb(154,255,229)",
    },
    fi: {
        border: "rgb(233,125,104)",
        back: "rgb(255,175,154)",
    },
    fj: {
        border: "rgb(153,180,209)",
        back: "rgb(203,230,255)",
    },
    fk: {
        border: "rgb(161,216,249)",
        back: "rgb(211,255,255)",
    },
    fl: {
        border: "rgb(231,143,131)",
        back: "rgb(255,193,181)",
    },
    fm: {
        border: "rgb(133,194,195)",
        back: "rgb(183,244,245)",
    },
    fn: {
        border: "rgb(242,119,251)",
        back: "rgb(255,169,255)",
    },
    fo: {
        border: "rgb(105,179,247)",
        back: "rgb(155,229,255)",
    },
    fp: {
        border: "rgb(235,211,227)",
        back: "rgb(255,255,255)",
    },
    fq: {
        border: "rgb(108,162,198)",
        back: "rgb(158,212,248)",
    },
    fr: {
        border: "rgb(236,135,145)",
        back: "rgb(255,185,195)",
    },
    fs: {
        border: "rgb(168,195,239)",
        back: "rgb(218,245,255)",
    },
    ft: {
        border: "rgb(120,108,233)",
        back: "rgb(170,158,255)",
    },
    fu: {
        border: "rgb(216,183,213)",
        back: "rgb(255,233,255)",
    },
    fv: {
        border: "rgb(140,110,207)",
        back: "rgb(190,160,255)",
    },
    fw: {
        border: "rgb(214,155,182)",
        back: "rgb(255,205,232)",
    },
    fx: {
        border: "rgb(161,197,219)",
        back: "rgb(211,247,255)",
    },
    fy: {
        border: "rgb(250,246,202)",
        back: "rgb(255,255,252)",
    },
    fz: {
        border: "rgb(177,255,208)",
        back: "rgb(227,255,255)",
    },
    ga: {
        border: "rgb(229,191,117)",
        back: "rgb(255,241,167)",
    },
    gb: {
        border: "rgb(214,212,250)",
        back: "rgb(255,255,255)",
    },
    gc: {
        border: "rgb(174,121,141)",
        back: "rgb(224,171,191)",
    },
    gd: {
        border: "rgb(209,179,146)",
        back: "rgb(255,229,196)",
    },
    ge: {
        border: "rgb(121,116,228)",
        back: "rgb(171,166,255)",
    },
    gf: {
        border: "rgb(108,214,204)",
        back: "rgb(158,255,254)",
    },
    gg: {
        border: "rgb(241,137,114)",
        back: "rgb(255,187,164)",
    },
    gh: {
        border: "rgb(241,178,206)",
        back: "rgb(255,228,255)",
    },
    gi: {
        border: "rgb(249,133,221)",
        back: "rgb(255,183,255)",
    },
    gj: {
        border: "rgb(123,210,175)",
        back: "rgb(173,255,225)",
    },
    gk: {
        border: "rgb(164,220,231)",
        back: "rgb(214,255,255)",
    },
    gl: {
        border: "rgb(191,235,163)",
        back: "rgb(241,255,213)",
    },
    gm: {
        border: "rgb(221,178,143)",
        back: "rgb(255,228,193)",
    },
    gn: {
        border: "rgb(151,127,161)",
        back: "rgb(201,177,211)",
    },
    go: {
        border: "rgb(116,239,227)",
        back: "rgb(166,255,255)",
    },
    gp: {
        border: "rgb(174,119,245)",
        back: "rgb(224,169,255)",
    },
    gq: {
        border: "rgb(109,231,118)",
        back: "rgb(159,255,168)",
    },
    gr: {
        border: "rgb(241,100,148)",
        back: "rgb(255,150,198)",
    },
    gs: {
        border: "rgb(211,128,117)",
        back: "rgb(255,178,167)",
    },
    gt: {
        border: "rgb(188,212,252)",
        back: "rgb(238,255,255)",
    },
    gu: {
        border: "rgb(220,229,235)",
        back: "rgb(255,255,255)",
    },
    gv: {
        border: "rgb(128,139,247)",
        back: "rgb(178,189,255)",
    },
    gw: {
        border: "rgb(175,244,217)",
        back: "rgb(225,255,255)",
    },
    gx: {
        border: "rgb(196,146,153)",
        back: "rgb(246,196,203)",
    },
    gy: {
        border: "rgb(150,234,247)",
        back: "rgb(200,255,255)",
    },
    gz: {
        border: "rgb(109,251,177)",
        back: "rgb(159,255,227)",
    },
    ha: {
        border: "rgb(229,157,100)",
        back: "rgb(255,207,150)",
    },
    hb: {
        border: "rgb(181,170,158)",
        back: "rgb(231,220,208)",
    },
    hc: {
        border: "rgb(108,180,115)",
        back: "rgb(158,230,165)",
    },
    hd: {
        border: "rgb(242,201,213)",
        back: "rgb(255,251,255)",
    },
    he: {
        border: "rgb(140,148,237)",
        back: "rgb(190,198,255)",
    },
    hf: {
        border: "rgb(129,223,196)",
        back: "rgb(179,255,246)",
    },
    hg: {
        border: "rgb(152,168,213)",
        back: "rgb(202,218,255)",
    },
    hh: {
        border: "rgb(158,251,247)",
        back: "rgb(208,255,255)",
    },
    hi: {
        border: "rgb(164,241,231)",
        back: "rgb(214,255,255)",
    },
    hj: {
        border: "rgb(110,202,209)",
        back: "rgb(160,252,255)",
    },
    hk: {
        border: "rgb(170,226,235)",
        back: "rgb(220,255,255)",
    },
    hl: {
        border: "rgb(103,180,131)",
        back: "rgb(153,230,181)",
    },
    hm: {
        border: "rgb(227,226,128)",
        back: "rgb(255,255,178)",
    },
    hn: {
        border: "rgb(130,233,134)",
        back: "rgb(180,255,184)",
    },
    ho: {
        border: "rgb(252,159,137)",
        back: "rgb(255,209,187)",
    },
    hp: {
        border: "rgb(190,247,176)",
        back: "rgb(240,255,226)",
    },
    hq: {
        border: "rgb(190,133,201)",
        back: "rgb(240,183,251)",
    },
    hr: {
        border: "rgb(238,154,160)",
        back: "rgb(255,204,210)",
    },
    hs: {
        border: "rgb(176,167,231)",
        back: "rgb(226,217,255)",
    },
    ht: {
        border: "rgb(202,138,142)",
        back: "rgb(252,188,192)",
    },
    hu: {
        border: "rgb(128,102,202)",
        back: "rgb(178,152,252)",
    },
    hv: {
        border: "rgb(215,145,141)",
        back: "rgb(255,195,191)",
    },
    hw: {
        border: "rgb(148,242,204)",
        back: "rgb(198,255,254)",
    },
    hx: {
        border: "rgb(217,164,123)",
        back: "rgb(255,214,173)",
    },
    hy: {
        border: "rgb(231,180,183)",
        back: "rgb(255,230,233)",
    },
    hz: {
        border: "rgb(109,116,198)",
        back: "rgb(159,166,248)",
    },
    ia: {
        border: "rgb(226,169,206)",
        back: "rgb(255,219,255)",
    },
    ib: {
        border: "rgb(102,118,106)",
        back: "rgb(152,168,156)",
    },
    ic: {
        border: "rgb(153,126,204)",
        back: "rgb(203,176,254)",
    },
    id: {
        border: "rgb(232,107,149)",
        back: "rgb(255,157,199)",
    },
    ie: {
        border: "rgb(174,181,239)",
        back: "rgb(224,231,255)",
    },
    if: {
        border: "rgb(160,130,186)",
        back: "rgb(210,180,236)",
    },
    ig: {
        border: "rgb(182,246,112)",
        back: "rgb(232,255,162)",
    },
    ih: {
        border: "rgb(184,185,207)",
        back: "rgb(234,235,255)",
    },
    ii: {
        border: "rgb(209,198,233)",
        back: "rgb(255,248,255)",
    },
    ij: {
        border: "rgb(154,141,170)",
        back: "rgb(204,191,220)",
    },
    ik: {
        border: "rgb(146,233,154)",
        back: "rgb(196,255,204)",
    },
    il: {
        border: "rgb(238,145,124)",
        back: "rgb(255,195,174)",
    },
    im: {
        border: "rgb(142,170,156)",
        back: "rgb(192,220,206)",
    },
    in: {
        border: "rgb(148,167,187)",
        back: "rgb(198,217,237)",
    },
    io: {
        border: "rgb(227,128,177)",
        back: "rgb(255,178,227)",
    },
    ip: {
        border: "rgb(173,162,147)",
        back: "rgb(223,212,197)",
    },
    iq: {
        border: "rgb(104,208,154)",
        back: "rgb(154,255,204)",
    },
    ir: {
        border: "rgb(106,206,212)",
        back: "rgb(156,255,255)",
    },
    is: {
        border: "rgb(174,228,156)",
        back: "rgb(224,255,206)",
    },
    it: {
        border: "rgb(220,136,167)",
        back: "rgb(255,186,217)",
    },
    iu: {
        border: "rgb(185,164,218)",
        back: "rgb(235,214,255)",
    },
    iv: {
        border: "rgb(129,208,160)",
        back: "rgb(179,255,210)",
    },
    iw: {
        border: "rgb(202,255,106)",
        back: "rgb(252,255,156)",
    },
    ix: {
        border: "rgb(172,218,197)",
        back: "rgb(222,255,247)",
    },
    iy: {
        border: "rgb(125,173,183)",
        back: "rgb(175,223,233)",
    },
    iz: {
        border: "rgb(173,131,177)",
        back: "rgb(223,181,227)",
    },
    ja: {
        border: "rgb(181,107,206)",
        back: "rgb(231,157,255)",
    },
    jb: {
        border: "rgb(140,239,210)",
        back: "rgb(190,255,255)",
    },
    jc: {
        border: "rgb(188,243,212)",
        back: "rgb(238,255,255)",
    },
    jd: {
        border: "rgb(242,154,223)",
        back: "rgb(255,204,255)",
    },
    je: {
        border: "rgb(233,206,147)",
        back: "rgb(255,255,197)",
    },
    jf: {
        border: "rgb(190,154,125)",
        back: "rgb(240,204,175)",
    },
    jg: {
        border: "rgb(186,237,159)",
        back: "rgb(236,255,209)",
    },
    jh: {
        border: "rgb(181,186,141)",
        back: "rgb(231,236,191)",
    },
    ji: {
        border: "rgb(160,181,178)",
        back: "rgb(210,231,228)",
    },
    jj: {
        border: "rgb(230,216,124)",
        back: "rgb(255,255,174)",
    },
    jk: {
        border: "rgb(149,233,198)",
        back: "rgb(199,255,248)",
    },
    jl: {
        border: "rgb(142,255,139)",
        back: "rgb(192,255,189)",
    },
    jm: {
        border: "rgb(111,144,160)",
        back: "rgb(161,194,210)",
    },
    jn: {
        border: "rgb(129,122,146)",
        back: "rgb(179,172,196)",
    },
    jo: {
        border: "rgb(213,178,233)",
        back: "rgb(255,228,255)",
    },
    jp: {
        border: "rgb(121,186,231)",
        back: "rgb(171,236,255)",
    },
    jq: {
        border: "rgb(169,227,251)",
        back: "rgb(219,255,255)",
    },
    jr: {
        border: "rgb(156,187,232)",
        back: "rgb(206,237,255)",
    },
    js: {
        border: "rgb(227,191,127)",
        back: "rgb(255,241,177)",
    },
    jt: {
        border: "rgb(137,246,220)",
        back: "rgb(187,255,255)",
    },
    ju: {
        border: "rgb(226,197,208)",
        back: "rgb(255,247,255)",
    },
    jv: {
        border: "rgb(170,194,166)",
        back: "rgb(220,244,216)",
    },
    jw: {
        border: "rgb(208,134,143)",
        back: "rgb(255,184,193)",
    },
    jx: {
        border: "rgb(120,137,244)",
        back: "rgb(170,187,255)",
    },
    jy: {
        border: "rgb(136,205,253)",
        back: "rgb(186,255,255)",
    },
    jz: {
        border: "rgb(137,136,110)",
        back: "rgb(187,186,160)",
    },
    ka: {
        border: "rgb(197,164,151)",
        back: "rgb(247,214,201)",
    },
    kb: {
        border: "rgb(200,184,167)",
        back: "rgb(250,234,217)",
    },
    kc: {
        border: "rgb(161,184,104)",
        back: "rgb(211,234,154)",
    },
    kd: {
        border: "rgb(123,116,205)",
        back: "rgb(173,166,255)",
    },
    ke: {
        border: "rgb(132,142,140)",
        back: "rgb(182,192,190)",
    },
    kf: {
        border: "rgb(203,251,137)",
        back: "rgb(253,255,187)",
    },
    kg: {
        border: "rgb(162,165,187)",
        back: "rgb(212,215,237)",
    },
    kh: {
        border: "rgb(153,128,220)",
        back: "rgb(203,178,255)",
    },
    ki: {
        border: "rgb(146,186,120)",
        back: "rgb(196,236,170)",
    },
    kj: {
        border: "rgb(172,241,118)",
        back: "rgb(222,255,168)",
    },
    kk: {
        border: "rgb(107,144,209)",
        back: "rgb(157,194,255)",
    },
    kl: {
        border: "rgb(101,229,102)",
        back: "rgb(151,255,152)",
    },
    km: {
        border: "rgb(232,164,229)",
        back: "rgb(255,214,255)",
    },
    kn: {
        border: "rgb(172,220,163)",
        back: "rgb(222,255,213)",
    },
    ko: {
        border: "rgb(186,104,203)",
        back: "rgb(236,154,253)",
    },
    kp: {
        border: "rgb(247,106,175)",
        back: "rgb(255,156,225)",
    },
    kq: {
        border: "rgb(253,250,146)",
        back: "rgb(255,255,196)",
    },
    kr: {
        border: "rgb(126,253,100)",
        back: "rgb(176,255,150)",
    },
    ks: {
        border: "rgb(246,160,217)",
        back: "rgb(255,210,255)",
    },
    kt: {
        border: "rgb(166,253,233)",
        back: "rgb(216,255,255)",
    },
    ku: {
        border: "rgb(151,206,120)",
        back: "rgb(201,255,170)",
    },
    kv: {
        border: "rgb(172,130,225)",
        back: "rgb(222,180,255)",
    },
    kw: {
        border: "rgb(160,148,181)",
        back: "rgb(210,198,231)",
    },
    kx: {
        border: "rgb(253,173,109)",
        back: "rgb(255,223,159)",
    },
    ky: {
        border: "rgb(204,246,112)",
        back: "rgb(254,255,162)",
    },
    kz: {
        border: "rgb(191,210,129)",
        back: "rgb(241,255,179)",
    },
    la: {
        border: "rgb(182,226,162)",
        back: "rgb(232,255,212)",
    },
    lb: {
        border: "rgb(230,106,133)",
        back: "rgb(255,156,183)",
    },
    lc: {
        border: "rgb(178,157,110)",
        back: "rgb(228,207,160)",
    },
    ld: {
        border: "rgb(230,155,166)",
        back: "rgb(255,205,216)",
    },
    le: {
        border: "rgb(186,164,248)",
        back: "rgb(236,214,255)",
    },
    lf: {
        border: "rgb(163,155,165)",
        back: "rgb(213,205,215)",
    },
    lg: {
        border: "rgb(241,203,251)",
        back: "rgb(255,253,255)",
    },
    lh: {
        border: "rgb(241,160,158)",
        back: "rgb(255,210,208)",
    },
    li: {
        border: "rgb(246,203,146)",
        back: "rgb(255,253,196)",
    },
    lj: {
        border: "rgb(235,219,150)",
        back: "rgb(255,255,200)",
    },
    lk: {
        border: "rgb(222,221,244)",
        back: "rgb(255,255,255)",
    },
    ll: {
        border: "rgb(151,207,164)",
        back: "rgb(201,255,214)",
    },
    lm: {
        border: "rgb(231,221,238)",
        back: "rgb(255,255,255)",
    },
    ln: {
        border: "rgb(167,182,147)",
        back: "rgb(217,232,197)",
    },
    lo: {
        border: "rgb(200,249,142)",
        back: "rgb(250,255,192)",
    },
    lp: {
        border: "rgb(206,130,109)",
        back: "rgb(255,180,159)",
    },
    lq: {
        border: "rgb(245,224,114)",
        back: "rgb(255,255,164)",
    },
    lr: {
        border: "rgb(176,188,209)",
        back: "rgb(226,238,255)",
    },
    ls: {
        border: "rgb(198,167,162)",
        back: "rgb(248,217,212)",
    },
    lt: {
        border: "rgb(182,104,205)",
        back: "rgb(232,154,255)",
    },
    lu: {
        border: "rgb(254,114,134)",
        back: "rgb(255,164,184)",
    },
    lv: {
        border: "rgb(238,109,103)",
        back: "rgb(255,159,153)",
    },
    lw: {
        border: "rgb(112,115,170)",
        back: "rgb(162,165,220)",
    },
    lx: {
        border: "rgb(195,138,213)",
        back: "rgb(245,188,255)",
    },
    ly: {
        border: "rgb(208,228,252)",
        back: "rgb(255,255,255)",
    },
    lz: {
        border: "rgb(117,167,124)",
        back: "rgb(167,217,174)",
    },
    ma: {
        border: "rgb(128,184,127)",
        back: "rgb(178,234,177)",
    },
    mb: {
        border: "rgb(135,236,168)",
        back: "rgb(185,255,218)",
    },
    mc: {
        border: "rgb(106,127,148)",
        back: "rgb(156,177,198)",
    },
    md: {
        border: "rgb(198,134,226)",
        back: "rgb(248,184,255)",
    },
    me: {
        border: "rgb(141,154,143)",
        back: "rgb(191,204,193)",
    },
    mf: {
        border: "rgb(196,202,250)",
        back: "rgb(246,252,255)",
    },
    mg: {
        border: "rgb(134,190,172)",
        back: "rgb(184,240,222)",
    },
    mh: {
        border: "rgb(128,194,214)",
        back: "rgb(178,244,255)",
    },
    mi: {
        border: "rgb(175,123,100)",
        back: "rgb(225,173,150)",
    },
    mj: {
        border: "rgb(178,203,136)",
        back: "rgb(228,253,186)",
    },
    mk: {
        border: "rgb(110,128,142)",
        back: "rgb(160,178,192)",
    },
    ml: {
        border: "rgb(109,254,245)",
        back: "rgb(159,255,255)",
    },
    mm: {
        border: "rgb(196,207,195)",
        back: "rgb(246,255,245)",
    },
    mn: {
        border: "rgb(242,150,208)",
        back: "rgb(255,200,255)",
    },
    mo: {
        border: "rgb(105,137,117)",
        back: "rgb(155,187,167)",
    },
    mp: {
        border: "rgb(255,152,170)",
        back: "rgb(255,202,220)",
    },
    mq: {
        border: "rgb(185,170,138)",
        back: "rgb(235,220,188)",
    },
    mr: {
        border: "rgb(133,181,243)",
        back: "rgb(183,231,255)",
    },
    ms: {
        border: "rgb(176,182,195)",
        back: "rgb(226,232,245)",
    },
    mt: {
        border: "rgb(196,180,143)",
        back: "rgb(246,230,193)",
    },
    mu: {
        border: "rgb(210,224,147)",
        back: "rgb(255,255,197)",
    },
    mv: {
        border: "rgb(103,241,113)",
        back: "rgb(153,255,163)",
    },
    mw: {
        border: "rgb(237,131,109)",
        back: "rgb(255,181,159)",
    },
    mx: {
        border: "rgb(124,144,244)",
        back: "rgb(174,194,255)",
    },
    my: {
        border: "rgb(183,180,229)",
        back: "rgb(233,230,255)",
    },
    mz: {
        border: "rgb(166,141,255)",
        back: "rgb(216,191,255)",
    },
    na: {
        border: "rgb(155,132,150)",
        back: "rgb(205,182,200)",
    },
    nb: {
        border: "rgb(243,105,120)",
        back: "rgb(255,155,170)",
    },
    nc: {
        border: "rgb(107,180,152)",
        back: "rgb(157,230,202)",
    },
    nd: {
        border: "rgb(110,170,218)",
        back: "rgb(160,220,255)",
    },
    ne: {
        border: "rgb(224,191,231)",
        back: "rgb(255,241,255)",
    },
    nf: {
        border: "rgb(217,113,156)",
        back: "rgb(255,163,206)",
    },
    ng: {
        border: "rgb(209,100,125)",
        back: "rgb(255,150,175)",
    },
    nh: {
        border: "rgb(227,188,160)",
        back: "rgb(255,238,210)",
    },
    ni: {
        border: "rgb(137,135,117)",
        back: "rgb(187,185,167)",
    },
    nj: {
        border: "rgb(146,245,217)",
        back: "rgb(196,255,255)",
    },
    nk: {
        border: "rgb(138,214,243)",
        back: "rgb(188,255,255)",
    },
    nl: {
        border: "rgb(216,205,102)",
        back: "rgb(255,255,152)",
    },
    nm: {
        border: "rgb(140,185,109)",
        back: "rgb(190,235,159)",
    },
    nn: {
        border: "rgb(138,160,105)",
        back: "rgb(188,210,155)",
    },
    no: {
        border: "rgb(193,189,124)",
        back: "rgb(243,239,174)",
    },
    np: {
        border: "rgb(183,253,139)",
        back: "rgb(233,255,189)",
    },
    nq: {
        border: "rgb(137,229,223)",
        back: "rgb(187,255,255)",
    },
    nr: {
        border: "rgb(123,210,193)",
        back: "rgb(173,255,243)",
    },
    ns: {
        border: "rgb(183,101,203)",
        back: "rgb(233,151,253)",
    },
    nt: {
        border: "rgb(128,113,238)",
        back: "rgb(178,163,255)",
    },
    nu: {
        border: "rgb(199,247,120)",
        back: "rgb(249,255,170)",
    },
    nv: {
        border: "rgb(241,199,237)",
        back: "rgb(255,249,255)",
    },
    nw: {
        border: "rgb(151,241,120)",
        back: "rgb(201,255,170)",
    },
    nx: {
        border: "rgb(192,195,132)",
        back: "rgb(242,245,182)",
    },
    ny: {
        border: "rgb(188,118,160)",
        back: "rgb(238,168,210)",
    },
    nz: {
        border: "rgb(251,227,229)",
        back: "rgb(255,255,255)",
    },
    oa: {
        border: "rgb(178,219,106)",
        back: "rgb(228,255,156)",
    },
    ob: {
        border: "rgb(214,147,217)",
        back: "rgb(255,197,255)",
    },
    oc: {
        border: "rgb(205,149,185)",
        back: "rgb(255,199,235)",
    },
    od: {
        border: "rgb(230,105,198)",
        back: "rgb(255,155,248)",
    },
    oe: {
        border: "rgb(251,174,236)",
        back: "rgb(255,224,255)",
    },
    of: {
        border: "rgb(105,137,214)",
        back: "rgb(155,187,255)",
    },
    og: {
        border: "rgb(156,188,204)",
        back: "rgb(206,238,254)",
    },
    oh: {
        border: "rgb(251,103,109)",
        back: "rgb(255,153,159)",
    },
    oi: {
        border: "rgb(104,101,239)",
        back: "rgb(154,151,255)",
    },
    oj: {
        border: "rgb(196,190,216)",
        back: "rgb(246,240,255)",
    },
    ok: {
        border: "rgb(177,105,207)",
        back: "rgb(227,155,255)",
    },
    ol: {
        border: "rgb(147,242,244)",
        back: "rgb(197,255,255)",
    },
    om: {
        border: "rgb(108,229,167)",
        back: "rgb(158,255,217)",
    },
    on: {
        border: "rgb(169,146,188)",
        back: "rgb(219,196,238)",
    },
    oo: {
        border: "rgb(142,165,140)",
        back: "rgb(192,215,190)",
    },
    op: {
        border: "rgb(240,225,160)",
        back: "rgb(255,255,210)",
    },
    oq: {
        border: "rgb(152,251,192)",
        back: "rgb(202,255,242)",
    },
    or: {
        border: "rgb(187,157,170)",
        back: "rgb(237,207,220)",
    },
    os: {
        border: "rgb(172,207,108)",
        back: "rgb(222,255,158)",
    },
    ot: {
        border: "rgb(141,120,214)",
        back: "rgb(191,170,255)",
    },
    ou: {
        border: "rgb(182,164,235)",
        back: "rgb(232,214,255)",
    },
    ov: {
        border: "rgb(161,130,227)",
        back: "rgb(211,180,255)",
    },
    ow: {
        border: "rgb(108,110,127)",
        back: "rgb(158,160,177)",
    },
    ox: {
        border: "rgb(133,225,174)",
        back: "rgb(183,255,224)",
    },
    oy: {
        border: "rgb(219,185,156)",
        back: "rgb(255,235,206)",
    },
    oz: {
        border: "rgb(140,234,124)",
        back: "rgb(190,255,174)",
    },
    pa: {
        border: "rgb(204,226,192)",
        back: "rgb(254,255,242)",
    },
    pb: {
        border: "rgb(166,212,146)",
        back: "rgb(216,255,196)",
    },
    pc: {
        border: "rgb(225,224,131)",
        back: "rgb(255,255,181)",
    },
    pd: {
        border: "rgb(158,168,211)",
        back: "rgb(208,218,255)",
    },
    pe: {
        border: "rgb(251,164,151)",
        back: "rgb(255,214,201)",
    },
    pf: {
        border: "rgb(153,221,153)",
        back: "rgb(203,255,203)",
    },
    pg: {
        border: "rgb(115,158,235)",
        back: "rgb(165,208,255)",
    },
    ph: {
        border: "rgb(166,112,161)",
        back: "rgb(216,162,211)",
    },
    pi: {
        border: "rgb(119,134,214)",
        back: "rgb(169,184,255)",
    },
    pj: {
        border: "rgb(133,207,240)",
        back: "rgb(183,255,255)",
    },
    pk: {
        border: "rgb(162,189,149)",
        back: "rgb(212,239,199)",
    },
    pl: {
        border: "rgb(202,120,208)",
        back: "rgb(252,170,255)",
    },
    pm: {
        border: "rgb(210,125,243)",
        back: "rgb(255,175,255)",
    },
    pn: {
        border: "rgb(216,149,188)",
        back: "rgb(255,199,238)",
    },
    po: {
        border: "rgb(130,158,226)",
        back: "rgb(180,208,255)",
    },
    pp: {
        border: "rgb(120,198,243)",
        back: "rgb(170,248,255)",
    },
    pq: {
        border: "rgb(246,155,145)",
        back: "rgb(255,205,195)",
    },
    pr: {
        border: "rgb(200,127,212)",
        back: "rgb(250,177,255)",
    },
    ps: {
        border: "rgb(236,167,236)",
        back: "rgb(255,217,255)",
    },
    pt: {
        border: "rgb(146,198,227)",
        back: "rgb(196,248,255)",
    },
    pu: {
        border: "rgb(193,124,246)",
        back: "rgb(243,174,255)",
    },
    pv: {
        border: "rgb(202,114,145)",
        back: "rgb(252,164,195)",
    },
    pw: {
        border: "rgb(208,192,246)",
        back: "rgb(255,242,255)",
    },
    px: {
        border: "rgb(241,255,138)",
        back: "rgb(255,255,188)",
    },
    py: {
        border: "rgb(163,170,215)",
        back: "rgb(213,220,255)",
    },
    pz: {
        border: "rgb(203,141,233)",
        back: "rgb(253,191,255)",
    },
    qa: {
        border: "rgb(123,183,187)",
        back: "rgb(173,233,237)",
    },
    qb: {
        border: "rgb(197,247,232)",
        back: "rgb(247,255,255)",
    },
    qc: {
        border: "rgb(203,249,228)",
        back: "rgb(253,255,255)",
    },
    qd: {
        border: "rgb(127,129,214)",
        back: "rgb(177,179,255)",
    },
    qe: {
        border: "rgb(242,112,106)",
        back: "rgb(255,162,156)",
    },
    qf: {
        border: "rgb(118,111,107)",
        back: "rgb(168,161,157)",
    },
    qg: {
        border: "rgb(224,220,152)",
        back: "rgb(255,255,202)",
    },
    qh: {
        border: "rgb(141,134,123)",
        back: "rgb(191,184,173)",
    },
    qi: {
        border: "rgb(201,120,177)",
        back: "rgb(251,170,227)",
    },
    qj: {
        border: "rgb(164,195,225)",
        back: "rgb(214,245,255)",
    },
    qk: {
        border: "rgb(228,120,118)",
        back: "rgb(255,170,168)",
    },
    ql: {
        border: "rgb(172,192,251)",
        back: "rgb(222,242,255)",
    },
    qm: {
        border: "rgb(217,159,157)",
        back: "rgb(255,209,207)",
    },
    qn: {
        border: "rgb(179,181,193)",
        back: "rgb(229,231,243)",
    },
    qo: {
        border: "rgb(226,224,158)",
        back: "rgb(255,255,208)",
    },
    qp: {
        border: "rgb(138,195,131)",
        back: "rgb(188,245,181)",
    },
    qq: {
        border: "rgb(176,170,226)",
        back: "rgb(226,220,255)",
    },
    qr: {
        border: "rgb(184,252,172)",
        back: "rgb(234,255,222)",
    },
    qs: {
        border: "rgb(193,212,176)",
        back: "rgb(243,255,226)",
    },
    qt: {
        border: "rgb(242,190,210)",
        back: "rgb(255,240,255)",
    },
    qu: {
        border: "rgb(108,245,105)",
        back: "rgb(158,255,155)",
    },
    qv: {
        border: "rgb(157,118,112)",
        back: "rgb(207,168,162)",
    },
    qw: {
        border: "rgb(242,241,186)",
        back: "rgb(255,255,236)",
    },
    qx: {
        border: "rgb(131,250,197)",
        back: "rgb(181,255,247)",
    },
    qy: {
        border: "rgb(224,154,159)",
        back: "rgb(255,204,209)",
    },
    qz: {
        border: "rgb(191,178,181)",
        back: "rgb(241,228,231)",
    },
    ra: {
        border: "rgb(218,103,208)",
        back: "rgb(255,153,255)",
    },
    rb: {
        border: "rgb(237,142,130)",
        back: "rgb(255,192,180)",
    },
    rc: {
        border: "rgb(116,209,126)",
        back: "rgb(166,255,176)",
    },
    rd: {
        border: "rgb(102,127,164)",
        back: "rgb(152,177,214)",
    },
    re: {
        border: "rgb(139,190,152)",
        back: "rgb(189,240,202)",
    },
    rf: {
        border: "rgb(234,156,252)",
        back: "rgb(255,206,255)",
    },
    rg: {
        border: "rgb(165,166,238)",
        back: "rgb(215,216,255)",
    },
    rh: {
        border: "rgb(108,228,150)",
        back: "rgb(158,255,200)",
    },
    ri: {
        border: "rgb(252,174,248)",
        back: "rgb(255,224,255)",
    },
    rj: {
        border: "rgb(161,196,240)",
        back: "rgb(211,246,255)",
    },
    rk: {
        border: "rgb(132,195,161)",
        back: "rgb(182,245,211)",
    },
    rl: {
        border: "rgb(221,130,146)",
        back: "rgb(255,180,196)",
    },
    rm: {
        border: "rgb(120,110,119)",
        back: "rgb(170,160,169)",
    },
    rn: {
        border: "rgb(145,104,237)",
        back: "rgb(195,154,255)",
    },
    ro: {
        border: "rgb(137,227,173)",
        back: "rgb(187,255,223)",
    },
    rp: {
        border: "rgb(166,126,237)",
        back: "rgb(216,176,255)",
    },
    rq: {
        border: "rgb(140,156,238)",
        back: "rgb(190,206,255)",
    },
    rr: {
        border: "rgb(146,239,157)",
        back: "rgb(196,255,207)",
    },
    rs: {
        border: "rgb(217,109,192)",
        back: "rgb(255,159,242)",
    },
    rt: {
        border: "rgb(165,210,144)",
        back: "rgb(215,255,194)",
    },
    ru: {
        border: "rgb(181,197,136)",
        back: "rgb(231,247,186)",
    },
    rv: {
        border: "rgb(233,104,221)",
        back: "rgb(255,154,255)",
    },
    rw: {
        border: "rgb(217,251,193)",
        back: "rgb(255,255,243)",
    },
    rx: {
        border: "rgb(204,115,234)",
        back: "rgb(254,165,255)",
    },
    ry: {
        border: "rgb(189,240,145)",
        back: "rgb(239,255,195)",
    },
    rz: {
        border: "rgb(114,139,194)",
        back: "rgb(164,189,244)",
    },
    sa: {
        border: "rgb(160,254,139)",
        back: "rgb(210,255,189)",
    },
    sb: {
        border: "rgb(128,119,138)",
        back: "rgb(178,169,188)",
    },
    sc: {
        border: "rgb(117,108,246)",
        back: "rgb(167,158,255)",
    },
    sd: {
        border: "rgb(110,208,130)",
        back: "rgb(160,255,180)",
    },
    se: {
        border: "rgb(208,132,150)",
        back: "rgb(255,182,200)",
    },
    sf: {
        border: "rgb(104,189,105)",
        back: "rgb(154,239,155)",
    },
    sg: {
        border: "rgb(240,232,147)",
        back: "rgb(255,255,197)",
    },
    sh: {
        border: "rgb(123,129,139)",
        back: "rgb(173,179,189)",
    },
    si: {
        border: "rgb(174,238,190)",
        back: "rgb(224,255,240)",
    },
    sj: {
        border: "rgb(246,118,158)",
        back: "rgb(255,168,208)",
    },
    sk: {
        border: "rgb(223,131,223)",
        back: "rgb(255,181,255)",
    },
    sl: {
        border: "rgb(201,133,141)",
        back: "rgb(251,183,191)",
    },
    sm: {
        border: "rgb(213,161,104)",
        back: "rgb(255,211,154)",
    },
    sn: {
        border: "rgb(120,177,144)",
        back: "rgb(170,227,194)",
    },
    so: {
        border: "rgb(128,169,141)",
        back: "rgb(178,219,191)",
    },
    sp: {
        border: "rgb(136,213,191)",
        back: "rgb(186,255,241)",
    },
    sq: {
        border: "rgb(241,195,112)",
        back: "rgb(255,245,162)",
    },
    sr: {
        border: "rgb(182,255,153)",
        back: "rgb(232,255,203)",
    },
    ss: {
        border: "rgb(242,164,236)",
        back: "rgb(255,214,255)",
    },
    st: {
        border: "rgb(122,145,236)",
        back: "rgb(172,195,255)",
    },
    su: {
        border: "rgb(167,229,172)",
        back: "rgb(217,255,222)",
    },
    sv: {
        border: "rgb(150,104,172)",
        back: "rgb(200,154,222)",
    },
    sw: {
        border: "rgb(154,119,121)",
        back: "rgb(204,169,171)",
    },
    sx: {
        border: "rgb(225,199,151)",
        back: "rgb(255,249,201)",
    },
    sy: {
        border: "rgb(180,165,140)",
        back: "rgb(230,215,190)",
    },
    sz: {
        border: "rgb(232,245,195)",
        back: "rgb(255,255,245)",
    },
    ta: {
        border: "rgb(214,128,249)",
        back: "rgb(255,178,255)",
    },
    tb: {
        border: "rgb(136,166,181)",
        back: "rgb(186,216,231)",
    },
    tc: {
        border: "rgb(164,175,243)",
        back: "rgb(214,225,255)",
    },
    td: {
        border: "rgb(207,149,227)",
        back: "rgb(255,199,255)",
    },
    te: {
        border: "rgb(246,104,117)",
        back: "rgb(255,154,167)",
    },
    tf: {
        border: "rgb(113,120,145)",
        back: "rgb(163,170,195)",
    },
    tg: {
        border: "rgb(123,115,191)",
        back: "rgb(173,165,241)",
    },
    th: {
        border: "rgb(126,237,117)",
        back: "rgb(176,255,167)",
    },
    ti: {
        border: "rgb(127,146,210)",
        back: "rgb(177,196,255)",
    },
    tj: {
        border: "rgb(199,168,131)",
        back: "rgb(249,218,181)",
    },
    tk: {
        border: "rgb(152,143,236)",
        back: "rgb(202,193,255)",
    },
    tl: {
        border: "rgb(196,179,102)",
        back: "rgb(246,229,152)",
    },
    tm: {
        border: "rgb(127,176,238)",
        back: "rgb(177,226,255)",
    },
    tn: {
        border: "rgb(196,221,134)",
        back: "rgb(246,255,184)",
    },
    to: {
        border: "rgb(173,243,173)",
        back: "rgb(223,255,223)",
    },
    tp: {
        border: "rgb(149,208,197)",
        back: "rgb(199,255,247)",
    },
    tq: {
        border: "rgb(133,186,145)",
        back: "rgb(183,236,195)",
    },
    tr: {
        border: "rgb(138,235,140)",
        back: "rgb(188,255,190)",
    },
    ts: {
        border: "rgb(189,248,111)",
        back: "rgb(239,255,161)",
    },
    tt: {
        border: "rgb(202,195,108)",
        back: "rgb(252,245,158)",
    },
    tu: {
        border: "rgb(212,157,172)",
        back: "rgb(255,207,222)",
    },
    tv: {
        border: "rgb(149,250,170)",
        back: "rgb(199,255,220)",
    },
    tw: {
        border: "rgb(120,203,104)",
        back: "rgb(170,253,154)",
    },
    tx: {
        border: "rgb(110,233,255)",
        back: "rgb(160,255,255)",
    },
    ty: {
        border: "rgb(231,158,187)",
        back: "rgb(255,208,237)",
    },
    tz: {
        border: "rgb(141,145,201)",
        back: "rgb(191,195,251)",
    },
    ua: {
        border: "rgb(229,132,100)",
        back: "rgb(255,182,150)",
    },
    ub: {
        border: "rgb(250,228,218)",
        back: "rgb(255,255,255)",
    },
    uc: {
        border: "rgb(184,233,199)",
        back: "rgb(234,255,249)",
    },
    ud: {
        border: "rgb(131,223,216)",
        back: "rgb(181,255,255)",
    },
    ue: {
        border: "rgb(220,139,102)",
        back: "rgb(255,189,152)",
    },
    uf: {
        border: "rgb(172,116,138)",
        back: "rgb(222,166,188)",
    },
    ug: {
        border: "rgb(160,238,197)",
        back: "rgb(210,255,247)",
    },
    uh: {
        border: "rgb(175,103,197)",
        back: "rgb(225,153,247)",
    },
    ui: {
        border: "rgb(240,189,232)",
        back: "rgb(255,239,255)",
    },
    uj: {
        border: "rgb(214,202,159)",
        back: "rgb(255,252,209)",
    },
    uk: {
        border: "rgb(194,125,153)",
        back: "rgb(244,175,203)",
    },
    ul: {
        border: "rgb(191,144,251)",
        back: "rgb(241,194,255)",
    },
    um: {
        border: "rgb(140,108,158)",
        back: "rgb(190,158,208)",
    },
    un: {
        border: "rgb(126,247,131)",
        back: "rgb(176,255,181)",
    },
    uo: {
        border: "rgb(212,175,132)",
        back: "rgb(255,225,182)",
    },
    up: {
        border: "rgb(174,165,217)",
        back: "rgb(224,215,255)",
    },
    uq: {
        border: "rgb(177,117,238)",
        back: "rgb(227,167,255)",
    },
    ur: {
        border: "rgb(238,237,241)",
        back: "rgb(255,255,255)",
    },
    us: {
        border: "rgb(214,194,199)",
        back: "rgb(255,244,249)",
    },
    ut: {
        border: "rgb(209,188,125)",
        back: "rgb(255,238,175)",
    },
    uu: {
        border: "rgb(189,152,160)",
        back: "rgb(239,202,210)",
    },
    uv: {
        border: "rgb(237,170,106)",
        back: "rgb(255,220,156)",
    },
    uw: {
        border: "rgb(231,187,117)",
        back: "rgb(255,237,167)",
    },
    ux: {
        border: "rgb(124,146,156)",
        back: "rgb(174,196,206)",
    },
    uy: {
        border: "rgb(111,195,243)",
        back: "rgb(161,245,255)",
    },
    uz: {
        border: "rgb(157,142,212)",
        back: "rgb(207,192,255)",
    },
    va: {
        border: "rgb(162,139,251)",
        back: "rgb(212,189,255)",
    },
    vb: {
        border: "rgb(154,151,111)",
        back: "rgb(204,201,161)",
    },
    vc: {
        border: "rgb(100,177,109)",
        back: "rgb(150,227,159)",
    },
    vd: {
        border: "rgb(190,144,112)",
        back: "rgb(240,194,162)",
    },
    ve: {
        border: "rgb(199,241,149)",
        back: "rgb(249,255,199)",
    },
    vf: {
        border: "rgb(219,114,178)",
        back: "rgb(255,164,228)",
    },
    vg: {
        border: "rgb(203,176,168)",
        back: "rgb(253,226,218)",
    },
    vh: {
        border: "rgb(200,117,142)",
        back: "rgb(250,167,192)",
    },
    vi: {
        border: "rgb(134,156,100)",
        back: "rgb(184,206,150)",
    },
    vj: {
        border: "rgb(171,230,252)",
        back: "rgb(221,255,255)",
    },
    vk: {
        border: "rgb(145,126,157)",
        back: "rgb(195,176,207)",
    },
    vl: {
        border: "rgb(106,140,205)",
        back: "rgb(156,190,255)",
    },
    vm: {
        border: "rgb(171,113,184)",
        back: "rgb(221,163,234)",
    },
    vn: {
        border: "rgb(130,199,122)",
        back: "rgb(180,249,172)",
    },
    vo: {
        border: "rgb(125,100,145)",
        back: "rgb(175,150,195)",
    },
    vp: {
        border: "rgb(237,205,196)",
        back: "rgb(255,255,246)",
    },
    vq: {
        border: "rgb(254,118,213)",
        back: "rgb(255,168,255)",
    },
    vr: {
        border: "rgb(103,173,208)",
        back: "rgb(153,223,255)",
    },
    vs: {
        border: "rgb(122,119,140)",
        back: "rgb(172,169,190)",
    },
    vt: {
        border: "rgb(237,112,230)",
        back: "rgb(255,162,255)",
    },
    vu: {
        border: "rgb(105,233,248)",
        back: "rgb(155,255,255)",
    },
    vv: {
        border: "rgb(203,103,212)",
        back: "rgb(253,153,255)",
    },
    vw: {
        border: "rgb(184,167,145)",
        back: "rgb(234,217,195)",
    },
    vx: {
        border: "rgb(130,112,222)",
        back: "rgb(180,162,255)",
    },
    vy: {
        border: "rgb(203,232,158)",
        back: "rgb(253,255,208)",
    },
    vz: {
        border: "rgb(237,100,174)",
        back: "rgb(255,150,224)",
    },
    wa: {
        border: "rgb(171,106,211)",
        back: "rgb(221,156,255)",
    },
    wb: {
        border: "rgb(166,158,127)",
        back: "rgb(216,208,177)",
    },
    wc: {
        border: "rgb(130,115,254)",
        back: "rgb(180,165,255)",
    },
    wd: {
        border: "rgb(193,221,137)",
        back: "rgb(243,255,187)",
    },
    we: {
        border: "rgb(123,245,229)",
        back: "rgb(173,255,255)",
    },
    wf: {
        border: "rgb(136,207,204)",
        back: "rgb(186,255,254)",
    },
    wg: {
        border: "rgb(134,125,231)",
        back: "rgb(184,175,255)",
    },
    wh: {
        border: "rgb(148,123,221)",
        back: "rgb(198,173,255)",
    },
    wi: {
        border: "rgb(183,228,201)",
        back: "rgb(233,255,251)",
    },
    wj: {
        border: "rgb(121,216,112)",
        back: "rgb(171,255,162)",
    },
    wk: {
        border: "rgb(158,198,142)",
        back: "rgb(208,248,192)",
    },
    wl: {
        border: "rgb(228,245,163)",
        back: "rgb(255,255,213)",
    },
    wm: {
        border: "rgb(191,198,165)",
        back: "rgb(241,248,215)",
    },
    wn: {
        border: "rgb(129,166,165)",
        back: "rgb(179,216,215)",
    },
    wo: {
        border: "rgb(134,168,168)",
        back: "rgb(184,218,218)",
    },
    wp: {
        border: "rgb(173,214,113)",
        back: "rgb(223,255,163)",
    },
    wq: {
        border: "rgb(210,130,173)",
        back: "rgb(255,180,223)",
    },
    wr: {
        border: "rgb(211,169,203)",
        back: "rgb(255,219,253)",
    },
    ws: {
        border: "rgb(216,206,227)",
        back: "rgb(255,255,255)",
    },
    wt: {
        border: "rgb(208,100,207)",
        back: "rgb(255,150,255)",
    },
    wu: {
        border: "rgb(216,206,156)",
        back: "rgb(255,255,206)",
    },
    wv: {
        border: "rgb(139,119,147)",
        back: "rgb(189,169,197)",
    },
    ww: {
        border: "rgb(241,191,104)",
        back: "rgb(255,241,154)",
    },
    wx: {
        border: "rgb(164,185,205)",
        back: "rgb(214,235,255)",
    },
    wy: {
        border: "rgb(176,124,227)",
        back: "rgb(226,174,255)",
    },
    wz: {
        border: "rgb(137,228,249)",
        back: "rgb(187,255,255)",
    },
    xa: {
        border: "rgb(254,211,202)",
        back: "rgb(255,255,252)",
    },
    xb: {
        border: "rgb(209,221,247)",
        back: "rgb(255,255,255)",
    },
    xc: {
        border: "rgb(192,149,226)",
        back: "rgb(242,199,255)",
    },
    xd: {
        border: "rgb(109,248,215)",
        back: "rgb(159,255,255)",
    },
    xe: {
        border: "rgb(117,187,131)",
        back: "rgb(167,237,181)",
    },
    xf: {
        border: "rgb(237,104,144)",
        back: "rgb(255,154,194)",
    },
    xg: {
        border: "rgb(169,229,229)",
        back: "rgb(219,255,255)",
    },
    xh: {
        border: "rgb(112,223,162)",
        back: "rgb(162,255,212)",
    },
    xi: {
        border: "rgb(194,120,180)",
        back: "rgb(244,170,230)",
    },
    xj: {
        border: "rgb(124,168,150)",
        back: "rgb(174,218,200)",
    },
    xk: {
        border: "rgb(122,200,190)",
        back: "rgb(172,250,240)",
    },
    xl: {
        border: "rgb(173,221,166)",
        back: "rgb(223,255,216)",
    },
    xm: {
        border: "rgb(144,136,178)",
        back: "rgb(194,186,228)",
    },
    xn: {
        border: "rgb(188,123,144)",
        back: "rgb(238,173,194)",
    },
    xo: {
        border: "rgb(102,216,153)",
        back: "rgb(152,255,203)",
    },
    xp: {
        border: "rgb(100,106,143)",
        back: "rgb(150,156,193)",
    },
    xq: {
        border: "rgb(225,173,185)",
        back: "rgb(255,223,235)",
    },
    xr: {
        border: "rgb(235,160,244)",
        back: "rgb(255,210,255)",
    },
    xs: {
        border: "rgb(237,106,202)",
        back: "rgb(255,156,252)",
    },
    xt: {
        border: "rgb(148,242,251)",
        back: "rgb(198,255,255)",
    },
    xu: {
        border: "rgb(217,105,175)",
        back: "rgb(255,155,225)",
    },
    xv: {
        border: "rgb(177,226,103)",
        back: "rgb(227,255,153)",
    },
    xw: {
        border: "rgb(203,170,186)",
        back: "rgb(253,220,236)",
    },
    xx: {
        border: "rgb(252,175,172)",
        back: "rgb(255,225,222)",
    },
    xy: {
        border: "rgb(203,117,137)",
        back: "rgb(253,167,187)",
    },
    xz: {
        border: "rgb(208,231,131)",
        back: "rgb(255,255,181)",
    },
    ya: {
        border: "rgb(180,191,142)",
        back: "rgb(230,241,192)",
    },
    yb: {
        border: "rgb(110,233,119)",
        back: "rgb(160,255,169)",
    },
    yc: {
        border: "rgb(232,162,112)",
        back: "rgb(255,212,162)",
    },
    yd: {
        border: "rgb(149,234,231)",
        back: "rgb(199,255,255)",
    },
    ye: {
        border: "rgb(144,243,221)",
        back: "rgb(194,255,255)",
    },
    yf: {
        border: "rgb(232,187,123)",
        back: "rgb(255,237,173)",
    },
    yg: {
        border: "rgb(107,129,229)",
        back: "rgb(157,179,255)",
    },
    yh: {
        border: "rgb(247,125,199)",
        back: "rgb(255,175,249)",
    },
    yi: {
        border: "rgb(205,103,122)",
        back: "rgb(255,153,172)",
    },
    yj: {
        border: "rgb(135,250,217)",
        back: "rgb(185,255,255)",
    },
    yk: {
        border: "rgb(134,106,194)",
        back: "rgb(184,156,244)",
    },
    yl: {
        border: "rgb(189,133,186)",
        back: "rgb(239,183,236)",
    },
    ym: {
        border: "rgb(183,178,200)",
        back: "rgb(233,228,250)",
    },
    yn: {
        border: "rgb(224,113,187)",
        back: "rgb(255,163,237)",
    },
    yo: {
        border: "rgb(148,117,106)",
        back: "rgb(198,167,156)",
    },
    yp: {
        border: "rgb(174,243,151)",
        back: "rgb(224,255,201)",
    },
    yq: {
        border: "rgb(204,164,233)",
        back: "rgb(254,214,255)",
    },
    yr: {
        border: "rgb(240,149,229)",
        back: "rgb(255,199,255)",
    },
    ys: {
        border: "rgb(122,158,106)",
        back: "rgb(172,208,156)",
    },
    yt: {
        border: "rgb(153,196,225)",
        back: "rgb(203,246,255)",
    },
    yu: {
        border: "rgb(233,101,148)",
        back: "rgb(255,151,198)",
    },
    yv: {
        border: "rgb(213,104,118)",
        back: "rgb(255,154,168)",
    },
    yw: {
        border: "rgb(137,125,204)",
        back: "rgb(187,175,254)",
    },
    yx: {
        border: "rgb(228,187,159)",
        back: "rgb(255,237,209)",
    },
    yy: {
        border: "rgb(131,184,197)",
        back: "rgb(181,234,247)",
    },
    yz: {
        border: "rgb(151,221,190)",
        back: "rgb(201,255,240)",
    },
    za: {
        border: "rgb(182,239,233)",
        back: "rgb(232,255,255)",
    },
    zb: {
        border: "rgb(204,250,149)",
        back: "rgb(254,255,199)",
    },
    zc: {
        border: "rgb(209,115,212)",
        back: "rgb(255,165,255)",
    },
    zd: {
        border: "rgb(244,191,219)",
        back: "rgb(255,241,255)",
    },
    ze: {
        border: "rgb(202,102,223)",
        back: "rgb(252,152,255)",
    },
    zf: {
        border: "rgb(171,225,108)",
        back: "rgb(221,255,158)",
    },
    zg: {
        border: "rgb(126,163,253)",
        back: "rgb(176,213,255)",
    },
    zh: {
        border: "rgb(101,184,214)",
        back: "rgb(151,234,255)",
    },
    zi: {
        border: "rgb(177,255,227)",
        back: "rgb(227,255,255)",
    },
    zj: {
        border: "rgb(208,106,245)",
        back: "rgb(255,156,255)",
    },
    zk: {
        border: "rgb(229,152,197)",
        back: "rgb(255,202,247)",
    },
    zl: {
        border: "rgb(196,139,165)",
        back: "rgb(246,189,215)",
    },
    zm: {
        border: "rgb(136,158,146)",
        back: "rgb(186,208,196)",
    },
    zn: {
        border: "rgb(106,215,102)",
        back: "rgb(156,255,152)",
    },
    zo: {
        border: "rgb(175,116,152)",
        back: "rgb(225,166,202)",
    },
    zp: {
        border: "rgb(236,239,131)",
        back: "rgb(255,255,181)",
    },
    zq: {
        border: "rgb(144,255,157)",
        back: "rgb(194,255,207)",
    },
    zr: {
        border: "rgb(132,176,190)",
        back: "rgb(182,226,240)",
    },
    zs: {
        border: "rgb(189,186,215)",
        back: "rgb(239,236,255)",
    },
    zt: {
        border: "rgb(212,253,111)",
        back: "rgb(255,255,161)",
    },
    zu: {
        border: "rgb(149,134,165)",
        back: "rgb(199,184,215)",
    },
    zv: {
        border: "rgb(219,250,184)",
        back: "rgb(255,255,234)",
    },
    zw: {
        border: "rgb(166,219,185)",
        back: "rgb(216,255,235)",
    },
    zx: {
        border: "rgb(104,151,236)",
        back: "rgb(154,201,255)",
    },
    zy: {
        border: "rgb(132,172,198)",
        back: "rgb(182,222,248)",
    },
    zz: {
        border: "rgb(241,227,164)",
        back: "rgb(255,255,214)",
    },
}

export const getRandomBackground = () => {
    let initials = [
        Math.floor(Math.random() * 26) + 97,
        Math.floor(Math.random() * 26) + 97,
    ]
    return colorByInitials[
        String.fromCharCode(initials[0]) + "" + String.fromCharCode(initials[1])
    ]
}

/**
 * The function `getBackgroundFromInitials` takes initials as input and returns a color based on the
 * initials.
 * @param initials - The `initials` parameter is a string representing the initials of a person or
 * entity.
 * @returns the value of `colorByInitials[initials]`.
 */
export const getBackgroundFromInitials = (initials) => {
    if (!initials) return signerBackgrounds[0]
    let currentInitials = ""
    Array(2)
        .fill()
        .forEach((_, i) => {
            if (initials[i].charCodeAt(0) < 97 || initials[i].charCodeAt(0) > 122)
                currentInitials += String.fromCharCode(
                    (initials[i].charCodeAt(0) % 25) + 97
                )
            else currentInitials += initials[i]
        })
    if (colorByInitials[currentInitials]) return colorByInitials[currentInitials]
    return signerBackgrounds[0]
}

export const getSignerBackgroundDecorum = (signerInitials, number) => {
    if (!number || number > signerBackgrounds.length)
        return getBackgroundFromInitials(signerInitials)
    return signerBackgrounds[number - 1]
}
