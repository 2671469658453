import React, { Component } from "react"

import "./setting.scss"

import SideNav from "./components/SideNav/SideNav"
import ProfileSetting from "./components/ProfileSettings/ProfileSettings"
// import Notifications from "./components/notificationSetting/Notification";
import Customization from "./components/Customization/Customization"
// import Subscription from "./components/Subscription/Subscription";
import WorkspaceSettings from "./components/WorkspaceSettings"
import Users from "./components/Users"
import Guard from "Awesome-ui/Guard"

class index extends Component {
    state = {
        view: "profile",
        loading: true,
        propsData: null,
    }

    views = {
        PROFILE: "profile",
        NOTIFICATION: "notification",
        CUSTOMIZATION: "customization",
        USERS: "users",
        PLAN: "plan",
        FREEPLAN: "freeplan",
        PERMISSIONS: "permissions",
        PERMISSIONS_EDIT: "permissions_edit",
        WORKSPACE: "workspace"
    }

    handleViewChange = (newValue) => {
        this.setState({ view: newValue })
    }

    componentDidMount() {
        const fromUrl = this.props.match.params.settingView
        const search = this.props.location.search
        let parsedUrl
        if (search) 
            parsedUrl = new URLSearchParams(search)
        
        if (fromUrl) {
            const result = Object.values(this.views).find(
                (defnView) => defnView.toLowerCase() === fromUrl.toLowerCase()
            )
            if (result) this.setState({ view: result, loading: false })
            else {
                this.props.history.push("/settings/profile")
            }
        }
    }

    componentDidUpdate() {
        const fromUrl = this.props.match.params.settingView
        if (fromUrl) {
            const result = Object.values(this.views).find(
                (defnView) => defnView.toLowerCase() === fromUrl.toLowerCase()
            )
            if (this.state.view === result) return;
            if (result) this.setState({ view: result, loading: false })
            else {
                this.props.history.push("/settings/profile")
            }
        }
    }

    shouldComponentUpdate(props, state) {
        if (this.props.match.params.settingView !== props.match.params.settingView);
        return true
    }

    render() {
        return (
            <div className="profileSetting">
                <SideNav
                    handleViewChange={this.handleViewChange}
                    views={this.views}
                    currView={this.state.view}
                />
                {(() => {
                    switch (this.state.view) {
                        case this.views.PROFILE:
                            return <ProfileSetting {...this.props} />
                        // case this.views.NOTIFICATION:
                        //   return <Notifications {...this.props} />;
                        case this.views.CUSTOMIZATION:
                            return <Customization {...this.props} />
                        case this.views.USERS:
                          return <Guard scope = "account_settings_get"><Users {...this.props} /></Guard>
                        // case this.views.FREEPLAN:
                        //   return <FreePlan />;
                        case this.views.PLAN:
                          return <Guard scope = "subscription_settings_get">Subscription</Guard>;
                        // case this.views.PERMISSIONS:
                        //   return <Permissions {...this.props} />;
                        case this.views.WORKSPACE:
                            return <Guard scope = "account_settings_get"><WorkspaceSettings {...this.props} /></Guard>
                        default:
                            return <div>hey</div>
                    }
                })()}
            </div>
        )
    }
}

export default index
