const circles = {
    width: "10px",
    height: "10px",
    border: "1px solid #0a73b6",
}

const circlesClear = {
    width: "10px",
    height: "10px",
    background: "transparent",
}

export const dragStyle = {
    bottom: {
        borderBottom: "1px dashed #0a73b6",
    },
    top: {
        borderTop: "1px dashed #0a73b6",
    },
    left: {
        borderLeft: "1px dashed #0a73b6",
    },
    right: {
        borderRight: "1px dashed #0a73b6",
    },
    bottomLeft: circles,
    topLeft: circles,
    topRight: circles,
    bottomRight: circles,
}

export const dragStyleTransparent = {
    bottom: {
        borderBottom: "1px solid transparent",
    },
    top: {
        borderTop: "1px solid transparent",
    },
    left: {
        borderLeft: "1px solid transparent",
    },
    right: {
        borderRight: "1px solid transparent",
    },
    bottomLeft: circlesClear,
    topLeft: circlesClear,
    topRight: circlesClear,
    bottomRight: circlesClear,
}

export const dragClasses = {
    top: "drag-item-resizing-bars-top",
    bottom: "drag-item-resizing-bars-bottom",
    left: "drag-item-resizing-bars-left",
    right: "drag-item-resizing-bars-right",
}
