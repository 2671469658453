import React, { useRef } from "react"
import { ReactComponent as ThreeDots } from "../../Assets/Storage/vertical-dots.svg"
import useToggle from "../../hooks/ToggleHook"
import "./style.js"
import { useStyle } from "./style.js"

/**
 * @description Three dot options dropdown component
 * @params
 * options[] : [{
 *      name: String,
 *      icon: HTMLComponent / ReactComponent
 *      onClick: Function (What happens onClick)
 *      style: Object (Custom style)
 * }]
 * onClick: Function (On Dropdown open)
 * enableIcon: show icons in dropdown
 * icon: vertical | horizontal
 */
const ThreeDotOptionDropdown = ({
    options = [],
    onClick = () => {},
    enableIcon,
    style = {},
    idName,
    iconStyle = "vertical",
    disabled,
    ...props
}) => {
    const classes = useStyle({ iconStyle, ...props })

    const brandsDrop = useRef()
    const [visible, toggle] = useToggle(brandsDrop)

    function handleToggle() {
        if (disabled) return
        toggle(!visible)
        onClick()
    }

    return (
        <div
            className={`${classes.threeDotDropdown} ${
                disabled
                    ? classes.threeDotDropdownInactive
                    : classes.threeDotDropdownActive
            }`}
            onClick={handleToggle}
            id={idName}
            style={style}
        >
            <span
                className={classes.dotIcon}
                style={{
                    transform: iconStyle === "horizontal" ? "rotate(90deg)" : "",
                    opacity: disabled ? 0.7 : 1,
                }}
            >
                <ThreeDots />
            </span>
            <li
                className={`${classes.threeDotSubDropdown} ${
                    visible
                        ? classes.threeDotSubDropdownopen
                        : classes.threeDotSubDropdownClose
                }`}
                style={{ minWidth: props.dropdownWidth ? props.dropdownWidth : "" }}
            >
                {options.map(({ name, icon, onClick = () => {}, style = {} }, i) => (
                    <ul
                        key={i}
                        onClick={() => {
                            toggle(false)
                            onClick()
                        }}
                        style={style}
                    >
                        {enableIcon && (
                            <span
                                style={{
                                    display: "inline-flex",
                                    maxWidth: "1rem",
                                    maxHeight: "1rem",
                                }}
                            >
                                {icon}
                            </span>
                        )}
                        <span>{name}</span>
                    </ul>
                ))}
            </li>
        </div>
    )
}

export default ThreeDotOptionDropdown
