import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./ESignaturePage.scss"

import PdfTop from "./PdfTop/PdfTop"
import PdfEditor from "./PdfViewer/PdfEditor"
import { setDocumentAccept, togglePdfPagesPreview } from "Action/signAction"
import { getDocumentByIdPublic } from "Action/documentAction"
import { getFieldsbyRoleId, signerViewEvent } from "Action/signersActions"
import { Button, Grid, Text } from "@awesomesuite-frontend/awesome-nebula"
import { ReactComponent as SignDocIMG } from "Assets/Dashboard/DocTosigned.svg"
import { rejectSign } from "Action/signersActions"
import { useHistory } from "react-router-dom"
import PdfLeftSideBar from "Components/PDFCommons/PdfLeftSideBar"
import { ReactComponent as PagesIcon } from "Assets/ToolsIcons/pagesIcon.svg"
import { CANCELLED, OUT_FOR_SIGNATURE } from "Types"
import PublicTemplateEditor from "public/TemplateEditor/PublicTemplateEditor"
import EditorLoader from "./EditorLoader"

/**
 * this is the pdf e-signature page with the pdf viewer and editing tools.
 * for public use.
 */
const ESignaturePage = ({
    signerId,
    document,
    isPdfPageView,
    documentUrl,
    currentSignerStatus,
    docStatus,
    enableTemplateFlow,
    isAccepted,
    ...props
}) => {
    const [activePage, setActivePage] = React.useState(0)
    const [isPageScrolling, setIsPageScrolling] = useState(false)
    const parentRef = React.useRef()
    const [loadingWhileDocumentSign, setLoadingWhileDocumentSign] = useState(false)

    function getPdfPages() {
        parentRef.current.getPdfPages()
    }

    function saveAllAnnotations() {
        return parentRef.current.saveAllAnnotations()
    }

    useEffect(() => {
        async function getPdfDocument() {
            if (!props.match.params) return

            const { documentId } = props.match.params
            if (documentId) {
                const documentResponse = await getDocumentByIdPublic(documentId)
                const { document, signer } = documentResponse
                if (
                    document.enableTemplateFlow &&
                    document.status === OUT_FOR_SIGNATURE
                ) {
                    await getFieldsbyRoleId(signer?.roleId)
                }
            }
        }
        getPdfDocument()
    }, [])

    useEffect(() => {
        if (signerId) signerViewEvent(signerId, document)
    }, [signerId])

    if (enableTemplateFlow)
        return (
            <PublicTemplateEditor
                activePage={activePage}
                setActivePage={setActivePage}
                parentRef={parentRef}
                params={props.match.params}
                loading={loadingWhileDocumentSign}
                setLoading={setLoadingWhileDocumentSign}
                saveAllAnnotations={saveAllAnnotations}
            />
        )

    return (
        <div style={{ width: "100%", overflow: "hidden", minHeight: "100vh" }}>
            {loadingWhileDocumentSign && <EditorLoader />}
            <PdfTop
                securityHash={props.match?.params?.documentId}
                setLoading={setLoadingWhileDocumentSign}
                saveAllAnnotations={saveAllAnnotations}
            />
            {!isAccepted
                ? ["VIEWED", "SENT"].indexOf(currentSignerStatus) !== -1 &&
                  docStatus !== CANCELLED && (
                      <AcceptSign
                          onClick={() => setDocumentAccept(true)}
                          signerId={signerId}
                          documentId={document}
                          token={props.match.params?.documentId}
                      />
                  )
                : isPdfPageView || (
                      <div className="public-pdf-handler-options">
                          <div
                              className={`operation-handler-public preview-btn-public`}
                              onClick={togglePdfPagesPreview}
                          >
                              <PagesIcon />
                          </div>
                      </div>
                  )}
            <div
                className="public-ESignature-page"
                style={{ height: docStatus && "92.2vh" }}
            >
                <div className="public-ESignature-pdf">
                    <PdfEditor
                        parentRef={parentRef}
                        {...props}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        canvasStyle={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                        isPageScrolling={isPageScrolling}
                    />
                </div>
                {isPdfPageView && (
                    <div className="public-pdf-preview">
                        <div
                            className="public-pdf-preview-cross"
                            onClick={togglePdfPagesPreview}
                        >
                            ✖
                        </div>
                        <PdfLeftSideBar
                            activePage={activePage}
                            getPdfPages={getPdfPages}
                            setActivePage={setActivePage}
                            publicPreview
                            pageScrollingHandler={setIsPageScrolling}
                        />
                    </div>
                )}
            </div>
            <a className="sign-aui-add" href="https://www.awesomesuite.com/sign">
                <Text>⚡ by Awesome Sign</Text>
            </a>
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    docStatus: signReducer.status,
    currentSignerStatus: signReducer?.currentSignerStatus,
    documentUrl: signReducer.documentDetails?.documentUrl,
    document: signReducer.documentDetails?.documentId,
    signerId: signReducer.documentDetails?.currentSigner,
    isPdfPageView: signReducer.isPdfPageView,
    isAccepted: signReducer.isAccepted,
    enableTemplateFlow: signReducer?.documentDetails?.enableTemplateFlow,
})

export default connect(mapStateToProps)(ESignaturePage)

export const AcceptSign = ({ onClick, signerId, documentId, token }) => {
    const history = useHistory()

    return (
        <Grid container align="center" padding="12px 0" justify={"center"}>
            <Grid item xs={1} align="center">
                <SignDocIMG
                    height="2.4rem"
                    style={{ margin: "0 auto", display: "block" }}
                />
            </Grid>
            <Grid item xs={6}>
                <Text align="center">
                    Please Accept that you acknowledge this document and will accept
                    to sign the document.
                </Text>
            </Grid>
            <Grid container item xs={2} justify="center">
                <Button color="sky" size="medium" radius={6} onClick={onClick}>
                    Accept
                </Button>
                <span style={{ marginRight: 12 }}></span>
                <Button
                    color="sky"
                    size="medium"
                    radius={6}
                    onClick={async () => {
                        await rejectSign(signerId, history, documentId)
                        if (token) await getDocumentByIdPublic(token)
                    }}
                >
                    Decline
                </Button>
            </Grid>
        </Grid>
    )
}
