import {
    SET_NO_SIDE_BAR,
    SET_SIDE_BAR_COLOR,
    SET_SIDE_BAR_EXPAND,
    SET_SIDE_BAR_TYPE,
} from "../ActionTypes/settingsTyps"
import { store } from "../store"

const { dispatch } = store;

/**
 * change the side bar type
 * there is two types of sidebars.
 *
 * 1. named : box type sidebar with name
 * 2. slim : slim icons
 *
 * @param {["named", "slim", "no_sideBar"]} type
 */
export const setSideBarType = (type) => {
    dispatch({ type: SET_SIDE_BAR_TYPE, payload: { type: type } })
}

/**
 * change the sidebar theme choose between dark and light.
 *
 * @param {["dark", "light"]}
 */
export const setSideBarColor = (color) => {
    dispatch({ type: SET_SIDE_BAR_COLOR, payload: { color: color } })
}

/**
 * handle remove the side bar or keep side bar
 * with the handler
 *
 */
export const setNoSideBar = () => {
    dispatch({ type: SET_NO_SIDE_BAR })
}

export const expandSideBar = () => {
    dispatch({ type: SET_SIDE_BAR_EXPAND })
}
