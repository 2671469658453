/**
 * canvas image generator for the textField component with the text wrapping feature.
 * and gives output in the blob format.
 * 
 * @param {*} text - text to be printed on the canvas and it can contain any type of text and 
 *                   it will get wrapped in this component. 
 * @param {*} width - width of the canvas
 * @param {*} height - height of the canvas
 * @param {*} scale - we can choose the scale of the canvas with this 
 *                    value you can put 2 to get 2 time of height and width.
 * @param {*} baseFont - font - size in numbers.
 * @param {*} fontFamily - fontFamily name with defaults other wise it will take the default values.
 * 
 */
export const createCanvasWithText = (text, width, height, scale = 1, baseFont, fontFamily) => {
    console.table(text, width, height, scale, baseFont, fontFamily);
    const scaledWidth = width * scale;
    const scaledHeight = height * scale;
    const canvas = document.createElement("canvas");
    
    canvas.width = scaledWidth;
    canvas.height = scaledHeight;
    
    const ctx = canvas.getContext("2d");
    ctx.setTransform(1, 0, 0, 1, 0, 0);

    ctx.font = `${baseFont * scale}px ${fontFamily}`;

    // left margin width in the canvas
    let x = 6;
    // this is height in y axis or text line  
    let y = 50;

    const para = text.split("\n");
    let line = "";
    para.forEach((element, _) => {
        line = "";
        const words = element.split(" ");

        words.forEach((word, index) => {
            var printLine = line + word + " ";
            var dimension = ctx.measureText(printLine);
            var width = dimension.width;
            if(width > scaledWidth && index > 0) {
                ctx.fillText(line, x, y);
                line = word + " ";
                y += 50;
            }
            else 
                line = printLine;
        });

        ctx.fillText(line, x, y);
        y +=50;
    });

    const data = canvas.toDataURL("image/png");
    
    return data;

}

export const getCanvasWithTextWidth = (text, fontFamily, scale, color) => {
    const tempCanvas = document.createElement("canvas");
    const ctx = tempCanvas.getContext("2d");
    ctx.font = `${1.2 * scale}rem`;
    const dimension = ctx.measureText(text);

    let actualHeight = dimension.actualBoundingBoxAscent + dimension.actualBoundingBoxDescent;
    let fontHeight = dimension.fontBoundingBoxAscent + dimension.fontBoundingBoxDescent

    let widthScaler = 5 ;

    if(text.length < 5) {
        widthScaler = 12
    }

    if(text.length > 40) {
        widthScaler = 6
    }

    if(text.length > 60) {
        widthScaler = 7
    }

    tempCanvas.width = dimension.width * widthScaler;
    tempCanvas.height = actualHeight + fontHeight + 50;

    ctx.font = `${1.2 * scale}rem ${fontFamily}`;
    ctx.fillStyle = `${color}`
    ctx.fillText(text, 4, 50);
    
    return {
        width: tempCanvas.width,
        height: tempCanvas.height,
        data: tempCanvas.toDataURL()
    };
}