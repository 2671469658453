import React from "react"
import { ReactComponent as FileIcon } from "Assets/Storage/filePdf.svg"
import { RECIEVED_FOR_SIGNATURE } from "Types"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import { Text, Avatar } from "@awesomesuite-frontend/awesome-nebula"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

const DocumentStatus = ({
    fileName,
    fileId,
    status,
    updatedAt,
    name,
    imageSource,
    signer,
    userId,
}) => {
    const { splitText, getDate } = useBasicUtils()
    const history = useHistory()
    const documentOpener = () => {
        if (status === RECIEVED_FOR_SIGNATURE) {
            const user = signer.find((s) => s.userId === userId)
            history.push(`/sign/${user.hash}`)
            return
        }
        history.push(`/pdf-editor/${fileId}`)
    }
    return (
        <div
            className={`aui-document-card document-card-${status}`}
            onClick={documentOpener}
        >
            <TooltipWrapper
                wrapperClassName="aui-document-data"
                direction={"top"}
                text={fileName}
                style={{ maxWidth: "18rem" }}
                textStyle={{ wordBreak: "break-all" }}
            >
                <div className="aui-document-time">
                    <FileIcon />
                </div>
                <div className="aui-document-name">
                    <Text fontSize={15} weight={600}>
                        {splitText(fileName, 40)}
                    </Text>
                    <Text fontSize={13} color="#7C98B6">
                        Last Updated : {getDate(updatedAt)}
                    </Text>
                </div>
            </TooltipWrapper>
            <div className="aui-document-avatar">
                <TooltipWrapper direction={"top"} text={`Owned by ${name}`}>
                    <Avatar name={name} imageSource={imageSource} />
                </TooltipWrapper>
            </div>
        </div>
    )
}

const mapStateToProps = ({ userReducer }) => ({
    userId: userReducer.userDetails?.id,
})

export default connect(mapStateToProps)(DocumentStatus)
