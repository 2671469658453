import React from "react";
import ResourceCenterCard from "../ResourceCenterCard/ResourceCenterCard";
import "./ResourceCenterContainer.scss";

import { ReactComponent as FaqICON } from "./images/faq.svg"
import { ReactComponent as GettingStartedICON } from "./images/getting-started.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula";

// Sample Data
const resources = [
  {
    name: "Getting Started",
    description: "How to start using Awesome Sign",
    Icon: GettingStartedICON,
    directTo: "https://help.awesomesuite.com/portal/en/kb/awesome-sign",
  },
  // {
  //   name: "FAQ",
  //   description: "Ask questions and discuss about Have a look at all the cool things we are working on",
  //   Icon: FaqICON
  // },
];

/**
 * Resource Center Container Used In HelpSideNavbar Component
 *
 * Mapping Resources
 *
 */

const ResourceCenterContainer = () => {
  return (
    <div className="resource-center-container">
      <Text varient="h5" marginBottom=".5rem" weight={700}>Resource Center</Text>
      {resources.map((resource, index) => (
        <ResourceCenterCard key={index} {...resource} />
      ))}
    </div>
  );
};

export default ResourceCenterContainer;

