import React, { useState, useEffect } from "react"
import DocumentContext from "../DocumentContext"
import TextLayer from "../Layers/TextLayer"
import CanvasLayer from "../Layers/CanvasLayer"
import AnnotationLayer from "../Layers/AnnotationLayer"

const PageCore = ({
    pdfjs,
    pdf,
    pdfViewer,
    pageNumber,
    enableAnotation,
    setPageSize,
    children,
    pdfHelpers,
    annotationStorages,
}) => {
    const [page, setpage] = useState(null)
    const [bonds, setBonds] = useState({ width: 1, height: 1 })
    let runningTask = null

    useEffect(() => {
        if (!pdf) throw new Error("Attempted to load page but no document found")

        loadPage()
    }, [])

    async function loadPage() {
        if (!pageNumber || !pdf) {
            return
        }

        runningTask = await pdf.getPage(pageNumber)
        setpage(runningTask)
    }

    async function hadelDimension(width, height) {
        setBonds((prev) => ({ ...prev, width, height }))
    }

    if (!page) return <div></div>

    return (
        <div
            className="pdf-page"
            style={{ width: bonds.width + "px", height: bonds.height + "px" }}
        >
            <CanvasLayer
                pdf={pdf}
                pageNumber={pageNumber}
                page={page}
                setBonds={hadelDimension}
                setPageSize={setPageSize}
            />
            {enableAnotation && (
                <TextLayer
                    pdfjs={pdfjs}
                    pdf={pdf}
                    page={page}
                    pageNumber={pageNumber}
                />
            )}
            {pdfViewer && pdfHelpers && (
                <AnnotationLayer
                    pdfjs={pdfjs}
                    page={page}
                    pageNumber={pageNumber}
                    pdfViewer={pdfViewer}
                    pdfHelpers={pdfHelpers}
                    annotationStorages={annotationStorages}
                />
            )}
            {children}
        </div>
    )
}

const Page = (props) => {
    return (
        <DocumentContext.Consumer>
            {(context) => {
                return <PageCore {...context} {...props} />
            }}
        </DocumentContext.Consumer>
    )
}

export default Page
