import { useCallback } from "react"

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

export const useBasicUtils = () => {
    /**
     * formate the text
     * @param {string} name text which needs to be formatted
     */
    const formatName = useCallback((name) => {
        if (!name) return name
        const newName = name
            .split("_")
            .map((item) => {
                return item[0] + item.slice(1).toLowerCase()
            })
            .join(" ")

        return newName
    }, [])

    const splitText = useCallback((text, range) => {
        if (!text) return text

        let length = 30
        if (range) length = range

        if (text.length >= length + 3) return text.substring(0, length) + "..."

        return text
    }, [])

    /**
     * get formatted Date
     * @param {*} value date in milliseconds
     */
    const getDate = (value) => {
        return new Date(value).toLocaleDateString("en-GB")
    }

    /**
     *  returns named date
     * @param {*} val
     */
    const namedDate = (val) => {
        const date = new Date(val)

        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
    }

    /**
     * The function takes a date value and returns a formatted string with the date and time in a
     * specific format.
     * @param value - The input value for the function, which is expected to be a valid date string or
     * a timestamp.
     * @returns The function `getDateAndTime` returns a formatted string representing the date and time
     * based on the input value. The string includes the month, day, year, hour, minutes, and time
     * period (AM or PM).
     */
    const getDateAndTime = (value) => {
        const date = new Date(value)
        let hour = date.getHours()
        let timePeriod = "AM"
        if (hour === 0) {
            hour = 12
            timePeriod = "AM"
        } else if (hour > 12) {
            hour = hour - 12
            timePeriod = "PM"
        }
        if (hour < 10) {
            hour = `0${hour}`
        }
        return `${
            months[date.getMonth()]
        } ${date.getDate()}, ${date.getFullYear()} ${hour}:${date.getMinutes()} ${timePeriod}`
    }
    return { formatName, getDate, splitText, namedDate, getDateAndTime }
}
