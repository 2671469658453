import React from "react"
import { createUseStyles } from "react-jss"
import Tooltip from "./Tooltip"

const useStyles = createUseStyles({
    tooltipWrapper: {
        position: "relative",
        "&:hover": {
            "& .invisible": {
                display: "block",
            },
        },
    },
})

const TooltipWrapper = ({
    children,
    text,
    title,
    direction,
    block,
    style,
    wrapperClassName = "",
    align = "center",
    offset,
    textStyle,
    ...props
}) => {
    const classes = useStyles()
    return (
        <div
            className={`${classes.tooltipWrapper} ${wrapperClassName}`}
            style={props.wrapperStyle}
            {...props}
        >
            {children}
            {!block && (
                <Tooltip
                    style={style}
                    text={text}
                    title={title}
                    direction={direction}
                    align={align}
                    offset={offset}
                    textStyle={textStyle}
                />
            )}
        </div>
    )
}

export default TooltipWrapper
