import {
    ROLE_FAILED,
    ROLE_LOADING,
    ROLE_SUCCESS,
    COMPANY_ROLE_SUCCESS,
    CLEAR_EVERYTHING,
    ROLE_AUTH_FAILED,
} from "../ActionTypes/roleTypes"

const initialState = {
    role: null,
    companyRoles: null,
    loading: false,
    isNewUser: true,
}

export const roleReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case CLEAR_EVERYTHING:
                return {
                    role: null,
                    companyRoles: null,
                    loading: false,
                }
            case ROLE_LOADING:
                return {
                    ...state,
                    loading: true,
                }
            case ROLE_SUCCESS:
                return {
                    ...state,
                    role: action.payload.role,
                    loading: false,
                    isNewUser: false,
                }
            case COMPANY_ROLE_SUCCESS:
                return {
                    ...state,
                    companyRoles: action.payload.roles,
                    loading: false,
                    isNewUser: false,
                }
            case ROLE_FAILED:
                return {
                    ...state,
                    loading: false,
                    role: [],
                    isNewUser: false,
                }
            case ROLE_AUTH_FAILED:
                return {
                    ...state,
                    loading: false,
                    role: [],
                    isNewUser: true,
                }
            default:
                return state
        }
    } catch (err) {}
}

