import { createUseStyles } from "react-jss"

export const useStyles = createUseStyles((theme) => ({
    templateFormWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: "2rem",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            height: 8,
            width: 8,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
            borderRadius: 20,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#b6b6b6",
            borderRadius: 20,
        },
    },
    templateFormInput: {
        width: "100%",
        padding: "1rem",
        border: "1px solid #CBD6E2",
        borderRadius: 12,
        marginBottom: "2rem",
    },
    pageNumberList: {
        display: "flex",
        alignItems: "center",
        marginTop: "1rem",
    },
    eachPageNumber: {
        padding: "0.25rem 0.5rem",
        border: "1px solid #CBD6E2",
        borderRadius: 12,
        marginRight: "0.5rem",
        cursor: "pointer",
        "&:last-child": {
            marginRight: 0,
        }
    },
    templateFormCheckboxToggle:{
        display: "flex",
        alignItems: "center",
        margin: "1rem 0",
    }
}))