import React from "react"
import { formatDateIntoWord } from "../../Library/DateAndTimeHandler/HandleDateAndTime"
import { Avatar, Text } from "@awesomesuite-frontend/awesome-nebula"
import StatusChip from "../StatusChip/StatusChip"
import "./creatorTitleBar.scss"
import { DOCUMENT } from "Types"

const CreatorBar = ({ creator, status, publishDate, type }) => {
    const _C = "aui-creator-bar"
    const { name, picture } = creator || {}
    return (
        <div className={_C}>
            <div className={_C + (creator ? "-image" : "-image-blank")}>
                <Avatar name={name} imageSource={picture} isTooltip={false} />
            </div>
            <div className={_C + "-creator"}>
                <Text fontSize={11}>Publisher</Text>
                {name && <Text fontSize={14} style={{fontWeight: 600}}>{creator ? name : ""}</Text>}
            </div>
            {type === DOCUMENT && <div className={_C + "-status"}>
                <Text fontSize={11}>Status</Text>
                <StatusChip status={status ? status : "DRAFT"} />
            </div>}
            <div className={_C + "-time"}>
                <Text fontSize={11}>Created At</Text>
                {publishDate && <Text fontSize={13}>{formatDateIntoWord(publishDate, true)}</Text>}
            </div>
        </div>
    )
}

export default CreatorBar
