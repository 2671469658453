import React from "react";
import "./RoundedSymbol.scss";

/**
 * This is a Simple Rounded Symbol Used in DeskPowerView, DeskTickets etc
 *
 * @param {Number} size Size of round
 * @param {String} color BackgroundColor
 * @param {String} borderColor
 *
 */

const RoundedSymbol = ({ size, color, borderColor, children }) => {
  return (
    <div
      className="rounded-symbol"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        minWidth: `${size}px`,
        minHeight: `${size}px`,
        backgroundColor: color,
        borderColor: borderColor ? borderColor : color,
      }}
    >
      {children}
    </div>
  );
};

export default RoundedSymbol;
