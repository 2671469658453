import {
    GET_SUBSCRIPTION_PLAN,
    HIDE_SUBSCRIPTION_POPUP,
    HIDE_UPGRADE_POPUP,
    LOAD_SUBSCRIPTION_DETAILS,
    LOAD_SUBSCRIPTION_DETAILS_FAILED,
    LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
    SET_REMAINING_DAYS,
    SHOW_SUBSCRIPTION_POPUP,
    SHOW_UPGRADE_POPUP,
} from "ActionTypes/subscriptionActions"

const initialState = {
    subscription: null,
    subscriptionPlans: null,
    subscriptionLoading: false,
    subscriptionPopUp: false,
    upgradePopUp: false,
    daysLeft: 0,
}

const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_SUBSCRIPTION_DETAILS:
            return {
                ...state,
                subscriptionLoading: true,
            }
        case LOAD_SUBSCRIPTION_DETAILS_FAILED:
            return {
                ...state,
                subscriptionLoading: false,
            }
        case LOAD_SUBSCRIPTION_DETAILS_SUCCESS:
            return {
                ...state,
                subscription: action.payload.subscription,
                subscriptionLoading: false,
            }

        case SHOW_SUBSCRIPTION_POPUP:
            return {
                ...state,
                subscriptionPopUp: true,
            }
        case HIDE_SUBSCRIPTION_POPUP:
            return {
                ...state,
                subscriptionPopUp: false,
            }
        case SHOW_UPGRADE_POPUP:
            return {
                ...state,
                upgradePopUp: true,
            }
        case HIDE_UPGRADE_POPUP:
            return {
                ...state,
                upgradePopUp: false,
            }
        case GET_SUBSCRIPTION_PLAN:
            return {
                ...state,
                subscriptionPlans: action.payload.plans,
            }
        case SET_REMAINING_DAYS:
            return {
                ...state,
                daysLeft: action.payload.daysLeft,
            }
        default:
            return state
    }
}

export default subscriptionReducer;
