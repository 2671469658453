import React, { useState, useRef, useEffect } from "react"
import { ReactComponent as CheckIcon } from "../../../../../Assets/ToolsIcons/check.svg"
import { Rnd } from "react-rnd"
import { dragClasses, dragStyle, dragStyleTransparent } from "../Common/Style"

import {
    dragSignatureElement,
    removeSignatureElement,
    resizeSignatureElement,
} from "../../../../../Action/signAction"
import Wrapper from "../Common/Wrapper"
import DragPreviewer from "../DragPreviewer"

/**
 * check box tick to stamp on the page canvas
 * @param {*} param0
 * @returns
 */
const CheckBox = ({
    getPagePosition,
    pageIndex,
    id,
    type,
    xDefault,
    yDefault,
    disable,
    ...other
}) => {
    const [toolModel, setToolModel] = useState(true)

    const [isDragging, setIsDragging] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
        onDragStop()
        onResize()
    }, [])

    function openModelHandler() {
        setToolModel(true)
    }

    function mouseLeaveHandler(e) {
        const comp = ref.current
        if (comp && comp.contains(e.target)) return
        else setToolModel(false)
    }

    function onRemoveHandler() {
        removeSignatureElement(pageIndex, id)
        other.onMouseLeave()
    }

    function onDragStop(event) {
        const element = ref.current.getBoundingClientRect()
        const canvas = getPagePosition()
        const pos = {
            x: Math.floor(element.left - canvas.left),
            y: Math.floor(canvas.bottom - element.top - element.height),
        }
        dragSignatureElement(
            pageIndex,
            id,
            pos.x,
            pos.y,
            element.top - canvas.top,
            element.left - canvas.left
        )
        setIsDragging(false)
    }

    function onResize() {
        const element = ref.current.getBoundingClientRect()
        const canvas = getPagePosition()
        const pos = {
            x: Math.floor(element.left - canvas.left),
            y: Math.floor(canvas.bottom - element.top - element.height),
        }
        resizeSignatureElement(
            pageIndex,
            id,
            Math.floor(element.width),
            Math.floor(element.height),
            pos.x,
            pos.y,
            element.left - canvas.left,
            element.top - canvas.top
        )
    }

    return (
        <Rnd
            style={{ zIndex: 12 }}
            bounds={"parent"}
            default={{
                x: xDefault,
                y: yDefault,
                width: "30px",
                height: "30px",
            }}
            minWidth={"18px"}
            minHeight={"18px"}
            lockAspectRatio
            resizeHandleStyles={toolModel ? dragStyle : dragStyleTransparent}
            resizeHandleClasses={toolModel ? dragClasses : {}}
            onDragStart={() => setIsDragging(true)}
            onDragStop={onDragStop}
            onResize={onResize}
            onMouseDown={openModelHandler}
            disableDragging={disable}
            enableResizing={!disable}
        >
            <div
                ref={(e) => (ref.current = e)}
                className=""
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
                {...other}
                onMouseLeave={() => {
                    document.addEventListener("click", mouseLeaveHandler)
                    other.onMouseLeave()
                }}
            >
                <DragPreviewer isDragging={isDragging} />
                {toolModel && <Wrapper onRemoveHandler={onRemoveHandler} />}
                <CheckIcon {...other} />
            </div>
        </Rnd>
    )
}

export default CheckBox
