import React from 'react';

/**
 * Root wrapper for the timeline component 
 * it wraps all the internal components of timeline.
 * 
 * it should be used on top of TimelineComponent,
 * TimeLineConnector and TimelineDetails
 * 
 * @example 
 * ```
 *  <TimeLineContainer>
        {timeline.map((item, index) => (
            <TimeLineComponent key={index}>
                <TimeLineConnector disableLine={index === 3} />
                <TimeLineDetails>
                    <PdfActivity {...item} />
                </TimeLineDetails>
            </TimeLineComponent>
        ))}
    </TimeLineContainer>
 * ``` 
 * 
 * @param {*} param.children sub component of timeline 
 */
const TimeLineContainer = ({children}) => {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            {children}
        </div>
    )
};

export default TimeLineContainer;