import { SUCCESS } from "Types"
import {
    CLOSE_MESSAGE,
    CREATE_MESSAGE,
    GET_NOTIFICATION,
    HIDE_WARNING_MESSAGE_POPUP,
    SHOW_WARNING_MESSAGE_POPUP,
    TOGGLE_APP_SWITCHER,
} from "../ActionTypes/messageAndNotificationTypes"
import { notificationApi, activityLogApi } from "../Apis/AllApisMapping"

import { store } from "../store"
import { getHeaders } from "./ActionHelper"
import { ReactComponent as Warning } from "Assets/ToolsIcons/warning.svg"

const { dispatch, getState } = store

/**
 * api to get all user related notifications
 */
export const getNotifications = async (action = "both", pageNo = 0) => {
    const headers = getHeaders()
    const workspace = getState().workspaceReducer?.workspace
    if (!workspace || workspace?.length < 1) return
    const workspaceId = workspace[0].id
    try {
        const notification = await notificationApi.get(
            `/notification?related_id=${workspaceId}&product_id=2&page=${pageNo}&action=${action}`,
            headers
        )
        if (notification.data.model) {
            dispatch({
                type: GET_NOTIFICATION,
                payload: { notification: notification.data.model },
            })
        }
    } catch (error) {
        console.log(error)
    }
}

/**
 * api to mark unseen notifications as seen
 */
export const markNotificationsAsSeen = async (notificationIds = []) => {
    const headers = getHeaders()
    if (notificationIds.length < 1) return
    try {
        const data = { notification_ids: notificationIds }
        const notification = await notificationApi.put(
            `/notification?action=seen`,
            data,
            headers
        )
        if (notification.data.model) {
            // dispatch({
            //     type: MARK_NOTIFICATION_AS_SEEN,
            //     payload: { notification: notification.data.model },
            // })
        }
    } catch (error) {
        console.log(error)
    }
}

/**
 *get the activites for the document with the documentId
 */
export const getActivityLog = async (documentId, page_no = 0) => {
    try {
        const headers = getHeaders()
        const { currentWorkspace } = getState().workspaceReducer
        headers.params = {
            product_id: 2,
            related_id: documentId,
            action: "document",
            page_no,
            workspace_id: currentWorkspace.id,
        }
        const logResult = await activityLogApi.get("/activity", headers)
        if (logResult.data.model) {
            let res = logResult.data.model.sort(
                (a, b) =>
                    new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
            )
            res = res.map(getMsg)
            return res
        }
        return []
    } catch (error) {
        console.log()
    }
    return []
}

/**
 *get the activites for the workspace with the workspaceId
 */
export const getActivityLogForWorkspace = async () => {
    try {
        const { currentWorkspace } = getState().workspaceReducer
        const headers = getHeaders()
        headers.params = {
            product_id: 2,
            workspace_id: currentWorkspace.id,
            action: "document",
        }
        const logResult = await activityLogApi.get("/activity", headers)
        if (logResult.data.model) {
            let res = logResult.data.model.sort(
                (a, b) =>
                    new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
            )
            res = res.map(getMsg)
            return res
        }
        return []
    } catch (error) {
        console.log()
    }
    return []
}

// try catch will be removed after activity api is done
function getMsg(msg) {
    const ISOtime = new Date(msg.createdOn).toISOString()
    let time = Math.floor(
        (new Date().getTime() - new Date(ISOtime).getTime()) / (3600 * 1000)
    )
    if (time > 24) time = `${Math.floor(time / 24)} days`
    else if (time !== 0) time = `${time} hours`
    else time = "Few minutes"

    Object.assign(msg, {
        message: {
            action: "document",
            event: msg.message.message,
            actorId: msg.message.userId,
            fileName: msg.message?.newState?.document?.fileName || "anonymous.pdf",
            time,
            signer: msg.message?.newState?.signer,
        },
    })

    return msg
}

let time = null
/**
 * app level notifications
 * @param {*} title
 * @param {*} subTitle
 * @param {*} category
 */

export const createMessage = (title, message, category = SUCCESS) => {
    dispatch({ type: CREATE_MESSAGE, payload: { title, message, category } })
    time = setTimeout(() => {
        closeMessage()
    }, 2000)
}

export const closeMessage = () => {
    clearTimeout(time)
    dispatch({ type: CLOSE_MESSAGE })
}

export const showWarningPopup = (
    title = "",
    description = "",
    agreeButtonTitle = "Delete",
    onAgree = () => {},
    cancelButtonTitle = "Cancel",
    onCancel = () => {},
    icon = <Warning />
) => {
    dispatch({
        type: SHOW_WARNING_MESSAGE_POPUP,
        payload: {
            title,
            description,
            agreeButtonTitle,
            onAgree,
            cancelButtonTitle,
            onCancel,
            icon,
        },
    })
}

export const hideWarningPopup = () => {
    dispatch({ type: HIDE_WARNING_MESSAGE_POPUP })
}

export const toggleAppSwitcher = () => {
    dispatch({ type: TOGGLE_APP_SWITCHER })
}