import { SIDEBAR_ITEM, TEXT_BOX } from "Pages/PdfEditor/container/Constants"
import DragItem from "Pages/PdfEditor/container/DragItem"
import React, { useState } from "react"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { createUseStyles } from "react-jss"
import CustomDragLayer from "Pages/PdfEditor/container/CustomDragLayer"
import { setActiveTool } from "Action/signAction"
import { SignatureDragType } from "../../../Types"

const useStyles = createUseStyles(() => ({
    addDrag: {
        width: "10px",
        height: "30px",
        border: "3px dotted #CBD6E2",
        borderTop: "none",
        borderBottom: "none",
        marginRight: "0.5rem",
    },
    dragWrapper: {
        display: "flex",
        alignItems: "center",
        padding: "12px",
        borderRadius: 4,
        border: "1px solid #d8e1ee",
        backgroundColor: "#f3f6fc",
        position: "relative",
        zIndex: 2,
    },
}))

const DragSign = (props) => {
    const classes = useStyles()
    const [elementOffset, setElementOfset] = useState(null)
    const dragSignRef = React.useRef(null)

    function addField() {
        const { firstName, emailId, id } = props.signer
        return setActiveTool({
            type: SignatureDragType.SIGNER_FIELD,
            top: 0,
            left: 0,
            data: {
                name: firstName,
                email: emailId,
                signerId: id,
            },
        })
    }

    return (
        <div style={{ flex: 1 }}>
            <DragItem
                data={{
                    id: 1,
                    type: SIDEBAR_ITEM,
                    component: {
                        type: TEXT_BOX,
                        content: null,
                    },
                    elementOffset,
                    setToolSignerField: addField,
                    setElementOfset,
                    height: dragSignRef.current?.getBoundingClientRect().height,
                    width: dragSignRef.current?.getBoundingClientRect().width,
                }}
                style={{ width: "100%" }}
            >
                <div
                    className={classes.dragWrapper}
                    ref={dragSignRef}
                    onMouseDown={(e) => {
                        const rect = dragSignRef.current.getBoundingClientRect()
                        const x = e.clientX - rect.left
                        const y = e.clientY - rect.top
                        setElementOfset({ x, y })
                    }}
                >
                    <div className={classes.addDrag}></div>
                    <Text color="#425B76" fontSize={14} style={{ fontWeight: 600 }}>
                        Signature Fields
                    </Text>
                </div>
            </DragItem>
            <CustomDragLayer />
        </div>
    )
}

export default DragSign
