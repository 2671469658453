import React from "react"
import { ReactComponent as SendForESign } from "Assets/PDFCommons/send-for-sign.svg"
import { ReactComponent as Viewed } from "Assets/PDFCommons/viewed.svg"
import { ReactComponent as Queued } from "Assets/PDFCommons/queued.svg"
import { createUseStyles } from "react-jss"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

const useStyles = createUseStyles(() => ({
    signerStatusDot: {
        height: 10,
        width: 10,
        borderRadius: "50%",
    },
    signerStatusChip: {
        margin: "0.5rem 0",
        display: "flex",
        borderRadius: 6,
        justifyContent: "center",
        alignItems: "center",
        padding: "0.25rem 0.75rem",
    },
}))

const StatusDot = ({ color }) => {
    const classes = useStyles()
    return (
        <div
            className={`${classes.signerStatusDot}`}
            style={{ backgroundColor: color }}
        />
    )
}

export const signerStatus = {
    SENT: {
        name: "Sent",
        icon: <SendForESign fill="#7E89FF" />,
    },
    VIEWED: {
        name: "Viewed",
        icon: <Viewed />,
    },
    ACCEPTED: {
        name: "Pending",
        color: "#DBAE60",
        backgroundColor: "#FEF8F0",
        borderColor: "#FAE0B5",
        icon: <StatusDot color="#DBAE60" />,
    },
    PENDING: {
        name: "Queued",
        icon: <Queued />,
    },
    SIGNED: {
        name: "Signed",
        color: "#00BDA5",
        backgroundColor: "#E5F8F6",
        borderColor: "#7FDED2",
        icon: <StatusDot color="#00BDA5" />,
    },
    REJECTED: {
        name: "Rejected",
        color: "#F2545B",
        backgroundColor: "#FDEDEE",
        borderColor: "#F8A9AD",
        icon: <StatusDot color="#F2545B" />,
    },
}

const SignerStatusChip = ({ status }) => {
    const classes = useStyles()
    return (
        <div
            className={`${classes.signerStatusChip}`}
            style={{
                backgroundColor: signerStatus[status]?.backgroundColor || "#fff",
                border: `1px solid ${
                    signerStatus[status]?.borderColor || "#CCDFF2"
                }`,
            }}
        >
            {signerStatus[status]?.icon && signerStatus[status]?.icon}
            <Text
                fontSize={14}
                color={signerStatus[status]?.color || "#33475B"}
                marginLeft="0.5rem"
            >
                {status}
            </Text>
        </div>
    )
}

export default SignerStatusChip
