import React from "react"
import { Route, Switch, useHistory, withRouter, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { initialAccessTokenLoad } from "Action/userAction"

import PrivateRouteWrapper from "./components/PrivateRouteWrapper"

import SignUp from "Pages/signUp"
import SignInPage from "Pages/login"
import Settings from "Pages/Setting"
import SettingPages from "Pages/SettingPages"
import ESignaturePage from "Pages/PdfEditor/ESignaturePage"
import Storage from "Pages/Storage"
import Error from "Pages/Error"
import OnBoarding from "Pages/OnBoarding"
import Addons from "Pages/addons"

import PublicESignature from "public/PdfEditor/PublicESignature"
import InBox from "Pages/InBox"
import SignSuccess from "public/SignSuccess/SignSuccess"
import { createUseStyles } from "react-jss"
import Templates from "Pages/TemplatesPage"
import GuardContext from "Awesome-ui/GuardContext"
import Dashboard from "Pages/Dashboard/"
import GetStarted from "Pages/GetStarted/GetStarted"
import GlobalComponent from "./components/GlobalComponent"
import TrustCenter from "Pages/TrustCenter/TrustCenter"
import { setWorkspaceLoaded } from "Action/workspaceAction"
import Media from "Pages/Media"

const useStyles = createUseStyles((theme) => ({
    alertWrapper: {
        position: "relative",
        overflowX: "hidden",
        backgroundColor: "var(--main-content)",
    },
}))

const PrivateRoute = ({ component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => (
                <PrivateRouteWrapper>
                    {React.createElement(component, props)}
                </PrivateRouteWrapper>
            )}
        />
    )
}

const PublicRoute = ({ component, ...rest }) => {
    return (
        <Route {...rest} render={(props) => React.createElement(component, props)} />
    )
}

const DocumentNotFound = () => {
    return <Error errorTitle="Document Not Found" />
}

let PublicRoutes = () => {
    return (
        <Switch>
            <PublicRoute path="/login" exact component={SignInPage} />
            <PublicRoute path="/signup" exact component={SignUp} />
            <PublicRoute
                path="/sign/:documentId"
                exact
                component={PublicESignature}
            />
            <PublicRoute
                path="/sign/signature/success"
                exact
                component={SignSuccess}
            />
            <PublicRoute
                path="/sign/404/document-not-found"
                exact
                component={DocumentNotFound}
            />
            <PublicRoute path="/addons" exact component={Addons} />
            <Route path="*" component={Error} />
        </Switch>
    )
}

let AllRoutes = () => {
    return (
        <Switch>
            <PublicRoute path="/login" exact component={SignInPage} />
            <PublicRoute path="/signup" exact component={SignUp} />
            <PublicRoute
                path="/sign/:documentId"
                exact
                component={PublicESignature}
            />
            <PublicRoute
                path="/sign/signature/success"
                exact
                component={SignSuccess}
            />
            <PublicRoute
                path="/sign/404/document-not-found"
                exact
                component={DocumentNotFound}
            />
            <PrivateRoute path="/settings" exact component={Settings} />
            <PrivateRoute
                path="/settings/:settingView"
                exact
                component={SettingPages}
            />
            <Route path="/pdf-editor/:documentId" component={ESignaturePage} />
            <PrivateRoute path="/inbox" component={InBox} />
            <PrivateRoute path="/documents" component={Storage} />
            <PrivateRoute path="/onboarding" component={OnBoarding} />
            <PrivateRoute path="/templates" component={Templates} />
            <PrivateRoute path="/get-started" component={GetStarted} />
            <PrivateRoute path="/trust-center" component={TrustCenter} />
            <PublicRoute path="/addons" exact component={Addons} />
            <PublicRoute path="/media" exact component={Media} />
            <PrivateRoute path="/" exact component={Dashboard} />
            <Route path="*" component={Error} />
        </Switch>
    )
}

let loading = true
const Layout = ({ token, permissions, roleId }) => {
    let history = useHistory()
    const classes = useStyles()

    async function load() {
        if (!loading) return
        const [subscription, workspace] = await initialAccessTokenLoad()
        const isSubscriptionPresent =
            Array.isArray(subscription) && subscription?.length > 0
        const isWorkspacePresent = Array.isArray(workspace) && workspace?.length > 0
        loading = false
        if (!isSubscriptionPresent) return history.push("/get-started")
        if (!isWorkspacePresent) return history.push("/onboarding")
    }

    React.useEffect(() => {
        let isFound = false
        if (window.location.pathname.includes("login")) isFound = true
        if (window.location.pathname.includes("sign/") && !token) isFound = true
        if (window.location.pathname.includes("addons")) isFound = true
        if (window.location.pathname.includes("media")) isFound = true

        if (!isFound) load()
        else setWorkspaceLoaded()
    }, [])

    return (
        <GuardContext.Provider value={{ role: roleId, permissions }}>
            <div className={classes.alertWrapper}>
                {token ? <AllRoutes /> : <PublicRoutes />}
                <GlobalComponent />
            </div>
        </GuardContext.Provider>
    )
}

const mapStateToProps = ({ userReducer, workspaceReducer, permissionsReducer }) => ({
    token: userReducer.token,
    permissions: permissionsReducer?.permissions,
    roleId: workspaceReducer.currentWorkspace?.roleId,
})

export default connect(mapStateToProps)(withRouter(Layout))
