import React from "react"

const Cloud = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="328.768"
            height="264.778"
            viewBox="0 0 328.768 264.778"
        >
            <g id="cloud-no-notification" transform="translate(0.001 -4.671)">
                <g id="_Group__544_" transform="translate(-0.001 4.671)">
                    <ellipse
                        id="_Ellipse__623_"
                        cx="94.807"
                        cy="10.549"
                        rx="94.807"
                        ry="10.549"
                        transform="translate(69.548 243.68)"
                        fill="#eaf0f6"
                    />
                    <g id="_Group_2_353_">
                        <g id="_Group_3_279_">
                            <g id="_Group_4_228_">
                                <path
                                    id="_Compound_Path__108_"
                                    d="M322.413,115.818A32.127,32.127,0,0,0,296.93,84.376a31.926,31.926,0,0,0-27-36.183,31.079,31.079,0,0,0-4.788-.329,32.361,32.361,0,0,0-18.085,5.549,32.129,32.129,0,0,0-54.8-24.8,31.991,31.991,0,0,0-61.652,0,32.125,32.125,0,0,0-54.8,24.8,32.361,32.361,0,0,0-18.085-5.549A31.984,31.984,0,0,0,25.934,84.376a32.127,32.127,0,0,0,1.713,63.159A32.059,32.059,0,0,0,75.6,185.348a31.992,31.992,0,0,0,56.515,22.674,31.992,31.992,0,0,0,58.227,0,31.99,31.99,0,0,0,56.515-22.674,32.058,32.058,0,0,0,47.952-37.813A32.194,32.194,0,0,0,322.413,115.818Z"
                                    transform="translate(2.923 -1.745)"
                                    fill="#fff"
                                />
                                <path
                                    id="_Path__644_"
                                    d="M316.91,18.773a23.162,23.162,0,0,0-10.755,18.633A34.252,34.252,0,0,1,271.9,70.081a34.85,34.85,0,0,1-6.028-.48,13.7,13.7,0,0,0-15.619,8.768A34.259,34.259,0,0,1,218.2,100.634a33.711,33.711,0,0,1-16.783-4.453,13.7,13.7,0,0,0-16.441,2.192,34.248,34.248,0,0,1-47.952,0,13.7,13.7,0,0,0-16.441-2.192,33.711,33.711,0,0,1-16.783,4.453A34.246,34.246,0,0,1,72.15,78.37,13.7,13.7,0,0,0,56.874,69.6a34.85,34.85,0,0,1-6.028.48A34.252,34.252,0,0,1,16.595,37.406,23.162,23.162,0,0,0,5.84,18.773,31.919,31.919,0,0,0,27.761,67.958a32.059,32.059,0,0,0,47.952,37.813,31.992,31.992,0,0,0,56.515,22.674,31.992,31.992,0,0,0,58.227,0,31.99,31.99,0,0,0,56.515-22.674A32.058,32.058,0,0,0,294.92,67.958a31.927,31.927,0,0,0,21.921-49.185Z"
                                    transform="translate(3.152 77.832)"
                                    fill="#dfe3eb"
                                />
                                <path
                                    id="Path_2351"
                                    data-name="Path 2351"
                                    d="M164.163,233.122a35.511,35.511,0,0,1-29.9-16.42A35.411,35.411,0,0,1,75.2,194.035a35.668,35.668,0,0,1-27.038.9,35.5,35.5,0,0,1-21.976-41.917,35.548,35.548,0,0,1-1.021-68.29c-.041-.692-.055-1.384-.055-2.069A35.457,35.457,0,0,1,60.526,47.371a34.594,34.594,0,0,1,14.892,3.206,35.557,35.557,0,0,1,35.361-31.895h.041A35.245,35.245,0,0,1,131.994,25.7a35.623,35.623,0,0,1,22.88-19.729A35.448,35.448,0,0,1,196.715,25.7a35.567,35.567,0,0,1,56.576,24.873,36.753,36.753,0,0,1,14.982-3.206,35.947,35.947,0,0,1,5.062.363,35.366,35.366,0,0,1,30.216,37,35.76,35.76,0,0,1,25.216,34.025v.014a35.881,35.881,0,0,1-26.647,34.3,35.493,35.493,0,0,1-49.014,40.985,35.421,35.421,0,0,1-38.492,31.717,35.1,35.1,0,0,1-20.578-9.063,35.463,35.463,0,0,1-29.874,16.413Zm-29.127-25.6a2.513,2.513,0,0,1,.452.034,3.421,3.421,0,0,1,2.665,1.973,28.561,28.561,0,0,0,51.987.007,3.4,3.4,0,0,1,2.665-1.973,3.3,3.3,0,0,1,3.089,1.206,28.563,28.563,0,0,0,50.459-20.242,3.433,3.433,0,0,1,1.706-3.206,3.369,3.369,0,0,1,3.624.13,28.637,28.637,0,0,0,42.828-33.785,3.433,3.433,0,0,1,2.72-4.59,28.653,28.653,0,0,0,1.9-56.419,3.429,3.429,0,0,1-2.685-3.843,28.526,28.526,0,0,0-24.106-32.3,25.152,25.152,0,0,0-4.268-.295,28.816,28.816,0,0,0-16.18,4.966,3.427,3.427,0,0,1-5.336-3.062,28.7,28.7,0,0,0-48.952-22.154,3.433,3.433,0,0,1-3.295.891,3.393,3.393,0,0,1-2.425-2.4,28.567,28.567,0,0,0-35.169-19.886,28.7,28.7,0,0,0-19.886,19.886,3.429,3.429,0,0,1-5.727,1.507,28.548,28.548,0,0,0-20.284-8.433h-.034A28.709,28.709,0,0,0,82.145,56.125a3.427,3.427,0,0,1-5.336,3.062,28.913,28.913,0,0,0-16.173-4.966h-.1A28.6,28.6,0,0,0,31.96,82.684a27.879,27.879,0,0,0,.288,4.138,3.423,3.423,0,0,1-2.678,3.836A28.7,28.7,0,0,0,31.1,147.083a3.43,3.43,0,0,1,2.678,4.583A28.636,28.636,0,0,0,76.6,185.444a3.427,3.427,0,0,1,5.329,3.083A28.568,28.568,0,0,0,132.4,208.77,3.42,3.42,0,0,1,135.036,207.523Zm190.307-88.779h0Z"
                                    transform="translate(0.001 -4.671)"
                                    fill="#99acc2"
                                />
                            </g>
                        </g>
                        <g id="_Group_5_178_" transform="translate(107.498 104.191)">
                            <path
                                id="_Path_2_636_"
                                d="M15.692,28.259c0,3.083,3.836,5.686,8.494,5.686s8.563-2.6,8.563-5.686-3.836-5.686-8.563-5.686S15.692,25.108,15.692,28.259Z"
                                transform="translate(-15.692 -4.129)"
                                fill="#ffb0ca"
                            />
                            <g
                                id="_Group_6_143_"
                                transform="translate(26.081 34.892)"
                            >
                                <g id="Group_834" data-name="Group 834">
                                    <path
                                        id="_Path_3_634_"
                                        d="M69.369,25.473a5.689,5.689,0,0,1,5.672,5.7,5.479,5.479,0,0,1-.123,1.151,28.429,28.429,0,0,1-54.8,0,5.68,5.68,0,0,1,4.4-6.727,5.861,5.861,0,0,1,1.151-.123Z"
                                        transform="translate(-16.604 -22.055)"
                                        fill="#fec8da"
                                    />
                                    <path
                                        id="Path_2352"
                                        data-name="Path 2352"
                                        d="M50.46,59.54A31.924,31.924,0,0,1,19.709,36.16a9.159,9.159,0,0,1,7-10.995,8.738,8.738,0,0,1,1.85-.192H72.271a9.11,9.11,0,0,1,9.083,9.138,9.353,9.353,0,0,1-.192,1.843A31.864,31.864,0,0,1,50.46,59.54ZM28.56,31.817c-1.665.3-2.452,1.507-2.2,2.72A25.058,25.058,0,0,0,43.754,51.765,25.006,25.006,0,0,0,74.511,34.331a2.412,2.412,0,0,0-.658-1.85,2.224,2.224,0,0,0-1.589-.671H28.56Z"
                                        transform="translate(-19.499 -24.974)"
                                        fill="#99acc2"
                                    />
                                </g>
                                <g
                                    id="Group_835"
                                    data-name="Group 835"
                                    transform="translate(4.947 11.385)"
                                >
                                    <path
                                        id="_Path_4_629_"
                                        d="M43.122,44.184a28.284,28.284,0,0,0,22.4-11.029,44.677,44.677,0,0,0-44.8,0A28.283,28.283,0,0,0,43.122,44.184Z"
                                        transform="translate(-17.294 -23.723)"
                                        fill="#ffb0ca"
                                    />
                                    <path
                                        id="Path_2353"
                                        data-name="Path 2353"
                                        d="M46.05,50.523A31.9,31.9,0,0,1,20.937,38.158a3.416,3.416,0,0,1,.993-5.049,48.171,48.171,0,0,1,48.239,0,3.429,3.429,0,0,1,.993,5.049A31.892,31.892,0,0,1,46.05,50.523ZM29.2,37.076c9.118,8.371,24.606,8.357,33.7,0A41.317,41.317,0,0,0,29.2,37.076Z"
                                        transform="translate(-20.222 -26.636)"
                                        fill="#99acc2"
                                    />
                                </g>
                            </g>
                            <path
                                id="Path_2354"
                                data-name="Path 2354"
                                d="M36.53,36.064a2.422,2.422,0,0,1-.315-.014,3.422,3.422,0,0,1-3.1-3.727,4.983,4.983,0,0,0,0-.9,5.134,5.134,0,1,0-10.227.911,3.425,3.425,0,0,1-6.823.617A11.988,11.988,0,1,1,39.948,30.8a12.529,12.529,0,0,1,0,2.151A3.45,3.45,0,0,1,36.53,36.064Z"
                                transform="translate(-13.78 -19.881)"
                                fill="#99acc2"
                            />
                            <path
                                id="_Path_6_577_"
                                d="M46.859,28.259c0,3.083-3.836,5.686-8.494,5.686s-8.563-2.6-8.563-5.686,3.836-5.686,8.563-5.686S46.859,25.108,46.859,28.259Z"
                                transform="translate(66.855 -4.129)"
                                fill="#ffb0ca"
                            />
                            <path
                                id="Path_2355"
                                data-name="Path 2355"
                                d="M31.922,36.058a3.427,3.427,0,0,1-3.4-3.11,11.353,11.353,0,0,1,0-2.158A11.988,11.988,0,0,1,52.4,32.941a3.475,3.475,0,0,1-3.72,3.11,3.424,3.424,0,0,1-3.1-3.713,5.141,5.141,0,0,0-10.241-.925,5.058,5.058,0,0,0,0,.911,3.427,3.427,0,0,1-3.1,3.727C32.135,36.058,32.032,36.058,31.922,36.058Z"
                                transform="translate(59.042 -19.875)"
                                fill="#99acc2"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Cloud
