import React from "react"

import { ReactComponent as SignIcon } from "Assets/ProductIcon/signicon.svg"
import { ReactComponent as SocialIcon } from "Assets/ProductIcon/social/social-icon-1.svg";
import {ReactComponent as DialIcon } from "Assets/ProductIcon/dial-icon.svg"

/**
 * Make a entry here for all our products
 */
export const productMap = [
    {
      name: "Social",
      defaultWebsiteTitle: "Awesome Social",
      description: "Social Media Management Software",
      tag: "new",
      Icon: <SocialIcon />,
      
    },
    {
      name: "Dial",
      defaultWebsiteTitle: "Awesome Dial",
      description: "Business Phone System & SMS",
      tag: "Launching Soon",
      Icon: <DialIcon />,
    },
    {
        name: "Send",
        defaultWebsiteTitle: "Awesome Send",
        description: "Email Marketing Software",
        tag: "Launching Soon",
        Icon:"💌",
    },
    //{
    //   name: "Desk",
    //   defaultWebsiteTitle: "Awesome Desk",
    //   description: "Customer Helpdesk",
    //   tag:"Launching Soon",
    //   //Icon: <SendIcon />,
    // },
];
