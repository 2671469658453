import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { SignatureDragType } from "Components/PDFCommons/container/Types"
import { ReactComponent as SignIcon } from "Assets/ToolsIcons/sign.svg"
import { ReactComponent as DateIcon } from "Assets/ToolsIcons/dateIcon.svg"
import { ReactComponent as TextIcon } from "Assets/ToolsIcons/textIcon.svg"
import { ReactComponent as AvatarNameIcon } from "Assets/ToolsIcons/avatarNameIcon.svg"
import { ReactComponent as EmailIcon } from "Assets/ToolsIcons/emailIcon.svg"
import { ReactComponent as CompanyIcon } from "Assets/ToolsIcons/companyIcon.svg"
import { ReactComponent as JobTitleIcon } from "Assets/ToolsIcons/jobTitleIcon.svg"
import { ReactComponent as CheckboxIcon } from "Assets/ToolsIcons/checkboxIcon.svg"
import { ReactComponent as NumericInputIcon } from "Assets/ToolsIcons/numeric-input.svg"

export const primaryElements = [
    {
        type: SignatureDragType.SIGNER_FIELD,
        subType: "Signature",
        Icon: SignIcon,
    },
    {
        type: SignatureDragType.DATE,
        subType: "Date",
        Icon: DateIcon,
    },
    {
        type: SignatureDragType.TEXT,
        subType: "Name",
        Icon: AvatarNameIcon,
    },
    {
        type: SignatureDragType.TEXT,
        subType: "Text",
        Icon: TextIcon,
    },
    {
        type: SignatureDragType.TEXT,
        subType: "Email",
        Icon: EmailIcon,
    },
    {
        type: SignatureDragType.CHECKBOX,
        subType: "Checkbox",
        Icon: CheckboxIcon,
    },
    {
        type: SignatureDragType.TEXT,
        subType: "Company",
        Icon: CompanyIcon,
    },
    {
        type: SignatureDragType.TEXT,
        subType: "Job Title",
        Icon: JobTitleIcon,
    },
]

export const variableOptions = [
    {
        name: <Text varient="subText">Text</Text>,
        value: SignatureDragType.TEXT,
    },
    {
        name: <Text varient="subText">Date</Text>,
        value: SignatureDragType.DATE,
    },
    {
        name: <Text varient="subText">Number</Text>,
        value: SignatureDragType.NUMBER,
    },
]

export const getElementIconByType = (type) => {
    switch (type) {
        case SignatureDragType.SIGNER_FIELD:
            return SignIcon
        case SignatureDragType.DATE:
            return DateIcon
        case SignatureDragType.TEXT:
            return TextIcon
        case SignatureDragType.CHECKBOX:
            return CheckboxIcon
        case SignatureDragType.NUMBER:
            return NumericInputIcon
        default:
            return TextIcon
    }
}
