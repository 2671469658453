import { Grid, Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as UploadDoc } from "../../Assets/Dashboard/upload-doc.svg"
import { ReactComponent as CreateDoc } from "../../Assets/Dashboard/create-doc.svg"
import { medialApi } from "Apis/AllApisMapping"
import { addDocument, addDocumentToS3 } from "Action/documentAction"
import { connect } from "react-redux"
import { initializeDocument } from "Action/signAction"
import { useHistory } from "react-router-dom"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import usePdfHook from "hooks/PdfLib/PdfHook"
import { createMessage } from "Action/messageAndNotificationActions"
import { DANGER } from "Types"

const useStyles = createUseStyles(() => ({
    addDocumentContainer: {
        position: "relative",
    },
    addDocument: {
        height: "30px",
        aspectRatio: 1,
        marginRight: "1rem",
        backgroundColor: "#2CD5F8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "rotate(45deg)",
        borderRadius: "50%",
        color: "white",
        paddingBottom: "2px",
        userSelect: "none",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#1cc1e3",
            color: "#FFFFFF",
        },
    },
    addDocumentModal: {
        width: "300px",
        padding: "1rem 0",
        position: "absolute",
        bottom: "-12px",
        right: 0,
        transform: "translateY(100%)",
        backgroundColor: "#fff",
        border: "1px solid var(--border-color)",
        borderRadius: "5px",
        display: "none",
    },
    addDocumentModalActive: {
        display: "block",
    },

    dropdownArrow: {
        top: 0,
        position: "absolute",
        right: "1rem",
        borderLeft: "12px solid transparent",
        borderRight: "12px solid transparent",
        borderBottom: "11px solid var(--border-color)",
        transform: "translateY(-100%)",
    },
    dropdownArrowCover: {
        top: "1px",
        position: "absolute",
        right: "calc(1rem + 1px)",
        borderLeft: "11px solid transparent",
        borderRight: "11px solid transparent",
        borderBottom: "11px solid white",
        transform: "translateY(-100%)",
    },
    gridContainerEach: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#EAF0F6",
        },
    },
}))

const AddFolder = ({ userToken }) => {
    const classes = useStyles()
    const [isActive, setIsactive] = React.useState(false)
    const createDocModal = React.useRef(null)
    const inputfile = React.useRef(null)
    const mediaController = new mediaHandler(medialApi)
    const history = useHistory()
    const { loadedLib, loadPdfLib, loadPdf } = usePdfHook()

    const outsideClick = (e) => {
        if (createDocModal.current?.contains(e.target)) return
        setIsactive(false)
        window.removeEventListener("click", outsideClick)
    }
    async function getNumberOfPages(pdfFile) {
        if (!loadedLib) {
            await loadPdfLib()
        }
        const res = await loadPdf(pdfFile)
        return res.numberOfPages
    }
    async function startDocumentSigning(fileDetails) {
        try {
            const response = await addDocument(
                fileDetails.name,
                fileDetails.mediaId,
                fileDetails.mediaPath,
                fileDetails.pages
            )
            if (response) {
                history.push(`/pdf-editor/${response.model.fileId}`)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function validateFile(file) {
        if (file.type !== "application/pdf")
            return createMessage(
                "Document type not supported. Please upload a pdf document.",
                null,
                DANGER
            )
        if (file.size > 100 * 1024 * 1024)
            return createMessage(
                "Document size is too large.",
                "Please upload a document less than 100mb.",
                DANGER
            )
        return true
    }

    async function handleFiles(listFiles) {
        //Only single file upload supported for now
        const singleFile = listFiles[0]
        inputfile.current.value= null

        if (!validateFile(singleFile)) return

        const mediaResp = await addDocumentToS3(
            singleFile,
            singleFile.name,
            singleFile.size
        )
        const pages = await getNumberOfPages(mediaResp.mediaPath)
        startDocumentSigning({
            ...mediaResp,
            name: singleFile.name,
            file: singleFile,
            preView: mediaResp.mediaPath,
            size: singleFile.size,
            pages,
        })
    }
    React.useEffect(() => {
        if (isActive) window.addEventListener("click", outsideClick)
    }, [isActive])
    return (
        <div className={classes.addDocumentContainer}>
            <div
                className={classes.addDocument}
                onClick={() => setIsactive(!isActive)}
            >
                ✖
            </div>
            <div
                className={`${classes.addDocumentModal} ${
                    isActive && classes.addDocumentModalActive
                }`}
                ref={createDocModal}
            >
                <div className={classes.dropdownArrow}></div>
                <div className={classes.dropdownArrowCover}></div>
                <Grid
                    container
                    spacing={10}
                    className={classes.gridContainerEach}
                    style={{ borderBottom: "1px solid var(--border-color)" }}
                >
                    <Grid item xs={3} align="center" justify={"center"}>
                        <CreateDoc />
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        align="center"
                        justify={"center"}
                        style={{ marginTop: "1rem" }}
                    >
                        <Text>Create Document</Text>
                        <Text varient="subText" color="light">
                            Create Document
                        </Text>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={10}
                    className={classes.gridContainerEach}
                    onClick={() => inputfile.current.click()}
                >
                    <Grid item xs={3} align="center" justify={"center"}>
                        <UploadDoc />
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        align="center"
                        justify={"center"}
                        style={{ marginTop: "1rem" }}
                    >
                        <Text>Upload Document</Text>
                        <Text varient="subText" color="light">
                            Upload Document
                        </Text>
                        <input
                            type="file"
                            onChange={(e) => handleFiles(e.target.files)}
                            hidden
                            multiple={false}
                            ref={inputfile}
                            accept="application/pdf"
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = ({ userReducer }) => ({
    userToken: userReducer.token,
})

export default connect(mapStateToProps)(AddFolder)
