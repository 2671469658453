import React, { useContext } from "react"
import { ReactComponent as PlugIn } from "./Assets/plugin.svg"
import { ReactComponent as Cross } from "./Assets/cross.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { ReactContext } from "./MediaLibrary"
import { useStyles } from "./MediaLibraryStyle"

const MediaLibraryTopBar = ({ onCancel, ...props }) => {
    const { styles } = useContext(ReactContext)
    const classes = useStyles(styles)
    return (
        <div className={`${classes.topBar}`}>
            <div className={`${classes.topBarLeft}`}>
                <PlugIn height={45} width={45} />
                <Text varient="h5" marginLeft="1rem">
                    Media Library
                </Text>
            </div>
            <div className={`${classes.topBarRight}`} onClick={onCancel}>
                <Cross height={25} width={25} />
            </div>
        </div>
    )
}

export default MediaLibraryTopBar
