import { Text } from "@awesomesuite-frontend/awesome-nebula"
import GetUserRole from "Awesome-ui/GetUserRole"
import ThreeDotOptionDropdown from "Awesome-ui/ThreeDotsDropDown"
import React, { useState } from "react"
import { ReactComponent as Settings } from "../../../../Assets/NavigationIcon/settings.svg"
import { ReactComponent as Bin } from "../../../../Assets/settings/bin.svg"
import { ReactComponent as Edit } from "../../../../Assets/settings/editpen.svg"
import EditTeamMember from "Components/EditTeamMember/EditTeamMember"

const UserFolderList = ({
    userFolder,
    getIcon,
    roles,
    user,
    deleteFolderHandler = () => {},
    dropDownTwoContent = [],
    disabled
}) => {
    const { folder, roleId, roleName } = userFolder
    const [showEditModal, setShowEditModal] = useState(null)
    const icon = getIcon(folder)

    if (folder?.name?.toLowerCase() === "my documents") return null

    return (
        <div className="react-table-row">
            {showEditModal && (
                <EditTeamMember
                    setShowModal={setShowEditModal}
                    editingMember={showEditModal}
                    currentFolderId={folder.id}
                    dropDownTwoContent={dropDownTwoContent}
                />
            )}
            <div
                className="react-table-cell"
                style={{
                    width: "10%",
                }}
            ></div>
            <div
                className="react-table-cell"
                style={{
                    width: "44.7%",
                }}
            >
                <div className="folder-details">
                    <div className="folder-details-icon">{icon}</div>
                    <Text
                        varient="subText"
                        marginLeft="1rem"
                        style={{ fontWeight: 600 }}
                    >
                        {folder.name}
                    </Text>
                </div>
            </div>
            <div
                className="react-table-cell"
                style={{
                    width: "30%",
                    color: "#03A9F4",
                }}
                align="center"
            >
                <GetUserRole user={{ role: roleName }} roles={roles} />
            </div>
            {/* <div
                className="react-table-cell"
                style={{
                    width: "15%",
                }}
                align="center"
            > */}
            {/* TODO: Get activity log */}
            {/* <Text>Today</Text>
            </div> */}
            <div
                className="react-table-cell"
                style={{
                    width: "10%",
                }}
                align="center"
            >
                <ThreeDotOptionDropdown
                    options={[
                        {
                            name: "Edit",
                            onClick: (e) => {
                                setShowEditModal({
                                    email: user.email_id,
                                    id: user.id,
                                    role: user.role,
                                })
                            },
                            icon: <Edit />,
                        },
                        {
                            name: "Delete",
                            onClick: (e) => {
                                deleteFolderHandler(folder.id)
                            },
                            icon: <Bin />,
                            style: {
                                color: "#F2545B",
                            },
                        },
                    ]}
                    enableIcon
                    idName="addIcons"
                    disabled={disabled}
                />
            </div>
            <div
                className="react-table-cell"
                style={{
                    // width: "10%",
                    cursor: "pointer",
                }}
                align="center"
                onClick={() => {
                    // link to folder direction
                }}
            >
                {/* * <Settings height={"1.5rem"} width={"1.5rem"} />  */}
            </div>
        </div>
    )
}

export default UserFolderList
