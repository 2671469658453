import contractImage1 from "../Assets/TemplatesPage/Contracts/accounting-contract/images/1.jpg"
import contractImage2 from "../Assets/TemplatesPage/Contracts/agency-agreement/images/1.jpg"
import contractImage3 from "../Assets/TemplatesPage/Contracts/basic-rent-agreement/images/1.jpg"
import contractImage4 from "../Assets/TemplatesPage/Contracts/bookkeeping-contract/images/1.jpg"
import contractImage5 from "../Assets/TemplatesPage/Contracts/caterer-agreement/images/1.jpg"
import contractImage6 from "../Assets/TemplatesPage/Contracts/coaching-agreement/images/1.jpg"
import contractImage7 from "../Assets/TemplatesPage/Contracts/commission-agreement/images/1.jpg"
import contractImage8 from "../Assets/TemplatesPage/Contracts/confidentiality-agreement/images/1.jpg"
import contractImage9 from "../Assets/TemplatesPage/Contracts/construction-contract-agreement/images/1.jpg"
import contractImage10 from "../Assets/TemplatesPage/Contracts/contractor-agreement/images/1.jpg"
import contractImage11 from "../Assets/TemplatesPage/Contracts/dj-contract/images/1.jpg"
import contractImage12 from "../Assets/TemplatesPage/Contracts/employment-agreement/images/1.jpg"
import contractImage13 from "../Assets/TemplatesPage/Contracts/event-planning-agreement/images/1.jpg"
import contractImage14 from "../Assets/TemplatesPage/Contracts/graphic-design-agreement/images/1.jpg"
import contractImage15 from "../Assets/TemplatesPage/Contracts/hvac-service-contract-agreement/images/1.jpg"
import contractImage16 from "../Assets/TemplatesPage/Contracts/indemnification-agreement/images/1.jpg"
import contractImage17 from "../Assets/TemplatesPage/Contracts/independent-contractor-agreement/images/1.jpg"
import contractImage18 from "../Assets/TemplatesPage/Contracts/intellectual-property-agreement/images/1.jpg"
import contractImage19 from "../Assets/TemplatesPage/Contracts/internship-contract/images/1.jpg"
import contractImage20 from "../Assets/TemplatesPage/Contracts/landscape-contract-agreement/images/1.jpg"
import contractImage21 from "../Assets/TemplatesPage/Contracts/loan-agreement/images/1.jpg"
import contractImage22 from "../Assets/TemplatesPage/Contracts/marketing-agreement/images/1.jpg"
import contractImage23 from "../Assets/TemplatesPage/Contracts/mou-agreement/images/1.jpg"
import contractImage24 from "../Assets/TemplatesPage/Contracts/non-compete-agreement/images/1.jpg"
// import contractImage25 from "../Assets/TemplatesPage/Contracts/non-completion-agreement/images/1.jpg"
import contractImage26 from "../Assets/TemplatesPage/Contracts/painting-agreement/images/1.jpg"
import contractImage27 from "../Assets/TemplatesPage/Contracts/payment-agreement/images/1.jpg"
import contractImage28 from "../Assets/TemplatesPage/Contracts/personal-training-contract/images/1.jpg"
import contractImage29 from "../Assets/TemplatesPage/Contracts/photo-release-form/images/1.jpg"
import contractImage30 from "../Assets/TemplatesPage/Contracts/property-management-agreement/images/1.jpg"
import contractImage31 from "../Assets/TemplatesPage/Contracts/referral-agreement/images/1.jpg"
import contractImage32 from "../Assets/TemplatesPage/Contracts/remodelling-contract/images/1.jpg"
import contractImage33 from "../Assets/TemplatesPage/Contracts/rent-agreement/images/1.jpg"
import contractImage34 from "../Assets/TemplatesPage/Contracts/retainer-agreement/images/1.jpg"
import contractImage35 from "../Assets/TemplatesPage/Contracts/roofing-contract/images/1.jpg"
import contractImage36 from "../Assets/TemplatesPage/Contracts/sales-contract/images/1.jpg"
import contractImage37 from "../Assets/TemplatesPage/Contracts/seo-contract/images/1.jpg"
import contractImage38 from "../Assets/TemplatesPage/Contracts/service-provider-contract/images/1.jpg"
import contractImage39 from "../Assets/TemplatesPage/Contracts/subcontractor-agreement/images/1.jpg"
import contractImage40 from "../Assets/TemplatesPage/Contracts/sublease-agreement/images/1.jpg"
import contractImage41 from "../Assets/TemplatesPage/Contracts/video-production-agreement/images/1.jpg"
import contractImage42 from "../Assets/TemplatesPage/Contracts/video-release-form/images/1.jpg"
import contractImage43 from "../Assets/TemplatesPage/Contracts/web-design-agreement/images/1.jpg"
import contractImage44 from "../Assets/TemplatesPage/Contracts/wedding-photography-agreement/images/1.jpg"
import contractImage45 from "../Assets/TemplatesPage/Contracts/wedding-planner-contract/images/1.jpg"
import contractImage46 from "../Assets/TemplatesPage/Contracts/work-for-hire-contract/images/1.jpg"

import contractImageCover1 from "../Assets/TemplatesPage/Contracts/accounting-contract/images/Cover.svg"
import contractImageCover2 from "../Assets/TemplatesPage/Contracts/agency-agreement/images/Cover.svg"
import contractImageCover3 from "../Assets/TemplatesPage/Contracts/basic-rent-agreement/images/Cover.svg"
import contractImageCover4 from "../Assets/TemplatesPage/Contracts/bookkeeping-contract/images/Cover.svg"
import contractImageCover5 from "../Assets/TemplatesPage/Contracts/caterer-agreement/images/Cover.svg"
import contractImageCover6 from "../Assets/TemplatesPage/Contracts/coaching-agreement/images/Cover.svg"
import contractImageCover7 from "../Assets/TemplatesPage/Contracts/commission-agreement/images/Cover.svg"
import contractImageCover8 from "../Assets/TemplatesPage/Contracts/confidentiality-agreement/images/Cover.svg"
import contractImageCover9 from "../Assets/TemplatesPage/Contracts/construction-contract-agreement/images/Cover.svg"
import contractImageCover10 from "../Assets/TemplatesPage/Contracts/contractor-agreement/images/Cover.svg"
import contractImageCover11 from "../Assets/TemplatesPage/Contracts/dj-contract/images/Cover.svg"
import contractImageCover12 from "../Assets/TemplatesPage/Contracts/employment-agreement/images/Cover.svg"
import contractImageCover13 from "../Assets/TemplatesPage/Contracts/event-planning-agreement/images/Cover.svg"
import contractImageCover14 from "../Assets/TemplatesPage/Contracts/graphic-design-agreement/images/Cover.svg"
import contractImageCover15 from "../Assets/TemplatesPage/Contracts/hvac-service-contract-agreement/images/Cover.svg"
import contractImageCover16 from "../Assets/TemplatesPage/Contracts/indemnification-agreement/images/Cover.svg"
import contractImageCover17 from "../Assets/TemplatesPage/Contracts/independent-contractor-agreement/images/Cover.svg"
import contractImageCover18 from "../Assets/TemplatesPage/Contracts/intellectual-property-agreement/images/Cover.svg"
import contractImageCover19 from "../Assets/TemplatesPage/Contracts/internship-contract/images/Cover.svg"
import contractImageCover20 from "../Assets/TemplatesPage/Contracts/landscape-contract-agreement/images/Cover.svg"
import contractImageCover21 from "../Assets/TemplatesPage/Contracts/loan-agreement/images/Cover.svg"
import contractImageCover22 from "../Assets/TemplatesPage/Contracts/marketing-agreement/images/Cover.svg"
import contractImageCover23 from "../Assets/TemplatesPage/Contracts/mou-agreement/images/Cover.svg"
import contractImageCover24 from "../Assets/TemplatesPage/Contracts/non-compete-agreement/images/Cover.svg"
// import contractImageCover25 from "../Assets/TemplatesPage/Contracts/non-completion-agreement/images/Cover.svg"
import contractImageCover26 from "../Assets/TemplatesPage/Contracts/painting-agreement/images/Cover.svg"
import contractImageCover27 from "../Assets/TemplatesPage/Contracts/payment-agreement/images/Cover.svg"
import contractImageCover28 from "../Assets/TemplatesPage/Contracts/personal-training-contract/images/Cover.svg"
import contractImageCover29 from "../Assets/TemplatesPage/Contracts/photo-release-form/images/Cover.svg"
import contractImageCover30 from "../Assets/TemplatesPage/Contracts/property-management-agreement/images/Cover.svg"
import contractImageCover31 from "../Assets/TemplatesPage/Contracts/referral-agreement/images/Cover.svg"
import contractImageCover32 from "../Assets/TemplatesPage/Contracts/remodelling-contract/images/Cover.svg"
import contractImageCover33 from "../Assets/TemplatesPage/Contracts/rent-agreement/images/Cover.svg"
import contractImageCover34 from "../Assets/TemplatesPage/Contracts/retainer-agreement/images/Cover.svg"
import contractImageCover35 from "../Assets/TemplatesPage/Contracts/roofing-contract/images/Cover.svg"
import contractImageCover36 from "../Assets/TemplatesPage/Contracts/sales-contract/images/Cover.svg"
import contractImageCover37 from "../Assets/TemplatesPage/Contracts/seo-contract/images/Cover.svg"
import contractImageCover38 from "../Assets/TemplatesPage/Contracts/service-provider-contract/images/Cover.svg"
import contractImageCover39 from "../Assets/TemplatesPage/Contracts/subcontractor-agreement/images/Cover.svg"
import contractImageCover40 from "../Assets/TemplatesPage/Contracts/sublease-agreement/images/Cover.svg"
import contractImageCover41 from "../Assets/TemplatesPage/Contracts/video-production-agreement/images/Cover.svg"
import contractImageCover42 from "../Assets/TemplatesPage/Contracts/video-release-form/images/Cover.svg"
import contractImageCover43 from "../Assets/TemplatesPage/Contracts/web-design-agreement/images/Cover.svg"
import contractImageCover44 from "../Assets/TemplatesPage/Contracts/wedding-photography-agreement/images/Cover.svg"
import contractImageCover45 from "../Assets/TemplatesPage/Contracts/wedding-planner-contract/images/Cover.svg"
import contractImageCover46 from "../Assets/TemplatesPage/Contracts/work-for-hire-contract/images/Cover.svg"

export const baseURLOfTemplates =
    "https://awesomesuite-images.s3.us-east-2.amazonaws.com/sign/template-pdf/"

export const contracts = [
    {
        title: "Accounting Contract",
        image: contractImage1,
        coverImage: contractImageCover1,
        fileNameInDb: "accounting-agreement-template.pdf",
    },
    {
        title: "Agency Agreement",
        image: contractImage2,
        coverImage: contractImageCover2,
        fileNameInDb: "agency-agreement-template.pdf",
    },
    // {
    //     title: "Basic Rental Agreement",
    //     image: contractImage3,
    //     coverImage: contractImageCover3,
    // },
    {
        title: "Bookkeeping Contract",
        image: contractImage4,
        coverImage: contractImageCover4,
        fileNameInDb: "bookkeeping-agreement-template.pdf",
    },
    {
        title: "Caterer Agreement",
        image: contractImage5,
        coverImage: contractImageCover5,
        fileNameInDb: "caterer-agreement-template.pdf",
    },
    {
        title: "Coaching Agreement",
        image: contractImage6,
        coverImage: contractImageCover6,
        fileNameInDb: "coaching-agreement-template.pdf",
    },
    {
        title: "Commission Agreement",
        image: contractImage7,
        coverImage: contractImageCover7,
        fileNameInDb: "commission-agreement-template.pdf",
    },
    // {
    //     title: "Confidentiality Agreement",
    //     image: contractImage8,
    //     coverImage: contractImageCover8,
    // },
    // {
    //     title: "Construction Contract Agreement",
    //     image: contractImage9,
    //     coverImage: contractImageCover9,
    // },
    // {
    //     title: "Contractor Agreement",
    //     image: contractImage10,
    //     coverImage: contractImageCover10,
    // },
    {
        title: "DJ Contract",
        image: contractImage11,
        coverImage: contractImageCover11,
        fileNameInDb: "dj-contract-template.pdf",
    },
    // {
    //     title: "Employment Agreement",
    //     image: contractImage12,
    //     coverImage: contractImageCover12,
    // },
    {
        title: "Event Planning Agreement",
        image: contractImage13,
        coverImage: contractImageCover13,
        fileNameInDb: "event-planning-agreement-template.pdf",
    },
    {
        title: "Graphic Design Agreement",
        image: contractImage14,
        coverImage: contractImageCover14,
        fileNameInDb: "graphic-design-agreement-template.pdf",
    },
    {
        title: "Hvac Service Contract Agreement",
        image: contractImage15,
        coverImage: contractImageCover15,
        fileNameInDb: "hvac-service-contract-template.pdf",
    },
    // {
    //     title: "Indemnification Agreement",
    //     image: contractImage16,
    //     coverImage: contractImageCover16,
    // },
    {
        title: "Independent Contractor Agreement",
        image: contractImage17,
        coverImage: contractImageCover17,
        fileNameInDb: "independent-contractor-agreement-template.pdf",
    },
    {
        title: "Intellectual Property Agreement",
        image: contractImage18,
        coverImage: contractImageCover18,
        fileNameInDb: "intellectual-property-agreement-template.pdf",
    },
    {
        title: "Internship Contract Agreement",
        image: contractImage19,
        coverImage: contractImageCover19,
        fileNameInDb: "internship-contract-template.pdf",
    },
    // {
    //     title: "Landscape Contract Agreement",
    //     image: contractImage20,
    //     coverImage: contractImageCover20,
    // },
    // {
    //     title: "Loan Agreement",
    //     image: contractImage21,
    //     coverImage: contractImageCover21,
    // },
    {
        title: "Marketing Agreement",
        image: contractImage22,
        coverImage: contractImageCover22,
        fileNameInDb: "marketing-agreement-template.pdf",
    },
    {
        title: "MOU Agreement",
        image: contractImage23,
        coverImage: contractImageCover23,
        fileNameInDb: "mou-template.pdf",
    },
    {
        title: "Non Compete Agreement",
        image: contractImage24,
        coverImage: contractImageCover24,
        fileNameInDb: "non-compete-agreement-template.pdf",
    },
    // {
    //     title: "Non Completion Agreement",
    //     image: contractImage25,
    //     coverImage: contractImageCover25,
    // },
    {
        title: "Painting Agreement",
        image: contractImage26,
        coverImage: contractImageCover26,
        fileNameInDb: "painting-agreement-template.pdf",
    },
    {
        title: "Payment Agreement",
        image: contractImage27,
        coverImage: contractImageCover27,
        fileNameInDb: "payment-agreement-template.pdf",
    },
    {
        title: "Personal Training Contract",
        image: contractImage28,
        coverImage: contractImageCover28,
        fileNameInDb: "personal-training-template.pdf",
    },
    // {
    //     title: "Photo Release Form",
    //     image: contractImage29,
    //     coverImage: contractImageCover29,
    // },
    {
        title: "Property Management Agreement",
        image: contractImage30,
        coverImage: contractImageCover30,
        fileNameInDb: "property-management-agreement-template.pdf",
    },
    {
        title: "Referral Agreement",
        image: contractImage31,
        coverImage: contractImageCover31,
        fileNameInDb: "referral-agreement-template.pdf",
    },
    {
        title: "Remodelling contract",
        image: contractImage32,
        coverImage: contractImageCover32,
        fileNameInDb: "remodelling-contract-template.pdf",
    },
    // {
    //     title: "Rent Agreement",
    //     image: contractImage33,
    //     coverImage: contractImageCover33,
    // },
    // {
    //     title: "Retainer Agreement",
    //     image: contractImage34,
    //     coverImage: contractImageCover34,
    // },
    {
        title: "Roofing Contract",
        image: contractImage35,
        coverImage: contractImageCover35,
        fileNameInDb: "roofing-contract-template.pdf",
    },
    {
        title: "Sales Contract",
        image: contractImage36,
        coverImage: contractImageCover36,
        fileNameInDb: "sales-contract-template.pdf",
    },
    {
        title: "SEO Contract",
        image: contractImage37,
        coverImage: contractImageCover37,
        fileNameInDb: "seo-proposal-template.pdf",
    },
    {
        title: "Service Provider Contract",
        image: contractImage38,
        coverImage: contractImageCover38,
        fileNameInDb: "service-provider-agreement-template.pdf",
    },
    // {
    //     title: "Subcontractor Agreement",
    //     image: contractImage39,
    //     coverImage: contractImageCover39,
    // },
    {
        title: "Sublease Agreement",
        image: contractImage40,
        coverImage: contractImageCover40,
        fileNameInDb: "sublease-agreement-template.pdf",
    },
    // {
    //     title: "Video Production Proposal",
    //     image: contractImage41,
    //     coverImage: contractImageCover41,
    // },
    {
        title: "Video Release Form",
        image: contractImage42,
        coverImage: contractImageCover42,
        fileNameInDb: "video-release-form-template.pdf",
    },
    {
        title: "Web Design Agreement",
        image: contractImage43,
        coverImage: contractImageCover43,
        fileNameInDb: "website-design-agreement-template.pdf",
    },
    {
        title: "Wedding Photography Agreement",
        image: contractImage44,
        coverImage: contractImageCover44,
        fileNameInDb: "wedding-photography-contract-template.pdf",
    },
    {
        title: "Wedding Planner Contract",
        image: contractImage45,
        coverImage: contractImageCover45,
        fileNameInDb: "wedding-planner-contract-template.pdf",
    },
    {
        title: "Work for Hire Contract",
        image: contractImage46,
        coverImage: contractImageCover46,
        fileNameInDb: "work-for-hire-contract-template.pdf",
    },
]
