export const SignatureDragType = {
    SIGN: "SIGN",
    CHECKBOX: "CHECKBOX",
    TEXT: "TEXT",
    DATE: "DATE",
    TYPE: "TYPE",
    IMAGE: "IMAGE",
    SIGNER_FIELD: "DIGITAL_SIGN",
    NUMBER: "NUMBER",
}

export const TemplateElementsDragType = {
    SIGNER_FIELD: "DIGITAL_SIGN",
    TEXT: "TEXT",
    // IMAGE: "IMAGE",
    DATE: "DATE",
    CHECKBOX: "CHECKBOX",
    NUMBER: "NUMBER",
}
