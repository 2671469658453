import { createUseStyles } from "react-jss"

export const useStyles = createUseStyles(() => ({
    container: {
        height: (props) => props.height || "100%",
        width: (props) => props.width || "97%",
        background: "#ffffff",
        margin: "1rem", //Testcase
        border: "1px solid #d6dfe8",
        maxHeight: (props) => props.maxHeight || props.height || "100%",
    },
    topBar: {
        minHeight: "50px",
        boxShadow: "0px 6px 6px #d6dfe8",
        display: "flex",
        alignItems: "center",
        position: "relative",
        zIndex: 1,
    },
    topBarLeft: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        "& svg": {
            marginLeft: "1rem",
        },
    },
    topBarRight: {
        marginRight: "1rem",
        cursor: "pointer",
    },
    mainContainer: {
        display: "flex",
        minHeight: "350px",
        height: "calc(100% - 50px)",
    },
    mainContainerLeft: {
        width: "max(200px, 15%)",
        padding: "0 1rem",
        border: "1px solid #d6dfe8",
        borderLeftColor: "transparent",
    },
    mainContainerRight: {
        flex: 1,
        height: "100%",
        overflow: "hidden auto",
        position: "relative",
    },
    mainContainerContinue: {
        position: "absolute",
        padding: "0.25rem 2rem",
        display : "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "-1px -1px 2px #A3B4C8",
        bottom: 0,
        left: 0,
        width: "100%",
        background: "#FFFFFF",
    },
    leftBarOption: {
        display: "flex",
        alignItems: "center",
        padding: "0.5rem 0.25rem",
        cursor: "pointer",
        margin: "3px 0",
    },
    leftBarOptionActive: {
        backgroundColor: "#EAF0F6",
        outline: "solid 1px #d6dfe8",
        borderRadius: 4,
        "& svg": {
            fill: "#33475B",
        },
    },
    leftBarUploadOption: {
        margin: "0.5rem 0",
        marginTop: "1rem",
    },
    uploadFilesWrapper: {
        width: "80%",
        height: "60%",
        maxHeight: 250,
        padding: 12,
        backgroundColor: "#EDF2F8",
        border: "2px solid #A5B6C9",
        borderRadius: 8,
        borderStyle: "dashed",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        margin: "1rem auto",
    },
    uploadFilesWrapperHover: {
        backgroundColor: "#E5F1FF",
        borderColor: "#6FA5F6",
    },
    fileUploaderRight: {
        width: "65%",
        height: "100%",
    },
    fileUploaderTop: {
        width: "100%",
        // height: "70%",
        margin: "3rem 0 0.5rem 0",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& div": {
            color: "#A5B6C9",
            cursor: "pointer",
        },
    },
    otherFilePickingMethods: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "80%",
        margin: "1rem auto",
        paddingBottom: 50,
    },
    otherFilePickingMethod: {
        width: "fit-content",
        display: "flex",
        margin: "0 12px",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #B9D3EC",
        backgroundColor: "#ffffff",
        padding: 6,
        borderRadius: 4,

        "& *:hover": {
            cursor: "pointer",
        },
        "& img": {
            width: 23,
        },
        "& svg": {
            width: 23,
            marginRight: 12,
        },
    },
    contentLibraryTop: {
        width: "100%",
        borderBottom: "1px solid #d6dfe8",
        padding: "1rem 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    contentLibraryTabSelector: {
        width: "70px",
        height: "35px",
        display: "flex",
        marginLeft: "2rem",
    },
    contentLibraryTab1: {
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #d6dfe8",
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
        borderRightColor: "transparent",
        cursor: "pointer",
    },
    contentLibraryTab2: {
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #d6dfe8",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: "4px",
        borderLeftColor: "transparent",
        cursor: "pointer",
    },
    contentLibraryTabActive: {
        background: "#EAF0F6",
        borderColor: "#99ACC2",
    },
    contentLibraryOptions: {
        padding: "0.5rem",
        display: "flex",
    },
    contentLibraryOptionUpload: {
        width: 80,
        height: 35,
        border: "1px solid #d6dfe8",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 6,
        marginLeft: "1rem",
        cursor: "pointer",
    },
    contentLibraryOption: {
        width: 35,
        height: 35,
        border: "1px solid #d6dfe8",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 6,
        marginLeft: "1rem",
        cursor: "pointer",
    },
    contentLibraryImages: {
        display: "flex",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        maxHeight: "calc(100% - 76px)",
        paddingBottom: 50,
        overflowY: "scroll",
        paddingTop: "1rem",
        cursor: "pointer",
        "&::-webkit-scrollbar": {
            height: 8,
            width: 8,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
            borderRadius: 20,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#b6b6b6",
            borderRadius: 20,
        },
    },
    contentLibraryImage: {
        width: "30%",
        marginBottom: "1rem",
        border: "1px solid #d6dfe8",
    },
    contentLibraryImageActive: {
        border: "1px solid #00BBD8",
    },
    contentLibraryImageContent: {
        height: 150,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        "& img":{
           width: "100%"
        }
    },
    contentLibraryImageDescription: {
        borderTop: "1px solid #DFE3EB",
        height: "70",
    },
    contentLibraryImageDescriptionActive: {
        borderTop: "1px solid #00BBD8",
        background: "#00BBD8",
    },
    contentLibraryImageDescriptionGIF: {
        display: "flex",
        alignItems: "center",
        padding: ".5rem .25rem",
    },
    contentLibraryImageUnsplash: {
        width : "100%",
        "& div:first-child":{
            height: '100%'
        },
        "& div:last-child":{
            height: 'unset',
            cursor: "pointer",
        },
    },
    fileDescription: {
        padding: "0.25rem",
        display: "flex",
        alignItems: "center",
        "& a": {
            display: "flex",
            alignItems: 'center',
            textDecoration: "none",
        },
    },
    fileType: {
        backgroundColor: "#99ACC2",
        borderRadius: "10px",
        height: 20,
        padding: "0 0.5rem",
        marginLeft: "auto",
        marginRight: "0.5rem",
    },
    fileTypeActive: {
        background: "#FFFFFF",
    }
}))
