import React, { useState } from "react"
import WelcomeOnBoard from "./WelcomeToOnboarding/welcomeOnBoard"
import CreateWorkspace from "./CreateWorkSpace/CreateWorkspace"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { useEffect } from "react"
import { addNewWorkspace, quickOnboardValidation } from "Action/workspaceAction"
import { useHistory } from "react-router-dom"
import { OWNER } from "Types"
import Guard from "Awesome-ui/Guard"
import PermissionDenied from "Awesome-ui/PermissionDenied"

const OnBoarding = ({ token, userEmailId, userRole }) => {
    const [pageNo, setPageNo] = useState(0)
    const [options, setOptions] = useState({
        MYSELF: false,
        MYTEAM: false,
    })
    const [workspaceName, setWorkspaceName] = useState("")
    const history = useHistory()

    const submitHandler = async () => {
        const newWorkspace = {
            name: workspaceName,
            email: userEmailId,
            address: "",
        }

        const isAddedWorkspace = await addNewWorkspace(newWorkspace)
        if (!isAddedWorkspace) return setPageNo(0)
        setWorkspaceName("")
        history.push("/")
    }

    useEffect(() => {
        const init = async () => {
            const res = await quickOnboardValidation(token)
            if (!res) history.push("/")
        }
        init()
    }, [token])

    return (
        <Guard
            forceBlock={userRole !== OWNER ? true : false}
            FallbackUi={<PermissionDenied />}
        >
            <div style={{ width: "100vw", height: "100vh" }}>
                <Helmet>
                    <title>Onboarding | Awesome Sign</title>
                </Helmet>
                {pageNo === 0 && (
                    <CreateWorkspace
                        val={workspaceName}
                        setVal={setWorkspaceName}
                        setView={setPageNo}
                    />
                )}
                {pageNo === 1 && (
                    <WelcomeOnBoard
                        options={options}
                        setOptions={setOptions}
                        setView={setPageNo}
                        onWorkspaceSubmit={submitHandler}
                    />
                )}
                {/* <BottomStepBar
                view={pageNo}
                setView={setPageNo}
                options={[forMyself, forMyteam]}
                workspaceName={workspaceName}
                setWorkspaceName={setWorkspaceName}
            /> */}
            </div>
        </Guard>
    )
}

const mapStateToProps = ({ userReducer }) => ({
    token: userReducer.token,
    userEmailId: userReducer.userDetails?.email_id,
    userRole: userReducer.userRole,
})

export default connect(mapStateToProps)(OnBoarding)
