import React, { useState } from "react"
import "./FileUploader.scss"
import mediaHandler from "../../Library/mediaHandler/mediaHandler"
import GoogleDrivePicker from "Awesome-ui/FilePickers/GooglePicker/GooglePicker"
import DropBoxPicker from "Awesome-ui/FilePickers/DropBoxPicker/DropBoxPicker"
import PdfDropDown from "Components/AddDocument/PdfDropdown"
import Guard from "Awesome-ui/Guard"
import { createMessage } from "Action/messageAndNotificationActions"
import { DANGER } from "Types"
import { LoaderWithText, Text } from "@awesomesuite-frontend/awesome-nebula"
import OneDrivePicker from "Awesome-ui/FilePickers/OneDrivePicker/OneDrivePicker"
import BoxPickerButton from "Awesome-ui/FilePickers/BoxPickerButton/BoxPickerButton"
import { addDocumentToS3 } from "Action/documentAction"
import usePdfHook from "hooks/PdfLib/PdfHook"

// const validatingParamaters = [
//     "image/jpg",
//     "image/jpeg",
//     "image/png",
//     "image/x-icon",
//     "image/gif",
//     "application/pdf",
// ]

function validateFile(file) {
    if (file.type !== "application/pdf")
        return createMessage(
            "Document type not supported. Please upload a pdf document.",
            null,
            DANGER
        )
    if (file.size > 100 * 1024 * 1024)
        return createMessage(
            "Document size is too large.",
            "Please upload a document less than 100mb.",
            DANGER
        )
    return true
}

function validateFileSize(file) {
    if (file.size > 500000) return false
    else return true
}

const FileUploader = ({
    addFiles,
    mediaApi,
    userToken,
    single,
    parentLoader,
    ...props
}) => {
    const [fileOver, setFileOver] = React.useState(false)
    const inputfile = React.useRef(null)
    const mediaController = new mediaHandler(mediaApi)
    const [loading, setloading] = useState(false)
    const [isHover, setHover] = useState(false)
    const {loadedLib, loadPdfLib, loadPdf} = usePdfHook()

    function uploadClick() {
        if (loading || parentLoader) return
        inputfile.current.click()
    }

    function onFileEnter(event) {
        event.preventDefault()
        // setFileOver(true);
    }
    function onFileDragOver(event) {
        event.preventDefault()
        if (!fileOver) setFileOver(true)
    }

    function onFileDrop(event) {
        event.preventDefault()
        handleFiles(event.dataTransfer.files)
        setFileOver(false)
    }

    function onFileLeave(event) {
        event.preventDefault()
        setFileOver(false)
    }

    async function getNumberOfPages(pdfFile) {
        if (!loadedLib) {
            await loadPdfLib()
        }
        const res = await loadPdf(pdfFile)
        return res.numberOfPages
    }

    async function handleFiles(listFiles) {
        listFiles = Array.from(listFiles)
        inputfile.current.value = null
        if (single) {
            const singleFile = listFiles[0]
            if (!validateFile(singleFile)) return

            setloading(true)
            const mediaResp = await addDocumentToS3(
                singleFile,
                singleFile.name,
                singleFile.size
            )
            if (!mediaResp) return setloading(false)
            const pages = await getNumberOfPages(mediaResp.mediaPath)
            addFiles({
                ...mediaResp,
                name: singleFile.name,
                file: singleFile,
                preView: mediaResp.mediaPath,
                size: singleFile.size,
                pages,
            })
            setloading(false)
        }
    }

    async function handleOtherFiles(listFiles, drive) {
        if (!single) return
        const singleFile = listFiles[0]
        // if (!validateFile(singleFile)) return

        setloading(true)
        addFiles({
            ...singleFile,
            name: singleFile.name || singleFile.mediaName,
            file: singleFile,
            preView: singleFile.mediaPath,
            size: singleFile.size,
        })
        setloading(false)
    }

    return (
        <div
            className={`upload-files-wrapper ${isHover && "hover"}`}
            onDragOver={onFileDragOver}
            onDragEnter={onFileEnter}
            onDrop={onFileDrop}
            onDragLeave={onFileLeave}
        >
            <Guard scope="document_create">
                <div className="file-uploader-right">
                    <div className="file-uploader-top-wrapper">
                        <div className="file-uploader-top">
                            <div
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                                onClick={uploadClick}
                            >
                                {loading || parentLoader ? (
                                    <LoaderWithText
                                        style={{ backgroundColor: "#727cf5" }}
                                        text={
                                            <Text fontSize={14} color="#fff">
                                                Uploading pdf...
                                            </Text>
                                        }
                                    />
                                ) : (
                                    <PdfDropDown />
                                )}
                            </div>
                        </div>
                        <Text
                            color="light"
                            fontSize={12}
                            align="center"
                            marginTop={"0.5rem"}
                        >
                            * Size Upto 10mb
                        </Text>
                    </div>
                    <div className="other-file-picking-methods">
                        <GoogleDrivePicker
                            setFile={handleOtherFiles}
                            styleClass="other-file-picking-method"
                        />
                        <DropBoxPicker
                            setFile={handleOtherFiles}
                            styleClass="other-file-picking-method"
                        />
                        {/* <OneDrivePicker setFile={handleOtherFiles} /> */}
                        <BoxPickerButton setFile={handleOtherFiles} />
                    </div>
                </div>
            </Guard>
            <input
                type="file"
                onChange={(e) => handleFiles(e.target.files)}
                hidden
                multiple={true}
                ref={inputfile}
                accept="application/pdf"
            />
        </div>
    )
}

export default FileUploader
