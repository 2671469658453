import React, { useEffect } from "react"

const Media = () => {
    useEffect(() => {
        let search = window.location.search
        let params = new URLSearchParams(search)
        let box = localStorage.getItem("box-init")

        if (box && params.get("code")) {
            localStorage.setItem("box-init", params.get("code"))
            window.close()
        }
    }, [])
    return <div></div>
}

export default Media
