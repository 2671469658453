import React, { useEffect } from "react"
import { useState } from "react"
import PlanPopupIcon from "./assets/PlanPopupIcon.png"
import { ReactComponent as GreenTickIcon } from "./assets/GreenTickCircle.svg"
import { ReactComponent as UpgradeManIcon } from "./assets/business-man-doing-paperwork.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { getLinkToService } from "data/ProductMapping"
import useStyles from "./style"

const PlanPopup = ({ active, isClosable = true, onClose = () => {}, plans }) => {
    const [activePlan, setActivePlan] = useState(0)
    const [currentPlans, setCurrentPlans] = useState([
        { tag: "Best Value", interval: "Monthly", price: 0 },
        { interval: "Monthly", price: 0 },
    ])
    const ACCOUNTS_LINK_API = getLinkToService("account")
    const [features, setFeatures] = useState([
        "Single User Only",
        "Unlimited eSignatures",
        "Templates: 10",
        "Signing workflows",
        "Integrations (Dropbox, Google Drive)",
        "Document and folder management",
    ])

    useEffect(() => {
        if (plans) {
            const teamPlans = [
                plans.filter((plan) => plan.title === "Professionals")[0],
                plans.filter((plan) => plan.title === "Business")[0],
            ]
            let updatedPlans = [...currentPlans]
            teamPlans.map((plan, key) => {
                if (!plan)  return
                updatedPlans[key].price = plan.amount
                updatedPlans[key] = { ...plan, ...updatedPlans[key] }
            })
            setCurrentPlans(updatedPlans)
        }
    }, [plans])

    useEffect(() => {
        const res = currentPlans[activePlan]?.features
        const currentFeatures = res?.split("|||")
        if (currentFeatures) setFeatures(currentFeatures)
    }, [activePlan])

    const classes = useStyles()

    if (!active) return <></>
    return (
        <div className={classes.auiPlan}>
            <div className={classes.auiPlanPopup}>
                <div className={classes.auiPlanLeft}>
                    <Text varient="h4" margin="2rem 0 1rem 0">
                        Do more with Awesome Sign's{" "}
                        {currentPlans[activePlan].title + " "}
                        Plan
                    </Text>
                    <Text varient="subText">
                        Guarantee a high performing team that boosts your business
                        with Awesome Sign's {currentPlans[activePlan].title} Plan.
                        {" " + currentPlans[activePlan].description}
                    </Text>
                    <Text
                        className={classes.auiPlanTitle}
                        varient="h4"
                        margin="1rem 0"
                    >
                        Why upgrade to {currentPlans[activePlan].title} Plan?
                    </Text>
                    <div className={classes.auiPlanFeatures}>
                        {features.map((feature, i) => (
                            <div
                                key={i}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <GreenTickIcon />{" "}
                                <Text marginLeft="0.25rem" fontSize={14}>
                                    {feature}
                                </Text>
                            </div>
                        ))}
                    </div>
                    <div className={classes.auiPlanContainer}>
                        {currentPlans.map(({ tag, interval, price }, i) => (
                            <div
                                key={i}
                                className={`
                                    ${classes.auiPlanCard} ${
                                    i === activePlan && classes.auiPlanCardActive
                                }`}
                                onClick={() => setActivePlan(i)}
                            >
                                {tag && (
                                    <div className={classes.auiPlanCardTag}>
                                        <Text fontSize={14} color="#ffffff">
                                            {tag}
                                        </Text>
                                    </div>
                                )}
                                <div className={classes.auiPlanCardPrice}>
                                    <Text
                                        fontSize={14}
                                        style={{ fontWeight: 800 }}
                                    >{`${interval}`}</Text>
                                    <Text
                                        fontSize={14}
                                        style={{ fontWeight: 800 }}
                                    >{`$${price}/folder/month`}</Text>
                                </div>
                                <div className={classes.auiPlanCardSub}>
                                    <Text fontSize={10} color="light">
                                        billed {interval}
                                    </Text>
                                </div>
                            </div>
                        ))}
                    </div>
                    <a
                        href={`${ACCOUNTS_LINK_API}/subscription/billing-details`}
                        className={classes.auiPlanUpgrade}
                    >
                        <Text color="#ffffff" style={{ fontWeight: 600 }}>
                            Upgrade to Essentials
                        </Text>
                    </a>
                    <a
                        href={`${ACCOUNTS_LINK_API}/subscription/plans`}
                        className={classes.auiPlanAllPlans}
                    >
                        <Text color="#00a4bd" varient="subText">
                            View All Plans
                        </Text>
                    </a>
                </div>
                <div className={classes.auiPlanRight}>
                    <div className={classes.auiPlanTitle}>
                        <Text varient="h4" align="center">
                            Keep growing your business. Upgrade to premium
                        </Text>
                    </div>
                    <UpgradeManIcon />
                    {/* <PlanPopupIcon style={{height: "80%"}}/> */}
                </div>
                {isClosable && (
                    <div className={classes.auiPlanClose} onClick={onClose}>
                        +
                    </div>
                )}
            </div>
        </div>
    )
}

export default PlanPopup
