import { DataLoader } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
    loadingWrapper: {
        border: "1px solid #D6DFE8",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 350,
    },
}))

const RecentActivitiesLoading = () => {
    const classes = useStyles()
    return (
        <div className={classes.loadingWrapper}>
            <DataLoader size={"3rem"} borderWidth={"5px"} />
        </div>
    )
}

export default RecentActivitiesLoading
