import React from 'react'
import { createUseStyles } from 'react-jss'
import SettingActivityItem from './settingActivityItem'
import { ReactComponent as WorkspaceFlag } from '../../../../Assets/settings/workspace-flag.svg'
import { ReactComponent as EmailIcon } from '../../../../Assets/settings/email-icon.svg'
import { ReactComponent as CompanyContentIcon } from '../../../../Assets/settings/company-content-icon.svg'
import { ReactComponent as DocumentPreferenceIcon } from '../../../../Assets/settings/doc-preference.svg'
import CompanyDetails from './components/CompanyDetails'
import EmailSignature from './components/EmailSignature'
import DocumentPreference from './components/DocumentPreference'
import WorkspaceSettingComponent from './components/WorkspaceSettingComponent'

const useStyles = createUseStyles(()=>({
    workspaceSettingsContainer: {
        width: "80%",
        margin:"2rem auto",
        marginBottom: "4rem"
    }
}))

const workspaceSettingsData = [
    {
        title: "Workspace Settings", 
        content:<WorkspaceSettingComponent/>,
        logo:<WorkspaceFlag />,
        titleinfo: "This is the General text",
        isUpgrade: true
    },
    {
        title: "Company Details", 
        content:<CompanyDetails />,
        logo:<CompanyContentIcon />,
        titleinfo: "This is the General text",
    },
    // {
    //     title: "Email Signature", 
    //     content:<EmailSignature />,
    //     logo:<EmailIcon />,
    //     titleinfo: "This is the General text",
    //     isUpgrade: true
    // },
    // {
    //     title: "Document Preference", 
    //     content:<DocumentPreference />,
    //     logo:<DocumentPreferenceIcon />,
    //     titleinfo: "This is the General text",
    //     isUpgrade: true
    // },
]

const WorkspaceSettings = () => {
    const classes = useStyles() 
  return (
    <div className={`${classes.workspaceSettingsContainer}`} >
        {workspaceSettingsData?.map(data => <SettingActivityItem data={data}/>)}
    </div>
  )
}

export default WorkspaceSettings