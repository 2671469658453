export const GET_WORKSPACE_BY_ID = "GET_WORKSPACE_BY_ID"
export const GET_WORKSPACE_ALL = "GET_WORKSPACE_ALL"
export const GET_CURRENT_WORKSPACE = "GET_CURRENT_WORKSPACE"
export const ADD_NEW_WORKSPACE = "ADD_NEW_WORKSPACE"
export const DELETE_WORKSPACE_BY_ID = "DELETE_WORKSPACE_BY_ID"
export const SET_WORKSPACE_LOADING = "SET_WORKSPACE_LOADING"
export const SET_WORKSPACE_LOADED = "SET_WORKSPACE_LOADED"
export const SET_WORKSPACE_TO_NULL = "SET_WORKSPACE_TO_NULL"
export const EDIT_WORKSPACE_BY_ID = "EDIT_WORKSPACE_BY_ID"
export const SET_WORKSPACE_USERS = "SET_WORKSPACE_USERS"
export const GET_FOLDERS_BY_USER = "GET_FOLDERS_BY_USER"
export const DELETE_USER_FROM_WORKSPACE = "DELETE_USER_FROM_WORKSPACE"
export const UPDATE_BRANDS_IN_CURRENT_WORKSPACE =
    "UPDATE_BRANDS_IN_CURRENT_WORKSPACE"
