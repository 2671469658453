import { ReactComponent as WhiteFolderIcon } from "../Assets/Folders/white-folder.svg"
import { ReactComponent as GrayFolderIcon } from "../Assets/Folders/gray-folder.svg"
import { ReactComponent as GreenFolderIcon } from "../Assets/Folders/green-folder.svg"
import { ReactComponent as OrangeFolderIcon } from "../Assets/Folders/orange-folder.svg"
import { ReactComponent as PinkFolderIcon } from "../Assets/Folders/pink-folder.svg"
import { ReactComponent as PurpleFolderIcon } from "../Assets/Folders/purple-folder.svg"
import { ReactComponent as VioletFolderIcon } from "../Assets/Folders/violet-folder.svg"
import { ReactComponent as BlueFolderIcon } from "../Assets/Folders/blue-folder.svg"

export const folderIcons = [
    {
        name: "GRAY_FOLDER_ICON",
        color: "gray",
        logo: <GrayFolderIcon />,
    },
    {
        name: "BLUE_FOLDER_ICON",
        color: "blue",
        logo: <BlueFolderIcon />,
    },
    {
        name: "GREEN_FOLDER_ICON",
        color: "green",
        logo: <GreenFolderIcon />,
    },
    {
        name: "ORANGE_FOLDER_ICON",
        color: "orange",
        logo: <OrangeFolderIcon />,
    },
    {
        name: "WHITE_FOLDER_ICON",
        color: "offwhite",
        logo: <WhiteFolderIcon />,
    },
    {
        name: "PURPLE_FOLDER_ICON",
        color: "purple",
        logo: <PurpleFolderIcon />,
    },
    {
        name: "PINK_FOLDER_ICON",
        color: "pink",
        logo: <PinkFolderIcon />,
    },
    {
        name: "VIOLET_FOLDER_ICON.svg",
        color: "violet",
        logo: <VioletFolderIcon />,
    },
]
