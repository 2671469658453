import React, { useState, useRef, useEffect } from "react"
import { Rnd } from "react-rnd"
import { dragClasses, dragStyle, dragStyleTransparent } from "../Common/Style"
import {
    addDataToElement,
    dragSignatureElement,
    removeSignatureElement,
    resizeSignatureElement,
} from "../../../../../Action/signAction"
import Wrapper from "../Common/Wrapper"
import DragPreviewer from "../DragPreviewer"

/**
 * show preview of the date in textbox
 * @param {*} param0
 * @returns
 */
const DateComp = ({
    pageIndex,
    getPagePosition,
    id,
    type,
    xDefault,
    yDefault,
    value,
    width,
    height,
    disable,
    ...other
}) => {
    const [text, setText] = useState("")
    const ref = useRef(null)
    const [toolModel, setToolModel] = useState(true)

    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        let formattedDate = ""
        function getDate() {
            const date = new Date()
            formattedDate = `${date.getDate()}-${
                date.getMonth() + 1
            }-${date.getFullYear()}`
            setText(formattedDate)
        }

        getDate()
        onDragStop()
        onResize()
        addDataToElement(pageIndex, id, formattedDate)
    }, [])

    function openModelHandler() {
        setToolModel(true)
    }

    function mouseLeaveHandler(e) {
        const comp = ref.current
        if (comp && comp.contains(e.target)) return
        else setToolModel(false)
    }

    function onRemoveHandler() {
        removeSignatureElement(pageIndex, id)
        other.onMouseLeave()
    }

    function onDragStop(event) {
        const element = ref.current.getBoundingClientRect()
        const canvas = getPagePosition()
        const pos = {
            x: Math.floor(element.left - canvas.left),
            y: Math.floor(canvas.bottom - element.top - element.height),
        }
        dragSignatureElement(
            pageIndex,
            id,
            pos.x,
            pos.y,
            element.top - canvas.top,
            element.left - canvas.left
        )
        setIsDragging(false)
    }

    function onResize() {
        const element = ref.current.getBoundingClientRect()
        const canvas = getPagePosition()
        const pos = {
            x: Math.floor(element.left - canvas.left),
            y: Math.floor(canvas.bottom - element.top - element.height),
        }
        resizeSignatureElement(
            pageIndex,
            id,
            Math.floor(element.width),
            Math.floor(element.height),
            pos.x,
            pos.y,
            element.left - canvas.left,
            element.top - canvas.top
        )
    }

    function onBlurHandler() {
        addDataToElement(pageIndex, id, text)
    }

    return (
        <Rnd
            style={{ zIndex: 12 }}
            bounds={"parent"}
            default={{
                x: xDefault,
                y: yDefault,
                width: "120px",
                height: "30px",
            }}
            minWidth={"130px"}
            minHeight={"30px"}
            resizeHandleStyles={toolModel ? dragStyle : dragStyleTransparent}
            resizeHandleClasses={toolModel ? dragClasses : {}}
            onDragStart={() => setIsDragging(true)}
            onDragStop={onDragStop}
            onResize={onResize}
            onMouseDown={openModelHandler}
            disableDragging={disable}
            enableResizing={!disable}
        >
            <div
                className=""
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
                {...other}
            >
                <DragPreviewer isDragging={isDragging} />
                {toolModel && <Wrapper onRemoveHandler={onRemoveHandler} />}
                <textarea
                    onMouseLeave={() =>
                        document.addEventListener("click", mouseLeaveHandler)
                    }
                    ref={(e) => (ref.current = e)}
                    style={{
                        width: "100%",
                        height: "100%",
                        resize: "none",
                        background: "transparent",
                        fontSize: "1.4rem",
                        border: "none",
                        outline: "none",
                        overflow: "hidden",
                        textAlign: "center",
                    }}
                    value={text}
                    onChange={(e) => {
                        setText(e.target.value)
                    }}
                    onBlur={onBlurHandler}
                ></textarea>
            </div>
        </Rnd>
    )
}

export default DateComp
