import React from "react"

import "./tooltip.scss"

/**
 * ToolTip to show the text on any Icons
 *
 * Parameters
 *
 * @param {String} Direction ["top", "bottom", "right", "left"]
 * @param {String} Name
 * @param {String} Color
 * @param {String} background ["Default", "Primary",...]
 *
 */
const ToolTip = ({ direction, name, color, background }) => {
    return (
        <div
            className={`tooltip ${direction}`}
            style={{
                color: color || "#fff",
                background: background || "#33475B",
            }}
        >
            {name}
        </div>
    )
}

export default ToolTip
