import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
    verticalTracker: {
        position: "absolute",
        height: "100%",
        width: "200vw",
        top: 0,
        left: 0,
        transform: "translateX(-50%)",
        zIndex: 1,
        border: "1px dashed #7FD1DE",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
    },
    horizontalTracker: {
        position: "absolute",
        width: "100%",
        height: "200vh",
        top: 0,
        left: 0,
        transform: "translateY(-50%)",
        zIndex: 1,
        border: "1px dashed #7FD1DE",
        borderTopColor: "transparent",
        borderBottomColor: "transparent",
    },
}))

const SignDragPreview = ({ height, width }) => {
    const classes = useStyles()
    return (
        <div style={{ position: "relative", width, height }}>
            <div className={classes.verticalTracker}></div>
            <div className={classes.horizontalTracker}></div>
        </div>
    )
}

export default SignDragPreview
