import React, { useEffect, useState } from "react"
import { getAllDocuments } from "Action/documentAction"
import { connect } from "react-redux"
import PageLoading from "Awesome-ui/PageLoading/PageLoading"
import CreateFolder from "./Components/CreateFolder"
import { getAllFolders, setCurrentFolder } from "Action/foldersAction"
import RenameFolder from "./Components/RenameFolder"
import AddTeamMember from "../../Components/AddTeamMember/AddTeamMember"
import { Helmet } from "react-helmet"
import Guard from "Awesome-ui/Guard"
import StoreLeft from "./Components/StoreLeft/StoreLeft"
import { Switch, Route } from "react-router-dom"
import StoreRight from "./Components/StoreRight/StoreRight"
import StoreRightFolder from "./Components/StoreRight/StoreRightFolder"
import "./Storage.scss"
import { useHistory } from "react-router-dom"
import EditTeamMember from "Components/EditTeamMember/EditTeamMember"

const Storage = ({ folderList, isWorkspacePresent, currentFolderId }) => {
    const [selection, setselection] = useState({})
    const [isCreateFolder, setIsModal] = useState(false)
    const [isEditFolder, setEditFolder] = useState(false)
    const [isAddNewMember, addNewMemberModal] = useState(false)
    const [editMemberModal, setEditMemberModal] = useState(null)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        async function init() {
            setLoading(true)
            await getAllFolders()
            setLoading(false)
        }
        if (!folderList) init()
    }, [folderList, isWorkspacePresent])

    function selectFile(val, id) {
        setselection((prev) => {
            const newData = { ...prev }
            newData[id] = val
            return newData
        })
    }
    function selectGroupOfFile(files = [], val) {
        setselection(() => {
            const newData = {}
            files.forEach((file) => (newData[file.fileId] = val))
            return newData
        })
    }
    function clearSelection() {
        setselection({})
    }

    useEffect(() => {
        if (
            history.location.pathname.includes("/documents") &&
            !history.location.pathname.includes("/folder") &&
            !history.location.pathname.includes("/my-documents")
        ) {
            setCurrentFolder(-1)
        }
    }, [history.location.pathname])

    if (loading) return <PageLoading />

    return (
        <Guard scope="document_get">
            <div className="store-page">
                {isCreateFolder && <CreateFolder setShowModal={setIsModal} />}
                {isEditFolder && <RenameFolder setShowModal={setEditFolder} />}
                {isAddNewMember && (
                    <AddTeamMember setShowModal={addNewMemberModal} />
                )}
                {editMemberModal && (
                    <EditTeamMember
                        setShowModal={setEditMemberModal}
                        editingMember={editMemberModal}
                        currentFolderId={currentFolderId}
                    />
                )}
                <Helmet>
                    <title>Documents | Awesome Sign</title>
                </Helmet>
                <div className="store-page-left">
                    <StoreLeft showModalHandler={setIsModal} />
                </div>
                <Switch>
                    <Route path={"/documents/my-documents"} exact>
                        <StoreRight
                            selection={selection}
                            selectFile={selectFile}
                            setselection={selectGroupOfFile}
                            clearSelection={clearSelection}
                            myDocuments
                        />
                    </Route>
                    <Route path={"/documents/folder/:folderId"}>
                        <StoreRightFolder
                            setEditFolder={setEditFolder}
                            addNewMemberModal={addNewMemberModal}
                            selectFile={selectFile}
                            selection={selection}
                            setselection={selectGroupOfFile}
                            clearSelection={clearSelection}
                            setEditMemberModal={setEditMemberModal}
                        />
                    </Route>
                    <Route path={"/documents"} exact>
                        <StoreRight
                            selection={selection}
                            selectFile={selectFile}
                            setselection={selectGroupOfFile}
                            clearSelection={clearSelection}
                        />
                    </Route>
                </Switch>
            </div>
        </Guard>
    )
}

const mapStateToProps = ({
    documentReducer,
    foldersReducer,
    userReducer,
    workspaceReducer,
}) => {
    return {
        documents: documentReducer.documents,
        folderList: foldersReducer?.folderList,
        isWorkspacePresent: workspaceReducer?.workspace,
        userDetails: userReducer?.userDetails,
        currentFolderId: foldersReducer?.currentFolder,
    }
}

export default connect(mapStateToProps)(Storage)
