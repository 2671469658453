import React from "react"
import SideDrawer from "Awesome-ui/SideDrawer"
import "./UserSideBar.scss"
// import Avatar from "Awesome-ui/Avatar/Avatar"
import ButtonWithRadial from "Awesome-ui/ButtonWithRadial"

import { ReactComponent as GiftIcon } from "Assets/SideBar/giftbox.svg"
import { ReactComponent as AccountIcon } from "Assets/SideBar/account.svg"
import { ReactComponent as LogoutIcon } from "Assets/SideBar/logout.svg"
import DrawerElementWrapper from "Awesome-ui/DrawerElementWrapper"
import { Avatar, Text } from "@awesomesuite-frontend/awesome-nebula"
import { getLinkToService } from "data/ProductMapping"
import { Link } from "react-router-dom"

const sideBarOptions = [
    {
        name: "SideNav Lite",
        value: "slim",
    },
    {
        name: "SideNav",
        value: "named",
    },
    {
        name: "Top Nav",
        value: "NO_SIDEBAR",
    },
]

const themeOptions = [
    {
        name: "Sign Theme",
        value: "dark",
    },
    {
        name: "White Theme",
        value: "light",
    },
]

/**
 * user sidebar for the sign
 *
 * @param {*} param0
 * @returns
 */
const UserSideBar = ({
    showUserDropDown,
    setShowUserDropDown,
    userDetails,
    signOut,
    sideBarType,
    setSideBarType,
    sideBarColor,
    setSideBarColor,
    numberOfSigns,
}) => {
    const linkToAccount = getLinkToService("account")
    if (!showUserDropDown) return null

    return (
        <SideDrawer
            width="424px"
            maxWidth={"424px"}
            vis
            position="right"
            toggle={() => setShowUserDropDown((prev) => !prev)}
            toggleBtn={<div className="user-sideBar-cross">+</div>}
            className={"user-sideBar"}
        >
            <div className="drawer-top-bar">
                <Avatar
                    imageSource={userDetails && userDetails.picture}
                    name={userDetails.first_name}
                    isTooltip={false}
                />
                <div className="user-info">
                    <Text className="usr-name">
                        {userDetails &&
                            `${userDetails.first_name} ${userDetails.last_name}`}
                    </Text>
                    <Text className="usr-email">
                        {userDetails && userDetails.email_id}
                    </Text>
                    <a href={`${linkToAccount}/profile`}>
                        <Text
                            className="setting-button"
                            varient="subText"
                            color="link"
                        >
                            Edit Profile
                        </Text>
                    </a>
                </div>
            </div>
            <div className="usr-sidebar-body">
                <Text className="usr-side-bar-title">Personalize</Text>

                <DrawerElementWrapper title="Navigation Style">
                    {sideBarOptions.map((item) => (
                        <ButtonWithRadial
                            name={item.name}
                            active={item.value === sideBarType}
                            onClick={() => setSideBarType(item.value)}
                        />
                    ))}
                </DrawerElementWrapper>
                <DrawerElementWrapper title="Theme Style">
                    {themeOptions.map((item) => (
                        <ButtonWithRadial
                            name={item.name}
                            active={item.value === sideBarColor}
                            onClick={() => setSideBarColor(item.value)}
                        />
                    ))}
                </DrawerElementWrapper>
                {/* <div className="freePlan">
                    <div className="freePlanHeading">
                        <div className="freePlanMainText">Free Plan</div>
                        <div className="freePlanUpgrade">Upgrade</div>
                    </div>
                    <div className="freePlanText">{`${numberOfSigns} of 3 signs`}</div>
                    <div className="freePlanProgressBar" onClick={() => {}}>
                        <div
                            className="freePlanProgressBarThumb"
                            style={{ width: `${Math.ceil(numberOfSigns * 33.33)}%` }}
                        ></div>
                    </div>
                </div> */}
                {/* <div className="appication-note">
                    Application Level Note can be made for each app. So Just make a
                    component
                </div>
                <div className="application-offer">
                    <div>
                        <b>Get upto 2 months free</b>
                        <p>
                            Share your experience with Awesome Social and Upto 2
                            Months of subscription free.
                        </p>
                    </div>
                    <GiftIcon />
                </div> */}
            </div>
            <div className="usr-bottom-nav">
                <div
                    className="usr-button"
                    onClick={() =>
                        window.open(getLinkToService("account"), "_blank").focus()
                    }
                >
                    <AccountIcon />
                    <Text varient="subText">My Account</Text>
                </div>
                <Link to={"/login?logout=true"}>
                    <div className="usr-button" onClick={() => signOut(true, true)}>
                        <LogoutIcon />
                        <Text varient="subText">Logout</Text>
                    </div>
                </Link>
            </div>
        </SideDrawer>
    )
}

export default UserSideBar
