import React, { useRef, useState, useEffect } from "react"
import "../GIFPicker/UnsplashPicker.scss"

import { createApi } from "unsplash-js"
import Input from "Awesome-ui/Input/Input"
import Avatar from "@awesomesuite-frontend/awesome-nebula/dist/Avatar"
import Text from "@awesomesuite-frontend/awesome-nebula/dist/Text"
import { useContext } from "react"
import { ReactContext } from "Awesome-ui/MediaLibrary/MediaLibrary"
import Loader from "Awesome-ui/Loader/Loader"
import { ReactComponent as Image } from "../../Assets/image-icon.svg"
import { usePickerStyles } from "../GIFPicker/PickerStyle"
import { useStyles } from "Awesome-ui/MediaLibrary/MediaLibraryStyle"

const GetImage = ({ item }) => {
    const { handlingFiles, setHandlingFiles, singleFile, initFiles, styles } =
        useContext(ReactContext)
    const classes = useStyles(styles)
    const [isSelected, setIsSelected] = useState(
        handlingFiles.unsplash.filter((f) => f.id === item.id).length !== 0
    )

    const addFileToHandle = () => {
        if (!handlingFiles.unsplash.find((f) => f.id === item.id))
            if (singleFile)
                setHandlingFiles(() => ({ ...initFiles, unsplash: [item] }))
            else
                setHandlingFiles((prev) => ({
                    ...prev,
                    unsplash: [...prev.unsplash, item],
                }))
        else
            setHandlingFiles((prev) => ({
                ...prev,
                unsplash: prev.unsplash.filter((f) => f.id !== item.id),
            }))
    }
    useEffect(() => {
        if (handlingFiles.unsplash.find((f) => f.id === item.id)) setIsSelected(true)
        else setIsSelected(false)
    }, [handlingFiles.unsplash])

    return (
        <div
            className={`${classes.contentLibraryImage} ${
                classes.contentLibraryImageUnsplash
            } ${isSelected && classes.contentLibraryImageActive}`}
            onClick={addFileToHandle}
        >
            <div className={classes.contentLibraryImageContent}>
                <img
                    src={item.urls && item.urls.thumb}
                    alt={item.alt_description}
                    loading="lazy"
                />
            </div>
            <div
                className={`${classes.contentLibraryImageDescription} ${
                    isSelected && classes.contentLibraryImageDescriptionActive
                }`}
            >
                <Text
                    fontSize={13}
                    weight={600}
                    margin="0.25rem"
                    color={`${isSelected ? "#FFFFFF" : "#497696"}`}
                >
                    {item.alt_description}
                </Text>
                <div className={classes.fileDescription}>
                    <a
                        target="_blank"
                        href={`${item.user.links.html}?utm_source=AwesomeSuite&utm_medium=referral`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Avatar
                            imageSource={item?.user.profile_image.medium}
                            size="xs"
                        />
                        <Text
                            varient="subText"
                            fontSize={12}
                            marginLeft="0.25rem"
                            color={`${isSelected ? "#FFFFFF" : "#497696"}`}
                        >
                            {item.user.name}
                        </Text>
                    </a>
                    <div
                        className={`${classes.fileType} ${
                            isSelected && classes.fileTypeActive
                        }`}
                    >
                        <Text
                            fontSize={11}
                            color={`${isSelected ? "#5E7C98" : "white"}`}
                        >
                            {"img"}
                        </Text>
                    </div>
                    <Image
                        style={{ marginRight: "0.5rem" }}
                        fill={`${isSelected ? "#ffffff" : "#99acc2"}`}
                    />
                </div>
            </div>
        </div>
    )
}

/**
 * unsplash picker to get the images from the unsplash
 * @param {*} setCheckoutFile function when user checkout from
 * the unsplash after selecting the files
 * @returns
 */
const UnsplashPicker = ({ setCheckoutFiles }) => {
    const [pictures, setPictures] = useState({})
    const [searchers, setSearchers] = useState("")
    const loaderRef1 = useRef(null)
    const loaderRef2 = useRef(null)
    const loaderRef3 = useRef(null)
    const scrollRefs = [loaderRef1, loaderRef2, loaderRef3]
    const [loading, setLoading] = useState(false)
    const { handlingFiles } = useContext(ReactContext)

    const unsplashApi = createApi({
        accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
    })

    /**
     * infinity scroll handler for the for fetching new images when user scroller
     */
    function handleObserver(entries) {
        // console.log(entries);
        if (!entries) return
        const target = entries.every((e) => e.isIntersecting === false)
        if (!target && loading === false) {
            fetchOnScroll()
        }
        // eslint-disable-next-line
    }

    useEffect(() => {
        const option = {
            root: null,
            threshold: 1,
        }
        function init() {
            // console.log(scrollRefs);
            const observer = new IntersectionObserver(handleObserver, option)
            scrollRefs.map((e) => {
                if (e.current) observer.observe(e.current)
            })

            return () => {
                scrollRefs.map((e) => observer.disconnect(e.current))
            }
        }
        init()
    }, [...scrollRefs.map((r) => r.current)])

    /**
     * api handler for unsplash
     */
    async function fetchOnScroll() {
        if (unsplashApi && !loading) {
            setLoading(true)
            const searchObj = {
                count: 44,
            }
            if (searchers) {
                searchObj.query = searchers
            }
            try {
                const photo = await unsplashApi.photos.getRandom(searchObj)
                setPictures((prev) => {
                    const mapContent = photo.response.map((item) => {
                        item.selected = false
                        return item
                    })
                    let column = {}
                    if (!prev[0]) {
                        let res = [...handlingFiles.unsplash, ...mapContent]
                        column = {
                            0: res.slice(0, 4),
                            1: res.slice(4, 8),
                            2: res.slice(8, 12),
                        }
                    } else
                        column = {
                            0: prev[0].concat(mapContent.slice(0, 4)),
                            1: prev[1].concat(mapContent.slice(4, 8)),
                            2: prev[2].concat(mapContent.slice(8, 12)),
                        }
                    return column
                })
            } catch (error) {}
            setLoading(false)
        }
    }

    /**
     * handle the text search on button click
     * @param {*} name - name of search type
     */
    async function onPictureSearch(name) {
        const queryPost = await unsplashApi.photos.getRandom({
            query: name,
            count: 44,
        })
        if (queryPost.response) {
            const posts = queryPost.response.map((item) => {
                item.selected = false
                return item
            })
            const column = {
                0: posts.slice(0, 10),
                1: posts.slice(10, 20),
                2: posts.slice(20, 30),
            }
            setPictures(column)
        }
    }

    useEffect(() => {
        let tout = null
        if (searchers) {
            const search = () => onPictureSearch(searchers)
            tout = setTimeout(search, 500)
        }
        return () => clearTimeout(tout)
    }, [searchers])

    const pickerClasses = usePickerStyles()
    return (
        <>
            <div className={pickerClasses.mediaPicker}>
                <div className={pickerClasses.mediaContentWrapper}>
                    <div className={pickerClasses.mediaContent}>
                        <div className={pickerClasses.mediaSearch}>
                            <Input
                                id="media Serch"
                                placeHolder="Search free high-resolution picture"
                                type="text"
                                style={{ width: "80%", maxWidth: "80%" }}
                                onKeyHandler={() => onPictureSearch(searchers)}
                                changeHandler={(data) => setSearchers(data)}
                                icon="search"
                                iconColor="#00BBD8"
                                inputStyle={{ background: "#EFF3F6" }}
                            />
                        </div>
                        <div className={pickerClasses.imageContent}>
                            {/* {Object.keys(pictures).map((col) => ( */}
                            <div className={pickerClasses.imgCol}>
                                {pictures[0]?.map((item, index) => {
                                    return <GetImage item={item} />
                                })}

                                <div
                                    ref={(el) => (scrollRefs[0].current = el)}
                                    className={pickerClasses.loaderRef}
                                    style={{
                                        width: "100%",
                                        height: "8rem",
                                    }}
                                >
                                    {loading && (
                                        <div style={{ textAlign: "center" }}>
                                            <Loader
                                                style={{
                                                    margin: "auto",
                                                    height: 24,
                                                    width: 24,
                                                }}
                                            ></Loader>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={pickerClasses.imgCol}>
                                {pictures[1]?.map((item, index) => {
                                    return <GetImage item={item} />
                                })}

                                <div
                                    ref={(el) => (scrollRefs[1].current = el)}
                                    className={pickerClasses.loaderRef}
                                    style={{
                                        width: "100%",
                                        height: "8rem",
                                    }}
                                >
                                    {loading && (
                                        <div style={{ textAlign: "center" }}>
                                            <Loader
                                                style={{
                                                    margin: "auto",
                                                    height: 24,
                                                    width: 24,
                                                }}
                                            ></Loader>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={pickerClasses.imgCol}>
                                {pictures[2]?.map((item, index) => {
                                    return <GetImage item={item} />
                                })}

                                <div
                                    ref={(el) => (scrollRefs[2].current = el)}
                                    className={pickerClasses.loaderRef}
                                    style={{
                                        width: "100%",
                                        height: "8rem",
                                    }}
                                >
                                    {loading && (
                                        <div style={{ textAlign: "center" }}>
                                            <Loader
                                                style={{
                                                    margin: "auto",
                                                    height: 24,
                                                    width: 24,
                                                }}
                                            ></Loader>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UnsplashPicker
