import { log } from "@awesomesuite-frontend/awesome-nebula"
import {
    ADD_DOCUMENT_TO_FOLDER,
    ADD_NEW_FOLDER,
    ADD_NEW_TEAM_MEMBER,
    GET_FOLDERS_ALL,
    GET_FOLDER_BY_ID,
    GET_USER_BY_FOLDER_ID,
    SET_CURRENT_FOLDER,
    SET_FOLDERS_LOADED,
    SET_FOLDERS_LOADING,
} from "ActionTypes/foldersActionType"
import { eSignApi, workspaceApi } from "Apis/AllApisMapping"
import { store } from "../store"
import { getHeaders } from "./ActionHelper"
import { createMessage } from "./messageAndNotificationActions"
import {
    getFoldersByUserId,
    setWorkspaceUsers,
    updateBrandsInCurrentWorkspace,
} from "./workspaceAction"
import { DANGER, SUCCESS } from "Types"

const { dispatch, getState } = store

export const getFolderDataById = async (folderId) => {
    if (!folderId) return
    const userId = getState().userReducer?.userDetails?.id
    try {
        setFoldersLoading()
        const headers = getHeaders()
        const folderResponse = await eSignApi.get(
            `/allDocument?userId=${userId}&folderId=${folderId}`,
            headers
        )
        if (folderResponse.data.model) {
            dispatch({
                type: GET_FOLDER_BY_ID,
                payload: folderResponse.data.model,
            })
            return folderResponse.data.model
        } else throw new Error("Unable to get folders by id " + folderId)
    } catch (err) {
        setFoldersLoaded()
        console.log(err)
    }
}

export const getAllFolders = async (loading) => {
    const { workspace, currentWorkspace } = getState().workspaceReducer
    const { subscription } = getState().subscriptionReducer
    if (!workspace || workspace?.length === 0 || !subscription) {
        return
    }
    const { productId, organisationId } = subscription[0]
    const workspaceId = workspace[0]?.id
    let folderIds = ""
    if (currentWorkspace?.brands?.length > 0)
        folderIds = currentWorkspace?.brands?.map((brand) => brand.brandId).join(",")
    try {
        if (loading) setFoldersLoading()
        const headers = getHeaders()
        const folderResponse = await eSignApi.get(
            `/folder?workspaceId=${workspaceId}&product_id=${productId}&organisation_id=${organisationId}&folderId=${folderIds}`,
            headers
        )
        if (folderResponse.data.model) {
            dispatch({
                type: GET_FOLDERS_ALL,
                payload: folderResponse.data.model,
            })
        } else
            throw new Error(
                "Unable to get all folders of the workspace id " + workspaceId
            )
        return folderResponse.data.model
    } catch (err) {
        setFoldersLoaded()
        console.log(err)
    }
}

export const addNewFolder = async ({ folderName = "", icon = "" }) => {
    if (folderName.trim() === "")
        return createMessage("Folder Name is required", null, DANGER)
    if (folderName.trim().toLowerCase === "my documents")
        return createMessage("Folder Name cannot be My Documents", null, DANGER)
    const { workspace } = getState().workspaceReducer
    const { subscription } = getState().subscriptionReducer
    if (workspace?.length === 0 || !subscription) {
        return
    }
    const { productId, organisationId } = subscription[0]
    const workspaceId = workspace[0]?.id
    try {
        const headers = getHeaders()
        const data = {
            workspaceId,
            name: folderName,
            icon,
            productId,
            orgId: organisationId,
        }

        const newFolderAddResponse = await eSignApi.post(`/folder`, data, headers)

        if (newFolderAddResponse.data.model) {
            dispatch({
                type: ADD_NEW_FOLDER,
                payload: newFolderAddResponse.data.model,
            })
            await updateBrandsInCurrentWorkspace(
                newFolderAddResponse.data.model.id,
                "add",
                newFolderAddResponse.data.model
            )
        } else {
            throw new Error("Unable to Add New Folder")
        }
        return newFolderAddResponse.data.model
    } catch (err) {
        console.log(err)
    }
}

export const editFolder = async ({ folderId, folderName = "", icon = "" }) => {
    if (!folderId) return
    if (folderName.trim() === "")
        return createMessage("Folder Name is required", null, DANGER)
    if (folderName.trim().toLowerCase === "my documents")
        return createMessage("Folder Name cannot be My Documents", null, DANGER)
    try {
        const headers = getHeaders()
        const newFolderAddResponse = await eSignApi.put(
            `/folder?folderId=${folderId}&updatedName=${folderName}`,
            null,
            headers
        )
        if (newFolderAddResponse.data.model) {
            await updateBrandsInCurrentWorkspace(
                folderId,
                "edit",
                newFolderAddResponse.data.model
            )
            return newFolderAddResponse.data.model
        } else {
            throw new Error("Unable to Edit Folder")
        }
    } catch (err) {
        console.log(err)
        createMessage("Unable to Edit Folder", null, DANGER)
    }
}

export const addDocumentToFolder = async (folderId) => {
    const userId = getState().userReducer?.userDetails?.id
    if (!userId || !folderId) return
    try {
        const headers = getHeaders()
        const data = {
            id: userId,
            folderId,
        }
        setFoldersLoading()
        const newFolderAddResponse = await eSignApi.post(`/addFolder`, data, headers)
        if (newFolderAddResponse.data.model)
            dispatch({
                type: ADD_DOCUMENT_TO_FOLDER,
                payload: newFolderAddResponse.data.model,
            })
        else {
            throw new Error("Unable to Add Document to Folder")
        }
    } catch (err) {
        setFoldersLoaded()
        console.log(err)
    }
}

export const deleteFolderById = async (folderId) => {
    const currentFolder = getState().foldersReducer?.folderList?.find(
        (f) => f.id === folderId
    )
    if (!folderId || folderId === -1) return
    try {
        const headers = getHeaders()
        setFoldersLoading()
        const newFolderAddResponse = await eSignApi.delete(
            `/folder?folderId=${folderId}`,
            headers
        )
        if (newFolderAddResponse.data.statusCode === 200) {
            await updateBrandsInCurrentWorkspace(folderId, "delete")
            createMessage(
                "Successfully Deleted Folder",
                `${currentFolder.name} folder is successfully deleted`,
                SUCCESS
            )
            return true
        } else {
            throw new Error("Unable to Delete Folder")
        }
    } catch (err) {
        setFoldersLoaded()
        log(err)
        createMessage("Unable to Delete Folder", null, DANGER)
    }
}

export const setFoldersLoading = () => dispatch({ type: SET_FOLDERS_LOADING })
export const setFoldersLoaded = () => dispatch({ type: SET_FOLDERS_LOADED })

export const setCurrentFolder = async (folderId, loadUsers = true) => {
    dispatch({ type: SET_CURRENT_FOLDER, payload: { folderId } })
    if (folderId === -1 || !loadUsers) return
    await getUserByFolderId(folderId)
}

export const getUserByFolderId = async (folderId, force = false) => {
    const { workspace } = getState().workspaceReducer
    if (!workspace || workspace?.length === 0 || !folderId) {
        return
    }
    const workspaceId = workspace[0]?.id

    const { folderList } = getState().foldersReducer
    if (!folderList) return
    const updatedFolder = folderList.find((folder) => folder.id === folderId)
    if (!force && updatedFolder && updatedFolder.users) return updatedFolder.users

    try {
        setFoldersLoading()
        const headers = getHeaders()
        const folderResponse = await workspaceApi.get(
            `/user?workspace_id=${workspaceId}&brand_id=${folderId}`,
            headers
        )
        if (folderResponse.data.model) {
            dispatch({
                type: GET_USER_BY_FOLDER_ID,
                payload: { users: folderResponse.data.model, folderId },
            })
        } else
            throw new Error(
                "Unable to get all users of the folder id " + workspaceId
            )
    } catch (err) {
        console.log(err)
    } finally {
        setFoldersLoaded()
    }
}

export const addTeamMember = async (memberData, loading = true) => {
    const { workspace, users } = getState().workspaceReducer
    if (!workspace || workspace?.length === 0) {
        return
    }
    const workspaceId = workspace[0]?.id
    const foldersReducer = getState().foldersReducer

    const currentFolderData = foldersReducer?.folderList?.find(
        (folder) => folder.id === memberData.folder_id
    )
    if (!currentFolderData) {
        createMessage("Some Error Occured", null, DANGER)
        return
    }

    if (!currentFolderData.users) {
        await getUserByFolderId(memberData.folder_id)
    }

    const alreadyExistingUser = currentFolderData.users.find(
        (user) => user.email_id === memberData.email_id
    )
    if (alreadyExistingUser) {
        createMessage("User Already Exists", null, DANGER)
        return alreadyExistingUser
    }

    const workspaceUsers = users || (await setWorkspaceUsers(false))

    const userAlreadyExistingInWorkspace = workspaceUsers.find(
        (user) => user.email_id === memberData.email_id
    )

    const data = { ...memberData, product_id: 2, workspace_id: workspaceId }
    let res = null
    try {
        if (loading) setFoldersLoading()
        const headers = getHeaders()
        const newMemberAddResponse = await workspaceApi.post(`/user`, data, headers)
        if (newMemberAddResponse.data.model) {
            dispatch({
                type: ADD_NEW_TEAM_MEMBER,
                payload: {
                    newUser: {
                        ...newMemberAddResponse.data.model,
                        status: userAlreadyExistingInWorkspace
                            ? userAlreadyExistingInWorkspace.status
                            : 0,
                    },
                    folderId: data.folder_id,
                },
            })
            res = newMemberAddResponse.data.model
        } else
            throw new Error(
                "Unable to get all users of the folder id " + data.folder_id
            )
    } catch (err) {
        console.log(err)
    } finally {
        setFoldersLoaded()
        return res
    }
}

export const editTeamMember = async (memberData, loading = true) => {
    const { workspace } = getState().workspaceReducer
    if (!workspace || workspace?.length === 0) {
        return
    }
    const workspaceId = workspace[0]?.id
    const foldersReducer = getState().foldersReducer

    const currentFolderData = foldersReducer?.folderList?.find(
        (folder) => folder.id === memberData.folder_id
    )
    if (!currentFolderData) {
        createMessage("Some Error Occured", null, DANGER)
        return
    }

    const data = { ...memberData, product_id: 2, workspace_id: workspaceId }
    let res = null
    try {
        if (loading) setFoldersLoading()
        const headers = getHeaders()
        const updatedUser = await workspaceApi.put(`/user`, data, headers)
        if (updatedUser.data.model) {
            await getUserByFolderId(memberData.folder_id, true)
            await getFoldersByUserId(memberData.id)
            res = updatedUser.data.model
        } else
            throw new Error(
                "Unable to get all users of the folder id " + data.folder_id
            )
    } catch (err) {
        console.log(err)
    } finally {
        setFoldersLoaded()
        return res
    }
}
