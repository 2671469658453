import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { SignatureDragType } from "Components/PDFCommons/container/Types"
import React from "react"
import { createUseStyles } from "react-jss"
import { connect } from "react-redux"

const useStyle = createUseStyles((theme) => ({
    wrapper: {
        position: "relative",
        width: "fit-content",
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    totalSigns: {
        width: 70,
        background: "#FF8F59",
        position: "absolute",
        top: 0,
        transition: "all 300ms",
        left: "-70px",
        // border: "1px solid #CCDFF2",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 34,
    },
    totalSignsSign: {
        background: "#00BDA5",
    },
    tag: {
        background: "#FF8F59",
        "&::before": {
            position: "absolute",
            content: `" "`,
            width: 0,
            height: 0,
            top: -0.75,
            // top: 0,
            right: "-34px",
            border: "17.75px solid transparent",
            borderLeft: "17.75px solid #FF8F59",
        },
    },
    done: {
        "&::before": {
            borderLeft: "17.75px solid #00BDA5",
        },
    },
}))

const SignatureItems = ({
    children,
    signature,
    pageIndex,
    pdfRefs,
    pageSize,
    view,
}) => {
    const classes = useStyle()

    return (
        <div
            className={classes.wrapper}
            style={{ width: pageSize.width, margin: "0 auto" }}
            ref={(el) => (pdfRefs.current[pageIndex] = el)}
        >
            {children}
            {signature &&
                signature[pageIndex] &&
                Object.values(signature[pageIndex]).map((signItem) => {
                    if (signItem.type !== SignatureDragType.SIGNER_FIELD)
                        return <></>
                    return (
                        <div
                            className={`${classes.totalSigns} ${
                                signItem.isSigned && classes.totalSignsSign
                            }`}
                            style={{
                                top: signItem.top + 10,
                            }}
                        >
                            <Text color="white" varient="subText">
                                {signItem.isSigned ? "Done" : "Sign"}
                            </Text>
                            <div
                                className={`${classes.tag}  ${
                                    signItem.isSigned && classes.done
                                }`}
                            ></div>
                        </div>
                    )
                })}
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => {
    const signatureFlat = Object.values(signReducer.signature).flat(1)

    return {
        signature: signReducer.signature,
        signed: signatureFlat.filter((item) => item.isSigned).length,
        total: signatureFlat.length,
        view: signReducer.documentDetails?.view,
    }
}

export default connect(mapStateToProps)(SignatureItems)
