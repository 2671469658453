import { createUseStyles } from "react-jss"
const useStyles = createUseStyles(() => ({
    auiPlan: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "#2d394c73",
        zIndex: 1000000000000,
    },

    auiPlanPopup: {
        position: "absolute",
        maxWidth: "1192px",
        width: "95%",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        animation: "grow 0.1s ease-in 1",
        backgroundColor: "white",
        borderRadius: "12px",
        display: "flex",
        overflow: "hidden",

        "@media (max-height: 750px)": {
            height: "80%",
            width: "75%",
        },
        "& div": {
            width: "100%",
        },
    },
    auiPlanTitle: {
        fontSize: "1.7rem",
        fontWeight: "800",
        margin: "2rem 0 1rem 0",
    },

    auiPlanLeft: {
        textAlign: "left",
        padding: "1rem 2rem 0 2rem",
        position: "relative",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            height: "8px",
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0",
            borderRadius: "20px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#b6b6b6",
            borderRadius: "20px",
        },
        "& a": {
            textDecoration: "none",
        },
    },

    auiPlanFeatures: {
        "& div": {
            margin: "0.25rem 0",
            "& span": {
                marginLeft: "0.5rem",
            },
        },
    },
    auiPlanContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        padding: "1.5rem 0",
    },
    auiPlanCard: {
        height: "94px",
        width: "220px",
        border: "1px solid #e9e9f0",
        boxShadow: "0 3px 6px #e9e9f0b2",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        borderRadius: "8px",
        cursor: "pointer",

        "& *": {
            textAlign: "center !important",
        },
    },

    auiPlanCardTag: {
        position: "absolute",
        left: "50%",
        top: 0,
        transform: "translate(-50%, -50%)",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& p": {
            width: "fit-content",
            backgroundColor: "#03b7f2",
            padding: "0.15rem 0.75rem",
            borderRadius: "6px",
        },
    },

    auiPlanCardActive: {
        borderColor: "#03b7f2",
    },

    auiPlanCardPrice: {
        fontSize: "14px",
        fontWeight: 600,
    },

    auiPlanCardSub: {
        fontSize: "11px",
        color: "#99acc2",
    },

    auiPlanUpgrade: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        backgroundColor: "#1BD0BF",
        color: "white",
        height: "3rem",
        fontSize: "1rem",
        fontWeight: 600,
        position: "sticky",
        bottom: "3rem",
    },

    auiPlanRight: {
        padding: "2rem 1rem 0 1rem",
        backgroundColor: "#DCD8FF",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "center",
        "@media (max-width: 900px)": {
            display: "none",
        },

        "& svg": {
            height: "80%",

            "@media (max-height: 750px)": {
                height: "90%",
            },

            "@media (max-height: 600px)": {
                height: "75%",
            },
            "@media (max-width: 900px)": {
                height: "50%",
                width: "80%",
            },
        },
    },

    auiPlanAllPlans: {
        cursor: "pointer",
        color: "#00a4bd",
        margin: "0 auto 0 auto",
        width: "100%",
        display: "block",
        textAlign: "center",
        position: "sticky",
        bottom: "0rem",
        backgroundColor: "white",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    auiPlanClose: {
        position: "absolute",
        top: "0rem",
        right: "1rem",
        fontSize: "2rem",
        fontWeight: 100,
        transform: "rotateZ(45deg)",
        width: "unset !important",
        cursor: "pointer",
        "&:hover": {
            fontWeight: 200,
        },
    },

    "@keyframes grow": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        },
    },
}))

export default useStyles
