import React, { useState, useEffect } from "react"
import { createUseStyles } from "react-jss"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import BoxPicker from "@awesomesuite-frontend/awesome-nebula/dist/BoxPicker"
import { ReactComponent as Logo } from "./box_blue.svg"
import { medialApi } from "Apis/AllApisMapping"
import Loader from "Awesome-ui/Loader/Loader"

const useStyles = createUseStyles((theme) => ({
    box: {
        padding: "5px 12px",
        borderRadius: 4,
        background: "#fff",
        width: "fit-content",
        border: "1px solid #d3d3d3",
        marginLeft: 12,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
    },
    icon: {
        display: "flex",
        alignItems: "center",
    },
}))

const BoxPickerButton = ({ setFile }) => {
    const clx = useStyles()
    const mediaHandlerService = new mediaHandler(medialApi)
    const [loading, setLoading] = useState(false)

    async function handleFile(selectedFile, token) {
        if (selectedFile.extension !== "pdf") return
        console.log(selectedFile)

        let data = await mediaHandlerService.getBase64(
            selectedFile.authenticated_download_url,
            token
        )

        return await mediaHandlerService.uploadMediaBlob(
            data,
            null,
            "application/pdf",
            selectedFile.name
        )
    }

    async function fileUploader(file) {
        if (!file.files || !file.files[0]) return
        console.log("just choose", file)
        setLoading(true)
        let uploadedFiles = await Promise.all(
            file.files.map(async (item) => await handleFile(item, file.token))
        )
        console.log(uploadedFiles)
        setLoading(false)
        setFile(uploadedFiles)
    }

    useEffect(() => {
        let search = window.location.search
        let params = new URLSearchParams(search)
        let box = localStorage.getItem("box-init")
        if (box && params.get("code")) {
            localStorage.setItem("box-init", params.get("code"))
            window.close()
        }
    }, [])

    return (
        <BoxPicker
            clientId={process.env.REACT_APP_BOX_CLIENT_ID}
            clientSecret={process.env.REACT_APP_BOX_CLIENT_SECRET}
            onChoose={fileUploader}
            onError={(e) => setLoading(false)}
            onClose={(e) => setLoading(false)}
            callbackUrl={window.location.origin+"/media"}
            width={750}
            height={500}
        >
            <div className={clx.box}>
                <Logo height={30} width={60} />
                {loading && (
                    <div style={{ position: "relative", marginLeft: 4 }}>
                        <Loader style={{ width: "16px", height: "16px" }} />
                    </div>
                )}
            </div>
        </BoxPicker>
    )
}

export default BoxPickerButton
