import { Text } from "@awesomesuite-frontend/awesome-nebula"
import GetUserRole from "Awesome-ui/GetUserRole"
import ProfileWithName from "Awesome-ui/ProfileWithName"
import ThreeDotOptionDropdown from "Awesome-ui/ThreeDotsDropDown"
import React, { useEffect, useState } from "react"
import { ReactComponent as RemoveUser } from "../../../../Assets/settings/remove-user.svg"
import { ReactComponent as Arrow } from "../../../../Assets/Dashboard/arrow-down.svg"
import {
    deleteUserFromFolder,
    deleteUserFromWorkspace,
    getFoldersByUserId,
    setWorkspaceUsers,
} from "Action/workspaceAction"
import UserFolderList from "./UserFolderList"
import Loader from "Awesome-ui/Loader/Loader"
import { getUserByFolderId } from "Action/foldersAction"
import Guard from "Awesome-ui/Guard"
import { showWarningPopup } from "Action/messageAndNotificationActions"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"

const User = ({
    user,
    users,
    allFolders,
    roles,
    selectedUsers,
    setSelectedUsers,
    getIcon,
    dropDownTwoContent = [],
    currentUserId,
    isAdminUser,
    isUserOwner,
}) => {
    let { id, user_name, picture, email_id } = user
    const [isDropdown, setDropdown] = useState(false)
    const [folderList, setFolderList] = useState(null)
    const [folderLoading, setFolderLoading] = useState(false)

    const deleteMemberFromFolderHandler = async (folderId) => {
        setFolderLoading(true)
        const res = await deleteUserFromFolder(id, folderId, false)
        if (res) await getFoldersByUserId(id)
        setFolderLoading(false)
        await getUserByFolderId(Number(folderId), true)
    }

    const deleteMemberFromWorkspaceHandler = async () => {
        const res = await deleteUserFromWorkspace(id, false)
        if (res) await setWorkspaceUsers(false)
    }

    const removeUserHandler = () => {
        showWarningPopup(
            "Delete this User?",
            "Are you sure you want to remove this user?",
            "Delete",
            deleteMemberFromWorkspaceHandler,
            "Cancel",
            () => {}
        )
    }

    function verifyAccessForEditingOrDeletingTeamMember() {
        const adminAccess = isAdminUser && currentUserId !== user.id
        const { role } = user
        const userRole = roles?.find((roleObj) => roleObj.roleName === role)
        const { roleId } = userRole
        if (roleId === 11) return isUserOwner && adminAccess
        return adminAccess
    }

    const canUserEditOrDeleteTeamMember = verifyAccessForEditingOrDeletingTeamMember()

    useEffect(() => {
        const init = async () => {
            let currentUser = users.find((user) => user.id === id)
            if (!currentUser) return
            if (!currentUser.folderList) {
                setFolderLoading(true)
                await getFoldersByUserId(id)
                setFolderLoading(false)
            } else
                setFolderList(() => {
                    let folderList = currentUser.folderList
                    let folders = folderList.map((e) => {
                        let folderId = e.brandId.brandId
                        const currentFolderDetail = allFolders.find(
                            (folder) => folder.id === folderId
                        )
                        if (currentFolderDetail)
                            return {
                                folder: currentFolderDetail,
                                roleId: e.roleId.id,
                                roleName: e.roleId.roleName,
                            }
                    })
                    folders = folders.filter((val) => val !== undefined)
                    return folders
                })
        }
        if (isDropdown === true) init()
    }, [isDropdown, users])

    return (
        <React.Fragment>
            <div
                className="react-table-row"
                style={{
                    backgroundColor: selectedUsers?.find((user) => user.id === id)
                        ? "#E5F5F8"
                        : "#fff",
                    borderColor: selectedUsers?.find((user) => user.id === id)
                        ? "#AFE1E9"
                        : "#DFE8EB",
                }}
            >
                <div
                    className="react-table-cell"
                    style={{
                        width: 5,
                        backgroundColor: selectedUsers.includes(id)
                            ? "#16BADB"
                            : "#fff",
                        alignSelf: "stretch",
                        padding: 0,
                    }}
                ></div>
                <div
                    className="react-table-cell"
                    style={{
                        width: "calc(5% - 5px)",
                    }}
                >
                    <div className="checkbox">
                        <div
                            className="box"
                            onClick={(e) => {
                                if (!selectedUsers.includes(id)) {
                                    setSelectedUsers([...selectedUsers, id])
                                } else
                                    setSelectedUsers(
                                        selectedUsers.filter(
                                            (selectUser) => selectUser !== id
                                        ) || []
                                    )
                                e.stopPropagation()
                            }}
                            style={{
                                color: !selectedUsers.includes(id)
                                    ? "white"
                                    : "black",
                                backgroundColor: selectedUsers.includes(id)
                                    ? "gray"
                                    : "#fff",
                            }}
                        >
                            {selectedUsers.includes(id) ? <>✔</> : ""}
                        </div>
                    </div>
                </div>
                <div
                    className="react-table-cell"
                    style={{
                        width: "35%",
                        textAlign: "left",
                        paddingLeft: "5%",
                    }}
                >
                    <ProfileWithName
                        title={user_name}
                        subText={email_id}
                        image={picture}
                        email={email_id}
                    />
                </div>
                <div
                    className="react-table-cell"
                    style={{
                        width: "15%",
                        textAlign: "left",
                    }}
                >
                    <TooltipWrapper
                        text={"User has not yet accepted the invitation"}
                        wrapperStyle={{ width: "fit-content", cursor: "pointer" }}
                    >
                        {user.status === 0 && (
                            <Text
                                color="#f5c26b"
                                marginLeft="0.5rem"
                                varient="subText"
                                style={{ width: "fit-content" }}
                            >
                                Pending
                            </Text>
                        )}
                    </TooltipWrapper>
                </div>
                <div
                    className="react-table-cell"
                    style={{ width: "30%" }}
                    align="center"
                >
                    <GetUserRole user={{ role: user.role }} roles={roles} />
                </div>
                {/* <div
                    className="react-table-cell"
                    style={{ width: "15%" }}
                    align="center"
                > */}
                {/* TODO: Get activity log */}
                {/* <Text>Today</Text> */}
                {/* </div> */}
                <div
                    className="react-table-cell"
                    style={{
                        width: "10%",
                        fill: "#d6dfe8",
                    }}
                    align="center"
                    onClick={(e) => e.stopPropagation()}
                >
                    <ThreeDotOptionDropdown
                        disabled={!canUserEditOrDeleteTeamMember}
                        options={[
                            {
                                name: "Remove",
                                onClick: removeUserHandler,
                                icon: <RemoveUser />,
                                style: { color: "#F2545B" },
                            },
                        ]}
                        enableIcon
                        idName="addIcons"
                    />
                </div>
                <div
                    className="react-table-cell"
                    style={{
                        width: "10%",
                    }}
                    align="center"
                >
                    <Guard scope="collaborate_get" show>
                        <div
                            style={{
                                transform: "rotate(180deg)",
                                cursor: "pointer",
                            }}
                            onClick={() => setDropdown(!isDropdown)}
                        >
                            <Arrow
                                className={`dropper dropper-${
                                    isDropdown ? "close" : "expand"
                                }`}
                                height="1.5rem"
                                width="1.5rem"
                            />
                        </div>
                    </Guard>
                </div>
            </div>
            {isDropdown && (
                <Guard scope="collaborate_get">
                    <div
                        className="react-table"
                        style={{
                            borderBottom:
                                folderList?.length > 0 && "1px solid #DFE8EB",
                        }}
                    >
                        {folderLoading ? (
                            <div
                                style={{
                                    width: "100%",
                                    minHeight: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderBottom:
                                        folderList?.length > 0 &&
                                        "1px solid #DFE8EB",
                                }}
                            >
                                <Loader
                                    h="1rem"
                                    w="1rem"
                                    b="6px"
                                    bk="#7fd1de"
                                    clr="#00c5e3"
                                    t="1s"
                                />
                            </div>
                        ) : (
                            folderList?.map((userFolder, key) => (
                                <UserFolderList
                                    key={key}
                                    user={user}
                                    currentUserId={currentUserId}
                                    userFolder={userFolder}
                                    getIcon={getIcon}
                                    roles={roles}
                                    deleteFolderHandler={
                                        deleteMemberFromFolderHandler
                                    }
                                    dropDownTwoContent={dropDownTwoContent}
                                    isAdminUser={isAdminUser}
                                    disabled={!canUserEditOrDeleteTeamMember}
                                />
                            ))
                        )}
                    </div>
                </Guard>
            )}
        </React.Fragment>
    )
}
export default User
