import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import { addDocument, addDocumentToS3 } from "Action/documentAction"
import { medialApi } from "Apis/AllApisMapping"
import Guard from "Awesome-ui/Guard"
import ThreeDotOptionDropdown from "Awesome-ui/ThreeDotsDropDown"
import usePdfHook from "hooks/PdfLib/PdfHook"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import React, { useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import AvatarGroup from "../AvatarGroup"
import { ReactComponent as Reload } from "Assets/Inbox/reload-icon.svg"
import { connect } from "react-redux"
import Loader from "Awesome-ui/Loader/Loader"
import { ReactComponent as Upload } from "Assets/Storage/upload.svg"
import {
    deleteUserFromFolder,
    getFoldersByUserId,
    setWorkspaceUsers,
} from "Action/workspaceAction"
import { getUserByFolderId, setFoldersLoading } from "Action/foldersAction"
import { createMessage } from "Action/messageAndNotificationActions"
import { DANGER } from "Types"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"

const StoreTop = ({
    logo,
    title,
    threeDotView,
    showModalHandler,
    userToken,
    currentFolder,
    addNewMemberModal,
    reloadHandler,
    users,
    roles,
    deleteFolderHandler = () => {},
    setEditMemberModal,
}) => {
    const inputfile = useRef()
    const [documentsUploading, setDocumentsUploading] = useState(false)
    const mediaController = new mediaHandler(medialApi)
    const history = useHistory()
    const { loadedLib, loadPdfLib, loadPdf } = usePdfHook()

    async function getNumberOfPages(pdfFile) {
        if (!loadedLib) {
            await loadPdfLib()
        }
        const res = await loadPdf(pdfFile)
        return res.numberOfPages
    }

    const deleteMemberFromFolderHandler = async (userId) => {
        setFoldersLoading(true)
        const res = await deleteUserFromFolder(userId, currentFolder, false)
        if (res) {
            await getUserByFolderId(Number(currentFolder), true)
            await setWorkspaceUsers(false)
        }
        setFoldersLoading(false)
    }

    const folderHandlingOptions = [
        {
            name: "Rename",
            icon: "",
            onClick: () => {
                showModalHandler(true)
            },
        },
        {
            name: "Delete",
            icon: "",
            onClick: () => {
                deleteFolderHandler(currentFolder)
            },
        },
    ]
    async function startDocumentSigning(fileDetails) {
        try {
            const response = await addDocument(
                fileDetails.name,
                fileDetails.mediaId,
                fileDetails.mediaPath,
                fileDetails.pages,
                currentFolder
            )
            if (response) {
                history.push(`/pdf-editor/${response.model.fileId}`)
            }
        } catch (error) {
            console.log(error)
        }
    }

    function validateFile(file) {
        if (file.type !== "application/pdf")
            return createMessage(
                "Document type not supported. Please upload a pdf document.",
                null,
                DANGER
            )
        if (file.size > 100 * 1024 * 1024)
            return createMessage(
                "Document size is too large.",
                "Please upload a document less than 100mb.",
                DANGER
            )
        return true
    }

    async function handleFiles(listFiles) {
        //Only single file upload supported for now
        const singleFile = listFiles[0]
        inputfile.current.value= null
        if (!validateFile(singleFile)) return

        setDocumentsUploading(true)
        const mediaResp = await addDocumentToS3(
            singleFile,
            singleFile.name,
            singleFile.size
        )
        const pages = await getNumberOfPages(mediaResp.mediaPath)
        await startDocumentSigning({
            ...mediaResp,
            name: singleFile.name,
            file: singleFile,
            preView: mediaResp.mediaPath,
            size: singleFile.size,
            pages,
        })
        setDocumentsUploading(false)
    }

    return (
        <div className="store-page-top">
            <div className="store-top-left">
                <div
                    className={`document-title-content ${
                        threeDotView && "document-title-content-toggler"
                    }`}
                >
                    {logo && <div style={{ fontSize: "30px" }}>{logo}</div>}
                    {title && (
                        <Text
                            varient="h5"
                            style={{
                                textTransform: "capitalize",
                                marginLeft: "0.5rem",
                            }}
                        >
                            {title}
                        </Text>
                    )}
                    <div
                        className="folder-options-toggler"
                        style={{
                            display:
                                threeDotView && title !== "My Documents"
                                    ? "block"
                                    : "none",
                        }}
                    >
                        <Guard scope="folder_settings_get">
                            <ThreeDotOptionDropdown
                                iconStyle="horizontal"
                                options={folderHandlingOptions}
                                dropdownWidth="10rem"
                            />
                        </Guard>
                    </div>
                </div>
                <TooltipWrapper text={"Refresh"} direction={"right"}>
                    <div className="documents-reload" onClick={reloadHandler}>
                        <Reload />
                    </div>
                </TooltipWrapper>
            </div>
            <div className="store-top-right">
                <Guard scope="document_create">
                    {/* <Button
                        height={"80%"}
                        className="create-button"
                        radius="6px"
                        onClick={() => {
                            if (documentsUploading) return
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Text fontSize={13} color="#fff">
                                Create Document
                            </Text>
                        </div>
                    </Button> */}
                    <TooltipWrapper
                        text={"Upload documents to this folder"}
                        direction={"bottom"}
                    >
                        <Button
                            height={"80%"}
                            className="upload-button"
                            radius="6px"
                            onClick={() => {
                                if (documentsUploading) return
                                inputfile.current.click()
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {documentsUploading && (
                                    <Loader
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginRight: "0.5rem",
                                        }}
                                    />
                                )}
                                <Upload />
                                <Text
                                    fontSize={13}
                                    color="#33475B"
                                    marginLeft="0.25rem"
                                >
                                    Upload
                                </Text>
                            </div>
                        </Button>
                    </TooltipWrapper>
                </Guard>
                <input
                    type="file"
                    onChange={(e) => handleFiles(e.target.files)}
                    hidden
                    multiple={false}
                    ref={inputfile}
                    accept="application/pdf"
                />
                {title !== "My Documents" && users && (
                    <div
                        style={{
                            // marginRight: "4rem",
                            display: "flex",
                            alignItems: "center",
                            width: 110,
                        }}
                    >
                        <Guard scope="collaborate_get">
                            <AvatarGroup
                                limit={2}
                                addNewAvatar={addNewMemberModal}
                                deleteAvatar={deleteMemberFromFolderHandler}
                                setEditMemberModal={setEditMemberModal}
                                data={users}
                                roles={roles}
                                fullCard
                                roleElement
                                editUsers
                            />
                        </Guard>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = ({ foldersReducer, userReducer, roleReducer }) => {
    return {
        folderList: foldersReducer?.folderList,
        currentFolder: foldersReducer?.currentFolder,
        userToken: userReducer?.token,
        roles: roleReducer.role,
    }
}

export default connect(mapStateToProps)(StoreTop)
