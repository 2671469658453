import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as OneDriveIcon } from "./onedrive.svg"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import { medialApi } from "Apis/AllApisMapping"
import Loader from "Awesome-ui/Loader/Loader"
import OneDrive from "@awesomesuite-frontend/awesome-nebula/dist/OneDrivePicker"

const useStyles = createUseStyles((theme) => ({
    oneDriveBox: {
        display: "block",
        width: "fit-content",
        background: "#fff",
        border: "1px solid #b9d3ec",
        padding: 6,
        borderRadius: 4,
        cursor: "pointer",
        marginLeft: 12
    },
    icon: {
        display: "flex",
        alignItems: "center",
    },
}))

const OneDrivePicker = ({ setFile }) => {
    const clx = useStyles()
    const mediaHandlerService = new mediaHandler(medialApi)
    const [loading, setLoading] = useState(false)

    async function handleFile(selectedFile) {
        if (!selectedFile.name.includes(".pdf")) return

        let data = await mediaHandlerService.getBase64(
            selectedFile["@microsoft.graph.downloadUrl"]
        )

        return await mediaHandlerService.uploadMediaBlob(
            data,
            null,
            "application/pdf",
            selectedFile.name
        )
    }

    async function fileUploader(file) {
        if (!file?.value || !file.value[0]) return
        setLoading(true)
        let uploadedFiles = await Promise.all(file.value.map(handleFile))
        console.log(uploadedFiles)
        setLoading(false)
        setFile(uploadedFiles)
    }

    return (
        <div className={clx.oneDriveBox}>
            <OneDrive
                onSuccess={fileUploader}
                onCancel={() => {}}
                clientID={process.env.REACT_APP_ONE_DRIVE}
            >
                <div className={clx.icon}>
                    <OneDriveIcon height={28} width={35} />
                    <span style={{ fontSize: 14, marginLeft: 4 }}>OneDrive</span>
                    {loading && (
                        <div style={{ position: "relative", marginLeft: 4 }}>
                            <Loader style={{ width: "16px", height: "16px" }} />
                        </div>
                    )}  
                </div>
            </OneDrive>
        </div>
    )
}

export default OneDrivePicker
