import React from "react"
import RoundedSymbol from "../RoundedSymbol/RoundedSymbol"
import "./NotificationContainer.scss"

/**
 * Notification Container Used In NotificationBars Component
 *
 * Notification Mmessages
 *
 *  @param {String} time
 *  @param {String} title
 *  @param {String} content
 *
 */

const NotificationContainer = ({ created_on, userId, content }) => {
    return (
        <div className="notification-container">
            <RoundedSymbol color="#BB5555" size="36" />
            <div className="notification-content">
                <h6>{userId && userId}</h6>
                <p style={{wordWrap: "break-word"}}>{content || "This is a notification"}</p>
                <p>{created_on && created_on}</p>
            </div>
        </div>
    )
}

export default NotificationContainer
