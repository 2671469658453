import React, { useEffect } from "react"
import "./SideBar.scss"
import { connect } from "react-redux"
import OptionSideNav from "Awesome-ui/OptionSideNav"

import { ReactComponent as Edit } from "Assets/PdfNavigation/edit.svg"
import { ReactComponent as Information } from "Assets/PdfNavigation/information.svg"
import { ReactComponent as Pulse } from "Assets/PdfNavigation/pulse.svg"
import { ReactComponent as ShareIcon } from "Assets/PdfNavigation/share.svg"
import { ReactComponent as RolesIcon } from "Assets/PdfNavigation/roles.svg"
import { ReactComponent as SignIcon } from "Assets/ToolsIcons/sign.svg"

import Info from "../Info/Info"
import Activity from "../Activity/Activity"
import EditPdf from "../EditPdf/EditPdf"
import {
    DRAFT,
    SEND_FOR_SIGNATURE,
    FINISHED,
    CANCELLED,
    TEMPLATE,
    DOCUMENT,
    PREDEFINED_TEMPLATE,
} from "Types/index"
import Flow from "../Flow/Flow"
import Guard from "Awesome-ui/Guard"
import { log } from "@awesomesuite-frontend/awesome-nebula"
import ShareDoc from "../ShareDoc/ShareDoc"
import UserSigns from "../UserSigns/UserSigns"
import { setActiveTab } from "Action/signAction"
import Roles from "../Roles/Roles"

let OPTIONS = [
    {
        id: 4,
        isDisabled: false,
        title: "Signs",
        Icon: SignIcon,
    },
    {
        id: 5,
        isDisabled: false,
        title: "Roles",
        Icon: RolesIcon,
    },
    {
        id: 0,
        isDisabled: false,
        title: "Edit",
        Icon: Edit,
    },
    {
        id: 1,
        isDisabled: false,
        title: "Activity",
        Icon: Pulse,
    },
    {
        id: 2,
        isDisabled: false,
        title: "Share",
        Icon: ShareIcon,
    },
    {
        id: 3,
        isDisabled: false,
        title: "Details",
        Icon: Information,
    },
]

const SideBar = ({
    state,
    status,
    eSignFlow,
    isSetSigning,
    setIsSetSigning,
    isDocForwarding,
    setIsDocForwarding,
    loading,
    activeTab,
    enableTemplateFlow,
    type,
}) => {
    const [optionState, setOptionsState] = React.useState(OPTIONS)
    const setActivePage = (id) => {
        setActiveTab(id)
    }

    useEffect(() => {
        setOptionsState((prev) => {
            const totalOptions = prev.map((item) => {
                switch (item.id) {
                    case 0:
                        item.isDisabled = eSignFlow !== SEND_FOR_SIGNATURE
                        break
                    case 1:
                        item.isDisabled =
                            eSignFlow !== SEND_FOR_SIGNATURE && status === DRAFT
                        break
                    case 4:
                        item.isDisabled = !eSignFlow
                        break
                    default:
                        item.isDisabled = false
                }
                return item
            })
            if (type === TEMPLATE) {
                setActivePage(5)
                return totalOptions.filter((item) => item.id === 5)
            }
            if (type === DOCUMENT && enableTemplateFlow) setActivePage(0)

            return totalOptions.filter((item) => item.id !== 5)
        })
    }, [eSignFlow, status, type])

    // handle Side nav
    function handleOptionClick(id) {
        if (optionState.find((item) => item.id === id)?.isDisabled) return

        if (id === activeTab) return setActivePage(-1)

        setActivePage(id)
    }

    const _C = "aui-pdf-manager-section"
    function GetComponent(state, option) {
        switch (option) {
            case 0:
                return (
                    <Guard scope="document_update">
                        <EditPdf
                            status={status}
                            isSetSigning={isSetSigning}
                            isDocForwarding={isDocForwarding}
                            setIsSetSigning={setIsSetSigning}
                            setIsDocForwarding={setIsDocForwarding}
                        />
                    </Guard>
                )
            case 4:
                return <UserSigns />
            case 5:
                return <Roles />
            case 1:
                return <Activity />
            case 2:
                return <ShareDoc />
            case 3:
                return <Info />
            default:
                return (
                    !eSignFlow &&
                    (loading ||
                    type !== DOCUMENT ||
                    status === FINISHED ||
                    status === CANCELLED ? (
                        <></>
                    ) : (
                        <Guard scope="document_update">
                            <Flow handleOptionClick={(id) => setActivePage(id)} />
                        </Guard>
                    ))
                )
        }
    }

    return (
        <div>
            <div className={_C} style={{ height: eSignFlow ? "87.6vh" : "92.1vh" }}>
                <div className={_C + "-wrapper"}>
                    <div className={_C + "-wrapper-component"}>
                        {GetComponent(state, activeTab)}
                    </div>
                    {type !== PREDEFINED_TEMPLATE && (
                        <OptionSideNav
                            options={optionState}
                            option={activeTab}
                            handleOptionClick={handleOptionClick}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ userReducer, signReducer }) => ({
    creator: userReducer.userDetails,
    eSignFlow: signReducer.flow,
    status: signReducer.status,
    finishDocumentLink: signReducer.finishDocumentLink,
    activeTab: signReducer.activeTab,
    enableTemplateFlow: signReducer.documentDetails?.enableTemplateFlow,
    type: signReducer.documentDetails?.type,
})

export default connect(mapStateToProps)(SideBar)
