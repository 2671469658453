import Guard from "Awesome-ui/Guard"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { createUseStyles } from "react-jss"
import { connect } from "react-redux"
// import "./InBox.scss"

import InboxLeft from "./InboxLeft/InboxLeft"
import InboxRight from "./InboxRight/InboxRight"
import { Route, Switch, useHistory } from "react-router-dom/cjs/react-router-dom"
import { DRAFT, FINISHED, OUT_FOR_SIGNATURE, RECIEVED_FOR_SIGNATURE } from "Types"

const useStyle = createUseStyles(() => ({
    eSignInbox: {
        display: "flex",
        minHeight: "inherit",
        height: "inherit",
        position: "relative",
        height: "92vh",
    },
}))

export const statusLinks = [
    {
        link: "draft",
        status: DRAFT,
    },
    {
        link: "recieved-for-signature",
        status: RECIEVED_FOR_SIGNATURE,
    },
    {
        link: "completed",
        status: FINISHED,
    },
    {
        link: "awaiting-signature",
        status: OUT_FOR_SIGNATURE,
    },
]

const InBox = ({
    userDetails,
    userToken,
    currentWorkspace,
}) => {
    const classes = useStyle()
    const history = useHistory()
    const [isInboxLeftToggle, setIsInboxLeftToggle] = useState(false)

    useEffect(() => {
        if (
            history.location.pathname.includes("/inbox") &&
            history.location.pathname.split("/").length === 2
        ) {
            history.push("/inbox/completed?page=1&size=10")
        }
    }, [history.location.pathname])

    if(!userDetails?.id || !currentWorkspace) return null

    return (
        <Guard scope="document_inbox_get">
            <div className={`${classes.eSignInbox}`}>
                <Helmet>
                    <title>Inbox | Awesome Sign</title>
                </Helmet>
                <InboxLeft
                    isToggle={isInboxLeftToggle}
                    setIsToggle={setIsInboxLeftToggle}
                />
                <Switch>
                    <Route path="/inbox/:documentStatus">
                        <InboxRight
                            isToggle={isInboxLeftToggle}
                            userDetails={userDetails}
                            userToken={userToken}
                        />
                    </Route>
                </Switch>
            </div>
        </Guard>
    )
}

const mapStateToProps = ({ userReducer, workspaceReducer }) => ({
    userDetails: userReducer?.userDetails,
    userToken: userReducer?.token,
    currentWorkspace: workspaceReducer?.currentWorkspace,
})

export default connect(mapStateToProps)(InBox)
