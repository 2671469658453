import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as TurnedOnIcon } from "Assets/ToolsIcons/ActivityStatus/done.svg"

const useStyle = createUseStyles(() => ({
    toggleButton: {
        minWidth: "50px",
        backgroundColor: "#EAF0F6",
        minHeight: "25px",
        borderRadius: "15px",
        position: "relative",
        cursor: "pointer",
        border: "1px solid #A5B6C9",
        marginTop: ".25rem",
        display: "flex",
        alignItems: "center",
    },
    toggleButtonActive: {
        backgroundColor: "#1FC78F",
        border: "1px solid #1FC78F",
    },
    toggleButtonText: {
        height: "100%",
        marginLeft: 25,
        marginRight: 0,
        display: "flex",
        alignItems: "center",
    },
    toggleButtonTextActive: {
        marginLeft: 0,
        marginRight: 25,
        paddingRight: 0,
    },
    toggleButtonSwitch: {
        position: "absolute",
        height: "95%",
        aspectRatio: "1",
        backgroundColor: "white",
        borderRadius: "50%",
        left: 1,
        top: "50%",
        transform: "translateY(-50%)",
        border: "1px solid #A5B6C9",
        transition: "left 0.3s ease-in-out",
        "& svg": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            "& circle":{
                fill: "#fff",
            },
            "& path#_Icon-Color":{
                fill: "#1FC78F",
            }
        }
    },
    toggleButtonSwitchActive: {
        left: "calc(100% - 22.75px)",
        border: "1px solid transparent",
    },
}))

/**
 * @param {boolean} isChecked = true (default) | false
 * @param {Function} setIsChecked = isChecked value handler
 * @param {object} style = any style (optional)
 * @param {*} props = other props (optional)
 * @returns
 */

const ToggleButton = ({
    isChecked = true,
    setIsChecked = () => {},
    style,
    isDisabled,
    checkedText = "",
    uncheckedText = "",
    ...props
}) => {
    const classes = useStyle()
    const toggleBtn = () => {
        if (isDisabled) return
        setIsChecked(!isChecked)
    }
    return (
        <div
            className={`${classes.toggleButton} ${
                isChecked && classes.toggleButtonActive
            }`}
            style={{
                opacity: isDisabled ? 0.75 : 1,
                cursor: isDisabled ? "not-allowed" : "pointer",
                ...style,
            }}
            onClick={toggleBtn}
            {...props}
        >
            {checkedText && uncheckedText && (
                <div
                    className={`${classes.toggleButtonText} ${
                        isChecked && classes.toggleButtonTextActive
                    }`}
                >
                    <Text
                        color={isChecked ? "#fff" : "#A5B6C9"}
                        align="center"
                        fontSize={13}
                        padding={
                            isChecked ? "0 0.25rem 0 0.5rem" : "0 0.5rem 0 0.25rem"
                        }
                    >
                        {isChecked ? checkedText : uncheckedText}
                    </Text>
                </div>
            )}
            <div
                className={`${classes.toggleButtonSwitch} ${
                    isChecked && classes.toggleButtonSwitchActive
                }`}
            >
                {isChecked ? <TurnedOnIcon /> : <></>}
            </div>
        </div>
    )
}

export default ToggleButton
