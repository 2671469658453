import React from 'react';
import "./TableCell.scss"

const TableCell = ({align, width,colSpan,style, ...props}) => {
    return (
        <td className="tableCell" colSpan={colSpan} align={align} style={{width: width, ...style}}>
            {props.children}
        </td>
    )
}

export default TableCell;
