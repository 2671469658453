import React from "react"
import { createUseStyles } from "react-jss"
import { Avatar, Text, Tooltip } from "@awesomesuite-frontend/awesome-nebula"
import Guard from "Awesome-ui/Guard"
import GetUserRole from "Awesome-ui/GetUserRole"
import RoleHandler from "./RoleHandler"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import { ReactComponent as AddIcon } from "Assets/settings/add-user.svg"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"

const useStyles = createUseStyles({
    avatarGroupContainer: (props) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: 15,
    }),
    avatarGroupGridContainer: (props) => ({
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gridColumnGap: 10,
        gridRowGap: 10,
    }),
    avatarGroupItem: (props) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: -12,
    }),
    avatarGroupItemEdit: (props) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: -10,
    }),
    addAvatarGroupItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: -10,
        height: 30,
        aspectRatio: 1,
        borderRadius: "50%",
        backgroundColor: "#F4F7F9",
        color: "#99acc2",
        zIndex: 200,
        border: "2px dashed #99acc2",
        transform: "rotate(45deg)",
        userSelect: "none",
        paddingLeft: "2px",
        paddingBottom: "2px",
        cursor: "pointer",
        fontSize: 14,
        "&:hover": {
            backgroundColor: "#EAF0F6",
        },
    },
})

const AvatarGroup = ({
    isAddNew = false,
    addNewAvatar = () => {},
    deleteAvatar = () => {},
    setEditMemberModal = () => {},
    roleElement,
    fullCard,
    roles,
    editUsers,
    currentFolder,
    ...props
}) => {
    const { splitText } = useBasicUtils()
    const classes = useStyles(props)
    return (
        <>
            {props.apperance === "stack" ? (
                <div className={classes.avatarGroupContainer}>
                    {props.data.slice(0, props.limit).map((item, index) => (
                        <div key={index} className={classes.avatarGroupItem}>
                            <Avatar
                                imageSource={item.imageSource}
                                name={item.name}
                                border="none"
                                email={splitText(item.email, 20)}
                                isTooltip
                                statusHighlightColor={item.statusHighlightColor}
                                statusElement={item.statusElement}
                                statusDotVisible={false}
                                size="xs"
                                fullCard={fullCard}
                                noStatusCircleOffset={props.noStatusCircleOffset}
                                roleElement={
                                    roleElement && (
                                        <GetUserRole
                                            user={item}
                                            roles={roles}
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                padding: "0.25rem 1rem",
                                                marginTop: "0.5rem",
                                            }}
                                        />
                                    )
                                }
                            />
                        </div>
                    ))}
                    {props.data.length - props.limit > 0 && (
                        <div className={classes.avatarGroupItem}>
                            <Avatar border="white" bgColor="#A5B6C9">
                                <Text varient="subText">
                                    {`+${props.data.length - props.limit}`}
                                </Text>
                            </Avatar>
                        </div>
                    )}
                    {editUsers && (
                        <RoleHandler
                            users={props.data}
                            addNewAvatar={addNewAvatar}
                            deleteAvatar={deleteAvatar}
                            setEditMemberModal={setEditMemberModal}
                        >
                            <div className={classes.avatarGroupItemEdit}>
                                <TooltipWrapper text={"Add User"}>
                                    <Avatar border="#A5B6C9" bgColor="#FFFFFF">
                                        <AddIcon />
                                    </Avatar>
                                </TooltipWrapper>
                            </div>
                        </RoleHandler>
                    )}
                    {isAddNew && (
                        <Guard scope="collaborate_create">
                            <div
                                className={classes.addAvatarGroupItem}
                                onClick={addNewAvatar}
                            >
                                ✖
                            </div>
                        </Guard>
                    )}
                </div>
            ) : (
                <div className={classes.avatarGroupGridContainer}>
                    {props.data.slice(0, props.limit).map((item, index) => (
                        <Tooltip
                            key={index}
                            onHoverText={item.name}
                            position="bottom"
                        >
                            <Avatar imageSource={item.imageSource} />
                        </Tooltip>
                    ))}
                    <Avatar bgColor="#A5B6C9">
                        +{props.data.length - props.limit}
                    </Avatar>
                </div>
            )}
        </>
    )
}

AvatarGroup.defaultProps = {
    apperance: "stack",
    data: [
        {
            imageSource:
                "https://pbs.twimg.com/profile_images/1503591435324563456/foUrqiEw_400x400.jpg",
            name: "Elon Musk",
        },
        {
            imageSource:
                "https://pbs.twimg.com/profile_images/669103856106668033/UF3cgUk4_400x400.jpg",
            name: "Jeff Bezoz",
        },
        {
            imageSource:
                "https://pbs.twimg.com/profile_images/1189494401359208448/AwbXHtpn_400x400.jpg",
            name: "Ratan Tata",
        },
        {
            imageSource:
                "https://pbs.twimg.com/profile_images/1393451192261304320/bKUpXvlc_400x400.jpg",
            name: "Virat Kohli",
        },
    ],
    limit: 4,
}

export default AvatarGroup
