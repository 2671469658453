import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { createUseStyles } from "react-jss"
import { setESignFlow } from "../../../../Action/signAction"
import { SIGN_NOW, SEND_FOR_SIGNATURE } from "../../../../Types"
import { ReactComponent as SignNowIcon } from "Assets/PdfNavigation/Sign-now.svg"
import { ReactComponent as SendForSignIcon } from "Assets/PdfNavigation/Send-for-signature.svg"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import { connect } from "react-redux"

const useStyles = createUseStyles(() => ({
    signFlowButton: {
        margin: "1rem auto",
        width: "170px",
        minHeight: "180px",
        borderRadius: "12px",
        boxShadow: "0px 3px 6px #d6dfe8",
        border: "1px solid #DFE3EB",

        "& svg": {
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            margin: "1rem 0",
        },
        position: "relative",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",

        "&:hover": {
            border: "1px solid #00A4BD",
            backgroundColor: "#E5F5F8",
        },
    },
}))

const Flow = ({ handleOptionClick, enableTemplateFlow }) => {
    const classes = useStyles()
    return (
        <div className="e-sign-flow-component">
            <Text
                varient="h6"
                weight={600}
                margin="1rem"
                marginBottom="2rem"
                align="center"
            >
                Choose an Action
            </Text>
            {!enableTemplateFlow && (
                <TooltipWrapper
                    wrapperClassName={`${classes.signFlowButton}`}
                    onClick={() => {
                        setESignFlow(SIGN_NOW)
                        handleOptionClick(4)
                    }}
                    text={"Self sign now"}
                >
                    <SignNowIcon />
                    <Text fontSize={16} weight={600} align="center">
                        Sign Now
                    </Text>
                </TooltipWrapper>
            )}
            <TooltipWrapper
                wrapperClassName={`${classes.signFlowButton}`}
                onClick={() => {
                    setESignFlow(SEND_FOR_SIGNATURE)
                    handleOptionClick(0)
                }}
                text={"Request signature"}
            >
                <SendForSignIcon />
                <Text fontSize={16} weight={600} align="center">
                    Send for Signature
                </Text>
            </TooltipWrapper>
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    enableTemplateFlow: signReducer.documentDetails?.enableTemplateFlow,
})

export default connect(mapStateToProps)(Flow)
