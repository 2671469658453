import React from "react"
import { SignatureDragType, TemplateElementsDragType } from "../../container/Types"
import Sign from "../DragElements/Sign/Sign"
import CheckBox from "../DragElements/ChcekBox/CheckBox"
import TextField from "../DragElements/TextField/TextField"
import DateComp from "../DragElements/Date/DateComp"
import { connect } from "react-redux"
import Type from "../DragElements/Type/Type"
import Image from "../DragElements/Image/Image"
import SignerField from "../DragElements/SignerField/SignerField"
import {
    DOCUMENT,
    FINISHED,
    FORM,
    OUT_FOR_SIGNATURE,
    SIGNER,
    TEMPLATE,
    USER,
} from "Types"
import { TEMPLATE_ITEM } from "Pages/PdfEditor/container/Constants"
import TemplateText from "../DragElements/TemplateDragItems/TemplateText/TemplateText"
import TemplateSignature from "../DragElements/TemplateDragItems/TemplateSignature/TemplateSignature"
import TemplateDate from "../DragElements/TemplateDragItems/TemplateDate/TemplateDate"
import TemplateCheckbox from "../DragElements/TemplateDragItems/TemplateCheckbox/TemplateCheckbox"

/**
 * it is to be used with the pdf canvas or it is a sub layer of
 * the canvas which will handle all the dragging with the limited border.
 * @param {*} { getClientPos, name }
 * @returns
 */
const DragItemCatcher = ({
    pageIndex,
    getClientPos,
    name,
    signature,
    setIsMouseOverElement,
    disable,
    editingMode,
    status,
    pageSize,
    typeOfSignature,
    signers,
    view,
    enableTemplateFlow,
    roles,
    type,
}) => {
    if (status === FINISHED) {
        return null
    }

    return (
        <div
            style={{
                width: pageSize.width,
                height: pageSize.height,
                position: "absolute",
                top: "0",
                left: "0",
                bottom: "0",
                right: "0",
            }}
        >
            <div
                className={name}
                style={{
                    position: "relative",
                    width: pageSize.width,
                    height: pageSize.height,
                }}
            >
                {signature &&
                    Object.keys(signature).map((key) => {
                        if (enableTemplateFlow && signature[key].isTemplateItem) {
                            const signatureRoleId =
                                signature[key].roleId || signature[key].data?.roleId
                            const signatureRole = roles?.find(
                                (role) => role.id === signatureRoleId
                            )
                            switch (signature[key].type) {
                                case TemplateElementsDragType.NUMBER:
                                    return (
                                        <TemplateText
                                            {...signature[key]}
                                            mode={editingMode}
                                            id={key}
                                            getPagePosition={getClientPos}
                                            pageIndex={pageIndex}
                                            xDefault={signature[key].left}
                                            yDefault={signature[key].top}
                                            key={key}
                                            onMouseOver={() =>
                                                setIsMouseOverElement(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsMouseOverElement(false)
                                            }
                                            disable={
                                                disable ||
                                                (enableTemplateFlow &&
                                                    type !== TEMPLATE)
                                            }
                                            textEditDisable={
                                                editingMode === USER ||
                                                status !== OUT_FOR_SIGNATURE ||
                                                view === FORM
                                            }
                                            signatureRole={signatureRole}
                                        />
                                    )
                                case TemplateElementsDragType.TEXT:
                                    return (
                                        <TemplateText
                                            {...signature[key]}
                                            mode={editingMode}
                                            id={key}
                                            getPagePosition={getClientPos}
                                            pageIndex={pageIndex}
                                            xDefault={signature[key].left}
                                            yDefault={signature[key].top}
                                            key={key}
                                            onMouseOver={() =>
                                                setIsMouseOverElement(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsMouseOverElement(false)
                                            }
                                            disable={
                                                disable ||
                                                (enableTemplateFlow &&
                                                    type !== TEMPLATE)
                                            }
                                            textEditDisable={
                                                editingMode === USER ||
                                                status !== OUT_FOR_SIGNATURE ||
                                                view === FORM
                                            }
                                            signatureRole={signatureRole}
                                        />
                                    )
                                case TemplateElementsDragType.SIGNER_FIELD:
                                    return (
                                        <TemplateSignature
                                            mode={editingMode}
                                            {...signature[key]}
                                            id={key}
                                            data={signature[key].data}
                                            signature={signature[key].mediaPath}
                                            getPagePosition={getClientPos}
                                            pageIndex={pageIndex}
                                            xDefault={signature[key].left}
                                            yDefault={signature[key].top}
                                            key={key}
                                            onMouseOver={() =>
                                                setIsMouseOverElement(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsMouseOverElement(false)
                                            }
                                            disable={
                                                disable ||
                                                (enableTemplateFlow &&
                                                    type !== TEMPLATE)
                                            }
                                            status={status}
                                            typeOfSignature={typeOfSignature}
                                            roles={roles}
                                            signatureRole={signatureRole}
                                        />
                                    )
                                case TemplateElementsDragType.DATE:
                                    return (
                                        <TemplateDate
                                            {...signature[key]}
                                            mode={editingMode}
                                            id={key}
                                            getPagePosition={getClientPos}
                                            pageIndex={pageIndex}
                                            xDefault={signature[key].left}
                                            yDefault={signature[key].top}
                                            key={key}
                                            onMouseOver={() =>
                                                setIsMouseOverElement(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsMouseOverElement(false)
                                            }
                                            disable={
                                                disable ||
                                                (enableTemplateFlow &&
                                                    type !== TEMPLATE)
                                            }
                                            dateSetupDisable={
                                                editingMode === USER ||
                                                status !== OUT_FOR_SIGNATURE
                                            }
                                            signatureRole={signatureRole}
                                        />
                                    )
                                case TemplateElementsDragType.CHECKBOX:
                                    return (
                                        <TemplateCheckbox
                                            {...signature[key]}
                                            mode={editingMode}
                                            id={key}
                                            getPagePosition={getClientPos}
                                            pageIndex={pageIndex}
                                            xDefault={signature[key].left}
                                            yDefault={signature[key].top}
                                            key={key}
                                            onMouseOver={() =>
                                                setIsMouseOverElement(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsMouseOverElement(false)
                                            }
                                            disable={
                                                disable ||
                                                (enableTemplateFlow &&
                                                    type !== TEMPLATE)
                                            }
                                            checkBoxFillDisabled={
                                                editingMode === USER ||
                                                status !== OUT_FOR_SIGNATURE
                                            }
                                            signatureRole={signatureRole}
                                        />
                                    )
                            }
                        }
                        switch (signature[key].type) {
                            case SignatureDragType.SIGN:
                                return (
                                    <Sign
                                        mode={editingMode}
                                        disable={disable}
                                        id={key}
                                        getPagePosition={getClientPos}
                                        pageIndex={pageIndex}
                                        signature={signature[key].mediaPath}
                                        xDefault={signature[key].left}
                                        yDefault={signature[key].top}
                                        key={key}
                                        onMouseOver={() =>
                                            setIsMouseOverElement(true)
                                        }
                                        onMouseLeave={() =>
                                            setIsMouseOverElement(false)
                                        }
                                    />
                                )
                            case SignatureDragType.CHECKBOX:
                                if (signature[key].data?.signerId)
                                    return (
                                        <TemplateCheckbox
                                            {...signature[key]}
                                            mode={editingMode}
                                            id={key}
                                            getPagePosition={getClientPos}
                                            pageIndex={pageIndex}
                                            xDefault={signature[key].left}
                                            yDefault={signature[key].top}
                                            key={key}
                                            onMouseOver={() =>
                                                setIsMouseOverElement(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsMouseOverElement(false)
                                            }
                                            disable={disable}
                                            checkBoxFillDisabled={
                                                editingMode === USER ||
                                                status !== OUT_FOR_SIGNATURE
                                            }
                                            signatureRole={signers?.find(
                                                (signer) =>
                                                    signer.id ===
                                                    signature[key].data?.signerId
                                            )}
                                        />
                                    )
                                return (
                                    <CheckBox
                                        mode={editingMode}
                                        id={key}
                                        getPagePosition={getClientPos}
                                        pageIndex={pageIndex}
                                        xDefault={signature[key].left}
                                        yDefault={signature[key].top}
                                        key={key}
                                        onMouseOver={() =>
                                            setIsMouseOverElement(true)
                                        }
                                        onMouseLeave={() =>
                                            setIsMouseOverElement(false)
                                        }
                                        disable={disable}
                                    />
                                )
                            case SignatureDragType.TEXT:
                                if (signature[key].data?.signerId)
                                    return (
                                        <TemplateText
                                            {...signature[key]}
                                            mode={editingMode}
                                            id={key}
                                            getPagePosition={getClientPos}
                                            pageIndex={pageIndex}
                                            xDefault={signature[key].left}
                                            yDefault={signature[key].top}
                                            key={key}
                                            onMouseOver={() =>
                                                setIsMouseOverElement(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsMouseOverElement(false)
                                            }
                                            disable={disable}
                                            textEditDisable={
                                                editingMode === USER ||
                                                status !== OUT_FOR_SIGNATURE
                                            }
                                            signatureRole={signers?.find(
                                                (signer) =>
                                                    signer.id ===
                                                    signature[key].data?.signerId
                                            )}
                                        />
                                    )

                                return (
                                    <TextField
                                        mode={editingMode}
                                        {...signature[key]}
                                        id={key}
                                        getPagePosition={getClientPos}
                                        pageIndex={pageIndex}
                                        xDefault={signature[key].left}
                                        yDefault={signature[key].top}
                                        key={key}
                                        onMouseOver={() =>
                                            setIsMouseOverElement(true)
                                        }
                                        onMouseLeave={() =>
                                            setIsMouseOverElement(false)
                                        }
                                        disable={disable}
                                    />
                                )
                            case SignatureDragType.DATE:
                                if (signature[key].data?.signerId)
                                    return (
                                        <TemplateDate
                                            {...signature[key]}
                                            mode={editingMode}
                                            id={key}
                                            getPagePosition={getClientPos}
                                            pageIndex={pageIndex}
                                            xDefault={signature[key].left}
                                            yDefault={signature[key].top}
                                            key={key}
                                            onMouseOver={() =>
                                                setIsMouseOverElement(true)
                                            }
                                            onMouseLeave={() =>
                                                setIsMouseOverElement(false)
                                            }
                                            disable={disable}
                                            dateSetupDisable={
                                                editingMode === USER ||
                                                status !== OUT_FOR_SIGNATURE
                                            }
                                            signatureRole={signers?.find(
                                                (signer) =>
                                                    signer.id ===
                                                    signature[key].data?.signerId
                                            )}
                                        />
                                    )
                                return (
                                    <DateComp
                                        mode={editingMode}
                                        {...signature[key]}
                                        id={key}
                                        getPagePosition={getClientPos}
                                        pageIndex={pageIndex}
                                        xDefault={signature[key].left}
                                        yDefault={signature[key].top}
                                        key={key}
                                        onMouseOver={() =>
                                            setIsMouseOverElement(true)
                                        }
                                        onMouseLeave={() =>
                                            setIsMouseOverElement(false)
                                        }
                                        disable={disable}
                                    />
                                )
                            case SignatureDragType.TYPE:
                                return (
                                    <Type
                                        mode={editingMode}
                                        {...signature[key]}
                                        id={key}
                                        signature={signature[key].mediaPath}
                                        getPagePosition={getClientPos}
                                        pageIndex={pageIndex}
                                        xDefault={signature[key].left}
                                        yDefault={signature[key].top}
                                        key={key}
                                        onMouseOver={() =>
                                            setIsMouseOverElement(true)
                                        }
                                        onMouseLeave={() =>
                                            setIsMouseOverElement(false)
                                        }
                                        disable={disable}
                                    />
                                )
                            case SignatureDragType.IMAGE:
                                return (
                                    <Image
                                        mode={editingMode}
                                        {...signature[key]}
                                        id={key}
                                        signature={signature[key].mediaPath}
                                        getPagePosition={getClientPos}
                                        pageIndex={pageIndex}
                                        xDefault={signature[key].left}
                                        yDefault={signature[key].top}
                                        key={key}
                                        onMouseOver={() =>
                                            setIsMouseOverElement(true)
                                        }
                                        onMouseLeave={() =>
                                            setIsMouseOverElement(false)
                                        }
                                        disable={disable}
                                    />
                                )
                            case SignatureDragType.SIGNER_FIELD:
                                return (
                                    <SignerField
                                        mode={editingMode}
                                        {...signature[key]}
                                        id={key}
                                        data={signature[key].data}
                                        signature={signature[key].mediaPath}
                                        getPagePosition={getClientPos}
                                        pageIndex={pageIndex}
                                        xDefault={signature[key].left}
                                        yDefault={signature[key].top}
                                        key={key}
                                        onMouseOver={() =>
                                            setIsMouseOverElement(true)
                                        }
                                        onMouseLeave={() =>
                                            setIsMouseOverElement(false)
                                        }
                                        disable={disable}
                                        status={status}
                                        typeOfSignature={typeOfSignature}
                                        signers={signers}
                                    />
                                )
                            default:
                                return null
                        }
                    })}
            </div>
        </div>
    )
}

const mapStateToProps = ({ signReducer }, { pageIndex }) => ({
    signature: signReducer.signature[pageIndex],
    status: signReducer?.status,
    typeOfSignature: signReducer?.typeOfSignature,
    signers: signReducer?.signers,
    view: signReducer?.documentDetails?.view,
    enableTemplateFlow: signReducer?.documentDetails?.enableTemplateFlow,
    roles: signReducer?.roles,
    type: signReducer.documentDetails?.type,
})

export default connect(mapStateToProps)(DragItemCatcher)
