import React, { useEffect, useState } from "react"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import { ReactComponent as UpScroller } from "Assets/PdfEditor/up-arrow.svg"
import { ReactComponent as DownScroller } from "Assets/PdfEditor/down-arrow.svg"
import { useStyles } from "./PdfSignScrollerStyle"
import { connect } from "react-redux"
import { FORM } from "Types"

const PdfSignScroller = ({
    signature,
    numberOfPages,
    pdfRefs,
    pdfWrapper,
    currentSignerStatus,
    view,
}) => {
    const classes = useStyles()
    const [pdfPagesSize, setPdfPagesSize] = useState([])
    const [userSignatures, setUserSignatures] = useState({
        signedSignature: 0,
        totalSignature: 0,
    })

    function setArrayOfPagesSize() {
        let next = 0
        let sizeOfTotalpages = Array(numberOfPages)
            .fill()
            .map((_, i) => {
                const canvas = pdfRefs.current[i]
                let prev = next
                next = canvas?.getBoundingClientRect().height + prev
                return prev
            })
        setPdfPagesSize(sizeOfTotalpages)
        return sizeOfTotalpages
    }

    function getScrollPosition(caseNumber) {
        let currentPdfPagesSize = [...pdfPagesSize]
        if (currentPdfPagesSize.length === 0)
            currentPdfPagesSize = setArrayOfPagesSize()
        let currentlyScrolled = pdfWrapper.current.scrollTop

        let prevCurrentPdfPagesSize = [...currentPdfPagesSize]
        if (caseNumber === -1) prevCurrentPdfPagesSize.reverse()

        const signPage = prevCurrentPdfPagesSize.findIndex(
            (pageHeight) => pageHeight * caseNumber > currentlyScrolled * caseNumber
        )
        let i
        if (caseNumber === 1) i = signPage === -1 ? numberOfPages - 1 : signPage - 1
        else i = signPage === -1 ? 0 : signPage

        for (; caseNumber === 1 ? i < numberOfPages : i >= 0; i += caseNumber) {
            if (!signature[i] || Object.keys(signature[i]).length === 0) continue
            let updatedSignatures = [...Object.keys(signature[i])]
            if (caseNumber === -1) updatedSignatures.reverse()
            const signIndexFound = updatedSignatures.find(
                (signIndex) =>
                    (signature[i][signIndex].top + currentPdfPagesSize[i]) *
                        caseNumber >
                    currentlyScrolled * caseNumber
            )
            if (signIndexFound && signature[i][signIndexFound])
                return pdfWrapper.current.scrollTo({
                    top: currentPdfPagesSize[i] + signature[i][signIndexFound].top,
                })
        }
    }

    function goToUpSign() {
        getScrollPosition(-1)
    }
    function goToDownSign() {
        getScrollPosition(1)
    }

    useEffect(() => {
        let signedSignature = 0,
            totalSignature = 0
        Object.keys(signature).forEach((signs) => {
            Object.keys(signature[signs]).forEach((eachSign) => {
                if (signature[signs][eachSign].isSigned) signedSignature++
                totalSignature++
            })
        })
        setUserSignatures({ signedSignature, totalSignature })
    }, [signature])

    if (view === FORM || currentSignerStatus === "SIGNED") return null

    return (
        <div className={classes.signCountWrapper}>
            <div className={classes.signCountContainer}>
                <Text color="white">
                    {`${userSignatures.signedSignature} out of ${userSignatures.totalSignature} signatures are done`}
                </Text>
                <div className={classes.signScrollersWrapper}>
                    <TooltipWrapper text={"Go to Previous Sign"} direction={"top"}>
                        <div
                            className={`${classes.signScroller}`}
                            onClick={goToUpSign}
                        >
                            <UpScroller />
                        </div>
                    </TooltipWrapper>
                    <TooltipWrapper text={"Go to Next Sign"} direction={"top"}>
                        <div
                            className={`${classes.signScroller}`}
                            onClick={goToDownSign}
                        >
                            <DownScroller />
                        </div>
                    </TooltipWrapper>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    signature: signReducer.signature,
    view: signReducer.documentDetails?.view,
    currentSignerStatus: signReducer?.currentSignerStatus,
})

export default connect(mapStateToProps)(PdfSignScroller)
