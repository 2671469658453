import React, { useEffect } from "react"
import { useContext } from "react"
import { ReactContext } from "Awesome-ui/MediaLibrary/MediaLibrary"
import { ReactComponent as Image } from "../../Assets/image-icon.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { useState } from "react"
import { usePickerStyles } from "./PickerStyle"
import { useStyles } from "Awesome-ui/MediaLibrary/MediaLibraryStyle"

const GetImage = ({ item }) => {
    const media = item.media_formats
    const { handlingFiles, setHandlingFiles, singleFile, initFiles, styles } =
        useContext(ReactContext)
    const classes = useStyles(styles)
    const [isSelected, setIsSelected] = useState(
        handlingFiles.tenor.filter((f) => f.id === item.id).length !== 0
    )
    const addFileToHandle = () => {
        if (!handlingFiles.tenor.find((f) => f.id === item.id))
            if (singleFile) setHandlingFiles(() => ({ ...initFiles, tenor: [item] }))
            else
                setHandlingFiles((prev) => ({
                    ...prev,
                    tenor: [...prev.tenor, item],
                }))
        else
            setHandlingFiles((prev) => ({
                ...prev,
                tenor: prev.tenor.filter((f) => f.id !== item.id),
            }))
    }

    useEffect(() => {
        if (handlingFiles.tenor.find((f) => f.id === item.id)) setIsSelected(true)
        else setIsSelected(false)
    }, [handlingFiles.tenor])

    const pickerClasses = usePickerStyles()

    return (
        <div className={pickerClasses.card} key={item.id} onClick={addFileToHandle}>
            <div
                className={`${classes.contentLibraryImage} ${
                    classes.contentLibraryImageUnsplash
                } ${isSelected && classes.contentLibraryImageActive}`}
            >
                <div className={classes.contentLibraryImageContent}>
                    <img
                        src={media.tinygif.url}
                        alt={item.content_description}
                        loading="lazy"
                        style={{ background: "#fafafa", width: "100%" }}
                    />
                </div>
                <div
                    className={`${classes.contentLibraryImageDescription} ${
                        classes.contentLibraryImageDescriptionGIF
                    } ${isSelected && classes.contentLibraryImageDescriptionActive}`}
                >
                    <Text
                        fontSize={13}
                        weight={600}
                        margin="0.25rem 0.5rem"
                        color={`${isSelected ? "#FFFFFF" : "#497696"}`}
                    >
                        {item.content_description}
                    </Text>
                    <div
                        className={`${classes.fileType} ${
                            isSelected && classes.fileTypeActive
                        }`}
                    >
                        <Text
                            fontSize={11}
                            color={`${isSelected ? "#5E7C98" : "white"}`}
                        >
                            {"gif"}
                        </Text>
                    </div>
                    <Image
                        fill={`${isSelected ? "#ffffff" : "#99acc2"}`}
                        style={{ marginRight: "0.5rem" }}
                    />
                </div>
            </div>
        </div>
    )
}

/**
 * gif column component shows images in 2 column format
 * @returns Component
 */
const GifList = ({ gif, scrollRefs }) => {
    return (
        <>
            <div className="img-col">
                {gif[0] && gif[0].map((item, index) => <GetImage item={item} />)}
                <div
                    key={gif[0]?.length}
                    ref={(el) => (scrollRefs[0].current = el)}
                    style={{
                        height: "100px",
                        width: "100%",
                        display: "inline-block",
                    }}
                ></div>
            </div>
            <div className="img-col">
                {gif[1] && gif[1].map((item, index) => <GetImage item={item} />)}
                <div
                    key={gif[1]?.length}
                    ref={(el) => (scrollRefs[1].current = el)}
                    style={{
                        height: "100px",
                        width: "100%",
                        display: "inline-block",
                    }}
                ></div>
            </div>
            <div className="img-col">
                {gif[2] && gif[2].map((item, index) => <GetImage item={item} />)}
                <div
                    key={gif[2]?.length}
                    ref={(el) => (scrollRefs[2].current = el)}
                    style={{
                        height: "100px",
                        width: "100%",
                        display: "inline-block",
                    }}
                ></div>
            </div>
        </>
    )
}

export default GifList
