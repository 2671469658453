import React, { Fragment } from "react"
import TemplatesRightTop from "../TemplatesRightTop"
import TemplateLists from "./components/TemplateLists"
import { deleteMultipleDocuments, paginationRequest } from "Action/documentAction"
import { TEMPLATE } from "Types"
import { connect } from "react-redux"
import useQuery from "hooks/UseQuery"
import { useHistory } from "react-router-dom"
import PaginationBar from "Awesome-ui/PaginationBar"

const MyTemplates = ({ userDetails, globalLoading, brands }) => {
    const [selection, setselection] = React.useState({})
    const [searchQuery, setSearchQuery] = React.useState("")
    const [currentTemplates, setCurrentTemplates] = React.useState([])
    const [totalPages, setTotalPages] = React.useState(0)
    const [templateLoading, setTemplateLoading] = React.useState(false)
    const { addQuery, getQuery, removeQuery } = useQuery()
    const history = useHistory()
    const [currentPage, setCurrentPage] = React.useState(
        Number(getQuery("page")) || 1
    )
    const [sortOrder, setSortOrder] = React.useState(getQuery("sort"))
    const [pageSize, setPageSize] = React.useState(Number(getQuery("size")) || 10)
    const [onSearchFileEmpty, setOnSearchFileEmpty] = React.useState(false)

    //TODO: fix this force render
    const [, setForceRender] = React.useState(false)

    function selectFile(val, id) {
        setselection((prev) => {
            const newData = { ...prev }
            newData[id] = val
            return newData
        })
    }
    function selectGroupOfFile(files = [], val) {
        setselection(() => {
            const newData = {}
            files.forEach((file) => (newData[file.fileId] = val))
            return newData
        })
    }
    function clearSelection() {
        setselection({})
    }

    const getCurrentDocuments = async () => {
        const myDocumentsFolder = brands?.find((b) => b.name === "My Documents")
        if (!brands || !myDocumentsFolder) {
            setCurrentTemplates([])
            setTotalPages(1)
            return
        }
        if (currentTemplates?.length < 1) setTemplateLoading(true)
        const userId = userDetails?.id
        const currentPage = Number(getQuery("page"))
        const sort = getQuery("sort")
        const size = Number(getQuery("size"))
        const searchQuery = getQuery("search")
        const res = await paginationRequest(
            userId,
            currentPage,
            "",
            sort,
            myDocumentsFolder.brandId,
            "",
            size,
            searchQuery,
            TEMPLATE
        )
        if (res?.content) {
            setCurrentTemplates(res.content)
            setTotalPages(res.totalPages)
            if (res.content.length === 0 && searchQuery) setOnSearchFileEmpty(true)
        }
        setTemplateLoading(false)
    }

    const reload = async () => {
        const currentPage = Number(getQuery("page"))
        const sort = getQuery("sort")
        const searchQuery = getQuery("search")

        if (currentPage !== 1) addQuery("page", "1")
        if (sort) removeQuery("sort")
        if (searchQuery) removeQuery("search")
        await getCurrentDocuments()
    }

    const deleteMultipleFileHandler = async () => {
        const fileIds = []
        Object.keys(selection).map((fileId) => {
            if (selection[fileId] === true) fileIds.push(fileId)
        })
        if (fileIds.length < 1) return
        const res = await deleteMultipleDocuments(fileIds)
        if (res === 200) {
            await reload()
        }
        clearSelection()
    }

    React.useEffect(() => {
        if (!globalLoading) getCurrentDocuments()
    }, [history.location.search, globalLoading])

    React.useEffect(() => {
        async function searchDebounce() {
            if (searchQuery) addQuery("search", searchQuery)
            else if (getQuery("search")) removeQuery("search")
            setForceRender((prev) => !prev)
        }
        const debounceTimeout = setTimeout(searchDebounce, 800)

        return () => clearTimeout(debounceTimeout)
    }, [searchQuery])

    return (
        <Fragment>
            <TemplatesRightTop
                header="My Templates"
                desc="All templates created by you or your team members"
                searchInputVisible
            />
            <TemplateLists
                templates={currentTemplates}
                selection={selection}
                totalPages={totalPages}
                onDeleteFile={reload}
                templateLoading={templateLoading}
                setselection={selectGroupOfFile}
                selectFile={selectFile}
                clearSelection={clearSelection}
                onSearchFileEmpty={onSearchFileEmpty}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                deleteMultipleFileHandler={deleteMultipleFileHandler}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />
            {totalPages > 0 && (
                <div style={{ marginTop: "1rem" }}>
                    <PaginationBar
                        currentPage={currentPage}
                        totalPages={totalPages}
                        size={pageSize}
                        setCurrentPage={(val) => {
                            setCurrentPage(val)
                            addQuery("page", val.toString())
                        }}
                        setSize={(val) => {
                            setPageSize(val)
                            addQuery("size", val.toString())
                        }}
                    />
                </div>
            )}
        </Fragment>
    )
}

const mapStateToProps = ({ userReducer, workspaceReducer }) => ({
    userDetails: userReducer.userDetails,
    globalLoading: userReducer.gloableLoading,
    brands: workspaceReducer.currentWorkspace?.brands,
})

export default connect(mapStateToProps)(MyTemplates)
