import { medialApi } from "Apis/AllApisMapping"
import Input from "Awesome-ui/Input/Input"
import Loader from "Awesome-ui/Loader/Loader"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import React, { useEffect, useState, useContext } from "react"
import { ReactContext } from "../MediaLibrary"
import { ReactComponent as Grid } from "../Assets/grid.svg"
import { ReactComponent as List } from "../Assets/list.svg"
import { ReactComponent as Pencil } from "../Assets/pencil.svg"
import { ReactComponent as Copy } from "../Assets/copy.svg"
import { ReactComponent as AddFolder } from "../Assets/add-folder.svg"
import { ReactComponent as Delete } from "../Assets/delete.svg"
import { ReactComponent as Upload } from "../Assets/upload.svg"
import {
    Cell,
    CheckBox,
    HeaderCell,
    PaginationContent,
    Row,
    Table,
    TableHeader,
    Text,
} from "@awesomesuite-frontend/awesome-nebula"
import GridImage from "./GridImage"
import ListImage from "./ListImage"
import { useStyles } from "../MediaLibraryStyle"

const ContentLibrary = () => {
    const { userToken, validatingParameters, editImage, styles } =
        useContext(ReactContext)
    const [userFiles, setUserFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchers, setSearchers] = useState("")
    const [selectedTab, setSelectedTab] = useState(1)
    const [allSelect, setAllSelect] = useState(false)
    const classes = useStyles(styles)

    useEffect(() => {
        async function init() {
            const mediaController = new mediaHandler(medialApi)
            let files = []
            setLoading(true)
            try {
                const res = await mediaController.getAllMediaForUser(userToken)
                if (res) {
                    if (validatingParameters)
                        files = res.filter((f) =>
                            validatingParameters.includes(f.mediaType)
                        )
                    else files = [...res]
                }
            } catch (err) {
                console.log("Media Error", err)
            } finally {
                if (Array.isArray(files)) setUserFiles(files)
                else setUserFiles([])
                setLoading(false)
            }
        }
        init()
    }, [userToken])

    return (
        <>
            <div className={classes.contentLibraryTop}>
                <Input
                    icon="search"
                    placeHolder="Search..."
                    type="text"
                    style={{ maxWidth: "70%", width: "70%" }}
                    changeHandler={(data) => setSearchers(data)}
                    className={classes.contentLibrarySearch}
                    inputStyle={{ background: "#EFF3F6" }}
                    iconColor="#00BBD8"
                />
                {/* //TODO: ADD LIST VIEW*/}
                {/* <div className={classes.contentLibraryTabSelector}>
                    <div
                        className={`${classes.contentLibraryTab1} ${
                            selectedTab === 1 && classes.contentLibraryTabActive
                        }`}
                        onClick={() => setSelectedTab(1)}
                    >
                        <Grid />
                    </div>
                    <div
                        className={`${classes.contentLibraryTab2} ${
                            selectedTab === 2 && classes.contentLibraryTabActive
                        }`}
                        onClick={() => setSelectedTab(2)}
                    >
                        <List />
                    </div>
                </div> */}
            </div>
            {editImage && (
                <div className={classes.contentLibraryOptions}>
                    {/* <div className={classes.contentLibraryOptionUpload}>
                    <Upload />
                    <Text fontSize={13} marginLeft="0.25rem">
                        Upload
                    </Text>
                </div> */}
                    <div className={classes.contentLibraryOption}>
                        <AddFolder />
                    </div>
                    <div className={classes.contentLibraryOption}>
                        <Pencil />
                    </div>
                    <div className={classes.contentLibraryOption}>
                        <Copy />
                    </div>
                    <div className={classes.contentLibraryOption}>
                        <Delete />
                    </div>
                </div>
            )}
            <div className={`${classes.contentLibraryImages}`}>
                {loading && (
                    <Loader
                        style={{
                            margin: "auto",
                            height: 24,
                            width: 24,
                        }}
                    />
                )}
                {selectedTab === 1 &&
                    userFiles.map((file) => <GridImage file={file} key={file.id} />)}
                {/* {selectedTab === 2 && (
                    <Table>
                        <TableHeader stickTop>
                            <HeaderCell
                                title={
                                    <CheckBox
                                        variant={"curved"}
                                        backgroundColor="#00BBD8"
                                        color={"white"}
                                        border="#DFE3EB"
                                        active={allSelect}
                                        clickFunction={() =>
                                            setAllSelect(!allSelect)
                                        }
                                    />
                                }
                                width="10%"
                            >
                                Hello
                            </HeaderCell>
                            <HeaderCell width="20%"></HeaderCell>
                            <HeaderCell title={"File Name"} width="15%"></HeaderCell>
                            <HeaderCell
                                title={"Size and Ratio"}
                                width="20%"
                            ></HeaderCell>
                            <HeaderCell
                                title={"Uploaded at"}
                                width="20%"
                            ></HeaderCell>
                            <HeaderCell title={"Author"} width="15%"></HeaderCell>
                        </TableHeader>
                        {/* {userFiles.map((file) => (
                            <ListImage file={file} key={file.id} />
                        ))} }
                    </Table>
                )} */}
            </div>
        </>
    )
}

export default ContentLibrary
