import React from "react"
import { useStyles } from "./TemplateFormStyle"
import { DatePicker, Text } from "@awesomesuite-frontend/awesome-nebula"
import "./TemplateFormDate.scss"
import { setTemplateItemValue } from "Action/signAction"

const TemplateFormDate = ({
    detailsOfPositions = [],
    value,
    scrollToPage = () => {},
}) => {
    const classes = useStyles()
    const elementInPages = new Set()
    detailsOfPositions.forEach((detail) => {
        elementInPages.add(detail.pageIndex)
    })
    return (
        <div className={classes.templateFormInput}>
            <Text varient="h6">{"Date"}</Text>
            <div
                className={classes.templateFormCheckboxToggle}
                style={{ position: "relative", zIndex: 1 }}
            >
                <DatePicker
                    date={value ? value.split("/").reverse().join("-") : null}
                    direction="bottom"
                    setDate={(val) =>
                        setTemplateItemValue(
                            val.split("-").reverse().join("/"),
                            detailsOfPositions
                        )
                    }
                />
            </div>

            <div className={classes.pageNumberList}>
                {Array.from(elementInPages).map((pageIndex, index) => (
                    <div
                        key={index}
                        className={classes.eachPageNumber}
                        onClick={() => scrollToPage(parseInt(pageIndex))}
                    >
                        <Text fontSize={13}>Pg. {parseInt(pageIndex) + 1}</Text>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TemplateFormDate
