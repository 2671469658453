
import { store } from "../store";

const { getState } = store;

export const getHeaders = (params) => {

    const token = getState().userReducer.token;
    
    if(!token) 
        return new Error("could not get the token of the user.");    

    const headers = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        }
    };

    if(params) 
        headers.params = params;    

    return headers;
}