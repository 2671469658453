import React from "react"
import Input from "Awesome-ui/Input/Input"
import { ReactComponent as ReloadIcon } from "../../../Assets/Inbox/reload-icon.svg"
import { ReactComponent as Delete } from "Assets/Storage/delete-icon.svg"
import { createUseStyles } from "react-jss"
import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"

const useStyle = createUseStyles(() => ({
    inboxContentTop: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inboxTopLeft: {
        width: "25%",
        display: "flex",
        alignItems: "center",
    },
    inboxTopRight: {
        display: "flex",
        alignItems: "center",
    },
    inboxFilter: {
        marginLeft: "1rem",
        display: "flex",
        alignItems: "center",
        color: "#33475B",
        "& span": {
            marginLeft: "1rem",
            display: "block",
            minWidth: "30px",
            width: "30px",
            minHeight: "30px",
            backgroundColor: "#073255",
        },
    },
    viewController: {
        position: "absolute",
        right: "2rem",
        borderRadius: "5px",
        border: "1px solid #d6dfe8",
        display: "flex",
    },
    viewControllerView: {
        borderRight: "1px solid #d6dfe8",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "30px",
        width: "30px",
        cursor: "pointer",
    },
    viewControllerView1: {
        borderTopLeftRadius: "5px",
        borderBottomLeftRadius: "5px",
    },
    viewControllerView3: {
        borderRight: "none",
        borderTopRightRadius: "5px",
        borderBottomRightRadius: "5px",
    },
    viewControllerViewActive: {
        backgroundColor: "#E1E8EE",
        "& svg *": {
            fill: "#334760",
        },
    },
    inboxReload: {
        marginLeft: "1rem",
        cursor: "pointer",
        border: "1px solid #d6dfe8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.25rem 0.5rem",
        borderRadius: "4px",
        "&:hover": {
            backgroundColor: "#EAF0F6",
        },
    },
    deleteFiles: {
        border: "1px solid #d6dfe8",
        borderRadius: 3,
        padding: "0.25rem 0.5rem",
        cursor: "pointer",
        marginLeft: "1rem",
        background: "#fff",
        "& div": {
            display: "flex",
            alignItems: "center",
        },
    },
}))

const InBoxTop = ({
    search,
    searchHandler,
    reloadFunc,
    deleteMultipleFileHandler,
    selectedFilesCount,
}) => {
    const classes = useStyle()
    const reload = async () => {
        await reloadFunc()
    }
    return (
        <div className={`${classes.inboxContentTop}`}>
            <div className={`${classes.inboxTopLeft}`}>
                <div className="inbox-search">
                    <Input
                        style={{
                            maxWidth: "225px",
                            padding: "10px 8px",
                            width: "25vw",
                        }}
                        value={search}
                        changeHandler={searchHandler}
                        placeHolder="Search documents"
                        inputStyle={{
                            fontSize: "1rem",
                            paddingRight: "25px",
                        }}
                        icon="search"
                    />
                </div>
                <TooltipWrapper direction={"right"} text={"Refresh"}>
                    <div className={`${classes.inboxReload}`} onClick={reload}>
                        <ReloadIcon />
                    </div>
                </TooltipWrapper>
            </div>
            <div className={classes.inboxTopRight}>
                {selectedFilesCount > 0 && (
                    <>
                        <div className="count-files">
                            <Text fontSize={14} color="#00B5D1">
                                {`${selectedFilesCount} files selected`}
                            </Text>
                        </div>
                        <Button
                            className={classes.deleteFiles}
                            onClick={deleteMultipleFileHandler}
                        >
                            <div>
                                <Delete />
                                <Text fontSize={14} marginLeft="0.5rem">
                                    Delete
                                </Text>
                            </div>
                        </Button>
                    </>
                )}
            </div>
        </div>
    )
}

export default InBoxTop
