import { useDragLayer } from "react-dnd"
import { HEADER, SIDEBAR_ITEM, TEMPLATE_ITEM, TEXT_BOX } from "./Constants.js"
import SignDragPreview from "./SignDragPreview.js"

const layerStyles = {
    position: "fixed",
    pointerEvents: "none",
    zIndex: 100,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
}

function getItemStyles(initialOffset, currentOffset) {
    if (!initialOffset || !currentOffset) {
        return {
            display: "none",
        }
    }
    let { x, y } = currentOffset
    const transform = `translate(${x}px, ${y}px)`
    return {
        transform,
        WebkitTransform: transform,
    }
}

const getRenderingComponent = (type, data, currentPath) => {
    switch (type) {
        case SIDEBAR_ITEM:
            return <SignDragPreview height={data.height} width={data.width} />
        case TEMPLATE_ITEM:
            return (
                <SignDragPreview
                    height={data.height}
                    width={data.width}
                />
            )
        default:
            return null
    }
}

const CustomDragLayer = (props) => {
    const { itemType, isDragging, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }))

    if (!isDragging) {
        return null
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                {getRenderingComponent(itemType, item, null)}
            </div>
        </div>
    )
}

export default CustomDragLayer
