import { Text } from "@awesomesuite-frontend/awesome-nebula";
import React from "react"

import "./StatusChip.scss"

const statusType = {
    DRAFT: {
        color: "#B9D3EC",
        dot: "#B9D3EC",
        backgroundColor: "#EBF1F8",
        text: "Draft",
    },
    OUT_FOR_SIGNATURE: {
        color: "#F2A100",
        dot: "#F2A100",
        backgroundColor: "#FFFEDE",
        text: "Pending",
    },
    FINISHED: {
        color: "#00BF9C",
        dot: "#00BE9B",
        backgroundColor: "#E2FFFA",
        text: "Signed",
    },
}

/**
 * status chip with pre defined types ["draft", "pending", "complete"]
 * @param {*} param0
 * @returns
 */
const StatusChip = ({ status}) => {
    const state = statusType[status];

    if(!state) return null;

    return (
        <div
            className="aui-status-chip"
            style={{
                borderColor: state.color,
                backgroundColor: state.backgroundColor,
            }}
        >
            <div
                className="status-chip-dot"
                style={{ backgroundColor: state.dot }}
            ></div>
            <Text fontSize={12}>{state.text}</Text>
        </div>
    );
};

export default StatusChip
