import {
    ADD_SIGNER_IN_DOCUMENT,
    UPDATE_SIGNER_PROPERTY,
    REMOVE_SIGNER_FROM_LIST,
    ADD_FIELD_TO_SIGNER,
    GET_FIELDS_BY_ROLE_ID,
} from "../ActionTypes/signerActionTypes"
import { eSignApi } from "Apis/AllApisMapping"

import { store } from "../store"
import { getHeaders } from "./ActionHelper"
import { startDocumentLoading, stopDocumentLoading } from "./documentAction"
import { createMessage } from "./messageAndNotificationActions"
import { ADD_FIELD_TO_DOCUMENT } from "ActionTypes/signActionTypes"
import { DANGER, SUCCESS } from "Types"
import { getBackgroundFromInitials, getSignerBackgroundDecorum } from "Library/randomBackgroundGenerator"

// variables
const { dispatch, getState } = store

/**
 * This is to add new signer person in the document.
 * 
 * sample obj : 
 * ```
 *   {
        emailId: "",
        userId: "",
        firstName: "",
        lastName: "",
        order: "",
        documentId: "",
        typeOfSignature: "ANNOTATION | SIGN | CLICK_SIGN", 
    }
 * ``` 
 * 
 * @param {*} signer
 */
export const addSignerInDoc = async (signer) => {
    const { signers, documentDetails } = getState().signReducer
    const headers = getHeaders()

    // get order
    let order
    if (!signers) order = 1
    else order = signers.length + 1
    const backgroundDecorum = getSignerBackgroundDecorum(
        (signer.firstName[0] + signer.lastName[0]).toLowerCase(),
        order
    )

    const data = {
        emailId: signer.email,
        userId: signer.userId ? signer.userId : 0, // not available rn
        firstName: signer.firstName,
        lastName: signer.lastName,
        fileId: documentDetails.documentId,
        order: order,
        documentId: documentDetails.id, // use the primary key for better get request
        typeOfSignature: signer.typeOfSignature,
        roleId: signer.roleId,
    }

    try {
        startDocumentLoading()
        const result = await eSignApi.post("/signer", data, headers)

        if (result.data.statusCode == 200) {
            dispatch({
                type: ADD_SIGNER_IN_DOCUMENT,
                payload: { signer: { ...result.data.model, backgroundDecorum } },
            })
            createMessage(
                "Document Saved Successfully!",
                "Added new signer in the document.",
                SUCCESS
            )
        }
        stopDocumentLoading()
    } catch (error) {
        stopDocumentLoading()
        createMessage(
            "Failed to add signer !",
            "Could not add signer to the document.",
            DANGER
        )
        console.log("Found the error in while saving signers to docment.", error)
    }
}

export const deleteSigner = async (id) => {
    try {
        const headers = getHeaders()
        startDocumentLoading()
        const result = await eSignApi.delete(`/signer?signerId=${id}`, headers)
        if (result.data.statusCode === 200) {
            createMessage(
                "Document Saved Successfully!",
                "Removed signer from the document.",
                SUCCESS
            )
            dispatch({ type: REMOVE_SIGNER_FROM_LIST, payload: { signerId: id } })
        }
        stopDocumentLoading()
    } catch (error) {
        createMessage(
            "Failed to add signer !",
            "Could not remove signer to the document.Something went wrong.",
            DANGER
        )
        stopDocumentLoading()
        console.log("Found the error in while saving signers to docment.", error)
    }
}

/**
 * Add the fields to the signer to get signed.
 * @param {*} signerId  signer id
 * @param {*} pageNumber page number
 * @param {*} xAxis x - axis
 * @param {*} yAxis y - axis
 */
export const addFields = async (
    signerId,
    pageNumber,
    xAxis,
    yAxis,
    top,
    left,
    id,
    height,
    width,
    pageHeight,
    pageWidth,
    roleId,
    type,
    subType,
) => {
    const data = {
        id: id,
        signerId: signerId,
        pageNumber: pageNumber,
        top: top,
        left: left,
        x_axis: xAxis,
        y_axis: yAxis,
        height,
        width,
        pageHeight,
        pageWidth,
        roleId,
        type,
        subType,
    }

    try {
        const headers = getHeaders()
        startDocumentLoading()
        const result = await eSignApi.post("/signer/fields", data, headers)

        if (result.data.model) {
            dispatch({ type: ADD_FIELD_TO_SIGNER, payload: result.data.model })
            if (!roleId)
                dispatch({
                    type: ADD_FIELD_TO_DOCUMENT,
                    payload: { field: result.data.model },
                })
            stopDocumentLoading()
            return result.data.model
        }
        stopDocumentLoading()
    } catch (error) {
        console.log(error)
        stopDocumentLoading()
        createMessage(
            "Failed to add fields for signer !",
            "Could not add signing field to the document.Something went wrong.",
            DANGER
        )
    }
}

/**
 * Add the fields to the signer to get signed.
 * @param {*} signerId  signer id
 * @param {*} pageNumber page number
 * @param {*} xAxis x - axis
 * @param {*} yAxis y - axis
 */
export const deleteFields = async (id) => {
    try {
        const headers = getHeaders()
        headers.params = { id }
        startDocumentLoading()
        const result = await eSignApi.delete("/signer/fields", headers)
        if (result.data.model) {
            return result.data.model
        }
        stopDocumentLoading()
    } catch (error) {
        console.log(error)
        console.log(DANGER)
        createMessage(
            "Failed to delete fields for signer !",
            "Could not delete signing field to the document.Something went wrong.",
            DANGER
        )
        stopDocumentLoading()
    }
}

/**
 * get the list of sigers in the document.
 * @param {*} documentID
 */
export const getAllSinersinDocument = async (documentID) => {
    if (!documentID) return

    const headers = getHeaders()
    try {
        headers.params = {
            documentId: documentID,
        }
        const result = await eSignApi.get("/allSigners", headers)
    } catch (error) {
        console.log("Could not fetch the list of signers.", error)
    }
}

/**
 * update the signer property like the signature type.
 * @param {*} signerId
 * @param {*} id
 * @param {*} value
 */
export const updateSignerProperty = (signerId, id, value) => {
    dispatch({ type: UPDATE_SIGNER_PROPERTY, payload: { signerId, id, value } })
}

// for public user
export const signerViewEvent = async (signerId, documentId) => {
    if (!signerId) return
    const headers = getHeaders()

    try {
        const result = await eSignApi.post(
            `/public/signer/action?signerId=${signerId}&key=view&documentId=${documentId}`,
            headers
        )
    } catch (error) {
        console.log("Could not fetch the list of signers.", error)
    }
}

/**
 * handle the case where signer wants to reject the signature reject sign
 * @param {*} signerId
 * @param {*} history
 */
export const rejectSign = async (signerId, history, documentId) => {
    if (!signerId) return
    const headers = getHeaders()

    try {
        const result = await eSignApi.post(
            `/public/signer/action?documentId=${documentId}&key=rejected&signerId=${signerId}`,
            headers
        )
    } catch (error) {
        console.log("Could not reject the signer pdf sign.", error)
    }
}

/**
 * The `resendEmailToSigner` function is an asynchronous function that resends an email to a signer
 * identified by `signerId` using an eSign API, and displays success or error messages accordingly.
 * @param signerId - The `signerId` parameter is the unique identifier of the signer to whom the email
 * needs to be resent.
 * @returns The function `resendEmailToSigner` returns a boolean value indicating whether the email was
 * sent successfully or not.
 */
export const resendEmailToSigner = async (signerId) => {
    if (!signerId) return
    const headers = getHeaders()

    try {
        const result = await eSignApi.put(
            `/sign/${signerId}/resend-email`,
            {},
            headers
        )
        if (result.data.statusCode == 200) {
            createMessage(
                "Email Sent Successfully!",
                "Email resend to the signer successfully.",
                SUCCESS
            )
            return true
        } else {
            throw new Error("Could not send email to the signer.")
        }
    } catch (error) {
        console.log("Could not reject the signer pdf sign.", error)
        createMessage(
            "Failed to send email!",
            "Could not send email to the signer.",
            DANGER
        )
    }
}

export const getFieldsbyRoleId = async (roleIds) => {
    const headers = getHeaders()
    const roles = getState().signReducer.roles
    const roleIdsList = roleIds ? roleIds : roles.map((role) => role.id).join(",")
    try {
        const resp = await eSignApi.get(
            `public/signer/fields?roleIds=${roleIdsList}`,
            headers
        )
        if (resp.data.statusCode === 200) {
            const fields = resp.data.model
            dispatch({ type: GET_FIELDS_BY_ROLE_ID, payload: { fields } })
            return fields
        } else throw new Error("Something went wrong while getting the fields")
    } catch (err) {
        console.log(err)
        createMessage(
            "Failed to get fields !",
            "Something went wrong while getting the fields",
            DANGER
        )
    }
}
