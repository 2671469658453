import {
    SET_NO_SIDE_BAR,
    SET_SIDE_BAR_COLOR,
    SET_SIDE_BAR_EXPAND,
    SET_SIDE_BAR_TYPE,
} from "../ActionTypes/settingsTyps"

const initialize = {
    sideBarType: "slim",
    sideBarColor: "light",
    noSideBar: false,
    sideExpand: false,
}

export const settingsReducer = (state = initialize, action) => {
    const payload = action.payload

    switch (action.type) {
        case SET_SIDE_BAR_TYPE:
            return {
                ...state,
                sideBarType: payload.type,
            }
        case SET_SIDE_BAR_COLOR:
            return {
                ...state,
                sideBarColor: payload.color,
            }
        case SET_NO_SIDE_BAR:
            return {
                ...state,
                noSideBar: !state.noSideBar,
            }
        case SET_SIDE_BAR_EXPAND:
            return {
                ...state,
                sideExpand: !state.sideExpand,
            }
        default:
            return state
    }
}
