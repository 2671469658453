import {
    GET_NOTIFICATION,
    CREATE_MESSAGE,
    CLOSE_MESSAGE,
    SHOW_WARNING_MESSAGE_POPUP,
    HIDE_WARNING_MESSAGE_POPUP,
    TOGGLE_APP_SWITCHER,
} from "../ActionTypes/messageAndNotificationTypes"

const initialize = {
    notifications: null,
    notificationLoading: false,
    messageShow: false,
    message: null,
    warningMessage: null,
    warningMessageShow: false,
    showAppSwitcher: false,
}

export const messageAndNotificationReducer = (state = initialize, action) => {
    const payload = action.payload

    switch (action.type) {
        case GET_NOTIFICATION:
            return {
                ...state,
                notifications: payload.notification,
            }
        case CREATE_MESSAGE:
            return {
                ...state,
                messageShow: true,
                message: payload,
            }
        case CLOSE_MESSAGE:
            return {
                ...state,
                messageShow: false,
            }
        case SHOW_WARNING_MESSAGE_POPUP:
            return {
                ...state,
                warningMessageShow: true,
                warningMessage: payload,
            }
        case HIDE_WARNING_MESSAGE_POPUP:
            return {
                ...state,
                warningMessageShow: false,
            }
        case TOGGLE_APP_SWITCHER:
            return {
                ...state,
                showAppSwitcher: !state.showAppSwitcher,
            }
        default:
            return state
    }
}
