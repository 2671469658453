import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(() => ({
    auiTrialEnded: {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "#2d394c73",
        zIndex: 1000000003,
    },
    auiTrialEndedPopup: {
        position: "absolute",
        maxWidth: "542px",
        width: "95%",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        animation: "grow 0.1s ease-in 1",
        transformOrigin: "center",
    },
    auiTrialEndedImage: {
        height: "340px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    auiTrialEndedTitle: {
        fontSize: "1.5rem",
        fontWeight: 600,
        margin: "1.5rem",
    },

    auiTrialEndedSubTitle: {
        margin: "0 1.5rem 1.5rem 1.5rem",
        fontSize: 13,
    },
    auiTrialEndedBottom: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        position: "relative",
    },
    auiTrialEndedButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        padding: "1rem",

        "& a": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "230px",
            height: "2.5rem",
            borderRadius: " 4px",
            cursor: "pointer",
            fontWeight: 500,
            "&:hover": {
                opacity: 0.8,
            },
            textDecoration: "none",
        },
    },
    auiTrialEndedSwitchButton: {
        border: "1px solid #e0e5e9",
        textDecoration: "none",
    },

    auiTrialEndedPaymentButton: {
        backgroundColor: "#33475B",
        border: "1px solid #33475B",
        color: "white",
    },
    auiTrialEndedAllPlans: {
        cursor: "pointer",
        color: "#00a4bd",
        margin: "1rem auto",
        display: "inline-block",
        textDecoration: "none",

        "&:hover": {
            textDecoration: "underline",
        },
    },

    auiTrialEndedLogout: {
        position: "absolute",
        right: "1rem",
        bottom: "1rem",
        cursor: "pointer",
        display: "inline-block",
        color: "#e78d7d",
        fontSize: "0.9rem",
        fontWeight: "bold",
        marginLeft: "auto",
        "&:hover": {
            textDecoration: "underline",
        },
    },

    auiTrialEndedClose: {
        position: "absolute",
        top: 0,
        right: "1rem",
        fontSize: "2rem",
        fontWeight: 100,
        transform: "rotateZ(45deg)",
        cursor: "pointer",
        "&:hover": {
            fontWeight: 200,
        },
    },

    "@keyframes grow": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        },
    },
    anchors: {
        textDecoration: "none",
    },
}))


export default useStyles
