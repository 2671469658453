import React from "react";
import RoundedSymbol from "../RoundedSymbol/RoundedSymbol";
import PrioritySymbol from "../PrioritySymbol/PrioritySymbol";

import "./DropDownOptions.scss";

/**
 * For Create Dropdown Options Use This DropDownOptions Component
 *
 * This is Used in DropdownContainer Component
 *
 * @param {Array} dropdownContent Array of Options
 * @param {Function} changeSelectedItem
 * @param {Function} setShowDropDown
 * @param {Boolean} showOnlyIconInSelect
 *
 */

const DropDownOptions = ({
  dropdownContent,
  changeSelectedItem,
  setShowDropDown,
  showOnlyIconInSelect,
}) => {
  return (
    <div className={`dropdown-options ${showOnlyIconInSelect && "right-0"}`}>
      {dropdownContent.map(({ id, name, priorityColor, roundedSymbol }) => (
        <div
          key={id}
          onClick={() => changeSelectedItem(id)}
          onBlur={() => setShowDropDown(false)}
        >
          {priorityColor && <PrioritySymbol color={priorityColor} />}
          {roundedSymbol && <RoundedSymbol {...roundedSymbol} />}
          <p>{name}</p>
        </div>
      ))}
    </div>
  );
};

export default DropDownOptions;
