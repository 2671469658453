import {
    SET_SIGNATURE_FLOW,
    ADD_SIGNATURE_ELEMENT,
    RESIZE_SIGNATURE_ELEMENT,
    REMOVE_SIGNATURE_ELEMENT,
    DRAG_SIGNATURE_ELEMENT,
    ADD_DATA_TO_ELEMENT,
    FINISH_AND_SIGN,
    INITIALIZE_DOCUMENT_SIGNATURE,
    SET_SEND_FOR_SIGNATURE_TYPE,
    SET_PDF_PAGES_PREVIEW,
    TOGGLE_PDF_PAGE_PREVIEW,
    SET_ACTIVE_TOOL,
    CLEAR_E_SIGN_DATA,
    SET_ACTIVITY_LOGS,
    CAPTURE_USER_CLICK,
    CAPTURE_USER_ACTION,
    ADD_FIELD_TO_DOCUMENT,
    UPDATE_SIGNATURE_ELEMENT,
    CLICK_TO_SIGN_ALL,
    CLICK_TO_SIGN_EACH,
    ADD_USER_SIGN,
    GET_USER_SIGNS,
    DELETE_USER_SIGN,
    SET_ACTIVE_TAB,
    UPDATE_VALUE_OF_TEMPLATE_ITEM,
    SET_TEMPLATE_VIEW,
    ADD_ROLE_TO_TEMPLATE,
    GET_ROLES_OF_TEMPLATE,
    UPDATE_ROLE_OF_TEMPLATE,
    REMOVE_ROLE_FROM_TEMPLATE,
    SET_IS_DOCUMENT_ACCEPTED,
} from "../ActionTypes/signActionTypes"
import {
    ADD_SIGNER_IN_DOCUMENT,
    UPDATE_SIGNER_PROPERTY,
    REMOVE_SIGNER_FROM_LIST,
    ADD_FIELD_TO_SIGNER,
    GET_FIELDS_BY_ROLE_ID,
} from "ActionTypes/signerActionTypes"
import {
    GET_DOCUMENT_BY_ID,
    REJECT_DOCUMENT_SIGNING,
    START_DOCUMENT_SIGNING,
    ADD_SIGNERS_TO_DOC,
    UPDATE_DOCUMENT_DETAILS,
    UPDATE_DOCUMENT_DETAILS_FAILED,
    UPDATE_DOCUMENT_DETAILS_LOADING,
} from "ActionTypes/documentType"
import update from "immutability-helper"
import {
    CANCELLED,
    DOCUMENT,
    DRAFT,
    FORM,
    OUT_FOR_SIGNATURE,
    SEND_FOR_SIGNATURE,
    SIGNER,
    USER,
} from "Types"
import { SignatureDragType } from "Components/PDFCommons/container/Types"
import { getCanvasSize } from "Library/canvasAdjuster"
import { getSignerBackgroundDecorum } from "Library/randomBackgroundGenerator"

const initialize = {
    documentDetails: {
        id: null,
        documentId: null,
        finishDocumentLink: null,
        fileName: null,
        documentUrl: null,
        tag: null,
        status: null,
        createdAt: null,
        updateAt: null,
        signFlowType: null,
    },
    // pdf editing feature
    pdfPages: null,
    saving: false,
    isPdfPageView: false,
    status: "draft",
    flow: null, // SIGN_NOW | SEND_FOR_SIGNATURE | VIEW_ONLY
    signatureType: null,
    activeTool: null,
    signature: {},
    dimension: {},
    disable: false,
    signers: [],
    activity: [],
    editingMode: USER,
    userSigns: null,
    activeTab: -1,
    roles: null,
    isAccepted: false,
}

export const signReducer = (state = initialize, action) => {
    const payload = action.payload

    switch (action.type) {
        case INITIALIZE_DOCUMENT_SIGNATURE:
            return initializeDocument(state, payload)
        case CLEAR_E_SIGN_DATA:
            return clearESignData()
        case SET_PDF_PAGES_PREVIEW:
            return {
                ...state,
                pdfPages: payload.pageArr,
            }
        case TOGGLE_PDF_PAGE_PREVIEW:
            return {
                ...state,
                isPdfPageView: !state.isPdfPageView,
            }
        case SET_SIGNATURE_FLOW:
            return { ...state, flow: payload.flow }
        case ADD_SIGNER_IN_DOCUMENT:
            return addSignerToList(state, payload)
        case UPDATE_SIGNER_PROPERTY:
            return updateSignerProperty(state, payload)
        case REMOVE_SIGNER_FROM_LIST:
            return removeSigner(state, payload)
        case ADD_SIGNATURE_ELEMENT:
            return addSignature(
                state,
                payload.details,
                payload.pageIndex,
                payload.dimension
            )
        case UPDATE_SIGNATURE_ELEMENT:
            return updateSignature(
                state,
                payload.details,
                payload.pageIndex,
                payload.currentId
            )
        case REMOVE_SIGNATURE_ELEMENT:
            return removeSignatureElement(state, payload)
        case RESIZE_SIGNATURE_ELEMENT:
            return resizeHandler(
                state,
                payload.pageIndex,
                payload.id,
                payload.width,
                payload.height,
                payload.xAxis,
                payload.yAxis,
                payload.top,
                payload.left
            )
        case DRAG_SIGNATURE_ELEMENT:
            return dragHandler(
                state,
                payload.pageIndex,
                payload.id,
                payload.xAxis,
                payload.yAxis,
                payload.top,
                payload.left
            )
        case ADD_DATA_TO_ELEMENT:
            return addData(state, payload.pageIndex, payload.id, payload.data)
        case FINISH_AND_SIGN:
            return {
                ...state,
                finishDocumentLink: payload.link,
                saving: false,
            }
        case CLICK_TO_SIGN_ALL:
            return {
                ...state,
                signature: payload.signature,
            }
        case CLICK_TO_SIGN_EACH:
            return {
                ...state,
                signature: payload.signature,
            }
        case SET_SEND_FOR_SIGNATURE_TYPE:
            return { ...state, signatureType: payload.type }
        case SET_ACTIVE_TOOL:
            return { ...state, activeTool: payload.toolDetails }
        case UPDATE_DOCUMENT_DETAILS_LOADING:
            return { ...state, saving: true }
        case UPDATE_DOCUMENT_DETAILS:
            return updateDocumentDetails(state, payload)
        case UPDATE_DOCUMENT_DETAILS_FAILED:
            return { ...state, saving: false }
        case GET_DOCUMENT_BY_ID:
            return initDocumentForPublic(state, payload)
        case SET_ACTIVITY_LOGS:
            return addActivityLogs(state, payload)
        case CAPTURE_USER_CLICK:
            return handleSignerClick(state, payload)
        case CAPTURE_USER_ACTION:
            return handleSignerAction(state, payload)
        case START_DOCUMENT_SIGNING:
            return handleStartSign(state)
        case REJECT_DOCUMENT_SIGNING:
            return handleRejectSign(state)
        case ADD_FIELD_TO_DOCUMENT:
            return addFieldToDocument(state, payload)
        case ADD_FIELD_TO_SIGNER:
            return addToSigner(state, payload)
        case ADD_SIGNERS_TO_DOC:
            return addSignersToDoc(state, payload)
        case ADD_USER_SIGN:
            return addUserSign(state, payload)
        case GET_USER_SIGNS:
            return getUserSigns(state, payload)
        case DELETE_USER_SIGN:
            return deleteUserSign(state, payload)
        case SET_ACTIVE_TAB:
            return { ...state, activeTab: payload.tab }
        case UPDATE_VALUE_OF_TEMPLATE_ITEM:
            return updateTemplateItemValue(state, payload)
        case SET_TEMPLATE_VIEW:
            return setTemplateView(state, payload)
        case ADD_ROLE_TO_TEMPLATE:
            return addRoleToTemplate(state, payload)
        case REMOVE_ROLE_FROM_TEMPLATE:
            return removeRoleFromTemplate(state, payload)
        case UPDATE_ROLE_OF_TEMPLATE:
            return updateRoleOfTemplate(state, payload)
        case GET_ROLES_OF_TEMPLATE:
            return getRolesOfTemplate(state, payload)
        case GET_FIELDS_BY_ROLE_ID:
            return getFieldsbyRoleId(state, payload)
        case SET_IS_DOCUMENT_ACCEPTED:
            return { ...state, isAccepted: payload.isAccepted }
        default:
            return state
    }
}

function addToSigner(state, payload) {
    let { signers } = state
    if (!payload) return state

    let signerIndex = signers?.findIndex((item) => item.id === payload.signerId)

    if (signerIndex >= 0) {
        if (!signers[signerIndex].fields) {
            signers[signerIndex].fields = []
        }
        signers[signerIndex].fields.push(payload)
    }

    return {
        ...state,
        signers: signers,
    }
}

/**
 * add the activity logs of document.
 * @param {*} state
 * @param {*} payload
 */
const addActivityLogs = (state, payload) => {
    const { logs } = payload
    if (!logs) return state
    let activity = []

    activity = logs?.map((item) => {
        const data = item.message
        return {
            id: item.id,
            time: item.createdOn,
            user: data.actorName,
            email: data.actorEmail,
            action: data.message,
            status: item.eventType,
        }
    })

    if (Array.isArray(state.activity)) activity = [...state.activity, ...activity]

    return {
        ...state,
        activity,
    }
}

/**
 * add signer or person in the list of signature timeline.
 * @param {*} state
 * @param {*} payload
 */
function addSignerToList(state, payload) {
    let newSignerList = [...state.signers]
    if (!newSignerList) newSignerList = []
    newSignerList.push(payload.signer)
    return {
        ...state,
        signers: newSignerList,
    }
}

/**
 * clear signature details or document information.
 */
function clearESignData() {
    return {
        documentDetails: {
            documentId: null,
            finishDocumentLink: null,
            fileName: null,
            documentUrl: null,
            tag: null,
            status: null,
            currentSigner: null,
            createdAt: null,
            updateAt: null,
        },
        // pdf editing feature
        pdfPages: null,
        isPdfPageView: false,
        status: "draft",
        flow: null, // SIGN_NOW | SEND_FOR_SIGNATURE | VIEW_ONLY
        signatureType: null,
        activeTool: null,
        signature: {},
        dimension: {},
        signers: [],
        activity: [],
        userSigns: null,
        activeTab: -1,
        roles: null,
    }
}

/**
 * initialize the document
 * @param {object} state
 * @param {string} payload
 */
function addSignersToDoc(state, payload) {
    const { signers } = payload
    const signature = {}

    if (signers && signers.length > 0) {
        signers.forEach((signer) => {
            if (signer?.signerStatus === "SIGNED") return
            signer?.fields?.forEach((field) => {
                if (!signature[field.pageNumber]) {
                    signature[field.pageNumber] = []
                }

                // field = { ...field, ...getDimensionOfElementPerPage(field) }

                const element = {
                    id: field.id || signature[field.pageNumber].length + 1,
                    xAxis: field.xAxis,
                    left: field.canvasLeft,
                    yAxis: field.yAxis,
                    top: field.canvasTop,
                    mediaPath: undefined,
                    type: field.type || SignatureDragType.SIGNER_FIELD,
                    pageHeight: field.pageHeight,
                    pageWidth: field.pageWidth,
                    height: field.height,
                    width: field.width,
                    data: {
                        id: field.id,
                        signerId: field.signerId,
                        email: signer.emailId,
                        name: `${signer.firstName} ${signer.lastName}`,
                        subType: field.subType,
                    },
                }
                signature[field.pageNumber].push(element)
            })
        })
    }

    return {
        ...state,
        signers: signers,
        signature: signature,
        flow:
            signers?.length > 0 || state.status === OUT_FOR_SIGNATURE
                ? SEND_FOR_SIGNATURE
                : null,
    }
}

/**
 * initialize the document
 * @param {object} state
 * @param {string} payload
 */
function initializeDocument(state, payload) {
    const { document } = payload
    let status = payload.document.status

    const data = {
        id: document.id,
        documentId: document.fileId,
        documentUrl: document.mediaUrl,
        fileName: document.fileName,
        tag: document.tag,
        createdAt: document.createdAt,
        updatedAt: document.updatedAt,
        version: document.version,
        status: status,
        currentSigner: document.currentSignerId,
        folderId: document.folderId,
        view: document.view,
        type: document.type,
        enableTemplateFlow: document.enableTemplateFlow,
        signFlowType: document.signFlowType,
    }

    return {
        ...state,
        documentDetails: data,
        status: document.status,
        disable: status !== DRAFT,
        editingMode: USER,
    }
}

/**
 * initialize the document for the public use
 * @param {*} state
 * @param {*} payload
 */
function initDocumentForPublic(state, payload) {
    const { document, signer } = payload
    const signature = {}

    const data = {
        id: document.id,
        documentId: document.fileId,
        documentUrl: document.mediaUrl,
        fileName: document.fileName,
        tag: document.tag,
        createdAt: document.createdAt,
        updatedAt: document.updatedAt,
        version: document.version,
        currentSigner:
            document.currentSignerId === -1 ? signer?.id : document.currentSignerId,
        folderId: document.folderId,
        view: document.view,
        type: document.type,
        enableTemplateFlow: document.enableTemplateFlow,
        signFlowType: document.signFlowType,
    }

    if (signer?.signerStatus !== "SIGNED") {
        signer?.fields.forEach((field) => {
            if (!signature[field.pageNumber]) {
                signature[field.pageNumber] = []
            }

            // field = { ...field, ...getDimensionOfElementPerPage(field) }

            const element = {
                id: field.id || signature[field.pageNumber].length + 1,
                xAxis: field.xAxis,
                left: field.canvasLeft,
                yAxis: field.yAxis,
                top: field.canvasTop,
                mediaPath: undefined,
                type: field.type || SignatureDragType.SIGNER_FIELD,
                isSigned: false,
                pageHeight: field.pageHeight,
                pageWidth: field.pageWidth,
                height: field.height,
                width: field.width,
                data: {
                    id: field.id,
                    signerId: field.signerId,
                    email: signer.emailId,
                    name: `${signer.firstName} ${signer.lastName}`,
                    subType: field.subType,
                },
            }
            signature[field.pageNumber].push(element)
        })
    }
    return {
        ...state,
        documentDetails: data,
        signers: document.signer,
        signature: signature,
        disable: true,
        editingMode: SIGNER,
        status: document.status,
        currentSignerStatus: signer?.signerStatus,
        typeOfSignature: signer?.typeOfSignature,
    }
}

/**
 * update the signer details like the name of signer to be included in the signature appliance.
 * @param {*} state - current sign Reducer state
 * @param {*} payload - payload with the id of property and signer id etc.
 * @returns new state.
 */
function updateSignerProperty(state, payload) {
    const newSigners = [...state.signers]
    const index = newSigners.findIndex((item) => item.id === payload.signerId)
    if (index < 0) return state
    const newSigner = { ...newSigners[index] }
    newSigner.properties = newSigner.properties.map((item) => {
        if (item.id === payload.id) item.enabled = payload.value
        return item
    })
    newSigner[index] = newSigner

    return {
        ...state,
        signers: newSigners,
    }
}

/**
 * remove the signer entity or user from the list.
 * @param {*} state - current sign Reducer state
 * @param {*} payload - payload containing the id of user to be remove
 * @returns new State.
 */
function removeSigner(state, payload) {
    let signers = [...state.signers]
    signers = signers.filter((item) => item.id !== payload.signerId)

    let signature = { ...state.signature }
    Object.keys(signature).forEach((pageNumber) => {
        Object.keys(signature[pageNumber]).forEach((elementIndex) => {
            if (
                signature[pageNumber][elementIndex].data.signerId ===
                payload.signerId
            )
                delete signature[pageNumber][elementIndex]
        })
    })
    return {
        ...state,
        signers: signers,
        signature: signature,
    }
}

/**
 * add signature element like textField, checkbox and sign component
 * to the page it belongs too.
 *
 * @param {*} state - current sign Reducer state
 * @param {*} details - details with the id, top, left, xAxis, yAxis, mediaPath and type
 * @param {*} pageIndex - page index for which this signature element belongs to.
 * @returns new State.
 */
function addSignature(state, details, pageIndex, dimension) {
    const { id, left, top, mediaPath, type, data, randomId, isTemplateItem } =
        details
    let signature = { ...(state.signature[pageIndex] || {}) }

    const isPresent = signature[id]

    if (!isPresent) {
        let newId = Object.keys(signature).slice(-1)[0]
        newId = newId ? parseInt(newId) + 1 : 1
        signature = update(signature, {
            $merge: {
                [newId]: {
                    left,
                    top,
                    mediaPath,
                    type,
                    data,
                    randomId,
                    pageHeight: dimension.height,
                    pageWidth: dimension.width,
                    isTemplateItem,
                    height: details.height,
                    width: details.width,
                },
            },
        })
    } else {
        signature = update(signature, {
            [id]: {
                $merge: { left, top, mediaPath, type },
            },
        })
    }

    return {
        ...state,
        signature: {
            ...state.signature,
            [pageIndex]: {
                ...signature,
            },
        },
        dimension: {
            ...state.dimension,
            [pageIndex + 1]: dimension,
        },
    }
}

function updateSignature(state, details, pageIndex, currentId) {
    const { randomId } = details

    let signature = { ...(state.signature[pageIndex] || {}) }
    for (let key in signature) {
        const value = signature[key]
        if (value.randomId && value.randomId === randomId) {
            if (signature[key].data) signature[key].data.id = currentId
        }
    }
    return {
        ...state,
        signature: {
            ...state.signature,
            [pageIndex]: {
                ...signature,
            },
        },
    }
}

/**
 * remove signature element from the page.
 *
 * @param {*} state - current sign state.
 * @param {*} id - id of the element to be removed.
 * @param {*} pageIndex - page Index where element belongs to.
 * @returns
 */
function removeSignatureElement(state, payload) {
    const { id, pageIndex, signerId, signId, type, isTemplateItem } = payload
    const deletedSignaturePage = { ...state.signature[pageIndex] }
    delete deletedSignaturePage[id]

    let updatedSigners = state.signers ? [...state.signers] : []
    if (type == SignatureDragType.SIGNER_FIELD && !isTemplateItem) {
        let signer = updatedSigners.find((val) => val.id === signerId)
        if (signer.fields.find((f) => f.id === signId)) {
            signer.fields = signer.fields.filter((f) => f.id !== signId)
        }
    }
    return {
        ...state,
        signers: updatedSigners,
        signature: {
            ...state.signature,
            [pageIndex]: deletedSignaturePage,
        },
    }
}

/**
 * resize handler for the element in the ui. it calls when user stops resizing the component.
 *
 * @param {*} state - current sign reducer state
 * @param {*} pageIndex - page on which element is present.
 * @param {*} id - id of the resign element.
 * @param {*} width - width of the element.
 * @param {*} height - height of the element.
 *
 * @returns new redux state
 */
function resizeHandler(
    state,
    pageIndex,
    id,
    width,
    height,
    xAxis,
    yAxis,
    top,
    left
) {
    const newSigner = { ...state.signature[pageIndex] }
    const signature = { ...newSigner[id] }

    signature.width = width
    signature.height = height

    if (xAxis !== undefined) signature.xAxis = xAxis
    if (yAxis !== undefined) signature.yAxis = yAxis
    if (top !== undefined) signature.top = top
    if (left !== undefined) signature.left = left

    newSigner[id] = signature

    return {
        ...state,
        signature: {
            ...state.signature,
            [pageIndex]: newSigner,
        },
    }
}

/**
 * drag handler for the element in the ui. it calls when user stops dragging the component.
 *
 * @param {*} state - current sign reducer state
 * @param {*} pageIndex - page on which element is present.
 * @param {*} id - id of the resign element.
 * @param {*} xAxis - x-axis of the element.
 * @param {*} yAxis - y-axis of the element.
 *
 * @returns new redux state
 */
function dragHandler(state, pageIndex, id, xAxis, yAxis, top, left) {
    const newSigner = { ...state.signature[pageIndex] }
    const signature = { ...newSigner[id] }

    signature.xAxis = xAxis
    signature.yAxis = yAxis
    signature.top = top !== undefined ? top : signature.top
    signature.left = left !== undefined ? left : signature.left

    newSigner[id] = signature

    return {
        ...state,
        signature: {
            ...state.signature,
            [pageIndex]: newSigner,
        },
    }
}

/**
 * add text data to the element itself.
 *
 * @param {*} state - current reducer state.
 * @param {*} pageIndex - page number of the element.
 * @param {*} id - id of the textField element.
 * @param {*} data - data present in the textField and
 * @returns
 */
function addData(state, pageIndex, id, data) {
    const newSigner = state.signature[pageIndex]

    const signature = { ...newSigner[id] }
    signature.data = data

    newSigner[id] = signature

    return {
        ...state,
        signature: {
            ...state.signature,
            [pageIndex]: newSigner,
        },
    }
}

/**
 * update the document details like file name and tag
 * @param {*} state
 * @param {*} payload
 */
function updateDocumentDetails(state, payload) {
    const { data } = payload

    const oldDetails = { ...state.documentDetails }
    if (data.fileId !== oldDetails.documentId) return { ...state }

    if (data.fileName !== oldDetails.fileName) oldDetails.fileName = data.fileName

    if (data.tag !== oldDetails.tag) oldDetails.tag = data.tag

    if (data.view !== oldDetails.view) oldDetails.view = data.view

    return {
        ...state,
        documentDetails: oldDetails,
        saving: false,
    }
}

/**
 * Actions for the signer or public use.
 */

/**
 * handle the signer element click
 */
function handleSignerClick(state, payload) {
    const { tool, pageIndex, elem } = payload

    return {
        ...state,
        activeTool: {
            tool,
            pageIndex,
            elem,
        },
    }
}

/**
 *
 * @param {*} state
 * @param {*} payload
 */
function handleSignerAction(state, payload) {
    if (!payload || !payload.mediaPath) {
        return { ...state, activeTool: null }
    }
    const { mediaPath, selectAll } = payload
    const data = state.activeTool
    const signature = { ...state.signature }

    if (selectAll) {
        Object.keys(signature).forEach((k) => {
            Object.keys(signature[k]).forEach((elementIndex) => {
                if (
                    signature[k][elementIndex].type ===
                    SignatureDragType.SIGNER_FIELD
                ) {
                    signature[k][elementIndex] = {
                        ...signature[k][elementIndex],
                        mediaPath,
                        isSigned: true,
                    }
                }
            })
        })
        return { ...state, activeTool: null, signature }
    }

    let fields = signature[data.pageIndex]

    let index = fields.findIndex((field) => field.data.id === data.elem.id)

    if (index > -1) {
        fields[index] = { ...fields[index], mediaPath: mediaPath, isSigned: true }
        signature[data.pageIndex] = fields
    }

    return { ...state, activeTool: null, signature }
}

/**
 * Handle the start signing event in document.
 */
function handleStartSign(state) {
    return {
        ...state,
        disable: true,
        status: OUT_FOR_SIGNATURE,
        documentDetails: {
            ...state.documentDetails,
            status: OUT_FOR_SIGNATURE,
        },
        saving: true,
    }
}

function handleRejectSign(state) {
    return {
        ...state,
        disable: true,
        status: CANCELLED,
        documentDetails: {
            ...state.documentDetails,
            status: CANCELLED,
        },
        saving: true,
    }
}

function addFieldToDocument(state, payload) {
    const { signerId } = payload.field
    let updatedSigners = [...state.signers]
    let signer = updatedSigners.find((val) => val.id === signerId)
    if (signer.fields.find((f) => f.id === payload.field.id)) {
        signer.fields = signer.fields.filter((f) => f.id !== payload.field.id)
    }
    signer.fields.push(payload.field)
    return { ...state, signers: updatedSigners }
}

function addUserSign(state, payload) {
    const { sign } = payload
    const newUserSigns = state.userSigns ? [...state.userSigns] : []
    newUserSigns.push(sign)
    return { ...state, userSigns: newUserSigns }
}

function getUserSigns(state, payload) {
    const { userSigns } = payload
    return { ...state, userSigns }
}

function deleteUserSign(state, payload) {
    const { signatureId } = payload
    let newUserSigns = [...state.userSigns]
    newUserSigns = newUserSigns.filter(
        (item) => item.id !== signatureId && item.signUrl !== signatureId
    )
    return { ...state, userSigns: newUserSigns }
}

function updateTemplateItemValue(state, payload) {
    const signature = { ...state.signature }
    const { value, detailsOfPositions } = payload
    detailsOfPositions?.map((elementPosition) => {
        const { elementId, pageIndex } = elementPosition
        const element = signature[pageIndex][elementId]
        element.data.value = value
        element.isSigned = value !== null && value !== ""
    })
    return {
        ...state,
        signature: { ...signature },
    }
}

function setTemplateView(state, payload) {
    const { view } = payload
    return {
        ...state,
        documentDetails: {
            ...state.documentDetails,
            view,
        },
    }
}

function getRolesOfTemplate(state, payload) {
    const { roles } = payload
    const fields = []
    roles?.forEach((role) => {
        role?.fields?.forEach((field) => {
            fields.push(field)
        })
    })

    const signature = signatureElementArrangingHelper(fields, roles)

    return {
        ...state,
        roles,
        signature,
    }
}

function addRoleToTemplate(state, payload) {
    const { role } = payload
    const { roles } = state
    const updatedRoles = roles ? [...roles, role] : [role]
    return {
        ...state,
        roles: updatedRoles,
    }
}

function removeRoleFromTemplate(state, payload) {
    const { roleId } = payload
    const { roles, signers, signature } = state
    const updatedRoles = roles.filter((role) => role.id !== roleId)
    updatedRoles.forEach((role, id) => {
        role.backgroundDecorum = getSignerBackgroundDecorum(
            role.roleName.slice(0, 2).toLowerCase(),
            id + 1
        )
    })
    const updatedSigners = signers.filter((signer) => signer.roleId !== roleId)
    if (signature)
        Object.keys(signature).forEach((key) => {
            const elementsInPage = signature[key]
            if (!elementsInPage) return
            Object.keys(elementsInPage).forEach((elementKey) => {
                if (elementsInPage[elementKey].data.roleId === roleId)
                    delete elementsInPage[elementKey]
            })
        })
    return {
        ...state,
        roles: updatedRoles,
        signers: updatedSigners,
        signature: { ...signature },
    }
}

function updateRoleOfTemplate(state, payload) {
    const { role } = payload
    const { roles } = state
    const updatedRoles = [...roles]
    const updatedRole = updatedRoles.find((item) => item.id === role.id)
    updatedRole.roleName = role.roleName
    updatedRole.additionalFields = role.additionalFields
    return {
        ...state,
        roles: updatedRoles,
    }
}

function getFieldsbyRoleId(state, payload) {
    const { fields } = payload
    const { roles } = state

    const signature = signatureElementArrangingHelper(fields, roles)

    return {
        ...state,
        signature,
        flow: fields?.length > 0 ? SEND_FOR_SIGNATURE : state.flow,
    }
}

function signatureElementArrangingHelper(fields, roles) {
    const signature = {}
    fields?.forEach((field) => {
        if (!signature[field.pageNumber]) {
            signature[field.pageNumber] = []
        }

        // field = { ...field, ...getDimensionOfElementPerPage(field) }

        const element = {
            id: field.id || signature[field.pageNumber].length + 1,
            xAxis: field.xAxis,
            left: field.canvasLeft,
            yAxis: field.yAxis,
            top: field.canvasTop,
            mediaPath: undefined,
            type: field.type || SignatureDragType.SIGNER_FIELD,
            pageHeight: field.pageHeight,
            pageWidth: field.pageWidth,
            roleId: field.roleId,
            roleName: roles?.find((role) => role.id === field.roleId)?.roleName,
            isTemplateItem: true,
            height: field.height,
            width: field.width,
            data: {
                id: field.id,
                roleId: field.roleId,
                roleName: roles?.find((role) => role.id === field.roleId)?.roleName,
                subType: field.subType,
            },
        }
        signature[field.pageNumber].push(element)
    })
    return signature
}

function getDimensionOfElementPerPage(field) {
    const {
        pageHeight,
        pageWidth,
        height,
        width,
        canvasLeft: left,
        canvasTop: top,
        xAxis,
        yAxis,
    } = field
    const { height: currentHeightOfPage, width: currentWidthOfPage } = getCanvasSize(
        pageHeight / pageWidth
    )
    const heightRatio = currentHeightOfPage / pageHeight
    const widthRatio = currentWidthOfPage / pageWidth
    const newHeight = height * heightRatio
    const newWidth = width * widthRatio
    const newLeft = left * widthRatio
    const newTop = top * heightRatio
    const newXAxis = xAxis * widthRatio
    const newYAxis = yAxis * heightRatio
    return {
        height: newHeight,
        width: newWidth,
        canvasLeft: newLeft,
        canvasTop: newTop,
        xAxis: newXAxis,
        yAxis: newYAxis,
    }
}
