import { Button, CheckBox, Text } from "@awesomesuite-frontend/awesome-nebula"
import Table from "Awesome-ui/Table/Table"
import TableHeader from "Awesome-ui/TableHeader/TableHeader"
import TableRow from "Awesome-ui/TableRow/TableRow"
import React from "react"
import { createUseStyles } from "react-jss"
import NoTemplates from "./NoTemplates"
import EachTemplate from "./EachTemplate"
import { ReactComponent as SortIcon } from "Assets/Inbox/sortIcon.svg"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import NoTemplatesOnSearch from "./NoTemplateOnSearch"
import useQuery from "hooks/UseQuery"
import { ReactComponent as Delete } from "Assets/Storage/delete-icon.svg"
import Input from "Awesome-ui/Input/Input"

const sortType = [
    "",
    "fileName,asc",
    "fileName,desc",
    "",
    "",
    "status,asc",
    "status,desc",
    "createdAt,asc",
    "createdAt,desc",
    "updatedAt,asc",
    "updatedAt,desc",
]

const useStyles = createUseStyles({
    templateListsWrapper: {
        margin: "1rem",
    },
    deleteFiles: {
        border: "1px solid #d6dfe8",
        borderRadius: 3,
        padding: "0.25rem 0.5rem",
        cursor: "pointer",
        marginLeft: "1rem",
        background: "#fff",
        "& div": {
            display: "flex",
            alignItems: "center",
        },
    },
    searchBoxInput: {
        "&::placeholder": {
            fontSize: "0.9rem",
            color: "#ccd6e2",
        },
    },
    templatesTableHeader: {
        width: "100%",
        border: "1px solid #d6dfe8",
        borderBottom: "none",
        backgroundColor: "#f0f1f3e6",
        display: "flex",
        alignItems: "center",
    },
})

const headerStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}

const TemplateLists = ({
    templates,
    selection = {},
    onDeleteFile = async () => {},
    setselection,
    selectFile,
    onSearchFileEmpty,
    sortOrder,
    setSortOrder,
    deleteMultipleFileHandler,
    searchQuery,
    setSearchQuery,
}) => {
    const classes = useStyles()
    const { addQuery, removeQuery } = useQuery()
    const checkAll = () => {
        if (!templates) return
        const condition = templates?.every((f) => selection[f.fileId] === true)
        setselection(templates, condition ? false : true)
    }
    const selectedFilesCount = Object.values(selection).filter(
        (e) => e === true
    ).length

    return (
        <div className={classes.templateListsWrapper}>
            <div className={classes.templatesTableHeader}>
                <Input
                    style={{
                        width: "100%",
                        maxWidth: "250px",
                        fontSize: "1rem",
                        padding: "10px 18px",
                    }}
                    inputClassname={classes.searchBoxInput}
                    value={searchQuery}
                    changeHandler={setSearchQuery}
                    placeHolder="Search templates"
                    icon="search"
                    iconColor="#1ECBE5"
                    inputStyle={{
                        padding: "5px 10px",
                    }}
                />
                {selectedFilesCount > 0 && (
                    <>
                        <div className={classes.countFiles}>
                            <Text fontSize={14} color="#00B5D1">
                                {`${selectedFilesCount} templates selected`}
                            </Text>
                        </div>
                        <Button
                            className={classes.deleteFiles}
                            onClick={deleteMultipleFileHandler}
                        >
                            <div>
                                <Delete />
                                <Text fontSize={14} marginLeft="0.5rem">
                                    Delete
                                </Text>
                            </div>
                        </Button>
                    </>
                )}
            </div>
            <Table style={{ backgroundColor: "#fff" }}>
                <TableRow
                    style={{
                        background: "#fff",
                        border: " 1px solid #d6dfe8",
                        boxShadow: "0px 3px 3px rgba(181,197,214,0.24)",
                        borderBottomColor: templates ? "none" : "#d6dfe8",
                    }}
                >
                    <TableHeader align="left" width="60px">
                        <CheckBox
                            active={
                                templates?.length > 0
                                    ? templates?.every(
                                          (f) => selection[f.fileId] === true
                                      )
                                    : false
                            }
                            clickFunction={checkAll}
                            color={"white"}
                            backgroundColor={"rgb(0, 189, 165)"}
                            variant={"curved"}
                            border={"lightblue"}
                        />
                    </TableHeader>
                    <TableHeader align="left" width="27.5%">
                        <div
                            style={{
                                ...headerStyle,
                                width: "fit-content",
                            }}
                        >
                            <Text fontSize={14}>Document Name</Text>
                            <TooltipWrapper text="Sort by File Name" direction="top">
                                <UpdatedSortIcon
                                    sortOrder={sortOrder}
                                    up={1}
                                    down={2}
                                    onClick={() => {
                                        if (sortOrder === 1) {
                                            addQuery("sort", sortType[2])
                                            setSortOrder(2)
                                        } else if (sortOrder === 2) {
                                            removeQuery("sort")
                                            setSortOrder(0)
                                        } else {
                                            addQuery("sort", sortType[1])
                                            setSortOrder(1)
                                        }
                                    }}
                                />
                            </TooltipWrapper>
                        </div>
                    </TableHeader>
                    <TableHeader>
                        <div style={{ ...headerStyle }}>
                            <Text fontSize={14}>Owner</Text>
                        </div>
                    </TableHeader>
                    <TableHeader align="center">
                        <div style={{ ...headerStyle }}>
                            <Text fontSize={14}>Created On</Text>
                            <TooltipWrapper text="Sort by Created" direction="top">
                                <UpdatedSortIcon
                                    sortOrder={sortOrder}
                                    up={7}
                                    down={8}
                                    onClick={() => {
                                        if (sortOrder === 7) {
                                            addQuery("sort", sortType[8])
                                            setSortOrder(8)
                                        } else if (sortOrder === 8) {
                                            removeQuery("sort")
                                            setSortOrder(0)
                                        } else {
                                            addQuery("sort", sortType[7])
                                            setSortOrder(7)
                                        }
                                    }}
                                />
                            </TooltipWrapper>
                        </div>
                    </TableHeader>
                    <TableHeader align="center">
                        <div style={{ ...headerStyle }}>
                            <Text fontSize={14}>Last Updated</Text>
                            <TooltipWrapper text="Sort by Updated" direction="top">
                                <UpdatedSortIcon
                                    sortOrder={sortOrder}
                                    up={9}
                                    down={10}
                                    onClick={() => {
                                        if (sortOrder === 9) {
                                            addQuery("sort", sortType[10])
                                            setSortOrder(10)
                                        } else if (sortOrder === 10) {
                                            removeQuery("sort")
                                            setSortOrder(0)
                                        } else {
                                            addQuery("sort", sortType[9])
                                            setSortOrder(9)
                                        }
                                    }}
                                />
                            </TooltipWrapper>
                        </div>
                    </TableHeader>
                    <TableHeader width="5%" align="center"></TableHeader>
                </TableRow>

                {templates &&
                    templates.length > 0 &&
                    templates.map((item, index) => (
                        <EachTemplate
                            template={item}
                            key={index}
                            selectFun={() => {
                                if (selection[item.fileId])
                                    selectFile(false, item.fileId)
                                else selectFile(true, item.fileId)
                            }}
                            isChecked={selection[item.fileId]}
                            userName={`${
                                item?.owner?.firstName
                                    ? item?.owner?.firstName +
                                      " " +
                                      item?.owner?.lastName
                                    : item?.owner?.userName
                            }`}
                            userEmail={item?.owner?.emailId}
                            userPicture={item?.owner.picture}
                            onDeleteFile={onDeleteFile}
                        />
                    ))}
            </Table>
            {(!templates || templates.length === 0) &&
                (onSearchFileEmpty ? (
                    <NoTemplatesOnSearch backgroundColor="#f4f7f9" />
                ) : (
                    <NoTemplates backgroundColor="#f4f7f9" />
                ))}
        </div>
    )
}

function UpdatedSortIcon({ sortOrder = -1, up, down, onClick = () => {} }) {
    return (
        <div
            style={{
                height: "fit-content",
                width: "fit-content",
                marginLeft: "4.5px",
            }}
            onClick={onClick}
        >
            <div
                style={{
                    height: "50%",
                    width: "fit-content",
                    cursor: "pointer",
                }}
            >
                <SortIcon className={`${sortOrder === up && "sort-icon-active"}`} />
            </div>
            <div
                style={{
                    transform: "rotate(180deg)",
                    height: "50%",
                    cursor: "pointer",
                    width: "fit-content",
                }}
            >
                <SortIcon
                    className={`${sortOrder === down && "sort-icon-active"}`}
                />
            </div>
        </div>
    )
}

export default TemplateLists
