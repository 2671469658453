import { InputUtility, Text } from "@awesomesuite-frontend/awesome-nebula"
import { setWorkspaceUsers } from "Action/workspaceAction"
import InputBox from "Awesome-ui/InputBox"
import Loader from "Awesome-ui/Loader/Loader"
import PageLoading from "Awesome-ui/PageLoading/PageLoading"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
    addTeamMember,
    getAllFolders,
    getUserByFolderId,
} from "Action/foldersAction"
import { folderIcons } from "data/folderIcons"
import { rolesDescription } from "data/rolesDescription"
import { ReactComponent as DownArrow } from "../../../../Assets/settings/sortArrow.svg"
import User from "./User"
import "./User.scss"
import { getPermissions } from "Action/permissionAction"
import Guard from "Awesome-ui/Guard"
import Input from "Awesome-ui/Input/Input"
import { OWNER } from "Types"

const Users = ({
    users,
    allFolders,
    roles,
    workspace,
    currentUserId,
    currentUserRole,
    userRole,
    ...props
}) => {
    const [usersList, setUsersList] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [searchByName, setSearchByName] = useState("")
    const [sortOrder, setSortOrder] = useState(0)

    const [inviteTeamMemberName, setInviteTeamMemberName] = useState("")
    const [dropDownOneContent, setDropdownOneContent] = useState([])
    const [dropDownOneValue, setDropdownOneValue] = useState({})
    const [dropDownTwoContent, setDropdownTwoContent] = useState([])
    const [dropDownTwoValue, setDropdownTwoValue] = useState({})

    const addMemberHandler = async () => {
        if (!inviteTeamMemberName || !dropDownOneValue || !dropDownTwoValue) return
        const user_name = inviteTeamMemberName.split("@")[0].trim()
        const data = {
            user_name,
            email_id: inviteTeamMemberName,
            role_id: dropDownOneValue.id,
            brand_id: dropDownTwoValue.id,
            folder_id: dropDownTwoValue.id,
        }
        await addTeamMember(data, false)
        await setWorkspaceUsers(false)
        getUserByFolderId(dropDownTwoValue.id)
        setInviteTeamMemberName("")
        setDropdownOneValue({})
        setDropdownTwoValue({})
    }

    const setNewRoles = () => {
        return new Promise((resolve, reject) => {
            let newRoles = roles?.map((role) => {
                if (!rolesDescription[role?.roleName])
                    return {
                        id: role.roleId,
                        name: role.roleName,
                    }

                return {
                    id: role.roleId,
                    ...rolesDescription[role?.roleName],
                }
            })
            resolve(newRoles)
        })
    }

    function getIcon(item) {
        const currentFolderIconDetails = folderIcons.find(
            (icon) => icon.name === item?.icon
        )

        if (currentFolderIconDetails) return currentFolderIconDetails?.logo
        if (item.icon) return item.icon
        return folderIcons[0].logo
    }

    useEffect(() => {
        if (!workspace || !workspace[0]?.id) return
        const init = async () => {
            if (!users) {
                await setWorkspaceUsers(false)
            }
            if (!allFolders) {
                await getAllFolders()
            }
            await setNewRoles().then((val) => setDropdownOneContent(val))
        }
        init()

        if (Array.isArray(users)) setUsersList(users)
    }, [users, workspace, allFolders, roles])

    useEffect(() => {
        if (allFolders) {
            let arr = allFolders
                .filter((folder) => folder.name.toLowerCase() !== "my documents")
                .map((folder) => ({
                    id: folder.id,
                    name: folder.name,
                    image: getIcon(folder),
                }))
            setDropdownTwoContent(arr)
        }
    }, [allFolders])

    useEffect(() => {
        if (users) {
            let updatedUserList = []
            users.map((e) => {
                if (e.user_name?.includes(searchByName)) updatedUserList.push(e)
            })

            //Implementation of sorting documents
            switch (sortOrder) {
                case 0: {
                    setUsersList(updatedUserList)
                    break
                }
                case 1: {
                    let updatedList = updatedUserList.sort((a, b) =>
                        a.user_name > b.user_name
                            ? 1
                            : b.user_name > a.user_name
                            ? -1
                            : 0
                    )
                    setUsersList(updatedList)
                    break
                }
                case 2: {
                    let updatedList = updatedUserList.sort((a, b) =>
                        a.user_name < b.user_name
                            ? 1
                            : b.user_name < a.user_name
                            ? -1
                            : 0
                    )
                    setUsersList(updatedList)
                    break
                }
                case 3: {
                    let updatedList = updatedUserList.sort((a, b) =>
                        a.roleId > b.roleId ? 1 : b.roleId > a.roleId ? -1 : 0
                    )
                    setUsersList(updatedList)
                    break
                }
                case 4: {
                    let updatedList = updatedUserList.sort((a, b) =>
                        a.roleId < b.roleId ? 1 : b.roleId < a.roleId ? -1 : 0
                    )
                    setUsersList(updatedList)
                    break
                }
            }
            setUsersList(updatedUserList)
        }
    }, [users, searchByName, sortOrder])

    if (!users || !allFolders) return <PageLoading style={{ width: "85%" }} />
    return (
        <div className="user-setting">
            <div className="user-title">
                <h3>Users</h3>
            </div>
            <div className="user-widget">
                <div className="user-left">
                    <Input
                        placeHolder="Search by name"
                        name="search"
                        type="text"
                        value={searchByName}
                        changeHandler={setSearchByName}
                        reverse
                        inputClassname={"user-search"}
                    />
                </div>
                <Guard scope="collaborate_create">
                    <div className="user-right">
                        <InputUtility
                            inputField={true}
                            inputValue={inviteTeamMemberName}
                            setInputValue={(val) => setInviteTeamMemberName(val)}
                            inputPlaceholder="Enter Email or Username"
                            inputType="text"
                            dropdownOne={true}
                            dropDownOneContent={dropDownOneContent}
                            dropDownOneValue={dropDownOneValue}
                            dropdownStyle={{ maxWidth: 150 }}
                            setDropdownOneValue={setDropdownOneValue}
                            dropdownTwo={true}
                            dropDownTwoContent={dropDownTwoContent}
                            dropDownTwoValue={dropDownTwoValue}
                            setDropdownTwoValue={setDropdownTwoValue}
                            dropdownTwoDisabled={false}
                            button={true}
                            buttonAction={addMemberHandler}
                            buttonText={"Invite"}
                        />
                    </div>
                </Guard>
            </div>
            <div className="workplace-list">
                <div className="react-table">
                    <div className="react-table-row">
                        <div
                            className="react-table-cell"
                            style={{ width: 5, padding: 0 }}
                        ></div>
                        <div
                            className="react-table-cell"
                            style={{ width: "calc(5% - 5px)" }}
                        >
                            <div className="checkbox">
                                <div
                                    className="box"
                                    onClick={(e) => {
                                        let userIds = []
                                        if (selectedUsers.length === users.length) {
                                            setSelectedUsers([])
                                            return
                                        }
                                        users.map((use) => {
                                            userIds.push(use.id)
                                        })
                                        setSelectedUsers(userIds)
                                        e.stopPropagation()
                                    }}
                                    style={{
                                        color: !(
                                            users.length === selectedUsers.length
                                        )
                                            ? "white"
                                            : "black",
                                        backgroundColor:
                                            users.length === selectedUsers.length
                                                ? "gray"
                                                : "#fff",
                                    }}
                                >
                                    {users.length === selectedUsers.length ? (
                                        <>✔</>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className={`react-table-cell sort-cell ${
                                sortOrder === 1 || sortOrder === 2
                                    ? "activeHeader"
                                    : ""
                            }`}
                            style={{
                                width: "35%",
                                textAlign: "left",
                                paddingLeft: "6%",
                            }}
                            highlight
                        >
                            <Text className="Header">NAME</Text>
                            <div className="arrows">
                                <DownArrow
                                    className={sortOrder !== 1 ? "activeSort" : ""}
                                    onClick={() =>
                                        sortOrder !== 1
                                            ? setSortOrder(1)
                                            : setSortOrder(0)
                                    }
                                />
                                <DownArrow
                                    className={sortOrder !== 2 ? "activeSort" : ""}
                                    onClick={() =>
                                        sortOrder !== 2
                                            ? setSortOrder(2)
                                            : setSortOrder(0)
                                    }
                                />
                            </div>
                        </div>
                        <div
                            className="react-table-cell"
                            style={{ width: "15%", padding: 0 }}
                        ></div>
                        <div
                            className={`react-table-cell sort-cell ${
                                sortOrder === 3 || sortOrder === 4
                                    ? "activeHeader"
                                    : ""
                            }`}
                            style={{ width: "30%", textAlign: "left" }}
                            align="center"
                        >
                            <Text className="Header">ROLE</Text>
                            <div className="arrows">
                                <DownArrow
                                    className={sortOrder !== 3 ? "activeSort" : ""}
                                    onClick={() =>
                                        sortOrder !== 3
                                            ? setSortOrder(3)
                                            : setSortOrder(0)
                                    }
                                />
                                <DownArrow
                                    className={sortOrder !== 4 ? "activeSort" : ""}
                                    onClick={() =>
                                        sortOrder !== 4
                                            ? setSortOrder(4)
                                            : setSortOrder(0)
                                    }
                                />
                            </div>
                        </div>
                        {/* <div
                            className={`react-table-cell sort-cell ${
                                sortOrder === 5 || sortOrder === 6
                                    ? "activeHeader"
                                    : ""
                            }`}
                            style={{ width: "35%", textAlign: "left" }}
                            align="center"
                        >
                            <div className="Header">LAST ACTIVITY</div>
                            <div className="arrows">
                                <DownArrow
                                    className={sortOrder !== 5 ? "activeSort" : ""}
                                    onClick={() =>
                                        sortOrder !== 5
                                            ? setSortOrder(5)
                                            : setSortOrder(0)
                                    }
                                />
                                <DownArrow
                                    className={sortOrder !== 6 ? "activeSort" : ""}
                                    onClick={() =>
                                        sortOrder !== 6
                                            ? setSortOrder(6)
                                            : setSortOrder(0)
                                    }
                                />
                            </div>
                        </div> */}
                        <div
                            className="react-table-cell"
                            style={{ width: "10%" }}
                        ></div>
                        <div
                            className="react-table-cell"
                            style={{ width: "10%" }}
                        ></div>
                    </div>
                    {usersList ? (
                        usersList.map((user, index) => {
                            return (
                                <User
                                    user={user}
                                    key={index}
                                    users={users}
                                    allFolders={allFolders}
                                    roles={roles}
                                    selectedUsers={selectedUsers}
                                    setSelectedUsers={setSelectedUsers}
                                    getIcon={getIcon}
                                    dropDownTwoContent={dropDownTwoContent}
                                    currentUserId={currentUserId}
                                    isAdminUser={currentUserRole === 11}
                                    isUserOwner={userRole?.toUpperCase() === OWNER}
                                />
                            )
                        })
                    ) : (
                        <Loader
                            h="1rem"
                            w="1rem"
                            b="6px"
                            bk="#7fd1de"
                            clr="#00cae9"
                            t="1s"
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({
    workspaceReducer,
    foldersReducer,
    roleReducer,
    userReducer,
}) => ({
    users: workspaceReducer?.users,
    allFolders: foldersReducer.folderList,
    roles: roleReducer?.role,
    workspace: workspaceReducer?.workspace,
    currentUserId: userReducer?.userDetails?.id,
    currentUserRole: workspaceReducer?.currentWorkspace?.roleId,
    userRole: userReducer.userRole,
})

export default connect(mapStateToProps)(Users)
