import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { setActiveTool } from "Action/signAction"
import { TemplateElementsDragType } from "Components/PDFCommons/container/Types"
import { SIDEBAR_ITEM, TEMPLATE_ITEM } from "Pages/PdfEditor/container/Constants"
import CustomDragLayer from "Pages/PdfEditor/container/CustomDragLayer"
import DragItem from "Pages/PdfEditor/container/DragItem"
import { DRAFT } from "Types"
import React from "react"
import { createUseStyles } from "react-jss"
import { connect } from "react-redux"

const useStyles = createUseStyles((theme) => ({
    eachRoleFieldWrapper: {
        marginBottom: "0.75rem",
        width: "47.5%",
    },
    eachRoleField: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        border: "1px solid #CBD6E2",
        borderRadius: 6,
        padding: "0.5rem",
        backgroundColor: "#fff",
        cursor: "all-scroll",
    },
}))

const getElementSize = (dragToolRef, type) => {
    let height = dragToolRef.current?.getBoundingClientRect().height
    let width = dragToolRef.current?.getBoundingClientRect().width

    if (type === TemplateElementsDragType.CHECKBOX) return { height: 20, width: 20 }
    if (type === TemplateElementsDragType.DATE) return { height: 35.08, width: 157 }

    return { height, width }
}

const TemplateDragElements = ({
    templateElementType,
    subType,
    Icon,
    roleId,
    roleName,
    type,
    status,
}) => {
    const classes = useStyles()
    const [elementOffset, setElementOfset] = React.useState(null)
    const dragToolRef = React.useRef(null)

    function addField(additionalData = {}) {
        return setActiveTool({
            data: {
                roleId,
                subType,
                roleName,
                value: "",
            },
            top: 0,
            left: 0,
            type,
            isTemplateItem: true,
            subType,
            roleId,
            roleName,
            ...additionalData,
        })
    }

    const calculatedElementSize = getElementSize(dragToolRef, type)

    return (
        <div className={classes.eachRoleFieldWrapper}>
            <DragItem
                data={{
                    id: 101,
                    type: TEMPLATE_ITEM,
                    component: {
                        type: templateElementType,
                        content: null,
                    },
                    elementOffset,
                    setToolSignerField: () => addField(),
                    setElementOfset,
                    height: calculatedElementSize.height,
                    width: calculatedElementSize.width,
                    disabled: status !== DRAFT,
                }}
            >
                <div
                    className={classes.eachRoleField}
                    ref={dragToolRef}
                    onMouseDown={(e) => {
                        const rect = dragToolRef.current.getBoundingClientRect()
                        const x = e.clientX - rect.left
                        const y = e.clientY - rect.top
                        setElementOfset({ x, y })
                    }}
                >
                    {Icon && <Icon height={20} width={20} fill="#33475b" />}
                    <Text marginLeft="0.5rem" varient="subText">
                        {subType}
                    </Text>
                </div>
            </DragItem>
            <CustomDragLayer />
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    status: signReducer.status,
})

export default connect(mapStateToProps)(TemplateDragElements)
