import React from 'react';
import "./TableHeader.scss";

const TableHeader = ({align, width, ...props}) => {
    return (
        <th className="tableHeader" align={align} style={{width: width, ...props}}>
            {props.children}
        </th>
    )
}

export default TableHeader;
