import React, { useEffect, useState } from "react"
import {
    setGloableLoading,
    signOut,
    userLoginManual,
    userLoginWithAccountByPass,
} from "../../Action/userAction"
import "./Components/Login.scss"
import { getLinkToService } from "data/ProductMapping"
import { setWorkspaceLoaded, setWorkspaceLoading } from "Action/workspaceAction"
import { useHistory } from "react-router-dom"
import { connect } from "react-redux"

const SignInPage = ({ redirectToken, gloableLoading, ...props }) => {
    const [loading, setLoading] = useState(false)
    let history = useHistory()
    console.log(">>>>>>>>>>>>>. rending login ")
    /**
     * handle the by pass when user is redirected from
     * from the awesome accounts universal login...
     *
     * here "p" in params is token for login.
     */
    useEffect(() => {
        async function bypass() {
            // if(loading) return;
            if (gloableLoading) return

            const search = props.location.search
            let parsedUrl = new URLSearchParams(search)

            let accLink = `${getLinkToService("account")}/login?service=sign&rd=${
                window.location.origin
            }`

            let isLogout = parsedUrl.get("logout") === "true"

            if (!parsedUrl.get("p")) {
                if (isLogout) accLink = accLink.replace("login", "logout")
                window.location.replace(accLink)
                setLoading(false)
                setWorkspaceLoaded()
                setGloableLoading(false)
                return
            }

            if (redirectToken === parsedUrl.get("p") && !isLogout) return

            setLoading(true)
            setGloableLoading(true)
            setWorkspaceLoading()
            signOut()
            const token = parsedUrl.get("p")
            let autoOnboard = false

            if (parsedUrl.get("onboardtype")) autoOnboard = true
            const res = await userLoginWithAccountByPass(token, autoOnboard)

            //If the user is auto onboarded, it will be redirected to dashboard
            if (res === "auto-onboarded") {
                history.push("/")
                return setGloableLoading(false)
            } else if (res === "getting-started") {
                history.push("/get-started")
                return setGloableLoading(false)
            }
            //If the user doesn't have workspaces, it will be redirected to onboarding
            else if (res === "onboarding-required") {
                history.push("/onboarding")
                return setGloableLoading(false)
            }
            //If the user is not auto onboarded, it will be redirected to onboarding
            // or to the dashboard based on the onboard flag in the url
            else if (res) {
                if (parsedUrl.get("onboard")) history.push("/onboarding")
                else history.push("/")
                return setGloableLoading(false)
            }
            // If the user is not auto onboarded and the token is invalid,
            // it will be redirected to login
            else {
                if (!isLogout) accLink = accLink.replace("login", "logout")
                window.location.replace(accLink)
                return setGloableLoading(false)
            }
        }
        bypass()
    }, [])

    return <div className="signIn green-bg"></div>
}

const mapStateToProps = ({ userReducer }) => ({
    gloableLoading: userReducer?.gloableLoading,
    redirectToken: userReducer?.redirectToken,
})

export default connect(mapStateToProps)(SignInPage)
