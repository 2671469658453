import { getAllDocuments, paginationRequest } from "Action/documentAction"
import {
    deleteFolderById,
    getAllFolders,
    getUserByFolderId,
    setCurrentFolder,
} from "Action/foldersAction"
import { folderIcons } from "data/folderIcons"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import UseQuery from "hooks/UseQuery"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import Files from "../SelectedFolder/Files"
import StoreTop from "../StoreTop/StoreTop"

function getLogoOfFolder(folderList = [], currentFolder) {
    if (!folderList || folderList.length < 1) return
    const folder = folderList.find((folder) => folder.id === currentFolder)
    const currentFolderIconDetails = folderIcons.find(
        (icon) => icon.name === folder?.icon
    )

    if (currentFolderIconDetails) return currentFolderIconDetails?.logo
    if (folder.icon) return folder.icon
    return folderIcons[0].logo
}

const StoreRightFolder = ({
    setEditFolder,
    addNewMemberModal,
    selection,
    folderList,
    userDetails,
    documents,
    setselection,
    selectFile,
    clearSelection,
    setEditMemberModal,
}) => {
    const [users, setUsers] = useState([])
    const history = useHistory()
    const { folderId } = useParams()
    const { addQuery, getQuery, removeQuery } = UseQuery()
    const [currentDocuments, setCurrentDocuments] = useState([])
    const [documentLoading, setDocumentLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const { splitText } = useBasicUtils()

    const reload = async () => {
        await teamMemberhandler()
        const currentPage = Number(getQuery("page"))
        const sort = getQuery("sort")
        const searchQuery = getQuery("search")

        if (currentPage !== 1) addQuery("page", "1")
        if (sort) removeQuery("sort")
        if (searchQuery) removeQuery("search")
        await getCurrentDocuments()
    }

    const getCurrentDocuments = async () => {
        const userId = userDetails?.id
        const currentPage = Number(getQuery("page"))
        const sort = getQuery("sort")
        const size = Number(getQuery("size"))
        const searchQuery = getQuery("search")

        setDocumentLoading(true)
        const res = await paginationRequest(
            userId,
            currentPage,
            "",
            sort,
            folderId,
            documents?.content?.length,
            size,
            searchQuery
        )
        if (res?.content) {
            setCurrentDocuments(res.content)
            setTotalPages(res.totalPages)
        }
        setDocumentLoading(false)
    }
    const deleteFolderHandler = async (data) => {
        setDocumentLoading(true)
        const res = await deleteFolderById(data)
        if (res) {
            history.push("/documents" + history.location.search)
            await getAllFolders()
            setCurrentFolder(-1)
        }
        setDocumentLoading(false)
    }

    async function teamMemberhandler() {
        return new Promise((resolve, reject) => {
            const currentFolderData = folderList?.find(
                (folder) => folder.id === Number(folderId)
            )
            if (
                Array.isArray(currentFolderData?.users) &&
                currentFolderData?.users.length > 0
            ) {
                let currentUsers = currentFolderData?.users?.map((user) => {
                    let name
                    if (!user.first_name) name = user.user_name
                    else name = user.first_name + " " + user.last_name
                    let userData = {
                        id: user.id,
                        name,
                        imageSource: user.picture,
                        email: user.email_id,
                        role: user.role,
                        isPending: user.status === 0,
                        role_id: user.role_id,
                    }
                    return userData
                })
                resolve(currentUsers)
            } else resolve([])
        })
    }

    useEffect(() => {
        const init = async () => {
            await getUserByFolderId(Number(folderId))
            const newUsers = await teamMemberhandler()
            setUsers(newUsers)
        }
        if (folderList) {
            setCurrentFolder(Number(folderId), false)
            init()
        }
    }, [folderId, folderList])

    useEffect(() => {
        getCurrentDocuments()
    }, [history.location.search, folderId])

    return (
        <div className="store-page-wrapper">
            <StoreTop
                showModalHandler={setEditFolder}
                logo={getLogoOfFolder(folderList, Number(folderId))}
                title={
                    folderList?.find((folder) => folder.id === Number(folderId))
                        ?.name
                }
                threeDotView
                addNewMemberModal={addNewMemberModal}
                reloadHandler={async () => {
                    await getUserByFolderId(Number(folderId))
                    await getAllFolders()
                    reload()
                }}
                users={users}
                deleteFolderHandler={deleteFolderHandler}
                setEditMemberModal={setEditMemberModal}
            />
            <div className="store-page-right">
                <Files
                    files={currentDocuments}
                    selection={selection}
                    totalPages={totalPages}
                    onDeleteFile={reload}
                    documentLoading={documentLoading}
                    setselection={setselection}
                    selectFile={selectFile}
                    clearSelection={clearSelection}
                />
            </div>
        </div>
    )
}

const mapStateToProps = ({ documentReducer, foldersReducer, userReducer }) => {
    return {
        documents: documentReducer.documents,
        folderList: foldersReducer?.folderList,
        userDetails: userReducer?.userDetails,
    }
}

export default connect(mapStateToProps)(StoreRightFolder)
