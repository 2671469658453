import { createUseStyles } from "react-jss"

export const useStyles = createUseStyles(() => ({
    getStartedWrapper: {
        minHeight: "92vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    getStartedBox: {
        boxShadow: "2px 3px 7px #DFE3EB",
        padding: "2rem",
        borderRadius: 12,
        border: "1px solid #DFE3EB",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fff",
    },
}))
