import { InputUtility, Text, Button } from "@awesomesuite-frontend/awesome-nebula"
import React, { useState, useEffect } from "react"
import { ReactComponent as CancelIcon } from "../../Assets/Storage/cancel-icon.svg"
import { connect } from "react-redux"
import { useStyles } from "./style"
import { rolesDescription } from "data/rolesDescription"
import { addTeamMember, getUserByFolderId } from "Action/foldersAction"
import { getPermissions } from "Action/permissionAction"
import PageLoading from "Awesome-ui/PageLoading/PageLoading"
import { setWorkspaceUsers } from "Action/workspaceAction"

const AddTeamMember = ({
    setShowModal,
    currentFolderId,
    roles,
    token,
    rolesLoading,
}) => {
    const classes = useStyles()
    const [userEmails, setUserEmails] = useState([""])
    const [dropDownOneContent, setDropdownOneContent] = useState([])
    const [dropDownOneValues, setDropdownOneValues] = useState([{}])
    const [loading, setLoading] = useState(false)

    async function addMemberHandler() {
        let validation = userEmails.find((ue) => ue === "")
        validation =
            validation || dropDownOneValues.find((val) => val.id === undefined)
        if (validation || currentFolderId === -1) return
        setLoading(true)
        await Promise.all(
            Array(userEmails.length)
                .fill(0)
                .map((val, key) => {
                    const user_name = userEmails[key].split("@")[0].trim()
                    const data = {
                        user_name,
                        first_name: user_name,
                        email_id: userEmails[key],
                        role_id: dropDownOneValues[key].id,
                        brand_id: currentFolderId,
                        folder_id: currentFolderId,
                    }
                    return addTeamMember(data, false)
                })
        )
        await getUserByFolderId(Number(currentFolderId), true)
        await setWorkspaceUsers(false)
        setUserEmails([""])
        setDropdownOneValues([{}])
        setLoading(false)
        setShowModal(false)
    }

    const setNewRoles = () => {
        return new Promise((resolve, reject) => {
            let newRoles = roles?.map((role) => {
                if (!rolesDescription[role?.roleName])
                    return {
                        id: role.roleId,
                        name: role.roleName,
                    }

                return {
                    id: role.roleId,
                    ...rolesDescription[role?.roleName],
                }
            })
            resolve(newRoles)
        })
    }

    async function init() {
        if (!roles) {
            setLoading(true)
            await getPermissions()
            setLoading(false)
            // if(!newRoles)   newRoles = []
        } else {
            setLoading(true)
            await setNewRoles().then((val) => setDropdownOneContent(val))
            setLoading(false)
        }
    }

    const inputUtilityHandler = () => {
        setUserEmails([...userEmails, ""])
        setDropdownOneValues([...dropDownOneValues, {}])
    }
    const cancelHandler = (key) => {
        setUserEmails([...userEmails.slice(0, key), ...userEmails.slice(key + 1)])
        setDropdownOneValues([
            ...dropDownOneValues.slice(0, key),
            ...dropDownOneValues.slice(key + 1),
        ])
    }

    useEffect(() => {
        init()
    }, [roles, token])

    return (
        <div className={classes.backgroundCompound}>
            <div className={classes.createFolderModal}>
                <div className={classes.modalHeader}>
                    <Text marginLeft="1rem">Invite Users</Text>
                    <div
                        className={classes.cancelLogo}
                        onClick={() => setShowModal(false)}
                    >
                        <CancelIcon />
                    </div>
                </div>
                <div className={classes.modalContent}>
                    {userEmails.map((val, key) => {
                        return (
                            <div className={classes.inputUtilityHolder}>
                                <InputUtility
                                    inputField={true}
                                    inputValue={userEmails[key]}
                                    setInputValue={(val) => {
                                        let currentValues = [...userEmails]
                                        currentValues[key] = val
                                        setUserEmails(currentValues)
                                    }}
                                    inputPlaceholder="Enter email address to invite"
                                    inputType="email"
                                    dropdownOne={true}
                                    dropDownOneContent={dropDownOneContent}
                                    dropDownOneValue={dropDownOneValues[key]}
                                    setDropdownOneValue={(val) => {
                                        let currentValues = [...dropDownOneValues]
                                        currentValues[key] = val
                                        setDropdownOneValues(currentValues)
                                    }}
                                    button={userEmails.length === 1}
                                    buttonAction={addMemberHandler}
                                    buttonText="Invite"
                                    cancel={true}
                                    cancelAction={() => cancelHandler(key)}
                                />
                            </div>
                        )
                    })}
                    {userEmails.length > 1 && (
                        <Button
                            style={{
                                marginTop: "1rem",
                                paddingLeft: "1.8rem",
                            }}
                            color="gray-1"
                            width={"100px"}
                            onClick={addMemberHandler}
                        >
                            Invite
                        </Button>
                    )}
                    <div className={classes.addMemberSwitch}>
                        <div>
                            <div className={classes.addMemberSwitchContent}>
                                <button
                                    className={classes.addMember}
                                    onClick={inputUtilityHandler}
                                >
                                    +
                                </button>
                                <Text color="#00bda5" fontSize={14}>
                                    Add More Collaborators
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Loading = () => {
    const classes = useStyles()
    return (
        <div className={classes.loader}>
            <PageLoading />
        </div>
    )
}

const mapStateToProps = ({ foldersReducer, roleReducer, userReducer }) => {
    return {
        currentFolderId: foldersReducer?.currentFolder,
        roles: roleReducer?.role,
        token: userReducer?.token,
        rolesLoading: roleReducer?.loading,
    }
}

export default connect(mapStateToProps)(AddTeamMember)
