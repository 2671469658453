import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    verticalTracker: {
        position: "absolute",
        height: "calc(100% + 10px)",
        width: "200vw",
        top: 0,
        left: 0,
        transform: "translate(-50%, -5px)",
        zIndex: 1,
        border: "1px dashed #7FD1DE",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
    },
    horizontalTracker: {
        position: "absolute",
        width: "calc(100% + 10px)",
        height: "200vh",
        top: 0,
        left: 0,
        transform: "translate(-5px, -50%)",
        zIndex: 1,
        border: "1px dashed #7FD1DE",
        borderTopColor: "transparent",
        borderBottomColor: "transparent",
    },
})

const DragPreviewer = ({ isDragging, signerField, ...props }) => {
    const classes = useStyles()
    if (!isDragging) return null
    return (
        <>
            <div className={classes.verticalTracker}></div>
            <div className={classes.horizontalTracker}></div>
        </>
    )
}

export default DragPreviewer
