import { createUseStyles } from "react-jss"

export const useStyles = createUseStyles(() => ({
    paginationBar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    pageSettler: {
        marginLeft: "0.25rem",
        cursor: "pointer",
        height: 28,
        width: "1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    pageSettlerActive: {
        border: "2px solid #44CCFF",
        background: "#E1FAFF",
        borderRadius: "3px",
    },
    firstPage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0.25rem",
        cursor: "pointer",
    },
    firstPageInactive: {
        "& svg": {
            fill: "#A5B6C9",
        },
    },
    lastPage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0.25rem",
        cursor: "pointer",
        "& svg": {
            transform: "rotate(180deg)",
        },
    },
    lastPageInactive: {
        "& svg": {
            fill: "#A5B6C9",
        },
    },
    prevPage: {
        border: "2px solid #00aeef",
        marginLeft: "0.25rem",
    },
    nextPage: {
        border: "2px solid #00aeef",
        marginLeft: "0.25rem",
    },
    pageSettlerInput: {
        marginLeft: "0.5rem",
    },
    pageDropdown: {
        width: "125px",
    },
}))
