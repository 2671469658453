import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React, { useState } from "react"
import { useContext } from "react"
import { ReactContext } from "../MediaLibrary"
import { ReactComponent as Image } from "../Assets/image-icon.svg"
import { useEffect } from "react"
import { useStyles } from "../MediaLibraryStyle"

const GridImage = ({ file }) => {
    const { setHandlingFiles, handlingFiles, singleFile, initFiles, styles } =
        useContext(ReactContext)
    const classes = useStyles(styles)
    const [isSelected, setIsSelected] = useState(
        handlingFiles.library.find((f) => f.mediaPath === file.mediaPath)
    )
    const addFileToHandle = () => {
        if (!handlingFiles.library.find((f) => f.mediaPath === file.mediaPath))
            if (singleFile)
                setHandlingFiles(() => ({ ...initFiles, library: [file] }))
            else
                setHandlingFiles((prev) => ({
                    ...prev,
                    library: [...prev.library, file],
                }))
        else
            setHandlingFiles((prev) => ({
                ...prev,
                library: prev.library.filter((f) => f.mediaPath !== file.mediaPath),
            }))
    }
    useEffect(() => {
        if (handlingFiles.library.find((f) => f.mediaPath === file.mediaPath))
            setIsSelected(true)
        else setIsSelected(false)
    }, [handlingFiles.library])

    const res = file.mediaPath.split("/")
    return (
        <div
            className={`${classes.contentLibraryImage} ${
                isSelected && classes.contentLibraryImageActive
            }`}
            onClick={addFileToHandle}
        >
            <div className={classes.contentLibraryImageContent}>
                <img src={file.mediaPath} alt="" loading="lazy" />
            </div>
            <div
                className={`${classes.contentLibraryImageDescription} ${
                    isSelected && classes.contentLibraryImageDescriptionActive
                }`}
            >
                <Text
                    fontSize={13}
                    weight={600}
                    margin="0.25rem"
                    color={`${isSelected ? "#FFFFFF" : "#497696"}`}
                    style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                    }}
                >
                    {res[res.length - 1]}
                </Text>
                <div className={classes.fileDescription}>
                    <Text
                        fontSize={11}
                        weight="600"
                        color={`${isSelected ? "#FFFFFF" : "#497696"}`}
                    >
                        {file.size || "3.4 mb"}
                    </Text>
                    <div
                        className={`${classes.fileType} ${
                            isSelected && classes.fileTypeActive
                        }`}
                    >
                        <Text
                            fontSize={11}
                            color={`${isSelected ? "#5E7C98" : "white"}`}
                        >
                            {file.mediaType.split("/")[1]}
                        </Text>
                    </div>
                    <Image
                        style={{ marginRight: "0.5rem" }}
                        fill={`${isSelected ? "#ffffff" : "#99acc2"}`}
                    />
                </div>
            </div>
        </div>
    )
}

export default GridImage
