import { Button, Grid, Text } from "@awesomesuite-frontend/awesome-nebula"
import React, { useState, useEffect, useRef } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
    image_selector: () => ({
        width: "100%",
        height: "100%",
        borderRadius: "50%",

        "& path": {
            fill: "gray",
        },
        paddingLeft: "10%"
    }),
    section: () => ({
        width: "3rem",
        height: "3rem",
        fontSize: "2rem",

        "& svg": {
            width: "2rem",
        },
    }),
    mainIcon: {
        height: "75px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            height: "60px",
            aspectRatio: 1,
        },
        backgroundColor: "#EAF0F6",
        border: "1px solid #DFE3EB",
        borderRadius: "6px"
    },
    mainLogo: {
        height: "75px",
        width: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            height: "60px",
            aspectRatio: 1
        },
        backgroundColor: "#EAF0F6",
        border: "1px solid #DFE3EB",
        borderRadius: "6px"
    },
    buttonToAddImg: {
        borderRadius: "4px",
        border: "1px solid #d6dfe8",
        "& span": {
            color: "#33475B"
        }
    },
})

const WorkspaceSettingComponent = () => {
    const classes = useStyles()
    const inputRefIcon = useRef(null)
    const inputRefLogo = useRef(null)
    const [src, setSrc] = useState(
        {
            icon: null,
            logo: null
        }
    )
    const [preview, setPreview] = useState(
        {
            icon: src.icon ? (typeof src.icon === "string" ? src.icon : URL.createObjectURL(src.icon)) : null,
            logo: src.logo ? (typeof src.logo === "string" ? src.logo : URL.createObjectURL(src.logo)) : null
        }
    )

    useEffect(() => {
        if (!src.icon && !src.logo) {
            setPreview({ icon: null, logo: null })
            return
        }

        let icon=null;
        if (src.icon) {
            if (typeof src.icon === "string") icon = src.icon
            else icon = URL.createObjectURL(src.icon)
        }

        let logo=null;
        if (src.logo) {
            if (typeof src.logo === "string") logo = src.logo
            else logo = URL.createObjectURL(src.logo)
        }
        setPreview({...preview, logo: logo, icon: icon});
    }, [src])

    const ImageHandler = (e, imgType) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        setSrc({ ...src, [imgType]: e.target.files[0] })
    }

    return (
        <div className={`${classes.image_selector}`}>
            <Text varient="h6" style={{ marginBottom: "0.5rem" }}>Symbol & name</Text>
            <Text varient="body" style={{ marginBottom: "0.5rem" }} color={"light"}>Giving a name and symbol to number will help you and your team distinguish between all the numbers.</Text>
            <Grid container={true} spacing={10}>
                <Grid item={true} md={4}>
                    <Grid item={true} md={12}>
                        <div className={classes.file_label_div}>
                            {/* {!src.logo && <div className={classes.div}>No Logo</div>} */}
                            <div className={classes.mainLogo}>
                                {preview.logo ? 
                                <img src={preview.logo} alt="" /> :
                                <Text varient="body" color="secondary">No Logo</Text>}
                            </div>
                        </div>
                        <input
                            id="file"
                            type="file"
                            name="image"
                            accept="image/png, image/jpg, image/jpeg, image/webp"
                            ref={inputRefLogo}
                            onChange={(e) => { ImageHandler(e, "logo") }}
                            style={{ visibility: "hidden" }}
                        />
                    </Grid>
                    <Grid item={true} md={12}>
                        <Button
                            size={"small"}
                            color="gray-4"
                            className={classes.buttonToAddImg}
                            onClick={() => inputRefLogo.current.click()}
                        >
                            Change Logo
                        </Button>
                    </Grid>
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item={true} md={2}>
                    <Grid item={true} md={6}>
                        <div className={classes.file_label_div}>
                            {/* {!src.icon && <div className={classes.div}>No Logo</div>} */}
                            <div className={classes.mainIcon}>
                                {preview.icon ? 
                                <img src={preview.icon} alt="" /> :
                                <Text varient="body" color="secondary">No Icon</Text>}
                            </div>
                        </div>
                        <input
                            id="file"
                            type="file"
                            name="image"
                            accept="image/png, image/jpg, image/jpeg, image/webp"
                            ref={inputRefIcon}
                            onChange={(e) => { ImageHandler(e, "icon") }}
                            style={{ visibility: "hidden" }}
                        />
                    </Grid>
                    <Grid item={true} md={12}>
                        <Button
                            size={"small"}
                            color="gray-4"
                            className={classes.buttonToAddImg}
                            onClick={() => inputRefIcon.current.click()}
                        >
                            Change Icon
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default WorkspaceSettingComponent
