import React from "react"
import "./TimelineConnector.scss"

/**
 * It is a Connector for timeline it contains line and 
 * round div for logo.
 * 
 * customization can be done using styles props-
 * 1. iconStyle - for adding style to the icon wrapper
 * 2. connectorStyle - change style of the whole connector using this.
 * 3. lineStyle - line style have separate style.
 * 4. disableLine - you can set this property false if you don't need line in some cases
 * 
 * @param {*} param0 - { icon, iconStyle, connectorStyle, lineStyle, disableLine }
 */
const TimeLineConnector = ({
    icon,
    iconStyle,
    connectorStyle,
    lineStyle,
    disableLine,
}) => {
    return (
        <div className="aui-timeline-connector" style={{ ...connectorStyle }}>
            <div className="connector-item" style={{ ...iconStyle }}>
                {icon && icon}
            </div>
            {!disableLine && (
                <div className="connector-line" style={{ ...lineStyle }}></div>
            )}
        </div>
    )
}

export default TimeLineConnector;
