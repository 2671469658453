import React, { useState, useEffect, useRef } from "react"
import { Rnd } from "react-rnd"
import { dragClasses, dragStyle, dragStyleTransparent } from "../Common/Style"
import {
    dragSignatureElement,
    removeSignatureElement,
    resizeSignatureElement,
} from "../../../../../Action/signAction"
import Wrapper from "../Common/Wrapper"
import DragPreviewer from "../DragPreviewer"

/**
 * Signature type drag component for dragging in the the pdf editor.
 *
 * @param {Object} props - parameters for this component
 * @param {Object} props.signature - image url for the signature
 * @param {number} props.xDefault - default x position for the signature
 * @param {number} props.yDefault - default y position for the signature
 *
 * @returns Sign component with the resize feature
 */
const Sign = ({
    pageIndex,
    getPagePosition,
    id,
    type,
    signature,
    xDefault,
    yDefault,
    dragHandler,
    resizeHandler,
    disable,
    ...other
}) => {
    const ref = useRef(null)
    const [toolModel, setToolModel] = useState(true)
    const [isDragging, setIsDragging] = useState(false)

    useEffect(() => {
        onDragStop()
        onResize(89)
    }, [])

    function openModelHandler() {
        setToolModel(true)
    }

    function mouseLeaveHandler(e) {
        const comp = ref.current
        if (comp && comp.contains(e.target)) return
        setToolModel(false)
        document.removeEventListener("click", mouseLeaveHandler)
    }

    function onRemoveHandler() {
        removeSignatureElement(pageIndex, id)
        other.onMouseLeave()
    }

    function onDragStop(event) {
        const element = ref.current.getBoundingClientRect()
        const canvas = getPagePosition()
        const pos = {
            x: Math.floor(element.left - canvas.left),
            y: Math.floor(canvas.bottom - element.top - element.height),
        }
        dragSignatureElement(
            pageIndex,
            id,
            pos.x,
            pos.y,
            element.top - canvas.top,
            element.left - canvas.left
        )
        setIsDragging(false)
    }

    function onResize(defaultHeight) {
        const element = ref.current.getBoundingClientRect()
        const canvas = getPagePosition()
        const pos = {
            x: Math.floor(element.left - canvas.left),
            y: Math.floor(canvas.bottom - element.top - element.height),
        }
        resizeSignatureElement(
            pageIndex,
            id,
            Math.floor(element.width),
            element.height ? Math.floor(element.height) : Math.floor(defaultHeight),
            pos.x,
            pos.y,
            element.left - canvas.left,
            element.top - canvas.top
        )
    }

    return (
        <Rnd
            style={{ zIndex: 12 }}
            bounds={"parent"}
            default={{
                x: xDefault,
                y: yDefault,
                width: "225.56px",
            }}
            minWidth={"50px"}
            minHeight={"20px"}
            lockAspectRatio
            resizeHandleStyles={toolModel ? dragStyle : dragStyleTransparent}
            resizeHandleClasses={toolModel ? dragClasses : {}}
            onDragStart={() => setIsDragging(true)}
            onDragStop={onDragStop}
            onResize={(e) => onResize()}
            onMouseDown={openModelHandler}
            disableDragging={disable}
            enableResizing={!disable}
        >
            <div
                className=""
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
                {...other}
            >
                {toolModel && <Wrapper onRemoveHandler={onRemoveHandler} />}
                <DragPreviewer isDragging={isDragging} />
                <div
                    style={{ width: "100%", height: "100%" }}
                    ref={(e) => (ref.current = e)}
                    onMouseLeave={() =>
                        document.addEventListener("click", mouseLeaveHandler)
                    }
                >
                    <img
                        src={signature}
                        width="100%"
                        height="100%"
                        style={{ objectFit: "contain", pointerEvents: "none" }}
                        alt={id}
                    />
                </div>
            </div>
        </Rnd>
    )
}

export default Sign
