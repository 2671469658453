import {
    DELETE_DOCUMENT,
    GET_LIST_OF_DOCUMENTS,
    GET_LIST_OF_DOCUMENTS_FAILED,
    GET_LIST_OF_DOCUMENT_LOADING,
    GET_DOCUMENTS_WITH_STATUS,
    GET_DOCUMENTS_WITH_STATUS_FAILED,
    ADD_DOCUMENT,
    GET_DOCUMENTS_RECIEVED_FOR_SIGN,
    // GET_DOCUMENT_WITH_STATUS_LOADING,
} from "ActionTypes/documentType"
import { DRAFT, FINISHED, OUT_FOR_SIGNATURE } from "Types"

const initialize = {
    documents: null,
    documentLoading: false,

    draftDocuments: {
        content: [],
    },
    draftFill: false,
    draftLoading: false,

    finishedDocments: {
        content: [],
    },
    finishedLoading: false,
    finishedFill: false,

    sendForSignature: {
        content: [],
    },
    sendLoading: false,
    sendFill: false,

    recievedForSign: {
        content: [],
    },
    recievedForSignLoading: false,
    recievedForSignFill: false,
}

export const documentReducer = (state = initialize, action) => {
    const payload = action.payload

    switch (action.type) {
        case GET_LIST_OF_DOCUMENTS:
            return documentToHashMap(state, payload.documents)
        case GET_LIST_OF_DOCUMENT_LOADING:
            return {
                ...state,
                documentLoading: true,
            }
        case GET_LIST_OF_DOCUMENTS_FAILED:
            return {
                ...state,
                documentLoading: false,
            }
        case DELETE_DOCUMENT:
            return handleDelete(state, payload)
        case GET_DOCUMENTS_WITH_STATUS:
            return handleDocumentWithStatus(state, payload)
        case GET_DOCUMENTS_RECIEVED_FOR_SIGN:
            return {
                ...state,
                recievedForSign: payload.documents,
                recievedForSignLoading: false,
                recievedForSignFill: false,
            }
        case GET_DOCUMENTS_WITH_STATUS_FAILED:
            return { ...state, documentLoading: false }
        case ADD_DOCUMENT:
            return addDocumentToState(state, payload)
        default:
            return { ...state }
    }
}

function documentToHashMap(state, documents) {
    let documentsMap = {
        // each tag will considered as a folder.
        ...documents,
        rootFiles: [],
        folderFiles: [],
    }

    documents.content.forEach((file) => {
        if (file.tag) {
            if (!documentsMap[file.tag]) documentsMap[file.tag] = []

            documentsMap[file.tag].push(file)
        }
        if (!file.folderId) {
            documentsMap.rootFiles.push(file)
        } else documentsMap.folderFiles.push(file)
    })

    return {
        ...state,
        documents: documentsMap,
        documentLoading: false,
    }
}

/**
 * handel the delete in front End
 */
function handleDelete(state, payload) {
    const { fileId, tag } = payload

    if (!fileId) return state

    let newDocuments = { ...state.documents }

    if (tag) {
        newDocuments[tag] = newDocuments[tag].filter(
            (item) => item.fileId !== fileId
        )
    }
    if (Array.isArray(newDocuments.rootFiles))
        newDocuments.rootFiles = newDocuments.rootFiles.filter(
            (item) => item.fileId !== fileId
        )
    if (Array.isArray(newDocuments.folderFiles))
        newDocuments.folderFiles = newDocuments.folderFiles.filter(
            (item) => item.fileId !== fileId
        )
    if (Array.isArray(newDocuments.content))
        newDocuments.content = newDocuments.content.filter(
            (item) => item.fileId !== fileId
        )

    let newState = {
        ...state,
        documents: newDocuments,
    }

    let updatedDocuments
    if (state.draftDocuments.content.find((e) => e.fileId === fileId)) {
        updatedDocuments = state.draftDocuments.content.filter(
            (e) => e.fileId !== fileId
        )
        newState = {
            ...newState,
            draftDocuments: {
                ...newState.draftDocuments,
                content: updatedDocuments,
                totalElements: newState.draftDocuments.totalElements - 1,
            },
        }
    }
    if (state.sendForSignature.content.find((e) => e.fileId === fileId)) {
        updatedDocuments = state.sendForSignature.content.filter(
            (e) => e.fileId !== fileId
        )
        newState = {
            ...newState,
            sendForSignature: {
                ...newState.sendForSignature,
                content: updatedDocuments,
                totalElements: newState.sendForSignature.totalElements - 1,
            },
        }
    }
    if (state.finishedDocments.content.find((e) => e.fileId === fileId)) {
        updatedDocuments = state.finishedDocments.content.filter(
            (e) => e.fileId !== fileId
        )
        newState = {
            ...newState,
            finishedDocments: {
                ...newState.finishedDocments,
                content: updatedDocuments,
                totalElements: newState.finishedDocments.totalElements - 1,
            },
        }
    }

    return newState
}

/**
 * handel load document with the status
 */
function handleDocumentWithStatus(state, payload) {
    const { status, documents } = payload

    switch (status) {
        case DRAFT:
            return {
                ...state,
                draftDocuments: documents,
                draftFill: true,
                draftLoading: false,
            }
        case OUT_FOR_SIGNATURE:
            return {
                ...state,
                sendForSignature: documents,
                sendLoading: false,
                sendFill: true,
            }
        case FINISHED:
            return {
                ...state,
                finishedDocments: documents,
                finishedLoading: false,
                finishedFill: true,
            }
        default:
            return state
    }
}

const addDocumentToState = (state, payload) => {
    let newDocuments = { ...state.documents }
    let updatedFiles = []
    let newState = { ...state }

    if (newDocuments.content) updatedFiles = [...newDocuments.content]
    updatedFiles.push(payload.fileData)
    newDocuments = { ...newDocuments, content: updatedFiles }
    newState = {
        ...state,
        documents: newDocuments,
    }

    updatedFiles = []

    if (payload.folderId === -1) {
        if (newDocuments.rootFiles) updatedFiles = [...newDocuments.rootFiles]
        updatedFiles.push(payload.fileData)
        newDocuments = { ...newDocuments, rootFiles: updatedFiles }
        newState = {
            ...newState,
            documents: newDocuments,
        }
    } else {
        if (newDocuments.folderFiles) updatedFiles = [...newDocuments.folderFiles]
        updatedFiles.push(payload.fileData)
        newDocuments = { ...newDocuments, folderFiles: updatedFiles }
        newState = {
            ...newState,
            documents: newDocuments,
        }
    }

    switch (payload.fileData.status) {
        case DRAFT:
            return {
                ...newState,
                draftDocuments: {
                    ...newState.draftDocuments,
                    content: [payload.fileData, ...state.draftDocuments.content],
                    totalElements: newState.draftDocuments.totalElements + 1,
                },
            }
        case OUT_FOR_SIGNATURE:
            return {
                ...newState,
                sendForSignature: {
                    ...newState.sendForSignature,
                    content: [payload.fileData, ...state.sendForSignature.content],
                },
            }
        case FINISHED:
            return {
                ...newState,
                finishedDocments: {
                    ...newState.finishedDocments,
                    content: [payload.fileData, ...state.finishedDocments.content],
                },
            }
        default:
            return { ...newState }
    }
}
