import { createUseStyles } from "react-jss"

export const useStyles = createUseStyles(() => ({
    permissionDeniedWrapper: {
        display: "flex",
        flexDirection: "column",
        minHeight: "max(calc(100vh - 7.5rem), calc(100vh - 60px))",
        // minHeight: "100vh",
    },
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        "& svg": {
            width: "10rem",
            height: "10rem",
        },
    },
}))
