import { Alert } from "@awesomesuite-frontend/awesome-nebula"
import { closeMessage } from "Action/messageAndNotificationActions"
import { setDaysLeftForPlan, showPopUpPlan } from "Action/subscriptionActions"
import WarningPopup from "Awesome-ui/WarningPopup/WarningPopup"
import SignAnimation from "Components/SignAnimation/SignAnimation"
import React from "react"
import { createUseStyles } from "react-jss"
import { connect } from "react-redux"

const useStyles = createUseStyles((theme) => ({
    alerts: {
        position: "absolute",
        bottom: "2%",
        right: "5%",
        transition: "all ease-in 200ms",
        zIndex: 1000000004,
    },
    alertsOpen: {
        display: "block",
        transform: "translateX(0px)",
    },
    alertsClose: {
        display: "block",
        transform: "translateX(130%)",
    },
    loaderWrapper: {
        position: "fixed",
        width: "100vw",
        height: "100vh",
        background: "#fff",
        zIndex: 1000000004,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top : 0,
        left : 0,
    },
}))

const GlobalComponent = ({
    messageShow,
    message,
    warningMessageShow,
    warningMessage,
    workspaceLoading,
    subscription,
}) => {
    React.useEffect(() => {
        if (subscription && Array.isArray(subscription)) {
            const currentSubscriptionEnd = subscription[0]?.endDate
            if (currentSubscriptionEnd) {
                const val =
                    new Date(currentSubscriptionEnd).getTime() - new Date().getTime()
                const daysLeft = Math.ceil(val / (24 * 60 * 60 * 1000))
                if (daysLeft >= 0) {
                    setDaysLeftForPlan(daysLeft)
                } else showPopUpPlan()
            }
        }
    }, [subscription])
    const classes = useStyles()
    return (
        <>
            {workspaceLoading && (
                <div className={classes.loaderWrapper}>
                    <SignAnimation />
                </div>
            )}
            <div
                className={`${classes.alerts} ${
                    messageShow ? classes.alertsOpen : classes.alertsClose
                }`}
            >
                <Alert onClose={closeMessage} {...message} />
            </div>
            {warningMessageShow && <WarningPopup {...warningMessage} />}
        </>
    )
}

const mapStateToProps = ({
    messageAndNotificationReducer,
    workspaceReducer,
    subscriptionReducer,
}) => ({
    messageShow: messageAndNotificationReducer?.messageShow,
    message: messageAndNotificationReducer?.message,
    warningMessageShow: messageAndNotificationReducer?.warningMessageShow,
    warningMessage: messageAndNotificationReducer?.warningMessage,
    workspaceLoading: workspaceReducer?.workspaceLoading,
    subscription: subscriptionReducer?.subscription,
})

export default connect(mapStateToProps)(GlobalComponent)
