import { createUseStyles } from "react-jss"
export const useStyles = createUseStyles(() => ({
    sendForSignAnimationWrapper: {
        position: "absolute",
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(45,62,80,0.28)",
        top: 0,
        left: 0,
        zIndex: 10000,
    },
    sendForSignAnimationModal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 300,
        aspectRatio: 1,
        backgroundColor: "#fff",
        borderRadius: 15,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}))
