import React from "react"
import "./App.scss"

import Layout from "./Layout/Layout"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { store, persister } from "./store"
import { BrowserRouter } from "react-router-dom"
import { ThemeContext } from "@awesomesuite-frontend/awesome-nebula"
import { Helmet } from "react-helmet"
import { turnOnClarity } from "configuration/clarity"

function App() {
    React.useEffect(() => {
        turnOnClarity()
    }, [])
    return (
        <Provider store={store}>
            <PersistGate persistor={persister}>
                <ThemeContext>
                    <Helmet>
                        <title>Awesome Sign</title>
                    </Helmet>
                    <BrowserRouter>
                        <Layout />
                    </BrowserRouter>
                </ThemeContext>
            </PersistGate>
        </Provider>
    )
}

export default App

// Color1: #145995
// Color2: #00d8ff
