import React from "react"
import { ReactComponent as LockIcon } from "Assets/ToolsIcons/lock.svg"
import { useStyles } from "./PermissionDeniedStyle"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

const PermissionDeniedSubComponent = () => {
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <LockIcon />
            <Text varient="h4" align="center" margin="1.5rem 0 .75rem 0">
                You don't have permission to view this page
            </Text>
            <Text align="center">
                A space admin or the person who shared this page may be able to give
                you access.
            </Text>
        </div>
    )
}

export default PermissionDeniedSubComponent
