import { Grid, Text } from '@awesomesuite-frontend/awesome-nebula'
import BoxCheckBox from 'Awesome-ui/BoxCheckBox'
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'

const useStyle = createUseStyles(()=>({
    docPreferenceContainer:{
        marginLeft: "10%"
    },
    boxCheckBox:{
        accentColor:"#2CD5F8",
    }
}))

const DocumentPreference = () => {
    const classes = useStyle()
    const [optionEnable, setOptionEnable] = useState({download: false, enable: false})
    return (
        <>
            <Grid item={true} md={5} xs={8} className={classes.docPreferenceContainer}>
                <div style={{marginTop:"1rem"}}>
                    <Text varient='body' style={{fontWeight: 600, marginBottom: "0.25rem"}} color='#33475B'>Allow signers to download document</Text>
                    <BoxCheckBox className={classes.boxCheckBox} checked={optionEnable.download} changeHandler={()=>{setOptionEnable({...optionEnable, download: !optionEnable.download})}}/>
                </div>
                <div style={{marginTop:"1rem"}}>
                    <Text varient='body' style={{fontWeight: 600, marginBottom: "0.25rem"}} color='#33475B'>Enable </Text>
                    <BoxCheckBox className={classes.boxCheckBox}  checked={optionEnable.enable} changeHandler={()=>{setOptionEnable({...optionEnable, enable: !optionEnable.enable})}}/>
                </div>
            </Grid>  
        </>
    )
}

export default DocumentPreference