// signature events
export const INITIALIZE_DOCUMENT_SIGNATURE = "INITIALIZE_DOCUMENT_SIGNATURE"
export const SET_SIGNATURE_FLOW = "SET_SIGNATURE_FLOW"
export const CLEAR_E_SIGN_DATA = "CLEAR_E_SIGN_DATA"
export const ADD_SIGNATURE_ELEMENT = "ADD_SIGNATURE_ELEMENT"
export const REMOVE_SIGNATURE_ELEMENT = "REMOVE_SIGNATURE_ELEMENT"
export const RESIZE_SIGNATURE_ELEMENT = "RESIZE_SIGNATURE_ELEMENT"
export const DRAG_SIGNATURE_ELEMENT = "DRAG_SIGNATURE_ELEMENT"
export const FINISH_AND_SIGN = "FINISH_AND_SIGN"
export const ADD_DATA_TO_ELEMENT = "ADD_DATA_TO_ELEMENT"
export const SET_SEND_FOR_SIGNATURE_TYPE = "SET_SEND_FOR_SIGNATURE_TYPE"
export const SET_PDF_PAGES_PREVIEW = "SET_PDF_PAGES_PREVIEW"
export const TOGGLE_PDF_PAGE_PREVIEW = "TOGGLE_PDF_PAGE_PREVIEW"
export const SET_ACTIVE_TOOL = "SET_ACTIVE_TOOL"
export const SET_ACTIVITY_LOGS = "SET_ACTIVITY_LOGS"
export const CAPTURE_USER_ACTION = "CAPTURE_USER_ACTION"
export const CAPTURE_USER_CLICK = "CAPTURE_USER_CLICK"
export const ADD_FIELD_TO_DOCUMENT = "ADD_FIELD_TO_DOCUMENT"
export const UPDATE_SIGNATURE_ELEMENT = "UPDATE_SIGNATURE_ELEMENT"
export const CLICK_TO_SIGN_ALL = "CLICK_TO_SIGN_ALL"
export const CLICK_TO_SIGN_EACH = "CLICK_TO_SIGN_EACH"
export const ADD_USER_SIGN = "ADD_USER_SIGN"
export const GET_USER_SIGNS = "GET_USER_SIGNS"
export const DELETE_USER_SIGN = "DELETE_USER_SIGN"
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"
export const UPDATE_VALUE_OF_TEMPLATE_ITEM = "UPDATE_VALUE_OF_TEMPLATE_ITEM"
export const SET_TEMPLATE_VIEW = "SET_TEMPLATE_VIEW"
export const ADD_ROLE_TO_TEMPLATE = "ADD_ROLE_TO_TEMPLATE"
export const REMOVE_ROLE_FROM_TEMPLATE = "REMOVE_ROLE_FROM_TEMPLATE"
export const UPDATE_ROLE_OF_TEMPLATE = "UPDATE_ROLE_OF_TEMPLATE"
export const GET_ROLES_OF_TEMPLATE = "GET_ROLES_OF_TEMPLATE"
export const SET_IS_DOCUMENT_ACCEPTED = "SET_IS_DOCUMENT_ACCEPTED"
