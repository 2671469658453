import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { ReactComponent as Download } from "Assets/PdfEditor/download.svg"
import "./PdfTop.scss"
import CreatorTitleBar from "Awesome-ui/CreatorTitleBar"
import { ReactComponent as PdfIcon } from "Assets/Storage/filePdf.svg"
import { ReactComponent as BackArrow } from "Assets/ToolsIcons/ArrowLeft.svg"
import { ReactComponent as Saving } from "Assets/PdfNavigation/saving.svg"
import { ReactComponent as SendForESign } from "Assets/PDFCommons/send-for-sign.svg"
import Button from "Awesome-ui/Button/Button"
import {
    Button as ButtonComponent,
    TagSelector,
} from "@awesomesuite-frontend/awesome-nebula"

import {
    DANGER,
    DOCUMENT,
    DRAFT,
    DRAW_SIGNATURE,
    FINISHED,
    OUT_FOR_SIGNATURE,
    PREDEFINED_TEMPLATE,
    SEND_FOR_SIGNATURE,
    TEMPLATE,
} from "Types"
import { medialApi } from "Apis/AllApisMapping"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import {
    convertTemplateToDocument,
    getAllDocuments,
    getAllDocumentsWithStatus,
    getAllPreviousTags,
    updateDocument,
} from "Action/documentAction"
import { startDocumentSinature } from "Action/documentAction"
import { clearESignData, finishAndSign } from "Action/signAction"
import { createCanvasWithText } from "Library/CanvasLib/CanvasLib"
import { getAllFolders } from "Action/foldersAction"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import Loader from "Awesome-ui/Loader/Loader"
import { createMessage } from "Action/messageAndNotificationActions"

const allTags = ["General", "Sales Contract", "Employ Contract"]

const PdfTop = ({
    finishDocumentLink,
    fileId,
    tag,
    creator,
    status,
    fileName,
    documentURL,
    documentId,
    saving,
    eSignFlow,
    isSetSigning,
    setIsSentForSign,
    getPdfDocument,
    enableTemplateFlow,
    type,
    signature,
    setIsConvertingtemplateLoading,
    saveAllAnnotations,
}) => {
    const mediaService = new mediaHandler(medialApi)
    const history = useHistory()
    const [file, setFile] = useState(fileName)
    const [_, setExtension] = useState("")
    const [isDisable, setIsDisable] = useState(false)
    const [options, setOptions] = useState([])
    const [areElementsPresentInTemplate, setAreElementsPresentInTemplate] =
        useState(false)

    let isDownloadMainButton =
        (eSignFlow && !finishDocumentLink && status !== OUT_FOR_SIGNATURE) ||
        areElementsPresentInTemplate

    async function fetchTags() {
        const tags = await getAllPreviousTags()
        setOptions([...tags, ...allTags].slice(0, 5))
    }

    async function tagHandler(value) {
        if (!fileId) return
        if (tag?.toLowerCase() === value?.toLowerCase()) return
        await updateDocument({
            fileId,
            fileName: null,
            tag: value,
        })
        fetchTags()
    }

    const handleDownload = async (toDownloadFileUrl) => {
        fetch(toDownloadFileUrl, { method: "get" })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement("a")
                aElement.setAttribute("download", fileName)
                const href = URL.createObjectURL(res)
                aElement.href = href
                aElement.setAttribute("target", "_blank")
                aElement.click()
                URL.revokeObjectURL(href)
            })
    }

    /**
     * update the file name
     */
    function fileNameChange() {
        if (file === fileName) return

        if (!file) return

        updateDocument({ fileId, fileName: file })
    }

    async function finishHandler() {
        setIsDisable(true)
        let resp
        const annotations = saveAllAnnotations()
        if (eSignFlow === SEND_FOR_SIGNATURE) {
            const type = isSetSigning ? "" : "all"
            resp = await startDocumentSinature(
                documentId,
                type,
                enableTemplateFlow,
                annotations
            )
            if (resp) setIsSentForSign(true)
        } else {
            resp = await finishAndSign(
                createCanvasWithText,
                mediaService,
                null,
                DRAW_SIGNATURE,
                annotations
            )
        }
        if (resp === 1) {
            getPdfDocument()
            getAllDocumentsWithStatus(DRAFT)
            getAllDocumentsWithStatus(OUT_FOR_SIGNATURE)
        }
        setIsDisable(false)
    }

    async function convertTemplateToDocumentByUser() {
        if (isDisable) return
        setIsConvertingtemplateLoading(true)
        const resp = await convertTemplateToDocument(
            fileId,
            type === PREDEFINED_TEMPLATE
        )
        if (!resp)
            return createMessage(
                type === TEMPLATE
                    ? "Could not convert the template to document!"
                    : "Could not copy the template to my templates!",
                null,
                DANGER
            )
        const templateId = resp.fileId
        history.replace(`/pdf-editor/${templateId}`)
    }

    useEffect(() => {
        if (!file && fileName) {
            const ext = fileName.lastIndexOf(".")
            if (ext > -1) {
                const tempName = fileName.slice(0, ext)
                const tempExt = fileName.slice(ext)
                setExtension(tempExt)
                setFile(tempName)
            } else {
                setFile(fileName)
            }
        }
    }, [fileName, status])

    useEffect(() => {
        if (creator?.id && options.length < 1) fetchTags()
    }, [options, creator])

    useEffect(() => {
        let areElementsPresent = false
        Object.values(signature).forEach((element) => {
            Object.values(element).forEach((e) => {
                if (e.type) areElementsPresent = true
                return
            })
            if (areElementsPresent) return
        })
        areElementsPresent = areElementsPresent && type !== DOCUMENT
        setAreElementsPresentInTemplate(areElementsPresent)
    }, [signature])

    if (!creator?.id) return null

    return (
        <div className="pdf-editor-top">
            <div className="editor-top-left">
                <div
                    className="editor-back-button"
                    onClick={() => {
                        clearESignData()
                        history.goBack()
                    }}
                >
                    <BackArrow />
                </div>
                <div className="editor-file-details">
                    <div className="editor-file">
                        <PdfIcon />
                    </div>
                    <div className="file-details">
                        <input
                            value={file}
                            onChange={(e) => setFile(e.target.value)}
                            onBlur={fileNameChange}
                            disabled={
                                status !== DRAFT || type === PREDEFINED_TEMPLATE
                            }
                        />
                        <div className="document-controls">
                            <TooltipWrapper
                                wrapperClassName="documentStatus"
                                text="Recent changes saved"
                            >
                                {saving && (
                                    <Saving
                                        className={`saveAnimation`}
                                        width="1.1rem"
                                        height="1.1rem"
                                        fill="#00BDA5"
                                    />
                                )}
                                <Text fontSize={11} color="#00BDA5">
                                    {saving ? "Saving" : "Saved"}
                                </Text>
                            </TooltipWrapper>
                        </div>
                    </div>
                    {type === DOCUMENT && (
                        <TooltipWrapper
                            wrapperClassName="tags-list"
                            text={"Add Document Label"}
                        >
                            <TagSelector
                                tag={tag}
                                options={options}
                                onTagChange={tagHandler}
                            />
                        </TooltipWrapper>
                    )}
                </div>
                <div style={{ marginLeft: "auto", marginRight: 12 }}>
                    <div className="pdf-toolbar-right">
                        <TooltipWrapper
                            wrapperlassName="download-btn-container"
                            text="Download Document"
                        >
                            <ButtonComponent
                                padding={"0.5rem 1rem"}
                                style={{
                                    marginRight: "1rem",
                                    borderRadius: 5,
                                    borderWidth: 1,
                                }}
                                onClick={() => handleDownload(documentURL)}
                                varient={
                                    isDownloadMainButton ? "outline" : "default"
                                }
                                color={isDownloadMainButton ? "#d6dfe8" : "#00aeef"}
                            >
                                <div className="download-btn">
                                    <Download
                                        height={13}
                                        width={13}
                                        fill={
                                            isDownloadMainButton
                                                ? "#33475B"
                                                : "white"
                                        }
                                    />
                                    <Text
                                        color={
                                            isDownloadMainButton
                                                ? "#33475B"
                                                : "white"
                                        }
                                        fontSize={14}
                                    >
                                        Download
                                    </Text>
                                </div>
                            </ButtonComponent>
                        </TooltipWrapper>
                        {areElementsPresentInTemplate && (
                            <TooltipWrapper
                                wrapperClassName="wrapper-submit-button"
                                text={
                                    type === TEMPLATE
                                        ? "Convert this template ready for signature"
                                        : "Copy this template to my templates"
                                }
                                style={{ padding: "7px 12px" }}
                            >
                                <Button
                                    width="100%"
                                    margin="0 auto"
                                    borderRadius={4}
                                    padding={"0.5rem 1rem"}
                                    onClick={convertTemplateToDocumentByUser}
                                    isDisable={isDisable}
                                    backgroundColor={
                                        isDisable ? "#d6dfe8" : "#7E89FF"
                                    }
                                    border="none"
                                    cursor={isDisable ? "wait" : "pointer"}
                                >
                                    <div className="send-sign-flow-btn">
                                        {isDisable ? (
                                            <Loader
                                                style={{
                                                    marginRight: "0.5rem",
                                                    borderColor: "#fff",
                                                    borderTopColor: "#d6dfe8",
                                                    width: 18,
                                                    height: 18,
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                        <Text
                                            fontSize={14}
                                            color="#FFFFFF"
                                            marginLeft={"0.5rem"}
                                        >
                                            {type === TEMPLATE
                                                ? "Send For Signature"
                                                : "Use Template"}
                                        </Text>
                                    </div>
                                </Button>
                            </TooltipWrapper>
                        )}
                        {eSignFlow &&
                            !finishDocumentLink &&
                            status !== OUT_FOR_SIGNATURE && (
                                <TooltipWrapper
                                    wrapperClassName="wrapper-submit-button"
                                    text={
                                        eSignFlow === SEND_FOR_SIGNATURE
                                            ? "Send document for signature"
                                            : "Save and Close to finish sign"
                                    }
                                    style={{ padding: "7px 12px" }}
                                >
                                    <Button
                                        width="100%"
                                        margin="0 auto"
                                        borderRadius={4}
                                        padding={"0.5rem 1rem"}
                                        onClick={finishHandler}
                                        isDisable={isDisable}
                                        backgroundColor={
                                            isDisable ? "#d6dfe8" : "#7E89FF"
                                        }
                                        border="none"
                                        cursor={isDisable ? "wait" : "pointer"}
                                    >
                                        <div className="send-sign-flow-btn">
                                            {isDisable ? (
                                                <Loader
                                                    style={{
                                                        marginRight:
                                                            eSignFlow !==
                                                            SEND_FOR_SIGNATURE
                                                                ? "0.5rem"
                                                                : 0,
                                                        borderColor: "#fff",
                                                        borderTopColor: "#d6dfe8",
                                                        width: 18,
                                                        height: 18,
                                                    }}
                                                />
                                            ) : eSignFlow === SEND_FOR_SIGNATURE ? (
                                                <SendForESign
                                                    height={18}
                                                    width={18}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            <Text
                                                fontSize={14}
                                                color="#FFFFFF"
                                                marginLeft={
                                                    eSignFlow === SEND_FOR_SIGNATURE
                                                        ? "0.5rem"
                                                        : 0
                                                }
                                            >
                                                {eSignFlow === SEND_FOR_SIGNATURE
                                                    ? "Send For eSign"
                                                    : `Save and Close`}
                                            </Text>
                                        </div>
                                    </Button>
                                </TooltipWrapper>
                            )}
                        {finishDocumentLink && (
                            <div className="wrapper-submit-button">
                                <Button
                                    color={"blue"}
                                    width="170px"
                                    margin="0 auto"
                                    borderRadius={2}
                                    onClick={() =>
                                        handleDownload(finishDocumentLink)
                                    }
                                >
                                    {`Download`}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {type !== PREDEFINED_TEMPLATE && (
                <div className="editor-top-right">
                    <CreatorTitleBar
                        creator={{
                            picture: creator && creator.picture,
                            name:
                                creator &&
                                `${creator.first_name} ${creator.last_name}`,
                        }}
                        status={status}
                        publishDate={new Date().getTime()}
                        type={type}
                    />
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({ userReducer, signReducer }) => ({
    creator: userReducer.userDetails,
    eSignFlow: signReducer.flow,
    status: signReducer.documentDetails?.status,
    saving: signReducer.saving,
    tag: signReducer.documentDetails.tag,
    documentURL: signReducer.documentDetails.documentUrl,
    fileId: signReducer.documentDetails.documentId,
    fileName: signReducer.documentDetails.fileName,
    documentId: signReducer.documentDetails.id,
    finishDocumentLink: signReducer.finishDocumentLink,
    enableTemplateFlow: signReducer.documentDetails?.enableTemplateFlow,
    type: signReducer.documentDetails?.type,
    signature: signReducer.signature,
})

export default connect(mapStateToProps)(PdfTop)
