import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import { setCurrentFolder } from "Action/foldersAction"
import Guard from "Awesome-ui/Guard"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import TreeComponent from "Awesome-ui/TreeComponent/TreeComponent"
import { folderIcons } from "data/folderIcons"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import React from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

const StoreLeft = ({ showModalHandler, folderList = [], currentFolder }) => {
    const { splitText } = useBasicUtils()
    const history = useHistory()
    const defaultFolder = folderList?.find(
        (folder) => folder.name.toLowerCase() === "my documents"
    )
    return (
        <div>
            <TooltipWrapper
                text={"All documents owned by you or shared with you by others."}
                direction={"right"}
                align="center"
            >
                {/* <Guard scope="folder_create" show={true}>
                    <Button
                        style={{
                            width: "180px",
                            margin: "1rem auto",
                            marginBottom: "0.5rem",
                            borderRadius: 4,
                            background: "#33475b",
                        }}
                        radius="6px"
                        onClick={() => showModalHandler(true)}
                    >
                        Create New
                    </Button>
                </Guard> */}
                <TreeComponent
                    title="All Documents"
                    headerStyle={{
                        backgroundColor: currentFolder === -1 ? "#eaf0f6" : "",
                        color: currentFolder === -1 ? "#33475b" : "",
                    }}
                    onClick={(e) => {
                        history.push("/documents?page=1&size=10")
                        setCurrentFolder(-1)
                    }}
                    style={{ margin: "1.5rem auto 1rem auto", width: "85%" }}
                />
            </TooltipWrapper>
            <TooltipWrapper
                text={"All documents owned by only you"}
                direction={"right"}
                align="center"
            >
                <TreeComponent
                    title="My Documents"
                    headerStyle={{
                        backgroundColor:
                            currentFolder === defaultFolder?.id ? "#eaf0f6" : "",
                        color: currentFolder === defaultFolder?.id ? "#33475b" : "",
                    }}
                    onClick={(e) => {
                        history.push("/documents/my-documents?page=1&size=10")
                        setCurrentFolder(defaultFolder?.id)
                    }}
                    style={{ margin: "1rem auto 1.5rem auto", width: "85%" }}
                />
            </TooltipWrapper>
            <Guard scope="folder_get">
                <TreeComponent
                    title={
                        <TooltipWrapper text=" Your Folders" direction={"top"}>
                            <Text varient="body" weight={500}>
                                My Folders
                            </Text>
                        </TooltipWrapper>
                    }
                    isAddBranch={true}
                    addBranchTooltipText={"Create New Folder"}
                    setIsAddBranch={() => showModalHandler(true)}
                    isDropdown
                >
                    <div className="folder-list-holder">
                        {folderList?.length > 0 &&
                            folderList
                                .filter((folder) => folder !== defaultFolder)
                                .map((folder) => (
                                    <TreeComponent
                                        title={splitText(folder.name, 18)}
                                        logo={
                                            folderIcons.find(
                                                (item) => item.name === folder.icon
                                            )?.logo ||
                                            folder.icon ||
                                            folderIcons[0].logo
                                        }
                                        key={folder.id}
                                        onClick={() => {
                                            history.push(
                                                `/documents/folder/${folder.id}?page=1&size=10`
                                            )
                                            setCurrentFolder(folder.id, false)
                                        }}
                                        headerStyle={{
                                            backgroundColor:
                                                currentFolder === folder.id
                                                    ? "#eaf0f6"
                                                    : "",
                                            color:
                                                currentFolder === folder.id
                                                    ? "#33475b"
                                                    : "",
                                            fontWeight: 500,
                                            fontSize: 15,
                                        }}
                                        style={{
                                            marginTop: "0.25rem",
                                            width: "90%",
                                        }}
                                    />
                                ))}
                    </div>
                </TreeComponent>
            </Guard>
        </div>
    )
}

const mapStateToProps = ({ foldersReducer }) => {
    return {
        folderList: foldersReducer?.folderList,
        currentFolder: foldersReducer?.currentFolder,
    }
}

export default connect(mapStateToProps)(StoreLeft)
