import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as AddFilesIcon } from "../../Assets/Dashboard/add-files.svg"
import { ReactComponent as ArrowDownIcon } from "../../Assets/Dashboard/arrow-down.svg"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"

const useStyle = createUseStyles((theme) => ({
    signDrop: {
        border: "1px solid #727cf5",
        display: "flex",
        borderRadius: 60,
        backgroundColor: "#727cf5",
        boxShadow: "3 3 9 #babef4",
        "&:hover": {
            backgroundColor: "#727cf5",
            borderColor: "#727cf5",
            "& div:first-child": {
                borderColor: "#b8bdfa",
            },
        },
    },
    signDropLeft: {
        padding: "12px 1.5rem",
        display: "flex",
        alignItems: "center",
    },
    signRight: {
        padding: 12,
    },
}))

const PdfDropDown = () => {
    const classes = useStyle()
    return (
        <TooltipWrapper text={"Upload Document"} direction={"top"}>
            <div className={classes.signDrop}>
                <div className={classes.signDropLeft}>
                    <AddFilesIcon />
                    <Text varient="h6" marginLeft="1rem" style={{ color: "white" }}>
                        Select a file
                    </Text>
                </div>
            </div>
        </TooltipWrapper>
    )
}

export default PdfDropDown
