import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { Avatar } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import "./ProfileWithName.scss"

const ProfileWithName = ({
    title,
    image,
    subText,
    imgWidth,
    imgHeight,
    imgVarient,
    style,
    fontSize = 16,
    textStyle,
    imgStyle,
    subTextStyle,
    backgroundColor,
    ...porps
}) => {
    return (
        <div className="profile-with-name" style={style}>
            <Avatar
                name={title}
                width={imgWidth}
                height={imgHeight}
                size={imgWidth}
                imageSource={image}
                isTooltip={false}
                backgroundColor={backgroundColor}
            />
            <div className="avatar-text">
                <Text varient="h6" style={{ fontSize: fontSize + "px", ...textStyle }}>
                    {title && title}
                </Text>
                {subText && (
                    <Text varient="subText" style={{ fontSize: fontSize - 2 + "px", ...subTextStyle }}>
                        {subText}
                    </Text>
                )}
            </div>
        </div>
    )
}

export default ProfileWithName
