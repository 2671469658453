import {
    ADD_DOCUMENT_TO_FOLDER,
    ADD_DOCUMENT_TO_SPECIFIC_FOLDER,
    ADD_NEW_FOLDER,
    ADD_NEW_TEAM_MEMBER,
    DELETE_DOCUMENT_FROM_FOLDER,
    GET_FOLDERS_ALL,
    GET_FOLDER_BY_ID,
    GET_USER_BY_FOLDER_ID,
    SET_CURRENT_FOLDER,
    SET_FOLDERS_LOADED,
    SET_FOLDERS_LOADING,
    SET_FOLDERS_TO_NULL,
} from "ActionTypes/foldersActionType"
import { DELETE_USER_FROM_WORKSPACE } from "ActionTypes/workspaceActionTypes"
import { TEMPLATE } from "Types"

const initialize = {
    folderList: null,
    foldersLoading: true,
    currentFolder: -1,
}

export const foldersReducer = (state = initialize, action) => {
    const { payload, type } = action

    switch (type) {
        case GET_FOLDER_BY_ID:
            return setDataOfFolderGetById(state, payload)
        case GET_FOLDERS_ALL:
            return { ...state, folderList: payload, foldersLoading: false }
        case ADD_NEW_FOLDER:
            return addNewFolderToState(state, payload)
        case ADD_DOCUMENT_TO_FOLDER:
            return addDocumentToSpecificFolder(state, payload)
        case ADD_DOCUMENT_TO_SPECIFIC_FOLDER:
            return addDocumentToState(state, payload)
        case SET_FOLDERS_LOADING:
            return { ...state, foldersLoading: true }
        case SET_FOLDERS_LOADED:
            return { ...state, foldersLoading: false }
        case SET_CURRENT_FOLDER:
            return { ...state, currentFolder: payload.folderId }
        case SET_FOLDERS_TO_NULL:
            return { ...initialize }
        case GET_USER_BY_FOLDER_ID:
            return getUsersOfFolder(state, payload)
        case ADD_NEW_TEAM_MEMBER:
            return addNewMember(state, payload.newUser, payload.folderId)
        case DELETE_DOCUMENT_FROM_FOLDER:
            return handleDeleteDocument(state, payload)
        case DELETE_USER_FROM_WORKSPACE:
            return { ...state, folderList: payload.updatedFolderList }
        default:
            return state
    }
}

const setDataOfFolderGetById = (state, payload) => {
    const { folderId } = payload
    let folderList = [...state.folderList]
    let updatedFolderIndex = folderList?.indexOf(
        folderList?.find((folder) => folder.id === folderId)
    )
    if (updatedFolderIndex > -1) {
        folderList[updatedFolderIndex] = {
            ...folderList[updatedFolderIndex],
            ...payload,
        }
    }

    return { ...state, folderList: [...folderList] }
}

const addDocumentToSpecificFolder = (state, payload) => {
    const { folderId } = payload
    let editedFolderList = state.folderList ? [...state.folderList] : []
    let updatedFolderIndex = editedFolderList?.indexOf(
        editedFolderList?.find((folder) => folder.id === folderId)
    )
    if (updatedFolderIndex > -1) {
        editedFolderList[updatedFolderIndex].documents?.push(payload)
    }
    return { ...state, folderList: [...editedFolderList], foldersLoading: false }
}

const addNewFolderToState = (state, payload) => {
    let editedFolderList = state.folderList ? [...state.folderList] : []

    if (payload) {
        payload.documents = []
        editedFolderList = editedFolderList.concat(payload)
    }

    return {
        ...state,
        folderList: [...editedFolderList],
        foldersLoading: false,
    }
}

const getUsersOfFolder = (state, data) => {
    const { users, folderId } = data
    const { folderList } = state

    const updatedFolderList = [...folderList]
    let updatedFolder = updatedFolderList.find((folder) => folder.id === folderId)

    updatedFolder.users = [...users]
    return { ...state, folderList: updatedFolderList }
}

const addNewMember = (state, newUser, folderId) => {
    const { folderList } = state

    const updatedFolderList = [...folderList]
    let updatedFolder = updatedFolderList.find((folder) => folder.id === folderId)

    if (!updatedFolder.users) updatedFolder.users = [newUser]
    else updatedFolder.users = [...updatedFolder.users, newUser]

    return { ...state, folderList: updatedFolderList }
}
const handleDeleteDocument = (state, payload) => {
    const { fileId, folderId } = payload
    if(folderId === 0) return state
    let updatedFolderList = [...state.folderList]
    let updatedFolder = updatedFolderList.find((folder) => folder.id === folderId)
    updatedFolder.documents = updatedFolder.documents?.filter(
        (e) => e.fileId !== fileId
    )
    return { ...state, folderList: updatedFolderList }
}

const addDocumentToState = (state, payload) => {
    const { fileData, folderId } = payload
    if(fileData.type === TEMPLATE) return state
    let updatedFolderList = [...state.folderList]
    let updatedFolder = updatedFolderList.find((folder) => folder.id === folderId)
    if(!updatedFolder.documents) updatedFolder.documents = []
    updatedFolder.documents.push(fileData)
    return { ...state, folderList: updatedFolderList }
}
