import { Avatar } from "@awesomesuite-frontend/awesome-nebula"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import React from "react"

/**
 * For the user avatar preview and dorp down in the for the other user
 * related details or links like  go to accounts app / refer and log out etc.
 *
 */
const User = ({
    userDetails,
    showUserDropDown,
    setShowUserDropDown,
    logoutHandler,
}) => {
    return (
        <TooltipWrapper text={"Profile"} direction={"bottom"}>
            <div
                style={{ position: "relative", cursor: "pointer" }}
                onClick={() => {
                    setShowUserDropDown((prev) => !prev)
                }}
            >
                <Avatar
                    name={
                        userDetails &&
                        `${userDetails.first_name} ${userDetails.last_name}`
                    }
                    imageSource={userDetails && userDetails.picture}
                    isTooltip={false}
                />
            </div>
        </TooltipWrapper>
    )
}

export default User
