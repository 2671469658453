import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as NoFileIcon } from "Assets/Storage/noFile.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

const useStyles = createUseStyles(() => ({
    noFileWrapper: {
        backgroundColor: ({ backgroundColor }) => backgroundColor && backgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "2rem",
        border: "1px solid #E3EBEE",
    },
}))

const NoFilesOnSearch = ({ currentFolder, ...props }) => {
    const classes = useStyles(props)
    return (
        <div className={classes.noFileWrapper}>
            <NoFileIcon />
            <Text fontSize={24} margin="2rem 0 1rem 0">
                No Results Found
            </Text>
            <Text
                color="light"
                marginBottom="2rem"
                style={{ width: "max(50%, 400px)" }}
                align="center"
            >
                You can try again, by changing the search term or filters until you
                find what you're looking for or you might not have any documents in
                the folder.
            </Text>
        </div>
    )
}

export default NoFilesOnSearch
