export const getCanvasSize = (aspectRatio = Math.sqrt(2)) => {
    let width = 980
    if (window.innerWidth < 1400) {
        width = 835
    } else if (window.innerWidth < 1500) {
        width = 875
    } else if (window.innerWidth < 1600) {
        width = 950
    } else width = 980

    return { width, height: width * aspectRatio }
}

export const getCanvasScale = () => {
    let scale = 1.7
    if (window.innerWidth < 1400) {
        scale = 3
    } else if (window.innerWidth < 1500) {
        scale = 2.6
    } else if (window.innerWidth < 1600) {
        scale = 2.2
    } else {
        scale = 1.7
    }
    return scale
}
