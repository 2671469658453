import React, { useState } from "react"
import { connect } from "react-redux"
import {
    setSideBarType,
    setNoSideBar,
    setSideBarColor,
} from "../../../../Action/settingActions"
import Loader from "../../../../Awesome-ui/Loader/Loader"

import "./Customization.scss"

const Customization = ({ user }) => {
    const [editing, setEdit] = useState(false)

    return (
        <div className="cust-setting">
            <div className="cust-left">
                <div className="setting-title">
                    <h5>Profile Photo</h5>
                </div>
                <div>
                    <div className="title">Change the Sidebar Style</div>
                    <div className="custom-btns">
                        <div
                            className="round-btn"
                            onClick={() => setSideBarType("slim")}
                        >
                            lite
                        </div>
                        <div
                            className="round-btn"
                            onClick={() => setSideBarType("named")}
                        >
                            named
                        </div>
                    </div>
                    <div className="custom-btns">
                        <div
                            className="round-btn"
                            onClick={() => setSideBarColor("light")}
                        >
                            light
                        </div>
                        <div
                            className="round-btn"
                            onClick={() => setSideBarColor("dark")}
                        >
                            dark
                        </div>
                    </div>
                    <div className="custom-btns" style={{ marginTop: "1rem" }}>
                        <div className="round-btn" onClick={() => setNoSideBar()}>
                            side Bar
                        </div>
                    </div>
                </div>
            </div>
            <div className="buttons">
                {!editing ? (
                    <div className="btn-base edit-btn" onClick={() => setEdit(true)}>
                        Edit
                    </div>
                ) : (
                    <>
                        <div className="btn-base edit-btn" onClick={() => {}}>
                            Discard
                        </div>
                        <div className="btn-base save-btn" onClick={() => {}}>
                            Save{" "}
                            {editing && (
                                <Loader
                                    h="1rem"
                                    w="1rem"
                                    b="2px"
                                    clr="white"
                                    bk="rgba(255,255,255,0.35)"
                                    t="1s"
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = ({ userReducer }) => {
    return { user: userReducer.user }
}

export default connect(mapStateToProps)(Customization)
