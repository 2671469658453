import { createUseStyles } from "react-jss"
export const useStyles = createUseStyles(() => ({
    loader: {
        position: "fixed",
        height: "92vh",
        width: "96vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F4F7F9",
        zIndex: 5000,
    },
    backgroundCompound: {
        position: "fixed",
        height: "100vh",
        width: "100vw",
        background: "rgba(45,62,80,0.72)",
        top: 0,
        left: 0,
        zIndex: 1000000000000000,
    },
    createFolderModal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "600px",
        backgroundColor: "#fff",
        borderRadius: "6px",
    },
    createFolderModalLarge: {
        width: 750,
    },
    modalHeader: {
        background:
            "#fff",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "6px 6px 0 0",
    },
    cancelLogo: {
        marginRight: "1rem",
        padding: "0.5rem",
        borderRadius: "50%",
        cursor: "pointer",
        "& svg g path": {
            stroke: "#33475b",
        },
        "&:hover": {
            backgroundColor: "#99ACC2",
            "& svg g path": {
                stroke: "#fff",
            },
        },
    },
    modalContent: {
        padding: "2rem 1.5rem",
        borderRadius: "0 0 6px 6px",
        border: "1px solid var(--border-color)",
    },
    folderSelector: {
        border: "1px solid var(--border-color)",
        width: "80%",
        aspectRatio: 1,
        marginTop: "auto",
        borderRadius: "4px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    folderSelectorCurrent: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    folderSelectorMap: {
        width: "180px",
        position: "absolute",
        bottom: 0,
        right: 0,
        transform: "translateY(calc(100% + 4px))",
        borderRadius: "4px",
        border: "1px solid var(--border-color)",
        padding: "1rem",
        backgroundColor: "#fff",
    },
    addMemberSwitch: {
        width: "100%",
        marginTop: "1rem",

        "& div": {
            textAlign: "left",
        },
    },
    addMemberSwitchContent: {
        display: "inline-block",
        textAlign: "center",
        transition: "all 0.3s ease-in-out",
        whiteSpace: "nowrap",
        color: "#1592e6",
        fontSize: "0.9rem",
    },
    addMember: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "2.5rem",
        height: "2.5rem",
        borderRadius: "50%",
        border: "1px dashed #1592e6",
        color: "#1592e6",
        marginRight: "1rem",
        background: "white",
        transition: "all 0.3s ease",
        outline: "none",
        boxShadow: "none",

        "&:hover": {
            color: "white",
            background: "rgba(21, 146, 230, 1)",
            borderColor: "#1592e6",
            borderStyle: "solid",
            outline: "none",
            boxShadow: "none",
        },
        "&:active": {
            outline: "none",
            boxShadow: "none",
        },
        "&:focus": {
            outline: "none",
            boxShadow: "none",
        },

        "@media (max-width: 992px)": {
            width: "2.25rem",
            height: "2.25rem",
        },
    },
    inputUtilityHolder: {
        margin: "0.25rem 0",
    },
}))
