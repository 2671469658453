import React, { useState } from "react"
import { Link } from "react-router-dom"
import InputBox from "../../../Awesome-ui/InputBox"
import Loader from "../../../Awesome-ui/Loader/Loader"

const Form = ({ updateMessage, onSubmitForm, loading }) => {
    const [first, setFirst] = useState("")
    const [last, setLast] = useState("")
    const [email, setEmail] = useState("")
    const [company, setCompany] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [checks, addChecks] = useState(false)
    const [curActive, changeActive] = useState(0)

    const [countCheck, updateCheck] = useState({
        len: false,
        upp: false,
        low: false,
        spe: false,
    })

    let check = (password) => {
        let len = false,
            upp = false,
            low = false,
            spe = false
        if (password.length >= 8) len = true
        for (let i = 0; i < password.length; i++) {
            if (password[i] >= "A" && password[i] <= "Z") upp = true
            if (password[i] >= "a" && password[i] <= "z") low = true
            if (
                !(
                    (password[i] >= "a" && password[i] <= "z") ||
                    (password[i] >= "A" && password[i] <= "Z") ||
                    (password[i] >= "0" && password[i] <= "9")
                )
            )
                spe = true
        }
        const ansCheck = {
            len,
            upp,
            low,
            spe,
        }
        return ansCheck
    }

    const submitHandler = () => {
        const newEmail = email.trim()
        const newFirst = first.trim()
        const newLast = last.trim()
        const newCompany = company.trim()
        const newPhone = phone.trim()

        if (
            newEmail.length === 0 ||
            !(
                countCheck.len &&
                countCheck.spe &&
                countCheck.low &&
                countCheck.upp
            ) ||
            newFirst.length === 0 ||
            newLast.length === 0 ||
            newPhone.length === 0
        ) {
            return updateMessage("warn", "Enter all the Fields")
        }

        const body = {
            user_name: newCompany,
            password: password,
            first_name: newFirst,
            last_name: newLast,
            email_id: email,
            phone_number: newPhone,
        }
        onSubmitForm(body)
    }

    return (
        <div className="formBox">
            <div className="iconContainer">
                <div className="icon">
                    <img src={""} alt="Awesome Social" />
                </div>
            </div>
            <form
                className="formSpace"
                onKeyDown={(e) => {
                    if (e.key === "Enter") submitHandler()
                }}
                onSubmit={submitHandler}
            >
                <div className="name">
                    <InputBox
                        placeHolder="First Name"
                        complete="off"
                        type="text"
                        src={""}
                        name="first"
                        value={first}
                        changeFunc={(val) => setFirst(val)}
                        ind={0}
                        onFocus={() => changeActive(0)}
                        onBlur={() => changeActive(null)}
                        focus={curActive}
                        req={true}
                    />
                    <InputBox
                        placeHolder="Last Name"
                        complete="off"
                        type="text"
                        name="last"
                        value={last}
                        src={""}
                        changeFunc={(val) => setLast(val)}
                        ind={1}
                        onFocus={() => changeActive(1)}
                        onBlur={() => changeActive(null)}
                        focus={curActive}
                        req={true}
                    />
                </div>
                <InputBox
                    placeHolder="Email"
                    complete="off"
                    type="email"
                    name="email"
                    value={email}
                    src={""}
                    changeFunc={(val) => setEmail(val)}
                    ind={2}
                    onFocus={() => changeActive(2)}
                    onBlur={() => changeActive(null)}
                    focus={curActive}
                    req={true}
                />
                <InputBox
                    placeHolder="Company"
                    complete="off"
                    type="text"
                    name="company"
                    value={company}
                    src={""}
                    ind={3}
                    onFocus={() => changeActive(3)}
                    onBlur={() => changeActive(null)}
                    focus={curActive}
                    changeFunc={(val) => setCompany(val)}
                    req={true}
                />
                <InputBox
                    placeHolder="Phone No."
                    complete="off"
                    type="tel"
                    name="telephone"
                    value={phone}
                    src={""}
                    changeFunc={(val) => setPhone(val)}
                    ind={4}
                    onFocus={() => changeActive(4)}
                    onBlur={() => changeActive(null)}
                    focus={curActive}
                    req={true}
                />
                <InputBox
                    placeHolder="Password"
                    complete="off"
                    type="password"
                    name="password"
                    value={password}
                    ind={5}
                    onFocus={() => changeActive(5)}
                    onBlur={() => changeActive(null)}
                    focus={curActive}
                    brdr={
                        checks
                            ? countCheck.len &&
                              countCheck.upp &&
                              countCheck.low &&
                              countCheck.spe
                                ? "rgba(128, 128, 128, 0.35)"
                                : "red"
                            : null
                    }
                    src={""}
                    changeFunc={(val) => {
                        addChecks(true)
                        setPassword(val)
                        let ans = check(val)
                        updateCheck(ans)
                    }}
                    req={true}
                />
                {checks && (
                    <div className={`checks ${checks && "inc"}`}>
                        <div className="check">
                            <div className={`radio ${countCheck.len ? "done" : ""}`}>
                                <div></div>
                            </div>
                            <div className="sentence">
                                At least 8 characters long
                            </div>
                        </div>
                        <div className="check">
                            <div className={`radio ${countCheck.upp ? "done" : ""}`}>
                                <div></div>
                            </div>
                            <div className="sentence">One UPPERCASE character</div>
                        </div>
                        <div className="check">
                            <div className={`radio ${countCheck.low ? "done" : ""}`}>
                                <div></div>
                            </div>
                            <div className="sentence">One lowercase character</div>
                        </div>
                        <div className="check">
                            <div className={`radio ${countCheck.spe ? "done" : ""}`}>
                                <div></div>
                            </div>
                            <div className="sentence">One Special Character</div>
                        </div>
                    </div>
                )}

                <div
                    className="button"
                    tabIndex="0"
                    type="submit"
                    onClick={submitHandler}
                >
                    SIGN UP FOR FREE
                    {loading && (
                        <span>
                            {"  "}
                            <Loader
                                h="1rem"
                                w="1rem"
                                b="2px"
                                clr="white"
                                bk="rgba(255,255,255,0.35)"
                                t="1s"
                            />
                        </span>
                    )}
                </div>

                <p>
                    By clicking on "SIGN UP FOR FREE", you agree to our{" "}
                    <Link className="green-clr" to="/">
                        Terms
                    </Link>{" "}
                    and acknowledge having out{" "}
                    <Link className="green-clr" to="/">
                        Privacy Notice
                    </Link>{" "}
                </p>

                <div className="hrDiv">
                    <hr />
                    <p>OR</p>
                    <hr />
                </div>
                <div className="googleBtn">
                    <div tabIndex="0">
                        <img src={""} alt="google" /> &nbsp; Sign Up With Google
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Form
