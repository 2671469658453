import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as RolesIcon } from "Assets/PdfNavigation/roles.svg"
import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import AddRole from "./AddRoles/AddRole"
import EachRole from "./EachRole/EachRole"
import { connect } from "react-redux"
import { DRAFT } from "Types"

const useStyles = createUseStyles({
    rolesWrapper: {
        paddingBottom: "1rem",
    },
    rolesHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        padding: "1rem",
        border: "1px solid #CCDFF2",
        borderTop: "none",
    },
    button: {
        textAlign: "center",
        borderRadius: 4,
        justifyContent: "center",
    },
    addSignerButton: {
        border: "1px solid #d6dfe8",
        backgroundColor: "#fff",
        "& span": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        },
        "&:hover": {
            boxShadow: "0px 3px 6px #d6dfe8",
            opacity: 1,
        },
    },
    buttonIcon: {
        marginRight: "0.5rem",
        backgroundColor: "#00BDA5",
        borderRadius: "50%",
        height: "1.5rem",
        width: "1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5rem",
    },
    addSignerButtonWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
})

const Roles = ({ roles, status }) => {
    const classes = useStyles()
    const [addRoles, setAddRoles] = React.useState(false)

    return (
        <div className={classes.rolesWrapper}>
            <div className={classes.rolesHeader}>
                <RolesIcon height={25} width={25} fill="#33475b" />
                <Text weight={600} style={{ flex: 1 }} marginLeft="1rem">
                    Create Roles
                </Text>
            </div>
            {roles?.map((role) => (
                <EachRole roleId={role.id} {...role} key={role.id} />
            ))}
            {addRoles && <AddRole afterAddRole={() => setAddRoles(false)} />}
            {status === DRAFT && (
                <TooltipWrapper
                    wrapperClassName={classes.addSignerButtonWrapper}
                    text={"Add Roles"}
                    direction={"top"}
                >
                    <Button
                        className={`${classes.button} ${classes.addSignerButton}`}
                        width="90%"
                        onClick={() => setAddRoles((prev) => !prev)}
                        padding={"0.5rem 1rem"}
                    >
                        <div className={classes.buttonIcon}>+</div>
                        <Text weight={500} fontSize={14}>
                            Add Roles for the Template
                        </Text>
                    </Button>
                </TooltipWrapper>
            )}
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    roles: signReducer.roles,
    status: signReducer.status,
})

export default connect(mapStateToProps)(Roles)
