import { createUseStyles } from "react-jss"

export const useStyle = createUseStyles({
    threeDotDropdown: {
        position: "relative",
        width: "2rem",
        height: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0.25rem",
        borderRadius: "50%",
        transition: "background-color 0.05s ease",
        cursor: "pointer",

        "&:active": {
            backgroundColor: "rgba(32, 33, 36, 0.1)",
        },
        "&:focus-within": {
            backgroundColor: "rgba(32, 33, 36, 0.1)",
        },
    },
    threeDotDropdownActive: {
        "&:hover": {
            backgroundColor: "rgba(32, 33, 36, 0.1)",
        },
    },
    threeDotDropdownInactive: {
        cursor: "not-allowed",
    },

    dotIcon: {
        width: "0.25rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& path": {
            fill: "#99acc2",
        },
    },

    threeDotSubDropdown: {
        zIndex: 100000,
        position: "absolute",
        top: "100%",
        left: 0,
        backgroundColor: "white",
        border: "1px solid rgb(204, 223, 242)",
        boxShadow: "rgba(98, 85, 143, 0.75) 0px 5px 16px -7px",
        borderRadius: "6px",
        minWidth: "2rem",

        padding: "0.5rem 0",

        "& ul": {
            minHeight: "2rem",
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            padding: "0.25rem 0.75rem",
            transition: "all 0.1s ease",
            color: "inherit",

            "&:hover": {
                backgroundColor: "#eaf0f6",
            },
        },
    },

    threeDotSubDropdownClose: {
        display: "none",
    },

    threeDotSubDropdownopen: {
        display: "block",
    },
})
