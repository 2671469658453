import { Button, Grid, Text } from "@awesomesuite-frontend/awesome-nebula"
import Input from "Awesome-ui/Input/Input"
import React, { useEffect } from "react"
import { useState } from "react"
import { createUseStyles } from "react-jss"
import { folderIcons } from "data/folderIcons"
import { ReactComponent as CancelIcon } from "../../../../Assets/Storage/cancel-icon.svg"
import { addNewFolder } from "Action/foldersAction"
import EmojiPicker from "../EmojiPicker"
import { DANGER } from "Types"
import { createMessage } from "Action/messageAndNotificationActions"
import useToggle from "hooks/ToggleHook"

const useStyles = createUseStyles(() => ({
    backgroundCompound: {
        position: "fixed",
        height: "100%",
        width: "100%",
        background: "rgba(45,62,80,0.72)",
        top: 0,
        left: 0,
        zIndex: 1000000003,
    },
    createFolderModal: {
        position: "absolute",
        top: "25%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "400px",
        backgroundColor: "#fff",
        borderRadius: "6px",
    },
    modalHeader: {
        background: "#fff",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "6px 6px 0 0",
    },
    cancelLogo: {
        marginRight: "1rem",
        padding: "0.5rem",
        borderRadius: "50%",
        cursor: "pointer",
        "& svg g path": {
            stroke: "#33475b",
        },
        "&:hover": {
            backgroundColor: "#99ACC2",
            "& svg g path": {
                stroke: "#fff",
            },
        },
    },
    modalContent: {
        padding: "2rem 1.5rem",
        borderRadius: "0 0 6px 6px",
        border: "1px solid var(--border-color)",
    },
    folderSelector: {
        width: "80%",
        aspectRatio: 1,
        marginTop: "auto",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    folderSelectorCurrent: {
        border: "1px solid var(--border-color)",
        borderRadius: "6px",
        height: 42.22,
        aspectRatio: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontSize: "22px",
    },
    folderSelectorMap: {
        width: "350px",
        position: "absolute",
        bottom: 0,
        right: 0,
        transform: "translateY(calc(100% + 4px))",
        borderRadius: "4px",
        border: "1px solid var(--border-color)",
        padding: "1rem",
        backgroundColor: "#fff",
    },
    folderIcon: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "0.5rem",
    },
    folderSelectorTopics: {
        display: "flex",
        alignItems: "center",
        marginBottom: "0.5rem",
        "& div": {
            paddingBottom: "0.5rem",
            marginRight: "0.75rem",
        },
    },
}))

const CreateFolder = ({ setShowModal }) => {
    const classes = useStyles()
    const [folderIconIndex, setFolderIconIndex] = useState("📝")
    const folderOptionsTogglerRef = React.useRef(null)
    const [isFolderOptionsShow, toggleFoldersOptionShow] = useToggle(
        folderOptionsTogglerRef
    )
    const [folderName, setFolderName] = useState("")
    const [isButtonDisable, setIsButtonDisable] = useState(false)
    const [showIcons, setShowIcons] = useState(true)

    const createFolder = async () => {
        if (folderName.trim() === "")
            return createMessage(
                "Folder name can't be empty",
                "Please enter a valid folder name",
                DANGER
            )
        let icon
        if (typeof folderIconIndex === "number")
            icon = folderIcons[folderIconIndex].name
        else icon = folderIconIndex

        setIsButtonDisable(true)
        await addNewFolder({
            folderName: folderName.trim(),
            icon,
        })
        setIsButtonDisable(false)
        setShowModal(false)
    }
    const keyPress = (e) => {
        if (e.code === "Enter") {
            createFolder()
        }
    }
    useEffect(() => {
        window.addEventListener("keypress", keyPress)
        return () => window.removeEventListener("keypress", keyPress)
    })
    return (
        <div className={classes.backgroundCompound}>
            <div className={classes.createFolderModal}>
                <div className={classes.modalHeader}>
                    <Text color="#33475b" marginLeft="1rem">
                        Create New Folder
                    </Text>
                    <div
                        className={classes.cancelLogo}
                        onClick={() => setShowModal(false)}
                    >
                        <CancelIcon />
                    </div>
                </div>
                <div className={classes.modalContent}>
                    <Grid container spacing={"10"}>
                        <Grid item xs={2} style={{ paddingTop: "27px" }}>
                            <div
                                className={classes.folderSelector}
                                ref={folderOptionsTogglerRef}
                            >
                                <div
                                    className={classes.folderSelectorCurrent}
                                    onClick={() => toggleFoldersOptionShow()}
                                >
                                    {typeof folderIconIndex === "number"
                                        ? folderIcons[folderIconIndex].logo
                                        : folderIconIndex}
                                </div>
                                {isFolderOptionsShow && (
                                    <div className={classes.folderSelectorMap}>
                                        <div
                                            className={classes.folderSelectorTopics}
                                        >
                                            <div onClick={() => setShowIcons(true)}>
                                                <Text
                                                    varient="subText"
                                                    style={{
                                                        borderBottom: `1px solid ${
                                                            showIcons
                                                                ? "#7C98B6"
                                                                : "transparent"
                                                        }`,
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Icons
                                                </Text>
                                            </div>
                                            <div onClick={() => setShowIcons(false)}>
                                                <Text
                                                    varient="subText"
                                                    style={{
                                                        borderBottom: `1px solid ${
                                                            !showIcons
                                                                ? "#7C98B6"
                                                                : "transparent"
                                                        }`,
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Emojis
                                                </Text>
                                            </div>
                                        </div>
                                        {showIcons ? (
                                            <Grid container spacing={4}>
                                                {folderIcons.map((icon, key) => (
                                                    <Grid
                                                        item
                                                        xs={2}
                                                        key={key}
                                                        onClick={() => {
                                                            setFolderIconIndex(key)
                                                            toggleFoldersOptionShow()
                                                        }}
                                                        className={
                                                            classes.folderIcon
                                                        }
                                                    >
                                                        {icon.logo}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        ) : (
                                            <EmojiPicker
                                                addEmoji={(val) => {
                                                    setFolderIconIndex(val.emoji)
                                                    toggleFoldersOptionShow()
                                                }}
                                                height="350px"
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={9}>
                            <Input
                                label="Folder Name"
                                borderRadius={"6px"}
                                value={folderName}
                                changeHandler={setFolderName}
                            ></Input>
                            <Button
                                style={{ marginTop: "1rem", paddingLeft: "1.8rem" }}
                                color="gray-1"
                                width={"100px"}
                                onClick={createFolder}
                                disabled={isButtonDisable}
                            >
                                Create
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

export default CreateFolder
