import React from "react"
import { createUseStyles } from "react-jss"
import { Button, Grid, Text } from "@awesomesuite-frontend/awesome-nebula"
import { ReactComponent as ForMyselfIcon } from "Assets/onboarding/for-myself-icon.svg"
import { ReactComponent as ForMyteamIcon } from "Assets/onboarding/for-myteam-icon.svg"
import CircleCheckBox from "Awesome-ui/CircleCheckBox/CircleCheckBox"
import { useAnimationStyles } from "../animateStyle"
import { ReactComponent as BackArrow } from "Assets/PdfEditor/down-arrow.svg"

const useStyle = createUseStyles(() => ({
    welcomeOnBoard: {
        position: "absolute",
        maxWidth: "700px",
        left: "50%",
        transform: "translateX(-50%)",
        padding: "1.5rem 2.5rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
    },
    welcomeOnBoardIcon: {
        "& svg": {
            width: "180px",
            height: "180px",
        },
    },
    gridChecker: {
        minHeight: "300px",
        margin: "2rem 0",
        borderRadius: "12px",
        boxShadow: "0px 3px 6px rgba(203,214,226, 0.41)",
        border: "1px solid #DFE3EB",
        backgroundColor: "#fff",

        "& svg": {
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            margin: "1rem 0",
        },
        padding: "0 1rem",
        position: "relative",
        cursor: "pointer",
    },
    gridCheckerActive: {
        border: "1px solid #00A4BD",
        backgroundColor: "#E5F5F8",
        boxShadow: "0px 3px 6px rgba(0, 164, 189, 0.41)",
    },
    disableBtn: {
        "&:disabled": {
            cursor: "not-allowed",
            backgroundColor: "#d7dde3",
        },
    },
    backButton: {
        position: "absolute",
        top: "2rem",
        left: "5rem",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
}))

const checkBoxStyle = {
    position: "absolute",
    right: "0.5rem",
    top: " 0.5rem",
    border: "1px solid #DFE3EB",
}
const checkBoxActiveStyle = {
    position: "absolute",
    right: "0.5rem",
    top: " 0.5rem",
    backgroundColor: "#22B7CE",
    border: "1px solid #22B7CE",
}

const WelcomeOnBoard = ({
    options,
    setOptions,
    setView,
    onWorkspaceSubmit = () => {},
}) => {
    const classes = useStyle()
    const animationStyles = useAnimationStyles()

    return (
        <div
            style={{ paddingTop: "5rem", position: "relative" }}
            className={animationStyles.animateUp}
        >
            <div className={classes.backButton} onClick={() => setView(0)}>
                <BackArrow
                    height={30}
                    width={30}
                    fill="#00aeef"
                    style={{ transform: "rotate(90deg)", marginRight: "1rem" }}
                />
                <Text fontSize={20} color="#00aeef">
                    Back
                </Text>
            </div>
            <Text varient="h4" align="center">
                How do you plan to use Awesome Sign?
            </Text>
            <Text fontSize={18} margin="2rem 0" align="center">
                Tell us how you intend to use Awesome Sign so we can improve your
                experience.
            </Text>
            <div className={classes.welcomeOnBoard}>
                <Grid
                    container
                    style={{ width: "100%" }}
                    justify="center"
                    spacing={8}
                >
                    <Grid
                        item
                        md={5}
                        xs={10}
                        className={`${classes.gridChecker} ${
                            options.MYSELF && classes.gridCheckerActive
                        }`}
                        onClick={() =>
                            setOptions({ ...options, MYSELF: true, MYTEAM: false })
                        }
                    >
                        <CircleCheckBox
                            style={
                                options.MYSELF
                                    ? { ...checkBoxActiveStyle }
                                    : { ...checkBoxStyle }
                            }
                            select={options.MYSELF}
                            selectChange={() =>
                                setOptions({ ...options, MYSELF: true })
                            }
                        />
                        <ForMyteamIcon />
                        <Text
                            varient="h5"
                            align="center"
                            style={{ margin: "0.5rem 0" }}
                            color="#33475B"
                        >
                            For My Team
                        </Text>
                        <Text align="center" varient="subText" color="#516F90">
                            Collaborate with your teams to sign, send and track
                            simple eSignature workflows.
                        </Text>
                    </Grid>

                    <Grid
                        item
                        md={5}
                        xs={10}
                        className={`${classes.gridChecker} ${
                            options.MYTEAM && classes.gridCheckerActive
                        }`}
                        onClick={() =>
                            setOptions({ ...options, MYTEAM: true, MYSELF: false })
                        }
                    >
                        <CircleCheckBox
                            style={
                                options.MYTEAM
                                    ? { ...checkBoxActiveStyle }
                                    : { ...checkBoxStyle }
                            }
                            select={options.MYTEAM}
                            selectChange={() =>
                                setOptions({ ...options, MYTEAM: true })
                            }
                        />
                        <ForMyselfIcon />
                        <Text
                            varient="h5"
                            align="center"
                            style={{ margin: "0.5rem 0" }}
                            color="#33475B"
                        >
                            For Myself
                        </Text>
                        <Text align="center" varient="subText" color="#516F90">
                            Self sign documents and send signature request.
                        </Text>
                    </Grid>
                    <Button
                        color="#33475b"
                        size="medium"
                        radius={6}
                        width="80%"
                        disabled={
                            options.MYSELF === false && options.MYTEAM === false
                        }
                        onClick={onWorkspaceSubmit}
                        className={`${
                            options.MYSELF === false && options.MYTEAM === false
                                ? classes.disableBtn
                                : ""
                        }`}
                    >
                        <Text color="#fff" fontSize={18} weight={500}>
                            Next
                        </Text>
                    </Button>
                </Grid>
            </div>
        </div>
    )
}

export default WelcomeOnBoard
