import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import Arrow from "../HelpSideNavbar/images/right-arrow.svg"
import "./ResourceCenterCard.scss"

/**
 * Resource Card Used In ResourceCenterContainer Component
 *
 * @param {String} name
 * @param {String} description
 *
 */

const ResourceCenterCard = ({ name, description, Icon, directTo }) => {
    return (
        <div className="resorce-center-card">
            <a href={directTo} style={{ textDecoration: "none" }} target="_blank">
                <div className="aui-rc-icon">{Icon && <Icon />}</div>
                <div className="aui-rc-title">
                    <Text varient="h5">{name}</Text>
                    <Text fontSize={13} color="light">
                        {description}
                    </Text>
                </div>
                <img src={Arrow} alt="arrow" />
            </a>
        </div>
    )
}

export default ResourceCenterCard
