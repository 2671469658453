import { getCanvasScale, getCanvasSize } from "Library/canvasAdjuster"
import { FORM, SIGNER } from "Types"
import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"

const checkboxAnnotationBugHandler = (e) => {
    e.stopPropagation()
    if (e.target.id?.includes("pdfjs_internal") && e.target.type === "checkbox") {
        const name = e.target.getAttribute("name")
        if (!name) return
        e.target.setAttribute("data-name", name)
        e.target.removeAttribute("name")
    }
}

const { height: NORMAL_HEIGHT, width: NORMAL_WIDTH } = getCanvasSize()

function getRenderingSize(
    height,
    width,
    boundingHeight = NORMAL_HEIGHT,
    boundingWidth = NORMAL_WIDTH
) {
    let renderingHeight = height,
        renderingWidth = width
    const aspectRatio = height / width

    if (aspectRatio > 1) {
        //portrait
        if (renderingWidth > boundingWidth) {
            renderingWidth = boundingWidth
            renderingHeight = boundingWidth * aspectRatio
        }
        if (renderingHeight > boundingHeight) {
            renderingHeight = boundingHeight
            renderingWidth = boundingHeight / aspectRatio
        }
    } else {
        //landscape
        if (renderingHeight > boundingHeight) {
            renderingHeight = boundingHeight
            renderingWidth = boundingHeight / aspectRatio
        }
        if (renderingWidth > boundingWidth) {
            renderingWidth = boundingWidth
            renderingHeight = boundingWidth * aspectRatio
        }
    }

    return { width: renderingWidth, height: renderingHeight }
}

const AnnotationLayer = ({
    page,
    pageNumber,
    pdfjs,
    view,
    editingMode,
    pdfViewer,
    annotationStorages,
    pdfHelpers,
}) => {
    const ref = useRef([null])
    if (!annotationStorages.current[pageNumber - 1]) {
        annotationStorages.current[pageNumber - 1] = {
            annotationStorage: new pdfHelpers.AnnotationStorage(),
        }
    }
    async function renderDom() {
        const annotations = await page.getAnnotations()
        const isSignerSigningInFormView = view === FORM && editingMode === SIGNER
        if (!annotations || annotations.length === 0) return

        let scale = isSignerSigningInFormView ? 2.5 : getCanvasScale()
        let viewPort = page.getViewport({ scale })
        if (!viewPort) return

        let maxWidthBound = isSignerSigningInFormView
            ? Math.min(0.6 * window.innerWidth, NORMAL_WIDTH)
            : NORMAL_WIDTH
        const { height, width } = getRenderingSize(
            viewPort.height,
            viewPort.width,
            NORMAL_HEIGHT,
            maxWidthBound
        )
        viewPort = page.getViewport({ scale: (width / viewPort.width) * scale })
        const annotatedDiv = ref.current[0]

        annotationStorages.current[pageNumber - 1].height = height
        annotationStorages.current[pageNumber - 1].width = width

        const renderParameters = {
            downloadManager: undefined,
            renderInteractiveForms: true,
            viewport: viewPort.clone({ dontFlip: true }),
            div: annotatedDiv,
            annotations: annotations,
            linkService: new pdfViewer.PDFLinkService({
                eventBus: new pdfViewer.EventBus(),
                externalLinkTarget: 2,
            }),
            annotationLayerFactory: new pdfViewer.DefaultAnnotationLayerFactory(),
            page: page,
            annotationStorage:
                annotationStorages.current[pageNumber - 1].annotationStorage,
        }

        annotatedDiv.innerHTML = ""

        try {
            pdfjs.AnnotationLayer.render(renderParameters)
        } catch (error) {
            console.log(">>> error", error)
        }
    }

    useEffect(() => {
        if (page) renderDom()

        ref.current[0].addEventListener("click", checkboxAnnotationBugHandler)

        return () => {
            ref.current[0]?.removeEventListener(
                "click",
                checkboxAnnotationBugHandler
            )
        }
    }, [page])

    return (
        <div
            ref={(el) => (ref.current[0] = el)}
            name={`${pageNumber}`}
            className={"Page__annotations annotationLayer"}
        />
    )
}

const mapStateToProps = ({ signReducer }) => ({
    activeToolType: signReducer.activeTool?.type,
    view: signReducer.documentDetails?.view,
    editingMode: signReducer.editingMode,
})

export default connect(mapStateToProps)(AnnotationLayer)
