import React, { useState, useRef } from "react"
import { Rnd } from "react-rnd"
import { dragClasses, dragStyle, dragStyleTransparent } from "../Common/Style"
import {
    addDataToElement,
    captureUserClick,
    clickToSignEach,
    dragSignatureElement,
    removeSignatureElement,
    resizeSignatureElement,
    // resizeSignatureElement,
} from "../../../../../Action/signAction"
import Wrapper from "../Common/Wrapper"
import { addFields, deleteFields } from "Action/signersActions"
import { createUseStyles } from "react-jss"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { ReactComponent as SignIcon } from "Assets/ToolsIcons/sign.svg"
import { ReactComponent as ClickIcon } from "Assets/ToolsIcons/click.svg"
import { CLICK_FOR_SIGN, OUT_FOR_SIGNATURE, SIGNER } from "Types"
import { SignatureDragType } from "../../Types"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import DragPreviewer from "../DragPreviewer"

const useStyle = createUseStyles((theme) => ({
    signBorder: {
        width: 4,
    },
    signatureItem: {
        // width: "274px",
        height: "100%",
        display: "flex",
        borderRadius: 3,
        cursor: "all-scroll",
    },
    signatureItemContent: {
        display: "flex",
        alignItems: "center",
    },
    signTypeIcon: {
        aspectRatio: "1/1",
        background: "#fff",
        padding: 6,
        border: 6,
        borderRadius: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 1rem",
    },
}))

/**
 * ui for the signer field
 * @returns
 */
export const SignerFiledUI = ({
    name,
    mode,
    signed,
    signer,
    style = {},
    status,
    typeOfSignature,
    height,
}) => {
    const classes = useStyle()
    const { splitText } = useBasicUtils()
    const userName = name?.split(" ")
    name = userName?.length > 1 ? `${userName[0][0]} ${userName[1]}` : name

    return (
        <div
            className={`${classes.signatureItem}`}
            style={{
                border: `1px solid ${
                    mode === SIGNER
                        ? signed
                            ? "#00bda5"
                            : "#d6dfe8"
                        : signer?.backgroundDecorum.border
                }`,
                background:
                    mode === SIGNER
                        ? signed
                            ? "#99e4db"
                            : "#EAF0F6"
                        : signer?.backgroundDecorum.back,
                ...style,
            }}
        >
            <div
                className={classes.signBorder}
                style={{
                    backgroundColor:
                        mode === SIGNER
                            ? signed
                                ? "#00bda5"
                                : "#d6dfe8"
                            : signer?.backgroundDecorum.border,
                }}
            ></div>
            <div className={classes.signatureItemContent}>
                <div
                    className={classes.signTypeIcon}
                    style={{ height: 0.8 * height, maxHeight: 45 }}
                >
                    {mode !== SIGNER ||
                    status !== OUT_FOR_SIGNATURE ||
                    signed ||
                    typeOfSignature !== CLICK_FOR_SIGN ? (
                        <SignIcon fill="#425B76" />
                    ) : (
                        <ClickIcon />
                    )}
                </div>
                <div>
                    <Text
                        marginBottom={height < 40 ? 2 : 4}
                        varient="h6"
                        fontSize={height < 40 ? 13.5 : 15}
                        color="#425B76"
                    >
                        {mode !== SIGNER || typeOfSignature !== CLICK_FOR_SIGN
                            ? "Signature"
                            : status === OUT_FOR_SIGNATURE && !signed
                            ? "Click to sign"
                            : "Signed By"}
                    </Text>
                    <Text fontSize={height < 40 ? 12 : 14} color="#425B76">
                        {splitText(name, 20)}
                    </Text>
                </div>
            </div>
        </div>
    )
}

const SignerField = ({
    mode,
    pageIndex,
    getPagePosition,
    id,
    type,
    signature,
    xDefault,
    yDefault,
    dragHandler,
    resizeHandler,
    data,
    disable,
    pageHeight,
    pageWidth,
    status,
    typeOfSignature,
    signers,
    height,
    width,
    ...other
}) => {
    const ref = useRef(null)
    const [toolModel, setToolModel] = useState(false)
    const [isDragging, setIsDragging] = useState(false)

    function openModelHandler() {
        if (!disable) setToolModel(true)
    }

    function mouseLeaveHandler(e) {
        const comp = ref.current
        if (comp && comp.contains(e.target)) return
        else setToolModel(false)
    }

    async function onRemoveHandler(e) {
        e.stopPropagation()
        await deleteFields(data.id)
        removeSignatureElement(pageIndex, id, data?.signerId, data?.id, type)
        other.onMouseLeave()
    }

    async function onDragStop(e, d) {
        e?.stopPropagation()
        const element = ref.current.getBoundingClientRect()
        const canvas = getPagePosition()
        const pos = {
            x: Math.floor(element.left - canvas.left),
            y: Math.floor(canvas.bottom - element.top - element.height),
        }
        dragSignatureElement(
            pageIndex,
            id,
            pos.x,
            pos.y,
            element.top - canvas.top,
            element.left - canvas.left
        )
        const response = await addFields(
            data?.signerId,
            pageIndex,
            pos.x,
            pos.y,
            element.top - canvas.top,
            d?.x,
            data?.id,
            Math.floor(element.height),
            Math.floor(element.width),
            pageHeight,
            pageWidth,
            null,
            SignatureDragType.SIGNER_FIELD
        )
        addDataToElement(pageIndex, id, { ...data, ...response })
        setIsDragging(false)
    }

    /**
     * click handler
     * if document is in sign mode we will open sign tool for user signature.
     */
    function clickHandler() {
        if (mode === SIGNER) {
            if (status !== OUT_FOR_SIGNATURE) return
            if (typeOfSignature === CLICK_FOR_SIGN)
                return clickToSignEach(pageIndex, data.id)
            captureUserClick({
                tool: SignatureDragType.SIGNER_FIELD,
                pageIndex,
                elem: { ...data },
            })
        }
    }

    async function onResizeHelper(e) {
        e?.stopPropagation()
        const element = ref.current.getBoundingClientRect()
        const canvas = getPagePosition()
        const pos = {
            x: Math.floor(element.left - canvas.left),
            y: Math.floor(canvas.bottom - element.top - element.height),
        }
        resizeSignatureElement(
            pageIndex,
            id,
            Math.floor(element.width),
            Math.floor(element.height),
            pos.x,
            pos.y,
            element.left - canvas.left,
            element.top - canvas.top
        )
        const response = await addFields(
            data?.signerId,
            pageIndex,
            pos.x,
            pos.y,
            element.top - canvas.top,
            pos.x,
            data?.id,
            Math.floor(element.height),
            Math.floor(element.width),
            pageHeight,
            pageWidth,
            null,
            SignatureDragType.SIGNER_FIELD
        )
        addDataToElement(pageIndex, id, { ...data, ...response })
    }

    let timer
    function debounce(func, timeout = 300) {
        return () => {
            clearTimeout(timer)
            timer = setTimeout(func, timeout)
        }
    }

    const onResize = (e) => {
        const init = debounce(() => onResizeHelper(e))
        init()
    }

    return (
        <Rnd
            style={{ zIndex: 12 }}
            bounds={"parent"}
            default={{
                x: other.left,
                y: other.top,
                width: width || "228px",
                height: height || "64px",
            }}
            maxWidth="450px"
            minWidth="150px"
            lockAspectRatio
            resizeHandleStyles={toolModel ? dragStyle : dragStyleTransparent}
            resizeHandleClasses={toolModel ? dragClasses : {}}
            onDragStop={onDragStop}
            onMouseDown={openModelHandler}
            disableDragging={disable}
            onResize={onResize}
            enableResizing={!disable}
            onDragStart={() => setIsDragging(true)}
        >
            {toolModel && <Wrapper onRemoveHandler={onRemoveHandler} />}
            <div
                onMouseLeave={() =>
                    document.addEventListener("click", mouseLeaveHandler)
                }
                ref={(e) => {
                    if (ref) ref.current = e
                }}
                onClick={clickHandler}
                style={{ height: "100%" }}
            >
                <DragPreviewer isDragging={isDragging} signerField />
                <SignerFiledUI
                    ref={ref}
                    name={data?.name}
                    signed={other.isSigned}
                    signer={signers?.find((signer) => signer.id === data?.signerId)}
                    mode={mode}
                    style={{ cursor: disable ? "pointer" : "all-scroll" }}
                    status={status}
                    typeOfSignature={typeOfSignature}
                    height={height}
                />
            </div>
        </Rnd>
    )
}

export default SignerField
