import React from "react"
import { useStyles } from "./TemplateFormStyle"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import Input from "Awesome-ui/Input/Input"
import { SignatureDragType } from "Components/PDFCommons/container/Types"
import { setTemplateItemValue } from "Action/signAction"

const TemplateFormInput = ({
    type,
    label = "",
    detailsOfPositions = [],
    value,
    scrollToPage,
}) => {
    const classes = useStyles()
    const elementInPages = new Set()
    detailsOfPositions.forEach((detail) => {
        elementInPages.add(detail.pageIndex)
    })
    return (
        <div className={classes.templateFormInput}>
            <Text varient="h6">{label}</Text>
            <Input
                value={value}
                changeHandler={(val) =>
                    setTemplateItemValue(val, detailsOfPositions)
                }
                placeHolder={`Enter ${label}...`}
                type={type}
                style={{ width: "100%", maxWidth: "100%", marginTop: "1rem" }}
                borderRadius={4}
            />
            <div className={classes.pageNumberList}>
                {Array.from(elementInPages).map((pageIndex, index) => (
                    <div
                        key={index}
                        className={classes.eachPageNumber}
                        onClick={() => {
                            scrollToPage(parseInt(pageIndex))
                        }}
                    >
                        <Text fontSize={13}>Pg. {parseInt(pageIndex) + 1}</Text>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default TemplateFormInput
