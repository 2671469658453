import React from "react"
import "./CircleCheckBox.css"
import { ReactComponent as Check } from "./check.svg"
import { ReactComponent as Disabled } from "./disable.svg"

/**
 * circle check box with the tick mark and it also provide the disable feature.
 * select : check box is selected or not.
 * selectChange : selectChange handle when check box is clicked
 * disabled : disabled boolean for the this.
 * onClick: custom handler for custom use
 * @param {*} params - {select , selectChange, disabled, onClick, props}
 */
const CircleCheckBox = ({
    select,
    selectChange,
    disabled,
    onClick,
    style,
    ...props
}) => {
    return (
        <div
            className={`aui-checkbox-select 
            ${select && "aui-checkbox-selected"} 
            ${disabled && "aui-checkbox-disabled"}`}
            onClick={() =>
                selectChange ? selectChange((prev) => !prev) : onClick(!select)
            }
            style={{ ...style }}
        >
            {select ? <Check /> : disabled && <Disabled />}
        </div>
    )
}

export default CircleCheckBox
