import React, { useEffect, useState } from "react"
import { Button, Grid, Text } from "@awesomesuite-frontend/awesome-nebula"
import { ReactComponent as AwesomeSign } from "Assets/ProductIcon/signicon-full.svg"
import { createUseStyles } from "react-jss"

const useStyle = createUseStyles((theme) => ({
    iconWrappper: {
        display: "flex",
        justifyContent: "center",
        margin: "1rem auto",
        maxWidth: "300px",
        fontSize: "2rem",
        fontWeight: 600,
        color: "#fff",
    },
    congratHeader: {
        "@media screen and (max-width: 1550px)": {
            fontSize: "2rem",
        },
    },
    congratBody: {
        "@media screen and (max-width: 1400px)": {
            fontSize: "1.1rem",
        },
    },
}))

const SignSuccess = () => {
    const classes = useStyle()
    const [url, seturl] = useState("")
    const [name, setname] = useState("")

    useEffect(() => {
        function getDocumentUrl() {
            const search = new URLSearchParams(window.location.search)
            if (search.get("url")) seturl(search.get("url"))
            if (search.get("name")) setname(search.get("name"))
        }
        getDocumentUrl()
    }, [window.location])

    const handleDownload = async () => {
        fetch(url, { method: "get" })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement("a")
                aElement.setAttribute("download", name)
                const href = URL.createObjectURL(res)
                aElement.href = href
                aElement.setAttribute("target", "_blank")
                aElement.click()
                URL.revokeObjectURL(href)
            })
    }

    return (
        <Grid
            container
            background="#24374A"
            justify="center"
            align="center"
            style={{ minHeight: "100vh", flexDirection: "column" }}
        >
            <Grid
                item
                justify={"center"}
                xs={11}
                sm={8}
                md={5}
                xl={4}
                background="#fff"
                padding="30px 16px"
                style={{ height: "74vh" }}
            >
                <Text varient="h1" align="center" margin="1rem">
                    🎉
                </Text>
                <Text
                    varient="h2"
                    align="center"
                    margin="1rem"
                    className={classes.congratHeader}
                >
                    Congratulations! You've signed the document.
                </Text>
                <Text
                    varient="body"
                    fontSize="1.2rem"
                    color="light"
                    align="center"
                    margin="1rem"
                    className={classes.congratBody}
                >
                    This document's signed PDF version is now available for download.
                    The link will expire after 30 days.
                </Text>
                <Grid margin="2rem auto" style={{ width: "fit-content" }}>
                    <Button color="sky" size="large" onClick={handleDownload}>
                        Download document
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={11}
                    background="#E1F4FE"
                    radius="10px"
                    padding="12px"
                    margin="1rem auto"
                >
                    <Text varient="body" align="center">
                        Have a document that requires signature ?
                    </Text>
                    <Text varient="body" align="center">
                        Start a free 14-day trial.
                    </Text>
                </Grid>
            </Grid>
            <Grid item xs={4} marginTop="2rem">
                <div className={classes.iconWrappper}>
                    <AwesomeSign height={40} />
                </div>
            </Grid>
        </Grid>
    )
}

export default SignSuccess
