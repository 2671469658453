import React, { useCallback, useEffect } from "react"

let pdfjs = null
let pdfWorker = null
let pdfViewer = null
let documetRenderTask = null
let pdfHelpers = null
let renderingTaskMap = {}

/**
 * PDFJS Library hooks implementation handle the following feature
 * 1. pdf rendering
 * 2. thumbnail generation
 * 3. pdf preview generation.
 * 4. printing etc.
 *
 * TODO: fix the rendering method to supports the dynamic rendering
 * >> which means we will render few pages at a time and clear the memory so that
 * >> we will have a very dynamic and fast rendering process and it will save the
 * >> memory and browser crash problem and also we will support zoom out and zoom in also.,
 * @param {*} pdfRefs
 */
const usePdfHook = () => {
    const [loadedLib, setLoadedLib] = React.useState(false)
    const [loadedPdf, setLoadedPdf] = React.useState(false)
    const [numberOfPages, setNumberOfPages] = React.useState(0)
    const [pdfData, setPdfData] = React.useState(null)
    const [metaData, setMetaData] = React.useState(null)

    useEffect(() => {
        /**
         * dynamically import the module from the package and initialize
         * it to a global variable. and also set the pdf worker.
         *
         * Note: pdf worker is having problem please fix that
         */
        if (!loadedLib) loadPdfLib()
        return () => {
            renderingTaskMap = {}
        }
    })

    async function loadPdfLib() {
        if (!pdfjs) {
            pdfWorker = await import("pdfjs-dist/legacy/build/pdf.worker.entry")
            pdfjs = await import("pdfjs-dist/legacy/build/pdf")
            pdfViewer = await import("pdfjs-dist/legacy/web/pdf_viewer")
            pdfHelpers = await import("pdfjs-dist/lib/display/annotation_storage")
            window.pdfjs = pdfjs
            window.pdfWorker = pdfWorker
            window.pdfViewer = pdfViewer
            window.pdfHelpers = pdfHelpers
            pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker
            setLoadedLib(true)
            return { pdfjs, pdfWorker }
        } else {
            if (!loadedLib) setLoadedLib(true)
        }
    }

    /**
     * load pdf handler to load the pdf and generate pdf canvas with the preview and show it to UI
     *
     * @param {*} fileUrl - url where file is present it can be s3 URL
     */
    const loadPdf = useCallback(async (fileUrl) => {
        if (pdfjs && fileUrl) {
            const decodeUrl = decodeURIComponent(fileUrl)
            if (!decodeUrl) return

            if (documetRenderTask != null) {
                documetRenderTask = null
                Object.keys(renderingTaskMap).map((item) => {
                    if (renderingTaskMap[item]) {
                        renderingTaskMap[item].cancel()
                    }
                })
            }

            documetRenderTask = pdfjs.getDocument({
                url: decodeUrl,
                standardFontDataUrl:
                    "https://unpkg.com/pdfjs-dist@2.13.216/standard_fonts/",
                useWorkerFetch: true,
            })

            const documentInFormation = new Promise((resolve, reject) =>
                documetRenderTask.promise.then(
                    async (pdf) => {
                        // get meta data like name, last modified, author
                        let metadata = await pdf.getMetadata()
                        setMetaData({
                            ...metadata.info,
                            pageCount: pdf.numPages,
                        })

                        const numberOfPages = pdf.numPages

                        setNumberOfPages(numberOfPages)
                        setPdfData(pdf)
                        setLoadedPdf(true)
                        resolve({ ...metadata.info, numberOfPages })
                    },
                    (error) => {
                        reject()
                        console.log(error)
                    }
                )
            )

            return documentInFormation
        }
    }, [])

    /**
     * converts File to the the Byte array.
     * pdfjs getdocument utilize this to create preview.
     *
     * @param {File} file
     * @returns {@link Uint8Array} of the file
     */
    async function getBobFromFile(file) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader()
                reader.readAsArrayBuffer(file)
                reader.onload = function () {
                    const typedArr = new Uint8Array(this.result)
                    resolve(typedArr)
                }
            } catch (error) {
                reject("Failed to Get the uploaded file data")
            }
        })
    }

    /**
     * render one page and creates a canvas with small width and height
     * and returns the object url to download.
     *
     * @param {Object} pdf  - pdf data
     * @returns data url
     */
    async function getPageThumbnail(pdf, pageNumber) {
        return new Promise((resolve, reject) => {
            pdf.getPage(pageNumber).then((page) => {
                const viewPort = page.getViewport({ scale: 0.9 })

                const canvasDiv = document.createElement("canvas")
                const ctx = canvasDiv.getContext("2d")

                canvasDiv.height = viewPort.height
                canvasDiv.width = viewPort.width
                canvasDiv.style.width = "200px"
                canvasDiv.style.height = "400px"
                ctx.clearRect(0, 0, canvasDiv.width, canvasDiv.height)

                const renderContext = {
                    canvasContext: ctx,
                    viewport: viewPort,
                }

                const renderTask = page.render(renderContext)

                renderTask.promise.then(() => {
                    console.log("page rendering")

                    const smallPdf = canvasDiv.toDataURL()
                    resolve(smallPdf)
                })
                console.log("entered to the get pdf handler.")
            })
        })
    }

    /**
     * generate Thumbnail from the uploaded pdf file for showing as a icon or preview.
     * or it is a show called preview generator.
     *
     * @param {File} file - uploaded file object
     */
    async function getPdfThumbNail(file) {
        if (!loadedLib) await loadPdfLib()

        const blob = await getBobFromFile(file)

        const loadedDocument = pdfjs.getDocument(blob)

        const imageBlob = await new Promise((resolve, reject) => {
            loadedDocument.promise.then((pdf) => {
                resolve(getPageThumbnail(pdf, 1))
            })
        })

        const downloader = document.createElement("a")
        downloader.href = imageBlob
        downloader.download = "thumb.jpg"

        downloader.click()
    }

    /**
     * generate Thumbnail from the uploaded pdf file for showing as a icon or preview.
     * or it is a show called preview generator.
     *
     * @param {File} file - uploaded file object
     */
    async function getAllPdfPagesForCurrentPdf(file, handler = () => {}) {
        if (!loadedLib) await loadPdfLib()

        if (!pdfData) return

        const numberOfPages = pdfData.numPages

        let nums = Array(numberOfPages)
            .fill()
            .map((e, i) => i + 1)
        let pagePreview = []

        for (let index of nums) {
            const imageBlob = await getPageThumbnail(pdfData, index)
            pagePreview.push({
                pageNumber: index,
                view: imageBlob,
            })
            if (index % 10 === 0) {
                handler([...pagePreview])
            }
        }
        handler([...pagePreview])

        return pagePreview
    }

    return {
        pdfData,
        loadedPdf,
        loadPdfLib,
        loadPdf,
        numberOfPages,
        loadedLib,
        getPdfThumbNail,
        metaData,
        getAllPdfPagesForCurrentPdf,
    }
}

export default usePdfHook
