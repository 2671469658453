import React from "react"

import { ReactComponent as ErrorIcon } from "Assets/404.svg"
import "./Error.scss"
import { signOut } from "Action/userAction"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

const Error = ({
    errorTitle = "Oops! looks like you're lost...",
    errorDesc = "You don't have access to this document or it doesn't exist anymore",
}) => {
    return (
        <div className="error-page">
            <div className="error-main">
                <Text varient="h2" align="center" margin="2.2rem 0 2rem 0">
                    {errorTitle}
                </Text>
                <Text align="center" marginTop="1.8rem" color="#7c98b6">
                    {errorDesc}
                </Text>
                <ErrorIcon className="error-icon" />
                <div className="error-btn-wrapper">
                    <a href="/" className="error-home">
                        Go to Home
                    </a>
                    <div onClick={() => signOut(true, true)} className="error-log">
                        or log out
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error
