import { createUseStyles } from "react-jss"

export const useAnimationStyles = createUseStyles(() => ({
    "@keyframes anime_up": {
        "0%": {
            transform: "translateY(5%)",
            opacity: 0,
        },
        "100%": {
            transform: "translateY(0%)",
            opacity: 1,
        },
    },

    "@keyframes anime_down": {
        "0%": {
            transform: "transformY(0%)",
            opacity: 1,
        },
        "100%": {
            transform: "translateY(5%)",
            opacity: 0,
        },
    },

    animateUp: {
        animation: "$anime_up 500ms cubic-bezier(0.64, 1.32, 0.9, 1.04) 1",
    },

    animateDown: {
        animation: "$anime_down 500ms cubic-bezier(0.64, 1.32, 0.9, 1.04) 1",
    },
}))
