import React, { Fragment } from "react"
import TemplatesRightTop from "../TemplatesRightTop/"
import SearchTemplate from "./components/SearchTemplate"
import StaticTemplates from "./components/StaticTemplates"

const TemplatesLibrary = () => {
    const [searchValue, setSearchValue] = React.useState("")
    return (
        <Fragment>
            <TemplatesRightTop
                header="Templates Library"
                desc="All templates created by you or your team members"
            />
            <SearchTemplate
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
            <StaticTemplates searchQuery={searchValue} />
        </Fragment>
    )
}

export default TemplatesLibrary
