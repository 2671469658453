import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import "./PdfTopInactive.scss"

import { ReactComponent as PdfIcon } from "Assets/Storage/filePdf.svg"
import { ReactComponent as BackArrow } from "Assets/ToolsIcons/ArrowLeft.svg"
import { ReactComponent as Locked } from "Assets/PdfEditor/locked.svg"
import { ReactComponent as Download } from "Assets/PdfEditor/download.svg"
import { ReactComponent as Tag } from "Assets/PdfEditor/tag.svg"
import { ReactComponent as SignedIcon } from "Assets/ToolsIcons/signature.svg"

import { CANCELLED, FINISHED, OUT_FOR_SIGNATURE } from "Types"
import { clearESignData } from "Action/signAction"
import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import LabelChip from "Awesome-ui/LabelChip"
import { getAllDocumentsWithStatus, rejectDocument } from "Action/documentAction"
import { showWarningPopup } from "Action/messageAndNotificationActions"

const colorsMapping = {
    A: { back: "#fedaa3", text: "#ffa51f" },
    B: { back: "#ffc0ce", text: "#fb5779" },
    C: { back: "#ffecc7", text: "#ffa800" },
    D: { back: "#b4ffdb", text: "#19db7e" },
    E: { back: "#a9fffa", text: "#00d4c8" },
    F: { back: "#ecd3ff", text: "#9f46e4" },
    G: { back: "#d4d1ff", text: "#6457f9" },
    H: { back: "#d2e8ff", text: "#5a7896" },
    I: { back: "#fedaa3", text: "#ffa51f" },
    J: { back: "#ffc0ce", text: "#fb5779" },
    K: { back: "#ffecc7", text: "#ffa800" },
    L: { back: "#b4ffdb", text: "#19db7e" },
    M: { back: "#b4ffdb", text: "#19db7e" },
    N: { back: "#d2e8ff", text: "#5a7896" },
    O: { back: "#d4d1ff", text: "#6457f9" },
    P: { back: "#ecd3ff", text: "#9f46e4" },
    Q: { back: "#fedaa3", text: "#ffa51f" },
    R: { back: "#ffc0ce", text: "#fb5779" },
    S: { back: "#ffecc7", text: "#ffa800" },
    T: { back: "#d4d1ff", text: "#6457f9" },
    U: { back: "#d4d1ff", text: "#6457f9" },
    V: { back: "#ecd3ff", text: "#9f46e4" },
    W: { back: "#ffc0ce", text: "#fb5779" },
    X: { back: "#d2e8ff", text: "#5a7896" },
    Y: { back: "#a9fffa", text: "#00d4c8" },
    Z: { back: "#a9fffa", text: "#00d4c8" },
}

const PdfTopInactive = ({
    status,
    fileName,
    documentURL,
    tag,
    id,
    getPdfDocument,
}) => {
    const history = useHistory()
    const [file, setFile] = useState(fileName)
    const [_, setExtension] = useState("")

    const getClassNameByStatus = () => {
        if (status === OUT_FOR_SIGNATURE) return "out-for-sign"
        if (status === FINISHED) return "finished"
        if (status === CANCELLED) return "rejected"

        // return "rejected"
        return ""
    }

    const getStatusText = () => {
        if (status === OUT_FOR_SIGNATURE)
            return "Document has been sent for eSignature"
        if (status === FINISHED) return "Document has been Signed"
        if (status === CANCELLED) return "Document has been Cancelled"

        // return "Document has been declined for eSignature"
        return ""
    }

    const cancelDoc = async () => {
        const res = await rejectDocument(id)
        if (res) {
            getPdfDocument()
            getAllDocumentsWithStatus(OUT_FOR_SIGNATURE)
        }
    }

    const cancelDochandler = async () => {
        showWarningPopup(
            "Reject Document",
            "If you rejected the document, it can not be signed or edited further. All signers and owner will find this document as rejeted one",
            "Reject",
            cancelDoc,
            "Cancel",
            () => {}
        )
    }

    const handleDownload = async (toDownloadFileUrl) => {
        fetch(toDownloadFileUrl, { method: "get" })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement("a")
                aElement.setAttribute("download", fileName)
                const href = URL.createObjectURL(res)
                aElement.href = href
                aElement.setAttribute("target", "_blank")
                aElement.click()
                URL.revokeObjectURL(href)
            })
    }

    useEffect(() => {
        if (!file && fileName) {
            const ext = fileName.lastIndexOf(".")
            if (ext > -1) {
                const tempName = fileName.slice(0, ext)
                const tempExt = fileName.slice(ext)
                setExtension(tempExt)
                setFile(tempName)
            } else {
                setFile(fileName)
            }
        }
    }, [fileName, status])

    return (
        <>
            <div className="pdf-editor-top-inactive">
                <div className="editor-top-left">
                    <div
                        className="editor-back-button"
                        onClick={() => {
                            clearESignData()
                            history.goBack()
                        }}
                    >
                        <BackArrow />
                    </div>
                    <div className="editor-file-details">
                        <div className="editor-file">
                            <PdfIcon />
                        </div>
                        <div className="file-details">
                            <div>
                                <Text varient="h6" marginBottom={3}>
                                    {file}
                                </Text>
                                <TagHandler tag={tag} />
                            </div>
                            <Locked />
                        </div>
                    </div>
                </div>
                <div className="editor-top-right">
                    {status === OUT_FOR_SIGNATURE && (
                        <Button
                            padding={6}
                            varient="outline"
                            color="#9AAFC6"
                            style={{ marginRight: "1rem", borderRadius: "5px" }}
                            onClick={cancelDochandler}
                        >
                            <div className="download-btn">
                                <Text color="#9AAFC6" fontSize={14}>
                                    Cancel Document
                                </Text>
                            </div>
                        </Button>
                    )}
                    <Button
                        padding={"8px 12px"}
                        style={{ marginRight: "1rem", borderRadius: "5px" }}
                        onClick={() => handleDownload(documentURL)}
                    >
                        <div className="download-btn">
                            <Download height={15} width={15} />
                            <Text color="white" fontSize={14}>
                                Download
                            </Text>
                        </div>
                    </Button>
                </div>
            </div>
            <div className={`status-ui-pdf ${getClassNameByStatus()}`}>
                {status && (
                    <>
                        <SignedIcon fill="#b98138" height={20} width={20} />
                        <Text varient="subText" color="#b98138" weight={500}>
                            {getStatusText()}
                        </Text>
                    </>
                )}
            </div>
        </>
    )
}

const TagHandler = ({ tag }) => {
    if (!tag) return <></>
    return (
        <div
            style={{
                background: colorsMapping[tag[0].toUpperCase()]?.back,
                borderRadius: 4,
                position: "relative",
                display: "flex",
                alignItems: "center",
                width: "fit-content",
                paddingRight: 5,
                height: 20,
            }}
        >
            <Tag
                fill={colorsMapping[tag[0].toUpperCase()]?.text}
                height={12}
                width={12}
                style={{
                    position: "absolute",
                    top: "50%",
                    left: -12,
                    transform: "translateY(-50%)",
                }}
            />
            <Text
                margin="0 0 0 20px"
                fontSize={12}
                color={colorsMapping[tag[0].toUpperCase()]?.text}
            >
                {tag}
            </Text>
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    status: signReducer.documentDetails?.status,
    documentURL: signReducer.documentDetails.documentUrl,
    fileName: signReducer.documentDetails.fileName,
    tag: signReducer.documentDetails.tag,
    id: signReducer.documentDetails.id,
})

export default connect(mapStateToProps)(PdfTopInactive)
