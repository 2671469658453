import { createUseStyles } from "react-jss"

export const useStyles = createUseStyles({
    eachRoleWrapper: {
        margin: "1rem 0.5rem",
        borderRadius: 9,
        border: "1px solid #FFDCB4",
        padding: "0.5rem 1rem",
    },
    eachRoleHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    eachRoleHeaderIcon: {
        height: 30,
        aspectRatio: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius: "50%",
        "&:hover": {
            backgroundColor: "#eaf0f6",
        },
    },
    eachRoleTitle: {
        marginTop: "1rem",
        display: "flex",
        border: "1px solid #CBD6E2",
        borderRadius: 6,
        backgroundColor: "#fff",
        alignItems: "center",
        padding: "0 0.5rem",

        "& input": {
            width: "100%",
            border: "none",
            outline: "none",
            fontSize: 16,
            borderRadius: 6,
            fontWeight: 600,
            color: "#33475b",
            padding: "1rem 0.5rem",
            fontFamily: "'Poppins', sans-serif",
            flex: 1,
            "&::placeholder": {
                color: "#C4C4C4",
            },
        },
    },
    eachRoleHeaderIconTick: {
        "& path": {
            stroke: "#33475b",
        },
    },
    eachRoleFields: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "1rem",
        alignItems: "stretch",
        justifyContent: "space-between",
    },
    addRoleField: {
        position: "relative",
    },
    addRoleFieldModal: {
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translateY(-100%)",
        width: "100%",
        backgroundColor: "#fff",
        padding: "1rem",
        boxShadow: "0px 3px 6px #d6dfe8",
        borderRadius: 6,
        zIndex: 1,
        border: "1px solid #CBD6E2",
        "& > div:first-child": {
            display: "flex",
            alignItems: "center",
            "& > div:first-child": {
                marginLeft: "auto",
                cursor: "pointer",
                height: 25,
                aspectRatio: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                "&:hover": {
                    backgroundColor: "#eaf0f6",
                    boxShadow: "inset 1px 2px #d6dfe8",
                },
            },
        },
    },
    addRoleFieldModalHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
    },
    addRoleFieldInputsWrapper: {},
    addRoleFieldButton: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        border: "1px solid #CBD6E2",
        borderRadius: 6,
        padding: "0.5rem 1rem",
        backgroundColor: "#fff",
        cursor: "pointer",
    },
    addRoleFieldInputsWrapperDropdown: {
        border: "1px solid #CBD6E2",
        borderRadius: 6,
        marginTop: "1rem",
    },
    addRoleFieldDropdown: {
        "& > div:first-child": {
            backgroundColor: "transparent",
        },
    },
})
