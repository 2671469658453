import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import printJS from "print-js"
import "./PdfTools.scss"

import { ReactComponent as ImageIcon } from "Assets/ToolsIcons/imageIcon.svg"
import { ReactComponent as PagesIcon } from "Assets/ToolsIcons/pagesIcon.svg"
import { ReactComponent as SignIcon } from "Assets/ToolsIcons/sign.svg"
import { ReactComponent as PrintIcon } from "Assets/ToolsIcons/printIcon.svg"
import { ReactComponent as TextIcon } from "Assets/ToolsIcons/textIcon.svg"
import { ReactComponent as CheckboxIcon } from "Assets/ToolsIcons/checkboxIcon.svg"
import { ReactComponent as DateIcon } from "Assets/ToolsIcons/dateIcon.svg"
import { ReactComponent as ArrowIcon } from "Assets/ToolsIcons/Arrow.svg"
import SignComponent from "Components/SignDrawer/SignComponent"

import { SignatureDragType } from "../container/Types"
import { setActiveTool, togglePdfPagesPreview } from "Action/signAction"
import { DRAFT } from "Types"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import MediaLibrary from "Awesome-ui/MediaLibrary/MediaLibrary"
import { createMessage } from "Action/messageAndNotificationActions"

const PdfTools = ({
    finishDocumentLink,
    eSignFlow,
    token,
    activeTool,
    isPdfPageView,
    documentId,
    docStatus,
    docURL,
    status,
    ...props
}) => {
    const [openSignatureModel, setOpenSignatureModel] = useState(false)
    const [uploadImageToolActive, setUploadImageToolActive] = useState(false)

    const tools = [
        {
            Icon: ArrowIcon,
            name: "Arrow",
            type: "Arrow",
            click: () => setActiveTool(null),
        },
        {
            Icon: TextIcon,
            name: "Text",
            type: SignatureDragType.TEXT,
            click: () =>
                setActiveTool({
                    type: SignatureDragType.TEXT,
                    top: 0,
                    left: 0,
                }),
        },
        {
            Icon: CheckboxIcon,
            name: "Check box",
            type: SignatureDragType.CHECKBOX,
            click: () =>
                setActiveTool({
                    type: SignatureDragType.CHECKBOX,
                    top: 0,
                    left: 0,
                }),
        },
        {
            Icon: DateIcon,
            name: "Date",
            type: SignatureDragType.DATE,
            click: () =>
                setActiveTool({
                    type: SignatureDragType.DATE,
                    top: 0,
                    left: 0,
                }),
        },
        {
            Icon: ImageIcon,
            name: "Image",
            type: SignatureDragType.IMAGE,
            // click: onImageClick,
            click: () => setUploadImageToolActive(true),
        },
    ]

    async function mediaLibraryHandler(file) {
        let updatedFile = file
        if (file.source === "Unsplash") {
            updatedFile = {
                ...file,
                mediaPath: file.preview,
                mediaId: file.otherDetails.id,
            }
        }
        setActiveTool({
            type: SignatureDragType.IMAGE,
            top: 0,
            left: 0,
            ...updatedFile,
        })
        setUploadImageToolActive(false)
    }

    function print() {
        printJS({
            type: "pdf",
            printable: docURL,
        })
    }

    if (status !== DRAFT && isPdfPageView) {
        return <></>
    }

    if (status !== DRAFT) {
        return (
            <div className="toolbar-ui-status">
                <div
                    className={`operation-handler preview-btn`}
                    onClick={togglePdfPagesPreview}
                >
                    <PagesIcon />
                </div>
                <div className={`operation-handler print-btn`} onClick={print}>
                    <PrintIcon />
                </div>
            </div>
        )
    }

    return (
        <>
            {uploadImageToolActive && (
                <div className="media-library-wrapper">
                    <div className="media-library-container">
                        <MediaLibrary
                            userToken={token}
                            height="97%"
                            // UnsplashPicker={false}
                            GIFPicker={false}
                            onCancel={() => setUploadImageToolActive(false)}
                            onContinue={mediaLibraryHandler}
                            singleFile
                        />
                    </div>
                </div>
            )}
            <div className="pdf-toolbar">
                <div className="pdf-toolbar-left">
                    <TooltipWrapper
                        wrapperClassName={`pdf-tool 
                            pdf-tool-block 
                            tools-cursor-pointer 
                            pdf-tool-hover 
                    ${isPdfPageView ? "tool-active" : ""}`}
                        text={"Pages Thumbnails"}
                        direction={"right"}
                        onClick={togglePdfPagesPreview}
                    >
                        <PagesIcon />
                    </TooltipWrapper>
                    <div
                        className="pdf-tool pdf-tool-block tools-cursor-pointer pdf-tool-hover"
                        onClick={print}
                    >
                        <PrintIcon />
                    </div>
                    {tools.map(({ Icon, name, click, type }, index) => {
                        const active = activeTool && activeTool.type === type
                        return (
                            <div
                                className={`pdf-tool pdf-tool-hover ${
                                    active ? "tool-active" : ""
                                }`}
                                onClick={() => {
                                    if (!eSignFlow)
                                        return createMessage(
                                            "Please select a signature flow first",
                                            "No signature flow selected",
                                            "error"
                                        )
                                    click()
                                }}
                                key={index}
                            >
                                <div className={`pdf-tools-icon`}>
                                    <Icon />
                                </div>
                                <Text
                                    fontSize={"0.9rem"}
                                    color={active ? "#1492e6" : "light"}
                                >
                                    {name}
                                </Text>
                            </div>
                        )
                    })}
                    <div style={{ position: "relative" }}>
                        <div
                            className={`pdf-tool tools-cursor-pointer pdf-tool-hover 
                        ${
                            activeTool && activeTool.type === SignatureDragType.SIGN
                                ? "tool-active"
                                : ""
                        }`}
                            onClick={() => {
                                if (!eSignFlow)
                                    return createMessage(
                                        "Please select a signature flow first",
                                        "No signature flow selected",
                                        "error"
                                    )
                                setOpenSignatureModel(true)
                            }}
                        >
                            <div className="pdf-tools-icon">
                                <SignIcon />
                            </div>
                            <Text fontSize={"0.9rem"} color="light">
                                Sign
                            </Text>
                        </div>
                    </div>
                </div>
                <SignComponent
                    open={openSignatureModel}
                    close={() => setOpenSignatureModel(false)}
                />
            </div>
        </>
    )
}

const mapStateToProps = ({ signReducer, userReducer }) => ({
    token: userReducer.token,
    eSignFlow: signReducer.flow,
    finishDocumentLink: signReducer.finishDocumentLink,
    activeTool: signReducer.activeTool,
    isPdfPageView: signReducer.isPdfPageView,
    documentId: signReducer.documentDetails.id,
    docURL: signReducer.documentDetails.documentUrl,
    docStatus: signReducer.documentDetails.status,
    status: signReducer.status,
})

export default connect(mapStateToProps)(PdfTools)
