import React from "react"
import { NavLink } from "react-router-dom"
import ToolTip from "../../ToolTip"
import "./IconStyle.scss"
/**
 * Icon for the side nav bar
 *
 * @param {String} src
 * @param {String} active
 * @param {function} clicked
 * @param {Number} ind
 * @param {String} name
 * @param {String} link
 */
const Icon = ({ SvgIcon, name, link, theme, isAppSwitch}) => {
    return (
        <NavLink
            to={link}
            exact
            activeClassName={`type-slim-${theme}-active`}
            className={`side-nav-type-slim slim-type-${theme} ${!isAppSwitch && "side-nav-type-slim-app-switch"}`}
        >
            <div className="icon-wrapper">
                <div className="slim-type-icon">{SvgIcon && <SvgIcon />}</div>
            </div>
            <span className="slim-type-text">{name.toUpperCase()}</span>
            <ToolTip
                direction="right"
                name={name.toUpperCase()}
                color={"#fff"}
            />
        </NavLink>
    )
}

export default Icon
