import React, { Component } from "react"
import { Helmet } from "react-helmet"
import Board from "./Board"
import { data } from "./data"

import "./setting.scss"

// Api's to C
class index extends Component {
    state = {
        view: "profile",
    }
    views = {
        PROFILE: "profile",
        NOTIFICATION: "notification",
        CUSTOMIZATION: "Customization",
        USERS: "users",
        PLAN: "plan",
    }

    handleViewChange = (newValue) => {
        this.setState({ view: newValue })
    }

    render() {
        return (
            <div className="setting">
                <Helmet><title>Settings | Awesome Sign</title></Helmet>
                {data &&
                    data.map((board, i) => (
                        <Board {...board} key={i} isCreating={false} />
                    ))}
            </div>
        )
    }
}

export default index
