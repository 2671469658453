import React from "react";
import "./PrioritySymbol.scss";

/**
 * Priority Symbol Used In Lot Of Components
 *
 * Rounded Symbol For Show Priority Type
 *
 *  @param {String} color
 *
 */

const PrioritySymbol = ({ color }) => {
  return (
    <div
      className="priority-symbol"
      style={{ backgroundColor: color && `${color + 48}` }}
    >
      <div style={{ backgroundColor: color && color }}></div>
    </div>
  );
};

export default PrioritySymbol;
