import React from "react"
import { useStyles } from "./WarningPopupStyle"
import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import { ReactComponent as CancelIcon } from "Assets/ToolsIcons/cancel.svg"
import { hideWarningPopup } from "Action/messageAndNotificationActions"

const WarningPopup = ({
    title,
    description,
    agreeButtonTitle,
    cancelButtonTitle,
    onAgree = async () => {},
    onCancel = async () => {},
    icon,
}) => {
    const classes = useStyles()
    const [loading, setLoading] = React.useState(false)
    const handler = async () => {
        setLoading(true)
        await onAgree()
        hideWarningPopup()
        setLoading(false)
    }
    const cancel = async () => {
        setLoading(true)
        await onCancel()
        hideWarningPopup()
        setLoading(false)
    }
    return (
        <div className={classes.warningWrapper}>
            <div className={classes.warningModal}>
                <div className={classes.warningModalUpper}>
                    <div
                        className={classes.warningModalUpperIconCancel}
                        onClick={cancel}
                    >
                        <CancelIcon />
                    </div>
                    <div className={classes.warningModalUpperIcon}>
                        {icon && icon}
                    </div>
                    <Text fontSize={32} color="#fff" align="center">
                        {title}
                    </Text>
                </div>
                <div className={classes.warningModalLower}>
                    <Text fontSize={14} margin="2rem 1rem" align="center">
                        {description}
                    </Text>
                    <div className={classes.warningModalButtons}>
                        <Button
                            color="#F2545B"
                            radius={3}
                            width={150}
                            onClick={handler}
                            disabled={loading}
                        >
                            <Text color="#fff">{agreeButtonTitle}</Text>
                        </Button>
                        <Button
                            varient="outline"
                            color="#CBD6E2"
                            radius={3}
                            width={150}
                            onClick={cancel}
                            disabled={loading}
                        >
                            <Text color="#f2545b" fontSize={14}>
                                {cancelButtonTitle}
                            </Text>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WarningPopup
