import React, { useState } from "react"
import GooglePicker from "@awesomesuite-frontend/awesome-nebula/dist/GooglePicker"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import { medialApi } from "Apis/AllApisMapping"
import Loader from "Awesome-ui/Loader/Loader"

const DrivePicker = ({ setFile, styleClass, ...props }) => {
    const mediaHandlerService = new mediaHandler(medialApi)
    const [token, settoken] = useState(null)
    const [loading, setLoading] = useState(false)

    async function handleFile(file) {
        return await mediaHandlerService.uploadMediaOther(
            file.name,
            file.mimeType,
            file.id,
            "drive",
            token
        )
    }

    async function fileUploader(data) {
        if (data.action !== "picked") return
        setLoading(true)
        let uploadedFiles = await Promise.all(data.docs.map(handleFile))
        setLoading(false)
        setFile(uploadedFiles)
    }

    return (
        <GooglePicker
            clientId={process.env.REACT_APP_DRIVE_CLIENT_ID}
            developerKey={process.env.REACT_APP_DRIVE_APP_KEY}
            scope={["https://www.googleapis.com/auth/drive.readonly"]}
            onChange={fileUploader}
            onAuthFailed={(data) => {}}
            onAuthenticate={(data) => {
                settoken(data)
                console.log("token : ", data)
            }}
            multiselect={true}
            navHidden={true}
            authImmediate={false}
            mimeTypes={["application/pdf"]}
        >
            <div className={styleClass}>
                <img
                    src="https://www.gstatic.com/images/icons/material/product/2x/drive_32dp.png"
                    alt="googleDrive"
                />
                <div style={{ color: "#33475B" }}>Google Drive</div>
                {loading && (
                    <div style={{ position: "relative", marginLeft: 4 }}>
                        <Loader style={{ width: "16px", height: "16px" }} />
                    </div>
                )}
            </div>
        </GooglePicker>
    )
}

export default DrivePicker
