import { DANGER } from "Types"
import {
    GET_USER_DETAILS_FAILED,
    GET_USER_DETAILS_LOADING,
    GET_USER_DETAILS_SUCCESS,
    USER_LOGIN_SUCCESS,
    USER_SIGN_OUT,
    GLOABLE_LOADING,
    STOP_GLOABLE_LOADING,
} from "../ActionTypes/userActionTypes"
import { accessApi, authApi } from "../Apis/AllApisMapping"
import { store } from "../store"
import { createMessage } from "./messageAndNotificationActions"
import { getSubscribedPlan, subscribeFreePlan } from "./subscriptionActions"
import {
    getWorkspaceAll,
    quickOnboard,
    quickOnboardValidation,
    setWorkspaceLoaded,
    setWorkspaceLoading,
} from "./workspaceAction"
import { getPermissions } from "./permissionAction"

const { dispatch, getState } = store

export const userLoginManual = async (userEmail, password) => {
    const loginUser = await getUserLoginToken(userEmail, password)

    if (!loginUser) {
        // TODO: Throw error here with any message;
        return
    }

    // TODO: check wether we need to get this access token or simple one is enough.
    const { accessToken, userRole } = await getAccessToken(loginUser.token)

    if (accessToken) {
        addTokenToLocalStorage(accessToken)

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: { token: accessToken, userRole: userRole },
        })

        getUserDetails()
    }
}

/**
 * oAuth login with the google token and get auth token of our app.
 * @param {*} gToken - google token
 * @param {*} reqAddress - getting request from
 */
export const oAuthLogin = async (gToken, reqAddress) => {
    if (reqAddress !== "login") return

    try {
        const googleLogin = await authApi.get(
            `/getToken?token=${gToken}&action=google`
        )

        if (googleLogin.data.code === 200) {
            const token = googleLogin.data.model.auth_token

            if (token) {
                addTokenToLocalStorage(token)
                dispatch({
                    type: USER_LOGIN_SUCCESS,
                    payload: { token },
                })
            }
            // when fail publish the message..
        }
    } catch (error) {
        // when fail publish the message..
    }
}

/**
 * bypass user with the token send by the accounts
 * @param {*} token
 */
export const userLoginWithAccountByPass = async (token, autoOnboard = false) => {
    const { accessToken, userRole } = await getAccessToken(token)

    if (!accessToken) return false
    addTokenToLocalStorage(accessToken)
    const user = await getUserDetails(accessToken)
    let res = false
    if (autoOnboard) {
        const onboardValidate = await quickOnboardValidation(accessToken)
        if (onboardValidate) {
            const organisation = await getOrganization(accessToken)
            await subscribeFreePlan(accessToken, organisation.id, user.id)
            res = await quickOnboard(
                accessToken,
                user.email_id,
                user.user_name,
                organisation.id
            )
        }
    }

    await dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: { token: accessToken, redirectToken: token, userRole: userRole },
    })
    await getPermissions()
    const [, subscribedPlan, workspaceData] = await Promise.all([
        getUserDetails(),
        getSubscribedPlan(),
        getWorkspaceAll(),
    ])
    const isSubscriptionExisting =
        Array.isArray(subscribedPlan) && subscribedPlan?.length > 0
    const isWorkspaceExisting =
        Array.isArray(workspaceData) && workspaceData?.length > 0

    if (!isSubscriptionExisting) return "getting-started"
    if (!isWorkspaceExisting) return "onboarding-required"

    return res ? "auto-onboarded" : true
}

export async function logoutUser() {
    try {
        const token = getState().userReducer?.token
        if (!token) return
        const resp = await authApi.post("/logout", null, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        if (resp.data.code !== 200) return null

        return resp.data.model
    } catch (err) {
        return null
    }
}

export const signOut = (redirect, callApi) => {
    if (callApi) logoutUser()
    dispatch({ type: USER_SIGN_OUT })
    removeTokenToLocalStorage()
    if (redirect) window.location = "/login?logout=true"
}

/**
 * get authenticated user details
 * @returns dispatch event for redux to store the details of user.
 */
export const getUserDetails = async (accessToken) => {
    dispatch({ type: GET_USER_DETAILS_LOADING })
    try {
        const { token } = getState().userReducer
        if (!accessToken) accessToken = token

        if (!accessToken) return

        const res = await authApi.get("/user", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        })
        if (res.data.model) {
            dispatch({
                type: GET_USER_DETAILS_SUCCESS,
                payload: { user: res.data.model },
            })
            return res.data.model
        } else {
            dispatch({ type: GET_USER_DETAILS_FAILED })
            removeTokenToLocalStorage()
            dispatch({ type: USER_SIGN_OUT })
        }
    } catch (err) {
        // localStorage.removeItem("auth-token");
        dispatch({ type: GET_USER_DETAILS_FAILED })
    }
}

/**
 * login api this api will login the user and give one auth token.
 * 
 * @param userEmail - user email
 * @param password - password
 * 
 * Api response :
 * @example {
      token: "asdoaosijdaosidjaosidjoigjsodifjdofij"
 * }
 * 
 */
const getUserLoginToken = async (userEmail, password) => {
    try {
        const result = await authApi.get(
            `/login?email=${userEmail}&password=${password}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )

        if (result.data.code === 200) {
            return {
                token: result.data.model.auth_token,
            }
        } else return null
    } catch (error) {
        console.log(error)
        return null
    }
}

/**
 * api to get access token that is a product specific token generated 
 * so that all user can get all his permissions while using app.
 * 
 * @param token - login token
 * 
 * Api response :
 * @example {
      auth_token: "asdoosijoiasdjoaisdjoai"
 * }
 * 
 */
const getAccessToken = async (token) => {
    try {
        const result = await accessApi.get("/accessToken", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
        let resp = {}
        if (result.data.code === 200) {
            resp = { accessToken: result.data.model.auth_token }
            if (Array.isArray(result.data.model.organisations))
                resp.userRole = result.data.model.organisations[0].role
        }
        return resp
    } catch (error) {
        console.log(error)
        createMessage("Something went wrong", "Please try again", DANGER)
        return {}
    }
}

function addTokenToLocalStorage(token) {
    localStorage.setItem("auth-token", token)
}

function removeTokenToLocalStorage(token) {
    localStorage.removeItem("auth-token")
}

export const getOrganization = async (token) => {
    try {
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
        if (token) {
            const res = await authApi.get("/organisation", headers)
            if (res.data.model) {
                return res.data.model
            } else createMessage(res.data.msg, null, DANGER)
        }
    } catch (err) {
        if (err.response) {
            createMessage(err.response.data, null, DANGER)
        }
    }
}

export const setGloableLoading = (enable) =>
    dispatch({ type: enable ? GLOABLE_LOADING : STOP_GLOABLE_LOADING })

export const initialAccessTokenLoad = async () => {
    try {
        const { token, gloableLoading } = getState().userReducer
        if (gloableLoading) return
        setGloableLoading(true)
        setWorkspaceLoading()
        const { accessToken, userRole } = await getAccessToken(
            token || localStorage.getItem("auth-token")
        )
        if (!accessToken) return window.location.replace("/login")
        addTokenToLocalStorage(accessToken)
        await dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: {
                token: accessToken,
                redirectToken: accessToken,
                userRole: userRole,
            },
        })
        await getPermissions()
        const [, subscribedPlan, workspaceData] = await Promise.all([
            getUserDetails(),
            getSubscribedPlan(),
            getWorkspaceAll(),
        ])
        setGloableLoading(false)
        return [subscribedPlan, workspaceData]
    } catch (error) {
        setGloableLoading(false)
        setWorkspaceLoaded()
    }
}
