import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as NoActivityIcon } from "Assets/Dashboard/no-activity.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

const useStyles = createUseStyles(() => ({
    noActivityWrapper: {
        border: "1px solid #D6DFE8",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 350,
    },
    noActivity: {
        width: "100%",
        marginTop: "3rem",
    },
    noActivityIcon: {
        display: "flex",
        justifyContent: "center",
    },
}))

const NoActivity = () => {
    const classes = useStyles()
    return (
        <div className={classes.noActivityWrapper}>
            <div className={classes.noActivityIcon}>
                <NoActivityIcon />
            </div>
            <div className={classes.noActivity}>
                <Text color="light" align="center">
                    No recent activity
                </Text>
            </div>
        </div>
    )
}

export default NoActivity
