import React from "react"
import { useHistory } from "react-router-dom"

const UseQuery = () => {
    const history = useHistory()
    const getQuery = (key) => {
        if(!history.location.search.includes(`${key}=`))    return null
        let searchParams = new URLSearchParams(history.location.search)
        return searchParams.get(key)
    }

    const addQuery = (key, value) => {
        let pathname = history.location.pathname
        let searchParams = new URLSearchParams(history.location.search)
        searchParams.set(key, value)
        history.push({
            pathname: pathname,
            search: searchParams.toString(),
        })
    }

    const removeQuery = (key) => {
        let pathname = history.location.pathname
        let searchParams = new URLSearchParams(history.location.search)
        searchParams.delete(key)
        history.push({
            pathname: pathname,
            search: searchParams.toString(),
        })
    }

    return { getQuery, addQuery, removeQuery }
}

export default UseQuery
