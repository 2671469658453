import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import "./PdfLeftSideBar.scss"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

const PdfLeftSideBar = ({
    pdfPages,
    isPdfPageView,
    activePage,
    setActivePage,
    getPdfPages,
    publicPreview,
    pageScrollingHandler,
}) => {
    useEffect(() => {
        if (isPdfPageView && !pdfPages) {
            getPdfPages()
        }
    })

    const handlerClick = (index) => {
        setActivePage((prev) => {
            if (prev !== index) return index
            return prev
        })
        pageScrollingHandler((prev) => !prev)
    }

    if (!isPdfPageView && publicPreview) return <></>
    if (!isPdfPageView) return <div className="pdf-pages-preview-spacer"></div>

    return (
        <div className="pdf-pages-preview">
            {pdfPages &&
                pdfPages.map((page, index) => (
                    <div
                        className={`pdf-page-view 
                        ${activePage === index && "pdf-page-active"}`}
                        onClick={() => handlerClick(index)}
                    >
                        <Text varient="subText">{page.pageNumber}</Text>
                        <div className="pdf-page-img">
                            <img
                                src={page.view}
                                width="100%"
                                height="100%"
                                alt={`page-${page.pageNumber}`}
                                loading="lazy"
                            />
                        </div>
                    </div>
                ))}
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    pdfPages: signReducer.pdfPages,
    isPdfPageView: signReducer.isPdfPageView,
})

export default connect(mapStateToProps)(PdfLeftSideBar)
