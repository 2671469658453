import React from "react"
import { useStyles } from "./EachRoleStyle"
import { getElementIconByType, variableOptions } from "data/RoleDragElements"
import { createMessage } from "Action/messageAndNotificationActions"
import { DANGER } from "Types"
import { Button, Dropdown, Text } from "@awesomesuite-frontend/awesome-nebula"
import { ReactComponent as AddNewVariableIcon } from "Assets/ToolsIcons/addNewVar.svg"
import { updateRoleOfTemplate } from "Action/signAction"
import Input from "Awesome-ui/Input/Input"

const AddNewVariable = ({
    setShowAdditionalFieldModal,
    templateElements,
    setTemplateElements,
    roleId
}) => {
    const classes = useStyles()
    const [newAdditionalField, setNewAdditionalField] = React.useState({
        type: null,
        subType: "",
    })

    const addNewVariable = async () => {
        if (!newAdditionalField.subType) {
            return createMessage("Variable name cannot be empty", null, DANGER)
        }
        if (!newAdditionalField.type) {
            return createMessage("Variable type cannot be empty", null, DANGER)
        }
        const newTemplateElements = [
            ...templateElements,
            {
                type: newAdditionalField.type,
                subType: newAdditionalField.subType,
                Icon: getElementIconByType(newAdditionalField.type),
            },
        ]
        setShowAdditionalFieldModal(false)
        setTemplateElements(newTemplateElements)
        const fieldsAsJSON = {}
        newTemplateElements.forEach(
            (el, i) => (fieldsAsJSON[i] = { type: el.type, subType: el.subType })
        )
        const res = await updateRoleOfTemplate(roleId, {
            additionalFields: JSON.stringify(fieldsAsJSON),
        })
        setNewAdditionalField({
            type: null,
            subType: "",
        })
        if (!res) return templateElements.pop()
    }

    return (
        <div className={classes.addRoleFieldModal}>
            <div>
                <div onClick={() => setShowAdditionalFieldModal(false)}>✕</div>
            </div>
            <div className={classes.addRoleFieldModalHeader}>
                <AddNewVariableIcon />
                <Text marginLeft="1rem" varient="subText">
                    New Custom Variable
                </Text>
            </div>
            <Text color="light" fontSize={13} marginBottom={"1rem"}>
                Create your own custom variable field for the document
            </Text>
            <div className={classes.addRoleFieldInputsWrapper}>
                <Input
                    placeHolder="Variable Name"
                    value={newAdditionalField.subType}
                    type="text"
                    changeHandler={(value) =>
                        setNewAdditionalField({
                            ...newAdditionalField,
                            subType: value,
                        })
                    }
                    fontSize={14.4}
                    placeHolderFontSize={13}
                    inputStyle={{ padding: "0.45rem 0.75rem" }}
                    borderRadius={6}
                />
                <div className={classes.addRoleFieldInputsWrapperDropdown}>
                    <Dropdown
                        dropdown={{ dropdownContent: variableOptions }}
                        value={newAdditionalField.type}
                        onChange={(value) =>
                            setNewAdditionalField({
                                ...newAdditionalField,
                                type: value,
                            })
                        }
                        placeHolder="Select type for variable"
                        className={classes.addRoleFieldDropdown}
                        position="top"
                    />
                </div>
            </div>
            <Button
                onClick={addNewVariable}
                style={{
                    marginTop: "1rem",
                    padding: "0.5rem 1rem",
                    borderRadius: 6,
                }}
            >
                <Text color="#fff" fontSize={13}>
                    Add Variable
                </Text>
            </Button>
        </div>
    )
}

export default AddNewVariable
