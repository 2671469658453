import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { createUseStyles } from "react-jss"
import ToggleButton from "../../../../Awesome-ui/ToggleButton/ToggleButton"
import { connect } from "react-redux"
import { DOCUMENT, DRAFT, FORM } from "Types"
import GridSelector from "Awesome-ui/GridSelector/GridSelector"
import { updateDocument } from "Action/documentAction"

const useStyles = createUseStyles({
    shareDocHeader: {
        padding: "0.75rem 1rem",
        borderBottom: "1px solid #CCDFF2",
    },
    shareDocContent: {
        margin: "1.25rem 0",
        padding: "0 1rem",
    },
    shareDocContentToggler: {
        display: "flex",
        margin: "0.5rem 0",
    },
})

const sendingViewOptions = [
    {
        name: "Document",
        value: DOCUMENT,
    },
    {
        name: "Form",
        value: FORM,
    },
]

const ShareDoc = ({ status, enableTemplateFlow, view, fileId }) => {
    const classes = useStyles()

    //TODO: Later on, these values will be handled by redux
    // const [isPubliclyFillable, setIsPubliclyFillable] = React.useState(false)
    const [isDownloadable, setIsDownloadable] = React.useState(true)
    const onOptionChange = async (option) => {
        updateDocument({ fileId, view: option })
    }

    return (
        <div className="e-sign-flow-component">
            <div className={classes.shareDocHeader}>
                <Text varient="h6">Share Document</Text>
            </div>
            {/* <div className={classes.shareDocContent}>
                <Text varient="body" weight={600} fontSize={15}>
                    Publicly Fillable?
                </Text>
                <div className={classes.shareDocContentToggler}>
                    <ToggleButton
                        isChecked={isPubliclyFillable}
                        setIsChecked={setIsPubliclyFillable}
                        checkedText="Yes"
                        uncheckedText="No"
                        isDisabled={status !== DRAFT}
                    />
                </div>
            </div> */}
            <div className={classes.shareDocContent}>
                <Text fontSize={15} weight={600}>
                    Can Download PDF?
                </Text>
                <div className={classes.shareDocContentToggler}>
                    <ToggleButton
                        isChecked={isDownloadable}
                        setIsChecked={setIsDownloadable}
                        checkedText="Yes"
                        uncheckedText="No"
                        isDisabled={status !== DRAFT}
                    />
                </div>
            </div>
            {enableTemplateFlow && (
                <div className={classes.shareDocContent}>
                    <Text fontSize={15} weight={600}>
                        Default View
                    </Text>
                    <div className={classes.shareDocContentToggler}>
                        <GridSelector
                            options={sendingViewOptions}
                            currentOption={view}
                            setCurrentOption={onOptionChange}
                            isDisabled={status !== DRAFT}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    status: signReducer.status,
    enableTemplateFlow: signReducer.documentDetails?.enableTemplateFlow,
    view: signReducer.documentDetails?.view,
    fileId: signReducer.documentDetails?.documentId,
})

export default connect(mapStateToProps)(ShareDoc)
