import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import TemplateFormInput from "./TemplateFormInput"
import AcceptAndSignTemplate from "./AcceptAndSignTemplate"
import { useStyles } from "./TemplateFormStyle"
import { connect } from "react-redux"
import { SignatureDragType } from "Components/PDFCommons/container/Types"
import SignComponent from "Components/SignDrawer/SignComponent"
import { captureUserAction } from "Action/signAction"
import TemplateFormCheckBox from "./TemplateCheckBox"
import TemplateFormDate from "./TemplateFormDate"

const TemplateForm = ({ signature, isAccepted, scrollToPage }) => {
    const classes = useStyles()
    const [templateElements, setTemplateElements] = React.useState([])
    const [openSignatureModel, setOpenSignatureModel] = React.useState(false)

    function addSignatureResp(model) {
        captureUserAction({ mediaPath: model.mediaPath, selectAll: true })
    }

    const templateInputsHelper = () => {
        const templateInputs = []
        if (!signature) return
        Object.keys(signature).forEach((pageIndex) => {
            if (!signature[pageIndex]) return
            Object.keys(signature[pageIndex]).forEach((elementId) => {
                const element = signature[pageIndex][elementId]
                if (!element || !element.isTemplateItem) return
                let existingElement = null
                if (element.type === SignatureDragType.TEXT)
                    existingElement = templateInputs.find(
                        (el) => el.subType === element.data?.subType
                    )
                if (element.type === SignatureDragType.DATE)
                    existingElement = templateInputs.find(
                        (el) => el.type === element.type
                    )
                if (existingElement)
                    existingElement.details.push({
                        pageIndex,
                        elementId,
                    })
                else
                    templateInputs.push({
                        subType: element.data?.subType,
                        type: element.type,
                        value: element.data?.value,
                        details: [
                            {
                                pageIndex,
                                elementId,
                            },
                        ],
                    })
            })
        })
        setTemplateElements(templateInputs)
    }

    React.useEffect(() => {
        templateInputsHelper()
    }, [signature])

    let checkboxCount = 0

    return (
        <div className={classes.templateFormWrapper}>
            <Text varient="h5" marginBottom="2rem">
                Employment Contract
            </Text>
            {templateElements.map((templateElement, key) => {
                if (templateElement.type === SignatureDragType.TEXT)
                    return (
                        <TemplateFormInput
                            key={key}
                            label={templateElement.subType}
                            detailsOfPositions={templateElement.details}
                            {...templateElement}
                            scrollToPage={scrollToPage}
                        />
                    )
                if (templateElement.type === SignatureDragType.NUMBER)
                    return (
                        <TemplateFormInput
                            key={key}
                            label={templateElement.subType}
                            detailsOfPositions={templateElement.details}
                            {...templateElement}
                            scrollToPage={scrollToPage}
                        />
                    )
                if (templateElement.type === SignatureDragType.CHECKBOX) {
                    checkboxCount++
                    return (
                        <TemplateFormCheckBox
                            key={key}
                            detailsOfPositions={templateElement.details}
                            {...templateElement}
                            count={checkboxCount}
                            scrollToPage={scrollToPage}
                        />
                    )
                }
                if (templateElement.type === SignatureDragType.DATE)
                    return (
                        <TemplateFormDate
                            key={key}
                            detailsOfPositions={templateElement.details}
                            {...templateElement}
                            scrollToPage={scrollToPage}
                        />
                    )
            })}
            <AcceptAndSignTemplate
                isAccepted={isAccepted}
                onAccept={() => setOpenSignatureModel(true)}
            />
            <SignComponent
                open={openSignatureModel}
                close={() => setOpenSignatureModel(false)}
                addSignatureHandler={addSignatureResp}
                addTypeSignature={addSignatureResp}
            />
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => {
    return {
        signature: signReducer.signature,
        isAccepted: signReducer.isAccepted,
    }
}

export default connect(mapStateToProps)(TemplateForm)
