import { Dropdown, Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { useStyles } from "./PaginationBarStyle"
import { ReactComponent as Arrow } from "./assets/prev-button.svg"

const dropdown_content = {
    dropdownContent: [
        {
            value: 10,
            name: "10 per page",
        },
        {
            value: 20,
            name: "20 per page",
        },
        {
            value: 50,
            name: "50 per page",
        },
        // {
        //     value: 100,
        //     name: "100 per page",
        // },
    ],
}

/**
 *
 * @param {Number} currentPage current page you are handling
 * @param {Function} setCurrentPage onChange of current page
 * @param {Number} totalPages   total pages of pagination
 * @param {Number} pageRange *optional, max consequtive page indices you
 *                              want to show in the pagination bar
 * @returns
 */

const PaginationBar = ({
    currentPage = 1,
    setCurrentPage = () => {},
    totalPages = 9,
    pageRange = 3,
    style,
    size = 10,
    setSize = () => {},
    dropdownPosition="top",
    ...props
}) => {
    const classes = useStyles()
    return (
        <div className={`${classes.paginationBar}`} style={{ ...style }}>
            <div
                className={`${classes.firstPage} ${
                    currentPage === 1 && classes.firstPageInactive
                }`}
                onClick={() => {
                    if (currentPage !== 1) setCurrentPage(1)
                }}
            >
                <Arrow />
                <Arrow />
                <Text
                    color={currentPage === 1 ? "#A5B6C9" : "#00AEEF"}
                    margin={"0 0.25rem"}
                >
                    First
                </Text>
            </div>
            <div
                className={`${classes.firstPage} ${
                    currentPage === 1 && classes.firstPageInactive
                }`}
                onClick={() => {
                    if (currentPage !== 1) setCurrentPage(currentPage - 1)
                }}
            >
                <Arrow />
                <Text
                    color={currentPage === 1 ? "#A5B6C9" : "#00AEEF"}
                    margin={"0 0.25rem"}
                >
                    Prev
                </Text>
            </div>
            {Array(totalPages)
                .fill()
                .map((val, key) => {
                    const pageNo = key + 1
                    return (
                        <div
                            className={`${classes.pageSettler} ${
                                pageNo === currentPage && classes.pageSettlerActive
                            }`}
                            onClick={() => {
                                if (pageNo !== currentPage) setCurrentPage(pageNo)
                            }}
                        >
                            <Text color="#00AEEF">{pageNo}</Text>
                        </div>
                    )
                })}
            <div
                className={`${classes.lastPage} ${
                    currentPage === totalPages && classes.lastPageInactive
                }`}
                onClick={() => {
                    if (currentPage !== totalPages) setCurrentPage(currentPage + 1)
                }}
            >
                <Text
                    color={currentPage === totalPages ? "#A5B6C9" : "#00AEEF"}
                    margin={"0 0.25rem"}
                >
                    Next
                </Text>
                <Arrow />
            </div>
            <div
                className={`${classes.lastPage} ${
                    currentPage === totalPages && classes.lastPageInactive
                }`}
                onClick={() => {
                    if (currentPage !== totalPages) setCurrentPage(totalPages)
                }}
            >
                <Text
                    color={currentPage === totalPages ? "#A5B6C9" : "#00AEEF"}
                    margin={"0 0.25rem"}
                >
                    Last
                </Text>
                <Arrow />
                <Arrow />
            </div>
            <div className={`${classes.pageDropdown}`}>
                <Dropdown
                    dropdown={dropdown_content}
                    value={size}
                    onChange={setSize}
                    style={{ color: "#00AEEF" }}
                    position={dropdownPosition}
                />
            </div>
        </div>
    )
}

export default PaginationBar
