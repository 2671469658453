import { Avatar, Text } from "@awesomesuite-frontend/awesome-nebula"
import { OUT_FOR_SIGNATURE } from "Types"
import React from "react"

const Activity = ({ message }) => {
    const { actorName, event, fileName, time } = message
    return (
        <div className="recent-activity-card">
            <div className="activity-user">
                <Avatar name={actorName || ""} />
            </div>
            <div>
                <div className="recent-activity-top">
                    <Text fontSize={15} className="ra-user">
                        {actorName}
                    </Text>
                    <Text fontSize={14} className="ra-activity">
                        {`${event === OUT_FOR_SIGNATURE ? "sent for sign" : event}`}{" "}
                        a document
                    </Text>
                    <Text fontSize={14} className="ra-doc">
                        {fileName}
                    </Text>
                </div>
                <Text className="recent-activity-time" fontSize={14}>
                    {`${time}`} ago
                </Text>
            </div>
        </div>
    )
}

export default Activity
