import React, { useState } from "react"
import { connect } from "react-redux"

import "./PdfTop.scss"

import { ReactComponent as PdfIcon } from "Assets/Storage/filePdf.svg"
import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import { captureUserAction, clickToSignAll, finishAndSign } from "Action/signAction"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import { medialApi } from "Apis/AllApisMapping"
import { createCanvasWithText } from "Library/CanvasLib/CanvasLib"

import SignComponent from "Components/SignDrawer/SignComponent"
import { SignatureDragType } from "Components/PDFCommons/container/Types"
import { FINISHED, OUT_FOR_SIGNATURE, CANCELLED, CLICK_FOR_SIGN } from "Types"
import { ReactComponent as SignedIcon } from "Assets/ToolsIcons/signature.svg"
import { ReactComponent as SignIcon } from "Assets/ProductIcon/awesomesign-full.svg"

const PdfTop = ({
    isAccepted,
    fileName,
    activeTool,
    disableBtn = true,
    signed,
    total,
    status,
    currentSignerStatus,
    securityHash,
    typeOfSignature,
    signedSignatures,
    totalSignatures,
    setLoading,
    saveAllAnnotations,
}) => {
    const [disabled, setDisabled] = useState(false)

    const getClassNameByStatus = () => {
        if (status === OUT_FOR_SIGNATURE && currentSignerStatus === "SIGNED")
            return "out-for-sign"
        if (currentSignerStatus === "SIGNED") return "finished"
        if (status === OUT_FOR_SIGNATURE) return "out-for-sign"
        if (status === FINISHED) return "finished"
        if (status === CANCELLED) return "rejected"

        // return "rejected"
        return ""
    }

    const getStatusText = () => {
        if (status === OUT_FOR_SIGNATURE && currentSignerStatus === "SIGNED")
            return "Waiting for other signers to sign"
        if (currentSignerStatus === "SIGNED") return "Document has been Signed"
        if (status === OUT_FOR_SIGNATURE)
            return "Document has been sent for eSignature"
        if (status === FINISHED) return "Document has been Signed"
        if (status === CANCELLED) return "Document has been Cancelled"

        // return "Document has been declined for eSignature"
        return ""
    }

    async function finish() {
        setLoading(true)
        setDisabled(true)
        const mediaService = new mediaHandler(medialApi)
        if (securityHash) {
            const annotations = await saveAllAnnotations()
            await finishAndSign(
                createCanvasWithText,
                mediaService,
                securityHash,
                typeOfSignature,
                annotations
            )
        }
        setDisabled(false)
        setLoading(false)
    }

    function addSignatureResp(model) {
        captureUserAction({ mediaPath: model.mediaPath, selectAll: model.selectAll })
    }

    return (
        <>
            <div className="open-pdf-editor-top">
                <div className="open-editor-top-left">
                    <SignIcon height={30} />
                    <div className="open-editor-file-details">
                        <div className="open-editor-file">
                            <PdfIcon />
                        </div>
                        <div className="open-file-details">
                            <Text varient="subText" color="#000" weight={500}>
                                {fileName}
                            </Text>
                        </div>
                    </div>
                </div>
                <div className="open-editor-top-right">
                    {isAccepted &&
                        ["VIEWED", "SENT"].indexOf(currentSignerStatus) > -1 &&
                        status !== CANCELLED && (
                            <>
                                {signedSignatures !== totalSignatures &&
                                    typeOfSignature === CLICK_FOR_SIGN && (
                                        <Button
                                            color="sky"
                                            size="medium"
                                            radius={6}
                                            style={{ margin: "0 1rem" }}
                                            onClick={clickToSignAll}
                                        >
                                            <Text
                                                fontSize={13}
                                                color="#fff"
                                                weight={500}
                                            >
                                                Click To Sign All
                                            </Text>
                                        </Button>
                                    )}
                                <Button
                                    color="sky"
                                    size="medium"
                                    radius={6}
                                    disabled={disableBtn || disabled}
                                    onClick={finish}
                                >
                                    <Text
                                        fontSize={13}
                                        color={signed !== total ? "default" : "#fff"}
                                        weight={500}
                                    >
                                        {signed === total
                                            ? "Confirm"
                                            : `signed ${signed} out of ${total}`}
                                    </Text>
                                </Button>
                            </>
                        )}
                </div>
                <SignComponent
                    open={activeTool?.tool === SignatureDragType.SIGNER_FIELD}
                    close={() => captureUserAction({})}
                    addSignatureHandler={addSignatureResp}
                    addTypeSignature={addSignatureResp}
                />
            </div>
            {fileName &&
                (status !== OUT_FOR_SIGNATURE ||
                    (status === OUT_FOR_SIGNATURE &&
                        currentSignerStatus === "SIGNED")) && (
                    <div className={`status-ui-pdf ${getClassNameByStatus()}`}>
                        <SignedIcon fill="#b98138" height={20} width={20} />
                        <Text varient="subText" color="#b98138" weight={500}>
                            {getStatusText()}
                        </Text>
                    </div>
                )}
        </>
    )
}

const mapStateToProps = ({ signReducer }) => {
    const signatureFlat = Object.values(signReducer.signature).flat(1)
    const signed = signatureFlat.filter((item) => item.isSigned).length

    return {
        eSignFlow: signReducer.flow,
        status: signReducer.status,
        currentSignerStatus: signReducer?.currentSignerStatus,
        fileName: signReducer.documentDetails?.fileName,
        signerId: signReducer.documentDetails.currentSigner,
        activeTool: signReducer.activeTool,
        signed: signed,
        total: signatureFlat.length,
        disableBtn: signed !== signatureFlat.length,
        typeOfSignature: signReducer.typeOfSignature,
        isAccepted: signReducer.isAccepted,
        signedSignatures: signatureFlat.filter(
            (item) => item.type === SignatureDragType.SIGNER_FIELD && item.isSigned
        ).length,
        totalSignatures: signatureFlat.filter(
            (item) => item.type === SignatureDragType.SIGNER_FIELD
        ).length,
    }
}

export default connect(mapStateToProps)(PdfTop)
