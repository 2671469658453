import { getCanvasScale, getCanvasSize } from "Library/canvasAdjuster"
import React, { memo, useEffect } from "react"
import { useState } from "react"
import IntersectionVisible from "react-intersection-visible"

const { height: MAX_HEIGHT, width: MAX_WIDTH } = getCanvasSize()

function getRenderingSize(height, width) {
    let renderingHeight = height,
        renderingWidth = width
    const aspectRatio = height / width

    if (aspectRatio > 1) {
        //portrait
        if (renderingWidth > MAX_WIDTH) {
            renderingWidth = MAX_WIDTH
            renderingHeight = MAX_WIDTH * aspectRatio
        }
        if (renderingHeight > MAX_HEIGHT) {
            renderingHeight = MAX_HEIGHT
            renderingWidth = MAX_HEIGHT / aspectRatio
        }
    } else {
        //landscape
        if (renderingHeight > MAX_HEIGHT) {
            renderingHeight = MAX_HEIGHT
            renderingWidth = MAX_HEIGHT / aspectRatio
        }
        if (renderingWidth > MAX_WIDTH) {
            renderingWidth = MAX_WIDTH
            renderingHeight = MAX_WIDTH * aspectRatio
        }
    }

    return { width: renderingWidth, height: renderingHeight }
}

const PdfCanvas = memo(
    ({ pdfRefs, pdf, pageNumber, index, setPageSize }) => {
        const [renderTask, setrenderTask] = useState(null)

        /**
         * render page when it is in view port.
         */
        async function getPages(e) {
            if (!pdfRefs.current[index]) return
            const canvasDiv = pdfRefs.current[index]

            await new Promise((resolve, reject) => {
                pdf?.getPage(pageNumber)
                    .then(async (page) => {
                        if (renderTask) {
                            await renderTask.cancel()
                            console.log("page is already in process of rendering...")
                        }

                        console.log("create Pages..........", pageNumber)
                        const viewPort = page.getViewport({ scale: getCanvasScale() })

                        const ctx = canvasDiv.getContext("2d")

                        const { width, height } = getRenderingSize(
                            viewPort.height,
                            viewPort.width
                        )
                        setPageSize((prev) => ({ ...prev, height, width }))

                        canvasDiv.height = viewPort.height
                        canvasDiv.width = viewPort.width
                        canvasDiv.style.width = width + "px"
                        canvasDiv.style.height = height + "px"
                        ctx.clearRect(0, 0, canvasDiv.width, canvasDiv.height)

                        const renderContext = {
                            canvasContext: ctx,
                            viewport: viewPort,
                        }

                        let task = page.render(renderContext)
                        setrenderTask(task)

                        task.promise
                            .then(() => {
                                console.log("page rendering")
                                resolve()
                            })
                            .catch(async (error) => {
                                console.log(error)
                                if (renderTask) {
                                    await renderTask.cancel()
                                }
                                reject()
                            })
                        console.log("entered to the get pdf handler.")
                    })
                    .catch((error) => {
                        console.log(error)
                        reject()
                    })
            })
        }

        useEffect(() => {
            return () => {
                setrenderTask(null)
                const canvasDiv = pdfRefs.current[index]
                if (canvasDiv) {
                    const ctx = canvasDiv.getContext("2d")
                    ctx.clearRect(0, 0, canvasDiv.width, canvasDiv.height)
                }
            }
        }, [])

        useEffect(() => {
            const init = async () => {
                if (renderTask) {
                    await getPages()
                }
            }

            init()
        }, [pdf])

        /**
         * cancle all the render tasks and clear canvas
         * @param {*} e
         */
        async function cancleTask(e) {
            if (renderTask) {
                await renderTask.cancel()
            }
            setrenderTask(null)
            const canvasDiv = pdfRefs.current[index]
            if (canvasDiv) {
                const ctx = canvasDiv.getContext("2d")
                ctx.clearRect(0, 0, canvasDiv.width, canvasDiv.height)
            }
        }

        return (
            <IntersectionVisible onHide={cancleTask} onShow={getPages}>
                <canvas ref={(el) => (pdfRefs.current[index] = el)}></canvas>
            </IntersectionVisible>
        )
    }
)

export default PdfCanvas
