import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import "./Info.scss"

import { ReactComponent as CalenderIcon } from "Assets/PdfEditor/calender.svg"
import { ReactComponent as FileIcon } from "Assets/PdfEditor/file.svg"
import { ReactComponent as TagIcon } from "Assets/PdfEditor/tag.svg"
import { ReactComponent as UserIcon } from "Assets/PdfEditor/user.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

function Details({ title, Icon, subText }) {
    const { splitText } = useBasicUtils()
    return (
        <div className="pdf-detail-comp">
            <div
                className="detail-icon"
                style={{ backgroundColor: !Icon && "#425A70" }}
            >
                {Icon && <Icon />}
            </div>
            <div className="detail-right">
                <div className="detail-title">
                    <Text color="#425A70" fontSize={14}>
                        {title}
                    </Text>
                </div>
                <div className="details-subText">
                    <Text fontSize={12.6} color="#8D9BA8" marginTop="0.25rem">
                        {splitText(subText, 15)}
                    </Text>
                </div>
            </div>
        </div>
    )
}

const detailsArr = [
    {
        id: 1,
        title: "Document ID",
        Icon: null,
        subText: "DOC-0003",
    },
    {
        id: 2,
        title: "Document Status",
        Icon: null,
        subText: "Pending Signature",
    },
    {
        id: 3,
        title: "Owner",
        Icon: null,
        subText: "User Name",
    },
    {
        id: 4,
        title: "Creation Date",
        Icon: null,
        subText: "Oct 13, 2021",
    },
    {
        id: 5,
        title: "Last Updated",
        Icon: null,
        subText: "Oct 13, 2021",
    },
]

/**
 * side bar component to show the document details
 * @returns Component
 */
const Info = ({ details, creator }) => {
    const [document, setDocument] = useState(detailsArr)
    const { getDate, formatName } = useBasicUtils()
    // console.log(creator);
    useEffect(() => {
        if (details) {
            const detailsTemplate = {
                title: null,
                Icon: null,
                subText: null,
            }
            let newDocumentDetails = []
            if (details.fileName) {
                newDocumentDetails.push({
                    ...detailsTemplate,
                    title: "File Name",
                    subText: details.fileName,
                    Icon: FileIcon,
                })
            }
            if (details.tag) {
                newDocumentDetails.push({
                    ...detailsTemplate,
                    title: "Tag",
                    subText: formatName(details.tag),
                    Icon: TagIcon,
                })
            }
            if (details.version) {
                newDocumentDetails.push({
                    ...detailsTemplate,
                    title: "Owner",
                    subText: `${creator.first_name} ${creator.last_name}`,
                    Icon: UserIcon,
                })
            }
            if (details.updatedAt) {
                newDocumentDetails.push({
                    ...detailsTemplate,
                    title: "Last Updated",
                    subText: getDate(details.updatedAt),
                    Icon: CalenderIcon,
                })
            }
            if (details.createdAt) {
                newDocumentDetails.push({
                    ...detailsTemplate,
                    title: "Creation Date",
                    subText: getDate(details.createdAt),
                    Icon: CalenderIcon,
                })
            }

            setDocument(newDocumentDetails)
        }
    }, [])

    return (
        <div className="aui-pdf-sidebar-comp">
            <div className="aui-pdf-details">
                {document &&
                    document.map((item, index) => <Details key={index} {...item} />)}
            </div>
        </div>
    )
}

const mapStateToProps = ({ signReducer, userReducer }) => ({
    details: signReducer.documentDetails,
    creator: userReducer.userDetails,
})

export default connect(mapStateToProps)(Info)
