import React, { forwardRef, useImperativeHandle } from "react"
import ColorPicker from "../ColorPicker/ColorPicker"

import "./SignDrawer.scss"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

/**
 * Signature Drawing component it provides canvas to draw
 * the signature with the three different color options blue, gray and red.
 *
 * it is dependent on useImperativeHandle hook so that the parent component
 * can get the details inside this component
 *
 * return Drawing Component
 */
const SignDrawer = forwardRef((props, ref) => {
    const [colorKeeper, setColorKeeper] = React.useState({
        color: "#33475B",
        id: "dr-1",
    })
    const width = 600
    const height = 230
    const canvasRef = React.useRef(null)

    var drawerCanvas = document.createElement("canvas")
    drawerCanvas.width = width
    drawerCanvas.height = height
    var drawerCtx = drawerCanvas.getContext("2d")

    function cleanCanvas() {
        const canvas = canvasRef.current
        const canvasCtx = canvas.getContext("2d")

        canvasCtx.clearRect(0, 0, width, height)
        drawerCtx.clearRect(0, 0, width, height)
    }

    function setCanvasStrokeColor(colorDetails) {
        setColorKeeper(colorDetails)

        const canvas = canvasRef.current
        const canvasCtx = canvas.getContext("2d")

        canvasCtx.strokeStyle = colorDetails.color
    }

    function downloadFile() {
        const canvas = canvasRef.current
        const a = document.createElement("a")
        a.href = canvas.toDataURL()
        a.download = "mySign.png"

        a.click()
    }

    useImperativeHandle(ref, () => ({
        getResult() {
            const canvas = canvasRef.current
            return canvas.toDataURL()
        },
        isCanvasBlank() {
            if (!canvasRef.current) return false
            const canvas = canvasRef.current
            return !canvas
                .getContext("2d")
                .getImageData(0, 0, canvas.width, canvas.height)
                .data.some((channel) => channel !== 0)
        },
    }))

    React.useEffect(() => {
        let i
        /**
         *
         * canvas features used :
         *
         * {@link lineCap: https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/lineCap}
         */
        function initDrawing() {
            // non constant variable to track user mouse points
            // so that we can draw on those points.
            let mousePoints = []
            // to check that user is drawing or not
            let isDrawing = false

            // TODO: SEE IF WE GET THIS ALL THE TIME ADD NULL CHECK LATER
            const canvas = canvasRef.current
            if (!canvas) return
            canvas.width = width
            canvas.height = height

            // handel events here
            // TODO: add touch support also
            canvas.addEventListener("mousedown", handleMouseDownEvent)
            canvas.addEventListener("mouseup", handleMouseUpEvent)
            canvas.addEventListener("mousemove", handlerMouseMove)

            // canvas context
            const ctx = canvas.getContext("2d")

            // set the line to draw configuration
            // set width of the line to draw
            ctx.lineWidth = 4
            ctx.strokeStyle = colorKeeper.color
            // type of line [round, square]
            ctx.lineCap = "round"
            ctx.lineJoin = "round"

            /**
             * initialize the user signature or capture the user start signature.
             *
             * @param {*} event mouse down
             */
            function handleMouseDownEvent(event) {
                isDrawing = true
                const mousePos = getMouseAxis(canvas, event)
                mousePoints.push(mousePos)
            }

            /**
             * stop the drawing when user is done and save the drawing to a in memory canvas
             * because the actual result with one canvas appears very disturb
             *
             * using second canvas to save the drawing make things smooth due to the line property we gave above.
             * simply it improves smoothness.
             *
             * @param {*} event mouse up event
             */
            function handleMouseUpEvent(event) {
                if (isDrawing) {
                    isDrawing = false
                    drawerCtx.clearRect(0, 0, width, height)
                    drawerCtx.drawImage(canvas, 0, 0)
                    mousePoints = []
                }
            }

            /**
             * handel mouse move event add actually draw the lines to the canvas
             * drawing will only start if isDrawing is true.
             *
             * @param {*} event event listener type event
             */
            function handlerMouseMove(event) {
                if (isDrawing) {
                    ctx.clearRect(0, 0, width, height)
                    ctx.drawImage(drawerCanvas, 0, 0)
                    const mousePos = getMouseAxis(canvas, event)
                    if (!mousePos) return
                    mousePoints.push(mousePos)
                    startSigning(ctx, mousePoints)
                }
            }

            /**
             * This is the main function to draw the arc or curved line to the given points
             *
             * if points are less then 6 the it draw a arc (basically a half some percentage of circle)
             * read up on arc here {@linkhttps://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/arc}
             *
             * if points are greater then 6 then we draw curve instead of arc
             * read up on quadraticCurveTo {@linkhttps://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/quadraticCurveTo}
             *
             * at last we need to render the the curves as stroke
             */
            function startSigning(context, points) {
                if (points.length < 6) {
                    const basePoint = points[0]
                    if (basePoint) {
                        context.beginPath()
                        context.arc(
                            basePoint.x,
                            basePoint.y,
                            context.lineWidth / 2.8,
                            Math.PI * 2,
                            !0
                        )
                    }
                }

                if (!points[0]) return

                context.beginPath()
                context.moveTo(points[0].x, points[0].y)

                for (i = 1; i < points.length - 2; i++) {
                    let cpx = (points[i].x + points[i + 1].x) / 2
                    let cpy = (points[i].y + points[i + 1].y) / 2
                    context.quadraticCurveTo(points[i].x, points[i].y, cpx, cpy)
                }

                context.stroke()
            }
            /**
             * gives the x and y axis of mouse in the canvas
             *
             * @param {*} canvasDom
             * @param {*} event
             * @returns {x: xAxis, y: YAxis}
             */
            function getMouseAxis(canvasDom, event) {
                const rect = canvasDom.getBoundingClientRect()

                if (
                    rect.left + 4 > event.clientX ||
                    rect.right - 4 < event.clientX ||
                    rect.top + 4 > event.clientY ||
                    rect.bottom - 4 < event.clientY
                ) {
                    startSigning(ctx, mousePoints)
                    handleMouseUpEvent(event)
                    return
                }

                return {
                    x: event.clientX - rect.left,
                    y: event.clientY - rect.top,
                }
            }
        }

        initDrawing()
    }, [drawerCanvas, drawerCtx])

    return (
        <div className="signDrawer-wrapper">
            <div className="signDrawer-content-wrapper">
                <div className="signDrawer-title">Sign</div>
                <div className="sign-Drawer-tools">
                    <ColorPicker
                        selectedColor={colorKeeper}
                        colorChangeHandler={setCanvasStrokeColor}
                    />
                    <div className="sideDrawer-btnBase" onClick={cleanCanvas}>
                        <Text fontSize={12}>Clear</Text>
                    </div>
                </div>
            </div>
            <div className="sign-here-divider"></div>
            <canvas ref={canvasRef} className="main-canvas" />
        </div>
    )
})

export default SignDrawer
