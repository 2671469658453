import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import Guard from "Awesome-ui/Guard/Guard"
import Input from "Awesome-ui/Input/Input"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as Upload } from "Assets/Storage/upload.svg"
import Loader from "Awesome-ui/Loader/Loader"
import mediaHandler from "Library/mediaHandler/mediaHandler"
import { medialApi } from "Apis/AllApisMapping"
import usePdfHook from "hooks/PdfLib/PdfHook"
import { addDocument, addDocumentToS3 } from "Action/documentAction"
import { DANGER, TEMPLATE } from "Types"
import { connect } from "react-redux"
import { createMessage } from "Action/messageAndNotificationActions"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const useStyles = createUseStyles({
    templatesRightTop: {
        padding: "1rem 3rem",
        borderBottom: "1px solid #CCD6E2",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    templatesPageOption: {
        display: "flex",
        alignItems: "center",
        marginLeft: "auto",
    },
    uploadButton: {
        border: "1px solid #d6dfe8",
        borderRadius: "4px",
        background: "#33475b",
        padding: "8px 16px",
        margin: "0 0.5rem",
        position: "relative",
        "& svg path": {
            fill: "#fff",
        },
    },
})

const TemplatesRightTop = ({
    header,
    desc,
    searchQuery = "",
    setSearchQuery = () => {},
    searchInputVisible,
    userToken,
}) => {
    const inputfile = React.useRef(null)
    const classes = useStyles()
    const [documentsUploading, setDocumentsUploading] = React.useState(false)
    const mediaController = new mediaHandler(medialApi)
    const { loadedLib, loadPdfLib, loadPdf } = usePdfHook()
    const history = useHistory()

    async function getNumberOfPages(pdfFile) {
        if (!loadedLib) {
            await loadPdfLib()
        }
        const res = await loadPdf(pdfFile)
        return res.numberOfPages
    }

    function validateFile(file) {
        if (file.type !== "application/pdf")
            return createMessage(
                "Document type not supported. Please upload a pdf document.",
                null,
                DANGER
            )
        if (file.size > 100 * 1024 * 1024)
            return createMessage(
                "Document size is too large.",
                "Please upload a document less than 100mb.",
                DANGER
            )
        return true
    }

    async function startDocumentSigning(fileDetails) {
        try {
            const response = await addDocument(
                fileDetails.name,
                fileDetails.mediaId,
                fileDetails.mediaPath,
                fileDetails.pages,
                -1,
                TEMPLATE
            )
            if (response) {
                history.push(`/pdf-editor/${response.model.fileId}`)
            }
        } catch (error) {
            console.log(error)
        }
    }

    async function handleFiles(listFiles) {
        //Only single file upload supported for now
        const singleFile = listFiles[0]
        inputfile.current.value= null
        if (!validateFile(singleFile)) return
        setDocumentsUploading(true)
        const mediaResp = await addDocumentToS3(
            singleFile,
            singleFile.name,
            singleFile.size
        )
        const pages = await getNumberOfPages(mediaResp.mediaPath)
        await startDocumentSigning({
            ...mediaResp,
            name: singleFile.name,
            file: singleFile,
            preView: mediaResp.mediaPath,
            size: singleFile.size,
            pages,
        })
        setDocumentsUploading(false)
    }

    return (
        <div className={classes.templatesRightTop}>
            <div className={classes.templatesPageHeader}>
                <Text varient="h5">{header}</Text>
                <Text marginTop="0.5rem" color="light">
                    {desc}
                </Text>
            </div>
            <div
                className={classes.templatesPageOption}
                style={{ display: searchInputVisible ? "flex" : "none" }}
            >
                <Guard scope="document_create">
                    <TooltipWrapper text={"Upload templates"} direction={"bottom"}>
                        <Button
                            height={"80%"}
                            className={classes.uploadButton}
                            radius="6px"
                            onClick={() => {
                                if (documentsUploading) return
                                inputfile.current.click()
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {documentsUploading ? (
                                    <Loader
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginRight: "0.5rem",
                                        }}
                                    />
                                ) : (
                                    <Upload />
                                )}
                                <Text
                                    fontSize={13}
                                    color="#ffffff"
                                    marginLeft="0.5rem"
                                >
                                    Upload template
                                </Text>
                            </div>
                        </Button>
                    </TooltipWrapper>
                    <input
                        type="file"
                        onChange={(e) => handleFiles(e.target.files)}
                        hidden
                        multiple={false}
                        ref={inputfile}
                        accept="application/pdf"
                    />
                </Guard>
            </div>
        </div>
    )
}

const mapStateToProps = ({ userReducer }) => ({
    userToken: userReducer.token,
})

export default connect(mapStateToProps)(TemplatesRightTop)
