import React, { useState } from "react"
import "./SignAnimation.scss"
import { ReactComponent as Sign } from "Assets/ProductIcon/signicon.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

const SignAnimation = () => {
    const description = [
        "Drafting contacts ...",
        "Making strokes ...",
        "No trees were harmed in making this signature.",
        "Sign in seconds, not ages.",
        "Simplify your signature.",
        "Sign faster, work smarter.",
        "Sign without limits.",
        "Sign on the go, with ease.",
        "Go green, sign electronically.",
        "Sign documents, in a heartbeat.",
        "Easier than signing a check.",
        "Sign now, thank us later.",
        "Signatures made a lot smoother.",
    ]
    const [idx] = useState(Math.floor(Math.random() * 10) % description.length)

    return (
        <div className="sign-first-loading">
            <div className="sign-loading-wrapper">
                <Sign />
            </div>
            <div className="sign-loading-tips">
                <div className="sign-loading-tips-description">
                    <Text align="center" color="light">
                        {description[idx]}
                    </Text>
                </div>
            </div>
        </div>
    )
}

export default SignAnimation
