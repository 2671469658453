import React from "react"
import "./Button.scss"

const Button = ({ color = "blue", onClick, isDisable, ...props }) => {
    return (
        <div
            className={`aui-button-base aui-btn-${color}`}
            onClick={()=>{
                if(!isDisable){
                    onClick()
                }
            }}
            style={{ ...props }}
        >
            <span>{props.children}</span>
        </div>
    )
}

export default Button
