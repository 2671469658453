import React from "react"
import { createUseStyles } from "react-jss"
import searchBg from "Assets/TemplatesPage/search-bg.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import Input from "Awesome-ui/Input/Input"

const useStyles = createUseStyles((theme) => ({
    searchTemplateWrapper: {
        backgroundImage: `url(${searchBg})`,
        minHeight: 220,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        margin: "1rem",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    searchTemplateContent: {
        width: "min(50%, 600px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
}))

const SearchTemplate = ({ searchValue, setSearchValue }) => {
    const classes = useStyles()
    return (
        <div className={classes.searchTemplateWrapper}>
            <div className={classes.searchTemplateContent}>
                <Text varient="h3" color="#fff" align="center" marginBottom="1rem">
                    Save time, explore from our library of free contract templates
                </Text>
                <Input
                    style={{
                        width: "100%",
                        fontSize: "1rem",
                        padding: "10px 18px",
                        maxWidth: 470,
                    }}
                    value={searchValue}
                    changeHandler={setSearchValue}
                    placeHolder="Search templates"
                    icon="search"
                    iconColor="#1ECBE5"
                    inputStyle={{
                        padding: "5px 10px",
                    }}
                />
            </div>
        </div>
    )
}

export default SearchTemplate
