import { getAllDocuments, paginationRequest } from "Action/documentAction"
import { getAllFolders } from "Action/foldersAction"
import useQuery from "hooks/UseQuery"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import Files from "../SelectedFolder/Files"
import StoreTop from "../StoreTop/StoreTop"

const StoreRight = ({
    selection,
    selectFile,
    documents,
    userDetails,
    setselection,
    clearSelection,
    myDocuments,
    folderList,
}) => {
    const history = useHistory()
    const { addQuery, getQuery, removeQuery } = useQuery()
    const [currentDocuments, setCurrentDocuments] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [documentLoading, setDocumentLoading] = useState(false)

    const reload = async () => {
        const currentPage = Number(getQuery("page"))
        const sort = getQuery("sort")
        const searchQuery = getQuery("search")

        if (currentPage !== 1) addQuery("page", "1")
        if (sort) removeQuery("sort")
        if (searchQuery) removeQuery("search")
        await getCurrentDocuments()
    }

    const getCurrentDocuments = async () => {
        if (currentDocuments?.length < 1) setDocumentLoading(true)
        const userId = userDetails?.id
        const currentPage = Number(getQuery("page"))
        const sort = getQuery("sort")
        const size = Number(getQuery("size"))
        const searchQuery = getQuery("search")
        const folderId = myDocuments
            ? folderList?.find(
                  (folder) => folder.name.toLowerCase() === "my documents"
              )?.id
            : ""
        const res = await paginationRequest(
            userId,
            currentPage,
            "",
            sort,
            folderId,
            documents?.content?.length,
            size,
            searchQuery
        )
        if (res?.content) {
            setCurrentDocuments(res.content)
            setTotalPages(res.totalPages)
        }
        setDocumentLoading(false)
    }

    useEffect(() => {
        getCurrentDocuments()
    }, [history.location.search, myDocuments])

    return (
        <div className="store-page-wrapper">
            <StoreTop
                title={myDocuments ? "My Documents" : "All Documents"}
                reloadHandler={async () => {
                    await getAllFolders()
                    reload()
                }}
            />
            <div className="store-page-right">
                <Files
                    files={currentDocuments}
                    selection={selection}
                    totalPages={totalPages}
                    onDeleteFile={reload}
                    documentLoading={documentLoading}
                    setselection={setselection}
                    selectFile={selectFile}
                    clearSelection={clearSelection}
                />
            </div>
        </div>
    )
}

const mapStateToProps = ({ documentReducer, userReducer, foldersReducer }) => {
    return {
        documents: documentReducer.documents,
        userDetails: userReducer?.userDetails,
        folderList: foldersReducer?.folderList,
    }
}

export default connect(mapStateToProps)(StoreRight)
