import Guard from "Awesome-ui/Guard"
import React from "react"
import { NavLink } from "react-router-dom"

import "./SideNav.scss"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

const SideNav = ({ handleViewChange, views, currView, ...props }) => {
    return (
        <div className="settings-sidenav">
            <div className="sidenav-header">
                <Text varient="h4">Settings</Text>
            </div>
            <div className="sidenav-catagories">
                <div className="sidenav-catagorie">
                    <Text
                        varient="h6"
                        margin="0.75rem 0.5rem"
                        className="catagories-heading"
                    >
                        My Settings
                    </Text>
                    <div className="catagories-links">
                        <ul>
                            <NavLink to="/settings/profile">
                                <li
                                    className={`${
                                        currView === views.PROFILE && "active"
                                    }`}
                                    onClick={() => {}}
                                >
                                    <Text varient="subText">Profile</Text>
                                    <span className="arrow"></span>
                                </li>
                            </NavLink>
                            {/* <NavLink to="/settings/permissions">
                                <li
                                    className={`${
                                        currView === views.PERMISSIONS && "active"
                                    }`}
                                    onClick={() => {}}
                                >
                                    <span>Permissions</span>
                                    <span className="arrow"></span>
                                </li>
                            </NavLink> */}
                            {/* <NavLink to="/settings/notification">
                                <li
                                    className={`${
                                        currView === views.NOTIFICATION && "active"
                                    }`}
                                    onClick={() => {}}
                                >
                                    <span>Notifications</span>
                                    <span className="arrow"></span>
                                </li>
                            </NavLink> */}
                        </ul>
                    </div>
                </div>
                <Guard scope="account_settings_get">
                    <div className="sidenav-catagorie">
                        <Text
                            varient="h6"
                            margin="0.75rem 0.5rem"
                            className="catagories-heading"
                        >
                            Accounts Settings
                        </Text>
                        <div className="catagories-links">
                            <ul>
                                {/* <NavLink to="/settings/customization">
                                <li
                                    className={`${
                                        currView === views.CUSTOMIZATION && "active"
                                    }`}
                                    onClick={() => {}}
                                >
                                    <span>Customization</span>
                                    <span className="arrow"></span>
                                </li>
                            </NavLink> */}
                                <NavLink to="/settings/users">
                                    <li
                                        className={`${
                                            currView === views.USERS && "active"
                                        }`}
                                        onClick={() => {}}
                                    >
                                        <Text varient="subText">Users</Text>
                                        <span className="arrow"></span>
                                    </li>
                                </NavLink>
                                {/* <NavLink to="/settings/plan">
                                    <li
                                        className={`${
                                            currView === views.PLAN && "active"
                                        }`}
                                        onClick={() => {}}
                                    >
                                        <span>Subscription Plan</span>
                                        <span className="arrow"></span>
                                    </li>
                                </NavLink> */}
                                {/* <NavLink to="/settings/workspace">
                                    <li
                                        className={`${
                                            currView === views.WORKSPACE && "active"
                                        }`}
                                        onClick={() => {}}
                                    >
                                        <span>Workspace</span>
                                        <span className="arrow"></span>
                                    </li>
                                </NavLink> */}
                                {/* <li>
                                <span>Referral Programme</span>
                                <span className="arrow"></span>
                            </li> */}
                                {/* <NavLink to="/settings/freeplan">
                                <li
                                    className={`${
                                        currView === views.FREEPLAN && "active"
                                    }`}
                                >
                                    <span>Free Plan</span>
                                    <span className="arrow"></span>
                                </li>
                            </NavLink> */}
                            </ul>
                        </div>
                    </div>
                </Guard>
            </div>
        </div>
    )
}

export default SideNav
