import React from "react"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { createUseStyles } from "react-jss"
import { ReactComponent as ToggleIcon } from "../../../Assets/Inbox/toggle-inbox-left.svg"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom"
import { DRAFT, FINISHED, OUT_FOR_SIGNATURE } from "Types"
import { connect } from "react-redux"
import {
    getAllDocumentsRecievedForSignature,
    getAllDocumentsWithStatus,
} from "Action/documentAction"

const useStyle = createUseStyles((theme) => ({
    dot: {
        width: 10,
        height: 10,
        borderRadius: "50%",
        display: "inline-block",
        marginRight: "6px",
    },
    toggleIcon: {
        position: "absolute",
        right: "1rem",
        top: "1rem",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        height: "27px",
    },
    toggleIconActive: {
        transform: "rotate(180deg) translateX(-50%)",
        marginRight: "0",
        right: "50%",
    },
    inboxLeft: {
        width: "Max(15%, 270px)",
        backgroundColor: "#F4F7F9",
        borderRight: "1px solid #d6dfe8",
        padding: "1rem",
        position: "sticky",
        "@media screen and (max-width: 600px)": {
            width: "200px",
        },
        top: 0,
        left: 0,
        "& a": {
            textDecoration: "none",
            color: "inherit",
            display: "block",
        },
    },
    inboxSelect: {
        width: "100%",
    },
    inboxSelectItem: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid transparent",
        borderRadius: "4px",
        cursor: "pointer",
        color: "#7c98b6",
        marginBottom: "0.25rem",
        "&:hover": {
            color: "#33475b",
            backgroundColor: "#eaf0f6",
            borderRadius: "4px",
        },
    },
    inboxItemSelected: {
        color: "#33475b",
        backgroundColor: "#eaf0f6",
        borderRadius: "4px",
    },
    inboxLeftToggle: {
        width: "fit-content",
    },
    inboxSelectItemLeft: {
        display: "flex",
        alignItems: "center",
        padding: "8px",
    },
    inboxCount: {
        height: "25px",
        aspectRatio: 1,
        display: "flex",
        alignItems: "center",
        backgroundColor: "#e9eff5",
        justifyContent: "center",
        borderRadius: 6,
        marginRight: "0.25rem",
    },
    inboxCountActive: {
        backgroundColor: "#d6e5f3",
    },
}))

const InboxLeft = ({
    draftCount,
    sendCount,
    recievedForSignCount,
    finishedCount,
    documents,
    isToggle,
    setIsToggle,
}) => {
    const classes = useStyle()
    const history = useHistory()
    const options = [
        {
            name: "Completed",
            link: "completed",
            index: 0,
            count: finishedCount ? finishedCount : 0,
            color: "#06D0A2",
        },
        {
            name: "Recieved for Signature",
            link: "recieved-for-signature",
            index: 1,
            count: recievedForSignCount ? recievedForSignCount : 0,
            color: "#25CAFF",
        },
        {
            name: "Awaiting Signature",
            link: "awaiting-signature",
            index: 2,
            count: sendCount ? sendCount : 0,
            color: "#F5C26B",
        },
        {
            name: "Draft",
            link: "draft",
            index: 3,
            count: draftCount ? draftCount : 0,
            color: "#CBD6E2",
        },
    ]

    const fetchAllDocuments = async () => {
        await Promise.all([
            getAllDocumentsWithStatus(DRAFT),
            getAllDocumentsWithStatus(OUT_FOR_SIGNATURE),
            getAllDocumentsWithStatus(FINISHED),
            getAllDocumentsRecievedForSignature(),
        ])
    }

    React.useEffect(() => {
        fetchAllDocuments()
    }, [documents])

    return (
        <div
            className={`${classes.inboxLeft} ${isToggle && classes.inboxLeftToggle}`}
        >
            <div
                className={`${classes.toggleIcon} ${
                    isToggle && classes.toggleIconActive
                }`}
                onClick={() => setIsToggle(!isToggle)}
            >
                <ToggleIcon />
            </div>
            <Text varient="h5" margin="0 0 1rem 0" color="#33475B">
                {!isToggle && "Document Inbox"}
            </Text>
            {!isToggle && (
                <div className={`${classes.inboxSelect}`}>
                    {options.map((item, key) => (
                        <Link to={`/inbox/${item.link}?page=1&size=10`} key={key}>
                            <div
                                key={key}
                                className={`${classes.inboxSelectItem} 
                        ${
                            history.location.pathname.split("/")[2] === item.link
                                ? classes.inboxItemSelected
                                : ""
                        }`}
                            >
                                <div className={`${classes.inboxSelectItemLeft}`}>
                                    <div
                                        className={classes.dot}
                                        style={{ background: item.color }}
                                    />
                                    <Text fontSize={14}>{item.name}</Text>
                                </div>
                                <div
                                    className={`${classes.inboxCount} 
                        ${
                            history.location.pathname.split("/")[2] === item.link
                                ? classes.inboxCountActive
                                : ""
                        }`}
                                >
                                    {item.count}
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}

const mapStateToProps = ({ documentReducer }) => ({
    documents: documentReducer.documents,
    draftCount: documentReducer.draftDocuments.totalElements,
    sendCount: documentReducer.sendForSignature.totalElements,
    finishedCount: documentReducer.finishedDocments.totalElements,
    recievedForSignCount: documentReducer.recievedForSign.totalElements,
})

export default connect(mapStateToProps)(InboxLeft)
