import React, { useEffect, useState } from "react"
import "./File.scss"

import TableRow from "Awesome-ui/TableRow/TableRow"
import TableCell from "Awesome-ui/TableCell/TableCell"

import { ReactComponent as FileIcon } from "Assets/Storage/filePdf.svg"
import { ReactComponent as More } from "Assets/Storage/more.svg"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import { initializeDocument } from "Action/signAction"
import { useHistory } from "react-router-dom"
import {
    deleteDocument,
    getAllDocumentsRecievedForSignature,
    getAllDocumentsWithStatus,
} from "Action/documentAction"
import AvatarGroup from "../AvatarGroup"
import { Avatar, CheckBox, Text } from "@awesomesuite-frontend/awesome-nebula"
import Guard from "Awesome-ui/Guard"
import { DRAFT, RECIEVED_FOR_SIGNATURE } from "Types"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import SignerStatusChip, {
    signerStatus,
} from "Awesome-ui/SignerStatusChip/SignerStatusChip"

const statusUiHandler = [
    {
        status: "DRAFT",
        name: "Draft",
        color: "#33475B",
        border: "1px solid #d6dfe8",
        backgroundColor: "#fff",
    },
    {
        status: "OUT_FOR_SIGNATURE",
        name: "Awating",
        border: "1px solid #F5C26B",
        backgroundColor: "#FEF8F0",
        color: "#DBAE60",
    },
    {
        status: "FINISHED",
        name: "Signed",
        border: "1px solid #00BDA5",
        backgroundColor: "#E5F8F6",
        color: "#00BDA5",
    },
    // "RECIEVED FOR ME": {color: "25caff"},
    {
        status: "CANCELLED",
        name: "Declined",
        color: "#F2545B",
        backgroundColor: "#FDEDEE",
        border: "1px solid #F8A9AD",
    },
]

const DropDown = ({
    fileId,
    tag,
    openDocumentForView,
    folderId,
    onDeleteFile,
    status,
}) => {
    const [openDrop, setOpenDrop] = useState(false)

    async function handleDelete() {
        await deleteDocument(fileId, tag, folderId)
        await onDeleteFile()
        if (status === RECIEVED_FOR_SIGNATURE)
            await getAllDocumentsRecievedForSignature()
        else await getAllDocumentsWithStatus(status)
        setOpenDrop(false)
    }

    function handleCloseDrop() {
        setOpenDrop(false)
        window.removeEventListener("click", handleCloseDrop)
    }

    return (
        <div className="file-menu">
            <div
                className="file-menu-icon"
                onClick={() => setOpenDrop((prev) => !prev)}
            >
                <More />
            </div>
            {openDrop && (
                <div
                    className="file-menu-options"
                    onMouseLeave={() =>
                        window.addEventListener("click", handleCloseDrop)
                    }
                >
                    <Guard scope="document_delete" show>
                        <div className="file-menu-item" onClick={handleDelete}>
                            Delete
                        </div>
                    </Guard>
                    <Guard scope="document_update" show>
                        <div
                            className="file-menu-item"
                            onClick={openDocumentForView}
                        >
                            Edit
                        </div>
                    </Guard>
                </div>
            )}
        </div>
    )
}

/**
 * File Type of Table Rom Component meant to be used by the
 * Selected Folder or the root file view.
 *
 * @param {*} param0
 */
const File = ({
    file,
    selectFun,
    isChecked,
    userName,
    userEmail,
    userPicture = "",
    onDeleteFile = () => {},
}) => {
    const { namedDate, splitText, getDateAndTime } = useBasicUtils()
    const history = useHistory()
    const [signers, setSigners] = useState([])
    useEffect(() => {
        if (file.signer && file.status !== DRAFT) {
            let newSigners = file.signer.map((eachSigner) => {
                return {
                    name: eachSigner.firstName + " " + eachSigner.lastName,
                    email: splitText(eachSigner.emailId, 20),
                    statusHighlightColor:
                        signerStatus[eachSigner.signerStatus]?.color || "#7C98B6",
                    statusElement: (
                        <SignerStatusChip status={eachSigner.signerStatus} />
                    ),
                }
            })
            setSigners(newSigners)
        }
    }, [file])

    async function openDocumentForView() {
        initializeDocument(file)
        history.push(`/pdf-editor/${file.fileId}`)
    }

    return (
        <TableRow
            style={{
                border: isChecked ? "none" : "1px solid #d6dfe8",
                backgroundColor: `${isChecked ? "#E2F4FC" : "transparent"}`,
                outline: isChecked ? "1px solid #3DC1F2" : "none",
            }}
        >
            <TableCell align="left" width="60px">
                <CheckBox
                    active={isChecked}
                    clickFunction={selectFun}
                    color={"white"}
                    backgroundColor={"rgb(0, 189, 165)"}
                    variant={"curved"}
                    border={"lightblue"}
                />
            </TableCell>
            <TableCell>
                <TooltipWrapper
                    direction={"top"}
                    text={file.fileName}
                    style={{ wordBreak: "break-all" }}
                >
                    <div
                        className="store-folder"
                        style={{
                            cursor: "pointer",
                            position: "relative",
                            width: "100%",
                        }}
                        onClick={openDocumentForView}
                    >
                        <FileIcon width="24px" />
                        {/* {splitText(file.fileName)} */}
                        {splitText(file.fileName, 15)}
                        {file.pages && (
                            <div
                                style={{
                                    color: "#3FC2F2",
                                    marginLeft: "auto",
                                    marginRight: "1rem",
                                    fontWeight: "500",
                                }}
                            >
                                {file.pages} Pages
                            </div>
                        )}
                    </div>
                </TooltipWrapper>
            </TableCell>
            <TableCell align="center">
                <Avatar
                    name={userName}
                    email={splitText(userEmail, 20)}
                    isTooltip
                    imageSource={userPicture}
                    border="none"
                />
            </TableCell>
            <TableCell align="center">
                <div className="store-team-flex">
                    {/* {file.team &&
                        file.team.map((usr, usrIndex) => (
                            <Avatar
                                key={usr}
                                style={{
                                    transform: `translateX(-${10 * usrIndex}px)`,
                                    border: "1px solid #fff",
                                }}
                            />
                        ))} */}
                    <AvatarGroup data={signers} limit={5} noStatusCircleOffset />
                </div>
            </TableCell>
            <TableCell align="center">
                <div
                    style={{
                        padding: "0.25rem 0.5rem",
                        backgroundColor: statusUiHandler.find(
                            (e) => e.status === file.status
                        )?.backgroundColor,
                        color: statusUiHandler.find((e) => e.status === file.status)
                            ?.color,
                        border:
                            statusUiHandler.find((e) => e.status === file.status)
                                ?.border ||
                            `1px solid ${
                                statusUiHandler.find((e) => e.status === file.status)
                                    ?.color
                            }`,
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "fit-content",
                    }}
                >
                    {statusUiHandler.find((e) => e.status === file.status)?.name}
                </div>
            </TableCell>
            <TableCell align="center" style={{ color: "#99ACC2" }}>
                <TooltipWrapper
                    text={`Created by ${userName} on ${getDateAndTime(
                        file.createdAt
                    )}`}
                >
                    {namedDate(file.createdAt)}
                </TooltipWrapper>
            </TableCell>
            <TableCell align="center" style={{ color: "#99ACC2" }}>
                <TooltipWrapper
                    text={`Last Updated by ${userName} on ${getDateAndTime(
                        file.updatedAt
                    )}`}
                >
                    {namedDate(file.updatedAt)}
                </TooltipWrapper>
            </TableCell>
            <TableCell width="5%">
                <Guard scope="document_settings_get">
                    <DropDown
                        openDocumentForView={openDocumentForView}
                        fileId={file.fileId}
                        tag={file.tag}
                        folderId={file.folderId}
                        onDeleteFile={onDeleteFile}
                        status={file.status}
                    />
                </Guard>
            </TableCell>
        </TableRow>
    )
}

export default File
