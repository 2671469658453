import React from "react"
import { Route, Switch } from "react-router-dom/cjs/react-router-dom"
import TemplatesLibrary from "../TemplatesLibrary"
import MyTemplates from "../MyTemplates"

const TemplatesRight = () => {
    return (
        <Switch>
            <Route path={"/templates/my-templates"}>
                <MyTemplates />
            </Route>
            <Route path={"/templates/templates-library"}>
                <TemplatesLibrary />
            </Route>
        </Switch>
    )
}

export default TemplatesRight
