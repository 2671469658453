import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as DragUserSignsIcon } from "Assets/PDFCommons/drag-user-signs.svg"
import { ReactComponent as DeleteIcon } from "Assets/Storage/delete-icon.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { connect } from "react-redux"
import { SignatureDragType } from "Components/PDFCommons/container/Types"
import DragUserSign from "./DragUserSign"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import { deleteUserSign, getUserSigns } from "Action/signAction"

const useStyles = createUseStyles({
    userSignsHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        padding: "1rem",
        border: "1px solid #CCDFF2",
        borderTop: "none",
    },
    userSignEach: {
        display: "flex",
        alignItems: "center",
        padding: "0 1rem",
        margin: "1rem 0",
    },
    userSignDel: {
        borderRadius: "50%",
        cursor: "pointer",
        marginRight: ".5rem",
        height: 40,
        aspectRatio: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg path": {
            fill: "#7C98B6",
        },
        "&:hover": {
            backgroundColor: "#eaf0f6",
        },
    },
})

export const EachSign = ({ sign }) => {
    const classes = useStyles()
    return (
        <div className={classes.userSignEach}>
            <DragUserSign sign={sign} />
            <TooltipWrapper
                wrapperClassName={classes.userSignDel}
                text={"Delete Sign"}
                direction={"bottom"}
                onClick={() => deleteUserSign(sign.id || sign.signUrl)}
            >
                <DeleteIcon width={22} height={22} />
            </TooltipWrapper>
        </div>
    )
}

const UserSigns = ({ userSigns }) => {
    const classes = useStyles()
    const [userSignatures, setUserSignatures] = React.useState(userSigns)
    const init = async () => {
        if (!userSigns) {
            const signs = await getUserSigns()
            return setUserSignatures(signs)
        }
        setUserSignatures(userSigns)
    }

    React.useEffect(() => {
        init()
    }, [userSigns])
    return (
        <div className={classes.userSign}>
            <div className={classes.userSignsHeader}>
                <DragUserSignsIcon />
                <Text
                    varient="subText"
                    weight={600}
                    style={{ flex: 1 }}
                    marginLeft="1rem"
                >
                    Drag and drop your signature
                </Text>
            </div>
            <div className={classes.userSignsAll}>
                {userSignatures &&
                    Array.isArray(userSignatures) &&
                    userSignatures.map((sign, index) => (
                        <EachSign sign={sign} key={index} />
                    ))}
            </div>
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    userSigns: signReducer.userSigns,
})

export default connect(mapStateToProps)(UserSigns)
