import React from "react"
import SideNav from "../../Components/SideNav/SideNav"
import AppBar from "../../Awesome-ui/AppBar/AppBar"

import "./styles.scss"
import { connect } from "react-redux"

import HelpSideNavBar from "../../Awesome-ui/HelpSideNavbar"
import HorizontalBar from "../../Awesome-ui/Horizontal/HorizontalBar"
import NotificationBell from "../../Awesome-ui/Icons/NotificationBell"
import NotificationBar from "../../Awesome-ui/NotificationBar"
import User from "./User"

import { ReactComponent as SignLogoMid } from "Assets/ProductIcon/sign-logo-med.svg"
import { ReactComponent as SignLogo } from "Assets/ProductIcon/signicon.svg"
import { ReactComponent as HomeIcon } from "Assets/NavigationIcon/home.svg"
import { ReactComponent as ArchiveIcon } from "Assets/NavigationIcon/archive.svg"
import { ReactComponent as FolderIcon } from "Assets/NavigationIcon/folder.svg"
import { ReactComponent as LayoutIcon } from "Assets/NavigationIcon/layout.svg"
import { ReactComponent as UpgradeIcon } from "Assets/NavigationIcon/upgrade-icon.svg"

import { signOut } from "../../Action/userAction"
import UserSideBar from "Awesome-ui/UserSideBar/UserSideBar"
import { setSideBarColor, setSideBarType } from "Action/settingActions"
import AppSwitcher from "Components/AppSwitcher/AppSwitcher"
import AddDocument from "Components/AddDocument/AddDocument"
import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import PlanPopup from "Awesome-ui/PlanPopup"
import {
    getSubscriptionPlan,
    hidePopUpPlan,
    hideUpgradePlan,
    showPopUpPlan,
    showUpgradePlan,
} from "Action/subscriptionActions"
import TrialEndedPopup from "Awesome-ui/TrialEndedPopup"
import Guard from "Awesome-ui/Guard"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import RightLinks from "./RightLinks"
import { toggleAppSwitcher } from "Action/messageAndNotificationActions"
import { OWNER } from "Types"

const navOptions = [
    { name: "Dashboard", link: "/", Icon: HomeIcon },
    { name: "Inbox", link: "/inbox", Icon: ArchiveIcon },
    { name: "Documents", link: "/documents?page=1&size=10", Icon: FolderIcon },
    { name: "Templates", link: "/templates", Icon: LayoutIcon },
]

const PrivateRouteWrapper = ({
    sideBarType,
    sideBarColor,
    sideExpand,
    noSideBar,
    notifications,
    userDetails,
    workspace,
    workspaceLoading,
    subscriptionPopUp,
    upgradePopUp,
    subscription,
    numberOfSigns = 1,
    userRole,
    showAppSwitcher,
    ...props
}) => {
    const [openNotifications, setOpenNotifications] = React.useState(false)
    const [showUserDropDown, setShowUserDropDown] = React.useState(false)
    const [showHelp, setShowHelp] = React.useState(false)
    const [planTitle, setPlanTitle] = React.useState("")
    const [subscriptionPlans, setSubscriptionPlans] = React.useState(null)

    const signOutUser = () => signOut(true, true)

    React.useEffect(() => {
        const init = async () => {
            if (!subscriptionPlans) {
                const res = await getSubscriptionPlan()
                setSubscriptionPlans(res)
            } else {
                const currentSubscriptionId = subscription[0]?.planId
                const plan = subscriptionPlans.find(
                    (subscription) => subscription.id === currentSubscriptionId
                )
                if (plan) setPlanTitle(plan.title)
            }
        }
        if (subscription) init()
    }, [subscription, subscriptionPlans])

    return (
        <div className="displayFlex">
            {(workspace?.length > 0 ||
                (userRole !== OWNER && (!workspace || !workspace.length))) &&
                sideBarType !== "NO_SIDEBAR" && (
                    <SideNav
                        IconFull={SignLogoMid}
                        IconSmall={SignLogo}
                        type={sideBarType}
                        expand={sideExpand}
                        theme={sideBarColor}
                        navOptions={navOptions}
                        appSwitch={() => toggleAppSwitcher()}
                        userRole={userRole}
                        restricted={
                            userRole !== OWNER && (!workspace || !workspace.length)
                        }
                        showAppSwitcher={
                            userRole === OWNER
                                ? true
                                : !workspace || !workspace.length
                        }
                    />
                )}
            <AppSwitcher
                openAppSwitcher={showAppSwitcher}
                setOpenSwitcher={toggleAppSwitcher}
            />
            <UserSideBar
                sideBarType={sideBarType}
                setSideBarType={setSideBarType}
                sideBarColor={sideBarColor}
                setSideBarColor={setSideBarColor}
                signOut={signOutUser}
                showUserDropDown={showUserDropDown}
                setShowUserDropDown={setShowUserDropDown}
                userDetails={userDetails}
                numberOfSigns={numberOfSigns}
            />
            <TrialEndedPopup
                active={subscriptionPopUp}
                onClose={hidePopUpPlan}
                onLogOut={signOutUser}
                isClosable={process.env.REACT_APP_ENV.includes("dev")}
            />
            <PlanPopup
                active={upgradePopUp}
                onClose={hideUpgradePlan}
                plans={subscriptionPlans}
            />
            <div className="main-app">
                <AppBar justify="space-between" theme={noSideBar && sideBarColor}>
                    {(workspace?.length > 0 ||
                        (userRole !== OWNER && (!workspace || !workspace.length))) &&
                    sideBarType === "NO_SIDEBAR" ? (
                        <HorizontalBar
                            Logo={SignLogo}
                            type={sideBarType}
                            expand={sideExpand}
                            theme={sideBarColor}
                            navOptions={navOptions}
                            appSwitch={() => toggleAppSwitcher()}
                            userRole={userRole}
                            restricted={
                                userRole !== OWNER &&
                                (!workspace || !workspace.length)
                            }
                            showAppSwitcher={
                                userRole === OWNER
                                    ? true
                                    : !workspace || !workspace.length
                            }
                        />
                    ) : (
                        <div></div>
                    )}
                    <div className="userAvatar">
                        {/* {userRole !== OWNER && (!workspace || !workspace.length) ? (
                            <></>
                        ) : ( */}
                        <RightLinks
                            setOpenNotifications={setOpenNotifications}
                            setOpenHelp={setShowHelp}
                            showUpgrade={planTitle !== "Business"}
                        />
                        {/* )} */}
                        <User
                            logoutHandler={signOutUser}
                            userDetails={userDetails}
                            showUserDropDown={showUserDropDown}
                            setShowUserDropDown={setShowUserDropDown}
                        />
                    </div>
                </AppBar>

                <div className="layout-main-content default-padding">
                    {props.children}
                </div>
            </div>
            <NotificationBar
                filterOption={true}
                showNotify={openNotifications}
                closeNotify={setOpenNotifications}
                listOfNotification={notifications}
            />
            <HelpSideNavBar helpOpen={showHelp} closeSideBar={setShowHelp} />
        </div>
    )
}

const mapStateToProps = ({
    settingsReducer,
    messageAndNotificationReducer,
    userReducer,
    workspaceReducer,
    subscriptionReducer,
}) => ({
    sideBarType: settingsReducer.sideBarType,
    sideBarColor: settingsReducer.sideBarColor,
    sideExpand: settingsReducer.sideExpand,
    noSideBar: settingsReducer.noSideBar,
    notifications: messageAndNotificationReducer.notifications,
    userDetails: userReducer.userDetails,
    workspace: workspaceReducer.workspace,
    workspaceLoading: workspaceReducer.workspaceLoading,
    subscriptionPopUp: subscriptionReducer.subscriptionPopUp,
    upgradePopUp: subscriptionReducer.upgradePopUp,
    subscription: subscriptionReducer?.subscription,
    userRole: userReducer.userRole,
    showAppSwitcher: messageAndNotificationReducer.showAppSwitcher,
    // numberOfSigns: signReducer?.noOfSignes,
})

export default connect(mapStateToProps)(PrivateRouteWrapper)
