import React, { useEffect, useState, useRef, useContext } from "react"
import axios from "axios"
import GifList from "./GifList"
import Input from "Awesome-ui/Input/Input"
import "./UnsplashPicker.scss"
import { ReactContext } from "Awesome-ui/MediaLibrary/MediaLibrary"
import { usePickerStyles } from "./PickerStyle"

export const gifApi = axios.create({
    baseURL: "https://tenor.googleapis.com/v2",
})

// TODO: get the new tokens.
const config = {
    key: process.env.REACT_APP_DRIVE_APP_KEY,
    client_key: process.env.REACT_APP_DRIVE_CLIENT_ID,
    media_filter: "minimal",
}

// TODO: fix these using these because intersection observer is not taking react state.
let lastKey = null
const options = {
    threshold: 1,
    root: null,
}

const Gif = ({ setCheckoutFiles }) => {
    const [gif, setGif] = useState({})
    const scrollRef1 = useRef(null)
    const scrollRef2 = useRef(null)
    const scrollRef3 = useRef(null)
    const scrollRefs = [scrollRef1, scrollRef2, scrollRef3]
    const [loading, setLoading] = useState(false)
    const [qSearch, setqSearch] = useState("")
    const { handlingFiles } = useContext(ReactContext)

    function handelObserver(entries) {
        if (!entries) return
        const target = entries.every((e) => e.isIntersecting === false)
        if (!target && loading === false) {
            fetchDetails()
        }
    }

    // tenorApi.Search
    useEffect(() => {
        function init() {
            const observer = new IntersectionObserver(handelObserver, options)
            scrollRefs.map((e) => {
                if (e.current) observer.observe(e.current)
            })

            return () => {
                scrollRefs.map((e) => observer.disconnect(e.current))
            }
        }
        init()
    }, [...scrollRefs.map((r) => r.current)])

    /**
     *  handle the gif fetch
     *  depend on the conditions by default it will search for the trends and if
     *  user is searching the we will search for that key.
     */
    async function fetchDetails() {
        setLoading(true)

        let params = {
            limit: "30",
            pos: lastKey,
            ...config,
        }

        let url = "search"

        if (qSearch && qSearch.length > 0) {
            url = "search"
            params.q = qSearch
        } else {
            const currentTrend = await gifApi.get("trending_terms", {
                params,
            })
            console.log(currentTrend)
            params.q = currentTrend.data.results[0]
        }

        const trend = await gifApi.get(url, { params })

        lastKey = trend.data.next
        const list = trend.data.results
        setGif((prev) => {
            const old = { ...prev }

            Array(3)
                .fill()
                .map((item, key) => {
                    if (old[key])
                        old[key] = old[key].concat(
                            list.slice(10 * key, 10 * (key + 1))
                        )
                    else {
                        let updatedList = list.concat(handlingFiles.tenor)
                        old[key] = updatedList.slice(10 * key, 10 * (key + 1))
                    }
                })
            return old
        })

        setLoading(false)
    }

    /**
     * search post trigger function to set the values to the defaults.
     */
    async function searchPost() {
        lastKey = null
        setLoading(true)
        setGif({ 0: [], 1: [], 2: [] })
        fetchDetails()
    }

    useEffect(() => {
        let tout = setTimeout(searchPost, 500)
        return () => clearTimeout(tout)
    }, [qSearch])

    const classes = usePickerStyles()
    return (
        <>
            <div className={classes.mediaPicker}>
                <div className={classes.mediaContentWrapper}>
                    <div className={classes.mediaContent}>
                        <div className={classes.mediaSearch}>
                            <Input
                                placeHolder="Search gifs"
                                type="text"
                                style={{ width: "80%", maxWidth: "80%" }}
                                onKeyHandler={searchPost}
                                changeHandler={(data) => {
                                    setqSearch(data)
                                }}
                                icon="search"
                                iconColor="#00BBD8"
                                inputStyle={{ background: "#EFF3F6" }}
                            />
                        </div>
                        <div className={classes.imageContent}>
                            <GifList gif={gif} scrollRefs={scrollRefs} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gif
