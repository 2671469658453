import { Button, Text } from "@awesomesuite-frontend/awesome-nebula"
import { createMessage } from "Action/messageAndNotificationActions"
import { addRoleToTemplate } from "Action/signAction"
import Input from "Awesome-ui/Input/Input"
import { DANGER } from "Types"
import React from "react"
import { createUseStyles } from "react-jss"
import { connect } from "react-redux"

const useStyles = createUseStyles({
    signerCard: {
        maxWidth: 360,
        margin: "0 auto",
        display: "flex",
        position: "relative",
    },
    addedSignerCard: {
        width: "100%",
        padding: 12,
        borderRadius: 8,
        marginBottom: "1rem",
    },
})

const AddRole = ({ afterAddRole = () => {}, documentId }) => {
    const [name, setName] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const classes = useStyles()

    const addRoleSubmit = async () => {
        if (loading) return
        if (!name.trim())
            return createMessage(
                "Role name field is empty",
                "Enter a role name to add",
                DANGER
            )
        setLoading(true)
        await addRoleToTemplate(documentId, name.trim())
        setLoading(false)
        afterAddRole(name)
    }

    return (
        <div className={classes.signerCard}>
            <div className={classes.addedSignerCard}>
                <Text style={{ marginBottom: "1rem" }}>New Role</Text>
                <Input
                    placeHolder="Role Name"
                    style={{ width: "100%", marginBottom: "12px" }}
                    value={name}
                    changeHandler={(val) => {
                        setName(val)
                    }}
                />
                <Button
                    className={classes.button}
                    onClick={addRoleSubmit}
                    loading={loading}
                    width="100%"
                    radius={2}
                >
                    <Text color="#fff" align="center" fontSize={14}>
                        ADD ROLE
                    </Text>
                </Button>
            </div>
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    documentId: signReducer.documentDetails?.documentId,
})

export default connect(mapStateToProps)(AddRole)
