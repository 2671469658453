import React, { memo, useEffect, useState } from "react"
import "./PdfEditor.scss"
import { connect } from "react-redux"
import DragItemCatcher from "Components/PDFCommons/container/DragItemCatcher/DragItemCatcher"

import usePdfHook from "../../../hooks/PdfLib/PdfHook"
import { addPdfPagesPreview } from "Action/signAction"
import SignatureItems from "./SignatureItems"
import Document from "Components/PdfViewer/Document"
import Page from "Components/PdfViewer/Page"
import { CANCELLED, FORM, OUT_FOR_SIGNATURE } from "Types"
import PdfSignScroller from "../PdfSignScroller/PdfSignScroller"
import TemplateForm from "public/TemplateEditor/TemplateForm/TemplateForm"

const eachAnnotationHandler = (annotationId, storage, eachPageAnnotations) => {
    const element = document.querySelector(`[data-annotation-id="${annotationId}"]`)
    let name = element?.childNodes[0]?.getAttribute("name")
    if (!name) name = element?.childNodes[0]?.getAttribute("data-name")
    return {
        value:
            element.childNodes[0].type === "checkbox"
                ? eachPageAnnotations[annotationId].value
                    ? "Yes"
                    : "Off"
                : eachPageAnnotations[annotationId].value,
        type: element.childNodes[0].type,
        name,
    }
}

const annotationsofEachStorageHandler = (storage) => {
    const eachPageAnnotations = storage.annotationStorage.getAll()
    if (!eachPageAnnotations) return []
    const res = []
    Object.keys(eachPageAnnotations).map((annotationId) => {
        const annotation = eachAnnotationHandler(
            annotationId,
            storage,
            eachPageAnnotations
        )
        if (annotation) res.push(annotation)
    })
    return res
}

/**
 * pdf viewer to show pdf and it contains the drag items
 *
 * @param {*} props params.pdfUrl is link to the pdf came for the editing.
 */
const PdfEditor = memo(
    ({
        activePage,
        documentUrl,
        setActivePage,
        canvasStyle,
        disable,
        editingMode,
        parentRef,
        status,
        isPageScrolling,
        signature,
        view,
    }) => {
        const pdfWrapper = React.useRef()
        var pdfRefs = React.useRef([])
        const annotationStorages = React.useRef([])
        const {
            loadPdf,
            loadPdfLib,
            loadedLib,
            getAllPdfPagesForCurrentPdf,
            loadedPdf,
            numberOfPages,
        } = usePdfHook(pdfRefs)
        const [pages, setPages] = useState()

        const scrollToPage = (pageNumber) => {
            const activePageRef = pdfRefs.current[pageNumber]
            const details = activePageRef.getBoundingClientRect()
            const pdfWrapperRef = pdfWrapper.current
            pdfWrapperRef.scrollTo({
                top: pdfWrapperRef.scrollTop + details.y - 150,
            })
        }

        /**
         * this will handle page scroll and page number of current page visible to the user
         * @param {*} pageNumber - page number of the the pdf
         */
        useEffect(() => {
            if (activePage > -1 && pdfRefs.current[activePage]) {
                scrollToPage(activePage)
            }
        }, [isPageScrolling])

        const activePageScrollHandler = () => {
            let start = 0,
                end = numberOfPages - 1
            while (start <= end) {
                let mid = Math.floor((start + end) / 2)
                if (mid === numberOfPages - 1) {
                    setActivePage(numberOfPages - 1)
                    break
                }

                if (pdfRefs.current[mid]) {
                    const midRef1 = pdfRefs.current[mid]
                    const details1 = midRef1.getBoundingClientRect()
                    const midRef2 = pdfRefs.current[mid + 1]
                    const details2 = midRef2.getBoundingClientRect()

                    if (details1.y > 150) end = mid - 1
                    else if (details2.y <= 150) start = mid + 1
                    else {
                        setActivePage(mid)
                        break
                    }
                }
            }
        }

        useEffect(() => {
            if (pdfWrapper.current && loadedPdf)
                pdfWrapper.current.addEventListener(
                    "scroll",
                    activePageScrollHandler
                )

            return () =>
                pdfWrapper.current?.removeEventListener(
                    "scroll",
                    activePageScrollHandler
                )
        }, [pdfWrapper.current, loadedPdf])

        useEffect(() => {
            async function getPdf() {
                if (loadedLib && documentUrl) {
                    await loadPdf(documentUrl)
                } else loadPdfLib()
            }

            getPdf()
        }, [loadedLib, documentUrl])

        /**
         * get the position or bonds of the pdf page.
         * */
        function getClientPos(id) {
            const canvas = pdfRefs.current[id]
            return canvas.getBoundingClientRect()
        }
        React.useImperativeHandle(parentRef, () => ({
            async getPdfPages() {
                if (loadedLib && documentUrl) {
                    const result = await getAllPdfPagesForCurrentPdf()
                    addPdfPagesPreview(result)
                }
            },
            saveAllAnnotations() {
                let allAnnotations = []
                annotationStorages.current.map((annotationStorage) =>
                    allAnnotations.push(
                        ...annotationsofEachStorageHandler(annotationStorage)
                    )
                )
                return allAnnotations
            },
        }))

        return (
            <>
                <div
                    className="pdf-render-page"
                    style={{ width: view === FORM ? "65vw" : "100%" }}
                    ref={(el) => (pdfWrapper.current = el)}
                >
                    {status === OUT_FOR_SIGNATURE && (
                        <PdfSignScroller
                            numberOfPages={numberOfPages}
                            pdfRefs={pdfRefs}
                            pdfWrapper={pdfWrapper}
                        />
                    )}
                    <div
                        className="pdf-canvas-wrapper"
                        style={{
                            ...canvasStyle,
                        }}
                    >
                        <Document
                            file={documentUrl}
                            onSuccessHandler={({ numberOfPages }) => {
                                setPages(new Array(numberOfPages).fill(0))
                            }}
                            signature={signature}
                        >
                            {pages &&
                                pages.map((item, key) => (
                                    <EachPage
                                        key={key}
                                        pdfRefs={pdfRefs}
                                        index={key}
                                        getClientPos={getClientPos}
                                        editingMode={editingMode}
                                        disable={disable}
                                        status={status}
                                        annotationStorages={annotationStorages}
                                    />
                                ))}
                        </Document>
                    </div>
                </div>
                {view === FORM && (
                    <div
                        className="public-template-form-view"
                        style={{ width: "35vw" }}
                    >
                        <TemplateForm scrollToPage={scrollToPage} />
                    </div>
                )}
            </>
        )
    },
    areEqual
)

function areEqual(prevprops, nextProps) {
    if (
        prevprops.documentUrl !== nextProps.documentUrl ||
        prevprops.activePage !== nextProps.activePage ||
        prevprops.signature !== nextProps.signature
    )
        return false

    return true
}

const EachPage = ({
    pdfRefs,
    index,
    getClientPos,
    editingMode,
    disable,
    status,
    annotationStorages,
}) => {
    const [pageSize, setPageSize] = React.useState({ height: 0, width: 0 })
    return (
        <div style={{ opacity: `${status === CANCELLED ? 0.15 : 1}` }}>
            <SignatureItems pageIndex={index} pdfRefs={pdfRefs} pageSize={pageSize}>
                <Page
                    pageNumber={index + 1}
                    key={index}
                    enableAnotation
                    setPageSize={setPageSize}
                    annotationStorages={annotationStorages}
                >
                    <DragItemCatcher
                        pageIndex={index}
                        getClientPos={() => getClientPos(index)}
                        name={`pdf-page-render-${index}`}
                        setIsMouseOverElement={() => {}}
                        editingMode={editingMode}
                        disable={disable}
                        pageSize={pageSize}
                    />
                </Page>
            </SignatureItems>
        </div>
    )
}

const mapStateToProps = ({ userReducer, signReducer }) => ({
    token: userReducer.token,
    activeTool: signReducer.activeTool,
    isPdfPageView: signReducer.isPdfPageView,
    documentUrl: signReducer.documentDetails.documentUrl,
    pdfPages: signReducer.pdfPages,
    disable: signReducer.disable,
    editingMode: signReducer.editingMode,
    status: signReducer.status,
    signature: signReducer.signature,
    view: signReducer.documentDetails?.view,
})

export default connect(mapStateToProps)(PdfEditor)
