import React from "react"
import { useStyles } from "./SendForSignAnimationStyle"
import sendingCompleted from "Assets/PdfEditor/sending-completed.json"
import Lottie from "react-lottie"

const defaultOptions = {
    loop: false,
    animationData: sendingCompleted,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
}

const SendForSignAnimation = ({setIsSentForSign}) => {
    const classes = useStyles()
    return (
        <div className={classes.sendForSignAnimationWrapper}>
            <div className={classes.sendForSignAnimationModal}>
                <Lottie
                    options={defaultOptions}
                    height={"100%"}
                    width={"55%"}
                    eventListeners={[
                        {
                            eventName: "complete",
                            callback: () => setIsSentForSign(false),
                        },
                    ]}
                />
            </div>
        </div>
    )
}

export default SendForSignAnimation
