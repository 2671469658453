import React, { useEffect, useState } from "react"
import "./EditPdf.scss"
import ProfileWithName from "../../../../Awesome-ui/ProfileWithName"

import Input from "../../../../Awesome-ui/Input/Input"
import { connect } from "react-redux"
import {
    setActiveTool,
    setSendForSignatureType,
} from "../../../../Action/signAction"
import {
    addSignerInDoc,
    deleteSigner,
    resendEmailToSigner,
} from "Action/signersActions"
import { SignatureDragType } from "Components/PDFCommons/container/Types"

import { ReactComponent as SignIcon } from "Assets/ToolsIcons/sign.svg"
import { ReactComponent as DateIcon } from "Assets/ToolsIcons/dateIcon.svg"
import { ReactComponent as TextIcon } from "Assets/ToolsIcons/textIcon.svg"
import { ReactComponent as AvatarNameIcon } from "Assets/ToolsIcons/avatarNameIcon.svg"
import { ReactComponent as EmailIcon } from "Assets/ToolsIcons/emailIcon.svg"
import { ReactComponent as CheckboxIcon } from "Assets/ToolsIcons/checkboxIcon.svg"
import { ReactComponent as CompanyIcon } from "Assets/ToolsIcons/companyIcon.svg"

import {
    CANCELLED,
    CLICK_FOR_SIGN,
    DANGER,
    DRAFT,
    DRAW_SIGNATURE,
    SUCCESS,
} from "Types"
import {
    Grid,
    Button as Button1,
    Text,
    Button,
    Dropdown,
} from "@awesomesuite-frontend/awesome-nebula"
import { createUseStyles } from "react-jss"
import ToggleButton from "Awesome-ui/ToggleButton/ToggleButton"
import { ReactComponent as SetSigningIcon } from "../../../../Assets/PDFCommons/set-signing.svg"
import { ReactComponent as DocForwardingIcon } from "../../../../Assets/PDFCommons/doc-forwarding.svg"
import { setWorkspaceUsers } from "Action/workspaceAction"
import DragSign from "Components/PDFCommons/container/DragElements/SignerDragItems/DragSign/DragSign"
import { createMessage } from "Action/messageAndNotificationActions"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"
import { ReactComponent as CopyIcon } from "Assets/ToolsIcons/copy-icon.svg"
import { getLinkToService } from "data/ProductMapping"
import SignerDragItems from "Components/PDFCommons/container/DragElements/SignerDragItems/SignerDragItems"

const useStyle = createUseStyles((theme) => ({
    button: {
        textAlign: "center",
        borderRadius: 4,
        justifyContent: "center",
    },
    addSignerButton: {
        border: "1px solid #d6dfe8",
        backgroundColor: "#fff",
        "& span": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        },
        "&:hover": {
            boxShadow: "0px 3px 6px #d6dfe8",
            opacity: 1,
        },
    },
    buttonIcon: {
        marginRight: "0.5rem",
        backgroundColor: "#00BDA5",
        borderRadius: "50%",
        height: "1.5rem",
        width: "1.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5rem",
    },
    signerEmailOptions: {
        display: "flex",
        width: "90%",
        margin: "1rem auto",
        marginTop: "2.5rem",
    },
    signerEmailOptionsInfo: {
        paddingLeft: "0.5rem",
    },
    addField: {
        padding: "0.5rem",
        backgroundColor: "#fff",
        margin: "8px",
        borderRadius: "4px",
        border: "1px dashed #d6dfe8",
        cursor: "all-scroll",
        display: "flex",
        alignItems: "center",
    },
    addDrag: {
        width: "10px",
        height: "30px",
        border: "3px dotted #d6dfe8",
        borderTop: "none",
        borderBottom: "none",
        marginRight: "0.5rem",
    },
    addSignerButtonWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    linkHandlers: {
        display: "flex",
        justifyContent: "end",
        marginTop: "1rem",
    },
    linkHandlerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
            marginRight: "0.5rem",
        },
    },
    copied: {
        "& svg circle": {
            fill: "#d6dfe8",
        },
    },
    eachSignerFields: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "1rem",
        alignItems: "stretch",
        justifyContent: "space-between",
    },
}))

const signerElements = [
    {
        type: SignatureDragType.SIGNER_FIELD,
        subType: "Signature",
        Icon: SignIcon,
    },
    {
        type: SignatureDragType.DATE,
        subType: "Date",
        Icon: DateIcon,
    },
    {
        type: SignatureDragType.TEXT,
        subType: "Name",
        Icon: AvatarNameIcon,
    },
    {
        type: SignatureDragType.TEXT,
        subType: "Text",
        Icon: TextIcon,
    },
    {
        type: SignatureDragType.TEXT,
        subType: "Email",
        Icon: EmailIcon,
    },
    {
        type: SignatureDragType.CHECKBOX,
        subType: "Checkbox",
        Icon: CheckboxIcon,
    },
    {
        type: SignatureDragType.TEXT,
        subType: "Company",
        Icon: CompanyIcon,
    },
]

function Signer({
    id,
    firstName,
    lastName,
    emailId,
    properties,
    index,
    signerFields,
    disable,
    backgroundDecorum,
    status,
    signerStatus,
    hash,
    enableTemplateFlow,
    roles,
    roleId,
}) {
    const [isCopied, setIsCopied] = useState(false)
    const [isResending, setIsResending] = useState(false)
    const [isResent, setIsResent] = useState(false)

    let copyTimeOut = null,
        resentTimeOut = null

    function copyLink() {
        navigator.clipboard.writeText(
            `${getLinkToService("sign").split("/login")[0]}/sign/${hash}/`
        )
        setIsCopied(true)
        createMessage(
            "Link for specified signer has been copied",
            "The link has been copied to the clipboard",
            SUCCESS
        )
    }

    async function resendEmail() {
        setIsResending(true)
        const isResentNow = await resendEmailToSigner(id)
        setIsResending(false)
        setIsResent(isResentNow)
    }

    React.useEffect(() => {
        if (isCopied)
            copyTimeOut = setTimeout(() => {
                setIsCopied(false)
            }, 2000)
        return () => {
            clearTimeout(copyTimeOut)
        }
    }, [isCopied])

    React.useEffect(() => {
        if (isResent)
            resentTimeOut = setTimeout(() => {
                setIsResent(false)
            }, 2000)
        return () => {
            clearTimeout(resentTimeOut)
        }
    }, [isResent])

    const classes = useStyle()

    if (roleId)
        backgroundDecorum =
            roles?.find((role) => role.id === roleId)?.backgroundDecorum ||
            backgroundDecorum

    return (
        <div className="signer-card">
            {!disable && (
                <div className="signer-card-close" onClick={() => deleteSigner(id)}>
                    ✕
                </div>
            )}
            <div
                className="added-signer-card"
                style={{
                    border: `1px solid ${backgroundDecorum?.border}`,
                    backgroundColor: `${backgroundDecorum?.back}`,
                }}
            >
                <Text varient="subText" className="added-signature-title">
                    {roles?.find((role) => role.id === roleId)?.roleName ||
                        `Signer ${index + 1}`}
                </Text>
                <div className="added-signer-details">
                    <ProfileWithName
                        title={`${firstName} ${lastName}`}
                        subText={emailId}
                        imgVarient="round"
                        imgHeight={"40px"}
                        imgWidth={"40px"}
                        fontSize={14}
                        subTextStyle={{ marginTop: "8px" }}
                        style={{ margin: "6px" }}
                    />
                </div>
                {!disable && !enableTemplateFlow && (
                    <div className="added-signer-additional">
                        <Text varient="subText" className="additional-title">
                            Additional Data fields
                        </Text>
                        {/* <div className={classes.addField}>
                            <DragSign signer={{ firstName, emailId, id }} />
                        </div> */}
                        <div className={classes.eachSignerFields}>
                            {signerElements.map((item) => (
                                <SignerDragItems
                                    {...item}
                                    signerDetails={{
                                        name: `${firstName} ${lastName}`,
                                        emailId: emailId,
                                        id: id,
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {status !== DRAFT && status !== CANCELLED && (
                    <div className={classes.linkHandlers}>
                        <div className={classes.linkHandler}>
                            <Button1
                                padding={"0.35rem 0"}
                                onClick={copyLink}
                                style={{
                                    borderRadius: 5,
                                    borderWidth: 1,
                                    backgroundColor: "#f4f7f9",
                                    width: 85,
                                    opacity: ["REJECTED", "PENDING"].includes(
                                        signerStatus
                                    )
                                        ? 0.7
                                        : 1,
                                    cursor: ["REJECTED", "PENDING"].includes(
                                        signerStatus
                                    )
                                        ? "not-allowed"
                                        : "pointer",
                                    marginRight: "1rem",
                                }}
                                color="#d6dfe8"
                                varient="outline"
                                disabled={
                                    ["REJECTED", "PENDING"].includes(signerStatus) ||
                                    isCopied
                                }
                            >
                                <div className={classes.linkHandlerContent}>
                                    <CopyIcon
                                        height={11}
                                        width={11}
                                        fill={"#33475B"}
                                    />
                                    <Text fontSize={11} style={{ flex: 1 }}>
                                        {isCopied ? "Copied" : "Copy Link"}
                                    </Text>
                                </div>
                            </Button1>
                        </div>
                        <div className={classes.linkHandler}>
                            <Button1
                                onClick={resendEmail}
                                padding={"0.35rem 0"}
                                style={{
                                    borderRadius: 5,
                                    border: "1px solid #7E89FF",
                                    backgroundColor: "#7E89FF",
                                    width: 85,
                                    opacity:
                                        ["SIGNED", "REJECTED", "PENDING"].includes(
                                            signerStatus
                                        ) || isResent
                                            ? 0.7
                                            : 1,
                                    cursor:
                                        ["SIGNED", "REJECTED", "PENDING"].includes(
                                            signerStatus
                                        ) || isResent
                                            ? "not-allowed"
                                            : "pointer",
                                }}
                                disabled={
                                    ["SIGNED", "REJECTED", "PENDING"].includes(
                                        signerStatus
                                    ) || isResent
                                }
                            >
                                <div className={classes.linkHandlerContent}>
                                    <Text fontSize={11} color="#FFFFFF">
                                        {isResending
                                            ? "Resending..."
                                            : isResent
                                            ? "Email Resent"
                                            : "Resend Email"}
                                    </Text>
                                </div>
                            </Button1>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

function AddSignerCard({
    closeTab,
    suggestionList = { firstNameList: [], lastNameList: [], emailList: [] },
    workspaceUsers,
    folderId,
    folderList,
    signers,
    signTypeSelected,
    roles = [],
    enableTemplateFlow,
}) {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [lastName, setLastName] = useState("")
    const [loading, setloading] = useState(false)
    const [roleAssignedToSigner, setRoleAssignedToSigner] = useState(null)
    const classes = useStyle()
    const dropdownContent =
        enableTemplateFlow &&
        roles.map((role) => ({
            name: <Text>{role.roleName}</Text>,
            value: role.id,
        }))

    async function addSigner() {
        if (!email)
            return createMessage(
                "Please enter email",
                "Enter the email of the signer you want to add",
                DANGER
            )
        if (!name || !lastName)
            return createMessage(
                "Please enter name properly",
                `Enter the ${name ? "" : "first name"} ${
                    !name && !lastName ? "and" : ""
                } ${lastName ? "" : "last name"} of the signer you want to add`,
                DANGER
            )
        if (signers.find((signer) => signer.emailId === email)) {
            createMessage(
                "Signer is already added.",
                "A signer can be added only once per document",
                DANGER
            )
            return
        }

        if (enableTemplateFlow && !roleAssignedToSigner) {
            createMessage(
                "Please select a role for the signer",
                "Select a role for the signer you want to add",
                DANGER
            )
            return
        }

        const userId = workspaceUsers.find((user) => user.email_id === email)?.id
        const data = {
            firstName: name.trim(),
            lastName: lastName.trim(),
            email: email.trim(),
            roleId: roleAssignedToSigner,
            typeOfSignature: signTypeSelected,
            properties: [
                {
                    id: 1,
                    name: "Signer Name",
                    enabled: true,
                },
                {
                    id: 2,
                    name: "Date Signed",
                    enabled: true,
                },
                {
                    id: 3,
                    name: "Signer Email",
                    enabled: true,
                },
            ],
        }

        if (userId) data.userId = userId

        setloading(true)
        await addSignerInDoc(data)
        setloading(false)
        setName("")
        setEmail("")
        setLastName("")
        closeTab()
    }

    const onSuggestionClick = async (suggestionEmail) => {
        const key = suggestionList.emailList.indexOf(suggestionEmail)
        if (key !== -1) {
            setEmail(suggestionList.emailList[key])
            setName(suggestionList.firstNameList[key])
            setLastName(suggestionList.lastNameList[key])
        }
    }

    return (
        <div className="signer-card">
            <div className="added-signer-card">
                <Text style={{ marginBottom: "1rem" }}>New Signer</Text>
                <Input
                    placeHolder="Email address"
                    style={{ width: "100%", marginBottom: "12px" }}
                    value={email}
                    changeHandler={(val) => {
                        setEmail(val)
                    }}
                    suggestionList={suggestionList.emailList}
                    setSuggestionvalue={onSuggestionClick}
                />
                <Input
                    placeHolder="First Name"
                    style={{ width: "100%", marginBottom: "12px" }}
                    value={name}
                    changeHandler={(val) => {
                        setName(val)
                    }}
                    // suggestionList={suggestionList.firstNameList}
                />
                <Input
                    placeHolder="Last Name"
                    style={{ width: "100%", marginBottom: "12px" }}
                    value={lastName}
                    changeHandler={(val) => {
                        setLastName(val)
                    }}
                    // suggestionList={suggestionList.lastNameList}
                />
                {enableTemplateFlow && (
                    <div className="signer-role-selector">
                        <Dropdown
                            dropdown={{ dropdownContent }}
                            value={roleAssignedToSigner}
                            onChange={setRoleAssignedToSigner}
                            placeHolder="Select Role for Signer"
                        />
                    </div>
                )}
                <Button1
                    className={classes.button}
                    onClick={addSigner}
                    loading={loading}
                    width="100%"
                    radius={2}
                    // padding={"12px 34%"}
                >
                    <Text color="#fff" align="center" fontSize={14}>
                        ADD SIGNEE
                    </Text>
                </Button1>
            </div>
        </div>
    )
}

const signTypeOptions = {
    dropdownContent: [
        {
            value: CLICK_FOR_SIGN,
            name: <Text fontSize={14}>Click Sign</Text>,
        },
        {
            value: DRAW_SIGNATURE,
            name: <Text fontSize={14}>Draw Sign</Text>,
        },
    ],
}

const EditPdf = ({
    signers,
    status,
    signatureType,
    signature,
    isSetSigning,
    setIsSetSigning,
    isDocForwarding,
    setIsDocForwarding,
    workspaceUsers,
    folderList,
    folderId,
    roles,
    enableTemplateFlow,
}) => {
    const [signFieldsData, setsignFieldsData] = useState({})
    const classes = useStyle()
    const [enableAddMore, setenableAddMore] = useState(false)
    const [suggestionList, setSuggestionList] = useState(null)
    const [signTypeSelected, setSignTypeSelected] = useState(
        enableTemplateFlow ? DRAW_SIGNATURE : CLICK_FOR_SIGN
    )

    useEffect(() => {
        if (!signature) return

        const signObject = {}
        const filteredSigns = Object.values(signature).filter(
            (item) => item.type !== SignatureDragType.SIGNER_FIELD
        )

        filteredSigns.forEach((page, index) => {
            const pageNumber = Object.keys(page)[0]

            Object.values(page)?.map((item) => {
                const { data } = item
                if (!data) return

                if (!signObject[data.signerId]) {
                    signObject[data.signerId] = []
                }
                signObject[data?.signerId].push({ pageNumber: pageNumber, ...item })
            })
        })

        setsignFieldsData(signObject)
    }, [signature])

    useEffect(() => {
        const init = async () => {
            if (!workspaceUsers) {
                await setWorkspaceUsers(false)
            } else {
                const firstNameList = workspaceUsers.map((user) => user.first_name)
                const lastNameList = workspaceUsers.map((user) => user.last_name)
                const emailList = workspaceUsers.map((user) => user.email_id)
                setSuggestionList({ firstNameList, lastNameList, emailList })
            }
        }
        if (status === DRAFT) init()
    }, [workspaceUsers])

    return (
        <div className="pdf-signers-edit">
            <div className="signers-top">
                <Text fontSize={14}>Signature Settings</Text>
                <Dropdown
                    dropdown={signTypeOptions}
                    value={signTypeSelected}
                    onChange={setSignTypeSelected}
                    className={"pdf-signers-edit-options"}
                    disabled={signers.length > 0 || status !== DRAFT}
                />
            </div>
            <div className="signers-main-component">
                {Array.isArray(signers) &&
                    signers.map((item, index) => (
                        <Signer
                            {...item}
                            key={item.id}
                            index={index}
                            signerFields={signFieldsData}
                            disable={status !== DRAFT}
                            status={status}
                            enableTemplateFlow={enableTemplateFlow}
                            roles={roles}
                        />
                    ))}
            </div>
            {status === DRAFT && (
                <>
                    {enableAddMore && (
                        <div className="signers-main-component">
                            <AddSignerCard
                                closeTab={() => setenableAddMore(false)}
                                suggestionList={suggestionList}
                                workspaceUsers={workspaceUsers}
                                folderId={folderId}
                                folderList={folderList}
                                signers={signers}
                                signTypeSelected={signTypeSelected}
                                roles={roles}
                                enableTemplateFlow={enableTemplateFlow}
                            />
                        </div>
                    )}
                    {/* <Grid container justify="center" marginBottom="12px"> */}
                    <TooltipWrapper
                        wrapperClassName={classes.addSignerButtonWrapper}
                        text={"Add Signers"}
                        direction={"top"}
                    >
                        <Button
                            className={`${classes.button} ${classes.addSignerButton}`}
                            width="90%"
                            onClick={() => setenableAddMore((prev) => !prev)}
                            padding={"0.5rem 1rem"}
                        >
                            <div className={classes.buttonIcon}>+</div>
                            <Text weight={500} fontSize={14}>
                                Add signer to document
                            </Text>
                        </Button>
                    </TooltipWrapper>
                    {/* </Grid> */}
                </>
            )}
            <div className={`${classes.signerEmailOptions}`}>
                <div className={`${classes.signerEmailOptionsIcon}`}>
                    <SetSigningIcon />
                </div>
                <div className={`${classes.signerEmailOptionsInfo}`}>
                    <Text varient="h6" style={{ marginBottom: "0.25rem" }}>
                        Set signing order
                    </Text>
                    <Text varient="subText">
                        Allows document to be signed in the order mentioned above.
                        You can drag and drop to arrange the order to your
                        preference.
                    </Text>
                </div>
                <div className={`${classes.signerEmailOptionsButton}`}>
                    <ToggleButton
                        isChecked={isSetSigning}
                        setIsChecked={setIsSetSigning}
                        isDisabled={status !== DRAFT}
                    />
                </div>
            </div>
            <div className={`${classes.signerEmailOptions}`}>
                <div className={`${classes.signerEmailOptionsIcon}`}>
                    <DocForwardingIcon />
                </div>
                <div className={`${classes.signerEmailOptionsInfo}`}>
                    <Text varient="h6" style={{ marginBottom: "0.25rem" }}>
                        Document forwarding
                    </Text>
                    <Text varient="subText">
                        Allows you to forward this document to another recipient.
                        They will be added as a CC.
                    </Text>
                </div>
                <div className={`${classes.signerEmailOptionsButton}`}>
                    <ToggleButton
                        isChecked={isDocForwarding}
                        setIsChecked={setIsDocForwarding}
                        isDisabled={status !== DRAFT}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ signReducer, workspaceReducer, foldersReducer }) => ({
    signers: signReducer.signers,
    signatureType: signReducer.signatureType,
    signature: signReducer.signature,
    folderId: signReducer.documentDetails?.folderId,
    workspaceUsers: workspaceReducer?.users,
    folderList: foldersReducer?.folderList,
    roles: signReducer.roles,
    enableTemplateFlow: signReducer.documentDetails?.enableTemplateFlow,
})

export default connect(mapStateToProps)(EditPdf)
