import {
    CircleSkeletonLoader,
    ContainerSkeletonLoader,
    LineSkeletonLoader,
} from "@awesomesuite-frontend/awesome-nebula"
import React from "react"

const RecentDocumentsSkeleton = () => {
    return (
        <>
            {Array(4)
                .fill()
                .map((_, i) => (
                    <ContainerSkeletonLoader
                        width="100%"
                        key={i}
                        style={{
                            display: "flex",
                            gap: "1rem",
                            backgroundColor: "#fff",
                            border: "1px solid #d6dfe8",
                            marginBottom: "1rem",
                        }}
                    >
                        <CircleSkeletonLoader width="45px" height="45px" />
                        <div style={{ flex: "1" }}>
                            <LineSkeletonLoader width="40%" height="1rem" />
                            <LineSkeletonLoader width="80%" height="1.25rem" />
                        </div>
                    </ContainerSkeletonLoader>
                ))}
        </>
    )
}

export default RecentDocumentsSkeleton
