import React, { Fragment } from "react"
import { useStyles } from "./TemplatesStyle"
import { Helmet } from "react-helmet"
import TemplatesLeft from "./components/TemplatesLeft/"
import TemplatesRight from "./components/TemplatesRight/"

const Templates = () => {
    const classes = useStyles()
    return (
        <Fragment>
            <Helmet>
                <title>Templates | Awesome Sign</title>
            </Helmet>
            <div className={classes.templatesPageWrapper}>
                <div className={classes.templatesPageLeft}>
                    <TemplatesLeft />
                </div>
                <div className={classes.templatesPageRight}>
                    <TemplatesRight />
                </div>
            </div>
        </Fragment>
    )
}

export default Templates
