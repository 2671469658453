import React, { useCallback } from "react"
import GuardContext from "../GuardContext"

const style = { pointerEvents: "none", userSelect: "none", opacity: 0.5 }

const GuardHandler = ({
    scope,
    show,
    opacity,
    role,
    permissions,
    children,
    FallbackUi = <></>,
    forceBlock,
}) => {
    const disableEvent = useCallback((e) => {
        e.persist()
        e.preventDefault()
        e.stopPropagation()
    }, [])

    
    if (forceBlock === true) return FallbackUi ? FallbackUi : null
    if (forceBlock === false) return children

    if (!role || !permissions) return FallbackUi ? FallbackUi : null

    if (!permissions[role] || !permissions[role][scope])
        return FallbackUi ? FallbackUi : null

    if (permissions[role][scope].Enable) return children

    function handleChild(element) {
        if (!React.isValidElement(element)) return FallbackUi ? FallbackUi : null
        return React.cloneElement(element, {
            ...element.props,
            onClick: () => {},
        })
    }

    if (show) {
        return React.createElement(
            "div",
            {
                style: { ...style, opacity: opacity || 0.5 },
                onClick: disableEvent,
                onKeyDown: disableEvent,
            },
            React.Children.map(children, handleChild)
        )
    }

    return FallbackUi ? FallbackUi : null
}

/**
 * Roles and Permission Guard component. you can wrap this component and
 * provide scope and other properties. please use GruradContext.Provider
 * to make this component work.
 *
 * @param {*} scope name of the permission.
 * @param {*} show pass it if you want to hide instead of removing component.
 * @param {*} opacity control the opacity of the hidden component.
 *
 * @returns Guard component.
 */
const Guard = ({ scope, show, opacity, ...props }) => {
    return (
        <GuardContext.Consumer>
            {(context) => (
                <GuardHandler
                    scope={scope}
                    opacity={opacity}
                    show={show}
                    {...context}
                    {...props}
                />
            )}
        </GuardContext.Consumer>
    )
}

export default Guard
