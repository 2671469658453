import { createUseStyles } from "react-jss"

export const useStyles = createUseStyles((theme) => ({
    templatesPageWrapper: {
        display: "flex",
        position: "relative",
    },
    templatesPageLeft: {
        position: "sticky",
        width: "Max(15%, 250px)",
        backgroundColor: "#f4f7f9",
        borderRight: "1px solid #d6dfe8",
        top: 0,
        left: 0,
        height: "92vh",
        zIndex: 100,
        "@media screen and (max-width: 600px)": {
            width: 200,
        },
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    templatesPageRight: {
        flex: 1,
    },
}))
