export const GET_FOLDER_BY_ID = "GET_FOLDER_BY_ID"
export const GET_FOLDERS_ALL = "GET_FOLDERS_ALL"
export const SET_FOLDERS_LOADING = "SET_FOLDERS_LOADING"
export const SET_FOLDERS_LOADED = "SET_FOLDERS_LOADED"
export const ADD_NEW_FOLDER = "ADD_NEW_FOLDER"
export const ADD_DOCUMENT_TO_FOLDER = "ADD_DOCUMENT_TO_FOLDER"
export const SET_FOLDERS_TO_NULL = "SET_FOLDERS_TO_NULL"
export const SET_CURRENT_FOLDER = "SET_CURRENT_FOLDER"
export const GET_USER_BY_FOLDER_ID = "GET_USER_BY_FOLDER_ID"
export const ADD_NEW_TEAM_MEMBER = "ADD_NEW_TEAM_MEMBER"
export const DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER"
export const DELETE_DOCUMENT_FROM_FOLDER = "DELETE_DOCUMENT_FROM_FOLDER"
export const ADD_DOCUMENT_TO_SPECIFIC_FOLDER = "ADD_DOCUMENT_TO_SPECIFIC_FOLDER"