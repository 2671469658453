import Guard from "Awesome-ui/Guard"
import React from "react"
import Dashboard from "./Dashboard"

const index = (props) => {
    let search = window.location.search
    let hash = window.location.hash
    return (
        <Guard scope="dashboard_get">
            <div className="page-document">
                <div
                    className="dashboard-hide"
                    style={{
                        display:
                            search?.includes("oauth=") ||
                            hash?.includes("access_token=")
                                ? "block"
                                : "none",
                    }}
                ></div>
                <Dashboard {...props} />
            </div>
        </Guard>
    )
}

export default index
