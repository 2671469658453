import React from "react"
import ToolTip from "../ToolTip"

import "./optionSideNav.scss"

const OptionSidenav = React.memo(({ options = [], option, handleOptionClick }) => {
    const _C = "aui-post-manager-sidenav"

    return (
        <div className={_C}>
            {options &&
                options.map(({ id, title, Icon, active, isDisabled }, i) => (
                    <div
                        key={i}
                        className={`${_C}-option 
                        ${id === option ? _C + "-option-active" : ""}
                            ${isDisabled ? _C + "-option-disabled" : ""}
                        `}
                        onClick={() => handleOptionClick(id)}
                    >
                        {Icon ? (
                            typeof Icon === "string" ? (
                                <img src={Icon} alt={title} />
                            ) : (
                                <Icon />
                            )
                        ) : (
                            <div></div>
                        )}
                        <ToolTip
                            direction="left"
                            name={title.toUpperCase()}
                        />
                    </div>
                ))}
        </div>
    )
})

export default OptionSidenav;
