import {
    GET_USER_DETAILS_LOADING,
    GET_USER_DETAILS_FAILED,
    GET_USER_DETAILS_SUCCESS,
    USER_LOGIN_LOADING,
    USER_LOGIN_FAILED,
    USER_LOGIN_SUCCESS,
    GLOABLE_LOADING,
    STOP_GLOABLE_LOADING,
} from "../ActionTypes/userActionTypes"

const initialState = {
    gloableLoading: false,
    /**
     * userDetails field is to store the user details like name,
     * phone number and avatar. call getUSerDetails api to get
     * these details.
     */
    userDetails: null,
    userDetailsLoading: false,
    /**
     * user login gives access token if it is called from the accounts redirection.
     * call getAccessToken Api to get app specific token
     */
    token: localStorage.getItem("auth-token"),
    redirectToken: localStorage.getItem("redirect-token"),
    userLoginLoading: false,
    userRole:""

}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_DETAILS_LOADING:
            return {
                ...state,
                userDetailsLoading: true,
            }
        case GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                userDetails: action.payload.user,
                userDetailsLoading: false,
            }
        case GET_USER_DETAILS_FAILED:
            return {
                ...state,
                userDetails: null,
                userDetailsLoading: false,
            }
        case USER_LOGIN_LOADING:
            return {
                ...state,
                userLoginLoading: true,
            }
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.token,
                redirectToken: action.payload.redirectToken,
                userDetails: action.payload.data,
                userRole:action.payload.userRole,
                userLoginLoading: false,
            }
        case USER_LOGIN_FAILED:
            return {
                ...state,
                token: null,
                userLoginLoading: false,
            }
        case GLOABLE_LOADING:
            return {
                ...state,
                gloableLoading: true,
            }
        case STOP_GLOABLE_LOADING:
            return {
                ...state,
                gloableLoading: false,
            }
        default:
            return state
    }
}
