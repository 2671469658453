import React, { useEffect, useState } from "react"
import TimeLineComponent from "../../../../Awesome-ui/TimeLineComponent"
import TimeLineConnector from "../../../../Awesome-ui/TimeLineConnector/TimeLineConnector"
import TimeLineContainer from "../../../../Awesome-ui/TimeLineContainer"
import TimeLineDetails from "../../../../Awesome-ui/TimeLineDetails"
import "./activity.scss"

import { ReactComponent as DoneIcon } from "../../../../Assets/ToolsIcons/ActivityStatus/done.svg"
import { ReactComponent as ViewIcon } from "../../../../Assets/ToolsIcons/ActivityStatus/view.svg"
import { ReactComponent as CreatedIcon } from "../../../../Assets/ToolsIcons/ActivityStatus/created.svg"
import { connect } from "react-redux"
import { getActivityLog } from "Action/messageAndNotificationActions"
import { useBasicUtils } from "hooks/BasicUtility/basicUtils"
import { Avatar, Text } from "@awesomesuite-frontend/awesome-nebula"
import IntersectionVisible from "react-intersection-visible"
import Loader from "Awesome-ui/Loader/Loader"
import { setWorkspaceUsers } from "Action/workspaceAction"
import { FINISHED, OUT_FOR_SIGNATURE } from "Types"

function getStatusIcon(eventType) {
    if (eventType === "CREATED") return <CreatedIcon />
    if (eventType === "FINISHED" || eventType === "SENT" || eventType === "SIGNED")
        return <DoneIcon />
    if (eventType === "REJECTED") return <ViewIcon fill="#d94c53" />
    return <ViewIcon />
}

function getStatusColor(eventType) {
    if (eventType === "CREATED") return "#FFAB40"
    if (eventType === "FINISHED" || eventType === "SENT" || eventType === "SIGNED")
        return "#00BF9D"
    if (eventType === "REJECTED") return "#d94c53"
    return "#6A78D1"
}

function PdfActivity({ id, time, user, email, action = "", eventType, splitText }) {
    return (
        <>
            <div>
                <Text
                    style={{ textTransform: "capitalize" }}
                    weight={600}
                    color={getStatusColor(eventType)}
                >
                    Document{" "}
                    {`${
                        eventType === OUT_FOR_SIGNATURE
                            ? "sent for sign"
                            : eventType.toLowerCase()
                    }`}
                </Text>
                <div className="doc-details">
                    <Avatar name={user} email={email} size="xs" />
                    <div className="doc-text-details">
                        <Text fontSize={15} weight={600} marginRight="1ch">
                            {user}
                        </Text>
                        <Text fontSize={14}>
                            {`${
                                eventType === OUT_FOR_SIGNATURE
                                    ? "sent for sign"
                                    : `${eventType.toLowerCase()}`
                            }` + " the document"}
                        </Text>
                    </div>
                </div>
                <Text color="light" fontSize={15} marginLeft={"0.25rem"}>
                    {`${time}`} ago
                </Text>
            </div>
        </>
    )
}

const Activity = ({ documentId, signers, workspaceUsers, status }) => {
    const { splitText } = useBasicUtils()
    const [pageNo, setPageNo] = useState(-1)
    const [disable, setDisable] = useState(false)
    const [activity, setActivity] = useState([])

    const activityFetcher = () => {
        if (disable) return
        setPageNo((prev) => prev + 1)
    }

    const getUserName = (user) => {
        if(!user) return "Anonymous"
        if (user.first_name && user.last_name)
            return `${user.first_name} ${user.last_name}`
        if (user.first_name) return user.first_name
        if (user.user_name) return user.user_name
        if (user.email_id) return user.email_id.split("@")[0]
        return "Anonymous"
    }

    const activityHelper = (activity, users = []) => {
        const userId = activity.message.actorId
        let user = users.find((u) => u.id === userId)
        console.log(user);
        if (!user && activity.message.signer) {
            user = {
                first_name: activity.message.signer.firstName,
                last_name: activity.message.signer.lastName,
                email_id: activity.message.signer.emailId,
                user_name: activity.message.signer.userName,
            }
        }

        const data = {
            id: activity.id,
            time: activity.message.time,
            user: getUserName(user),
            email: user?.email_id || "",
            action: activity.message.eventType,
            eventType: activity.eventType,
        }

        return data
    }

    useEffect(() => {
        async function getActivity() {
            const data = await getActivityLog(documentId, pageNo)
            if (data.length < 10) {
                setDisable(true)
            }

            let users = []
            if (!workspaceUsers) users = await setWorkspaceUsers(false)
            else users = workspaceUsers

            const currentActivity = data.map((activity) =>
                activityHelper(activity, users)
            )
            console.log(activity, currentActivity);
            setActivity((prev) => [...prev, ...currentActivity])
        }
        if (pageNo !== -1) getActivity()
    }, [pageNo])

    return (
        <div className="pdf-activity-bar">
            <div className="pdf-signers-box">
                <div className="signature-box">
                    <Text fontSize={14}>Signers</Text>
                </div>
                <div className="list-of-signee">
                    {signers &&
                        signers.map((item) => (
                            <div
                                className={`signee-${item.signerStatus}`}
                                key={item.id}
                            >
                                <Avatar
                                    width={"28px"}
                                    height={"28px"}
                                    name={`${item.firstName} ${item.lastName}`}
                                    email={item.emailId}
                                    isTooltip
                                    size="xs"
                                />
                            </div>
                        ))}
                </div>
            </div>
            <div className="pdf-signers-info">
                <Text fontSize={14}>
                    Here is an activity log of all the changes and updates associated
                    with this document
                </Text>
            </div>
            {status === FINISHED ? (
                <div className="pdf-signers-status">
                    <Text fontSize={14}>
                        🎉 All signers have signed the document
                    </Text>
                </div>
            ) : (
                <></>
            )}
            <div className="pdf-signers-main-activity-box">
                <TimeLineContainer>
                    {activity &&
                        activity.map((item, index) => (
                            <TimeLineComponent key={item.id}>
                                <TimeLineConnector
                                    icon={getStatusIcon(item.eventType)}
                                />
                                <TimeLineDetails>
                                    <PdfActivity {...item} splitText={splitText} />
                                </TimeLineDetails>
                            </TimeLineComponent>
                        ))}
                    <IntersectionVisible onShow={activityFetcher}>
                        {!disable && (
                            <div className="activity-intersector">
                                <Loader />
                            </div>
                        )}
                    </IntersectionVisible>
                </TimeLineContainer>
            </div>
        </div>
    )
}

const mapStateToProps = ({ signReducer, workspaceReducer }) => ({
    signers: signReducer.signers,
    documentId: signReducer.documentDetails?.documentId,
    workspaceUsers: workspaceReducer.users,
    status: signReducer.documentDetails?.status,
})

export default connect(mapStateToProps)(Activity)
