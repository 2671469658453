import { combineReducers } from "redux"
import { USER_SIGN_OUT } from "../ActionTypes/userActionTypes"
import { userReducer } from "./userReducer"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { settingsReducer } from "./settingsReducer"
import { messageAndNotificationReducer } from "./messageAndNotificationReducer";
import { signReducer } from "./signReducer";
import { documentReducer } from "./documentReducer";
import subscriptionReducer from "./subscriptionReducer"
import { workspaceReducer } from "./workspaceReducer"
import { foldersReducer } from "./folderReducer"
import { permissionsReducer } from "./permissionsReducer"
import { roleReducer } from "./roleReducer"

const permissionsPersist = {
    key: "permissions",
    storage,
    whitelist: ["permissions", "fill"],
}

const rolePersist = {
    key: "roles",
    storage,
    whitelist: ["role"],
}

const appReducer = combineReducers({
    userReducer,
    settingsReducer,
    messageAndNotificationReducer,
    signReducer,
    documentReducer,
    subscriptionReducer,
    workspaceReducer,
    foldersReducer,
    permissionsReducer: persistReducer(permissionsPersist, permissionsReducer),
    roleReducer: persistReducer(rolePersist, roleReducer),
})

/**
 * This is root state handler on sign out it will
 * clean all the data in all other reducer.
 *
 * @param state
 * @param action
 *
 */
const rootReducer = (state, action) => {
    if (action.type === USER_SIGN_OUT) {
        return { userReducer: {}, workspaceReducer:{}}
    }
    return appReducer(state, action)
}

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["settingsReducer"],
}

export default persistReducer(persistConfig, rootReducer)
