export const getLinkToService = (serviceName) => {
    const varient = process.env.REACT_APP_ENV
    const normal = serviceName?.toLowerCase()

    switch (varient) {
        case "production":
            return getProdLinks(normal)
        case "release":
            return getReleaseLinks(normal)
        case "dev":
            return getDevLinks(normal)
        case "dev-local":
            return getDevLocalLinks(normal)
        default:
            return getDevLinks(normal)
    }
}

const getProdLinks = (serviceName) => {
    switch (serviceName) {
        case "social":
            return "https://social.byawesome.com/login"
        case "sign":
            return "https://sign.byawesome.com/login"
        case "desk":
            return "https://desk.byawesome.com/login"
        case "dial":
            return "https://7ajvglr8rg0.typeform.com/to/aMdaUxrJ"
        case "send":
            return "https://7ajvglr8rg0.typeform.com/to/SrXCBtvI"
        case "checkout":
            return "https://billing.byawesome.com"
        case "account":
            return "https://my.awesomesuite.com"
        default:
            return "/"
    }
}

const getReleaseLinks = (serviceName) => {
    switch (serviceName) {
        case "social":
            return "https://social.betaawesome.com/login"
        case "sign":
            return "https://sign.betaawesome.com/login"
        case "desk":
            return "https://desk.betaawesome.com/login"
        case "dial":
            return "https://7ajvglr8rg0.typeform.com/to/aMdaUxrJ"
        case "send":
            return "https://7ajvglr8rg0.typeform.com/to/SrXCBtvI"
        case "checkout":
            return "https://billing.awesomesuite.com/"
        case "account":
            return "https://my.betaawesome.com"
        default:
            return "/"
    }
}

const getDevLinks = (serviceName) => {
    switch (serviceName) {
        case "social":
            return "https://social-dev.betaawesome.com/login"
        case "sign":
            return "https://sign-dev.betaawesome.com/login"
        case "desk":
            return "https://desk-dev.betaawesome.com/login"
        case "dial":
            return "https://dial-dev.betaawesome.com/login"
        case "send":
            return "https://send-dev.betaawesome.com/login"
        case "checkout":
            return "https://billing-dev.betaawesome.com"
        case "account":
            return "http://my-dev.betaawesome.com"
        default:
            return "/"
    }
}

const getDevLocalLinks = (serviceName) => {
    switch (serviceName) {
        case "social":
            return "http://localhost:3000/login"
        case "sign":
            return "http://localhost:3000/login"
        case "desk":
            return "http://localhost:3000/login"
        case "dial":
            return "http://localhost:3000/login"
        case "checkout":
            return "http://localhost:3000"
        default:
            return "/"
    }
}
