import { applyMiddleware, createStore } from "redux"
import reduxLogger from "redux-logger"
import reducers from "./reducers/reducers"
import { persistStore } from "redux-persist"

const middleware = []
// const middleware = [reduxLogger]

export const store = createStore(reducers, applyMiddleware(...middleware))

export const persister = persistStore(store)
