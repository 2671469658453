import {
    CLEAR_EVERYTHING,
    PERMISSION_FAILED,
    PERMISSION_LOADING,
    PERMISSION_SUCCESS,
    UPDATE_PERMISSIONS,
    PERMISSION_FAILED_INTERNAL_AUTH,
    LOAD_USER_PERMISSIONS,
    USER_PERMISSIONS_FAIL,
    USER_PERMISSIONS_SUCCESS,
} from "../ActionTypes/permissionActionType"

const initialState = {
    fill: false,
    permissionsObj: {},
    permissions: {},
    userPermission: null,
    loading: true,
    isNewUser: false,
    userPermissionsLoading: false,
}

export const permissionsReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case CLEAR_EVERYTHING:
                return {
                    fill: false,
                    permissionObj: {},
                    permissions: {},
                    loading: false,
                }
            case PERMISSION_LOADING:
                return {
                    ...state,
                    loading: true,
                }
            case PERMISSION_SUCCESS:
                return {
                    ...state,
                    permissionsObj: action.payload.permissionsObj,
                    permissions: action.payload.permissions,
                    loading: false,
                    fill: true,
                    isNewUser: false,
                }
            case UPDATE_PERMISSIONS:
                const updatePermission = [...state.permissionsObj]
                const updateIndex = updatePermission.findIndex(
                    (item) =>
                        item.company_id.id === parseInt(action.payload.company_id)
                )
                const permissionIndex = updatePermission[
                    updateIndex
                ].permission.findIndex((item) => item.Id === action.payload.data.Id)
                updatePermission[updateIndex].permission[permissionIndex] =
                    action.payload.data

                return {
                    ...state,
                    permissionsObj: updatePermission,
                    isNewUser: false,
                }
            case PERMISSION_FAILED:
                return {
                    ...state,
                    loading: false,
                    fill: false,
                    isNewUser: false,
                }
            case PERMISSION_FAILED_INTERNAL_AUTH:
                return {
                    ...state,
                    loading: false,
                    fill: false,
                    isNewUser: true,
                }
            case LOAD_USER_PERMISSIONS:
                return {
                    ...state,
                    userPermissionsLoading: true,
                }
            case USER_PERMISSIONS_FAIL:
                return {
                    ...state,
                    userPermissionsLoading: false,
                }
            case USER_PERMISSIONS_SUCCESS:
                return {
                    ...state,
                    userPermissionsLoading: false,
                    userPermission: action.payload.permissions,
                }
            default:
                return state
        }
    } catch (err) {}
}
