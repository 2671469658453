export const GET_DOCUMENT_BY_ID = "GET_DOCUMENT_BY_ID";
export const GET_DOCUMENT_BY_ID_FAILED = "GET_DOCUMENT_BY_ID_FAILED";

export const GET_LIST_OF_DOCUMENTS = "GET_LIST_OF_DOCUMENTS";
export const GET_LIST_OF_DOCUMENTS_FAILED = "GET_LIST_OF_DOCUMENTS_FAILED";
export const GET_LIST_OF_DOCUMENT_LOADING = "GET_LIST_OF_DOCUMENT_LOADING";

export const GET_DOCUMENTS_WITH_STATUS = "GET_DOCUMENTS_WITH_STATUS";
export const GET_DOCUMENTS_WITH_STATUS_FAILED = "GET_DOCUMENTS_WITH_STATUS_FAILED";
export const GET_DOCUMENT_WITH_STATUS_LOADING = "GET_DOCUMENT_WITH_STATUS_LOADING";
export const GET_DOCUMENTS_RECIEVED_FOR_SIGN = "GET_DOCUMENTS_RECIEVED_FOR_SIGN";

export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const ADD_DOCUMENT_FAILED = "ADD_DOCUMENT_FAILED";

export const UPDATE_DOCUMENT_DETAILS = "UPDATE_DOCUMENT_DETAILS";
export const UPDATE_DOCUMENT_DETAILS_LOADING = "UPDATE_DOCUMENT_DETAILS_LOADING";
export const UPDATE_DOCUMENT_DETAILS_FAILED = "UPDATE_DOCUMENT_DETAILS_FAILED";

export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

export const START_DOCUMENT_SIGNING = "START_DOCUMENT_SIGNING";
export const REJECT_DOCUMENT_SIGNING = "REJECT_DOCUMENT_SIGNING";

export const ADD_SIGNERS_TO_DOC = "ADD_SIGNERS_TO_DOC";