import React, { Component } from "react"

import AppBar from "../../Awesome-ui/AppBar/AppBar"
import Form from "./components/signUp"

import "./components/signUp.scss"

class SignUp extends Component {
    onSubmitForm = async (body) => {}

    render() {
        return (
            <div className="signUp">
                <AppBar />
                <Form
                    loading={this.props.loading}
                    onSubmitForm={this.onSubmitForm}
                />
            </div>
        )
    }
}

export default SignUp
