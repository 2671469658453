import React, { useState } from "react";
import DropDownOptions from "../DropDownOptions/DropDownOptions";
import DropDownSelectedItem from "..//DropDownSelectedItem/DropDownSelectedItem";

import "./DropdownContainer.scss";

/**
 * This container used in  SidenavTwoTopItems, SidenavTwoProfileContainer etc.
 *
 * This component is for create a Dropdown
 *
 * @param {Object} dropdown
 * @param {Boolean} showOnlyIconInSelect
 * @param {String} selectedItemColor
 *
 */

const DropdownContainer = ({
  dropdown,
  showOnlyIconInSelect,
  selectedItemColor,
}) => {
  const { title, dropdownContent } = dropdown;

  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(1);

  if (dropdownContent) {
    var selectedItem = dropdownContent.find(
      (dropdown) => dropdown.id === selectedItemId
    );
  }

  // Change Selection
  const changeSelectedItem = (id) => {
    setSelectedItemId(id);
    setShowDropDown(false); // Close when selected
  };

  const dropdownOptionsData = dropdownContent.filter(
    (dropdown) => dropdown.id !== selectedItemId
  );

  return (
    <div className="dropdown-container">
      <p>{title && title}</p>
      <DropDownSelectedItem
        selectedItem={selectedItem}
        showDropDown={showDropDown}
        setShowDropDown={setShowDropDown}
        showOnlyIconInSelect={showOnlyIconInSelect}
        selectedItemColor={selectedItemColor}
      />
      {showDropDown && dropdownContent && (
        <DropDownOptions
          dropdownContent={dropdownOptionsData}
          changeSelectedItem={changeSelectedItem}
          setShowDropDown={setShowDropDown}
          showOnlyIconInSelect={showOnlyIconInSelect}
        />
      )}
    </div>
  );
};

export default DropdownContainer;
