import React from "react"
import PermissionDeniedSubComponent from "./PermissionDeniedSubComponent"
import { useStyles } from "./PermissionDeniedStyle"
import { connect } from "react-redux"

const PermissionDenied = ({ sideBarType, sideBarColor, sideExpand, userRole }) => {
    const classes = useStyles()
    return (
        <div className={classes.permissionDeniedWrapper}>
            <PermissionDeniedSubComponent />
        </div>
    )
}

const mapStateToProps = ({ settingsReducer, userReducer }) => ({
    sideBarType: settingsReducer.sideBarType,
    sideBarColor: settingsReducer.sideBarColor,
    sideExpand: settingsReducer.sideExpand,
    userRole: userReducer.userRole,
})

export default connect(mapStateToProps)(PermissionDenied)
