import React, { isValidElement } from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as UpgradeIcon } from "./dropdownTree.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import Guard from "Awesome-ui/Guard"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"

const useStyles = createUseStyles(() => ({
    treeComponent: {
        width: "100%",
    },
    treeHeader: {
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        "&:hover": {
            color: "#33475b",
            backgroundColor: (isAddBranch) =>
                isAddBranch ? " transparent" : "#EAF0F670",
            borderRadius: "6px",
        },
        padding: "0.25rem 1rem",
    },
    treeComponentChildren: {
        paddingLeft: ({ levelPadding }) => levelPadding || "1rem",
    },
    treeDropdownLogo: {
        marginRight: "0.5rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& svg": {
            transform: "rotate(-90deg)",
            fill: ({ dropdownFill }) => dropdownFill || "#99acc2",
        },
        "& svg path": {
            fill: ({ dropdownFill }) => dropdownFill || "#99acc2",
        },
        aspectRatio: 1,
    },
    treeDropdownLogoActive: {
        "& svg": {
            transform: "rotate(0deg)",
            fill: ({ dropdownFill }) => dropdownFill || "#32475a",
        },
        "& svg path": {
            fill: ({ dropdownFill }) => dropdownFill || "#32475a",
        },
    },
    addBranch: {
        marginLeft: "auto",
    },
    addBranchIcon: {
        color: "#33475B",
        borderRadius: 4,
        height: "30px",
        aspectRatio: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1.5rem",
        fontWeight: "600",
        cursor: "pointer",
        position: "relative",
        zIndex: "1",
        "&:hover": {
            backgroundColor: "#eaf0f6",
            color: "#33475b",
        },
    },
    treeComponentLogo: {
        margin: "auto 0.5rem",
        marginLeft: "0",
        fontSize: "1.25rem",
        "& svg": {
            height: "20px",
            width: "25px",
        },
    },
}))

const TreeComponent = ({
    initShow = true,
    title = "Title",
    logo = "",
    isDropdown = false,
    dropdownLogo = <UpgradeIcon />,
    dropdownFill,
    levelPadding,
    children,
    isAddBranch = false,
    setIsAddBranch,
    style = {},
    className,
    headerStyle = {},
    onClick = () => {},
    addBranchTooltipText,
    addBranchTooltipDirection = "bottom",
    ...props
}) => {
    const classes = useStyles({ dropdownFill, levelPadding, isAddBranch })
    const [isShow, setIsShow] = React.useState(initShow)
    const addBranch = React.useRef(null)

    return (
        <div
            className={`${classes.treeComponent} ${className}`}
            style={{ ...style }}
            {...props}
        >
            <div
                className={classes.treeHeader}
                style={{ ...headerStyle }}
                onClick={(e) => {
                    if (e.target === addBranch.current) return
                    setIsShow(!isShow)
                    onClick()
                }}
            >
                {(children || isDropdown) && (
                    <div
                        className={`${classes.treeDropdownLogo} ${
                            isShow && classes.treeDropdownLogoActive
                        }`}
                    >
                        {dropdownLogo}
                    </div>
                )}
                {logo && <div className={classes.treeComponentLogo}>{logo}</div>}
                <div className={classes.treeComponentTitle}>
                    {isValidElement(title) ? (
                        title
                    ) : (
                        <Text
                            varient="body"
                            style={{
                                fontWeight: 600,
                                color: headerStyle?.color,
                                textTransform: "capitalize",
                                ...headerStyle,
                            }}
                        >
                            {title}
                        </Text>
                    )}
                </div>
                {isAddBranch && (
                    <Guard scope="folder_create">
                        <TooltipWrapper
                            text={addBranchTooltipText}
                            wrapperClassName={classes.addBranch}
                            direction={addBranchTooltipDirection}
                        >
                            <div
                                onClick={setIsAddBranch || (() => {})}
                                ref={addBranch}
                                className={classes.addBranchIcon}
                            >
                                +
                            </div>
                        </TooltipWrapper>
                    </Guard>
                )}
            </div>
            {children && isShow && (
                <div className={classes.treeComponentChildren}>{children}</div>
            )}
        </div>
    )
}

export default TreeComponent
