import React from "react"
import DropboxChooser from "react-dropbox-chooser"

const DropBoxPicker = ({ setFile, styleClass, ...props }) => {
    return (
        <DropboxChooser
            appKey={process.env.REACT_APP_DROPBOX_KEY}
            success={(files) => {
                setFile(
                    files.map((item) => ({
                        id: item.id,
                        mediaId: item.name,
                        mediaType: "application/pdf",
                        mediaPath: item.link,
                    }))
                )
            }}
            linkType="direct"
            cancel={() => {}}
            multiselect={true}
            extensions={[".pdf"]}
        >
            <div className={styleClass}>
                <svg
                    className="install-view-layout__logo"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    width="26px"
                    height="26px"
                    viewBox="0 0 32 32"
                    style={{ fill: "rgb(0, 97, 255)" }}
                >
                    <path d="M8 2.4l8 5.1-8 5.1-8-5.1 8-5.1zm16 0l8 5.1-8 5.1-8-5.1 8-5.1zM0 17.7l8-5.1 8 5.1-8 5.1-8-5.1zm24-5.1l8 5.1-8 5.1-8-5.1 8-5.1zM8 24.5l8-5.1 8 5.1-8 5.1-8-5.1z"></path>
                </svg>
                <div style={{ color: "#33475B" }}>Dropbox</div>
            </div>
        </DropboxChooser>
    )
}

export default DropBoxPicker
