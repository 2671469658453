import { store } from "../store"
import { createMessage } from "./messageAndNotificationActions"

import {
    PERMISSION_FAILED,
    PERMISSION_LOADING,
    PERMISSION_SUCCESS,
    UPDATE_PERMISSIONS,
    PERMISSION_FAILED_INTERNAL_AUTH,
    USER_PERMISSIONS_SUCCESS,
    USER_PERMISSIONS_FAIL,
    LOAD_USER_PERMISSIONS,
} from "../ActionTypes/permissionActionType"
import { workspaceApi } from "Apis/AllApisMapping"
import { getHeaders } from "./ActionHelper"
import { getRolesFromPermissions } from "./roleAction"
import { DANGER } from "Types"

const { dispatch, getState } = store

export const getPermissions = async () => {
    dispatch({ type: PERMISSION_LOADING })

    try {
        const headers = getHeaders()
        let res = await workspaceApi.get(`/permission?product_id=2`, headers)
        if (res.data.code === 200) {
            let permissions = {}
            let roles = []

            res.data.model.roles.forEach(({ roleId, roleName, permission }) => {
                roles.push({ roleId, roleName })
                permissions[roleId] = {}
                permission.forEach((per) => {
                    const { Enable, Id, RoleId } = per
                    permissions[roleId][per.ActionId.Action] = { Enable, Id, RoleId }
                })
            })
            getRolesFromPermissions(roles)
            dispatch({
                type: PERMISSION_SUCCESS,
                payload: {
                    permissions,
                },
            })
        } else {
            dispatch({ type: PERMISSION_FAILED_INTERNAL_AUTH })
            createMessage(res.data.msg, null, DANGER)
        }
    } catch (err) {
        if (err.response) {
            dispatch({ type: PERMISSION_FAILED })
            createMessage(err.response.data, null, DANGER)
        }
    }
}

export const updatePermission = async (id, company_id) => {
    try {
        const headers = getHeaders()
        const result = await workspaceApi.get(`/permission?id=${id}`, headers)

        if (result.data.code === 200) {
            dispatch({
                type: UPDATE_PERMISSIONS,
                payload: { company_id: company_id, data: result.data.model },
            })
        } else {
            createMessage(result.data.msg, null, DANGER)
        }
    } catch (error) {
        if (error.response) {
            createMessage(error.response.data, null, DANGER)
        }
        createMessage("Something went wrong.", null, DANGER)
    }
}

export const getUserPermission = async (id) => {
    dispatch({ type: LOAD_USER_PERMISSIONS })

    try {
        const headers = getHeaders()
        const result = await workspaceApi.get(`/permission?id=${1}`, headers)

        if (result.data.code === 200) {
            dispatch({
                type: USER_PERMISSIONS_SUCCESS,
                payload: { permissions: result.data.model },
            })
        } else {
            dispatch({ type: USER_PERMISSIONS_FAIL })
            createMessage(result.data.msg, null, DANGER)
        }
    } catch (error) {
        if (error.response) {
            createMessage(error.response.data, null, DANGER)
        }
        createMessage("Something went wrong.", null, DANGER)
    }
}

// export const getUserPermissions = async () => {
//     // https://zc264xqbli.execute-api.ap-south-1.amazonaws.com/default/permission?id=1
// }
