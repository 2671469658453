import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { ReactComponent as DeleteIcon } from "Assets/Storage/delete-icon.svg"
import { ReactComponent as PenIcon } from "Assets/settings/editpen.svg"
import { ReactComponent as TickIcon } from "Assets/ToolsIcons/tick.svg"
import { ReactComponent as AddNewVariableIcon } from "Assets/ToolsIcons/addNewVar.svg"

import { useStyles } from "./EachRoleStyle"
import useToggle from "hooks/ToggleHook"
import TemplateDragElements from "../TemplateDragElements/TemplateDragElements"
import { removeRoleFromTemplate, updateRoleOfTemplate } from "Action/signAction"
import { connect } from "react-redux"
import { DANGER, DRAFT } from "Types"
import { createMessage } from "Action/messageAndNotificationActions"
import { getElementIconByType, primaryElements } from "data/RoleDragElements"
import AddNewVariable from "./AddNewVariable"

const EachRole = ({
    roleName,
    roleId,
    status,
    backgroundDecorum,
    additionalFields,
}) => {
    //TODO: handle template elements in a better way
    const [templateElements, setTemplateElements] = React.useState(
        additionalFields ? Object.values(JSON.parse(additionalFields)) : []
    )

    const classes = useStyles()
    const [currentRoleName, setCurrentRoleName] = React.useState(roleName)
    const editingInputWrapperRef = React.useRef()
    const [isEditing, toggleIsEditing] = useToggle(editingInputWrapperRef)
    const editingInputRef = React.useRef()
    const [visibleDropdown, setVisibleDropdown] = React.useState(false)

    React.useEffect(() => {
        if (isEditing) {
            editingInputRef.current.focus()
        } else {
            if (!currentRoleName) {
                createMessage("Role name cannot be empty", null, DANGER)
                return setCurrentRoleName(roleName)
            }
            if (roleName === currentRoleName) return
            updateRoleOfTemplate(roleId, { roleName: currentRoleName })
        }
    }, [isEditing])

    const customBackgroundColor = backgroundDecorum

    return (
        <div
            className={classes.eachRoleWrapper}
            style={{
                backgroundColor: customBackgroundColor.back,
                borderColor: customBackgroundColor.border,
            }}
        >
            <div className={classes.eachRoleHeader}>
                <Text weight={600}>{`${roleName} fields`}</Text>
                <div
                    className={classes.eachRoleHeaderIcon}
                    style={{
                        cursor: status !== DRAFT ? "not-allowed" : "pointer",
                        opacity: status !== DRAFT ? 0.7 : 1,
                    }}
                    onClick={async () => {
                        if (status !== DRAFT) return
                        await removeRoleFromTemplate(roleId, roleName)
                    }}
                >
                    <DeleteIcon height={22} width={22} />
                </div>
            </div>
            <div
                className={classes.eachRoleTitle}
                ref={editingInputWrapperRef}
                style={{
                    borderColor: isEditing ? "#425B76" : "#CBD6E2",
                }}
            >
                <input
                    type="text"
                    value={currentRoleName}
                    onChange={(e) => setCurrentRoleName(e.target.value)}
                    disabled={!isEditing}
                    ref={editingInputRef}
                />
                <div
                    className={classes.eachRoleHeaderIcon}
                    style={{
                        cursor: status !== DRAFT ? "not-allowed" : "pointer",
                        opacity: status !== DRAFT ? 0.7 : 1,
                    }}
                    onClick={() => {
                        if (status !== DRAFT) return
                        toggleIsEditing()
                    }}
                >
                    {isEditing ? (
                        <TickIcon />
                    ) : (
                        <PenIcon height={15} width={15} fill="#33475b" />
                    )}
                </div>
            </div>
            <div className={classes.eachRoleFields}>
                {[...primaryElements, ...templateElements].map((element, i) => (
                    <TemplateDragElements
                        {...element}
                        roleId={roleId}
                        roleName={roleName}
                        Icon={element.Icon || getElementIconByType(element.type)}
                    />
                ))}
            </div>
            <div className={classes.addRoleField}>
                {visibleDropdown && (
                    <AddNewVariable
                        setShowAdditionalFieldModal={setVisibleDropdown}
                        setTemplateElements={setTemplateElements}
                        templateElements={templateElements}
                        roleId={roleId}
                    />
                )}
                <div
                    className={classes.addRoleFieldButton}
                    onClick={() => setVisibleDropdown(!visibleDropdown)}
                >
                    <AddNewVariableIcon />
                    <Text marginLeft="1rem" varient="subText">
                        New Custom Variable
                    </Text>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ signReducer }) => ({
    status: signReducer.status,
})

export default connect(mapStateToProps)(EachRole)
