import {
    LOAD_SUBSCRIPTION_DETAILS,
    LOAD_SUBSCRIPTION_DETAILS_FAILED,
    LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
    HIDE_SUBSCRIPTION_POPUP,
    HIDE_UPGRADE_POPUP,
    SHOW_SUBSCRIPTION_POPUP,
    SHOW_UPGRADE_POPUP,
    GET_SUBSCRIPTION_PLAN,
    SET_REMAINING_DAYS,
} from "ActionTypes/subscriptionActions"
import { subscriptionApi } from "Apis/AllApisMapping"
import { store } from "store"
import { getHeaders } from "./ActionHelper"
import { createMessage } from "./messageAndNotificationActions"
import { getOrganization } from "./userAction"
import { DANGER } from "Types"

const { dispatch, getState } = store

/**
 * get the subscribed plans by users
 */
export const getSubscribedPlan = async () => {
    dispatch({ type: LOAD_SUBSCRIPTION_DETAILS })

    try {
        const headers = getHeaders()
        const { token, userDetails } = getState().userReducer

        const subscription = await subscriptionApi.get(
            `/subscription?product_id=2`,
            headers
        )

        let res = subscription.data.model
        if (subscription.data.code === 200) {
            dispatch({
                type: LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
                payload: {
                    subscription: subscription.data.model,
                },
            })
        } else if (
            subscription.data.code === 400 &&
            Array.isArray(res) &&
            res.length < 1
        ) {
            dispatch({
                type: LOAD_SUBSCRIPTION_DETAILS_SUCCESS,
                payload: {
                    subscription: [],
                },
            })
        } else {
            dispatch({ type: LOAD_SUBSCRIPTION_DETAILS_FAILED })
        }
        return res
    } catch (error) {
        dispatch({ type: LOAD_SUBSCRIPTION_DETAILS_FAILED })
    }
}

export async function getSubscriptionPlan() {
    try {
        const headers = getHeaders()

        const plans = await subscriptionApi.get(
            `/v2/subscription?product_id=2`,
            headers
        )

        if (plans.data.model) {
            return plans.data.model
        } else {
            throw new Error("Can't load subscription problem in reducer.")
        }
    } catch (error) {
        createMessage("Can't load subscription problem.", null, DANGER)
        console.log(error)
    }
}

export const subscribeFreePlan = async (token, orgId, userId) => {
    try {
        let data = {
            productId: 2,
            orgId: orgId,
            userId: userId,
            planId: 1,
            purchaseId: "",
        }

        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }

        const subscription = await subscriptionApi.post(
            `/subscription`,
            data,
            headers
        )

        if (subscription.data.code === 200) {
            getSubscribedPlan()
            return true
        } else {
            // createMessage(DANGER, subscription.data.msg);
        }
    } catch (error) {
        // createMessage(DANGER, "Something went wrong while selecting the plan !");
    }
}

export function showPopUpPlan() {
    dispatch({ type: SHOW_SUBSCRIPTION_POPUP })
}
export function hidePopUpPlan() {
    dispatch({ type: HIDE_SUBSCRIPTION_POPUP })
}
export function showUpgradePlan() {
    dispatch({ type: SHOW_UPGRADE_POPUP })
}
export function hideUpgradePlan() {
    dispatch({ type: HIDE_UPGRADE_POPUP })
}
export function setDaysLeftForPlan(daysLeft) {
    dispatch({ type: SET_REMAINING_DAYS, payload: { daysLeft } })
}
