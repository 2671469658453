import React from "react"
import { Button, Grid, Text } from "@awesomesuite-frontend/awesome-nebula"
import Input from "Awesome-ui/Input/Input"
import { createUseStyles } from "react-jss"
import { ReactComponent as WelcomeOnBoardIcon } from "Assets/onboarding/welcome-onboard.svg"
import { connect } from "react-redux"
import { useAnimationStyles } from "../animateStyle"

const useStyle = createUseStyles(() => ({
    leftWrapper: {
        paddingLeft: "10%",
    },
    workspaceInput: {
        fontSize: "1.25rem !important",
        "&::placeholder": {
            fontSize: "1.1rem",
        },
    },
    rightWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        height: "100vh",
    },
    disableBtn: {
        "&:disabled": {
            backgroundColor: "#d7dde3",
            cursor: "not-allowed",
        },
    },
}))

const CreateNewPostUI = ({ val, setVal, firstName, lastName, setView }) => {
    const classes = useStyle()
    const animationStyles = useAnimationStyles()

    return (
        <Grid container className={animationStyles.animateUp}>
            <Grid item md={7} className={classes.leftWrapper}>
                <Text varient="h4" marginTop="10rem">
                    👋 Welcome {firstName} {lastName}
                </Text>
                <Text fontSize={20} marginTop="4rem">
                    Thank you for making a signature move
                </Text>
                <Text varient="h3" margin="1rem 0">
                    Let's get your account setup
                </Text>
                <Input
                    style={{
                        width: "50%",
                        border: "none",
                        borderBottom: "1px solid #7CCFDC",
                        marginTop: "5rem",
                        maxWidth: "none",
                    }}
                    borderRadius="5px"
                    value={val}
                    changeHandler={setVal}
                    placeHolder="Workspace Name"
                    inputClassname={classes.workspaceInput}
                ></Input>
                <Button
                    color={"#33475b"}
                    size="medium"
                    radius={6}
                    width="50%"
                    style={{ marginTop: "2rem" }}
                    disabled={!val}
                    onClick={() => setView(1)}
                    className={`${val ? "" : classes.disableBtn}`}
                >
                    <Text color="#fff" fontSize={18} weight={500}>
                        Next
                    </Text>
                </Button>
            </Grid>
            <Grid item md={4} className={classes.rightWrapper}>
                <WelcomeOnBoardIcon />
                <Text varient="h4" marginTop="3rem">
                    Create a workspace
                </Text>
                <Text marginTop="1rem" align="center">
                    Create a workspace for your organization. You and your team could
                    collaborate effectively.
                </Text>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = ({ userReducer }) => {
    return {
        firstName: userReducer.userDetails?.first_name,
        lastName: userReducer.userDetails?.last_name,
    }
}

export default connect(mapStateToProps)(CreateNewPostUI)
