import { getHeaders } from "Action/ActionHelper"
import { createMessage } from "Action/messageAndNotificationActions"
import { eSignApi } from "Apis/AllApisMapping"
import { DANGER } from "Types"
import axios from "axios"
import Axios from "axios"

/**
 * mediaHandler for handling all media related related stuff
 * use by initializing it with the end point and then use the handler provided by this.
 *
 * @example
 * ```
 *     const useMedia = new mediaHandler(mediaApi);
 * ```
 */
class mediaHandler {
    constructor(apiEndPoint) {
        this.mediaApi = Axios.create({
            baseURL: apiEndPoint,
        })
    }

    async getBobFromFile(file) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = function (e) {
                    const dataWithOtherInfo = e.target.result.split(",")[1]
                    resolve(dataWithOtherInfo)
                }
            } catch (error) {
                reject("Failed to Get the uploaded file data")
            }
        })
    }

    removeTypeDescription(blobUrl) {
        const splitData = blobUrl.split(",")
        return {
            data: splitData[1],
            otherDescription: splitData[0],
        }
    }

    /**
     * Upload file to  the server and get the media id
     *
     * @example
     * ```
     *     {
     *      "id": 9,
     *      "mediaId": "media_1630355551",
     *      "mediaType": "image/jpeg",
     *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
     *      "isEnabled": 1,
     *      "userId": 12,
     *      "relatedId": "",
     *      "status": "success"
     *     }
     *```
     *
     * */
    async uploadMedia(file, otherFileName, authToken) {
        if (!(file instanceof File)) return

        let fileNameToSend = file.name

        if (otherFileName) fileNameToSend = otherFileName

        var fileToSend = await this.getBobFromFile(file)

        const data = {
            fileName: fileNameToSend,
            dataType: file.type,
            data: fileToSend,
        }

        try {
            const mediaUploadResult = await this.mediaApi.post(
                "/media?action=upload",
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            )

            if (mediaUploadResult.data.code === 200) {
                return mediaUploadResult.data.model
            } else {
                throw new Error(
                    "Some technical issue occured while uploading the media"
                )
            }
        } catch (error) {
            createMessage("Could not upload the media", error.message, DANGER)
        }
    }

    /**
     * public api to upload the images and other stuff for pubic user...
     * right now it is limited for small file size.
     *
     * @example
     * ```
     *     {
     *      "id": 9,
     *      "mediaId": "media_1630355551",
     *      "mediaType": "image/jpeg",
     *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
     *      "isEnabled": 1,
     *      "userId": 12,
     *      "relatedId": "",
     *      "status": "success"
     *     }
     *```
     *
     * */
    async uploadMediaPublic(file, otherFileName) {
        if (!(file instanceof File)) return

        let fileNameToSend = file.name

        if (otherFileName) fileNameToSend = otherFileName

        var fileToSend = await this.getBobFromFile(file)

        const data = {
            fileName: fileNameToSend,
            dataType: file.type,
            data: fileToSend,
        }

        try {
            const mediaUploadResult = await eSignApi.post("/upload", data)

            if (mediaUploadResult.data.code === 200) {
                return mediaUploadResult.data.model
            } else {
                return new Error(
                    "Could not upload the file due to some technical issue"
                )
            }
        } catch (error) {
            createMessage("Could not upload the media", error.message, DANGER)
        }
    }

    /**
     * Upload file data url directly.
     *
     * @param dataUrl - data url like "data:image/png;base64,soifjiosdjfoisdf"
     * @example
     * ```
     *     {
     *      "id": 9,
     *      "mediaId": "media_1630355551",
     *      "mediaType": "image/jpeg",
     *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
     *      "isEnabled": 1,
     *      "userId": 12,
     *      "relatedId": "",
     *      "status": "success"
     *     }
     *```
     *
     * */
    async uploadMediaBlob(dataUrl, authToken, type, alterName = "sign.png") {
        if (!dataUrl) return

        let fileNameToSend = alterName

        let headers = getHeaders()
        if (authToken) {
            headers = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            }
        }

        var fileToSend = await this.removeTypeDescription(dataUrl)

        const data = {
            fileName: fileNameToSend,
            dataType: type || "image/png",
            data: fileToSend.data,
        }

        try {
            const mediaUploadResult = await this.mediaApi.post(
                "/media?action=upload",
                data,
                headers
            )

            if (mediaUploadResult.data.code === 200) {
                return mediaUploadResult.data.model
            } else {
                return new Error(
                    "Could not upload the file due to some technical issue"
                )
            }
        } catch (error) {
            return new Error("Could not upload the media")
        }
    }

    /**
     * Upload file data url directly.
     *
     * @param dataUrl - data url like "data:image/png;base64,soifjiosdjfoisdf"
     * @example
     * ```
     *     {
     *      "id": 9,
     *      "mediaId": "media_1630355551",
     *      "mediaType": "image/jpeg",
     *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
     *      "isEnabled": 1,
     *      "userId": 12,
     *      "relatedId": "",
     *      "status": "success"
     *     }
     *```
     *
     * */
    async uploadMediaBlobPublic(dataUrl, alterName = "sign.png") {
        if (!dataUrl) return

        let fileNameToSend = alterName

        var fileToSend = await this.removeTypeDescription(dataUrl)

        const data = {
            fileName: fileNameToSend,
            dataType: "image/png",
            data: fileToSend.data,
        }

        try {
            const mediaUploadResult = await eSignApi.post("/public/upload", data)

            if (mediaUploadResult.data.statusCode === 200) {
                return mediaUploadResult.data.model
            } else {
                return new Error(
                    "Could not upload the file due to some technical issue"
                )
            }
        } catch (error) {
            return new Error("Could not upload the media")
        }
    }

    /**
     * get Uploaded media details with the media id
     *
     * @example
     * ```
     *     {
     *      "id": 9,
     *      "mediaId": "media_1630355551",
     *      "mediaType": "image/jpeg",
     *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
     *      "isEnabled": 1,
     *      "userId": 12,
     *      "relatedId": "",
     *      "status": "success"
     *     }
     *```
     *
     * */
    async getMediaById(mediaId, authToken) {
        if (!mediaId)
            return new Error("Could not find the media id in the parameters")

        try {
            const mediaUploadResult = await this.mediaApi.get(
                `/media?media_id=${mediaId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            )

            if (mediaUploadResult.data.code === 200) {
                return mediaUploadResult.data.model
            } else {
                return new Error(
                    "Could not load the file due to some technical issue"
                )
            }
        } catch (error) {
            return new Error("Could not upload the media")
        }
    }

    /**
     * get all Uploaded media details for the user using the auth token
     *
     * @example
     * ```
     *     [ {
     *      "id": 9,
     *      "mediaId": "media_1630355551",
     *      "mediaType": "image/jpeg",
     *      "mediaPath": "https://com-awrsomesuite-media-service.s3.ap-south-1.amazonaws.com/media_1630355551test.jpeg",
     *      "isEnabled": 1,
     *      "userId": 12,
     *      "relatedId": "",
     *      "status": "success"
     *     } ]
     *```
     *
     * */
    async getAllMediaForUser(authToken) {
        try {
            const mediaUploadResult = await this.mediaApi.get(`/media`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            })

            if (mediaUploadResult.data.code === 200) {
                return mediaUploadResult.data.model
            } else {
                throw new Error(
                    "Could not load the file due to some technical issue"
                )
            }
        } catch (error) {
            throw new Error("Could not upload the media")
        }
    }

    async uploadMediaOther(fileName, type, fileId, platform = "drive", token) {
        try {
            const data = {
                fileName: fileName,
                dataType: type || "image/jpeg",
                fileId: fileId,
                relatedId: null,
                token: token,
            }

            const header = getHeaders()
            const mediaUploadResult = await this.mediaApi.post(
                `/media_platform?platform=${platform}`,
                data,
                header
            )

            if (mediaUploadResult.data.code === 200) {
                return mediaUploadResult.data.model
            } else {
                throw new Error(
                    "Could not upload the file due to some technical issue"
                )
            }
        } catch (error) {
            throw new Error("Could not upload the media")
        }
    }

    async getBase64(file, token) {
        let data = await axios.get(file, {
            responseType: "blob",
            headers: { Authorization: `Bearer ${token}` },
        })
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(data.data)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })
    }
}

export default mediaHandler
