import React from "react"
import { ReactComponent as Upload } from "./Assets/upload-icon.svg"
import { ReactComponent as Gallery } from "./Assets/gallery.svg"
import { ReactComponent as Unsplash } from "./Assets/unsplash.svg"
import { ReactComponent as Tenor } from "./Assets/tenor.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import { useContext } from "react"
import { ReactContext } from "./MediaLibrary"
import { useStyles } from "./MediaLibraryStyle"

const MediaLibraryLeft = () => {
    const { styles, selectedTab, setSelectedTab, UnsplashPicker, GIFPicker } =
        useContext(ReactContext)
    const classes = useStyles(styles)
    return (
        <div className={`${classes.mainContainerLeft}`}>
            <div
                className={`${classes.leftBarOption} ${
                    classes.leftBarUploadOption
                } ${selectedTab === 1 && classes.leftBarOptionActive}`}
                onClick={(e) => {
                    e.preventDefault()
                    setSelectedTab(1)
                }}
            >
                <Upload />
                <Text
                    fontSize={14}
                    marginLeft="0.75rem"
                    color={`${selectedTab === 1 ? "#33475B" : "#A3B4C8"}`}
                >
                    Upload File
                </Text>
            </div>
            <Text fontSize={14} marginBottom="0.25rem">
                Media
            </Text>
            <div
                className={`${classes.leftBarOption} ${
                    selectedTab === 2 && classes.leftBarOptionActive
                }`}
                onClick={(e) => {
                    e.preventDefault()
                    setSelectedTab(2)
                }}
            >
                <Gallery />
                <Text
                    fontSize={14}
                    marginLeft="0.75rem"
                    color={`${selectedTab === 2 ? "#33475B" : "#A3B4C8"}`}
                >
                    Content Library
                </Text>
            </div>
            {UnsplashPicker && (
                <div
                    className={`${classes.leftBarOption} ${
                        selectedTab === 3 && classes.leftBarOptionActive
                    }`}
                    onClick={(e) => {
                        e.preventDefault()
                        setSelectedTab(3)
                    }}
                >
                    <Unsplash />
                    <Text
                        fontSize={14}
                        marginLeft="0.75rem"
                        color={`${selectedTab === 3 ? "#33475B" : "#A3B4C8"}`}
                    >
                        Unsplash
                    </Text>
                </div>
            )}
            {GIFPicker && (
                <div
                    className={`${classes.leftBarOption} ${
                        selectedTab === 4 && classes.leftBarOptionActive
                    }`}
                    onClick={(e) => {
                        e.preventDefault()
                        setSelectedTab(4)
                    }}
                >
                    <Tenor />
                    <Text
                        fontSize={14}
                        marginLeft="0.75rem"
                        color={`${selectedTab === 4 ? "#33475B" : "#A3B4C8"}`}
                    >
                        Tenor (GIFs)
                    </Text>
                </div>
            )}
        </div>
    )
}

export default MediaLibraryLeft
