import { Grid, Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyle = createUseStyles(() => ({
    gridSelector: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "1px solid #CBD6E2",
        borderRadius: "4px",
    },
    gridSelectorItem: {
        minWidth: "100px",
        padding: "0.5rem 1rem",
        borderRight: "1px solid #CBD6E2",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:first-child": {
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
        },
        "&:last-child": {
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            borderRight: "none",
        },
    },
    gridSelectorItemActive: {
        backgroundColor: "#EAF0F6",
    },
}))

const GridSelector = ({
    options = [],
    currentOption,
    setCurrentOption = () => {},
    isDisabled = false,
}) => {
    const classes = useStyle()
    return (
        <div className={classes.gridSelector}>
            {options.map((option, index) => (
                <div
                    className={`${classes.gridSelectorItem} ${
                        option.value === currentOption
                            ? classes.gridSelectorItemActive
                            : ""
                    }`}
                    key={index}
                    onClick={() => {
                        if (isDisabled) return
                        setCurrentOption(option.value)
                    }}
                    style={{
                        cursor: isDisabled ? "not-allowed" : "pointer",
                        opacity: isDisabled ? 0.5 : 1,
                    }}
                >
                    <Text varient="subText">{option.name}</Text>
                </div>
            ))}
        </div>
    )
}

export default GridSelector
