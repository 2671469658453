import React, { Component } from "react"
import { expandSideBar } from "../../Action/settingActions"

import Apps from "../../Assets/Dashboard/Apps.svg"
import "./sideBar.scss"

/**
 * This is Side Bar or a wrapper to wrap the nav icons.
 *
 * @param {[Icons]} Icons List to show
 * It needs only List of Icons
 */
class SideBar extends Component {
    render() {
        const {
            type,
            expand,
            theme,
            IconFull,
            IconSmall,
            appSwitch,
            userRole,
            showAppSwitcher,
        } = this.props
        return (
            <>
                <div
                    className={`
                        sideBar ${expand && "expand"} 
                        ${type === "named" ? "medium" : "slim"}
                        theme-${theme}`}
                >
                    <div className="topIcons">
                        {showAppSwitcher && (
                            <div className="app-logo" onClick={() => appSwitch()}>
                                <img src={Apps} alt="apps" />
                                {expand && (
                                    <span
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            color: `${
                                                theme == "dark"
                                                    ? "#FFFFFF"
                                                    : "#073255"
                                            }`,
                                            marginLeft: "1rem",
                                            marginRight: "5rem",
                                            letterSpacing: "0.5px",
                                        }}
                                    >
                                        APPS
                                    </span>
                                )}
                            </div>
                        )}
                        <div
                            onClick={() => expandSideBar()}
                            className="company-logo"
                        >
                            <div>{expand ? <IconFull /> : <IconSmall />}</div>
                        </div>
                        {this.props.children}
                    </div>
                </div>
                <div
                    className={`spacer ${
                        expand
                            ? "space-expand"
                            : type === "named"
                            ? "space-medium"
                            : "space-slim"
                    }`}
                ></div>
            </>
        )
    }
}

export default SideBar
