import React, { useEffect, useState } from "react"
import Table from "Awesome-ui/Table/Table"
import TableHeader from "Awesome-ui/TableHeader/TableHeader"
import TableRow from "Awesome-ui/TableRow/TableRow"
import File from "../File/File"
import { ReactComponent as SortIcon } from "Assets/Inbox/sortIcon.svg"
import { ReactComponent as Delete } from "Assets/Storage/delete-icon.svg"
import PaginationBar from "Awesome-ui/PaginationBar"
import { connect } from "react-redux"
import useQuery from "hooks/UseQuery"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Input from "Awesome-ui/Input/Input"
import NoFilesOnSearch from "./NoFilesOnSearch"
import PageLoading from "Awesome-ui/PageLoading/PageLoading"
import { Button, CheckBox, Text } from "@awesomesuite-frontend/awesome-nebula"
import {
    deleteMultipleDocuments,
    getAllDocumentsRecievedForSignature,
    getAllDocumentsWithStatus,
} from "Action/documentAction"
import { DRAFT, FINISHED, OUT_FOR_SIGNATURE } from "Types"
import NoFiles from "./NoFiles"
import TooltipWrapper from "Awesome-ui/SignTooltip/TooltipWrapper"

const sortType = [
    "",
    "fileName,asc",
    "fileName,desc",
    "",
    "",
    "status,asc",
    "status,desc",
    "createdAt,asc",
    "createdAt,desc",
    "updatedAt,asc",
    "updatedAt,desc",
]

const headerStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}

const Files = ({
    files,
    selection = {},
    userDetails,
    currentFolder,
    totalPages,
    onDeleteFile = async () => {},
    documentLoading,
    setselection,
    selectFile,
    clearSelection,
}) => {
    const { addQuery, removeQuery, getQuery } = useQuery()
    const history = useHistory()
    const [sortOrder, setSortOrder] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [size, setSize] = useState(10)
    const [searchQuery, search] = useState(getQuery("search"))
    const [onSearchFileEmpty, setOnSearchFileEmpty] = useState(false)

    const checkAll = () => {
        const condition = files?.every((f) => selection[f.fileId] === true)
        setselection(files, condition ? false : true)
    }

    const deleteMultipleFileHandler = async () => {
        const fileIds = []
        Object.keys(selection).map((fileId) => {
            if (selection[fileId] === true) fileIds.push(fileId)
        })
        if (fileIds.length < 1) return
        const res = await deleteMultipleDocuments(fileIds)
        if (res === 200) {
            await onDeleteFile()
            await Promise.all([
                getAllDocumentsWithStatus(DRAFT),
                getAllDocumentsWithStatus(OUT_FOR_SIGNATURE),
                getAllDocumentsWithStatus(FINISHED),
                getAllDocumentsRecievedForSignature(),
            ])
        }
        clearSelection()
    }

    useEffect(() => {
        if (getQuery("sort")) {
            const val = sortType.findIndex((e) => e === getQuery("sort"))
            if (val !== -1) {
                setSortOrder(val)
                return
            }
        }
        removeQuery("sort")
        setSortOrder(0)

        if (Number(getQuery("page")) <= totalPages && Number(getQuery("page")) > 0)
            setCurrentPage(Number(getQuery("page")))

        if ([10, 20, 50, 100].find((e) => e === Number(getQuery("size"))))
            setSize(Number(getQuery("size")))
        else setSize(10)

        if (getQuery("search")) search(getQuery("search"))
        else removeQuery("search")
    }, [history.location.search])

    useEffect(() => {
        const init = async () => {
            if (searchQuery) addQuery("search", searchQuery)
            else if (getQuery("search")) removeQuery("search")
        }
        const debounceTimeout = setTimeout(init, 800)

        return () => clearTimeout(debounceTimeout)
    }, [searchQuery])

    useEffect(() => {
        if (files?.length) return
        const currentPage = Number(getQuery("page"))
        const searchQuery = getQuery("search")
        if (currentPage > 1 || searchQuery) setOnSearchFileEmpty(true)
        else setOnSearchFileEmpty(false)
    }, [files])

    if (!files) {
        return null
    }

    return (
        <>
            <div className="store-page-main-content">
                <div className="store-page-header-options">
                    <Input
                        style={{
                            width: "100%",
                            maxWidth: "250px",
                            fontSize: "1rem",
                            padding: "10px 18px",
                        }}
                        inputClassname={"searchBoxInput"}
                        value={searchQuery}
                        changeHandler={search}
                        placeHolder="Search documents"
                        icon="search"
                        iconColor="#1ECBE5"
                        inputStyle={{
                            padding: "5px 10px",
                        }}
                    />
                    {Object.values(selection).filter((e) => e === true).length >
                        0 && (
                        <>
                            <div className="count-files">
                                <Text fontSize={14} color="#00B5D1">
                                    {`${
                                        Object.values(selection).filter(
                                            (e) => e === true
                                        ).length
                                    } files selected`}
                                </Text>
                            </div>
                            <Button
                                className="delete-files"
                                onClick={deleteMultipleFileHandler}
                            >
                                <div>
                                    <Delete />
                                    <Text fontSize={14} marginLeft="0.5rem">
                                        Delete
                                    </Text>
                                </div>
                            </Button>
                        </>
                    )}
                </div>
                {documentLoading ? (
                    <PageLoading
                        style={{ minHeight: "calc(92vh - 63px - 2rem - 55px)" }}
                    />
                ) : (
                    <>
                        <Table style={{ backgroundColor: "#fff" }}>
                            <TableRow
                                style={{
                                    background: "#fff",
                                    border: " 1px solid #d6dfe8",
                                    boxShadow: "0px 3px 3px rgba(181,197,214,0.24)",
                                    borderBottom: "none",
                                }}
                            >
                                <TableHeader align="left" width="60px">
                                    <CheckBox
                                        active={
                                            files?.length > 0
                                                ? files?.every(
                                                      (f) =>
                                                          selection[f.fileId] ===
                                                          true
                                                  )
                                                : false
                                        }
                                        clickFunction={checkAll}
                                        color={"white"}
                                        backgroundColor={"rgb(0, 189, 165)"}
                                        variant={"curved"}
                                        border={"lightblue"}
                                    />
                                </TableHeader>
                                <TableHeader align="left" width="27.5%">
                                    <div
                                        style={{
                                            ...headerStyle,
                                            width: "fit-content",
                                        }}
                                    >
                                        <Text fontSize={14}>Document Name</Text>
                                        <TooltipWrapper
                                            text="Sort by File Name"
                                            direction="top"
                                        >
                                            <UpdatedSortIcon
                                                up={1}
                                                down={2}
                                                onClick={() => {
                                                    if (sortOrder === 1) {
                                                        setSortOrder(2)
                                                        addQuery("sort", sortType[2])
                                                    } else if (sortOrder === 2) {
                                                        setSortOrder(0)
                                                        removeQuery("sort")
                                                    } else {
                                                        setSortOrder(1)
                                                        addQuery("sort", sortType[1])
                                                    }
                                                }}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </TableHeader>
                                <TableHeader>
                                    <div style={{ ...headerStyle }}>
                                        <Text fontSize={14}>Owner</Text>
                                    </div>
                                </TableHeader>
                                <TableHeader>
                                    <div style={{ ...headerStyle }}>
                                        <Text fontSize={14}>Recepients</Text>
                                        {/* <UpdatedSortIcon up={3} down={4} onClick={()=>{
                                if(sortOrder===3)
                                setSortOrder(4)
                                else if(sortOrder===4)
                                setSortOrder(0)
                                else setSortOrder(3)
                            }}/> */}
                                    </div>
                                </TableHeader>
                                <TableHeader>
                                    <div style={{ ...headerStyle }}>
                                        Status
                                        <TooltipWrapper
                                            text="Sort by Status"
                                            direction="top"
                                        >
                                            <UpdatedSortIcon
                                                up={5}
                                                down={6}
                                                onClick={() => {
                                                    if (sortOrder === 5) {
                                                        setSortOrder(6)
                                                        addQuery("sort", sortType[6])
                                                    } else if (sortOrder === 6) {
                                                        setSortOrder(0)
                                                        removeQuery("sort")
                                                    } else {
                                                        setSortOrder(5)
                                                        addQuery("sort", sortType[5])
                                                    }
                                                }}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </TableHeader>
                                <TableHeader align="center">
                                    <div style={{ ...headerStyle }}>
                                        <Text fontSize={14}>Created On</Text>
                                        <TooltipWrapper
                                            text="Sort by Created"
                                            direction="top"
                                        >
                                            <UpdatedSortIcon
                                                up={7}
                                                down={8}
                                                onClick={() => {
                                                    if (sortOrder === 7) {
                                                        setSortOrder(8)
                                                        addQuery("sort", sortType[8])
                                                    } else if (sortOrder === 8) {
                                                        setSortOrder(0)
                                                        removeQuery("sort")
                                                    } else {
                                                        setSortOrder(7)
                                                        addQuery("sort", sortType[7])
                                                    }
                                                }}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </TableHeader>
                                <TableHeader align="center">
                                    <div style={{ ...headerStyle }}>
                                        <Text fontSize={14}>Last Updated</Text>
                                        <TooltipWrapper
                                            text="Sort by Updated"
                                            direction="top"
                                        >
                                            <UpdatedSortIcon
                                                up={9}
                                                down={10}
                                                onClick={() => {
                                                    if (sortOrder === 9) {
                                                        setSortOrder(10)
                                                        addQuery(
                                                            "sort",
                                                            sortType[10]
                                                        )
                                                    } else if (sortOrder === 10) {
                                                        setSortOrder(0)
                                                        removeQuery("sort")
                                                    } else {
                                                        setSortOrder(9)
                                                        addQuery("sort", sortType[9])
                                                    }
                                                }}
                                            />
                                        </TooltipWrapper>
                                    </div>
                                </TableHeader>
                                <TableHeader width="5%" align="center"></TableHeader>
                            </TableRow>

                            {files &&
                                files.length > 0 &&
                                files.map((item, index) => (
                                    <File
                                        file={item}
                                        key={index}
                                        selectFun={() => {
                                            if (selection[item.fileId])
                                                selectFile(false, item.fileId)
                                            else selectFile(true, item.fileId)
                                        }}
                                        isChecked={selection[item.fileId]}
                                        userName={`${
                                            item?.owner?.firstName
                                                ? item?.owner?.firstName +
                                                  " " +
                                                  item?.owner?.lastName
                                                : item?.owner?.userName
                                        } `}
                                        userEmail={item?.owner?.emailId}
                                        userPicture={item?.owner?.picture}
                                        onDeleteFile={onDeleteFile}
                                    />
                                ))}
                        </Table>
                        {(!files || files.length === 0) &&
                            (onSearchFileEmpty ? (
                                <NoFilesOnSearch
                                    currentFolder={currentFolder}
                                    backgroundColor="#f4f7f9"
                                />
                            ) : (
                                <NoFiles
                                    currentFolder={currentFolder}
                                    backgroundColor="#f4f7f9"
                                />
                            ))}
                    </>
                )}
            </div>
            {files && files.length > 0 && (
                <div
                    style={{
                        width: "100%",
                        margin: "1.5rem 0 0.5rem 0",
                        position: "relative",
                        zIndex: 10,
                        background: "#f4f7f9",
                    }}
                >
                    <PaginationBar
                        style={{ marginTop: "0.5rem" }}
                        currentPage={currentPage}
                        setCurrentPage={(val) => {
                            setCurrentPage(val)
                            addQuery("page", val.toString())
                        }}
                        totalPages={totalPages}
                        size={size}
                        setSize={(val) => {
                            setSize(val)
                            addQuery("size", val.toString())
                        }}
                    />
                </div>
            )}
        </>
    )

    function UpdatedSortIcon({ up, down, onClick = () => {} }) {
        return (
            <div
                style={{
                    height: "fit-content",
                    width: "fit-content",
                    marginLeft: "4.5px",
                }}
                onClick={onClick}
            >
                <div
                    style={{
                        height: "50%",
                        width: "fit-content",
                        cursor: "pointer",
                    }}
                >
                    <SortIcon
                        className={`${sortOrder === up && "sort-icon-active"}`}
                    />
                </div>
                <div
                    style={{
                        transform: "rotate(180deg)",
                        height: "50%",
                        cursor: "pointer",
                        width: "fit-content",
                    }}
                >
                    <SortIcon
                        className={`${sortOrder === down && "sort-icon-active"}`}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ foldersReducer, userReducer }) => {
    return {
        currentFolder: foldersReducer?.currentFolder,
        userDetails: userReducer?.userDetails,
    }
}

export default connect(mapStateToProps)(Files)
