import React from "react";

import "./esignToolWrapper.scss";
import { ReactComponent as Delete } from "../../../../../Assets/ToolsIcons/delete.svg"

const Wrapper = ({ onRemoveHandler }) => {
    return (
        <div
        className="tool-wrapper"
        >
            <div
                className="removeBtn"
                onClick={onRemoveHandler}
            >
                <span>Remove</span> <Delete width="1.1rem" />
            </div>
        </div>
    )
}

export default Wrapper
