import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"
import { Link } from "react-router-dom"

const PhotoCard = ({ type, link, data, Icon, externalLink }) => {
    if (type === "blank") {
        return (
            <div className="cardCont">
                <div className="card blank">
                    <div className="create">{/* onClick={isCreating} */}+</div>
                    <div className="text">
                        <span>Add New Setting</span>
                    </div>
                </div>
            </div>
        )
    } else {
        const { name } = data
        return (
            <div className="cardCont">
                {externalLink ? (
                    <a href={link} style={{ textDecoration: "none" }}>
                        <div className="card">
                            <div className="src">{Icon && <Icon />}</div>
                            <Text varient="subText" className="name">
                                {name}
                            </Text>
                        </div>
                    </a>
                ) : (
                    <Link to={link}>
                        <div className="card">
                            <div className="src">{Icon && <Icon />}</div>
                            <Text varient="subText" className="name">
                                {name}
                            </Text>
                        </div>
                    </Link>
                )}
            </div>
        )
    }
}

export default PhotoCard
