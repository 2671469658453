import React from "react"
import { createUseStyles } from "react-jss"
import { ReactComponent as NoFileIcon } from "Assets/Storage/noFile.svg"
import { Text } from "@awesomesuite-frontend/awesome-nebula"

const useStyles = createUseStyles(() => ({
    noFileWrapper: {
        backgroundColor: ({ backgroundColor }) => backgroundColor && backgroundColor,
    },
    noFile: {
        width: "90%",
        border: "1px solid #4DD0E1",
        padding: "1rem 2rem",
        margin: "2rem auto",
        borderRadius: "4px",
        backgroundColor: "#E1F5FE",
    },
    noFileIcon: {
        display: "flex",
        justifyContent: "center",
    },
}))

const NoFiles = ({ currentFolder, ...props }) => {
    const classes = useStyles(props)
    return (
        <div className={classes.noFileWrapper}>
            <div className={classes.noFile}>
                <Text>
                    You have no documents
                    {`${
                        currentFolder === -1
                            ? " in the root folder"
                            : " associated with this folder"
                    }`}
                    .
                    {currentFolder !== -1 &&
                        "Let's start by creating a new document for this folder."}
                </Text>
            </div>
            <div className={classes.noFileIcon}>
                <NoFileIcon />
            </div>
        </div>
    )
}

export default NoFiles
