import { createUseStyles } from "react-jss"

export const useStyles = createUseStyles((theme) => ({
    signCountWrapper: {
        position: "fixed",
        maxWidth: 980,
        width: "100%",
        bottom: 5,
        zIndex: 100,
        height: "3rem",
        left: "50%",
        transform: "translateX(-50%)",
    },
    signCountContainer: {
        width: "90%",
        margin: "0 auto",
        background: "#272A2F",
        height: "100%",
        borderRadius: 15,
        display: "flex",
        alignItems: "center",
        padding: "0 4rem",
        justifyContent: "space-between",
    },
    signScrollersWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    signScroller: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        border: "1px solid #fff",
        padding: "0.5rem",
        marginLeft: "1rem",
        cursor: "pointer",
        "&:hover": {
            background: "#fff",
            "& svg": {
                fill: "#272A2F",
            },
        },
    },

    signScrollerDisabled: {
        opacity: 0.5,
        cursor: "not-allowed",
        "&:hover": {
            background: "#272A2F",
            "& svg": {
                fill: "#fff",
            },
        },
    },
}))
