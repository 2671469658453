import axios from "axios";

const cancleMem = {
  //id: details
  // id: { time: "", token: "" }
};

/**
 * Request handler class to handle all kind of the requests.
 *
 * implementation
 * ```
 *  const request = new Request("url", "get | post | put etc", headers, idmpotency id);
 *
 *  to make the request use:
 *
 *  const response = await requset.makeRequest();
 *  it returns response data.
 *
 *  manual cancle request.
 *  request.cancle();
 *
 * ```
 */
export class Request {
  constructor(url, method, header, idempotency) {
    this.url = url;
    this.method = method;
    this.headers = header;
    this.idempotency = idempotency;
    this.cToken = null;
  }

  /**
   * this is the builders use these to add data to the request.
   * for post and put request.
   * useage:
   * const request = new Request(url, method, headers, id).withData({obj});
   *
   * @param {} data
   */
  withData(data) {
    this.data = data;
    return this;
  }
  /**
   * this is the builder for adding the params to the request
   *
   * const request = new Request(url, method, headers, id).withData({obj});
   * @param {*} params
   */
  withParams(params) {
    this.params = params;
    return this;
  }

  async makeRequest() {
    if (cancleMem[this.idempotency]) {
      this.cancle(this.idempotency);
    }

    const state = new Date();
    const cancelToken = axios.CancelToken.source();

    return new Promise((resolve, reject) => {
      const configObj = {
        headers: this.headers,
        cancelToken: cancelToken.token,
        params: this.params,
      };

      if (this.method == "post" || this.method == "put") {
        axios[this.method](this.url, this.data, configObj)
          .then((res) => {
            this.requestHandler(res, resolve);
          })
          .catch((res) => {
            this.errorHandler(res, reject);
          });
      } else {
        axios[this.method](this.url, configObj)
          .then((res) => {
            this.requestHandler(res, resolve);
          })
          .catch((res) => {
            this.errorHandler(res, reject);
          });
      }

      this.cToken = cancelToken;
      cancleMem[this.idempotency] = {
        time: state.getTime(),
        token: cancelToken,
      };
    });
  }

  /**
   * api request response handler
   * @param {*} res response object
   * @param {*} resolve promise call back
   */
  requestHandler(res, resolve) {
    if(res.data.statusCode !== 200) resolve(null)
    resolve(res.data);
  }

  /**
   * request handler for the error handling
   * @param {*} error error object.
   * @param {*} reject reject promise call back
   */
  errorHandler(error, reject) {
    if (axios.isCancel(error)) {
      console.log("Api request has been cancled.", error);
    }
    reject(error.message);
  }

  /**
   * manualy cancle the request.
   */
  cancle() {
    if (this.cToken) {
      this.cToken.cancel("Operation canceled.");
    }
  }

  /**
   * cancle the request with the idempoten̥cy (it is a uniqe id for each request and
   * it should be same for all same api signature).
   *
   * @param {*} idempoten̥cy
   */
  cancle(idempotency) {
    if (cancleMem[idempotency]) {
      try {
        const date = new Date();
        const context = cancleMem[idempotency];
        const diff = date.getTime() - context.time;
        // 10 seconds.
        if (diff < 10000) {
          context.token.cancel("cancle the request");
        }
      } catch (error) {
        console.log("request has been cancled.");
        console.log("recived error.", error);
      }
    }
  }
}
