import React from 'react'

/**
 * wrapper for the TimelineConnector and TimelineDetails 
 * component it is suppose to used as a wrapper.
 * 
 * @param {*} param0
 */
const TimelineComponent = ({children}) => {
    return (
        <div style={{ display: "flex"}}>
            {children}
        </div>
    )
}

export default TimelineComponent;
