import { log } from "@awesomesuite-frontend/awesome-nebula"
import {
    GET_WORKSPACE_BY_ID,
    GET_WORKSPACE_ALL,
    ADD_NEW_WORKSPACE,
    SET_WORKSPACE_LOADING,
    SET_WORKSPACE_LOADED,
    DELETE_WORKSPACE_BY_ID,
    EDIT_WORKSPACE_BY_ID,
    SET_WORKSPACE_USERS,
    GET_FOLDERS_BY_USER,
    DELETE_USER_FROM_WORKSPACE,
    GET_CURRENT_WORKSPACE,
    UPDATE_BRANDS_IN_CURRENT_WORKSPACE,
} from "ActionTypes/workspaceActionTypes"
import { eSignApi, workspaceApi } from "Apis/AllApisMapping"
import { store } from "../store"
import { getHeaders } from "./ActionHelper"
import { createMessage } from "./messageAndNotificationActions"
import { DANGER, SUCCESS } from "Types"
import { DELETE_TEAM_MEMBER } from "ActionTypes/foldersActionType"

const { dispatch, getState } = store

/**
 * Takes companyid as parameter and returns the workspace of the company if it exists
 * 
 * sample get data
 * 
 * {
    "code": 200,
    "msg": "success",
    "model": {
        "id": 303,
        "name": "Sayan",
        "code": "Sayan",
        "timezone": "",
        "emailId": "test@demo.com",
        "address": "abcd",
        "logo": "",
        "enable": 1,
        "brands": [],
        "users": [
            {
                "id": 467,
                "user_name": "luproz",
                "first_name": "Saurabh",
                "last_name": "Pathak",
                "email_id": "luproz@metadownload.org",
                "phone_number": "",
                "enable": 1,
                "role": "admin",
                "picture": "",
                "status": 1
            }
        ],
        "roleId": 1,
        "productId": 2
    }
}
 * 
 * @param {*} id 
 * @returns 
 */

export const getWorkspaceByID = async (id) => {
    if (!id) return
    try {
        setWorkspaceLoading()
        const headers = getHeaders()
        const workspaceResponse = await workspaceApi.get(
            `/company?id=${id}&product_id=2`,
            headers
        )
        if (workspaceResponse.data.model) {
            dispatch({
                type: GET_WORKSPACE_BY_ID,
                payload: workspaceResponse.data.model,
            })
            return workspaceResponse.data.model
        } else throw new Error("Unable to Get Workspace By Id")
    } catch (err) {
        setWorkspaceLoaded()
        log(err)
    }
}

/**
 * Get all workspace data in a single account
 * 
 * sample get data
 * 
 * {
    "code": 200,
    "msg": "success",
    "model": [
        {
            "id": 303,
            "name": "",
            "code": "Sayan",
            "timezone": "",
            "emailId": "test@demo.com",
            "address": "abcd",
            "logo": "",
            "enable": 1,
            "brands": null,
            "users": null,
            "roleId": 1,
            "productId": 2
        }
    ]
}
 * 
 * @returns 
 */

export const getWorkspaceAll = async () => {
    try {
        const headers = getHeaders()
        setWorkspaceLoading()
        const workspaceResponse = await workspaceApi.get(
            `/company?product_id=2`,
            headers
        )
        const data = workspaceResponse.data.model
        if (Array.isArray(data) && data?.length > 0) {
            const currentWorkspaceId = data[0].id
            const currentWorkspace = await setCurrentWorkspace(currentWorkspaceId)
            data[0].roleId = currentWorkspace.roleId
        }
        if (data) {
            dispatch({
                type: GET_WORKSPACE_ALL,
                payload: data,
            })
            return data
        } else {
            setWorkspaceLoaded()
            throw new Error("Unable to Get All Workspace")
        }
    } catch (err) {
        setWorkspaceLoaded()
        log(err)
    }
}

/**
 * takes an object of data sent by user, consisting name, email and address(optional)
 * @param {object} param0 
 * creates new workspace for the user
 * sends back the details we got created the workspace with
 * 
 * sample 
 * 
 * model : {
    address: "abc",
    code: "Sayan Ghosh",
    emailId: "test@gmail.com",
    enable: 1,
    id: 306,
    isDeleted: 0,
    logo: "",
    name: "Sayan Ghosh",
    productId: 2,
    timezone: ""
}
 */

export const addNewWorkspace = async ({ name, email, address }) => {
    try {
        const headers = getHeaders()
        const data = {
            name,
            code: name,
            emailId: email,
            address,
            enable: 1,
            productId: 2,
        }
        setWorkspaceLoading()
        const newWorkspaceAddResponse = await workspaceApi.post(
            `/company`,
            data,
            headers
        )
        if (newWorkspaceAddResponse.data.model) {
            const currentWorkspaceId = newWorkspaceAddResponse.data.model.id
            const currentWorkspace = await setCurrentWorkspace(currentWorkspaceId)
            newWorkspaceAddResponse.data.model.roleId = currentWorkspace.roleId
            dispatch({
                type: ADD_NEW_WORKSPACE,
                payload: newWorkspaceAddResponse.data.model,
            })
            return true
        } else {
            setWorkspaceLoaded()
            throw new Error("Unable to Add Workspace")
        }
    } catch (err) {
        setWorkspaceLoaded()
        createMessage(
            "Unable to Create Workspace",
            "Some error occured while creating new workspace",
            DANGER
        )
        log(err)
    }
}

/**
 *  deletes the workspace of the company of id given as param
 * On successful deletion, sends 200 as data code
 * @param {*} id
 */

export const deleteWorkspaceById = async (id) => {
    try {
        const headers = getHeaders()
        setWorkspaceLoading()
        const deleteWorkspaceByIdResponse = await workspaceApi.delete(
            `/company?id=${id}`,
            headers
        )
        if (deleteWorkspaceByIdResponse.data.code === 200)
            dispatch({ type: DELETE_WORKSPACE_BY_ID, payload: { id: id } })
        else throw new Error("Unable to Delete Workspace")
    } catch (err) {
        setWorkspaceLoaded()
        log(err)
    }
}

/**
 * Takes the data of a company workspace that is to be edited
 * data = {id(required), name, logo}
 * 
 * on successful operation, gives back edited data
 * sample: 
 * sending: {
    "id": 306,
    "name": "Sayan",
    "logo" : ""
}

 * response: {
    "code": 200,
    "msg": "success",
    "model": {
        "id": 306,
        "name": "Sayan",
        "code": "Sayan Ghosh",
        "timezone": "",
        "emailId": "test@gmail.com",
        "address": "abc",
        "logo": "",
        "enable": 1,
        "isDeleted": 0,
        "productId": 2
    }
}
 * 
 * @param {object} data 
 * @returns 
 */

export const editWorkspaceById = async (data) => {
    const { id } = data
    if (!id) return

    try {
        const headers = getHeaders()
        setWorkspaceLoading()
        const editWorkspaceByIdResponse = await workspaceApi.put(
            `/company`,
            data,
            headers
        )
        if (editWorkspaceByIdResponse.data.model)
            dispatch({ type: EDIT_WORKSPACE_BY_ID, payload: data })
        else throw new Error("Unable to Edit Workspace")
    } catch (err) {
        setWorkspaceLoaded()
        log(err)
    }
}

export const setWorkspaceLoading = () => dispatch({ type: SET_WORKSPACE_LOADING })
export const setWorkspaceLoaded = () => dispatch({ type: SET_WORKSPACE_LOADED })

export const setWorkspaceUsers = async (loading = true) => {
    const { workspace } = getState().workspaceReducer
    if (!workspace || workspace?.length === 0) {
        return
    }
    const workspaceId = workspace[0]?.id

    try {
        const headers = getHeaders()
        if (loading !== false) setWorkspaceLoading()
        const setUsers = await workspaceApi.get(
            `/user?workspace_id=${workspaceId}`,
            headers
        )
        if (setUsers.data.model) {
            dispatch({
                type: SET_WORKSPACE_USERS,
                payload: { users: setUsers.data.model },
            })
            return setUsers.data.model
        } else throw new Error("Unable to Edit Workspace")
    } catch (err) {
        log(err)
    } finally {
        setWorkspaceLoaded()
    }
}

export const getFoldersByUserId = async (userId) => {
    const { workspace } = getState().workspaceReducer
    if (!workspace || workspace?.length === 0) {
        return
    }
    const workspaceId = workspace[0]?.id

    try {
        const headers = getHeaders()
        // setWorkspaceLoading()
        const response = await workspaceApi.get(
            `/user?workspace_id=${workspaceId}&user_id=${userId}`,
            headers
        )
        if (response.data.model) {
            if (response.data.model.find((folder) => folder.userId === 0)) return
            dispatch({
                type: GET_FOLDERS_BY_USER,
                payload: { userId, folderList: response.data.model },
            })
        } else throw new Error("Unable to Edit Workspace")
    } catch (err) {
        log(err)
    } finally {
        // setWorkspaceLoaded()
    }
}

export const deleteUserFromFolder = async (userId, folderId, loading = true) => {
    const { workspace } = getState().workspaceReducer
    if (!workspace || workspace?.length === 0) {
        return
    }
    const workspaceId = workspace[0]?.id
    if (!userId || !workspaceId) return

    try {
        const headers = getHeaders()
        if (loading) setWorkspaceLoading()
        const response = await workspaceApi.delete(
            `/user?workspace_id=${workspaceId}&brand_id=${folderId}&user_id=${userId}`,
            headers
        )
        if (response.data.code === 200) {
            dispatch({
                type: DELETE_TEAM_MEMBER,
                payload: { userId, folderId },
            })
            createMessage(
                "The user has been deleted successfully",
                "The member is deleted from the folder",
                SUCCESS
            )
            return true
        } else throw new Error("Unable to Delete Member from folder")
    } catch (err) {
        createMessage("Unable to Delete Member from folder", null, DANGER)
        log(err)
    } finally {
        setWorkspaceLoaded()
    }
}

export const deleteUserFromWorkspace = async (userId, loading = true) => {
    const { workspaceReducer, foldersReducer } = getState()
    const { workspace } = workspaceReducer
    if (!workspace || workspace?.length === 0) {
        return
    }
    const workspaceId = workspace[0]?.id
    if (!userId || !workspaceId) return

    try {
        const headers = getHeaders()
        if (loading) setWorkspaceLoading()
        const response = await workspaceApi.delete(
            `/user?workspace_id=${workspaceId}&user_id=${userId}`,
            headers
        )
        if (response.data.code === 200) {
            const { folderList } = foldersReducer

            if (folderList) {
                let updatedFolderList = [...folderList]
                updatedFolderList = updatedFolderList.map((f) => {
                    if (f.users)
                        f.users = f.users.filter((user) => userId !== user.id)
                    return f
                })
                dispatch({
                    type: DELETE_USER_FROM_WORKSPACE,
                    payload: { updatedFolderList },
                })
            }

            createMessage(
                "The user has been deleted successfully",
                "The member is deleted from Workspace",
                SUCCESS
            )
            return true
        } else throw new Error("Unable to Delete Member from Workspace")
    } catch (err) {
        createMessage("Unable to Delete Member from Workspace", null, DANGER)
        log(err)
    } finally {
        setWorkspaceLoaded()
    }
}

export const quickOnboard = async (
    token,
    email,
    userName,
    orgId,
    loading = true
) => {
    const data = {
        name: userName,
        code: userName,
        emailId: email,
        folderName: "My Documents",
        orgId,
    }

    try {
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
        if (loading) setWorkspaceLoading()
        const response = await eSignApi.post(`/quick-onboard`, data, headers)
        if (response.data.statusCode === 200) return true
        else throw new Error("Unable to Quick onboard user on Workspace")
    } catch (err) {
        createMessage(
            "Unable to Quick onboard user on Workspace, Api failed to response",
            null,
            DANGER
        )
        log(err)
    }
}

export const quickOnboardValidation = async (token) => {
    try {
        const headers = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
        const workspaceResponse = await workspaceApi.get(
            `/company?product_id=2`,
            headers
        )
        const data = workspaceResponse.data.model
        if (Array.isArray(data) && data?.length > 0) return false
        return true
    } catch (err) {
        createMessage(err.response, null, DANGER)
        return false
    }
}

export const setCurrentWorkspace = async (workspaceId) => {
    try {
        const headers = getHeaders()
        const currentWorkspace = await workspaceApi.get(
            `/company?id=${workspaceId}&product_id=2`,
            headers
        )
        if (currentWorkspace.data.code === 200) {
            dispatch({
                type: GET_CURRENT_WORKSPACE,
                payload: { currentWorkspace: currentWorkspace.data.model },
            })
            return currentWorkspace.data.model
        } else {
            throw new Error("Unable to get current workspace")
        }
    } catch (err) {
        createMessage(err.response, null, DANGER)
        console.log(err)
    }
}

export const updateBrandsInCurrentWorkspace = async (
    updatedFolderId,
    flag,
    updatedFolderDetails = {}
) => {
    const { currentWorkspace } = getState().workspaceReducer
    let updatedBrands = currentWorkspace.brands ? [...currentWorkspace.brands] : []
    switch (flag) {
        case "add": {
            updatedBrands.push({
                ...updatedFolderDetails,
                brandId: updatedFolderDetails.id,
                companyId: updatedFolderDetails.workspaceId,
            })
            break
        }
        case "delete": {
            updatedBrands = updatedBrands.filter(
                (brand) => brand.brandId !== updatedFolderId
            )
            break
        }
        case "edit": {
            updatedBrands = updatedBrands.map((brand) => {
                if (brand.brandId === updatedFolderId) {
                    return {
                        ...updatedFolderDetails,
                        brandId: updatedFolderDetails.id,
                        companyId: updatedFolderDetails.workspaceId,
                    }
                }
                return brand
            })
            break
        }
        default:
            break
    }
    dispatch({
        type: UPDATE_BRANDS_IN_CURRENT_WORKSPACE,
        payload: { updatedBrands },
    })
    return updatedBrands
}
