import React, { useEffect, useState } from "react"
import { Text } from "@awesomesuite-frontend/awesome-nebula"
import "./RecentActivity.scss"
import { getActivityLogForWorkspace } from "Action/messageAndNotificationActions"
import { connect } from "react-redux"
import { setWorkspaceUsers } from "Action/workspaceAction"
import Activity from "./components/Activity"
import RecentActivitiesLoading from "./components/RecentActivitiesLoading"
import NoActivity from "./components/NoActivity"

const RecentActivityWrapper = ({ children }) => {
    return (
        <div className="aui-recent-activity">
            <Text className="recent-activity">Recent Activity</Text>
            {children}
        </div>
    )
}

const getUserName = (user) => {
    if (!user) return "Anonymous"
    if (user.first_name && user.last_name)
        return `${user.first_name} ${user.last_name}`
    if (user.first_name) return user.first_name
    if (user.user_name) return user.user_name
    if (user.email_id) return user.email_id.split("@")[0]
    return "Anonymous"
}

const activityHelper = (activity, users = []) => {
    const userId = activity.message.actorId
    let user = users.find((u) => u.id === userId)

    if (!user && activity.message.signer) {
        user = {
            first_name: activity.message.signer.firstName,
            last_name: activity.message.signer.lastName,
            email_id: activity.message.signer.emailId,
        }
    }

    activity.message.actorName = getUserName(user)
    activity.message.actorEmail = user?.email_id || ""
    return activity
}

const RecentActivity = ({ workspaceUsers }) => {
    const [activities, setActivities] = useState([])
    const [loading, setLoading] = useState(false)

    async function getAllActivities() {
        let users = []
        setLoading(true)
        if (!workspaceUsers) users = await setWorkspaceUsers(false)
        else users = workspaceUsers

        const res = await getActivityLogForWorkspace()
        const currentActivity = res.map((activity) =>
            activityHelper(activity, users)
        )
        setActivities(currentActivity)
        setLoading(false)
    }

    useEffect(() => {
        getAllActivities()
    }, [])

    if (loading)
        return (
            <RecentActivityWrapper>
                <RecentActivitiesLoading />
            </RecentActivityWrapper>
        )

    if (activities.length === 0)
        return (
            <RecentActivityWrapper>
                <NoActivity />
            </RecentActivityWrapper>
        )

    return (
        <RecentActivityWrapper>
            <div className="aui-activities">
                {activities.map((item, i) => (
                    <Activity key={i} {...item} />
                ))}
            </div>
        </RecentActivityWrapper>
    )
}

const mapStateToProps = ({ workspaceReducer }) => ({
    workspaceUsers: workspaceReducer.users,
})

export default connect(mapStateToProps)(RecentActivity)
