import { Text } from "@awesomesuite-frontend/awesome-nebula"
import React from "react"

const colorsMapping = {
    A: { back: "#fedaa3", text: "#ffa51f" },
    B: { back: "#ffc0ce", text: "#fb5779" },
    C: { back: "#ffecc7", text: "#ffa800" },
    D: { back: "#b4ffdb", text: "#19db7e" },
    E: { back: "#a9fffa", text: "#00d4c8" },
    F: { back: "#ecd3ff", text: "#9f46e4" },
    G: { back: "#d4d1ff", text: "#6457f9" },
    H: { back: "#d2e8ff", text: "#5a7896" },
    I: { back: "#fedaa3", text: "#ffa51f" },
    J: { back: "#ffc0ce", text: "#fb5779" },
    K: { back: "#ffecc7", text: "#ffa800" },
    L: { back: "#b4ffdb", text: "#19db7e" },
    M: { back: "#b4ffdb", text: "#19db7e" },
    N: { back: "#d2e8ff", text: "#5a7896" },
    O: { back: "#d4d1ff", text: "#6457f9" },
    P: { back: "#ecd3ff", text: "#9f46e4" },
    Q: { back: "#fedaa3", text: "#ffa51f" },
    R: { back: "#ffc0ce", text: "#fb5779" },
    S: { back: "#ffecc7", text: "#ffa800" },
    T: { back: "#d4d1ff", text: "#6457f9" },
    U: { back: "#d4d1ff", text: "#6457f9" },
    V: { back: "#ecd3ff", text: "#9f46e4" },
    W: { back: "#ffc0ce", text: "#fb5779" },
    X: { back: "#d2e8ff", text: "#5a7896" },
    Y: { back: "#a9fffa", text: "#00d4c8" },
    Z: { back: "#a9fffa", text: "#00d4c8" },
}

/**
 * label maker with the unique colors for each alphabet
 * children : child only text is allowed
 * paddingH : padding Horizontal
 * paddingV : padding vertical
 * @param {*} {children, paddingH, paddingV}
 */
const LabelChip = ({
    children,
    paddingH,
    paddingV,
    margin,
    text = false,
    fontSize,
}) => {
    if (!children) return <></>
    const colors = colorsMapping[children.slice(0, 1).toUpperCase()]

    return (
        <div
            className="aui-label-chip"
            style={{
                width: "fit-content",
                display: "block",
                paddingTop: paddingH ? paddingH : 2,
                paddingBottom: paddingH ? paddingH : 2,
                paddingLeft: paddingV ? paddingV : 14,
                paddingRight: paddingV ? paddingV : 14,
                borderRadius: 4,
                backgroundColor: colors.back,
                margin: margin && margin,
            }}
        >
            {text ? (
                <Text fontSize={fontSize || 14} color={colors.text}>
                    {children}
                </Text>
            ) : (
                children
            )}
        </div>
    )
}

export default LabelChip
