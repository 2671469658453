import axios from "axios"

var prodApis = {
    USER_API:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/user-management-service",
    MEDIA_API:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/media-service",
    AUTH_API:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/user-management-service",
    NOTIFICATION_API:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/notification-service",
    ANALYTIC_API:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/activity-service",
    TRANSACTION_API:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/payment-service",
    SESSIONS_API:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/activity-service",
    SUBSCRIPTION_API:
        "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/subscription-service",
    ESGIN_API: "https://wgj748w3el.execute-api.us-west-1.amazonaws.com/prod/esign-service",
    WORKSPACE_API: "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/workspace-service",
    MEDIA_API: "https://p69v085vy3.execute-api.ap-south-1.amazonaws.com/prod/media-service",
}

var releaseApis = {
    USER_API:
        "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/user-management-service",
    MEDIA_API:
        "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/media-service",
    AUTH_API:
        "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/user-management-service",
    NOTIFICATION_API:
        "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/notification-service",
    ANALYTIC_API:
        "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/activity-service",
    TRANSACTION_API:
        "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/payment-service",
    SESSIONS_API:
        "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/activity-service",
    SUBSCRIPTION_API:
        "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/subscription-service",
    ESGIN_API: "",
    WORKSPACE_API: "https://x2x055d83j.execute-api.us-east-2.amazonaws.com/sit/workspace-service",
}

var devApis = {
    USER_API:
        "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/user-management-service",
    MEDIA_API:
        "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/media-service",
    AUTH_API:
        "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/user-management-service",
    NOTIFICATION_API:
        "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/notification-service",
    ANALYTIC_API:
        "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/activity-service",
    TRANSACTION_API:
        "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/payment-service",
    SESSIONS_API:
        "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/activity-service",
    SUBSCRIPTION_API:
        "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/subscription-service",
    ESGIN_API:
        "https://e7r2100v0g.execute-api.ap-south-1.amazonaws.com/dev/esign-services",
    WORKSPACE_API: "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/workspace-service",
    MEDIA_API: "https://jowzxoke22.execute-api.ap-south-1.amazonaws.com/dev/media-service",
}

/**
 * Get the api routes data
 *
 * @returns apis routes
 */
var getApiURLs = () => {
    const enviroment = process.env.REACT_APP_ENV

    switch (enviroment) {
        case "production":
            return prodApis
        case "release":
            return releaseApis
        case "dev":
            return devApis
        default:
            return devApis
    }
}

export const API = getApiURLs()

/**
 * access token api end point, use this api to get the access token for the incoming
 * loggedIn user from accounts
 */
export const accessApi = axios.create({
    baseURL: API.AUTH_API,
})

/**
 * this is the authorization api endpoint handle
 * @example{Login, Logout, signup}
 */
export const authApi = axios.create({
    baseURL: API.USER_API,
})

/**
 * static api for the media service
 */
export const medialApi = API.MEDIA_API

/**
 * notification api end point.to handler get and put notifications
 */
export const notificationApi = axios.create({
    baseURL: API.NOTIFICATION_API,
})

export const activityLogApi = axios.create({
    baseURL: API.ANALYTIC_API,
})

/**
 * eSign api for stamping and getting the documents.
 * "https://q1fcu4motl.execute-api.ap-south-1.amazonaws.com/prod"
 */
export const eSignApi = axios.create({
    baseURL: API.ESGIN_API,
})

/**
 * subscription api for getting the subscribed products.
 */
export const subscriptionApi = axios.create({
    baseURL: API.SUBSCRIPTION_API,
})

/**
 * Workspace api for getting the workspace of the current user
 */

export const workspaceApi = axios.create({
    baseURL: API.WORKSPACE_API,
})

export const mediaApi = axios.create({
    baseURL: API.MEDIA_API,
})