import { createUseStyles } from "react-jss"

export const useStyles = createUseStyles(() => ({
    warningWrapper: {
        position: "fixed",
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(45,62,80,0.72)",
        top: 0,
        left: 0,
        zIndex: 10000000000,
    },
    warningModal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        backgroundColor: "#fff",
        borderRadius: 3,
    },
    warningModalUpper: {
        background: "linear-gradient(45deg, #F2547D, #FF8F59)",
        borderRadius: "3px 3px 0 0",
        paddingBottom: "2rem",
        position: "relative",
    },
    warningModalLower: {
        background: "#fff",
        paddingBottom: "2rem",
        borderRadius: "0 0 3px 3px",
    },
    warningModalUpperIcon: {
        position: "relative",
        height: 100,
        "& svg": {
            position: "absolute",
            transform: "translate(-50%, -50%)",
            top: 0,
            left: "50%",
            width: 200,
        },
        "& img": {
            position: "absolute",
            transform: "translate(-50%, -50%)",
            top: 0,
            left: "50%",
            width: 200,
        },
    },
    warningModalUpperIconCancel: {
        position: "absolute",
        right: 10,
        top: 10,
        height: 30,
        width: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        cursor: "pointer",
        zIndex: 20,
        "&:hover": {
            backgroundColor: "#eaf0f6",
            "& svg": {
                fill: "#33475B",
            }
        },
    },
    warningModalButtons: {
        display: "flex",
        justifyContent: "space-evenly",
    },
}))
