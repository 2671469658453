import React from "react"
import { connect } from "react-redux"
import SideBar from "../../Awesome-ui/sideBar/SideBar"
import Icon from "../../Awesome-ui/sideBar/SideBarSlimIcon/SideBarSlimIcon"
import SideBarNameIcon from "../../Awesome-ui/SideBarNameIcon/SideBarNameIcon"

const SideNav = ({
    type,
    expand,
    theme,
    navOptions,
    IconFull,
    IconSmall,
    appSwitch,
    isAppSwitch,
    userRole,
    restricted,
    showAppSwitcher,
}) => {
    return (
        <SideBar
            type={type}
            expand={expand}
            theme={theme}
            IconFull={IconFull}
            IconSmall={IconSmall}
            appSwitch={appSwitch}
            userRole={userRole}
            showAppSwitcher={showAppSwitcher}
        >
            {!restricted &&
                type === "named" &&
                navOptions.map((link, index) => (
                    <SideBarNameIcon
                        theme={theme}
                        key={index}
                        name={link.name}
                        link={link.link}
                        Pic={link.Icon}
                    />
                ))}
            {!restricted &&
                (!type ||
                    (type === "slim" &&
                        navOptions.map((link, index) => (
                            <Icon
                                theme={theme}
                                key={index}
                                name={link.name}
                                link={link.link}
                                SvgIcon={link.Icon}
                                isAppSwitch={isAppSwitch}
                            />
                        ))))}
        </SideBar>
    )
}

const mapStateToProps = ({ settingsReducer }) => ({
    isAppSwitch: settingsReducer.sideExpand,
})

export default connect(mapStateToProps)(SideNav)
